<template>
  <section id="dimensions">
    <div class="section-heading">
      <h6>Package Dimensions</h6>
      <button
        v-if="showUndoIdwButton"
        class="btn btn-sm idw-undo-btn"
        @click="undoIdwDimensions()">
        <span>Undo
          <i class="material-icons">
            undo
          </i>
        </span>
      </button>
      <idw-button
        title="Dimensions"
        section="dimensions"
        :loaded="idwLoaded"
        :has-data="idwDimensions.length > 0"
        :btn-name="'View IDEA'"
        @openDialog="$refs.idwDimensionsDialog.open()"></idw-button>
    </div>
    <div class="section-body">
      <div
        v-for="d in dimensions"
        :key="`dimension-${d.dimensionTypeId}`"
        class="form-group product-dimension"
        track-by="index">
        <label class="uom-label form-control-label">
          {{ d.dimension }}
        </label>
        <div class="input-group">
          <input
            v-model.number="d.dimensionValue"
            type="number"
            class="form-control"
            @input="setDefaultUnit(d)" />
          <span class="input-group-btn"></span>
          <select
            v-if="d.dimensionTypeId == weight.dimensionTypeId"
            v-model="d.uomId"
            class="uom-select form-control">
            <option
              v-for="u in weight.uoms"
              :key="`unit-weight-${u.uomId}`"
              :value="u.uomId">
              {{ u.unitOfMeasure }}
            </option>
          </select>
          <select
            v-else
            v-model="d.uomId"
            class="uom-select form-control"
            @change="setAllUnits(d)">
            <option
              v-for="u in length.uoms"
              :key="`unit-length-${u.uomId}`"
              :value="u.uomId">
              {{ u.unitOfMeasure }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <sweet-modal
      ref="idwDimensionsDialog"
      title="Package Dimensions"
      :extra-extra-large="true">
      <idw-dimensions
        :product.sync="product"
        :idea-package="ideaPackage"
        :dimensions="originalValues.length > 0 ? originalValues : dimensions"
        @onSelected="applyIdwDimensions($event)"></idw-dimensions>
    </sweet-modal>
  </section>
</template>
<script type="text/javascript">
import axios from 'axios'
import IdwButton from 'components/products/edit/idw/Button'
import IdwDimensions from 'components/products/edit/idw/Dimensions'
import SweetModal from 'components/modals/sweet.vue'

export default {
  components: {
    'idw-button': IdwButton,
    'idw-dimensions': IdwDimensions,
    'sweet-modal': SweetModal
  },
  props: {
    product: Object,
    dimensions: Array,
    idwDimensions: {
      type: Array,
      required: false
    },
    ideaPackage: {
      type: Array,
      required: false,
      default: null
    },
    idwLoaded: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      weight: {
        typeId: 2,
        dimensionTypeId: 1,
        uoms: [], // units of measurements
        defaultUOM: 10 // lb
      },
      length: {
        typeId: 1,
        dimensionTypeId: null,
        uoms: [],
        defaultUOM: 1 // Inch
      },
      showUndoIdwButton: false,
      originalValues: []
    }
  },
  mounted () {
    this.getUnitOfMeasurementOptions()
  },
  methods: {
    getUnitOfMeasurementOptions () {
      axios.get(`/api/unit-of-measurements/${this.length.typeId}`)
        .then(res => {
          const result = res.data
          this.length.uoms = result
        }).catch(e => console.warn(e))

      axios.get(`/api/unit-of-measurements/${this.weight.typeId}`)
        .then(res => {
          const result = res.data
          this.weight.uoms = result
        }).catch(e => console.warn(e))
    },
    setDefaultUnit (dimension) {
      if (!dimension.uomId) {
        const isWeightDimension = (dimension.dimensionTypeId === this.weight.dimensionTypeId)
        if (isWeightDimension) {
          dimension.uomId = this.weight.defaultUOM
        } else {
          dimension.uomId = this.length.defaultUOM
          this.setAllUnits(dimension)
        }
      }
    },
    setAllUnits (dimension) {
      const uomId = dimension.uomId
      this.dimensions.forEach(d => {
        if (d.dimensionTypeId !== 1) {
          d.uomId = !d.uomId ? uomId : d.uomId
        }
      })
    },
    applyIdwDimensions (ideaPackage) {
      this.originalValues = JSON.parse(JSON.stringify(this.dimensions))

      if (ideaPackage.ideaPakWgt > 0 && ideaPackage.ideaPakWgtUom) {
        const weight = this.dimensions.find(x => x.dimension === 'Weight')
        weight.dimensionValue = ideaPackage.ideaPakWgt
        const weightUom = this.weight.uoms.find(x => x.unitOfMeasure === ideaPackage.ideaPakWgtUom)
        weight.uomId = weightUom.uomId
        weight.unitOfMeasure = weightUom.unitOfMeasure
      }

      if (ideaPackage.ideaPakLen > 0 && ideaPackage.ideaPakLenUom) {
        const length = this.dimensions.find(x => x.dimension === 'Length')
        length.dimensionValue = ideaPackage.ideaPakLen
        const lengthUom = this.length.uoms.find(x => x.unitOfMeasure === ideaPackage.ideaPakLenUom)
        length.uomId = lengthUom.uomId
        length.unitOfMeasure = lengthUom.unitOfMeasure
      }

      if (ideaPackage.ideaPakWid > 0 && ideaPackage.ideaPakWidUom) {
        const width = this.dimensions.find(x => x.dimension === 'Width')
        width.dimensionValue = ideaPackage.ideaPakWid
        const widthUom = this.length.uoms.find(x => x.unitOfMeasure === ideaPackage.ideaPakWidUom)
        width.uomId = widthUom.uomId
        width.unitOfMeasure = widthUom.unitOfMeasure
      }

      if (ideaPackage.ideaPakHgt > 0 && ideaPackage.ideaPakHgtUom) {
        const height = this.dimensions.find(x => x.dimension === 'Height')
        height.dimensionValue = ideaPackage.ideaPakHgt
        const heightUom = this.length.uoms.find(x => x.unitOfMeasure === ideaPackage.ideaPakHgtUom)
        height.uomId = heightUom.uomId
        height.unitOfMeasure = heightUom.unitOfMeasure
      }

      this.showUndoIdwButton = true
      this.$refs.idwDimensionsDialog.close()
    },
    undoIdwDimensions () {
      this.showUndoIdwButton = false
      this.originalValues.forEach(d => {
        const dimension = this.dimensions.find(i => i.dimension === d.dimension)
        dimension.dimensionValue = d.dimensionValue
        dimension.uomId = d.uomId
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/extra/mixins/flexbox.scss";
.section-heading {
  .idw-undo-btn {
    position: absolute;
    right: 5.75rem;
    top: 0.5rem;

    span {
      font-size: 0.75rem;
      position: relative;
      @include flexbox;
      @include justify-content(space-between);
      i {
        padding-left: 6px;
      }
    }
  }

  .material-icons {
    font-size: 16px;
  }

  .btn-idw {
    right: 0;
    top: 0.5rem;
  }
}
section {
  .product-dimension {
    .uom-label {
      position: relative;
      display: block;
      &:before {
        content: "uom:";
        text-transform: uppercase;
        position: absolute;
        right: 0;
        width: 4.75rem;
      }
    }
    .uom-select {
      @include flex(0, 1, 5.125rem);
      max-width: 5.125rem;
      margin-left: -1px;
      background-color: #ececec;
      box-shadow: none;
    }
  }
}
</style>
