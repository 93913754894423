export default {
  init (context) {
    context.commit('init')
  },
  logOut () {
    console.log(`action logOut window.location.href=${window.location.href}`)
    // window.location.href = '/logout'
    window.location.href = 'http://localhost:5004/logout'
  },
  setLanguage (context, obj) {
    context.commit('setLanguage', obj)
  },
  setLanguageOptions (context, list) {
    context.commit('setLanguageOptions', list)
  },
  setUserDetail (context, obj) {
    context.commit('setUserDetail', obj)
  },
  setUser (context, obj) {
    context.commit('setUser', obj)
  },
  setSpinner (context, bool) {
    context.commit('setSpinnerState', bool)
  },
  setModal (context, obj) {
    context.commit('setModalState', obj)
  },
  resetModal (context) {
    const modal = {
      show: false,
      title: null,
      message: '',
      icon: 'success',
      blockClose: false,
      btn: {
        text: 'Done',
        fn: () => null
      }
    }
    context.commit('setModalState', modal)
  },
  setManufacturers (context, result) {
    context.commit('setManufacturers', result)
  },
  setCompanyList (context, list) {
    context.commit('setCompanyList', list)
  },
  setBannersList (context, list) {
    context.commit('setBannersList', list)
  },
  setPagination (context, page) {
    context.commit('setPagination', page)
  },
  setUsersList (context, list) {
    context.commit('setUsersList', list)
  },
  setTeamList (context, list) {
    context.commit('setTeamList', list)
  },
  setProductBulkList (context, products) {
    context.commit('setProductBulkList', products)
  },
  setCategory (context, cat) {
    context.commit('setCategory', cat)
  },
  setAccessToken (context, accessToken) {
    context.commit('setAccessToken', accessToken)
  },
  async setIdentity (context, obj) {
    context.commit('setIdentity', obj)

    // // on setting identity, we also want to set the current banner to its default
    // const bannerName = context.getters.getDefaultBannerName
    // context.commit('setCurrentBanner', bannerName)

    // await context.dispatch('getGlobalBranches')
    // context.dispatch('setDefaultBranch')
  },
  setAppConfig (context, obj) {
    context.commit('setAppConfig', obj)
  },
  setPermissions (context, permissions) {
    context.commit('setPermissions', permissions)
  },
  setExternalUrl (context, externalUrl) {
    context.commit('setExternalUrl', externalUrl)
  }
}
