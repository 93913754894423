<template>
  <div>
    <page-title :title="'Sub Category Product Details'"></page-title>
    <div
      class="spinner"
      :class="{'show-spinner': showSpinner}"></div>
    <div
      v-if="!showSpinner"
      class="search-section">
      <div class="product-search">
        <input
          v-model.trim="search"
          type="text"
          class="form-control"
          placeholder="Search within results" />
        <template>
          <i
            v-show="isEmpty"
            class="material-icons">
            search
          </i>
          <i
            v-show="isDirty"
            class="material-icons times"
            @click="search = ''">
            close
          </i>
        </template>
      </div>
      <div class="product-table-pager">
        <div class="table-viewing-info">
          <label>Viewing {{ currentViewing | numeric }} -
            <span v-if="parseInt(currentViewing) + parseInt(pagination.per_page) - 1 < pagination.total">
              {{ parseInt(currentViewing) + parseInt(pagination.per_page) - 1 | numeric }}
            </span>
            <span v-else>
              {{ pagination.total | numeric }}
            </span> of {{ pagination.total | numeric }}
          </label>
        </div>
        <div class="input-group">
          <span class="input-group-addon">
            Showing:
          </span>
          <select
            v-model="pagination.per_page"
            class="form-control">
            <option
              v-for="o in paginationOptions.pageSizes"
              :key="`pager-${o}`"
              :value="o">
              {{ o }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <h5>{{ title }}</h5>
    <div
      v-if="productList.length"
      class="product-list">
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="img-col"></th>
            <th class="id-col">
              <a @click="sortStringValues('catNum', sortAsc)">PRODUCT
                <i
                  v-if="sortCol == 'catNum' && sortAsc == true"
                  class="material-icons">
                  expand_more
                </i>
                <i
                  v-else-if="sortCol == 'catNum' && sortAsc == false"
                  class="material-icons">
                  expand_less
                </i>
                <i
                  v-else
                  class="material-icons no-sort">
                  code
                </i>
              </a>
            </th>
            <th class="mfr-col">
              <a @click="sortStringValues('mfrName', sortAsc)">MANUFACTURER
                <i
                  v-if="sortCol == 'mfrName' && sortAsc == true"
                  class="material-icons">
                  expand_more
                </i>
                <i
                  v-else-if="sortCol == 'mfrName' && sortAsc == false"
                  class="material-icons">
                  expand_less
                </i>
                <i
                  v-else
                  class="material-icons no-sort">
                  code
                </i>
              </a>
            </th>
            <th class="description-col">
              DESCRIPTION
            </th>
            <th class="description-col">
              SHORT DESCRIPTION
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <router-link
            v-for="product in productList"
            :key="'product-' + product.napmId"
            tag="tr"
            :to="{ name: 'productEdit', params: { id: product.napmId }}">
            <td class="img-col">
              <div class="img-fluid">
                <img
                  v-if="product.folderName"
                  :src="`https://rexel-cdn.com/Products/${product.catNum}.jpg?i=${product.folderName}&f=150`" />
                <img
                  v-else
                  :src="`https://rexel-cdn.com/products/${product.catNum}.jpg?i=0EA7A6C5-7DD2-4482-9229-D628D3AFAE9D&f=150`" />
              </div>
            </td>
            <td class="id-col">
              Name:
              <span v-html="formatResult(product.catNum, search)"></span><br /> PIM#:
              <span v-html="formatResult(product.napmId, search)"></span><br /> UPC:
              <span v-html="formatResult(product.upc, search)"></span>
            </td>
            <td class="mfr-col">
              <span v-html="formatResult(product.mfrName, search)"></span>
            </td>
            <td class="description-col">
              <span v-html="formatResult(product.description, search)"></span>
            </td>
            <td>
              <span v-html="formatResult(product.shortDescription, search)"></span>
            </td>
            <td class="arrow-col">
              <span class="productCount-arrow">
                <i class="material-icons">
                  keyboard_arrow_right
                </i>
              </span>
            </td>
          </router-link>
        </tbody>
      </table>
    </div>
    <div
      v-else
      class="alert alert-info">
      No Sub Categories match the search term of
      <strong>"{{ search }}"</strong>. Try changing your search term.
    </div>
    <div
      v-if="!showSpinner && pagination.last_page !== 1"
      class="products-pagination">
      <pagination
        :pagination="pagination"
        :callback="searchPagintation"
        :options="paginationOptions"></pagination>
    </div>
  </div>
</template>
<script type="text/javascript">
import axios from 'axios'
import TitleComponent from 'components/pageTitle.vue'
import PaginationComponent from 'components/pagination.vue'

export default {
  name: 'SubCategoryProductList',
  components: {
    'page-title': TitleComponent,
    pagination: PaginationComponent
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.languageId = to.meta.lang
      vm.title = to.params.name
      vm.getAllSubCategoryProducts(to.params.id)
    })
  },
  data () {
    return {
      products: [],
      sortCol: '',
      sortAsc: false,
      search: '',
      pagination: {
        total: 0,
        per_page: 50, // required
        current_page: 1, // required
        last_page: 0, // required
        from: 0,
        to: 15 // required
      },
      paginationOptions: {
        offset: 5,
        previousText: 'Prev',
        nextText: 'Next',
        alwaysShowPrevNext: true,
        pageSizes: [25, 50, 100, 150, 250, 500]
      },
      currentViewing: 1,
      showSpinner: true,
      title: '',
      currentSubCategoryId: 0,
      languageId: 0
    }
  },
  computed: {
    isEmpty () {
      return !this.search
    },
    isDirty () {
      return !!this.search
    },
    productsFiltered () {
      let list = []
      if (!this.search) {
        list = this.products
      }
      const search = this.search.toLowerCase()
      list = this.products.filter((product, index) => {
        return (product.catNum && product.catNum.toLowerCase().indexOf(search) > -1) ||
          (product.description && product.description.toLowerCase().indexOf(search) > -1) ||
          (product.mfrName && product.mfrName.toLowerCase().indexOf(search) > -1) ||
          (product.napmId && product.napmId.toString().indexOf(search) > -1) ||
          (product.section && product.section.toLowerCase().indexOf(search) > -1) ||
          (product.shortDescription && product.shortDescription.toLowerCase().indexOf(search) > -1) ||
          (product.upc && product.upc.toString().indexOf(search) > -1)
      })
      this.currentViewing = 1
      this.pagination.current_page = 1
      this.pagination.total = list.length
      this.searchPagintation(true)
      return list
    },
    productList () {
      this.currentViewing = (this.pagination.current_page - 1) * this.pagination.per_page + 1
      const list = this.productsFiltered.filter((ven, index) => {
        return index >= ((this.pagination.current_page - 1) * this.pagination.per_page) && index <= ((this.pagination.current_page - 1) * this.pagination.per_page + this.pagination.per_page - 1)
      })
      return list
    }
  },
  watch: {
  },
  methods: {
    getAllSubCategoryProducts (id) {
      if (this.$route.params.id === this.currentSubCategoryId) {
        return
      }
      this.currentSubCategoryId = this.$route.params.id
      this.showSpinner = true
      this.products = []
      this.search = ''
      axios.get(`/api/reports/subcategory/GetProductDetail/${id}/${this.languageId}`).then(res => {
        const response = res.data
        this.products = response
        this.pagination.total = this.products.length
        this.pagination.last_page = Math.ceil(this.products.length / this.pagination.per_page)
        this.$nextTick(() => {
          this.showSpinner = false
        })
      })
    },
    searchPagintation (size) {
      if (size) {
        this.pagination.last_page = Math.ceil(this.pagination.total / this.pagination.per_page)
      }
    },
    escapeRegExChars (value) {
      return value.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
    },
    formatResult (suggestion, search) {
      // Do not replace anything if the current value is empty
      if (!search || search === '' || !suggestion) {
        return suggestion
      }

      const pattern = '(' + this.escapeRegExChars(search) + ')'
      return suggestion.toString()
        .replace(new RegExp(pattern, 'gi'), '<strong>$1</strong>')
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/&lt;(\/?strong)&gt;/g, '<$1>')
    },
    sortIds (col, ascending) {
      this.pagination.current_page = 1
      ascending = !ascending
      this.sortCol = col
      this.sortAsc = ascending
      if (ascending) {
        this.products.sort((a, b) => a[col] - b[col])
      } else {
        this.products.sort((a, b) => b[col] - a[col])
      }
    },
    sortStringValues (col, ascending) {
      this.pagination.current_page = 1
      ascending = !ascending
      this.sortCol = col
      this.sortAsc = ascending
      if (ascending) {
        this.products.sort((a, b) => a[col].localeCompare(b[col]))
      } else {
        this.products.sort((a, b) => b[col].localeCompare(a[col]))
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/scss/bootstrap.scss';
@import '../../../assets/scss/extra/mixins/flexbox.scss';
@import '../../../assets/scss/bootstrap-custom-variables.scss';
.search-section {
  margin-bottom: 1.5rem;
  @include flexbox;
  .product-search {
    position: relative;
    @include flex(0, 1, 40%);
    .form-control {
      & ~ .material-icons.times:hover,
      &:focus ~ .material-icons.times {
        cursor: pointer;
        color: $brand-danger;
      }
    }
    .material-icons {
      color: darken($gray-lighter, 25%);
      position: absolute;
      font-size: 1.125rem;
      top: 0.125rem;
      right: 0.125rem;
      padding: 0.5rem;
    }
  }
  .add-new-btn {
    padding: 0 1rem; // hidden until we are ready to add create..
    display: none;
    .btn {
      padding: 0.5rem 2rem;
    }
  }
  .product-table-pager {
    @include flex(1, 1, 30%);
    @include flexbox;
    @include justify-content(flex-end);
    .table-viewing-info {
      width: 10rem;
      text-align: right;
      color: #222;
      font-size: 0.875rem;
      @include align-self(center);
      @include flex(1, 1, 10rem);

      > label {
        background: #eee;
        padding: 0.5rem 1.5rem;
        margin-bottom: 0;
        border: 1px solid rgba(0, 0, 0, 0.15);
      }
    }
    .input-group {
      width: 12rem;
      border: 0;

      .input-group-addon {
        font-size: 0.875rem;
        border: 0;
        background: #fff;
      }
      .form-control {
        font-size: 0.875rem;
        background: #eee;
      }
    }
  }
}

.product-list {
  .table {
    border: 1px solid #eceeef;
    thead {
      tr {
        th {
          text-transform: uppercase;
          .material-icons {
            font-size: 1rem;
          }
          .no-sort {
            -ms-transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
          }
        }
      }
    }
    tbody {
      tr {
        td {
          vertical-align: middle;
          cursor: pointer;
        }
      }
    }
    .product-col {
      width: 23.75rem;
    }
    .img-col {
      width: 4.5rem;
      border-right: 1px solid #eceeef;
      .img-fluid {
        @include flexbox;
        @include justify-content(center);
        height: 3.75rem;
        img {
          @include align-self(center);
          max-height: 3.75rem;
          max-width: 3.75rem;
        }
      }
    }
    .description-col {
      width: 40rem;
    }
    .mfr-col {
      width: 10rem;
    }
    .id-col,
    .active-col {
      width: 12rem;
    }
    .arrow-col {
      width: 4rem;
      text-align: center;
      .productCount-arrow {
        display: block;
        position: relative;
        color: #fff;
        background: $brand-primary;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        &:hover {
          background: darken($brand-primary, 10%);
        }
        .material-icons {
          position: absolute;
          font-size: 1.5rem;

          top: 50%;
          right: 50%;
          margin: -0.75rem -0.825rem 0 0;
        }
      }
    }
  }
}

.product-nav {
  @include flexbox;
  @include justify-content(space-between);

  .btn-nav {
    @include flex(1, 1, 75%);

    .router-link-exact-active {
      @extend .btn-primary;
    }
  }
}
</style>
