import axios from 'axios'
import MediaFileTypesMixin from '@/views/media/types/MediaFileTypes.mixin'
import ImageUploadMixin from '@/views/media/types/image/ImageUpload.mixin'
import DocumentUploadMixin from '@/views/media/types/document/DocumentUpload.mixin'

export default {
  mixins: [MediaFileTypesMixin, ImageUploadMixin, DocumentUploadMixin],
  data () {
    return {
      uriToUpload: {
        fileName: '',
        value: '',
        error: false,
        type: '',
        manufacturer: null
      },
      uploadUri: '',
      showUploadUrlError: false,
      showUploadSpinner: false,
      uploadUrlErrorMsg: `Unable to import url. Please check the url or download the media directly
      to your computer and upload through the file upload up above.`
    }
  },
  methods: {
    createUriForUpload () {
      this.showUploadSpinner = true
      this.showUploadUrlError = false // needed for when they try to upload again after error
      this.uriToUpload.error = false // needed for when they try to upload again after error
      const uri = this.uploadUri
      const nameData = uri.split('/')
      const filename = nameData[nameData.length - 2] + nameData[nameData.length - 1]
      this.uriToUpload.fileName = filename.split('?')[0]
      this.uriToUpload.value = uri
      const self = this
      return new Promise((resolve, reject) => {
        const url = '/api/media/to-byte-array?uri=' + encodeURIComponent(uri)
        axios({
          url,
          method: 'GET',
          responseType: 'blob'
        })
          .then((res) => {
            if (res.status !== 200) {
              self.uriToUpload.error = true
              self.showUploadUrlError = true
            }
            self.uriToUpload.type = this.getMediaTypeByFileType(res.data.type)
            self.showUploadSpinner = false
            resolve(res.data)
          })
          .catch((err) => {
            console.error(err)
          })
      })
    },
    saveUri () {
      const data = new FormData()
      data.append('uri', this.uriToUpload.value)
      data.append('fileName', this.uriToUpload.fileName)
      data.append('mfrId', this.uriToUpload.manufacturer)
      const opts = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      let postUrl
      if (this.uriToUpload.type === this.mediaTypes.image) {
        this.appendImageData(data)
        postUrl = '/api/media/images'
      } else if (this.uriToUpload.type === 'video') {
        postUrl = '/api/media/videos'
      } else {
        this.appendDocumentData(data)
        postUrl = '/api/media/documents'
      }
      return axios.post(postUrl, data, opts)
        .then(res => {
          return res.data
        })
        .catch((e) => {
          console.log(e)
        })
    },
    resetUriDefaults () {
      this.uriToUpload = {
        fileName: '',
        value: '',
        error: false,
        type: '',
        manufacturer: ''
      }
      this.uploadUri = null
      this.showUploadUrlError = false
    }
  }
}
