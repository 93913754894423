<template>
  <div
    v-if="details.mediaId"
    class="container ml-0 pl-0">
    <page-title title="Media Details"></page-title>
    <section class="row justify-content-between">
      <div class="col-8">
        <nav class="btn-nav">
          <button
            v-if="isDocument && details.languageId > 0"
            class="mt-0 btn btn-rounded btn-primary">
            {{ mediaLanguage }}
          </button>
          <!-- <template v-for="lang in $store.getters.languageOptions">
            <button :key="`language-id-${lang.id}`" v-on:click="changeLanguage(lang)" class="mt-0 btn btn-rounded" :class="[lang.id === language ? 'btn-primary' : 'btn-gray-light']">{{lang.name}}</button>
          </template> -->
        </nav>
      </div>
      <div class="col text-right">
        <div class="btn-group">
          <button
            type="button"
            class="btn btn-primary btn-save"
            @click="saveDetails()">
            Save
          </button>
          <button
            v-if="isAdmin"
            type="button"
            class="btn btn-primary dropdown-toggle dropdown-toggle-split"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">
            <span class="sr-only">
              Toggle Dropdown
            </span>
          </button>
          <div
            v-if="isAdmin"
            class="dropdown-menu dropdown-menu-right">
            <a
              class="dropdown-item btn-delete"
              @click="confirmDelete()">Delete</a>
          </div>
        </div>
      </div>
    </section>
    <section class="row">
      <div class="col-6">
        <div class="form-group">
          <div>
            <label class="form-control-label">
              File Name
            </label>
            <div>
              {{ details.name }}
            </div>
          </div>
        </div>
        <div
          v-if="details.documentTypeId"
          class="form-group">
          <label class="form-control-label">
            Document Type
          </label>
          <select
            v-model="details.documentTypeId"
            class="form-control">
            <option
              v-for="d in documentTypes"
              :key="d.documentTypeId"
              :value="d.documentTypeId">
              {{ d.name }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label class="form-control-label">
            Manufacturer
          </label>
          <select
            v-model="details.mfrId"
            class="form-control">
            <option :value="null">
              -- Select --
            </option>
            <option
              v-for="m in manufacturers"
              :key="m.id"
              :value="m.id">
              {{ m.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-6">
        <div class="media-preview">
          <template v-if="!replaceMedia">
            <media-icon
              v-if="isDocument"
              :type="details.mediaType"
              :file.sync="details.fileName"
              size="media-icon-large"></media-icon>
            <div
              v-else-if="details.mediaTypeId === 4"
              class="media-video">
              <a @click="playVideo(details.mediaId)">
                <img src="../../../assets/images/icon-play.svg" />
              </a>
            </div>
            <div
              v-else
              class="media-image">
              <img :src="getMediaImagePath({ folder: details.folderName, size: 320 })" />
            </div>
          </template>
          <div
            v-else
            class="media-replace">
            <button
              class="btn btn-danger"
              @click="replaceMedia = false">
              <i class="material-icons">
                close
              </i>
            </button>
            <new-media
              :manufacturers="manufacturers"
              :document-types="documentTypes"
              :type-id="details.documentTypeId"
              :filter-type="originalMediaType.toLowerCase()"
              :media-replace="true"
              :media-id="details.mediaId"
              @update="$emit('recent')"></new-media>
          </div>

          <a
            v-if="!replaceMedia"
            :href="downloadUrl"
            target="_blank"
            download
            class="btn-raised download"
            title="Download">
            <i class="material-icons">
              cloud_download
            </i>
          </a>
          <a
            v-if="details.mediaType == 'Image' && !replaceMedia"
            class="btn-raised crop"
            title="Crop"
            @click="reCrop = true">
            <i class="material-icons">
              crop
            </i>
          </a>
          <a
            v-if="!replaceMedia"
            class="btn-raised replace"
            title="Replace"
            @click.prevent="replaceMedia = true">
            <i class="material-icons">
              restore_page
            </i>
          </a>
        </div>
      </div>
    </section>
    <section class="product-list">
      <div
        v-if="isAdmin"
        class="row">
        <div class="col">
          <autocomplete
            response-data="products"
            placeholder="Add Products..."
            @hit="addProduct($event)"></autocomplete>
        </div>
        <div class="col text-right pt-2">
          <button
            type="text"
            class="btn btn-rounded btn-danger"
            :disabled="!selectedProducts.length"
            @click="removeProducts()">
            Remove {{ productText }}
          </button>
        </div>
      </div>
      <table
        v-if="details.products.length"
        class="table table-bordered">
        <thead>
          <tr>
            <th colspan="2">
              <span class="pl-3">
                Product Name
              </span>
            </th>
            <th>UPC</th>
            <th>NapmID</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="p in details.products"
            :key="p.napmId"
            class="row-product">
            <td class="cell-check">
              <label
                v-if="isAdmin"
                class="custom-control custom-checkbox">
                <input
                  v-model="selectedProducts"
                  type="checkbox"
                  class="custom-control-input"
                  :value="p.napmId" />
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description"></span>
              </label>
            </td>
            <td>
              <div class="cell-product">
                <div class="img-wrap">
                  <img
                    :src="getMediaImagePath({ folder: (p.mainImage) ? p.mainImage : p.folderName, size: 36 })"
                    class="img-fluid" />
                </div>
                <div class="pod-name">
                  <router-link :to="{name: 'productEdit', params: {id: p.napmId}}">
                    {{ p.productName }}
                  </router-link>
                  <div>
                    <router-link
                      v-if="p.plattProductId !== 0"
                      class="banner-label"
                      :to="{name: 'productEdit', params: {id: p.napmId}}">
                      Platt
                    </router-link>
                    <span
                      v-if="p.plattProductId !== 0 && p.rexelProductId !== 0"
                      class="text-seperator">-</span>
                    <router-link
                      v-if="p.rexelProductId !== 0"
                      class="banner-label"
                      :to="{name: 'productEdit', params: {id: p.napmId}}">
                      Rexel
                    </router-link>
                  </div>
                </div>
              </div>
            </td>
            <td>{{ p.upc }}</td>
            <td>{{ p.napmId }}</td>
          </tr>
        </tbody>
        <tfoot v-if="selectedProducts.length">
          <tr>
            <td
              colspan="4"
              class="text-right">
              <h6 class="mb-0">
                <span class="badge badge-danger">
                  {{ selectedProducts.length }} {{ productText }} Selected For Removal
                </span>
              </h6>
            </td>
          </tr>
        </tfoot>
      </table>
      <div
        v-else
        class="alert alert-primary-outline mt-4">
        <i class="material-icons">
          info_outline
        </i> No products have been associated with this {{ details.mediaType }}.
      </div>
    </section>
    <modal
      ref="mediaDetailsModal"
      :icon="modal.icon"
      :title="modal.title"
      :blocking="true">
      <div v-if="modal.list">
        <div class="alert alert-danger-outline">
          {{ modal.message }}
        </div>
        <table
          v-if="details.products.length"
          class="table table-bordered mt-3">
          <thead>
            <th
              colspan="2"
              class="pl-5">
              Product Name
            </th>
            <th>UPC</th>
            <th>NapmID</th>
          </thead>
          <tbody>
            <tr
              v-for="p in details.products"
              :key="`detelte-prod-${p.napmId}`">
              <td>
                <img :src="getMediaImagePath({ folder: p.mainImage, size: 36 })" />
              </td>
              <td>{{ p.productName }}</td>
              <td>{{ p.upc }}</td>
              <td>{{ p.napmId }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h6
        v-else
        class="success-message">
        {{ modal.message }}
      </h6>
      <div
        slot="button"
        class="modal-actions">
        <button
          type="button"
          class="btn btn-link"
          @click="$refs.mediaDetailsModal.close()">
          Close
        </button>
        <button
          v-if="modal.list"
          type="button"
          class="btn btn-danger"
          :disabled="loading"
          @click="deleteItem()">
          {{ loading ? 'Removing...' : 'Confirm Delete' }}
        </button>
        <button
          v-else
          type="button"
          class="btn btn-primary"
          @click="$refs.mediaDetailsModal.close()">
          Done
        </button>
      </div>
    </modal>
    <cropper
      :open.sync="reCrop"
      :path="downloadUrl"
      :folder="details.mainImage"
      :media-id="details.mediaId"
      :name="fileName"
      @replace="updateDetails($event)"></cropper>
    <play-video
      :open.sync="showVideo"
      :src.sync="videoSrc"></play-video>
  </div>
</template>
<script type="text/javascript">
import axios from 'axios'
import ReCrop from './recrop.vue'
import PlayVideo from './playVideo.vue'
import MediaIcon from '../mediaIcon.vue'
import Modal from 'components/modals/sweet.vue'
import PageTitle from 'components/pageTitle.vue'
import AutocompleteInput from '@/components/products/search/AutocompleteInput'
import NewMediaSection from '../upload/uploadSingle.vue'
import MediaFilePathMixin from '@/views/media/types/MediaFilePath.mixin'
import PermissionsMixin from 'mixins/Permissions.mixin'
import { EventBus } from '../bus'

export default {
  name: 'MediaDetails',
  components: {
    modal: Modal,
    cropper: ReCrop,
    'play-video': PlayVideo,
    'page-title': PageTitle,
    'media-icon': MediaIcon,
    autocomplete: AutocompleteInput,
    'new-media': NewMediaSection
  },
  mixins: [MediaFilePathMixin],
  beforeRouteEnter (to, from, next) {
    const mediaType = parseInt(to.params.type)
    next(vm => {
      to.meta.spinner()
      switch (mediaType) {
        case 1:
          vm.type = 'images'
          break
        case 4:
          vm.type = 'videos'
          break
        case 5:
          vm.type = 'documents'
          break
        default:
          break
      }
      vm.getDetails(vm.type, to.params.id)
      vm.isDocument = mediaType === 5
    })
  },
  beforeRouteUpdate (to, from, next) {
    const mediaType = parseInt(to.params.type)
    switch (mediaType) {
      case 1:
        this.type = 'images'
        break
      case 4:
        this.type = 'videos'
        break
      case 5:
        this.type = 'documents'
        break
      default:
        break
    }
    to.meta.spinner()
    this.getDetails(this.type, to.params.id)
    this.isDocument = mediaType === 5
    next()
  },
  beforeRouteLeave (to, from, next) {
    this.replaceMedia = false
    if (!this.$refs.length) {
      next()
    } else if (this.$refs.mediaDetailsModal.is_open) {
      this.$refs.mediaDetailsModal.close()
    }
    next()
  },
  data () {
    return {
      url: '',
      details: {},
      active: true,
      videoSrc: {},
      reCrop: false,
      loading: false,
      mediaType: null,
      type: '',
      isDocument: null,
      originalId: null,
      originalFolderName: null,
      originalMediaType: null,
      showVideo: false,
      manufacturers: [],
      documentTypes: [],
      selectedProducts: [],
      replaceMedia: false,
      modal: {
        title: '',
        icon: null,
        list: false,
        message: ''
      },
      replacementRequest: {},
      originalProductIds: []
    }
  },
  computed: {
    fileName () {
      return this.details.fileName ? this.details.fileName : `${this.details.name}${this.details.fileExtension}`
    },
    isAdmin () {
      return this.$store.getters.hasPermissionForAnyBanner(PermissionsMixin.data.Roles.admin)
    },
    language () {
      return this.$store.getters.languageId
    },
    mediaLanguage () {
      // Used for getting the name of the language associated with the media currently being viewed
      const languages = this.$store.getters.languageOptions
      return this.details.languageId > 0 ? languages[this.details.languageId - 1].name : ''
    },
    productText () {
      return this.selectedProducts.length === 0 || this.selectedProducts.length > 1 ? 'Products' : 'Product'
    },
    downloadUrl () {
      if (this.isDocument || this.details.mediaTypeId === 4) {
        return this.getMediaFilePath({
          folder: this.details.folderName,
          ext: this.details.fileExtension,
          file: this.details.fileName
        })
      } else {
        return this.getMediaImagePath({ folder: this.details.folderName })
      }
    }
  },
  watch: {
    language (value, oldValue) {
      if (value !== oldValue && this.isDocument) {
        this.changeLanguage(value)
      }
    }
  },
  mounted () {
    EventBus.$on('mediaReplace', mediaItem => {
      this.details = {
        ...mediaItem,
        mfrId: this.details.mfrId,
        products: this.details.products
      }

      this.replacementRequest = {
        oldId: this.originalId,
        newId: mediaItem.mediaId,
        languageId: this.language
      }
    })
    EventBus.$on('mediaReplaceCancel', mediaItem => {
      this.replaceMedia = false
    })
  },
  methods: {
    getDetails (type, id) {
      axios.all([
        axios.get('/api/manufacturers'),
        axios.get(`/api/media/${type}/${id}?languageId=${this.language}`),
        axios.get(`/api/media/documents/types?languageId=${this.language}`)
      ]).then(axios.spread((manufacturers, details, documentTypes) => {
        this.active = true
        this.details = details.data
        this.manufacturers = manufacturers.data
        this.documentTypes = documentTypes.data
        this.$store.dispatch('setSpinner', false)
        this.originalId = this.details.mediaId
        this.originalFolderName = this.details.folderName
        this.originalMediaType = this.details.mediaType
        this.originalProductIds = this.details.products.map(prod => prod.napmId)
      })).catch(err => {
        console.warn(err)
        this.active = false
        this.$store.dispatch('setSpinner', false)
        this.$toasted.error('Error Getting Details', {
          theme: 'primary',
          position: 'bottom-center',
          icon: 'error_outline',
          action: [
            {
              text: 'Close',
              onClick: (e, toast) => {
                toast.goAway(0)
                this.$router.back()
              }
            }
          ]
        })
      })
    },
    changeLanguage (lang) {
      // 6/14/2019 - The current behavior when changing language selections is incorrect, but we don't know if we wish to have this functionality at all at this time.
      // Until a decision is made, we are removing any functionality surrounding changing the language selector, as the current behavior is incorrect.

      // axios.all([
      //   axios.get(`/api/media/documents/${this.details.mediaId}/${lang}`),
      //   axios.get(`/api/media/documents/types?languageId=${lang}`)
      // ]).then(axios.spread((details, documentTypes) => {
      //   this.details = details.data
      //   this.documentTypes = documentTypes.data
      // }))
    },
    addProduct (product) {
      const newProd = {
        napmId: product.napmId,
        productName: this.productName(product),
        upc: product.upc,
        mainImage: product.mainImage
      }
      // Add check to prevent existing product from being added to avoid duplicate key error on the frontend.
      if (!this.details.products.filter(p => (p.napmId === product.napmId)).length > 0) {
        this.details.products.push(newProd)
      }
    },
    productName (product) {
      return `${product.manufacturer.name} ${product.catNum} ${product.shortDescription}`
    },
    removeProducts () {
      const prods = this.details.products.filter(prod => (!this.selectedProducts.includes(prod.napmId)))
      this.selectedProducts = []
      this.$set(this.details, 'products', prods)
    },
    confirmDelete () {
      const isPlural = this.details.products.length === 0 || this.details.products.length > 1
      this.modal.list = true
      this.modal.icon = null
      this.modal.title = 'Confirm Delete'
      this.modal.message = `Are you sure you want to delete this ${this.details.mediaType}? This ${this.details.mediaType} is attached to ${this.details.products.length} ${isPlural ? 'products' : 'product'}, deleting this will erase it from ${isPlural ? 'those items' : 'that item'}.`
      this.$refs.mediaDetailsModal.open()
    },
    deleteItem () {
      axios.delete(`/api/media/${this.type}/${this.details.mediaId}`)
        .then(res => {
          this.$router.back()
        })
        .catch(err => {
          this.modal.title = 'Error'
          this.modal.message = 'There was an error deleting this image'
          this.modal.list = false
          console.log(err)
        })
    },
    updateDetails (media) {
      this.details = {
        ...media,
        products: this.details.products
      }

      this.replacementRequest = {
        oldId: this.originalId,
        newId: media.mediaId,
        languageId: this.language
      }

      this.$router.replace({
        name: 'mediaDetails',
        params: {
          id: this.replacementRequest.newId,
          type: this.isDocument ? 5 : 1
        }
      })
    },
    saveDetails (deleteItem) {
      let data = null
      const products = this.details.products.map(prod => prod.napmId)
      this.active = !deleteItem
      if (this.isDocument) {
        data = {
          documentId: this.details.mediaId,
          documentTypeId: this.details.documentTypeId,
          name: this.details.name,
          productIds: products,
          originalProductIds: this.originalProductIds,
          mfrId: this.details.mfrId ? this.details.mfrId : null,
          languageId: this.language,
          activeInd: this.active
        }
      } else {
        data = {
          mediaId: this.details.mediaId,
          mediaTypeId: this.details.mediaTypeId,
          name: this.details.name,
          productIds: products,
          mfrId: this.details.mfrId ? this.details.mfrId : null,
          languageId: this.language,
          activeInd: this.active
        }
      }
      axios.put(`/api/media/${this.type}/${this.details.mediaId}`, data)
        .then(res => {
          this.modal.list = false
          if (this.active) {
            this.originalProductIds = this.details.products.map(prod => prod.napmId)
            this.modal.icon = 'success'
            this.modal.title = `Edit ${this.details.mediaType}`
            this.modal.message = 'Successfully Saved!'
            if (this.replacementRequest.newId) {
              this.replaceFile()
              return
            }
          } else {
            this.modal.icon = 'error'
            this.modal.title = `Delete ${this.details.mediaType}`
            this.modal.message = `${this.details.mediaType} Removed.`
          }
          this.$refs.mediaDetailsModal.open()
          setTimeout(() => {
            this.$refs.mediaDetailsModal._animateIcon()
          }, 50)
        })
        .catch(err => {
          this.modal.title = 'Error'
          this.modal.message = err.response.status === 400 ? err.response.data : 'There was an error updating this document.'
          this.modal.list = false
          this.$refs.mediaDetailsModal.open()
          setTimeout(() => {
            this.$refs.mediaDetailsModal._animateIcon()
          }, 50)
          console.log(err)
        })
    },
    playVideo (id) {
      axios.get(`/api/media/videos/${id}/urls`).then(res => {
        if (res.status === 204) {
          this.$toasted.error('Error finding video to play.', {
            theme: 'primary',
            position: 'bottom-center',
            icon: 'error_outline',
            action: [
              {
                text: 'Close',
                onClick: (e, toast) => toast.goAway(0)
              }
            ]
          })
        } else {
          this.videoSrc = res.data
          this.showVideo = true
        }
      })
    },
    replaceFile () {
      axios.patch(`/api/media/${this.type}`, this.replacementRequest)
        .then(res => {
          this.$router.replace({
            name: 'mediaDetails',
            params: {
              id: this.replacementRequest.newId,
              type: this.isDocument ? 5 : 1
            }
          })
        })
      this.replaceMedia = false
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/extra/mixins/flexbox.scss";
@import "../../../assets/scss/bootstrap-custom-variables.scss";

.btn-group {
  .btn-save {
    min-width: 8.25rem;
    text-align: left;
  }
  .dropdown-menu {
    padding: 0;
    .btn-delete {
      padding: 0.5rem 0.75rem;
      &:hover {
        background: $teal;
        color: $white;
      }
    }
  }
}

.media-preview {
  position: relative;
  margin-bottom: 1rem;
  .btn-raised {
    position: absolute;
    padding: 0.325rem;
    border-radius: 50%;
    background: #eee;
    position: absolute;
    height: 2.5rem;
    width: 2.5rem;
    text-align: center;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px rgba(0, 0, 0, 0.14),
      0 1px 14px rgba(0, 0, 0, 0.12) !important;
    color: #333;
    &:hover {
      cursor: pointer;
      color: $white;
      background: $brand-primary;
    }
    &.download {
      bottom: 0;
      right: 0;
    }
    &.crop {
      bottom: 7rem;
      right: 0;
    }
    &.replace {
      bottom: 3.5rem;
      right: 0;
    }
    .material-icons {
      font-size: 1.5rem;
    }
  }
  .media-replace {
    position: relative;
    max-width: 320px;
    margin: 0 auto;
    .btn-danger {
      position: absolute;
      padding: 0.15rem 0.35rem;
      z-index: 3;
      top: 0;
      right: -8px;
      i {
        position: relative;
        top: -1px;
        line-height: 1;
      }
    }
  }
  .media-video {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    a {
      cursor: pointer;
      height: 20rem;
      width: 100%;
      max-width: 20rem;
      background: $gray-200;
      border: 1px solid $gray-300;
      @include flexbox;
      @include align-items(center);
      @include justify-content(center);
      &:hover {
        background: $gray-300;
      }
      img {
        width: 5rem;
        height: 5rem;
      }
    }
  }
  .media-image {
    text-align: center;
    img {
      margin: 0 auto;
      display: block;
      border: 1px solid $gray-300;
    }
  }
}

.product-list {
  margin-top: 0.75rem;
  .table {
    margin: 1rem 0;
    tr.row-product {
      td.cell-check {
        width: 3rem;
        text-align: center;
        .custom-control {
          margin-right: 0;
          min-height: 1rem;
          padding-left: 1rem;
          .custom-control-input:checked ~ .custom-control-indicator {
            background-color: $brand-danger;
          }
        }
      }
      div.cell-product {
        @include flexbox;
        @include align-items(center);
        .img-wrap {
          border: 1px solid #eceeef;
          min-width: 2.5rem;
          height: 2.5rem;
          @include flexbox;
          @include align-items(center);
          @include justify-content(center);
        }
      }
    }
  }
}
.table tr td {
  vertical-align: middle;
  &:first-child {
    text-align: center;
  }
}
.success-message {
  text-align: center;
  font-weight: bold;
  color: $gray-700;
}
.modal-actions {
  @include flexbox;
  @include justify-content(space-between);
  padding-right: 0.75rem;
  .btn:not(.btn-link) {
    min-width: 7rem;
  }
}
.dropdown-menu {
  top: -2px !important;
  right: 2px;
}
.banner-label {
  color: #777;
}
.text-separator {
  color: #777;
}
div .banner-label:last-child {
  margin-right: 0;
}
</style>
