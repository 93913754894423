<template>
  <div>
    <page-title title="Add New Manufacturer"></page-title>
    <div
      class="spinner"
      :class="{'show-spinner': showSpinner}"></div>
    <div class="row">
      <div class="col">
        <section class="vendor-info">
          <div class="mb-4">
            <div>
              <h5 class="d-inline">
                Manufacturer Information
              </h5>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label class="form-control-label required">
                  Manufacturer Name
                </label>
                <input
                  v-model="vendor.name"
                  type="text"
                  class="form-control" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label class="form-control-label required">
                  Website Name
                </label>
                <input
                  v-model="vendor.websiteLink"
                  type="text"
                  class="form-control" />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="form-control-label">
              Description
            </label>
            <textarea
              v-model="vendor.description"
              type="text"
              class="form-control"
              rows="6"></textarea>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label">
                  Parent Manufacturer
                </label>
                <select
                  v-model="vendor.parentMfrId"
                  class="form-control">
                  <option :value="null"></option>
                  <option
                    v-for="v in vendorsList"
                    :key="v.id"
                    :value="v.id">
                    {{ v.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label">
                  Manufacturer Alias
                </label>
                <select
                  v-model="vendor.aliasId"
                  class="form-control">
                  <option :value="null"></option>
                  <option
                    v-for="v in vendorsList"
                    :key="v.id"
                    :value="v.id">
                    {{ v.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="custom-control custom-checkbox">
              <input
                v-model="vendor.noUpc"
                type="checkbox"
                class="custom-control-input" />
              <span class="custom-control-indicator"></span>
              <span class="custom-control-description">
                Products for this manufacturer do not contain a UPC
              </span>
            </label>
          </div>
        </section>
        <section class="vendor-brand-mapping">
          <div
            id="plattMapping"
            role="tabpanel"
            class="tab-pane active form-group clearfix">
            <div class="mb-4">
              <h5>Platt Mapping</h5>
            </div>
            <table
              v-if="brandMappingsPlatt.length || newBrandMappingsPlatt.length"
              class="table table-bordered platt-mappings">
              <thead>
                <tr>
                  <th>SEC</th>
                  <th>GRP</th>
                  <th>CAT</th>
                  <th>ThreeDigits</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="map in brandMappingsPlatt"
                  :key="'platt-' + map.sec + '-' + map.grp + '-' + map.cat + '-' + map.threeDigits">
                  <td>{{ map.section }}</td>
                  <td>{{ map.group }}</td>
                  <td>{{ map.category }}</td>
                  <td>{{ map.threeDigits }}</td>
                </tr>
                <template v-if="newBrandMappingsPlatt.length">
                  <tr
                    v-for="(map, index) in newBrandMappingsPlatt"
                    :key="index"
                    class="new-platt-mapping">
                    <td>
                      <input
                        v-model="map.section"
                        class="w-100"
                        type="text"
                        maxlength="3"
                        :class="{ 'has-error': $v.newBrandMappingsPlatt.$each[index].section.$invalid }"
                        @keydown="errorMessages = []" />
                    </td>
                    <td>
                      <input
                        v-model="map.group"
                        class="w-100"
                        type="text"
                        maxlength="3"
                        :class="{ 'has-error': $v.newBrandMappingsPlatt.$each[index].group.$invalid }"
                        @keydown="errorMessages = []" />
                    </td>
                    <td>
                      <input
                        v-model="map.category"
                        class="w-100"
                        type="text"
                        maxlength="3"
                        :class="{ 'has-error': $v.newBrandMappingsPlatt.$each[index].category.$invalid }"
                        @keydown="errorMessages = []" />
                    </td>
                    <td class="position-relative">
                      <input
                        v-model="map.threeDigits"
                        class="w-100"
                        type="text"
                        maxlength="3"
                        :class="{ 'has-error': $v.newBrandMappingsPlatt.$each[index].threeDigits.$invalid }"
                        @keydown="errorMessages = []" />
                      <i
                        class="material-icons text-danger position-absolute remove-platt-map"
                        title="Remove this row"
                        @click="removePlattMap(index)">
                        cancel
                      </i>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            <ul
              v-if="$v.newBrandMappingsPlatt.$invalid || errorMessages.length"
              class="error-messages">
              <li
                v-if="$v.newBrandMappingsPlatt.$invalid"
                class="error-message">
                Codes must be either 3 characters or blank
              </li>
              <li
                v-for="(error, index) in errorMessages"
                :key="index"
                class="error-message">
                {{ error }}
              </li>
            </ul>
            <button
              type="button"
              class="btn btn-primary btn-sm"
              :class="{'float-right': brandMappingsPlatt.length || newBrandMappingsPlatt.length }"
              @click="addPlattMap()">
              {{ !brandMappingsPlatt.length && !newBrandMappingsPlatt.length ? 'Create Platt Mapping' : 'Add New' }}
            </button>
          </div>
          <div
            v-if="brandMappingsRexel"
            id="rexelMapping"
            class="rexel-mappings form-group">
            <div class="mb-4">
              <h5>Rexel Mapping</h5>
            </div>
            <div class="form-group">
              <label class="form-control-label">
                PriceLine
              </label>
              <div class="input-group">
                <input
                  v-model="newRexelMap"
                  type="text"
                  class="form-control rexel-add-control" />
                <span class="input-group-btn">
                  <button
                    type="button"
                    class="btn btn-primary rexel-add-control"
                    @click="addRexelMap(newRexelMap)">
                    Add
                  </button>
                </span>
              </div>
            </div>
            <div v-if="brandMappingsRexel.length || newBrandMappingsRexel.length">
              <span
                v-for="(map, index) in brandMappingsRexel"
                :key="'map-' + index"
                class="badge text-info btn btn-outline-info rounded mr-1 p-2 disabled">
                {{ map.priceLine }}
              </span>
              <template v-if="newBrandMappingsRexel.length">
                <span
                  v-for="(map, index) in newBrandMappingsRexel"
                  :key="`rxl-${map + '-' + index}`"
                  class="badge text-info btn btn-outline-info rounded mr-1 p-2">
                  {{ map.priceLine }}
                  <i
                    class="material-icons pointer text-danger"
                    @click="removeRexelMap(index)">
                    clear
                  </i>
                </span>
              </template>
            </div>
          </div>
          <div
            v-if="brandMappingsPartsSuperCenter"
            id="partsSuperCenterMapping"
            class="rexel-mappings form-group">
            <div class="mb-4">
              <h5>Parts Super Center Mapping</h5>
            </div>
            <div class="form-group">
              <label class="form-control-label">
                PriceLine
              </label>
              <div class="input-group">
                <input
                  v-model="newPartsSuperCenterMap"
                  type="text"
                  class="form-control rexel-add-control" />
                <span class="input-group-btn">
                  <button
                    type="button"
                    class="btn btn-primary rexel-add-control"
                    @click="addPartsSuperCenterMap(newPartsSuperCenterMap)">
                    Add
                  </button>
                </span>
              </div>
            </div>
            <div v-if="brandMappingsPartsSuperCenter.length || newBrandMappingsPartsSuperCenter.length">
              <span
                v-for="(map, index) in brandMappingsPartsSuperCenter"
                :key="'map-' + index"
                class="badge text-info btn btn-outline-info rounded mr-1 p-2 disabled">
                {{ map.priceLine }}
              </span>
              <template v-if="newBrandMappingsPartsSuperCenter.length">
                <span
                  v-for="(map, index) in newBrandMappingsPartsSuperCenter"
                  :key="`rxl-${map + '-' + index}`"
                  class="badge text-info btn btn-outline-info rounded mr-1 p-2">
                  {{ map.priceLine }}
                  <i
                    class="material-icons pointer text-danger"
                    @click="removePartsSuperCenterMap(index)">
                    clear
                  </i>
                </span>
              </template>
            </div>
          </div>
          <div
            v-if="brandMappingsRexelCanada"
            id="rexelCanadaMapping"
            class="rexel-mappings form-group">
            <div class="mb-4">
              <h5>Rexel Canada Mapping</h5>
            </div>
            <div class="form-group">
              <label class="form-control-label">
                BrandId
              </label>
              <div class="input-group">
                <input
                  v-model="newRexelCanadaMap"
                  type="text"
                  class="form-control rexel-add-control"
                  maxlength="50" />
                <span class="input-group-btn">
                  <button
                    type="button"
                    class="btn btn-primary rexel-add-control"
                    @click="addRexelCanadaMap(newRexelCanadaMap)">
                    Add
                  </button>
                </span>
              </div>
            </div>
            <div v-if="brandMappingsRexelCanada.length || newBrandMappingsRexelCanada.length">
              <span
                v-for="(map, index) in brandMappingsRexelCanada"
                :key="'map-' + index"
                class="badge text-info btn btn-outline-info rounded mr-1 p-2 disabled">
                {{ map.brandId }}
              </span>
              <template v-if="newBrandMappingsRexelCanada.length">
                <span
                  v-for="(map, index) in newBrandMappingsRexelCanada"
                  :key="`rxl-${map + '-' + index}`"
                  class="badge text-info btn btn-outline-info rounded mr-1 p-2">
                  {{ map.brandId }}
                  <i
                    class="material-icons pointer text-danger"
                    @click="removeRexelCanadaMap(index)">
                    clear
                  </i>
                </span>
              </template>
            </div>
            <ul
              v-if="$v.newBrandMappingsPlatt.$invalid || errorMessages.length"
              class="error-messages">
              <li
                v-if="$v.newBrandMappingsPlatt.$invalid"
                class="error-message">
                Codes must be either 3 characters or blank
              </li>
              <li
                v-for="(error, index) in errorMessages"
                :key="index"
                class="error-message">
                {{ error }}
              </li>
            </ul>
          </div>
        </section>
      </div>
      <div class="col-md-auto">
        <div class="form-group mb-5">
          <button
            type="button"
            class="btn btn-primary btn-block"
            :disabled="!checkValidation"
            @click="commitChanges()">
            Create Manufacturer
          </button>
        </div>
        <section class="image-header mb-5">
          <div class="mb-4">
            <h5>Manufacturer Logo</h5>
          </div>
          <div
            v-if="imagePreview != ''"
            class="d-flex mb-3">
            <div class="img-wrap text-center w-100">
              <div class="img-fluid img-large img-inner">
                <button
                  type="button"
                  class="btn btn-circle position-absolute"
                  aria-label="Close"
                  @click.prevent="removeImage()">
                  <span aria-hidden="true">
                    &times;
                  </span>
                </button>
                <img :src="imagePreview" />
              </div>
            </div>
          </div>
          <template v-if="!imageReset">
            <div class="form-group">
              <label
                for="file1"
                class="col-form-label form-control-label">
                Upload Image
              </label>
              <div
                class="custom-file"
                style="display: block;">
                <input
                  class="custom-file-input form-control"
                  name="file1"
                  type="file"
                  @change="imageValidate($event)" />
                <span class="custom-file-control custom-file-btn"></span>
              </div>
            </div>
          </template>
        </section>
        <section class="vendor-company-access">
          <div class="mb-4">
            <h5>
              Banner Access<span class="text-danger required">
                required
              </span>
              <span
                v-tooltip="'Set which companies are allowed to edit this product'"
                class="info">
                <i class="material-icons">
                  info_outline
                </i>
              </span>
            </h5>
          </div>
          <ul class="list-group">
            <li
              v-for="c in bannerAccess"
              :key="'company-perm-' + c.bannerId"
              class="list-group-item">
              <toggle
                :id="'company-toggle-' + c.bannerId"
                color="primary"
                :is-checked.sync="c.access"
                class="permissions-option"></toggle>
              <label
                class="toggle-lbl"
                :class="{'active': c.access}">
                {{ c.name }}
              </label>
            </li>
          </ul>
        </section>
      </div>
      <div class="col-md-12 text-right mt-4">
        <div class="form-group">
          <button
            type="button"
            class="btn btn-primary"
            :disabled="!checkValidation"
            @click="commitChanges()">
            Create Manufacturer
          </button>
        </div>
      </div>
    </div>
    <modal
      ref="uploadMfrImage"
      :title="modal.title"
      :icon="modal.icon"
      :animate-icon="modal.animate"
      :blocking="true"
      :large="true"
      :no-padding="true"
      @close="resetState()">
      <template v-if="uploadMessage">
        <p class="lead text-center">
          {{ modal.message }}
        </p>
      </template>
      <template v-else-if="imageUpload">
        <uploading :loading="loading"></uploading>
        <div :class="{'uploading-media': loading}">
          <cropper
            ref="imgCropper"
            :enable-exif="true"
            :show-zoomer="false"
            :show-buttons="true"
            :zoom-level="imgZoom"
            :mouse-wheel-zoom="true"
            :enforce-boundary="true"
            :enable-orientation="true"
            :boundary="{height: 450, width: 768}"
            :viewport="{ width: 400, height: 400, type: 'square' }"
            @update="imageCropUpdate($event)"
            @rotate="rotated = $event">
          </cropper>
        </div>
      </template>
      <template v-else>
        <upload-zone
          :document="false"
          :error="modal.error"
          :message="modal.message"
          :message-type="modal.messageType"
          @upload="imageValidate($event)"></upload-zone>
      </template>
      <div
        slot="button"
        class="modal-actions">
        <template v-if="uploadMessage && !loading">
          <span class="spacer"></span>
          <button
            type="button"
            class="btn btn-gray-alt"
            @click="resetState()">
            Done
          </button>
        </template>
        <template v-else>
          <button
            type="button"
            class="btn btn-link"
            :disabled="loading"
            @click="resetState()">
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            :disabled="loading"
            @click="imageSave($event)">
            {{ loading ? 'Sending Image' : 'Upload Image' }}
          </button>
        </template>
      </div>
    </modal>
    <modal
      ref="saveModalRef"
      :icon="saveModal.icon"
      @close="resetSaveModal">
      <div class="text-center">
        {{ saveModal.message }}
      </div>
    </modal>
  </div>
</template>
<script type="text/javascript">
import axios from 'axios'
import ModalComponent from 'components/modals/sweet.vue'
import TitleComponent from 'components/pageTitle.vue'
// import MultiselectComponent from 'components/inputs/multiselect.vue'
import ToggleComponent from 'components/inputs/toggleSwitch.vue'
import Cropper from 'components/crop'
import Uploading from '@/views/media/upload/uploading'
import MediaFileTypesMixin from '@/views/media/types/MediaFileTypes.mixin'
import UploadZone from '@/views/media/upload/uploadZone.vue'
import { minLength } from 'vuelidate/lib/validators'

export default {
  name: 'ManufacturerDetail',
  components: {
    'page-title': TitleComponent,
    // multiselect: MultiselectComponent,
    modal: ModalComponent,
    toggle: ToggleComponent,
    cropper: Cropper,
    uploading: Uploading,
    'upload-zone': UploadZone
  },
  mixins: [MediaFileTypesMixin],
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.showSpinner = true
      vm.getActiveVendors()
    })
  },
  data () {
    return {
      showSpinner: true,
      vendor: {},
      vendorsList: [],
      bannerAccess: [
        {
          name: 'Platt',
          bannerId: 1,
          access: false
        },
        {
          name: 'Rexel',
          bannerId: 2,
          access: false
        },
        {
          name: 'Gexpro',
          bannerId: 3,
          access: false
        },
        {
          name: 'Rexel CA',
          bannerId: 4,
          access: false
        }
      ],
      newBrandMappingsPlatt: [],
      newBrandMappingsRexel: [],
      newBrandMappingsPartsSuperCenter: [],
      newBrandMappingsRexelCanada: [],
      newRexelMap: '',
      newPartsSuperCenterMap: '',
      newRexelCanadaMap: '',
      brandMappingsPlatt: [],
      brandMappingsRexel: [],
      brandMappingsPartsSuperCenter: [],
      brandMappingsRexelCanada: [],
      modal: {
        title: 'Upload Manufacturer Logo Image',
        icon: null,
        animate: false,
        message: '',
        messageType: '',
        error: false
      },
      saveModal: {
        message: 'Save Successful',
        icon: 'success'
      },
      saveModalOpen: false,
      file: null,
      fileName: null,
      image: null,
      imageUpload: false,
      uploadModal: false,
      uploadMessage: false,
      rotated: 0,
      imgZoom: 1,
      imgPoints: {},
      loading: false,
      imageReset: false,
      imagePreview: '',
      newImage: {
        folder: '',
        mid: ''
      },
      editing: false,
      errorMessages: [],
      errorsRexelMap: [],
      redirectMfrId: null
    }
  },
  computed: {
    checkValidation () {
      return (this.bannerAccess.some(banner => banner.access === true)) && this.vendor.name && this.vendor.websiteLink
    }
  },
  watch: {
    uploadModal (value) {
      if (value) {
        this.$refs.uploadMfrImage.open()
        this.editing = false
      } else {
        this.editing = true
        this.$refs.uploadMfrImage.close()
        this.resetState()
      }
    }
  },
  methods: {
    addPlattMap () {
      this.newBrandMappingsPlatt.push({})
    },
    addRexelMap () {
      if (this.newRexelMap !== '') {
        this.errorMessages = []
        this.newBrandMappingsRexel.push({ priceLine: this.newRexelMap })
        this.newRexelMap = ''
      }
    },
    addPartsSuperCenterMap () {
      if (this.newPartsSuperCenterMap !== '') {
        this.errorMessages = []
        this.newBrandMappingsPartsSuperCenter.push({ priceLine: this.newPartsSuperCenterMap })
        this.newPartsSuperCenterMap = ''
      }
    },
    addRexelCanadaMap () {
      if (this.newRexelCanadaMap !== '') {
        this.errorMessages = []
        this.newBrandMappingsRexelCanada.push({ brandId: this.newRexelCanadaMap })
        this.newRexelCanadaMap = ''
      }
    },
    removePlattMap (index) {
      this.newBrandMappingsPlatt.splice(index, 1)
      this.errorMessages = []
    },
    removeRexelMap (index) {
      this.newBrandMappingsRexel.splice(index, 1)
      this.errorMessages = []
    },
    removePartsSuperCenterMap (index) {
      this.newBrandMappingsPartsSuperCenter.splice(index, 1)
      this.errorMessages = []
    },
    removeRexelCanadaMap (index) {
      this.newBrandMappingsRexelCanada.splice(index, 1)
      this.errorMessages = []
    },
    setImagePreview (folder) {
      const mainImage = (folder) || this.vendor.mainImage
      let url = ''
      if (mainImage === null) {
        url = `https://rexel-cdn.com/products/${this.vendor.name}.jpg?i=0EA7A6C5-7DD2-4482-9229-D628D3AFAE9D&f=150`
      } else {
        url = `https://rexel-cdn.com/Products/${this.vendor.name}.jpg?i=${mainImage}&f=150`
      }
      this.imagePreview = url
    },
    getActiveVendors () {
      this.$http.get('/api/manufacturers')
        .then(res => {
          this.vendorsList = res.data.map(v => { return { id: v.id, name: v.name } })
          this.showSpinner = false
        }).catch(e => console.warn(e))
    },
    commitChanges () {
      let mid = this.vendor.mediaId
      let folder = this.vendor.folderName
      if (this.newImage.folder !== '' && this.newImage.mid !== '') {
        mid = this.newImage.mid
        folder = this.newImage.folder
      }
      if (this.newBrandMappingsPlatt.length) {
        this.newBrandMappingsPlatt.forEach((map, i) => {
          const isEmpty = Object.keys(map).length === 0 && map.constructor === Object
          if (isEmpty) {
            this.newBrandMappingsPlatt.splice(i, 1)
          }
        })
      }
      const data = {
        name: this.vendor.name,
        websiteLink: this.vendor.websiteLink,
        description: this.vendor.description,
        bannerAccess: this.bannerAccess,
        aliasId: this.vendor.aliasId,
        parentMfrId: this.vendor.parentMfrId,
        mediaId: mid,
        mainImage: folder,
        noUpc: this.vendor.noUpc ? this.vendor.noUpc : false
      }
      if (this.newBrandMappingsPlatt.length) {
        // convert platt mapping values to uppercase
        data.brandMappingsPlatt = this.newBrandMappingsPlatt.map((item, i) => {
          const { section, group, category, threeDigits } = item
          const data = {}
          if (section) data.section = section.toUpperCase()
          if (group) data.group = group.toUpperCase()
          if (category) data.category = category.toUpperCase()
          if (threeDigits) data.threeDigits = threeDigits.toUpperCase()

          return data
        })
      }
      if (this.newBrandMappingsRexel.length) {
        data.brandMappingsRexel = this.newBrandMappingsRexel
      }
      if (this.newBrandMappingsPartsSuperCenter.length) {
        data.brandMappingsPartsSuperCenter = this.newBrandMappingsPartsSuperCenter
      }
      if (this.newBrandMappingsRexelCanada.length) {
        data.brandMappingsRexelCanada = this.newBrandMappingsRexelCanada
      }
      if (!this.$v.newBrandMappingsPlatt.$invalid) {
        this.$http.post('/api/manufacturers', data).then(res => {
          this.$emit('commit', { vendor: data, index: this.index })
          if (res.data === 0) {
            this.saveModal.message = 'Failed to create manufacturer.'
            this.saveModal.icon = 'error'
          }
          this.$refs.saveModalRef.open()
          this.redirectMfrId = res.data
        })
          .catch((error) => {
            const status = error.response.status
            const messages = error.response.data

            this.saveModal.message = 'Failed to save changes'
            this.saveModal.icon = 'error'
            this.$refs.saveModalRef.open()
            if (status === 400 && messages.length) {
              messages.forEach((message) => {
                this.errorMessages.push(message)
              })
            }
          })
      }
    },
    imageValidate (event) {
      this.file = event.target.files[0]
      this.fileName = event.target.files[0].name
      if (this.file.type.indexOf('image/') > -1) {
        this.uploadModal = true
        this.imageInit(event)
      } else {
        // throw error
      }
    },
    imageInit (event) {
      const image = new Image()
      const reader = new FileReader()
      reader.onload = (file) => {
        this.image = file.target.result
        image.src = file.target.result
      }
      reader.onloadend = (file) => {
        if (image.width >= 400 && image.height >= 400) {
          this.imageUpload = true
          this.$nextTick(() => {
            this.$refs.imgCropper.bind({
              url: this.image
            })
          })
        } else {
          this.imageUpload = false
          this.modal.error = true
          this.modal.messageType = 'ERROR'
          this.modal.message = 'Minimum size required is 400px by 400px.'
        }
      }
      reader.readAsDataURL(event ? this.file : false)
    },
    imageCropUpdate (event) {
      this.imgZoom = event.zoom
      this.imgPoints = event.points
    },
    imageSave (event) {
      event.preventDefault()
      const data = new FormData()
      data.append('file', this.file)
      data.append('x1', this.imgPoints[0])
      data.append('y1', this.imgPoints[1])
      data.append('x2', this.imgPoints[2])
      data.append('y2', this.imgPoints[3])
      data.append('fileName', this.fileName)
      data.append('rotate', this.rotated ? this.rotated * 90 : null)

      this.loading = true
      axios.post('/api/media/images', data, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
        const result = res.data
        this.loading = false
        this.uploadMessage = true
        if (res.status === 200) {
          this.newImage.mid = result.mediaId
          this.newImage.folder = result.folderName
          this.setImagePreview(result.folderName)
          this.modal.message = 'Manufacturer image has been successfully uploaded and updated.'
          this.modal.icon = 'success'

          this.$refs.uploadMfrImage.close()
          this.resetState()
        } else {
          this.modal.message = 'Errors proccessing Manufacturer image. Please try again later or contact your administrator.'
          this.modal.icon = 'error'
        }
        setTimeout(() => {
          this.$refs.uploadMfrImage._animateIcon()
        }, 50)
      })
    },
    removeImage () {
      this.vendor.folderName = null
      this.setImagePreview()
    },
    resetState () {
      this.modal = {
        title: 'Upload Manufacturer Image',
        icon: null,
        animate: false,
        message: '',
        messageType: '',
        error: false
      }
      this.file = this.image = null
      this.imageUpload = this.uploadModal = this.uploadMessage = false
      this.rotated = 0
      this.imgZoom = 1
      this.imgPoints = {}
      this.imageReset = true
      this.$nextTick(() => {
        // resetting image input value
        this.imageReset = false
      })
    },
    resetSaveModal () {
      const mfrId = this.redirectMfrId
      this.$emit('close')
      this.saveModal = {
        message: 'Save Successful',
        icon: 'success'
      }
      if (mfrId) {
        this.$router.push({
          name: 'manufacturerDetail',
          params: { id: mfrId }
        })
      }
    }
  },
  validations: {
    newBrandMappingsPlatt: {
      $each: {
        section: {
          minLength: minLength(3)
        },
        group: {
          minLength: minLength(3)
        },
        category: {
          minLength: minLength(3)
        },
        threeDigits: {
          minLength: minLength(3)
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/scss/extra/mixins/flexbox.scss';
@import '../../assets/scss/bootstrap-custom-variables.scss';

.btn-circle {
  width: 25px;
  height: 25px;
  padding: 6px 0;
  border-radius: 15px;
  text-align: center;
  font-size: 24px;
  line-height: 0.5;
  color: #fff;
  top: -12px;
  right: -12px;
  background-color: $brand-danger;
}

.form-control-label {
  position: relative;
  display: block;
  &.required:before {
    content: 'required';
    text-transform: uppercase;
    color: $brand-danger;
    font-size: 0.75rem;
    right: 0;
    top: 0.125rem;
    position: absolute;
  }
}

.custom-file-btn:before {
  content: 'Upload';
  color: #fff;
  background-color: #00416a;
  border-color: #00416a;
}

.custom-file-input:focus ~ .custom-file-btn {
  box-shadow: initial;
}

.img-inner {
  border: solid 1px #c9c9c9;
  padding: 10px;
  position: relative;
}

.remove-platt-map {
  top: 0;
  right: 0;
  cursor: pointer;
}

.required {
  text-transform: uppercase;
  font-size: 0.75rem;
}

.rexel-mappings {
  margin-top: 30px;
}
</style>
