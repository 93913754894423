<template>
  <section id="categories">
    <div class="section-heading">
      <h6>Categories</h6>
    </div>
    <div class="section-body">
      <div class="container ml-0 pl-0">
        <div class="row">
          <div class="col-auto">
            <label class="form-control-label">
              Sub Category Id
              <span
                v-if="isBadSubCategoryId"
                class="alert-danger col"
                role="alert">
                Invalid Sub Category Id
              </span>
            </label>
            <input
              v-model="userEnteredSubCategoryId"
              type="number"
              class="form-control"
              @keyup.enter="searchSubCategory()" />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label class="form-control-label">
              Section
            </label>
            <select
              v-if="cats.sectionList.length != 0"
              v-model="cats.sectionId"
              class="form-control"
              @change="sectionSelected($event)">
              <option :value="null">
                -- Select --
              </option>
              <option
                v-for="s in cats.sectionList"
                :key="`option-${s.id}`"
                :value="s.id">
                {{ s.name }}
              </option>
            </select>
          </div>
          <div class="col">
            <label class="form-control-label">
              Group
            </label>
            <select
              v-if="cats.groupList.length != 0"
              v-model="cats.groupId"
              class="form-control"
              @change="groupSelected($event)">
              <option :value="null">
                -- Select --
              </option>
              <option
                v-for="g in cats.groupList"
                :key="`option-${g.id}`"
                :value="g.id">
                {{ g.name }}
              </option>
            </select>
            <empty-category v-else></empty-category>
          </div>
          <div class="col">
            <label class="form-control-label">
              Category
            </label>
            <select
              v-if="cats.categoryList.length != 0"
              v-model="cats.categoryId"
              class="form-control"
              @change="categorySelected($event)">
              <option :value="null">
                -- Select --
              </option>
              <option
                v-for="c in cats.categoryList"
                :key="`option-${c.id}`"
                :value="c.id">
                {{ c.name }}
              </option>
            </select>
            <empty-category v-else></empty-category>
          </div>
          <div class="col">
            <router-link
              v-if="categoryParams"
              :to="{ name: 'productSearch', query: categoryParams}">
              Sub Category ({{ cats.subCategoryId }})
            </router-link>
            <label
              v-else
              class="form-control-label">
              Sub Category {{ cats.subCategoryId > 0 ? (cats.subCategoryId) : '' }}
            </label>
            <select
              v-if="cats.subCategoryList.length != 0"
              v-model="cats.subCategoryId"
              class="form-control"
              :required="cats.sectionId">
              <option :value="null">
                -- Select --
              </option>
              <option
                v-for="s in cats.subCategoryList"
                :key="`option-${s.id}`"
                :value="s.id">
                {{ s.name }}
              </option>
            </select>
            <empty-category v-else></empty-category>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script type="text/javascript">
import axios from 'axios'

const categoryEmptyComponent = {
  template: '<select class="form-control"><option :value="value">{{ name }}</option></select>',
  props: {
    name: {
      type: String,
      default: '-- Select --'
    },
    value: {
      type: Number,
      default: null
    }
  }
}

export default {
  components: {
    'empty-category': categoryEmptyComponent
  },
  props: {
    lang: Number,
    hasIdw: Number,
    loaded: Boolean,
    load: {
      type: Boolean,
      default: false
    },
    cats: {
      type: Object,
      required: true,
      default: () => {
        return {
          sectionId: null,
          groupId: null,
          categoryId: null,
          subCategoryId: null,
          sectionList: [],
          groupList: [],
          categoryList: [],
          subCategoryList: [],
          isValid: true
        }
      }
    }
  },
  data () {
    return {
      userEnteredSubCategoryId: null,
      isBadSubCategoryId: false
    }
  },
  computed: {
    categoryParams () {
      if (this.cats.sectionId > 0 && this.cats.subCategoryId > 0 && this.cats.groupId > 0 && this.cats.subCategoryId > 0 &&
        this.cats.sectionList.length > 0 && this.cats.groupList.length > 0 && this.cats.categoryList.length > 0 && this.cats.subCategoryList.length > 0) {
        return {
          section: this.cats.sectionList.find(x => x.id === this.cats.sectionId).name,
          group: this.cats.groupList.find(x => x.id === this.cats.groupId).name,
          category: this.cats.categoryList.find(x => x.id === this.cats.categoryId).name,
          subCategory: this.cats.subCategoryList.find(x => x.id === this.cats.subCategoryId).name
        }
      } else {
        return null
      }
    }
  },
  watch: {
    lang () {
      this.getSections()
    },
    load (value) {
      if (value) {
        this.getSections()
      }

      if (this.cats.sectionId) {
        this.getGroups()
        this.getCategories()
        this.getSubCategories()
      }
    },
    'cats.sectionId' (value) {
      if ((value && this.cats.subCategoryId) || (!value && !this.cats.subCategoryId)) {
        this.cats.isValid = true
      } else {
        this.cats.isValid = false
      }
    },
    'cats.subCategoryId' (value) {
      if (!value && this.cats.sectionId) {
        this.cats.isValid = false
      } else if (value) {
        this.cats.isValid = true
      }
    },
    userEnteredSubCategoryId (value) {
      if (!value) {
        this.isBadSubCategoryId = false
      }
    }
  },
  methods: {
    fetchData (id) {
      const query = (id)
        ? `/api/categories/${id}/children?languageId=${this.lang}`
        : `/api/categories/children?languageId=${this.lang}`
      return axios.get(query)
        .then(res => {
          return res.data
        })
        .catch(e => {
          console.error(e)
        })
    },
    fetchPredessorsData (id) {
      const query = `/api/categories/${id}/predecessors`
      return axios.get(query)
        .then(res => {
          return res.data
        })
        .catch(e => {
          console.error(e)
        })
    },
    getSections () {
      this.fetchData()
        .then(res => {
          this.cats.sectionList = res
        })
    },
    getGroups () {
      this.fetchData(this.cats.sectionId)
        .then(res => {
          this.cats.groupList = res
        })
    },
    getCategories () {
      this.fetchData(this.cats.groupId)
        .then(res => {
          this.cats.categoryList = res
        })
    },
    getSubCategories () {
      this.fetchData(this.cats.categoryId)
        .then(res => {
          this.cats.subCategoryList = res
        })
    },
    sectionSelected (e) {
      if (e.isTrusted) {
        this.cats.groupId = null
        this.cats.categoryId = null
        this.cats.subCategoryId = null
        this.cats.groupList = []
        this.cats.categoryList = []
        this.cats.subCategoryList = []
        if (this.cats.sectionId) this.getGroups()
      }
    },
    groupSelected (e) {
      if (e.isTrusted) {
        this.cats.categoryId = null
        this.cats.subCategoryId = null
        this.cats.categoryList = []
        this.cats.subCategoryList = []
        this.getCategories()
      }
    },
    categorySelected (e) {
      if (e.isTrusted) {
        this.cats.subCategoryId = null
        this.cats.subCategoryList = []
        this.getSubCategories()
      }
    },
    searchSubCategory () {
      this.isBadSubCategoryId = false
      if (this.userEnteredSubCategoryId && Number.isInteger(parseInt(this.userEnteredSubCategoryId))) {
        this.fetchPredessorsData(this.userEnteredSubCategoryId)
          .then(res => {
            if (res.length !== 4) {
              this.isBadSubCategoryId = true
              return
            }
            const predessors = res
            this.cats.sectionId = predessors[3].id
            this.cats.groupId = predessors[2].id
            this.getGroups()
            this.cats.categoryId = predessors[1].id
            this.getCategories()
            this.cats.subCategoryId = predessors[0].id
            this.getSubCategories()
          })
      } else {
        this.isBadSubCategoryId = true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/scss/extra/mixins/flexbox.scss';
.section-heading {
  margin-top: 0.5rem;
  .btn-idw {
    right: 0;
    top: 0.5rem;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
