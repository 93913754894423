<template>
  <div :class="size">
    <svg
      viewBox="0 0 63 72"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      :class="classAttr">
      <defs>
        <rect
          id="path-1"
          x="9"
          y="47"
          width="46"
          height="17"
          rx="8.5" />
        <filter
          id="filter-2"
          x="-15.2%"
          y="-29.4%"
          width="130.4%"
          height="182.4%"
          filterUnits="objectBoundingBox">
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation="2"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
            type="matrix"
            in="shadowBlurOuter1" />
        </filter>
      </defs>
      <g
        id="Symbols"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd">
        <g id="ic-/-mp4">
          <g>
            <rect
              id="Rectangle-3"
              fill="#EEEEEE"
              x="0"
              y="0"
              width="63"
              height="72"
              rx="8" />
            <g id="Rectangle-4">
              <use
                fill="black"
                fill-opacity="1"
                filter="url(#filter-2)"
                xlink:href="#path-1" />
              <use
                fill="#13BBB6"
                fill-rule="evenodd"
                xlink:href="#path-1" />
            </g>
            <text
              id="MP4"
              font-size="12"
              font-weight="bold"
              fill="#FFFFFF">
              <tspan
                x="19.866"
                y="59">
                {{ classAttr }}
              </tspan>
            </text>
            <path
              id="Combined-Shape"
              d="M40.102439,19 L45.3780488,19 L45.3780488,37.375 C45.3780488,38.81875 44.1910366,40 42.7402439,40 L21.6378049,40 C20.1870122,40 19,38.81875 19,37.375 L19,21.625 C19,20.18125 20.1870122,19 21.6378049,19 L22.9567073,19 L25.5945122,24.25 L29.5512195,24.25 L26.9134146,19 L29.5512195,19 L32.1890244,24.25 L36.1457317,24.25 L33.5079268,19 L36.1457317,19 L38.7835366,24.25 L42.7402439,24.25 L40.102439,19 Z M29.5512195,28.1875 L29.5512195,36.0625 L35.7689024,32.125 L29.5512195,28.1875 Z"
              fill="#C9C9C9" />
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'VideoIcon',
  props: {
    classAttr: {
      type: String,
      default: '',
      required: true
    },
    size: {
      type: String,
      default: 'icon-size',
      required: false
    }
  }
}
</script>
<style scoped>
  .icon-size {
    margin: 0  auto 1rem;
    height: 4.6875rem;
    width: 4.6875rem;
  }
</style>
