<template>
  <div>
    <modal
      v-show="editing"
      ref="editVendorModal"
      title="Edit Manufacturer"
      @close="resetModal()">
      <div>
        <section class="image-header">
          <div class="image-box">
            <img :src="imagePreview" />
          </div>
          <div class="title">
            <h4>
              {{ vendorDetails.mfrName }}
            </h4>
          </div>
        </section>
        <div class="image-upload">
          <span>Upload Image</span>
          <template v-if="!imageReset">
            <input
              type="file"
              :accept="validMediaFileTypes.images"
              @change="imageValidate($event)" />
          </template>
        </div>
        <section class="vendor-info">
          <div
            class="alert"
            :class="{'alert-info': vendorDetails.activeInd, 'alert-danger': !vendorDetails.activeInd}">
            <label
              class="custom-control custom-checkbox disable-checkbox"
              @click.prevent>
              <input
                v-model="vendorDetails.activeInd"
                type="checkbox"
                class="custom-control-input"
                @click.prevent />
              <span class="custom-control-indicator"></span>
              <span class="custom-control-description">
                Manufacturer {{ vendorDetails.activeInd ? 'Active' : 'Not Active' }}
              </span>
            </label>
          </div>
          <div class="form-group">
            <label class="form-control-label required">
              Manufacturer Name
            </label>
            <input
              v-model="vendorDetails.mfrName"
              type="text"
              class="form-control" />
          </div>
          <div class="alert alert-dark">
            <label class="custom-control custom-checkbox">
              <input
                v-model="vendorDetails.noUpc"
                type="checkbox"
                class="custom-control-input" />
              <span class="custom-control-indicator"></span>
              <span class="custom-control-description">
                No UPC
              </span>
            </label>
          </div>
          <div class="form-group">
            <label class="form-control-label required">
              Website
            </label>
            <div class="input-group">
              <span class="input-group-addon">
                <i class="material-icons">
                  http
                </i>
              </span>
              <input
                v-model="vendorDetails.websiteLink"
                type="text"
                class="form-control" />
            </div>
          </div>
          <div class="form-group">
            <label class="form-control-label">
              Description
            </label>
            <textarea
              v-model="vendorDetails.description"
              type="text"
              class="form-control"
              rows="4"></textarea>
          </div>

          <label class="form-control-label">
            Brand Mapping
          </label>
          <ul
            class="nav nav-tabs"
            role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                data-toggle="tab"
                href="#plattMapping"
                role="tab">
                Platt
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                data-toggle="tab"
                href="#rexelMapping"
                role="tab">
                Rexel
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                data-toggle="tab"
                href="#partsSuperCenterMapping"
                role="tab">
                Parts Super Center
              </a>
            </li>
          </ul>
          <div class="tab-content">
            <div
              id="plattMapping"
              role="tabpanel"
              class="tab-pane active form-group clearfix">
              <table
                v-if="brandMappingsPlatt.length || newBrandMappingsPlatt.length"
                class="table table-bordered platt-mappings">
                <thead>
                  <tr>
                    <th>SEC</th>
                    <th>GRP</th>
                    <th>CAT</th>
                    <th>ThreeDigits</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="map in brandMappingsPlatt"
                    :key="'platt-' + map.sec + '-' + map.grp + '-' + map.cat + '-' + map.threeDigits">
                    <td>{{ map.sec }}</td>
                    <td>{{ map.grp }}</td>
                    <td>{{ map.cat }}</td>
                    <td>{{ map.threeDigits }}</td>
                  </tr>
                  <template v-if="newBrandMappingsPlatt.length">
                    <tr
                      v-for="(map, index) in newBrandMappingsPlatt"
                      :key="index"
                      class="new-platt-mapping">
                      <td>
                        <input
                          v-model="map.sec"
                          type="text"
                          maxlength="3"
                          :class="{ 'has-error': $v.newBrandMappingsPlatt.$each[index].sec.$invalid }"
                          @keydown="errorMessages = []" />
                      </td>
                      <td>
                        <input
                          v-model="map.grp"
                          type="text"
                          maxlength="3"
                          :class="{ 'has-error': $v.newBrandMappingsPlatt.$each[index].grp.$invalid }"
                          @keydown="errorMessages = []" />
                      </td>
                      <td>
                        <input
                          v-model="map.cat"
                          type="text"
                          maxlength="3"
                          :class="{ 'has-error': $v.newBrandMappingsPlatt.$each[index].cat.$invalid }"
                          @keydown="errorMessages = []" />
                      </td>
                      <td>
                        <input
                          v-model="map.threeDigits"
                          type="text"
                          maxlength="3"
                          :class="{ 'has-error': $v.newBrandMappingsPlatt.$each[index].threeDigits.$invalid }"
                          @keydown="errorMessages = []" />
                        <i
                          class="material-icons"
                          title="Remove this row"
                          @click="removePlattMap(index)">
                          cancel
                        </i>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
              <ul
                v-if="$v.newBrandMappingsPlatt.$invalid || errorMessages.length"
                class="error-messages">
                <li
                  v-if="$v.newBrandMappingsPlatt.$invalid"
                  class="error-message">
                  Codes must be either 3 characters or blank
                </li>
                <li
                  v-for="(error, index) in errorMessages"
                  :key="index"
                  class="error-message">
                  {{ error }}
                </li>
              </ul>
              <button
                type="button"
                class="btn btn-primary btn-sm"
                :class="{'float-right': brandMappingsPlatt.length || newBrandMappingsPlatt.length }"
                @click="addPlattMap()">
                {{ !brandMappingsPlatt.length && !newBrandMappingsPlatt.length ? 'Create Platt Mapping' : 'Add New' }}
              </button>
            </div>
            <div
              v-if="brandMappingsRexel"
              id="rexelMapping"
              role="tabpanel"
              class="rexel-mappings tab-pane form-group clearfix">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>
                      PriceLine
                      <input
                        v-model="newRexelMap"
                        type="text"
                        class="form-control rexel-add-control" />
                      <button
                        type="button"
                        class="btn btn-primary rexel-add-control"
                        @click="addRexelMap(newRexelMap)">
                        Add
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="brandMappingsRexel.length || newBrandMappingsRexel.length">
                    <td>
                      <span
                        v-for="(map, index) in brandMappingsRexel"
                        :key="'map-' + index"
                        class="badge badge-default">
                        {{ map.priceLine }}
                      </span>
                      <template v-if="newBrandMappingsRexel.length">
                        <span
                          v-for="(map, index) in newBrandMappingsRexel"
                          :key="`rxl-${map + '-' + index}`"
                          class="badge badge-primary">{{ map.priceLine }}
                          <i
                            class="material-icons"
                            @click="removeRexelMap(index)">
                            clear
                          </i>
                        </span>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
              <ul
                v-if="$v.newBrandMappingsPlatt.$invalid || errorMessages.length"
                class="error-messages">
                <li
                  v-if="$v.newBrandMappingsPlatt.$invalid"
                  class="error-message">
                  Codes must be either 3 characters or blank
                </li>
                <li
                  v-for="(error, index) in errorMessages"
                  :key="index"
                  class="error-message">
                  {{ error }}
                </li>
              </ul>
            </div>
            <div
              v-if="brandMappingsPartsSuperCenter"
              id="partsSuperCenterMapping"
              role="tabpanel"
              class="rexel-mappings tab-pane form-group clearfix">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>
                      PriceLine
                      <input
                        v-model="newPartsSuperCenterMap"
                        type="text"
                        class="form-control rexel-add-control" />
                      <button
                        type="button"
                        class="btn btn-primary rexel-add-control"
                        @click="addPartsSuperCenterMap(newPartsSuperCenterMap)">
                        Add
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="brandMappingsPartsSuperCenter.length || newBrandMappingsPartsSuperCenter.length">
                    <td>
                      <span
                        v-for="(map, index) in brandMappingsPartsSuperCenter"
                        :key="'map-' + index"
                        class="badge badge-default">
                        {{ map.priceLine }}
                      </span>
                      <template v-if="newBrandMappingsPartsSuperCenter.length">
                        <span
                          v-for="(map, index) in newBrandMappingsPartsSuperCenter"
                          :key="`rxl-${map + '-' + index}`"
                          class="badge badge-primary">{{ map.priceLine }}
                          <i
                            class="material-icons"
                            @click="removePartsSuperCenterMap(index)">
                            clear
                          </i>
                        </span>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
        <section class="vendor-company-access">
          <label>
            <span>Company Access</span>
            <span class="info">
              <i class="material-icons">
                info_outline
              </i>
            </span>
          </label>

          <ul class="list-group">
            <li
              v-for="c in companyAccess"
              :key="'company-perm-' + c.companyId"
              class="list-group-item">
              <toggle
                :id="'company-toggle-' + c.companyId"
                color="primary"
                :is-checked.sync="c.access"
                class="permissions-option"></toggle>
              <label
                class="toggle-lbl"
                :class="{'active': c.access}">
                {{ c.name }}
              </label>
            </li>
          </ul>
        </section>
      </div>
      <div
        slot="button"
        class="footer-btns">
        <button
          type="button"
          class="btn btn-primary"
          @click="commitChanges()">
          Save Changes
        </button>
        <button
          type="button"
          class="btn btn-link"
          @click="resetModal()">
          Cancel
        </button>
      </div>
    </modal>
    <modal
      ref="uploadMfrImage"
      :title="modal.title"
      :icon="modal.icon"
      :animate-icon="modal.animate"
      :blocking="true"
      :large="true"
      :no-padding="true"
      @close="resetState()">
      <template v-if="uploadMessage">
        <p class="lead text-center">
          {{ modal.message }}
        </p>
      </template>
      <template v-else-if="imageUpload">
        <uploading :loading="loading"></uploading>
        <div :class="{'uploading-media': loading}">
          <cropper
            ref="imgCropper"
            :enable-exif="true"
            :show-zoomer="false"
            :show-buttons="true"
            :zoom-level="imgZoom"
            :mouse-wheel-zoom="true"
            :enforce-boundary="true"
            :enable-orientation="true"
            :boundary="{height: 450, width: 768}"
            :viewport="{ width: 400, height: 400, type: 'square' }"
            @update="imageCropUpdate($event)"
            @rotate="rotated = $event">
          </cropper>
        </div>
      </template>
      <template v-else>
        <upload-zone
          :document="false"
          :error="modal.error"
          :message="modal.message"
          :message-type="modal.messageType"
          @upload="imageValidate($event)"></upload-zone>
      </template>
      <div
        slot="button"
        class="modal-actions">
        <template v-if="uploadMessage && !loading">
          <span class="spacer"></span>
          <button
            type="button"
            class="btn btn-gray-alt"
            @click="resetState()">
            Done
          </button>
        </template>
        <template v-else>
          <button
            type="button"
            class="btn btn-link"
            :disabled="loading"
            @click="resetState()">
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            :disabled="loading"
            @click="imageSave($event)">
            {{ loading ? 'Sending Image' : 'Upload Image' }}
          </button>
        </template>
      </div>
    </modal>
  </div>
</template>
<script type="text/javascript">
import axios from 'axios'
import ModalComponent from 'components/modals/sweet.vue'
import ToggleComponent from 'components/inputs/toggleSwitch.vue'
import Cropper from 'components/crop'
import Uploading from '@/views/media/upload/uploading'
import MediaFileTypesMixin from '@/views/media/types/MediaFileTypes.mixin'
import UploadZone from '@/views/media/upload/uploadZone.vue'
import { minLength } from 'vuelidate/lib/validators'

export default {
  components: {
    modal: ModalComponent,
    toggle: ToggleComponent,
    cropper: Cropper,
    uploading: Uploading,
    'upload-zone': UploadZone
  },
  mixins: [MediaFileTypesMixin],
  props: {
    vendor: Object,
    show: Boolean,
    index: Number
  },
  data () {
    return {
      vendorDetails: {},
      companyAccess: [],
      newBrandMappingsPlatt: [],
      newBrandMappingsRexel: [],
      newBrandMappingsPartsSuperCenter: [],
      newRexelMap: '',
      newPartsSuperCenterMap: '',
      brandMappingsPlatt: [],
      brandMappingsRexel: [],
      brandMappingsPartsSuperCenter: [],
      modal: {
        title: 'Upload Manufacturer Logo Image',
        icon: null,
        animate: false,
        message: '',
        messageType: '',
        error: false
      },
      file: null,
      fileName: null,
      image: null,
      imageUpload: false,
      uploadModal: false,
      uploadMessage: false,
      rotated: 0,
      imgZoom: 1,
      imgPoints: {},
      loading: false,
      imageReset: false,
      imagePreview: '',
      newImage: {
        folder: '',
        mid: ''
      },
      editing: false,
      errorMessages: [],
      errorsRexelMap: []
    }
  },
  computed: {},
  watch: {
    uploadModal (value) {
      if (value) {
        this.$refs.uploadMfrImage.open()
        this.editing = false
      } else {
        this.editing = true
        this.$refs.uploadMfrImage.close()
        this.resetState()
      }
    },
    show (value) {
      if (value) {
        this.editing = true
        this.$refs.editVendorModal.open()
        this.getManufacturerDetails()
      } else {
        this.$refs.editVendorModal.close()
      }
    }
  },
  methods: {
    addPlattMap () {
      this.newBrandMappingsPlatt.push({})
    },
    addRexelMap () {
      if (this.newRexelMap !== '') {
        this.errorMessages = []
        this.newBrandMappingsRexel.push({ priceLine: this.newRexelMap })
        this.newRexelMap = ''
      }
    },
    addPartsSuperCenterMap () {
      if (this.newPartsSuperCenterMap !== '') {
        this.errorMessages = []
        this.newBrandMappingsPartsSuperCenter.push({ priceLine: this.newPartsSuperCenterMap })
        this.newPartsSuperCenterMap = ''
      }
    },
    removePlattMap (index) {
      this.newBrandMappingsPlatt.splice(index, 1)
      this.errorMessages = []
    },
    removeRexelMap (index) {
      this.newBrandMappingsRexel.splice(index, 1)
      this.errorMessages = []
    },
    removePartsSuperCenterMap (index) {
      this.newBrandMappingsPartsSuperCenter.splice(index, 1)
      this.errorMessages = []
    },
    setImagePreview (folder) {
      const folderName = (folder) || this.vendorDetails.folderName
      let url = ''
      if (folderName === null) {
        url = `https://rexel-cdn.com/products/${this.vendorDetails.mfrName}.jpg?i=0EA7A6C5-7DD2-4482-9229-D628D3AFAE9D&f=150`
      } else {
        url = `https://rexel-cdn.com/Products/${this.vendorDetails.mfrName}.jpg?i=${folderName}&f=150`
      }
      this.imagePreview = url
    },
    resetModal () {
      this.$emit('update:show', false)
      this.companyAccess = []
      this.vendorDetails = {}
      this.brandMappingsPlatt = []
      this.brandMappingsRexel = []
      this.brandMappingsPartsSuperCenter = []
      this.newImage.mid = ''
      this.newImage.folder = ''
      this.imagePreview = ''
      this.newBrandMappingsPlatt = []
      this.newBrandMappingsRexel = []
      this.newBrandMappingsPartsSuperCenter = []
      this.newRexelMap = ''
      this.newPartsSuperCenterMap = ''
    },
    getManufacturerDetails () {
      this.$http.get(`/api/manufacturers/${this.vendor.mfrId}`)
        .then(res => {
          const response = res.data
          this.vendorDetails = response.details
          this.brandMappingsPlatt = response.brandMappingsPlatt
          this.brandMappingsRexel = response.brandMappingsRexel
          this.brandMappingsPartsSuperCenter = response.brandMappingsPartsSuperCenter
          const companies = this.$store.getters.companyList
          this.companyAccess = response.companyAccess.map(c1 => {
            return Object.assign(
              c1,
              companies.find(c2 => {
                return c2 && c1.bannerId === c2.companyId
              })
            )
          })
          if (!this.imagePreview) {
            this.setImagePreview()
          }
        })
    },
    commitChanges () {
      let mid = this.vendorDetails.mediaId
      let folder = this.vendorDetails.mainImage
      if (this.newImage.folder !== '' && this.newImage.mid !== '') {
        mid = this.newImage.mid
        folder = this.newImage.folder
      }
      if (this.newBrandMappingsPlatt.length) {
        this.newBrandMappingsPlatt.forEach((map, i) => {
          const isEmpty = Object.keys(map).length === 0 && map.constructor === Object
          if (isEmpty) {
            this.newBrandMappingsPlatt.splice(i, 1)
          }
        })
      }
      const data = {
        Id: this.vendorDetails.id,
        Name: this.vendorDetails.name,
        websiteLink: this.vendorDetails.websiteLink,
        description: this.vendorDetails.description,
        activeInd: this.vendorDetails.activeInd,
        logo: this.vendorDetails.logo,
        bannerAccess: this.bannerAccess,
        aliasId: this.vendorDetails.aliasId,
        parentMfrId: this.vendorDetails.parentMfrId,
        webMfrId: this.vendorDetails.webMfrId,
        mediaId: mid,
        mainImage: folder,
        noUpc: this.vendorDetails.noUpc
      }
      if (this.newBrandMappingsPlatt.length) {
        // convert platt mapping values to uppercase
        data.brandMappingsPlatt = this.newBrandMappingsPlatt.map((item, i) => {
          const data = {}
          if (item.section) data.section = item.section.toUpperCase()
          if (item.group) data.group = item.group.toUpperCase()
          if (item.category) data.category = item.category.toUpperCase()
          if (item.threeDigits) data.threeDigits = item.threeDigits.toUpperCase()
          return data
        })
      }
      if (this.newBrandMappingsRexel.length) {
        data.brandMappingsRexel = this.newBrandMappingsRexel
      }
      if (this.newBrandMappingsPartsSuperCenter.length) {
        data.brandMappingsPartsSuperCenter = this.newBrandMappingsPartsSuperCenter
      }
      if (!this.$v.newBrandMappingsPlatt.$invalid) {
        this.$http.put('/api/manufacturers', data)
          .then(res => {
            this.$emit('commit', { vendor: data, index: this.index })
            this.resetModal()
          })
          .catch((error) => {
            const status = error.response.status
            const messages = error.response.data
            if (status === 400 && messages.length) {
              messages.forEach((message) => {
                this.errorMessages.push(message)
              })
            }
          })
      }
    },
    imageValidate (event) {
      this.file = event.target.files[0]
      this.fileName = event.target.files[0].name
      if (this.file.type.indexOf('image/') > -1) {
        this.uploadModal = true
        this.imageInit(event)
      } else {
        // throw error
      }
    },
    imageInit (event) {
      const image = new Image()
      const reader = new FileReader()
      reader.onload = (file) => {
        this.image = file.target.result
        image.src = file.target.result
      }
      reader.onloadend = (file) => {
        if (image.width >= 400 && image.height >= 400) {
          this.imageUpload = true
          this.$nextTick(() => {
            this.$refs.imgCropper.bind({
              url: this.image
            })
          })
        } else {
          this.imageUpload = false
          this.modal.error = true
          this.modal.messageType = 'ERROR'
          this.modal.message = 'Minimum size required is 400px by 400px.'
        }
      }
      reader.readAsDataURL(event ? this.file : false)
    },
    imageCropUpdate (event) {
      this.imgZoom = event.zoom
      this.imgPoints = event.points
    },
    imageSave (event) {
      event.preventDefault()
      const data = new FormData()
      data.append('file', this.file)
      data.append('x1', this.imgPoints[0])
      data.append('y1', this.imgPoints[1])
      data.append('x2', this.imgPoints[2])
      data.append('y2', this.imgPoints[3])
      data.append('fileName', this.fileName)
      data.append('rotate', this.rotated ? this.rotated * 90 : null)

      this.loading = true
      axios.post('/api/media/images', data, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
        const result = res.data
        this.loading = false
        this.uploadMessage = true
        if (res.status === 200) {
          this.newImage.mid = result.mediaId
          this.newImage.folder = result.folderName
          this.setImagePreview(result.folderName)
          this.modal.message = 'Manufacturer image has been successfully uploaded and updated.'
          this.modal.icon = 'success'
          this.$emit('update:show', true)
          this.$refs.uploadMfrImage.close()
          this.resetState()
        } else {
          this.modal.message = 'Errors proccessing Manufacturer image. Please try again later or contact your administrator.'
          this.modal.icon = 'error'
        }
        setTimeout(() => {
          this.$refs.uploadMfrImage._animateIcon()
        }, 50)
      })
    },
    resetState () {
      this.modal = {
        title: 'Upload Manufacturer Image',
        icon: null,
        animate: false,
        message: '',
        messageType: '',
        error: false
      }
      this.file = null
      this.image = null
      this.imageUpload = false
      this.uploadModal = false
      this.uploadMessage = false
      this.rotated = 0
      this.imgZoom = 1
      this.imgPoints = {}
      this.imageReset = true
      this.$nextTick(() => {
        // resetting image input value
        this.imageReset = false
      })
    }
  },
  validations: {
    newBrandMappingsPlatt: {
      $each: {
        sec: {
          minLength: minLength(3)
        },
        grp: {
          minLength: minLength(3)
        },
        cat: {
          minLength: minLength(3)
        },
        threeDigits: {
          minLength: minLength(3)
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/scss/extra/mixins/flexbox.scss';
@import '../../assets/scss/bootstrap-custom-variables.scss';
.image-header {
  @include flexbox;
  .image-box {
    width: 8rem;
    height: 8rem;
    padding: 0.5rem;
    margin-bottom: 0.25rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(center);
    > img {
      width: 100%;
      max-width: 7rem;
      max-height: 7rem;
    }
  }
  .title {
    @include flex(1, 1, 75%);
    @include flexbox;
    @include align-items(center);
    h4 {
      margin: 0 2rem;
    }
  }
  .change-image {
    padding: 1rem 2rem;
    @include align-self(center);
  }
}
.tab-content {
  border: 1px solid #ddd;
  border-top: none;
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  .form-group,
  table {
    margin-bottom: 0;
  }
}
#plattMapping {
  button.float-right {
    margin-top: 0.25rem;
  }
}
.platt-mappings {
  th {
    padding: 0.5rem 0.75rem;
  }
  margin-bottom: 0.5rem;
  td {
    width: 25%;
  }
  .new-platt-mapping {
    td {
      padding: 0.5rem;
      input {
        padding: 0.25rem;
        width: 100%;
        border: 1px solid #ccc;
        text-transform: uppercase;
      }
      &:last-child {
        input {
          display: inline-block;
          width: 78%;
        }
        i.material-icons {
          position: relative;
          font-size: 21px;
          color: #d00;
          transition: color 0.3s ease;
          top: -2px;
          &:hover {
            cursor: pointer;
            color: #900;
          }
        }
      }
    }
  }
}
.form-control-label {
  margin-top: 1.25rem;
  margin-bottom: 0.25rem;
}
.rexel-mappings {
  th {
    padding: 0.5rem 0.75rem;
    button {
      padding: 0.4rem 1.35rem;
    }
  }
  .badge {
    font-size: 14px;
    padding: 0.3rem 0.5rem;
    border-radius: 15px;
    margin: 3px;
    line-height: 14px;
    height: 25px;
    &.badge-default {
      background: #fff;
      border: 1px solid #555;
      color: #555;
    }
    &.badge-primary {
      background: #fff;
      border: 1px solid #00416a;
      color: #00416a;
    }
    i.material-icons {
      position: relative;
      top: -2px;
      font-size: 14px;
      color: #d00;
      transition: color 0.3s ease;
      &:hover {
        cursor: pointer;
        color: #900;
      }
    }
  }
  .form-control {
    width: 200px;
    display: inline-block;
    padding: 0.4rem;
    margin-left: 10.9rem;
  }
  button {
    position: relative;
    top: -3px;
    font-size: 14px;
    padding: 0.4rem;
  }
}
.image-upload {
  position: relative;
  font-size: 0.8125rem;
  letter-spacing: 0.75px;
  color: $gray-600;
  padding-top: 0.25rem;
  text-decoration: underline;
  width: 8rem;
  text-align: center;
  margin-bottom: 1.25rem;
  &:hover {
    cursor: pointer;
    color: $brand-primary;
  }
  input {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
.vendor-info {
  .custom-checkbox {
    cursor: default;
    margin-bottom: 0;
  }
  .disable-checkbox {
    pointer-events: none;
  }
  .form-control-label {
    position: relative;
    display: block;
    cursor: default;
    pointer-events: none;
    &.required:before {
      content: 'required';
      text-transform: uppercase;
      color: $brand-danger;
      font-size: 0.75rem;
      right: 0;
      top: 0.125rem;
      position: absolute;
    }
  }
}
.vendor-company-access {
  .list-group {
    .list-group-item {
      border-right: 0;
      border-left: 0;
      padding: 0.5rem 1.25rem;
      @include flexbox;
      @include align-items(center);
      .toggle-lbl {
        padding-left: 1rem;
        color: lighten($gray, 15%);
        font-size: 1rem;
        &.active {
          color: $brand-primary;
        }
      }
    }
  }
}
.footer-btns {
  padding: 0 0.75rem;
  @include flexbox;
  @include justify-content(space-between);
  @include flex-direction(row-reverse);
  .btn-primary {
    min-width: 8.75rem;
  }
}
.has-error {
  border-color: #c00 !important;
}
.error-messages {
  display: inline-block;
  padding: 0;
  margin: 0;
  width: 450px;
  padding-top: 0.5rem;
  .error-message {
    color: #c00;
    list-style-position: inside;
  }
}
</style>
