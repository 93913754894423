<template>
  <div>
    <page-title :title="`Sub Category Product Counts`"></page-title>
    <div
      class="spinner"
      :class="{'show-spinner': showSpinner}"></div>
    <h6>Categories with Greater than <b>{{ currentCount }}</b> Products</h6>
    <div class="search-section">
      <div class="productCount-number-search">
        <input
          v-model="count"
          type="number"
          class="form-control"
          placeholder="Minimun Number of Products"
          @keyup.enter="getAllProductCounts()" />
        <button
          class="btn btn-gray-alt"
          @click="getAllProductCounts()">
          View Results
        </button>
      </div>
    </div>
    <div
      v-if="!showSpinner"
      class="search-section">
      <div class="productCount-search">
        <input
          v-model="search"
          type="text"
          class="form-control"
          placeholder="Search within results" />
        <template>
          <i
            v-show="isEmpty"
            class="material-icons">
            search
          </i>
          <i
            v-show="isDirty"
            class="material-icons times"
            @click="search = ''">
            close
          </i>
        </template>
      </div>
      <div class="add-new-btn">
        <router-link
          tag="button"
          :to="{name: 'manufacturerCreate'}"
          type="button"
          class="btn btn-primary">
          Add New
        </router-link>
      </div>
      <div class="productCount-table-pager">
        <div class="table-viewing-info">
          <label>Viewing {{ currentViewing | numeric }} -
            <span v-if="parseInt(currentViewing) + parseInt(pagination.per_page) - 1 < pagination.total">
              {{ parseInt(currentViewing) + parseInt(pagination.per_page) - 1 | numeric }}
            </span>
            <span v-else>
              {{ pagination.total | numeric }}
            </span> of {{ pagination.total | numeric }}
          </label>
        </div>
        <div class="input-group">
          <span class="input-group-addon">
            Showing:
          </span>
          <select
            v-model="pagination.per_page"
            class="form-control">
            <option
              v-for="o in paginationOptions.pageSizes"
              :key="`pager-${o}`"
              :value="o">
              {{ o }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div
      v-if="productCountList.length"
      class="productCount-list">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>
              <a @click="sortStringValues('section', sortAsc)">SECTION
                <i
                  v-if="sortCol == 'section' && sortAsc == true"
                  class="material-icons">
                  expand_more
                </i>
                <i
                  v-else-if="sortCol == 'section' && sortAsc == false"
                  class="material-icons">
                  expand_less
                </i>
                <i
                  v-else
                  class="material-icons no-sort">
                  code
                </i>
              </a>
            </th>
            <th>
              <a @click="sortStringValues('group', sortAsc)">GROUP
                <i
                  v-if="sortCol == 'group' && sortAsc == true"
                  class="material-icons">
                  expand_more
                </i>
                <i
                  v-else-if="sortCol == 'group' && sortAsc == false"
                  class="material-icons">
                  expand_less
                </i>
                <i
                  v-else
                  class="material-icons no-sort">
                  code
                </i>
              </a>
            </th>
            <th>
              <a @click="sortStringValues('category', sortAsc)">CATEGORY
                <i
                  v-if="sortCol == 'category' && sortAsc == true"
                  class="material-icons">
                  expand_more
                </i>
                <i
                  v-else-if="sortCol == 'category' && sortAsc == false"
                  class="material-icons">
                  expand_less
                </i>
                <i
                  v-else
                  class="material-icons no-sort">
                  code
                </i>
              </a>
            </th>
            <th>
              <a @click="sortStringValues('subCategory', sortAsc)">SUB CATEGORY
                <i
                  v-if="sortCol == 'subCategory' && sortAsc == true"
                  class="material-icons">
                  expand_more
                </i>
                <i
                  v-else-if="sortCol == 'subCategory' && sortAsc == false"
                  class="material-icons">
                  expand_less
                </i>
                <i
                  v-else
                  class="material-icons no-sort">
                  code
                </i>
              </a>
            </th>
            <th>
              <a @click="sortIds('productCount', sortAsc)">Product Count
                <i
                  v-if="sortCol == 'productCount' && sortAsc == true"
                  class="material-icons">
                  expand_more
                </i>
                <i
                  v-else-if="sortCol == 'productCount' && sortAsc == false"
                  class="material-icons">
                  expand_less
                </i>
                <i
                  v-else
                  class="material-icons no-sort">
                  code
                </i>
              </a>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <router-link
            v-for="record in productCountList"
            :key="'productCount-' + record.subCategoryId"
            tag="tr"
            :to="{name: 'reportsSubCategoryProductDetails', params: { id: record.subCategoryId, name: getBreadcrumbPageTitle(record) }}">
            <td>
              {{ record.section }}
            </td>
            <td>
              {{ record.group }}
            </td>
            <td>
              {{ record.category }}
            </td>
            <td>
              {{ record.subCategory }}
            </td>
            <td>
              {{ record.productCount }}
            </td>
            <td class="arrow-col">
              <span class="productCount-arrow">
                <i class="material-icons">
                  keyboard_arrow_right
                </i>
              </span>
            </td>
          </router-link>
        </tbody>
      </table>
    </div>
    <div
      v-else
      class="alert alert-info">
      No Sub Categories match the search term of <strong>"{{ search }}"</strong>. Try changing your search term.
    </div>
    <div
      v-if="!showSpinner && pagination.last_page !== 1"
      class="productCount-pagination">
      <pagination
        :pagination="pagination"
        :callback="searchPagintation"
        :options="paginationOptions"></pagination>
    </div>
  </div>
</template>
<script type="text/javascript">
import axios from 'axios'
import TitleComponent from 'components/pageTitle.vue'
import PaginationComponent from 'components/pagination.vue'

export default {
  name: 'ProductCountsList',
  components: {
    'page-title': TitleComponent,
    pagination: PaginationComponent
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.languageId = to.meta.lang
      if (!vm.productCounts.length) {
        vm.getAllProductCounts()
      }
    })
  },
  data () {
    return {
      productCounts: [],
      sortCol: '',
      sortAsc: false,
      search: '',
      productCountIndex: 0,
      pagination: {
        total: 0,
        per_page: 50, // required
        current_page: 1, // required
        last_page: 0, // required
        from: 0,
        to: 15 // required
      },
      paginationOptions: {
        offset: 5,
        previousText: 'Prev',
        nextText: 'Next',
        alwaysShowPrevNext: true,
        pageSizes: [25, 50, 100, 150, 250, 500]
      },
      currentViewing: 1,
      showSpinner: true,
      count: null,
      currentCount: 0,
      languageId: 0
    }
  },
  computed: {
    isEmpty () {
      return !this.search
    },
    isDirty () {
      return !!this.search
    },
    productCountsFiltered () {
      let list = []
      if (!this.search) {
        list = this.productCounts
      }
      const search = this.search.toLowerCase()
      list = this.productCounts.filter((record, index) => {
        return (record.category && record.category.toLowerCase().indexOf(search) > -1) ||
          (record.categoryId && record.categoryId.toString().indexOf(search) > -1) ||
          (record.groupId && record.groupId.toString().indexOf(search) > -1) ||
          (record.group && record.group.toLowerCase().indexOf(search) > -1) ||
          (record.section && record.section.toLowerCase().indexOf(search) > -1) ||
          (record.sectionId && record.sectionId.toString().indexOf(search) > -1) ||
          (record.subCategoryId && record.subCategoryId.toString().indexOf(search) > -1) ||
          (record.subCategory && record.subCategory.toLowerCase().indexOf(search) > -1)
      })
      this.currentViewing = 1
      this.pagination.current_page = 1
      this.pagination.total = list.length
      this.searchPagintation(true)
      return list
    },
    productCountList () {
      this.currentViewing = (this.pagination.current_page - 1) * this.pagination.per_page + 1
      const list = this.productCountsFiltered.filter((ven, index) => {
        return index >= ((this.pagination.current_page - 1) * this.pagination.per_page) && index <= ((this.pagination.current_page - 1) * this.pagination.per_page + this.pagination.per_page - 1)
      })
      return list
    }
  },
  watch: {
  },
  mounted () {
    this.count = 100
  },
  methods: {
    getAllProductCounts () {
      this.currentCount = this.count
      this.showSpinner = true
      axios.get(`/api/reports/subcategory/GetProductCount/${this.currentCount}/${this.languageId}`).then(res => {
        const response = res.data
        this.productCounts = response
        this.pagination.total = response.length
        this.pagination.last_page = Math.ceil(response.length / this.pagination.per_page)
        this.$nextTick(() => {
          this.showSpinner = false
        })
      })
    },
    searchPagintation (size) {
      if (size) {
        this.pagination.last_page = Math.ceil(this.pagination.total / this.pagination.per_page)
      }
    },
    escapeRegExChars (value) {
      return value.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
    },
    formatResult (suggestion, search) {
      // Do not replace anything if the current value is empty
      if (!search || search === '' || !suggestion) {
        return suggestion
      }

      const pattern = '(' + this.escapeRegExChars(search) + ')'
      return suggestion.toString().toLowerCase()
        .replace(new RegExp(pattern, 'gi'), '<strong>$1</strong>')
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/&lt;(\/?strong)&gt;/g, '<$1>')
    },
    sortIds (col, ascending) {
      this.pagination.current_page = 1
      ascending = !ascending
      this.sortCol = col
      this.sortAsc = ascending
      if (ascending) {
        this.productCounts.sort((a, b) => a[col] - b[col])
      } else {
        this.productCounts.sort((a, b) => b[col] - a[col])
      }
    },
    sortStringValues (col, ascending) {
      this.pagination.current_page = 1
      ascending = !ascending
      this.sortCol = col
      this.sortAsc = ascending
      if (ascending) {
        this.productCounts.sort((a, b) => a[col].localeCompare(b[col]))
      } else {
        this.productCounts.sort((a, b) => b[col].localeCompare(a[col]))
      }
    },
    getBreadcrumbPageTitle (record) {
      return `${record.section} > ${record.group} > ${record.category} > ${record.subCategory}`
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/bootstrap.scss";
@import "../../../assets/scss/extra/mixins/flexbox.scss";
@import "../../../assets/scss/bootstrap-custom-variables.scss";
.search-section {
  margin-bottom: 1.5rem;
  @include flexbox;
  .productCount-search {

    position: relative;
    @include flex(0, 1, 40%);
    .form-control {
      &~.material-icons.times:hover,
      &:focus~.material-icons.times {
        cursor: pointer;
        color: $brand-danger;
      }
    }
    .material-icons {
      color: darken($gray-lighter, 25%);
      position: absolute;
      font-size: 1.125rem;
      top: .125rem;
      right: .125rem;
      padding: .5rem;
    }
  }
  .productCount-number-search {
    position: relative;
    display: flex;
    @include flex(0, 1, 22%);
    .form-control {
      &~.material-icons.times:hover,
      &:focus~.material-icons.times {
        cursor: pointer;
        color: $brand-danger;
      }
    }
    .material-icons {
      color: darken($gray-lighter, 25%);
      position: absolute;
      font-size: 1.125rem;
      top: .125rem;
      right: .125rem;
      padding: .5rem;
    }
  }
  .add-new-btn {
    padding: 0 1rem; // hidden until we are ready to add create..
    display: none;
    .btn {
      padding: .5rem 2rem;
    }
  }
  .productCount-table-pager {
    @include flex(1, 1, 30%);
    @include flexbox;
    @include justify-content(flex-end);
    .table-viewing-info {
      width: 10rem;
      text-align: right;
      color: #222;
      font-size: .875rem;
      @include align-self(center);
      @include flex(1, 1, 10rem);

      >label {
        background: #eee;
        padding: .5rem 1.5rem;
        margin-bottom: 0;
        border: 1px solid rgba(0, 0, 0, .15);
      }
    }
    .input-group {
      width: 12rem;
      border: 0;

      .input-group-addon {
        font-size: .875rem;
        border: 0;
        background: #fff;
      }
      .form-control {
        font-size: .875rem;
        background: #eee;
      }
    }
  }
}

.productCount-list {
  .table {
    border: 1px solid #eceeef;
    thead {
      tr {
        th {
          text-transform: uppercase;
          .material-icons {
            font-size: 1rem;
          }
          .no-sort {
            -ms-transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
          }
        }
      }
    }
    tbody {
      tr {
        td {
          vertical-align: middle;
          cursor: pointer;
        }
      }
    }
    .productCount-col {
      width: 23.75rem;
    }
    .arrow-col {
      width: 4rem;
      text-align: center;
      .productCount-arrow {
        display: block;
        position: relative;
        color: #fff;
        background: $brand-primary;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        &:hover {
          background: darken($brand-primary, 10%);
        }
        .material-icons {
          position: absolute;
          font-size: 1.5rem;

          top: 50%;
          right: 50%;
          margin: -.75rem -.825rem 0 0;
        }
      }
    }
    .img-col {
      width: 4.5rem;
      border-right: 1px solid #eceeef;
      .img-fluid {
        @include flexbox;
        @include justify-content(center);
        height: 3.75rem;
        img {
          @include align-self(center);
          max-height: 3.75rem;
          max-width: 3.75rem;
        }
      }
    }
    .id-col,
    .active-col {
      width: 6rem;
    }
  }
}

.productCount-nav {
  @include flexbox;
  @include justify-content(space-between);

  .btn-nav {
    @include flex(1, 1, 75%);

    .router-link-exact-active {
      @extend .btn-primary;
    }
  }
}
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
