<template>
  <div>
    <page-title title="Reviews"></page-title>
    <div
      class="spinner"
      :class="{'show-spinner': showSpinner}"></div>
    <section class="review-nav">
      <div class="btn-nav">
        <button
          class="btn btn-rounded btn-gray-alt"
          :class="{'btn-primary': status === 'N'}"
          @click="toggleStatus('N')">
          Pending
        </button>
        <button
          class="btn btn-rounded btn-gray-alt"
          :class="{'btn-primary': status === 'A'}"
          @click="toggleStatus('A')">
          Approved
        </button>
        <button
          class="btn btn-rounded btn-gray-alt"
          :class="{'btn-primary': status === 'D'}"
          @click="toggleStatus('D')">
          Denied
        </button>
      </div>
    </section>
    <div class="search-section">
      <div class="reviews-search">
        <search-filter
          v-model="search"
          class="search-slang-terms"
          placeholder="Search..."
          @input="searchReviews">
        </search-filter>
      </div>
    </div>
    <div class="mt-4 mb-4">
      <data-table
        :headers="tableHeader"
        :pagination.sync="pagination"
        :options.sync="paginationOptions"
        :max-height="500"
        :body-cell-class="'d-flex align-items-center'"
        :items="filteredReviews">
        <product-body-cell
          slot="body-cell-1"
          slot-scope="props"
          :item="props.item">
        </product-body-cell>
        <div
          slot="body-cell-2"
          slot-scope="props"
          class="basic-cell more-approved-cell">
          <div class="pr-2">
            {{ props.item.subject }}
          </div>
        </div>
        <div
          slot="body-cell-3"
          slot-scope="props"
          class="basic-cell--three-lines">
          {{ props.item.comments }}
        </div>
        <div
          slot="body-cell-4"
          slot-scope="props"
          class="basic-cell">
          {{ props.item.rating }}
        </div>
        <div
          slot="body-cell-5"
          slot-scope="props"
          class="basic-cell">
          <div>{{ props.item.createUser }}</div>
          <div>{{ props.item.userLocation }}</div>
        </div>
        <div
          slot="body-cell-6"
          slot-scope="props"
          class="basic-cell">
          {{ props.item.createDateFormatted }}
        </div>
        <!-- <div slot-scope="props" slot="body-cell-7" class="basic-cell">
          {{props.item.submittedOn}}
        </div> -->
        <div
          slot="body-cell-7"
          slot-scope="props"
          class="basic-cell">
          <div class="arrow-col">
            <span
              class="vendor-arrow"
              @click="openReviewModal(props.item)">
              <i class="material-icons">
                keyboard_arrow_right
              </i>
            </span>
          </div>
        </div>
      </data-table>
    </div>
    <edit-review-modal
      ref="reviewModal"
      :item="editReview"
      @save="savedEditModal"></edit-review-modal>
  </div>
</template>
<script type="text/javascript">
import axios from 'axios'
import TitleComponent from 'components/pageTitle.vue'
import DataTable from 'components/data-table/DataTable.vue'
import ProductBodyCell from './ProductBodyCell.vue'
import ReviewsTableHeader from './ReviewsTableHeader'
import SearchFilter from 'components/inputs/SearchFilter.vue'
import EditReviewModal from './EditReviewModal.vue'
import moment from 'moment'

const DEFAULT_PAGINATION = {
  total: 0,
  per_page: 50, // required
  current_page: 1, // required
  last_page: 0, // required
  from: 0,
  to: 15 // required
}

export default {
  name: 'Reviews',
  components: {
    'page-title': TitleComponent,
    'data-table': DataTable,
    ProductBodyCell,
    SearchFilter,
    EditReviewModal
  },
  data () {
    return {
      reviews: [],
      showSpinner: true,
      search: '',
      pagination: DEFAULT_PAGINATION,
      paginationOptions: {
        offset: 5,
        previousText: 'Prev',
        nextText: 'Next',
        alwaysShowPrevNext: true,
        pageSizes: [25, 50, 100, 150, 250, 500]
      },
      tableHeader: ReviewsTableHeader,
      status: 'N',
      editReview: {}
    }
  },
  computed: {
    filteredReviews () {
      const filteredReviews = this.reviews.filter(r => {
        return r.status === this.status
      })

      if (this.search !== '') {
        return this.searchReviews(filteredReviews)
      }
      return filteredReviews
    }
  },
  watch: {
    status (newVal) {
      // reset pagination when table data changes
      this.pagination.current_page = 1
      this.pagination.per_page = 50
    }
  },
  created () {
    this.getAllReviews()
  },
  methods: {
    getAllReviews () {
      axios.get('/api/product-reviews/GetProductReviewsList').then(res => {
        const response = res.data.map((review, b) => {
          review.createDateFormatted = moment(review.createDate, 'YYYYMMDDHH:mmA').format('MM/DD/YYYY HH:mm A')
          return review
        })
        this.reviews = response
        this.$nextTick(() => {
          this.showSpinner = false
        })
      }).catch(err => {
        console.log(err)
      })
    },
    toggleStatus (status) {
      this.status = status
      return status
    },
    openReviewModal (item) {
      this.editReview = item
      this.$refs.reviewModal.open()
    },
    savedEditModal (savedReview) {
      this.reviews.find((r) => {
        return r.reviewId === savedReview.reviewId
      }).status = savedReview.status
    },
    searchReviews (item) {
      if (!Array.isArray(item)) return
      return item.filter((obj) => {
        return Object.keys(obj).some((key) => {
          if (obj[key] !== null) {
            const tempKey = obj[key].toString().toLowerCase()
            const tempSearch = this.search.toLowerCase()
            return tempKey.includes(tempSearch)
          }
          return false
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/scss/bootstrap.scss';
@import '../../assets/scss/extra/mixins/flexbox.scss';
@import '../../assets/scss/bootstrap-custom-variables.scss';

.search-section {
  margin-bottom: 1.5rem;
  @include flexbox;
  .reviews-search {
    @include flex(0, 1, 40%);
  }
  .reviews-table-pager {
    @include flex(1, 1, 30%);
    @include flexbox;
    @include justify-content(flex-end);
    .table-viewing-info {
      width: 10rem;
      text-align: right;
      color: #222;
      font-size: 0.875rem;
      @include align-self(center);
      @include flex(1, 1, 10rem);

      > label {
        background: #eee;
        padding: 0.5rem 1.5rem;
        margin-bottom: 0;
        border: 1px solid rgba(0, 0, 0, 0.15);
      }
    }
    .input-group {
      width: 12rem;
      border: 0;

      .input-group-addon {
        font-size: 0.875rem;
        border: 0;
        background: #fff;
      }
      .form-control {
        font-size: 0.875rem;
        background: #eee;
      }
    }
  }
}

.arrow-col {
  width: 4rem;
  text-align: center;
  .vendor-arrow {
    display: block;
    position: relative;
    color: #fff;
    background: $brand-primary;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    &:hover {
      background: darken($brand-primary, 10%);
    }
    .material-icons {
      position: absolute;
      font-size: 1.5rem;
      top: 50%;
      right: 50%;
      margin: -0.75rem -0.825rem 0 0;
    }
  }
}

.review-nav {
  @include flexbox;
  @include justify-content(space-between);

  .btn-nav {
    @include flex(1, 1, 75%);

    .router-link-exact-active {
      @extend .btn-primary;
    }
  }
}

/* styles for '...' http://hackingui.com/front-end/a-pure-css-solution-for-multiline-text-truncation/ */
.basic-cell--three-lines {
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative;
  /* use this value to count block height */
  line-height: 1.2em;
  /* max-height = line-height (1.2) * lines max number (3) */
  max-height: 3.6em;
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;
  /* place for '...' */
  margin-right: -1em;
  padding-right: 1em;
}
/* create the ... */
.basic-cell--three-lines:before {
  /* points in the end */
  content: '...';
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of block */
  right: 0;
  bottom: 0;
}
/* hide ... if we have text, which is less than or equal to max lines */
.basic-cell--three-lines:after {
  /* points in the end */
  content: '';
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of text */
  right: 0;
  /* set width and height */
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  /* bg color = bg color under block */
  background: white;
}
</style>
