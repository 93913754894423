<template>
  <modal
    ref="editUserModal"
    :icon="modal.icon"
    :blocking="modal.blockClose"
    :title="modal.title"
    @close="resetModal()">
    <template v-if="showModal">
      <div
        v-if="showMessage"
        class="modal-message">
        {{ modal.message }}
      </div>
      <div
        v-else
        class="edit-user-permissions">
        <div
          class="alert row"
          :class="[user.activeInd ? 'alert-info' : 'alert-danger']">
          <div :class="{'has-primary': user.activeInd, 'has-danger': !user.activeInd}">
            <label class="custom-control custom-checkbox mb-0">
              <input
                v-model="user.activeInd"
                type="checkbox"
                class="custom-control-input" />
              <span class="custom-control-indicator"></span>
              <span class="custom-control-description">
                Account {{ user.activeInd ? 'Active' : 'Inactive' }}
              </span>
            </label>
          </div>
        </div>
        <div class="form-group row">
          <label
            for="inputName"
            class="form-control-label">
            Full Name
          </label>
          <input
            id="inputName"
            v-model="user.displayName"
            type="text"
            class="form-control"
            placeholder="Display Name"
            :class="{ 'is-invalid': $v.user.displayName.$error }" />
        </div>
        <div class="company-permissions mt-4">
          <label class="company-label">
            Company Access
          </label>
          <ul class="list-group">
            <li
              v-for="(perm, index) in userPerms"
              :key="'permission-' + index"
              class="list-group-item">
              <div class="row">
                <div class="col-4 pt-2">
                  <toggle
                    :id="'check-toggle-edit-' + index"
                    color="primary"
                    :is-checked.sync="perm.canView"
                    class="permissions-option"
                    @update:isChecked="canView($event, perm)"></toggle>
                  <label class="company-name">
                    {{ perm.name }}
                  </label>
                </div>
                <div class="col-8">
                  <div
                    v-if="perm.perm === 'Admin'"
                    class="admin-perm disabled">
                    Admin
                  </div>
                  <div
                    v-else
                    class="text-right">
                    <select
                      v-model="perm.perm"
                      class="select-permissions form-control"
                      :disabled="!perm.canView"
                      @change="adminType(perm, perm.perm)">
                      <option :value="null">
                        Select Role
                      </option>
                      <option
                        v-for="o in permOptions"
                        :key="`perm-${o.perm}`"
                        :value="o.perm">
                        {{ o.perm }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <div
      slot="button"
      class="footer-btns">
      <button
        type="button"
        class="btn btn-link"
        @click="resetModal()">
        Close
      </button>
      <button
        v-if="!showMessage"
        type="button"
        class="btn btn-primary"
        @click="saveUser()">
        Save
      </button>
      <button
        v-else
        type="button"
        class="btn btn-primary"
        @click="resetModal()">
        Done
      </button>
    </div>
  </modal>
</template>
<script type="text/javascript">
import axios from 'axios'
import Modal from 'components/modals/sweet.vue'
import Toggle from 'components/inputs/toggleSwitch.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'UserEdit',
  components: {
    modal: Modal,
    toggle: Toggle
  },
  props: {
    user: {
      type: Object,
      default: () => {
        return {
          displayName: '',
          emailAddress: '',
          userPermissions: []
        }
      }
    },
    showModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showMessage: false,
      permOptions: [
        // { perm: 'Admin' },
        { perm: 'Editor' },
        { perm: 'Contributor' },
        { perm: 'Marketing' }
      ],
      modal: {
        title: 'Edit Permissions',
        message: '',
        icon: null,
        blockClose: false
      }
    }
  },
  computed: {
    userPerms: {
      get () {
        const permArray = this.user.userPermissions.map((permission, index) => {
          if (permission.isAdmin) {
            permission.perm = 'Admin'
            permission.isAdmin = true
            permission.isEditor = true
            permission.contributor = true
            permission.marketing = true
          } else if (permission.isEditor) {
            permission.perm = 'Editor'
            permission.isAdmin = false
            permission.isEditor = true
            permission.contributor = true
            permission.marketing = false
          } else if (permission.contributor) {
            permission.perm = 'Contributor'
            permission.isAdmin = false
            permission.isEditor = false
            permission.contributor = true
            permission.marketing = false
          } else if (permission.marketing) {
            permission.perm = 'Marketing'
            permission.isAdmin = false
            permission.isEditor = false
            permission.contributor = false
            permission.marketing = true
          } else {
            permission.perm = null
            permission.isAdmin = false
            permission.isEditor = false
            permission.contributor = false
            permission.marketing = false
          }
          return permission
        })

        // Reorder so the Company Access controls appear in preferrd order
        const bannerNamesPreferredDisplayOrder = ['Platt', 'Rexel', 'Gexpro', 'Mayer', 'Rexel Energy', 'Capitol Light', 'Parts Super Center', 'Westburne Quebec', 'Westburne Ontario', 'Westburne Midwest', 'Westburne West', 'Nedco Quebec', 'Nedco Ontario', 'Nedco West', 'Rexel Atlantic']
        const orderedPermArray = []
        bannerNamesPreferredDisplayOrder.forEach((item, index) => {
          const foundIndex = permArray.findIndex(b => b.name === item)
          orderedPermArray.push(permArray[foundIndex])
        })

        return orderedPermArray
      },
      set (newValue) {
        this.user.userPermissions = newValue
      }
    }
  },
  watch: {
    showModal (value) {
      if (value) {
        this.$refs.editUserModal.open()
      } else {
        this.$refs.editUserModal.close()
      }
    }
  },
  methods: {
    resetModal () {
      this.$emit('update:showModal', false)
      this.$v.user.$reset()
      setTimeout(() => {
        this.modal = {
          title: 'Edit Permissions',
          message: '',
          icon: null,
          blockClose: false
        }
        this.showMessage = false
      }, 500)
    },
    canView (event, permission) {
      permission.canView = event
      if (!event) {
        permission.perm = null
        permission.isAdmin = false
        permission.isEditor = false
        permission.contributor = false
      }
    },
    adminType (permission, selected) {
      if (selected === 'Admin') {
        permission.canView = true
        permission.isAdmin = true
        permission.isEditor = true
        permission.contributor = true
        permission.marketing = true
      } else if (selected === 'Editor') {
        permission.canView = true
        permission.isAdmin = false
        permission.isEditor = true
        permission.contributor = true
        permission.marketing = false
      } else if (selected === 'Contributor') {
        permission.canView = true
        permission.isAdmin = false
        permission.isEditor = false
        permission.contributor = true
        permission.marketing = false
      } else if (selected === 'Marketing') {
        permission.canView = true
        permission.isAdmin = false
        permission.isEditor = false
        permission.contributor = false
        permission.marketing = true
      } else {
        permission.perm = null
        permission.isAdmin = false
        permission.isEditor = false
        permission.contributor = false
        permission.marketing = false
      }
    },
    saveUser () {
      this.$v.user.$touch()
      const data = {
        userId: this.user.userId,
        email: this.user.emailAddress,
        displayName: this.user.displayName,
        activeInd: this.user.activeInd,
        bannerId: 1,
        permissions: this.user.userPermissions
      }
      if (!this.$v.user.$invalid) {
        axios.post('/api/users/ManageUser', data).then(res => {
          const response = res.data
          this.$emit('user', response)
          this.showMessage = true
          this.modal.message = 'Successfully updated ' + this.user.displayName + '\'s permissions!'
          this.modal.icon = 'success'
          this.$emit('userUpdated', this.user.userId)
          setTimeout(() => {
            this.$refs.editUserModal._animateIcon()
          }, 50)
        }).catch(err => {
          this.modal.message = err.response.data
          this.modal.icon = 'error'
          this.showMessage = true
          setTimeout(() => {
            this.$refs.editUserModal._animateIcon()
          }, 50)
        })
      }
    }
  },
  validations () {
    return {
      user: {
        displayName: {
          required
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/scss/extra/mixins/flexbox.scss';
@import '../../../assets/scss/bootstrap-custom-variables.scss';

.has-primary {
  .custom-control {
    color: $brand-primary;
  }
}

.has-danger {
  .custom-control {
    color: $brand-danger;
  }
}

.custom-control {
  margin-bottom: 0;
  color: lighten($gray, 15%);
}

.company-permissions {
  margin: 0 -15px;
  .company-label {
    color: lighten($gray, 15%);
    margin: 0;
  }

  .company-name {
    margin: 0;
    padding: 0 1rem;
    line-height: 1.75;
    color: lighten($gray, 15%);
  }

  .material-switch {
    display: inline-block;
  }

  .list-group {
    .list-group-item {
      padding: 0.75rem 0.25rem 0.675rem 1rem;
      border-color: #eceeef;
      border-right: 0;
      border-left: 0;
      .admin-perm {
        font-size: 1rem;
        border: 1px solid rgba(0, 0, 0, 0.15);

        padding: 0.5rem 0.75rem;
      }
      &:first-child {
        border-top: 0;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.modal-message {
  margin: 1.5rem 0 0;
  color: $gray-700;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
}

.footer-btns {
  @include flex(1,1,100%);
  @include flexbox;
  @include justify-content(space-between);

  .btn:not(.btn-link) {
    cursor: pointer;
    min-width: 7rem;
  }
}
</style>
