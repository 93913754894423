<template>
  <div class="tile-container">
    <div
      class="dashboard-tile"
      :style="{ background: bg }"
      @click="tileClick(obj)">
      <div class="tile-wrap">
        <p class="tile-title">
          {{ title }}
        </p>
        <p class="tile-number">
          {{ value | numeric }}
        </p>
      </div>
      <i class="material-icons">
        arrow_forward
      </i>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  props: {
    bg: String,
    title: String,
    value: Number,
    obj: Object
  },
  methods: {
    tileClick (tile) {
      this.$emit('tile-click', tile)
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../../../assets/scss/extra/mixins/flexbox.scss";
  .tile-container {
    @include flexbox;
    width: 100%;
    padding: 0 5px;

    @media (min-width: 37.5rem) {
      width: calc(50%);
    }

    @media (min-width: 60rem) {
      width: calc(100% / 3);
    }

    @media (min-width: 75rem) {
      width: calc(100% / 5);
    }
  }

  .dashboard-tile {
    margin-bottom: .5rem;
    padding: .75rem 1rem;
    color: #fff;
    position: relative;
    @include flexbox;
    @include justify-content(space-between);
    width: 100%;

    &:before {
      content: '';
      background: linear-gradient(
        to bottom,
        rgba(0,0,0,0) 0%,
        rgba(0,0,0,0.5) 100%
      );
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 2;
      transition: all 250ms ease;
    }

    &:hover:before {
      cursor: pointer;
      opacity: .35;
    }

    .tile-wrap {
      .tile-title {
        margin: 0;
        font-size: .8125rem;
      }

      .tile-number {
        margin-bottom: .25rem;
        font-weight: 400;
        font-size: 1.25rem;
        letter-spacing: 1px;
        line-height:1.25;
      }
    }

    .material-icons {
      @include align-self(center);
      font-size: 1.15rem;
    }
  }
</style>
