<template>
  <div class="idw-dialog">
    <div v-if="dimensions.length > 0">
      <strong>
        Current Dimensions
      </strong>
      <div style="font-size:large">
        {{ getCurrentDimensions() }}
      </div>
    </div>

    <template v-if="ideaPackage">
      <data-table
        :headers="dimensionsHeaders"
        :items="ideaPackage">
        <div
          slot="body-cell-1"
          slot-scope="props"
          class="url-text"
          :item="props.item">
          <input
            v-model="selectedValue"
            :value="props.item"
            type="radio" />
        </div>
        <div
          slot="body-cell-2"
          slot-scope="props"
          class="url-text"
          :item="props.item">
          <strong>{{ props.item.ideaItemGtin }}</strong>
        </div>
        <div
          slot="body-cell-3"
          slot-scope="props"
          class="url-text"
          :item="props.item">
          <strong>{{ props.item.ideaPakDscr }}</strong>
        </div>
        <div
          slot="body-cell-4"
          slot-scope="props"
          class="url-text"
          :item="props.item">
          <strong>{{ props.item.ideaPakGtin }}</strong>
        </div>
        <div
          slot="body-cell-5"
          slot-scope="props"
          class="url-text"
          :item="props.item">
          <strong>{{ props.item.ideaPakAltUpc }}</strong>
        </div>
        <div
          slot="body-cell-6"
          slot-scope="props"
          class="url-text"
          :item="props.item">
          <strong>{{ props.item.ideaSubPakQty }}</strong>
        </div>
        <div
          slot="body-cell-7"
          slot-scope="props"
          class="url-text"
          :item="props.item">
          <strong>{{ props.item.ideaSubPakGtin }}</strong>
        </div>
        <div
          slot="body-cell-8"
          slot-scope="props"
          class="url-text"
          :item="props.item">
          <strong>{{ props.item.ideaPakUnitQty }} {{ props.item.ideaPakLowestLevelUom }}</strong>
        </div>
        <div
          slot="body-cell-9"
          slot-scope="props"
          class="url-text"
          :item="props.item">
          <strong>{{ props.item.ideaPakWgt }} {{ props.item.ideaPakWgtUom }}</strong>
        </div>
        <div
          slot="body-cell-10"
          slot-scope="props"
          class="url-text"
          :item="props.item">
          <strong>{{ props.item.ideaPakLen }} {{ props.item.ideaPakLenUom }}</strong>
        </div>
        <div
          slot="body-cell-11"
          slot-scope="props"
          class="url-text"
          :item="props.item">
          <strong>{{ props.item.ideaPakWid }} {{ props.item.ideaPakWidUom }}</strong>
        </div>
        <div
          slot="body-cell-12"
          slot-scope="props"
          class="url-text"
          :item="props.item">
          <strong>{{ props.item.ideaPakHgt }} {{ props.item.ideaPakHgtUom }}</strong>
        </div>
      </data-table>
      <div class="row text-center mt-2">
        <div class="col">
          <button
            type="button"
            class="btn btn-primary"
            :disabled="selectedValue == null"
            @click="onSubmit">
            Apply Selected
          </button>
        </div>
      </div>
    </template>

    <div
      v-else
      class="alert alert-primary">
      No dimension data found.
    </div>

    <sweet-modal
      ref="responseModal"
      :icon="modal.icon"
      :blocking="modal.blockClose"
      :title="modal.title"
      @close="resetModal()">
      <div class="text-center">
        {{ modal.message }}
      </div>
      <div slot="button">
        <div>
          <button
            type="button"
            class="btn btn-default"
            @click="resetModal()">
            Close
          </button>
        </div>
      </div>
    </sweet-modal>
  </div>
</template>
<script type="text/javascript">
import DataTable from 'components/data-table/DataTable.vue'
import SweetModalComponent from 'components/modals/sweet.vue'

export default {
  name: 'IdwDimensions',
  components: {
    'sweet-modal': SweetModalComponent,
    DataTable
  },
  props: {
    product: Object,
    dimensions: Array,
    ideaPackage: {
      type: Array,
      required: false,
      default: null
    }
  },
  data () {
    return {
      selectAll: false,
      selectedValue: null,
      modal: {
        title: null,
        message: '',
        icon: 'success',
        blockClose: false
      },
      dimensionsHeaders: [
        {
          text: '',
          width: '3%',
          sortable: false
        },
        {
          text: 'Item GTIN',
          value: 'ideaItemGtin',
          width: '10%'
        },
        {
          text: 'Pkg Dscr',
          value: 'ideaPakDscr',
          width: '7%'
        },
        {
          text: 'Pkg GTIN',
          value: 'ideaPakGtin',
          width: '10%'
        },
        {
          text: 'Pkg Alt UPC',
          value: 'ideaPakAltUpc',
          width: '8%'
        },
        {
          text: 'Sub Pkg Qty',
          value: 'ideaSubPakQty',
          width: '12%'
        },
        {
          text: 'Sub Pkg GTIN',
          value: 'ideaSubPakGtin',
          width: '10%'
        },
        {
          text: 'Pkg Unit Qty',
          value: 'ideaPakUnitQty',
          width: '12%'
        },
        {
          text: 'Weight',
          value: 'ideaPakWgt',
          width: '7%'
        },
        {
          text: 'Length',
          value: 'ideaPakLen',
          width: '7%'
        },
        {
          text: 'Width',
          value: 'ideaPakWid',
          width: '7%'
        },
        {
          text: 'Height',
          value: 'ideaPakHgt',
          width: '7%'
        }

      ]
    }
  },
  watch: {
  },
  methods: {
    resetModal () {
      if (this.$refs.responseModal.visible) {
        this.$refs.responseModal.close()
      }

      if (this.showModalDeleteButton) {
        this.showModalDeleteButton = false
        this.clearForm()
      }

      this.modal = {
        title: null,
        message: '',
        icon: 'success',
        blockClose: false
      }
    },
    onSubmit () {
      this.$emit('onSelected', this.selectedValue)
    },
    getCurrentDimensions () {
      const weight = this.dimensions.find(x => x.dimension === 'Weight')
      const length = this.dimensions.find(x => x.dimension === 'Length')
      const width = this.dimensions.find(x => x.dimension === 'Width')
      const height = this.dimensions.find(x => x.dimension === 'Height')

      return `Weight: ${weight?.dimensionValue ?? ''} ${weight?.unitOfMeasure ?? ''},
      Length: ${length?.dimensionValue ?? ''} ${length?.unitOfMeasure ?? ''}, 
      Width: ${width?.dimensionValue ?? ''} ${width?.unitOfMeasure ?? ''}, 
      Height: ${height?.dimensionValue ?? ''} ${height?.unitOfMeasure ?? ''}`
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../../assets/scss/extra/mixins/flexbox";
@import "../../../../assets/scss/bootstrap-custom-variables";
.idw-dialog {
  border: none;

  .checkboxes {
    display: flex;
    align-items: center;
    z-index: 100;

    :hover {
      cursor: pointer;
    }

    label {
      padding-left: 0rem;
    }
  }
}

</style>
