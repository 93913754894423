<template>
  <div>
    <label
      v-if="showLabel"
      class="form-control-label">Banners</label>
    <multi-select
      v-model="selectedBanners"
      :options="banners"
      label="name"
      track-by="id"
      :multiple="true"></multi-select>
  </div>
</template>

<script>
import Multiselect from 'components/inputs/multiselect.vue'

export default {
  name: 'BannerDropdown',
  components: {
    'multi-select': Multiselect
  },
  props: {
    showLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    defaultBannerId: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      selectedBanners: []
    }
  },
  computed: {
    banners () {
      return this.$store.getters.banners
    }
  },
  watch: {
    selectedBanners (val) {
      this.emitSelected()
    }
  },
  mounted () {
    this.setDeaultBanner()
  },
  methods: {
    emitSelected () {
      this.$emit('onSelected', this.selectedBanners)
    },
    resetDropdown () {
      this.selectedBanners = null
    },
    setDeaultBanner () {
      if (this.defaultBannerId) {
        const bannerIndex = this.banners.findIndex(i => i.id === this.defaultBannerId)
        this.selectedBanners.push(this.banners[bannerIndex])
      }
    }
  }
}
</script>
