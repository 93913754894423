<template>
  <div class="state-button">
    <label :class="{ 'checked': isChecked, 'has-icon': icon }">
      <slot>Button</slot>
      <img
        v-if="icon"
        class="state-button-icon"
        :src="icon"
        alt="" />
      <input
        type="checkbox"
        class="hidden"
        @change="setSelected()" />
    </label>
  </div>
</template>

<script>
export default {
  name: 'StateButton',
  props: ['value', 'icon', 'selected'],
  data () {
    return {
      isChecked: false
    }
  },
  watch: {
    'selected' (value) {
      this.isChecked = value
    }
  },
  methods: {
    setSelected () {
      this.isChecked = !this.isChecked
      const data = this.isChecked ? this.value : null
      this.$emit('selected', data)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/extra/mixins/flexbox.scss';
.state-button {
  @include flexbox;
  @include flex(1);
  @include justify-content(center);
}
label {
  @include flexbox;
  @include flex(1, 1, 100%);
  @include justify-content(space-between);
  @include align-items(center);
  text-transform: uppercase;
  font-size: 0.825rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  margin: 0.25rem;
  cursor: pointer;
  background: #eee;
  max-width: 200px;
  &.checked {
    background: #dceefa;
  }
  .hidden {
    display: none;
    position: absolute;
  }
  &.has-icon {
    text-align: left !important;
  }
}
</style>
