export default {
  methods: {
    getMediaImagePath (mediaDetails) {
      if (mediaDetails.folderNameGuid) {
        mediaDetails.folder = mediaDetails.folderNameGuid
      } else if (mediaDetails.folderName) {
        mediaDetails.folder = mediaDetails.folderName
        mediaDetails.size = 77
      }

      if (mediaDetails.folder && mediaDetails.folder.length) {
        let filePath =
          this.mediaProductFilePath(mediaDetails.rexelCdn) +
          mediaDetails.folder +
          '/'
        if (mediaDetails.size) {
          filePath += mediaDetails.size + '.jpg'
        } else {
          filePath += mediaDetails.folder + (mediaDetails.ext || '.jpg')
        }
        return filePath
      } else {
        return this.fallBackMediaImage(mediaDetails)
      }
    },
    getMediaFilePath (mediaDetails) {
      let filePath = this.mediaProductFilePath() + mediaDetails.folder + '/'
      if (mediaDetails.ext) {
        filePath += mediaDetails.folder + mediaDetails.ext
      } else {
        filePath += mediaDetails.file
      }
      return filePath
    },
    fallBackMediaImage (mediaDetails) {
      const size = mediaDetails && mediaDetails.size ? mediaDetails.size : 150
      return `https://rexel-cdn.com/products/NoImage.jpg?i=0EA7A6C5-7DD2-4482-9229-D628D3AFAE9D&f=${size}`
    },
    mediaProductFilePath () {
      return 'https://rexel-cdn.com/products/'
    }
  }
}
