<template>
  <modal
    ref="bulkUploadModal"
    :blocking="modal.blockClose"
    :title="title ? title : 'Bulk Import Tool'"
    :icon="modal.icon"
    :upload-type="uploadType"
    :upload-subtype="uploadSubtype"
    @close="closeModal()">
    <div id="bulk-upload">
      <div
        v-if="bulkUploadProcess.step == 1"
        class="step step-1 form-group">
        <div class="btn-upload btn btn-primary btn-lg">
          Browse Files
          <input
            class="file-input"
            type="file"
            value="Browse Files"
            @change="bulkUploadSelectAndValidateFiles($event)" />
        </div>
        <div>
          <label
            v-if="bulkUploadProcess.files.length > 0"
            class="file-after-lbl">You have selected: {{ uploadFileName }}</label>
          <label
            v-if="validationErrors.length > 0"
            class="file-after-lbl">Format errors found, please correct:</label>
          <textarea
            v-if="validationErrors.length > 0"
            v-model="validationErrors"
            class="form-control"
            no-resize
            rows="5"></textarea>
          <label
            v-if="errorsAreWarnings"
            class="custom-control custom-checkbox has-tooltip">
            <input
              v-model="ignoreBadValueWarnings"
              type="checkbox"
              class="custom-control-input" />
            <span
              class="custom-control-indicator"></span>
            <span
              class="custom-control-description">Ignore Warnings</span>
          </label>
          <p
            v-if="ignoreBadValueWarnings">
            New attribute values will be added to the database.
          </p>
        </div>
        <template v-if="directions">
          <hr />
          <slot name="directions"></slot>
        </template>
      </div>
      <div
        v-if="bulkUploadProcess.step == 2"
        class="step step-2 form-group">
        <div v-if="bulkUploadProcess.showMessage">
          <!--Success-->
          <template v-if="bulkUploadProcess.success == 'success'">
            <div class="sweet-modal-icon sweet-modal-success animate">
              <span class="sweet-modal-line sweet-modal-tip animateSuccessTip"></span>
              <span class="sweet-modal-line sweet-modal-long animateSuccessLong"></span>
              <div class="sweet-modal-placeholder"></div>
              <div class="sweet-modal-fix"></div>
            </div>
          </template>

          <!--Warning-->
          <template v-else-if="bulkUploadProcess.success == 'warning'">
            <div class="warning-message">
              <template v-if="bulkUploadProcess.uploadId">
                <h6 class="recordTotals validTotal">
                  {{ bulkUploadProcess.validTotal }} products were updated successfully.
                </h6>
                <h6 class="recordTotals invalidTotal">
                  {{ bulkUploadProcess.invalidTotal }} products were not updated.
                </h6>
                <div class="mt-4">
                  <h5>
                    {{ bulkUploadProcess.invalidTotal }} records contained errors. Click the download button below to retrieve a new file that contains records that errored out.
                  </h5>
                  <p>*Errors are provided in the last column.</p>
                  <a
                    :href="`/api/bulk/${bulkUploadProcess.uploadId}`"
                    target="_blank"
                    class="download-rejected-btn">
                    <button
                      v-if="bulkUploadProcess.uploadId"
                      type="button"
                      class="btn btn-primary download-rejected-btn">
                      Download
                      <i class="material-icons pl-2">
                        file_download
                      </i>
                    </button>
                  </a>
                </div>
              </template>
            </div>
          </template>
          <!--Error-->
          <template v-else-if="bulkUploadProcess.success == 'error'">
            <div class="sweet-modal-icon sweet-modal-error animateErrorIcon">
              <span class="sweet-modal-x-mark animateXMark">
                <span class="sweet-modal-line sweet-modal-left"></span>
                <span class="sweet-modal-line sweet-modal-right"></span>
              </span>
            </div>
          </template>

          <template v-if="Array.isArray(bulkUploadProcess.message)">
            <template v-for="(msg, index) in bulkUploadProcess.message">
              <div
                :key="msg"
                class="mb-2 error-message text-left">
                <strong>{{ index + 1 }}</strong>. {{ msg }}
              </div>
            </template>
          </template>

          <template v-else>
            <div class="mb-2 error-message">
              {{ bulkUploadProcess.message }}
            </div>
          </template>
        </div>
        <div
          v-else
          class="spinner show-spinner"></div>
      </div>
    </div>
    <div slot="button">
      <div
        v-if="bulkUploadProcess.step === 1"
        class="step step-1">
        <button
          type="button"
          class="btn btn-gray-alt"
          @click="closeModal()">
          Cancel
        </button>
        <button
          v-if="canProcess"
          type="button"
          class="btn btn-primary"
          @click="bulkUpload($event)">
          Run Process
        </button>
      </div>
      <div
        v-if="bulkUploadProcess.step === 2"
        class="step step-2">
        <button
          type="button"
          class="btn btn-gray-alt"
          @click="bulkUploadProcessStep($event, 1)">
          Upload Another
        </button>
        <button
          type="button"
          class="btn btn-gray-alt"
          @click="closeModal()">
          Done
        </button>
      </div>
    </div>
  </modal>
</template>
<script type="text/javascript">
import axios from 'axios'
import Modal from 'components/modals/sweet.vue'
// import ToggleSwitch from 'components/inputs/toggleSwitch.vue'

export default {
  name: 'BulkImportModal',
  components: {
    modal: Modal
    // toggle: ToggleSwitch
  },
  props: {
    showModal: Boolean,
    directions: Boolean,
    title: String,
    uploadType: Number,
    uploadSubtype: Number
  },
  data () {
    return {
      validationErrorModalOpen: false,
      validationErrors: [],
      uploadFileName: '',
      uploadFileIsValid: false,
      bulkUploadProcess: {
        step: 1,
        message: '',
        showMessage: true,
        success: null,
        invalidTotal: 0,
        validTotal: 0,
        files: []
      },
      modal: {
        icon: null,
        blockClose: true
      },
      ignoreBadValueWarnings: false,
      errorsAreWarnings: false
    }
  },
  computed: {
    canProcess () {
      return this.bulkUploadProcess.files.length > 0 && (this.uploadFileIsValid === true || this.ignoreBadValueWarnings === true)
    }
  },
  watch: {
    showModal (value) {
      if (value) {
        this.bulkUploadProcess.step = 1
        this.$refs.bulkUploadModal.open()
      } else {
        this.$refs.bulkUploadModal.close()
      }
    }
  },
  methods: {
    closeModal () {
      this.validationErrors = ''
      this.ignoreBadValueWarnings = false
      this.errorsAreWarnings = false
      this.$emit('update:showModal', false)
      this.bulkUploadProcessStep(null, 1)
      this.modal = {
        title: this.title ? this.title : 'Bulk Import Tool',
        icon: null,
        blockClose: true
      }
    },
    bulkUploadProcessStep (event, value) {
      this.bulkUploadProcess.step = value
      if (value === 1) {
        this.bulkUploadProcess = {
          step: 1,
          files: [],
          fileName: '',
          showMessage: false,
          message: '',
          success: true
        }
      }
    },
    bulkUploadSelectAndValidateFiles (event) {
      this.uploadFileIsValid = false
      this.validationErrors = ''
      this.ignoreBadValueWarnings = false
      this.errorsAreWarnings = false
      const files = event.target.files || event.dataTransfer.files
      this.bulkUploadProcess.files = files
      this.uploadFileName = this.bulkUploadProcess.files[0].name
      const formData = new FormData()
      formData.append('file', this.bulkUploadProcess.files[0])
      formData.append('uploadType', this.uploadType)
      formData.append('uploadSubtype', this.uploadSubtype)
      axios.post('/api/bulk/prevalidatespreadsheet', formData)
        .then(res => {
          if (res.data.length === 0) {
            this.uploadFileIsValid = true
          } else {
            this.validationErrors = res.data.join('')
            this.uploadFileIsValid = false
            if (this.validationErrors.indexOf('Warning:') === 0) {
              this.errorsAreWarnings = true
            }
          }
        }).catch(e => {
          this.bulkUploadProcess.success = 'error'
          this.bulkUploadProcess.message = e.response.data
          this.bulkUploadProcess.showMessage = true
        })
    },
    bulkUpload (event) {
      console.log('bulk upload')
      console.log(this)
      console.log(this.uploadType)
      console.log(this.uploadSubType)
      const formData = new FormData()
      formData.append('file', this.bulkUploadProcess.files[0])
      formData.append('uploadType', this.uploadType)
      let subtype = this.uploadSubtype
      if (this.ignoreBadValueWarnings) {
        subtype = 10 // UploadSubType.AttributesWithNewValues
      }
      formData.append('uploadSubtype', subtype)
      this.bulkUploadProcessStep(event, 2)
      this.bulkUploadProcess.showMessage = false
      console.log('gonna upload')
      console.log(this)
      if (this.uploadType === 5) {
        axios.post('/api/bulk/associations', formData)
          .then(res => {
            this.bulkUploadProcess.success = 'success'
            this.bulkUploadProcess.message = 'File has been uploaded and is being processed. Please check back to ensure file did not contain any errors.'
            this.bulkUploadProcess.showMessage = true
          }).catch(e => {
          // TODO: Check to see if a list of errors was returned. If so, display those in the model.
          // Else, it was just a badrequest and something else happend so display message below
            this.bulkUploadProcess.success = 'error'
            this.bulkUploadProcess.message = e.response.data
            this.bulkUploadProcess.showMessage = true
          })
      } else if (!this.$store.getters.isProductionEnvironment && this.uploadType === 6) {
        axios.post('/api/bulk/productmatches', formData)
          .then(res => {
            this.bulkUploadProcess.success = 'success'
            this.bulkUploadProcess.message = 'File has been uploaded and is being processed. Please check back to ensure file did not contain any errors.'
            this.bulkUploadProcess.showMessage = true
          }).catch(e => {
            // TODO: Check to see if a list of errors was returned. If so, display those in the model.
            // Else, it was just a badrequest and something else happend so display message below
            this.bulkUploadProcess.success = 'error'
            this.bulkUploadProcess.message = e.response.data
            this.bulkUploadProcess.showMessage = true
          })
      } else {
        axios.post('/api/bulk/products', formData)
          .then(res => {
            this.bulkUploadProcess.success = 'success'
            this.bulkUploadProcess.message = 'File has been uploaded and is being processed. Please check back to ensure file did not contain any errors.'
            this.bulkUploadProcess.showMessage = true
          }).catch(e => {
          // TODO: Check to see if a list of errors was returned. If so, display those in the model.
          // Else, it was just a badrequest and something else happend so display message below
            this.bulkUploadProcess.success = 'error'
            this.bulkUploadProcess.message = e.response.data
            this.bulkUploadProcess.showMessage = true
          })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../assets/scss/bootstrap-custom-variables";
@import "../../assets/scss/extra/mixins/flexbox.scss";
.sweet-content {
  .step {
    position: relative;
    &.step-1 {
      text-align: center;
    }
    label {
      display: inline-block;
      text-align: center;
      font-size: 0.75rem;
      color: #666;
      position: relative;

      &.file-before-lbl {
        padding-right: 1.5rem;
      }
      .exclamation {
        font-size: 1.25rem;
        display: inline-block;
        font-weight: 600;
        color: $brand-primary;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    input,
    .input {
      max-width: 14.75rem;
      font-size: 0.75rem;
      margin: 0 auto;
      padding: 0.5rem 0.75rem;
      background-color: #00416a;
      color: #fff;
      border: none;
      text-align: center;
      &:hover {
        cursor: pointer;
      }
    }
    .btn-upload {
      position: relative;
      overflow: hidden;
      padding: 0.5rem 2.5rem;
      margin-bottom: 1rem;
      .file-input {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        z-index: 1;
      }
    }
    .input {
      background-color: #00416a;
      color: #fff;
      z-index: 2;
      &:hover {
        cursor: pointer;
      }
    }
    &.step-2 {
      min-height: 7.75rem;
    }
    .warning-message {
      text-align: center;
      font-size: 0.875rem;
      color: #464a4c;
    }
  }
}
.sweet-buttons {
  .step {
    @include flexbox;
    @include justify-content(space-between);
    .btn {
      font-size: 0.875rem;
      min-width: 8.25rem;
    }
  }
}

.recordTotals {
  text-align: left;
  color: #fff;
  padding: 0.5rem 1rem;
}

.validTotal {
  background-color: #62d998;
}

.invalidTotal {
  background-color: #e7423c;
}

.download-rejected-btn {
  text-align: left;

  a {
    color: #fff;
    text-decoration: none;
  }

  i {
    font-size: 24px;
  }
}

.error-message {
  text-align: center;
}
</style>
