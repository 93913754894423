<template>
  <section
    id="media-filter"
    class="component-container">
    <div class="media-search form-row align-items-center">
      <div class="col product-search">
        <div class="input-group">
          <input
            v-model="searchValue"
            type="text"
            class="form-control"
            :placeholder="`Search for media`"
            @keyup.enter="searchMedia()" />
          <span class="input-group-btn">
            <button
              type="button"
              class="btn btn-gray-light"
              @click="searchMedia()">
              <i class="material-icons">
                search
              </i>
            </button>
          </span>
        </div>
      </div>
      <div class="col product-sortby">
        <multi-select
          v-model="searchManufacturer"
          placeholder="Search By Manufacturer"
          :options="manufacturers"
          track-by="id"
          label="name"
          select-label=""
          selected-label=""
          @select="searchByManufacturer"></multi-select>
      </div>
      <div class="col product-sortby">
        <div class="input-group">
          <span class="input-group-addon">
            Sort By:
          </span>
          <multi-select
            v-model="sortBy"
            :options="sortByOptions"
            track-by="value"
            label="name"
            :searchable="false"
            tag-placeholder=""
            select-label=""
            deselect-label=""
            @select="sort"></multi-select>
        </div>
      </div>
      <div class="col-auto bulk-upload">
        <button
          class="btn btn-block btn-primary"
          title="Bulk Upload is coming soon!"
          @click="bulkUpload = true">
          Bulk Upload
        </button>
        <bulk-upload :show.sync="bulkUpload"></bulk-upload>
      </div>
    </div>
    <nav class="nav nav-pills">
      <template v-for="(media, index) in mediaTypes">
        <a
          :key="`media-type-${index}`"
          class="flex-sm-fill text-sm-center nav-item nav-link media-type"
          :class="{'active': media.id === mediaType.id}"
          @click="mediaTypeChanged(media)">
          {{ media.name }}
        </a>
      </template>
    </nav>
  </section>
</template>
<script type="text/javascript">
import BulkUpload from './bulk.vue'
import MultiSelectComponent from 'components/inputs/multiselect.vue'

export default {
  name: 'MediaSearch',
  components: {
    'bulk-upload': BulkUpload,
    'multi-select': MultiSelectComponent
  },
  props: ['manufacturers', 'pagination', 'search'],
  data () {
    return {
      bulkUpload: false,
      searchManufacturer: null,
      searchValue: '',
      sortBy: { name: 'Select Option', type: 'unsorted', value: null },
      sortByOptions: [
        { name: 'Ascending Date', type: 'uploadDate-asc', value: 12 },
        { name: 'Descending Date', type: 'uploadDate-dsc', value: 13 },
        { name: 'Ascending Name', type: 'name-asc', value: 1 },
        { name: 'Descending Name', type: 'name-dsc', value: 2 },
        { name: 'Ascending Media Type', type: 'typeId-asc', value: 14 },
        { name: 'Descending Media Type', type: 'typeId-dsc', value: 15 }
      ],
      mediaType: { name: 'All', value: null, recent: false, id: 2 },
      mediaTypes: [
        { name: 'All', value: null, recent: false, id: 2 },
        { name: 'Documents', value: 5, recent: false, id: 3 },
        { name: 'Images', value: 1, recent: false, id: 4 },
        { name: 'Videos', value: 4, recent: false, id: 5 }
      ]
    }
  },
  computed: {
    manufacturerId () {
      return this.searchManufacturer ? this.searchManufacturer.mfrId : null
    }
  },
  methods: {
    searchObj (mfr) {
      const data = {
        search: this.searchValue,
        sortBy: this.sortBy.value,
        companies: this.search.companies,
        mediaTypeId: this.mediaType.value,
        mfrId: mfr || this.manufacturerId,
        languageId: this.$store.getters.languageId
      }
      return data
    },
    searchMedia (mfr) {
      this.$emit('update:search', this.searchObj(mfr))
      this.$emit('search-media', this.searchObj(mfr))
    },
    searchByManufacturer (mfr) {
      this.searchMedia(mfr.id)
    },
    mediaTypeChanged (type) {
      this.mediaType = type
      if (type.id === 1) {
        this.searchValue = ''
        this.$emit('recent', {
          count: 500,
          sortBy: this.sortBy.value
        })
      } else {
        this.$nextTick(() => {
          this.searchMedia()
        })
      }
    },
    sort (selected) {
      this.sortBy = selected
      this.pagination.current_page = 1
      this.$nextTick(() => {
        this.$emit('update:search', this.search)
        if (this.mediaType.recent) {
          this.$emit('recent', {
            count: 500,
            sortBy: this.sortBy.value
          })
        } else {
          this.$emit('search-media', this.searchObj())
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/scss/extra/mixins/flexbox.scss';
@import '../../../assets/scss/bootstrap-custom-variables.scss';
.media-search {
  margin-bottom: 1rem;
  padding: 0 0.75rem;
  width: calc(100% - 3rem);
  .product-search {
    .input-group {
      .input-group-btn {
        height: 44px;
        .btn-gray-light {
          cursor: pointer;
          height: 100%;
          background-color: #f8f9fa;
        }
      }
    }
  }
  .product-sortby {
    padding-left: 1rem;
    .input-group {
      max-width: 45.25rem;
      .input-group-addon {
        background-color: $gray-100;
      }
    }
  }
  .bulk-upload {
    padding-left: 1rem;
    .btn {
      height: 44px;
      padding: 0.5rem 1.25rem;
    }
  }
}

.nav-pills {
  padding: 0.5rem 0.75rem 0;
  .media-type {
    min-width: 8rem;
    border-radius: 25px;
    margin: 0.25rem 0.675rem 1.25rem 0;
    font-size: 0.875rem;
    &:not(.active) {
      background: #eee;
      border-color: #eceeef;
      &:hover {
        cursor: pointer;
        background: darken(#eee, 10%);
      }
    }
    &.active:hover {
      color: #fff;
      background: darken($blue, 5%);
    }
  }
}
</style>
