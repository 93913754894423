<template>
  <div
    v-cloak
    id="team-builder"
    class="team-builder">
    <page-title title="Team Builder"></page-title>
    <div
      class="spinner"
      :class="{'show-spinner': showSpinner}"></div>
    <div :class="{'page-loading': showSpinner}">
      <div class="search-row row">
        <div class="col-lg-4 col-md-6">
          <div class="form-group">
            <label class="form-control-label">
              Search
            </label>
            <div class="search-box">
              <input
                v-model="searchTerm"
                type="text"
                class="form-control"
                placeholder="Search By Team or User Name" />
              <a
                v-if="searchTerm.length"
                class="cancel"
                @click="searchTerm = ''">
                <i class="material-icons cancel">
                  close
                </i>
              </a>
              <a
                v-else
                class="search">
                <i class="material-icons search">
                  search
                </i>
              </a>
            </div>
          </div>
        </div>
        <div class="companies-nav col-lg-8 col-md-6">
          <div
            v-if="companyList && companyList.length > 0"
            class="select-nav">
            <label class="form-control-label">
              Company Filter
            </label>
            <div class="input-group">
              <span class="input-group-addon">
                <i class="material-icons">
                  business
                </i>
              </span>
              <select
                v-model="selectedCompany"
                class="form-control"
                @change="selectCompany(company)">
                <option :value="{ name: 'All', companyId: null }">
                  All
                </option>
                <option
                  v-for="company in companyList"
                  :key="`company-${company.companyId}`"
                  :value="{name: company.name, companyId: company.companyId}">
                  {{ company.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <goal-table
        v-if="enrichmentGoals && enrichmentGoals.yearlyGoals.length > 0"
        :yearly-goals="enrichmentGoals.yearlyGoals"
        :current-progress="enrichmentGoals.currentProgress"></goal-table>
      <div
        v-if="companyList && companyList.length > 0"
        class="companies-nav">
        <h5 class="companies-title">
          Companies
        </h5>
        <div class="btn-nav">
          <button
            class="btn btn-rounded"
            :class="{'btn-primary': selectedCompany.companyId == null, 'btn-gray-alt': selectedCompany.companyId != null}"
            @click="selectCompany({ name: 'All', companyId: null })">
            All
          </button>
          <button
            v-for="company in companyList"
            :key="`filter-company-${company.name}`"
            class="btn btn-rounded"
            :class="[selectedCompany.companyId === company.companyId ? 'btn-primary' : 'btn-gray-alt']"
            @click="selectCompany(company)">
            {{ company.name }}
          </button>
        </div>
      </div>
      <team-list
        v-if="teamList && teamList.length > 0"
        :team-list="teamList"
        :search="searchTerm"
        :company-filter="selectedCompany"
        :team-filter="selectedTeam"
        @select="selectTeam($event)"></team-list>
      <user-list
        :user-list="userList"
        :search="searchTerm"
        :company-filter="selectedCompany"
        :companies="companyList"
        :team-filter="selectedTeam"
        @user="addUserToList"></user-list>
    </div>
  </div>
</template>
<script type="text/javascript">
import axios from 'axios'

import teamList from './teams/list.vue'
import userList from './users/list.vue'
import PageTitle from 'components/pageTitle.vue'
import goalsTable from './goals.vue'

export default {
  components: {
    'team-list': teamList,
    'user-list': userList,
    'page-title': PageTitle,
    'goal-table': goalsTable
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.getTeamBuildingData()
    })
  },
  data () {
    return {
      showSpinner: true,
      searchTerm: '',
      userList: [],
      teamList: [],
      companyList: [],
      enrichmentGoals: null,
      selectedTeam: {},
      selectedCompany: { name: 'All', companyId: null }
    }
  },
  mounted () {

  },
  methods: {
    getTeamBuildingData () {
      this.showSpinner = true
      const api = [
        this.getUserList(),
        this.getTeamList(),
        this.getEnrichmentGoals(),
        this.getCompanyList()
      ]
      axios.all(api).then(() => {
        const defaultUsersTeam = this.getDefaultItem(this.teamList, this.$store.getters.user.team, 'teamId')
        if (defaultUsersTeam) {
          this.selectTeam(defaultUsersTeam)
        }
        const defaultUsersCompany = this.getDefaultItem(this.companyList, this.$store.getters.user.defaultCompany, 'companyId')
        if (defaultUsersCompany) {
          this.selectCompany(defaultUsersCompany)
        }
        this.showSpinner = false
      })
    },
    getUserList () {
      return axios.get('/api/users/getAllUsers').then(res => {
        const response = res.data
        this.userList = response
        this.$store.dispatch('setUsersList', response)
        return response
      })
    },
    getTeamList () {
      return axios.get('/api/teams').then(res => {
        const response = res.data
        this.teamList = response
        this.$store.dispatch('setTeamList', response)
        return response
      })
    },
    getEnrichmentGoals () {
      return axios.get('/api/teams/enrichment-goals')
        .then(res => {
          const response = res.data
          this.enrichmentGoals = response
          this.$store.dispatch('setTeamList', response)
          return response
        })
    },
    getCompanyList () {
      return axios.get('/api/users/getAllCompanies').then(res => {
        const companies = res.data.map((company) => {
          company.canView = false
          company.isAdmin = false
          company.isEditor = false
          company.contributor = false
          company.perm = null
          return company
        })
        this.$store.dispatch('setCompanyList', companies)
        this.companyList = companies
        return res.data
      })
    },
    getDefaultItem (list, userDefault, key) {
      for (let ii = 0; ii < list.length; ii++) {
        const listItem = list[ii]
        if (listItem[key] === userDefault) {
          return listItem
        }
      }
      return null
    },
    selectCompany (company) {
      const team = this.selectedTeam
      this.selectedCompany = company
      const containsTeam = team.companyList ? team.companyList.some(c => c === company.companyId) : true
      if (!containsTeam) {
        this.selectedTeam = {}
      }
      this.teamList.forEach(t => {
        // Set team member count for company-specific active members
        if (company.companyId) {
          t.memberCount = this.userList.filter(u => u.team === t.teamId && u.activeInd && (u.companyId === this.selectedCompany.companyId || u.defaultCompany === this.selectedCompany.companyId)).length
          // Set team member count for all active members
        } else {
          t.memberCount = this.userList.filter(u => u.team === t.teamId && u.activeInd).length
        }
      })
    },
    selectTeam (team) {
      if (this.selectedTeam.teamId === team.teamId) {
        this.selectedTeam = {}
      } else {
        this.selectedTeam = team
      }
    },
    addUserToList (e) {
      this.userList.push(e)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/scss/bootstrap-custom-variables.scss';
@import '../../assets/scss/extra/mixins/flexbox.scss';
.search-row {
  padding: 0 0.5rem;
  &:after {
    content: '';
    display: none;
    width: calc(100% - 1rem);
    margin: 0.5rem 0 1.5rem 0.5rem;
    border-bottom: 1px solid $gray-500;
  }
  .search-box {
    position: relative;
    .search,
    .cancel {
      color: $gray-500;
      position: absolute;
      right: 0.25rem;
      top: 50%;
      margin-top: -0.25rem;
    }
    .cancel {
      color: $brand-danger;
    }
  }
}
.companies-nav {
  padding: 0 0.5rem;
  .companies-title {
    display: none;
    font-size: 1.5rem;
    font-weight: 500;
    padding: 0.5rem 0 0 0;
  }
  .btn-nav {
    display: none;
  }
  .select-nav {
    margin-bottom: 2rem;
  }
}
@media (min-width: 68rem) {
  .companies-nav {
    .companies-title,
    .btn-nav {
      display: block;
    }
    .select-nav {
      display: none;
    }
  }
  .search-row {
    &:after {
      display: block;
    }
    .form-control-label {
      display: none;
    }
  }
}
</style>
