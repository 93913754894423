export default {
  data () {
    return {
      validMediaFileTypes: {
        media: '.drawing/x-dwf,.bmp,.graphic,.jpeg,.jpeg;charset=UTF-8,.jpg,.png,.vnd.dwg,.vnd.dxf,.x-ms-bmp,.x-wmf,.iges,.vnd.dwf,.vrml,.mp4,.mpeg,.quicktime,.x-flv,.x-ms-asf,.x-ms-wmv,.metafile',
        images: '.jpeg,.jpeg;charset=UTF-8,.jpg,.png,.bmp,.gif',
        videos: '.webm,.mkv,.avi,.mov,.wmv,.mp4,.m4v,.mpg,.mpeg,.3gp,.ogg,.ogv',
        document: '.acad,.CDFV2-encrypted,.dxf,.force-download,.msword,.octet-stream,.pdf,.pdf;charset=ISO-8859-1,.pdf;charset=UTF-8,.postscript,.step,.vnd.ms-excel,.vnd.ms-office,.vnd.ms-powerpoint,.vnd.openxmlformats-officedocument.presentationml.presentation,.vnd.openxmlformats-officedocument.spreadsheetml.sheet,.vnd.openxmlformats-officedocument.wordprocessingml.document,.x-bzip2,.x-compressed,.x-dosexec,.x-dwg,.x-dxf,.x-msdownload,.x-msi,.x-ms-installer,.x-msmetafile,.x-shockwave-flash,.x-tika-msoffice,.html,.plain,.csv,.xls,.rfa,.xlsx',
        all: '.acad,.CDFV2-encrypted,.dxf,.force-download,.msword,.octet-stream,.pdf,.pdf;charset=ISO-8859-1,.pdf;charset=UTF-8,.postscript,.step,.vnd.ms-excel,.vnd.ms-office,.vnd.ms-powerpoint,.vnd.openxmlformats-officedocument.presentationml.presentation,.vnd.openxmlformats-officedocument.spreadsheetml.sheet,.vnd.openxmlformats-officedocument.wordprocessingml.document,.x-bzip2,.x-compressed,.x-dosexec,.x-dwg,.x-dxf,.x-msdownload,.x-msi,.x-ms-installer,.x-msmetafile,.x-shockwave-flash,.x-tika-msoffice,.x-zip-compressed,.zip,.drawing/x-dwf,.bmp,.graphic,.jpeg,.jpeg;charset=UTF-8,.jpg,.png,.vnd.dwg,.vnd.dxf,.x-ms-bmp,.x-wmf,.iges,.vnd.dwf,.vrml,.html,.plain,.mp4,.mpeg,.quicktime,.x-flv,.x-ms-asf,.x-ms-wmv,.metafile,.csv,.xls,.rfa,.xlsx'
      },
      mediaTypes: {
        image: 'image',
        video: 'video',
        document: 'document'
      }
    }
  },
  methods: {
    getMediaTypeByFileName (fileName) {
      const splitFileName = fileName.split('.')
      const extension = `.${splitFileName[splitFileName.length - 1].toLowerCase().trim()}`

      if (this.validMediaFileTypes.images.indexOf(extension) > -1) {
        return this.mediaTypes.image
      } else if (this.validMediaFileTypes.videos.indexOf(extension) > -1) {
        return this.mediaTypes.video
      } else {
        return this.mediaTypes.document
      }
    },
    getValidMediaTypes (types) {
      if (types && types.length > 0) {
        return types.map(type => this.validMediaFileTypes[type] || '').join(',')
      } else {
        return this.validMediaFileTypes.all
      }
    },
    getMediaTypeByFileType (fileType) {
      if (fileType.indexOf('image/') > -1) {
        return this.mediaTypes.image
      } else if (fileType.indexOf('video/') > -1) {
        return this.mediaTypes.video
      } else {
        return this.mediaTypes.document
      }
    }
  }
}
