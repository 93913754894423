import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import Dashboard from '@/views/dashboard/dashboard.vue'
import ProductEdit from '@/views/products/edit/main.vue'
import ProductSearch from '@/views/products/Search.vue'
import ProductQnA from '@/views/productqna/list.vue'
import SlangTerms from '@/views/slangterms/list.vue'
// import ApprovalsNav from '@/views/approvals/list/nav.vue'
// import ApprovalsHistory from '@/views/approvals/list/history.vue'
// import ApprovalsBulkNAPM from '@/views/approvals/list/bulkList.vue'
// import ApprovalsBulkHistoryNAPM from '@/views/approvals/list/bulkHistory.vue'
// import ApprovalsCompare from '@/views/approvals/compare/main.vue'
// import ApprovalsPendingNAPM from '@/views/approvals/list/singleList.vue'
import TeamBuilderList from '@/views/teambuilder/main.vue'
import TeamBuilderUserDetails from '@/views/teambuilder/users/details.vue'
import Marketing from '@/views/marketing/list.vue'
import MarketingCreate from '@/views/marketing/create.vue'
import MarketingDetail from '@/views/marketing/detail.vue'
import MarketingEdit from '@/views/marketing/edit.vue'
import Media from '@/views/media/main.vue'
import MediaDetails from '@/views/media/mediaDetails/details.vue'
import Categories from '@/views/categories/main.vue'
import categoryChildSection from '@/views/categories/components/section.vue'
import categoryChildGroup from '@/views/categories/components/group.vue'
import categoryChildCategory from '@/views/categories/components/category.vue'
import categoryChildSubCategory from '@/views/categories/components/subCategory.vue'
import Manufacturers from '@/views/manufacturers/list.vue'
import ManufacturerCreate from '@/views/manufacturers/create.vue'
import ManufacturerDetail from '@/views/manufacturers/detail.vue'
import Attributes from '@/views/attributes/list.vue'
import AttributeDetail from '@/views/attributes/detail.vue'
import Reports from '@/views/reports/main.vue'
import Sustainability from '@/views/sustainability/sustainability.vue'
import ReportsSubCategoryProductCounts from '@/views/reports/subCategory/productCounts.vue'
import ReportsSubCategoryProductDetails from '@/views/reports/subCategory/details.vue'
import BulkUploads from '@/views/bulkuploads/list.vue'
import Reviews from '@/views/reviews/main.vue'
import ProductReviews from '@/views/reviews/productReviews/main.vue'
import AuthCallback from '@/views/AuthCallback.vue'
import AuthCallbackSilent from '@/views/AuthCallbackSilent.vue'
import PermissionsMixin from '@/mixins/Permissions.mixin'
import ExternalContent from '@/views/external/default.vue'
import ErrorPage from '@/views/error/default.vue'
import axios from 'axios'
import { initAuth } from '@/auth'

const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') {
      throw err
    }
  })
}

Vue.use(Router)

export const router = new Router({
  mode: 'history',
  routes: [
    {
      name: 'AuthCallback',
      path: '/auth-callback',
      component: AuthCallback
    },
    {
      name: 'AuthCallbackSilent',
      path: '/silent-auth-callback',
      component: AuthCallbackSilent
    },
    {
      path: '/',
      component: Dashboard,
      name: 'dashboard',
      meta: {
        title: 'Content Central'
      }
    },
    {
      path: '/products',
      component: ProductSearch,
      name: 'productSearch',
      meta: {
        title: 'Product Search'
      }
    },
    {
      path: '/products/edit/:id',
      component: ProductEdit,
      name: 'productEdit',
      meta: {
        title: 'Product Edit',
        searchSend: {},
        fromEdit: false,
        task: false
      },
      query: {
        remove: null
      }
    },
    {
      path: '/productqna/:status?',
      component: ProductQnA,
      name: 'productQnA',
      meta: {
        title: 'Q & A',
        task: true,
        fromEdit: false
      }
    },
    {
      path: '/slangterms/:status?',
      component: SlangTerms,
      name: 'slangTerms',
      meta: {
        title: 'Slang Terms'
      }
    },
    // {
    //   path: '/approvals',
    //   component: ApprovalsNav,
    //   children: [{
    //     path: '',
    //     component: ApprovalsPendingNAPM,
    //     name: 'approvalsPendingNAPM',
    //     alias: 'pending',
    //     meta: {
    //       title: 'Pending Approvals',
    //       needsAdmin: true
    //     }
    //   },
    //   {
    //     path: 'bulk',
    //     component: ApprovalsBulkNAPM,
    //     name: 'approvalsBulkNAPM',
    //     alias: 'napm/bulk',
    //     meta: {
    //       title: 'Pending Approvals',
    //       needsAdmin: true
    //     }
    //   },
    //   {
    //     path: 'history',
    //     component: ApprovalsHistory,
    //     name: 'approvalsHistory',
    //     alias: 'napm/history',
    //     meta: {
    //       title: 'Approvals History',
    //       needsAdmin: true
    //     }
    //   },
    //   {
    //     path: 'bulk-history',
    //     component: ApprovalsBulkHistoryNAPM,
    //     name: 'approvalsBulkHistory',
    //     alias: 'napm/bulk/history',
    //     meta: {
    //       title: 'Bulk Approvals History',
    //       needsAdmin: true
    //     }
    //   }
    //   ]
    // },
    // {
    //   path: '/approvals/napm/compare/:id/:masterId/:user',
    //   component: ApprovalsCompare,
    //   name: 'napmApprovalsCompare',
    //   meta: {
    //     title: 'Approval Compare',
    //     needsAdmin: true,
    //     selected: {}
    //   }
    // },
    {
      path: '/teambuilder',
      component: TeamBuilderList,
      name: 'teamBuilderList',
      meta: {
        title: 'Team Builder',
        needsAdmin: true
      }
    },
    // {
    //   path: '/teambuilder/detail/:id',
    //   component: TeamBuilderTeamDetails,
    //   name: 'teamDetail',
    //   meta: {
    //     title: 'Team Detail',
    //     needsAdmin: true
    //   }
    // },
    {
      path: '/teambuilder/users/detail/:id',
      component: TeamBuilderUserDetails,
      name: 'userDetail',
      meta: {
        title: 'User Detail',
        needsAdmin: true
      }
    },
    {
      path: '/marketing',
      component: Marketing,
      name: 'marketing',
      meta: {
        title: 'Marketing',
        spinner: () => store.dispatch('setSpinner', true)
      }
    },
    {
      path: '/marketing/create',
      component: MarketingCreate,
      name: 'marketingCreate',
      meta: {
        title: 'Create New Marketing File'
      }
    },
    {
      path: '/marketing/detail/:id',
      component: MarketingDetail,
      name: 'marketingDetail',
      meta: {
        title: 'Marketing File Detail'
      }
    },
    {
      path: '/marketing/edit/:id',
      component: MarketingEdit,
      name: 'marketingEdit',
      meta: {
        title: 'Edit Marketing File',
        spinner: () => store.dispatch('setSpinner', true)
      }
    },
    {
      path: '/media',
      component: Media,
      name: 'media',
      meta: {
        title: 'Media',
        spinner: () => store.dispatch('setSpinner', true)
      }
    },
    {
      path: '/media/details/:type/:id',
      component: MediaDetails,
      name: 'mediaDetails',
      meta: {
        title: 'Media Details',
        spinner: () => store.dispatch('setSpinner', true)
      }
    },
    {
      path: '/categories',
      component: Categories,
      name: 'categories',
      meta: {
        title: 'Categories',
        needsAdmin: true,
        noAfterScroll: true,
        spinner: () => store.dispatch('setSpinner', true)
      },
      props: true,
      children: [{
        path: 'section',
        component: categoryChildSection,
        name: 'categoryChildSection',
        meta: {
          title: 'Categories',
          needsAdmin: true,
          noAfterScroll: true,
          spinner: () => store.dispatch('setSpinner', true)
        },
        props: true,
        alias: ''
      },
      {
        path: ':sectionId/group',
        component: categoryChildGroup,
        name: 'categoryChildGroup',
        meta: {
          title: 'Categories',
          needsAdmin: true,
          noAfterScroll: true,
          spinner: () => store.dispatch('setSpinner', true)
        },
        props: true,
        alias: ':sectionId'
      },
      {
        path: ':sectionId/:groupId/cats',
        component: categoryChildCategory,
        name: 'categoryChildCategory',
        meta: {
          title: 'Categories',
          needsAdmin: true,
          noAfterScroll: true,
          spinner: () => store.dispatch('setSpinner', true)
        },
        props: true,
        alias: ':sectionId/:groupId'
      },
      {
        path: ':sectionId?/:groupId?/:categoryId?/:subCategoryId?/sub-cats',
        component: categoryChildSubCategory,
        name: 'categoryChildSubCategory',
        meta: {
          title: 'Categories',
          needsAdmin: true,
          noAfterScroll: true,
          spinner: () => store.dispatch('setSpinner', true)
        },
        props: true,
        alias: ':sectionId?/:groupId?/:categoryId?/:subCategoryId?'
      }
      ]
    },
    {
      path: '/manufacturers',
      component: Manufacturers,
      name: 'manufacturerList',
      meta: {
        title: 'Manufacturers',
        needsAdmin: true
      }
    },
    {
      path: '/manufacturers/create',
      component: ManufacturerCreate,
      name: 'manufacturerCreate',
      meta: {
        title: 'Create Manufacturer',
        needsAdmin: true
      }
    },
    {
      path: '/manufacturers/details/:id',
      component: ManufacturerDetail,
      name: 'manufacturerDetail',
      meta: {
        title: 'Edit Manufacturer',
        needsAdmin: true
      }
    },
    {
      path: '/attributes',
      component: Attributes,
      name: 'attributesList',
      meta: {
        title: 'Attributes'
      }
    },
    {
      path: '/attributes/details/:id',
      component: AttributeDetail,
      name: 'attributeDetails',
      meta: {
        title: 'Attribute Details'
      }
    },
    {
      path: '/reports',
      component: Reports,
      name: 'reports',
      meta: {
        title: 'Reports'
      }
    },
    {
      path: '/sustainability',
      component: Sustainability,
      name: 'sustainability',
      meta: {
        title: 'Sustainability'
      }
    },
    {
      path: '/reports/subcategoryproductcount',
      component: ReportsSubCategoryProductCounts,
      name: 'reportsSubCategoryProductCounts',
      meta: {
        title: 'Sub Category Product Counts'
      }
    },
    {
      path: '/reports/subcategoryproductcount/details/:id',
      component: ReportsSubCategoryProductDetails,
      name: 'reportsSubCategoryProductDetails',
      meta: {
        title: 'Sub Category Product Details'
      },
      params: {
        id: null,
        name: null
      }
    },
    {
      path: '/bulk',
      component: BulkUploads,
      name: 'bulk',
      meta: {
        title: 'Bulk Uploads'
      }
    },
    {
      path: '/reviews',
      component: Reviews,
      name: 'reviews',
      meta: {
        title: 'Reviews'
      }
    },
    {
      path: '/reviews/:id',
      component: ProductReviews,
      name: 'productReviews',
      meta: {
        title: 'Product Reviews'
      },
      params: {
        id: null,
        product: null
      }
    },
    {
      path: '/externalContent',
      component: ExternalContent,
      name: 'externalContent',
      meta: {
        title: 'External Content',
        isExternal: true
      }
    },
    {
      path: '/errorPage',
      component: ErrorPage,
      name: 'errorPage',
      meta: {
        title: 'Error',
        isExternal: true
      }
    },
    {
      path: '*',
      redirect: {
        name: 'dashboard'
      }
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title

  initAuth()
    .then((manager) => {
      to.meta.lang = store.getters.languageId

      // override axios to force log out if api returns error 401
      axios.interceptors.response.use(null, function (error) {
        if (error.response && error.response.status === 401) {
          manager.signoutRedirect()
        } else {
          return Promise.reject(error)
        }
      })

      if (to.meta.isExternal) {
        next()
      } else if (!store.getters.user.companyPermissions) {
        next('/errorPage')
      } else if (to.meta.needsAdmin) {
        if (store.getters.hasPermissionForAnyBanner(PermissionsMixin.data.Roles.admin)) {
          next()
        } else {
          console.log('!hasPermissionForAnyBanner')
          next(from.path || '/dashboard')
        }
      } else {
        next()
      }
    })
    .catch((err) => {
      console.error('router initAuth', err)
    })
})

router.afterEach((to, from) => {
  document.title = to.name === 'externalContent' ? `${to.query.contentName[0].toUpperCase()}${to.query.contentName.slice(1)}` : to.meta.title

  if (!to.meta.noAfterScroll) {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 150)
  }
})
