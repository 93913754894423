<template>
  <div class="input-group">
    <span class="input-group-addon">
      Sort By
    </span>
    <multi-select
      v-model="sortBy"
      :options="options"
      label="name"
      track-by="value"
      :allow-empty="false"
      :searchable="false"
      :hide-selected="true"
      :show-labels="false"
      placeholder="Select Option"></multi-select>
  </div>
</template>

<script>
import Multiselect from 'components/inputs/multiselect.vue'

export default {
  name: 'SortByDropdown',
  components: {
    'multi-select': Multiselect
  },
  props: {
    type: {
      type: String,
      required: false,
      default: 'products'
    }
  },
  data () {
    return {
      sortBy: null,
      options: [
        { value: null, name: 'Select Option' },
        { value: 1, name: 'Ascending Name' },
        { value: 2, name: 'Descending Name' },
        { value: 3, name: 'Ascending Manufacturer' },
        { value: 4, name: 'Descending Manufacturer' },
        { value: 5, name: 'Ascending Category' },
        { value: 6, name: 'Descending Category' },
        { value: 7, name: 'Ascending Section' },
        { value: 8, name: 'Descending Section' },
        { value: 9, name: 'Ascending Group' },
        { value: 10, name: 'Descending Group' },
        { value: 19, name: 'Ascending Sales Tier' },
        { value: 20, name: 'Descending Sales Tier' },
        { value: 21, name: 'Ascending Top Viewed' },
        { value: 22, name: 'Descending Top Viewed' },
        { value: 23, name: 'Ascending Top Sold' },
        { value: 24, name: 'Descending Top Sold' }
      ]
    }
  },
  watch: {
    sortBy (val) {
      this.emitData()
    }
  },
  methods: {
    emitData () {
      this.$emit('onSelected', this.sortBy)
    }
  }
}
</script>

<style lang="scss" scoped>
.search-box {
  height: 43px;
  & ~ .input-group-btn {
    .btn {
      height: 43px;
    }
  }
}
</style>
