<template>
  <nav
    aria-label="Page navigation"
    class="d-flex justify-content-between flex-wrap">
    <div>
      <ul
        v-if="pagination.last_page > 0 && showPageNumbers"
        class="pagination">
        <li
          v-if="showPrevious()"
          :class="{'page-item': true, 'disabled' : pagination.current_page <= 1}">
          <span
            v-if="pagination.current_page <= 1"
            class="page-link">
            <span aria-hidden="true">
              {{ config.previousText }}
            </span>
          </span>

          <a
            v-if="pagination.current_page > 1 "
            href="#"
            :aria-label="config.ariaPrevious"
            class="page-link"
            @click.prevent="changePage(pagination.current_page - 1)">
            <span aria-hidden="true">
              {{ config.previousText }}
            </span>
          </a>
        </li>
        <li
          v-for="num in array"
          :key="num"
          :class="{ 'page-item': true, 'active': num == pagination.current_page}">
          <a
            href="#"
            class="page-link"
            @click.prevent="changePage(num)">
            {{ num }}
          </a>
        </li>
        <li
          v-if="showNext()"
          :class="{ 'page-item': true, 'disabled' : pagination.current_page === pagination.last_page || pagination.last_page === 0 }">
          <span
            v-if="pagination.current_page === pagination.last_page || pagination.last_page === 0"
            class="page-link">
            <span aria-hidden="true">
              {{ config.nextText }}
            </span>
          </span>

          <a
            v-if="pagination.current_page < pagination.last_page"
            href="#"
            class="page-link"
            :aria-label="config.ariaNext"
            @click.prevent="changePage(pagination.current_page + 1)">
            <span aria-hidden="true">
              {{ config.nextText }}
            </span>
          </a>
        </li>
      </ul>
    </div>
    <div class="table-pager">
      <div
        v-show="showViewingAmount"
        class="table-viewing-info">
        <label>Viewing {{ pagination.currentViewing | numeric }} -
          <span v-if="!viewingAllItems">
            {{ parseInt(pagination.currentViewing) + parseInt(pagination.per_page) - 1 | numeric }}
          </span>
          <span v-else>
            {{ pagination.total | numeric }}
          </span> of {{ pagination.total | numeric }}
        </label>
      </div>
      <div
        v-if="showPageSizeDropdown"
        class="input-group">
        <span class="input-group-addon">
          Showing:
        </span>
        <select
          v-model="pagination.per_page"
          class="form-control">
          <option
            v-for="o in options.pageSizes"
            :key="`pager-${o}`"
            :value="o">
            {{ o }}
          </option>
        </select>
      </div>
    </div>
  </nav>
</template>
<script type="text/javascript">
export default {
  name: 'DataTablePagination',
  props: {
    pagination: {
      type: Object,
      required: true
    },
    options: {
      type: Object
    },
    showPageNumbers: {
      type: Boolean,
      default: true,
      required: false
    },
    showViewingAmount: {
      type: Boolean,
      default: true,
      required: false
    },
    showPageSizeDropdown: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  computed: {
    array () {
      if (this.pagination.last_page <= 0) {
        return []
      }

      let from = this.pagination.current_page - this.config.offset
      if (from < 1) {
        from = 1
      }

      let to = from + (this.config.offset * 2)
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page
      }

      const arr = []
      while (from <= to) {
        arr.push(from)
        from++
      }
      return arr
    },
    config () {
      return Object.assign({
        offset: 5,
        ariaPrevious: 'Previous',
        ariaNext: 'Next',
        previousText: '«',
        nextText: '»',
        alwaysShowPrevNext: false
      }, this.options)
    },
    viewingAllItems () {
      return parseInt(this.pagination.currentViewing) + parseInt(this.pagination.per_page) - 1 >= this.pagination.total
    }
  },
  methods: {
    showPrevious () {
      return this.config.alwaysShowPrevNext || this.pagination.current_page > 1
    },
    showNext () {
      return this.config.alwaysShowPrevNext || this.pagination.current_page < this.pagination.last_page
    },
    changePage (page) {
      if (this.pagination.current_page === page) {
        return
      }
      this.$set(this.pagination, 'current_page', page)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/scss/extra/mixins/flexbox.scss';
@import '../../assets/scss/bootstrap-custom-variables';

.pagination {
  margin-bottom: 0;
}

.table-pager {
  @include flex(1, 1, 30%);
  @include flexbox;
  @include justify-content(flex-end);
  .table-viewing-info {
    width: 10rem;
    text-align: right;
    color: #222;
    font-size: .875rem;
    @include align-self(center);
    @include flex(1, 1, 10rem);

    >label {
      background: #eee;
      padding: .5rem 1.5rem;
      margin-bottom: 0;
      border: 1px solid rgba(0, 0, 0, .15);
    }
  }
  .input-group {
    width: 12rem;
    border: 0;

    .input-group-addon {
      font-size: .875rem;
      border: 0;
      background: #fff;
    }
    .form-control {
      font-size: .875rem;
      background: #eee;
    }
  }
}
</style>
