<template>
  <div class="product-enriched">
    <ul class="list-group">
      <li class="list-group-item enriched-heading">
        <h6>Enriched</h6>
        <a><i class="material-icons">
          brush
        </i></a>
      </li>
      <li
        class="list-group-item"
        :class="[enriched.level1 ? 'text-primary' : 'text-danger']">
        <h6 class="level-title">
          <i
            v-if="enriched.level1"
            class="material-icons mb-1">
            check_circle
          </i>
          <i
            v-else
            class="material-icons mb-1">
            block
          </i>
          <span>Level 1</span>
          <span class="expand-collapse-icons">
            <i class="material-icons level-collapsed">
              add
            </i>
            <i class="material-icons level-show">
              remove
            </i>
          </span>
        </h6>
      </li>
      <li
        class="list-group-item"
        :class="[enriched.level2 ? 'text-primary' : 'text-danger has-children']">
        <a
          class="level-control"
          data-toggle="collapse"
          href="#collapseLevel2"
          :aria-expanded="enrichLevel === 2 ? 'true' : 'false'"
          aria-controls="collapseLevel2">
          <h6 class="level-title">
            <i
              v-if="enriched.level2"
              class="material-icons mb-1">
              check_circle
            </i>
            <i
              v-else
              class="material-icons mb-1">
              block
            </i>
            <span>Level 2</span>
            <span class="expand-collapse-icons push-right">
              <i class="material-icons level-collapsed">
                add
              </i>
              <i class="material-icons level-show">
                remove
              </i>
            </span>
          </h6>
        </a>

        <ul
          v-if="!enriched.level2"
          id="collapseLevel2"
          class="collapse"
          :class="{ 'show': enrichLevel === 2 }"
          :aria-expanded="enrichLevel === 2 ? 'true' : 'false'">
          <li
            class="list-group-item"
            :class="[enriched.picture ? 'text-primary' : 'text-danger']">
            <i
              v-if="enriched.picture"
              class="material-icons mb-1">
              check_circle
            </i>
            <i
              v-else
              class="material-icons mb-1">
              block
            </i>
            <span class="pl-1">
              Picture
            </span>
          </li>
          <li
            class="list-group-item"
            :class="[enriched.shortDescription ? 'text-primary' : 'text-danger']">
            <i
              v-if="enriched.shortDescription"
              class="material-icons mb-1">
              check_circle
            </i>
            <i
              v-else
              class="material-icons mb-1">
              block
            </i>
            <span class="pl-1">
              Short Description
            </span>
          </li>
          <li
            class="list-group-item"
            :class="[enriched.longDescription ? 'text-primary' : 'text-danger']">
            <i
              v-if="enriched.longDescription"
              class="material-icons mb-1">
              check_circle
            </i>
            <i
              v-else
              class="material-icons mb-1">
              block
            </i>
            <span class="pl-1">
              Long Description
            </span>
          </li>
          <li
            class="list-group-item"
            :class="[enriched.attributes ? 'text-primary' : 'text-danger']">
            <i
              v-if="enriched.attributes"
              class="material-icons mb-1">
              check_circle
            </i>
            <i
              v-else
              class="material-icons mb-1">
              block
            </i>
            <span class="pl-1">
              Attributes
            </span>
          </li>
          <li
            class="list-group-item"
            :class="[enriched.categories ? 'text-primary' : 'text-danger']">
            <i
              v-if="enriched.categories"
              class="material-icons mb-1">
              check_circle
            </i>
            <i
              v-else
              class="material-icons mb-1">
              block
            </i>
            <span class="pl-1">
              Categories
            </span>
          </li>
          <li
            class="list-group-item"
            :class="[enriched.document ? 'text-primary' : 'text-danger']">
            <i
              v-if="enriched.document"
              class="material-icons mb-1">
              check_circle
            </i>
            <i
              v-else
              class="material-icons mb-1">
              block
            </i>
            <span class="pl-1">
              Document
            </span>
          </li>
        </ul>
      </li>
      <li
        class="list-group-item"
        :class="[enriched.level3 ? 'text-primary' : 'text-danger has-children']">
        <a
          class="level-control collapsed"
          data-toggle="collapse"
          href="#collapseLevel3"
          :aria-expanded="enrichLevel === 3 ? 'true' : 'false'"
          aria-controls="collapseLevel3">
          <h6 class="level-title">
            <i
              v-if="enriched.level3"
              class="material-icons mb-1">
              check_circle
            </i>
            <i
              v-else
              class="material-icons mb-1">
              block
            </i>
            <span>Level 3</span>
            <span class="expand-collapse-icons push-right">
              <i class="material-icons level-collapsed">
                add
              </i>
              <i class="material-icons level-show">
                remove
              </i>
            </span>
          </h6>
        </a>

        <ul
          v-if="!enriched.level3"
          id="collapseLevel3"
          class="collapse"
          :class="{ 'show': enrichLevel === 3 }"
          :aria-expanded="enrichLevel === 3 ? 'true' : 'false'">
          <li
            class="list-group-item"
            :class="[enriched.mfrCatNum ? 'text-primary' : 'text-danger']">
            <i
              v-if="enriched.mfrCatNum"
              class="material-icons mb-1">
              check_circle
            </i>
            <i
              v-else
              class="material-icons mb-1">
              block
            </i>
            <span class="pl-1">
              Mfr Cat #
            </span>
          </li>
          <li
            class="list-group-item"
            :class="[enriched.manufacturer ? 'text-primary' : 'text-danger']">
            <i
              v-if="enriched.manufacturer"
              class="material-icons mb-1">
              check_circle
            </i>
            <i
              v-else
              class="material-icons mb-1">
              block
            </i>
            <span class="pl-1">
              Manufacturer
            </span>
          </li>
          <li
            class="list-group-item"
            :class="[enriched.upc ? 'text-primary' : 'text-danger']">
            <i
              v-if="enriched.upc"
              class="material-icons mb-1">
              check_circle
            </i>
            <i
              v-else
              class="material-icons mb-1">
              block
            </i>
            <span class="pl-1">
              UPC
            </span>
          </li>
        </ul>
      </li>
      <li
        class="list-group-item"
        :class="[enriched.level4 ? 'text-primary' : 'text-danger has-children']">
        <a
          class="level-control collapsed"
          data-toggle="collapse"
          href="#collapseLevel4"
          :aria-expanded="enrichLevel === 4 ? 'true' : 'false'"
          aria-controls="collapseLevel4">
          <h6 class="level-title">
            <i
              v-if="enriched.level4"
              class="material-icons mb-1">
              check_circle
            </i>
            <i
              v-else
              class="material-icons mb-1">
              block
            </i>
            <span>Level 4</span>
            <span class="expand-collapse-icons push-right">
              <i class="material-icons level-collapsed">
                add
              </i>
              <i class="material-icons level-show">
                remove
              </i>
            </span>
          </h6>
        </a>

        <ul
          v-if="!enriched.level4"
          id="collapseLevel4"
          class="collapse"
          :class="{ 'show': enrichLevel === 4 }"
          :aria-expanded="enrichLevel === 4 ? 'true' : 'false'">
          <li
            class="list-group-item"
            :class="[enriched.associated ? 'text-primary' : 'text-danger']">
            <i
              v-if="enriched.associated"
              class="material-icons mb-1">
              check_circle
            </i>
            <i
              v-else
              class="material-icons mb-1">
              block
            </i>
            <span class="pl-1">
              Associated
            </span>
          </li>
          <li
            class="list-group-item"
            :class="[enriched.keywords ? 'text-primary' : 'text-danger']">
            <i
              v-if="enriched.keywords"
              class="material-icons mb-1">
              check_circle
            </i>
            <i
              v-else
              class="material-icons mb-1">
              block
            </i>
            <span class="pl-1">
              Keywords
            </span>
          </li>
          <li
            class="list-group-item"
            :class="[enriched.minOrderQty ? 'text-primary' : 'text-danger']">
            <i
              v-if="enriched.minOrderQty"
              class="material-icons mb-1">
              check_circle
            </i>
            <i
              v-else
              class="material-icons mb-1">
              block
            </i>
            <span class="pl-1">
              Min Order Qty
            </span>
          </li>
          <li
            class="list-group-item"
            :class="[enriched.weight ? 'text-primary' : 'text-danger']">
            <i
              v-if="enriched.weight"
              class="material-icons mb-1">
              check_circle
            </i>
            <i
              v-else
              class="material-icons mb-1">
              block
            </i>
            <span class="pl-1">
              Weight
            </span>
          </li>
        </ul>
      </li>
      <li
        class="list-group-item"
        :class="[enriched.level5 ? 'text-primary' : 'text-danger has-children']">
        <a
          class="level-control collapsed"
          data-toggle="collapse"
          href="#collapseLevel5"
          :aria-expanded="enrichLevel === 5 ? 'true' : 'false'"
          aria-controls="collapseLevel5">
          <h6 class="level-title">
            <i
              v-if="enriched.level5"
              class="material-icons mb-1">
              check_circle
            </i>
            <i
              v-else
              class="material-icons mb-1">
              block
            </i>
            <span>Level 5</span>
            <span class="expand-collapse-icons push-right">
              <i class="material-icons level-collapsed">
                add
              </i>
              <i class="material-icons level-show">
                remove
              </i>
            </span>
          </h6>
        </a>

        <ul
          v-if="!enriched.level5"
          id="collapseLevel5"
          class="collapse"
          :class="{ 'show': enrichLevel === 5 }"
          :aria-expanded="enrichLevel === 5 ? 'true' : 'false'">
          <li
            class="list-group-item"
            :class="[enriched.marketingDescription ? 'text-primary' : 'text-danger']">
            <i
              v-if="enriched.marketingDescription"
              class="material-icons mb-1">
              check_circle
            </i>
            <i
              v-else
              class="material-icons mb-1">
              block
            </i>
            <span class="pl-1">
              Marketing Description
            </span>
          </li>
          <li
            class="list-group-item"
            :class="[enriched.dimensionalInfo ? 'text-primary' : 'text-danger']">
            <i
              v-if="enriched.dimensionalInfo"
              class="material-icons mb-1">
              check_circle
            </i>
            <i
              v-else
              class="material-icons mb-1">
              block
            </i>
            <span class="pl-1">
              Dimensional Info
            </span>
          </li>
          <li
            class="list-group-item"
            :class="[enriched.multiplePictures ? 'text-primary' : 'text-danger']">
            <i
              v-if="enriched.multiplePictures"
              class="material-icons mb-1">
              check_circle
            </i>
            <i
              v-else
              class="material-icons mb-1">
              block
            </i>
            <span class="pl-1">
              Multiple Pictures
            </span>
          </li>
          <li
            class="list-group-item"
            :class="[enriched.manufacturerLogo ? 'text-primary' : 'text-danger']">
            <i
              v-if="enriched.manufacturerLogo"
              class="material-icons mb-1">
              check_circle
            </i>
            <i
              v-else
              class="material-icons mb-1">
              block
            </i>
            <span class="pl-1">
              Manufacturer Logo
            </span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'Enriched',
  props: {
    enriched: Object
  },
  data () {
    return {
      enrichLevel: 0
    }
  },
  watch: {
    'enriched' () {
      const enriched = this.enriched
      const levels = [
        enriched.level1,
        enriched.level2,
        enriched.level3,
        enriched.level4,
        enriched.level5
      ]
      for (let i = 0; i < levels.length; i++) {
        if (levels[i] === false) {
          this.enrichLevel = i + 1
          break
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../../../assets/scss/extra/mixins/flexbox";
  @import "../../../assets/scss/bootstrap-custom-variables";
  .push-right {
    float: right;
  }
  .expand-collapse-icons { display: none; }
  .product-enriched {
    margin: 1rem 0 1.5rem;
    .list-group {
      .list-group-item {
        a.level-control {
          display: block;
          color: unset;
          text-decoration: none;
          cursor: text;
        }
        &.enriched-heading {
          position: relative;
          color: $white;
          background: $brand-primary;
          text-transform: uppercase;
          padding: .75rem;
          h6 {
            margin-bottom: 0;
            text-align: center;
            @include align-self(center);
          }
          a {
            color: $white;
            position: absolute;
            right: .75rem;
            top: 50%;
            height: 1.5rem;
            margin-top: -.75rem;
            @include flexbox;
            @include justify-content(center);
            @include align-items(center);
            i {
              font-size: 1.5rem;
            }
          }
        }
        h6.level-title {
          margin: 0;
          line-height: 1.25;
        }
        &.has-children {
          padding: 0;
          .expand-collapse-icons { display: unset; }
          a.level-control {
            cursor: pointer;
            .level-show,
            .level-collapsed {
              position: relative;
              top: -2px;
              font-size: 1.5rem;
              display: none;
            }
            &[aria-expanded="false"] {
              .level-collapsed {
                display: inline-block;
              }
            }
            &[aria-expanded="true"] {
              .level-show {
                display: inline-block;
              }
            }
          }
          h6.level-title {
            position: relative;
            margin: 0;
            line-height: 1.25;
            padding: .75rem 1.25rem;
            transition: background .2s ease;
          }
          :not(.collapsed) {
            h6.level-title {
              background-color: #eee;
              &:before {
                content: '';
                position: absolute;
                left: 3.45rem;
                bottom: 0;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 6px 6px 6px;
                border-color: transparent transparent #fff transparent;
                z-index: 2;
              }
              &:after {
                content: '';
                position: absolute;
                display: block;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                box-shadow: inset 0px -10px 10px -10px #CCC, inset 0px -11px 0px -15px #ddd;
              }
            }
          }
        }
        ul {
          padding: 0px;
          li {
            border: 0;
            padding: .5rem 1.25rem;
            &:first-child {
              padding-top: 1rem;
            }
            &:last-child {
              padding-bottom: 1rem;
            }
          }
        }

      }
    }
  }
</style>
