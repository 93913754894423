<template>
  <div class="upload-zone">
    <div
      v-if="overide"
      class="small-image-error">
      <div class="p-4">
        <div class="alert alert-danger">
          This image does not meet our minimum size requirements (640px x 640px). Your image quality may be degraded after uploading, please upload a higher quality image.
        </div>
      </div>
    </div>
    <div
      class="drag-drop"
      :style="{'height': height}">
      <i class="material-icons">
        cloud_upload
      </i>
      <h5>Drag &amp; Drop Files Here</h5>
      <button
        type="button"
        class="btn btn-secondary">
        Browse
      </button>
      <input
        type="file"
        class="file-upload"
        :accept="document ? validMediaFileTypes.document : validMediaFileTypes.media"
        @change="$emit('upload', $event)" />
    </div>
    <div class="p-4">
      <div
        class="alert alert-primary"
        :class="[error && !overide ? 'alert-danger' : '']"
        role="alert">
        <strong>{{ messageType }}:</strong> {{ message }}
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import MediaFileTypesMixin from '@/views/media/types/MediaFileTypes.mixin'

export default {
  name: 'DropZone',
  mixins: [MediaFileTypesMixin],
  props: {
    overide: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      required: true,
      default: ''
    },
    messageType: {
      type: String,
      required: false,
      default: 'Information'
    },
    height: {
      type: String,
      required: false,
      default: '13.5rem'
    },
    document: {
      type: Boolean,
      required: true,
      default: false
    },
    error: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      showSmallImgMsg: false
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../../../assets/scss/extra/mixins/flexbox.scss";
  @import "../../../assets/scss/bootstrap-custom-variables.scss";
  .upload-zone {
    position: relative;
    .small-image-error {
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      background: #fff;
      z-index: 1000;
    }
    .drag-drop {
      background: $gray-300;
      @include flexbox;
      @include align-items(center);
      @include flex-direction(column);
      @include justify-content(center);
      box-shadow: inset 0 0 8px 0 rgba(0,0,0,.2);
      position: relative;
      min-height: 13.5rem;
      .material-icons {
        font-size: 4rem;
      }
      > h5 {
        margin: 1rem 0 1.5rem;
        color: $gray;
      }
      .btn {
        min-width: 10rem;
        font-weight: 500;
      }
      input.file-upload {
        opacity: 0;
        z-index: 2;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        cursor: pointer;
      }
      &:hover, &:active, &:focus {
        background: darken($gray-300, 5%);
      }
    }
    .alert {
      margin-bottom: 0;
    }
  }
</style>
