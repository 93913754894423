<template id="product-edit-tmpl">
  <div
    v-cloak
    class="product-edit">
    <section>
      <div class="row align-items-center">
        <div class="col">
          <page-title
            ref="pageTitle"
            :title="productTitle"
            :has-html="true"></page-title>
        </div>
      </div>
    </section>
    <div class="product-information-container">
      <div id="product-information-aside">
        <div class="form-group">
          <div
            v-if="!isEditor && !isAdmin"
            class="text-danger">
            Edit Permission Required
          </div>
          <button
            type="button"
            class="btn btn-primary btn-block"
            :disabled="!formEdited || !validInputs || (!isEditor && !isAdmin)"
            @click="saveProduct">
            Submit
          </button>
          <div
            v-if="lastEdited.editId"
            class="last-saved">
            <span>LAST UPDATED</span>
            <span>{{ lastEdited.editDate | dateTime }}</span>
            <span>{{ lastEdited.emailAddress | lowercase }}</span>
          </div>
        </div>

        <!--Images-->
        <images-section
          :images.sync="images"
          :idea-images.sync="ideaImages"
          :added-images.sync="addedImages"
          :removed-images.sync="removedImages"
          :language="language"
          :mfr="product.mfrId"
          @useSuccess="showIDWsuccess('Image added successfully', 'success', false)"></images-section>
        <!-- Copy From Product Dialog -->
        <copy-from-product
          :product.sync="product"
          :cats.sync="cats"
          :lang="language"
          :images.sync="images"
          :added-images.sync="addedImages"
          :removed-images.sync="removedImages"
          :attributes.sync="attributes"
          :added-attributes.sync="addedAttributes"
          :removed-attributes.sync="removedAttributes"
          :documents.sync="documents"
          :added-documents.sync="addedDocuments"
          :removed-documents.sync="removedDocuments"
          :company.sync="banners"
          :dimensions.sync="dimensions"
          :associated.sync="associatedProducts"
          :cross-sell-product-edit-list.sync="crossSellProductEditList"
          @keywordsChange="triggerKeywordsChange()"
          @featurechange="triggerFeatureChange()"></copy-from-product>

        <!-- Product Enriched Section -->
        <enriched-section :enriched="enriched"></enriched-section>

        <!-- Product Sales Tier Section -->
        <sales-tier-section :sales-tier="salesTiers"></sales-tier-section>

        <!-- Product History Section -->
        <history-section
          :id="product.napmId"
          @history="lastEdited = $event"></history-section>
        <div
          class="mt-5">
          <v-textarea
            v-model="product.productNotes"
            label="Product Notes"
            variant="outlined"
            :maxlength="500"
            auto-grow
            bg-color="grey-lighten-2">
          </v-textarea>
        </div>
      </div>

      <div id="product-edit-section">
        <div
          v-if="isPending.length"
          class="alert alert-primary">
          <i class="material-icons">
            info_outline
          </i> This product is currently pending for approval from
          <a :href="`mailto:${isPending[0].emailAddress}`">
            {{ isPending[0].emailAddress }}
          </a> on {{ isPending[0].editDate | isoDate }}.
        </div>
        <!--ERPStatus Info-->
        <erpstatus-section
          :banners.sync="banners"></erpstatus-section>

        <!--Catalog Info-->
        <catalog-section
          :product.sync="product"
          @validate-ean="validInputs = $event"></catalog-section>

        <!--Dimensions-->
        <dimensions-section
          :dimensions.sync="dimensions"
          :idw-dimensions="idwDimensions"
          :idw-loaded="idwLoaded"
          :idea-package="ideaPackage"
          :product.sync="product"></dimensions-section>

        <!--Categories-->
        <category-section
          :lang="language"
          :cats.sync="cats"
          :load.sync="loadCats"></category-section>

        <!--Banner Section-->
        <banner-section
          :banners.sync="banners"
          @bannerPropChange="bannerPropChange($event)"></banner-section>

        <!--Descriptions-->
        <descriptions-section
          :is-error="showTruncateError"
          :product.sync="product"
          :product-features.sync="product.features"
          :idw-single-values="idwSingleValues"
          :idw-loaded="idwLoaded"
          @featurechange="triggerFeatureChange()"></descriptions-section>

        <!--Attributes-->
        <attributes-section
          :language="language"
          :attributes.sync="attributes"
          :suggested-attributes="suggestedAttributes"
          :added-attributes.sync="addedAttributes"
          :removed-attributes.sync="removedAttributes"></attributes-section>

        <!--Keywords-->
        <keywords-section
          :keywords.sync="keywords"
          :keywords-search-only.sync="keywordsSearchOnly"></keywords-section>

        <!--Documents-->
        <documents-section
          :language="language"
          :documents="documents"
          :removed-documents.sync="removedDocuments"
          :added-documents.sync="addedDocuments"
          :mfr="product.mfrId"
          :idw-urls="idwUrls"
          :idw-loaded="idwLoaded"
          @upload="uploadMedia($event)"></documents-section>

        <!--Third party videos-->
        <third-party-videos-section
          :associated-videos.sync="associatedThirdPartyVideos"
          :napm-identifier="product.napmId"></third-party-videos-section>

        <!--Export Information-->
        <export-section
          :product.sync="product"
          :countries="countryList"
          :loaded="loadCats"></export-section>

        <!--Associated Section-->
        <associated-section
          :associated.sync="associatedProducts"
          :napmid="product.napmId"
          :associated-actions.sync="crossSellProductEditList"></associated-section>

        <!--Substitutes Section-->
        <substitutes-section
          :substitutes.sync="substituteProducts"
          :napmid="product.napmId"
          :substitutes-actions.sync="crossSellProductEditList"></substitutes-section>

        <!--Like Items Section-->
        <like-section
          :like-items.sync="likeItems"
          :napmid="product.napmId"
          :like-item-actions.sync="crossSellProductEditList"></like-section>

        <!--Slang Terms Section -->
        <slang-terms-section
          :slang-terms.sync="slangTerms"
          @update="getApprovedSlangTermsForProduct($route.params.id)"></slang-terms-section>

        <!--Sustainability-->
        <sustainability-section
          :product.sync="product">
        </sustainability-section>
      </div>
    </div>
    <sweet-modal
      ref="responseModal"
      :icon="modal.icon"
      :blocking="modal.blockClose"
      :title="modal.title"
      @close="resetModal()">
      <div class="text-center">
        {{ modal.message }}
      </div>
      <div slot="button">
        <div>
          <button
            type="button"
            class="btn btn-default"
            @click="resetModal()">
            Close
          </button>
          <button
            v-if="showResponseModalOkBtn()"
            type="button"
            class="btn btn-primary"
            @click="confirmLeaveTo(-1)">
            OK
          </button>
        </div>
      </div>
    </sweet-modal>
  </div>
</template>
<script type="text/javascript">
import axios from 'axios'
import PageTitleComponent from 'components/pageTitle.vue'
import SweetModalComponent from 'components/modals/sweet.vue'
import CopyFromProductSection from './copyFromProduct.vue'
import ErpStatusSection from './erpstatus.vue'
import CatalogSection from './catalog.vue'
import CategorySection from './categories.vue'
import DimensionsSection from './dimensions.vue'
import DescriptionsSection from './descriptions.vue'
import ImagesSection from './images.vue'
import AttributesSection from './attributes.vue'
import SubstitutesSection from './substitutes.vue'
import AssociatedSection from './associated.vue'
import DocumentsSection from './documents.vue'
import ThirdPartyVideosSection from './thirdpartyvideos.vue'
import KeywordsSection from './keywords.vue'
import EnrichedSection from './enriched.vue'
import HistorySection from './history.vue'
import LikeItemsSection from './like.vue'
import ExportSection from './export.vue'
import SalesTierSection from './sales-tier-section.vue'
import isEqualWith from 'lodash.isequalwith'
import lodash from 'lodash'
import BannerSection from './banners/index'
import PermissionsMixin from '@/mixins/Permissions.mixin'
import SlangTerms from './slangTerms.vue'
import SustainabilitySection from './sustainability.vue'

export default {
  name: 'ProductEdit',
  components: {
    'page-title': PageTitleComponent,
    'erpstatus-section': ErpStatusSection,
    'catalog-section': CatalogSection,
    'sweet-modal': SweetModalComponent,
    'category-section': CategorySection,
    'dimensions-section': DimensionsSection,
    'copy-from-product': CopyFromProductSection,
    'descriptions-section': DescriptionsSection,
    'substitutes-section': SubstitutesSection,
    'sustainability-section': SustainabilitySection,
    'attributes-section': AttributesSection,
    'associated-section': AssociatedSection,
    'documents-section': DocumentsSection,
    'third-party-videos-section': ThirdPartyVideosSection,
    'keywords-section': KeywordsSection,
    'enriched-section': EnrichedSection,
    'history-section': HistorySection,
    'like-section': LikeItemsSection,
    'export-section': ExportSection,
    'images-section': ImagesSection,
    'sales-tier-section': SalesTierSection,
    'banner-section': BannerSection,
    'slang-terms-section': SlangTerms
  },
  beforeRouteLeave (to, from, next) {
    if (this.formEdited && !this.confirmLeaveEdited) {
      this.modal.message = 'You have made modifications to this product.  Leaving this page will result in these changes being lost.  Do you want to proceed?'
      this.modal.blockClose = false
      this.modal.icon = 'warning'
      this.$refs.responseModal.open()
      next(false)
    } else {
      this.$route.meta.searchSend = this.$store.state.searchSend

      if (to.name === 'productsResults') {
        this.$route.meta.fromEdit = true
      }
      next()
    }
  },
  data () {
    return {
      modalAddThirdParty: false,
      product: {
        napmId: 0,
        mfrId: 0,
        mfrName: '',
        shortDescription: '',
        description: '',
        marketingDescription: '',
        features: [],
        harmonizedCode: '',
        eccn: 0,
        catNum: 0,
        countryId: '',
        upc: 0,
        noUpc: false,
        ean: 0,
        boxQty: null,
        energyStar: null,
        discontinued: null,
        madeInUSA: null,
        ecoLabelDlcPremiumInd: false,
        sustainabilityTypeId: null,
        sustainableSegmentId: null,
        sustainableOfferInd: false,
        ecoLabelEnergyStarCertificationId: 0,
        ecoLabelEnergyStarCertificationDate: '',
        ecoLabelDlcPremiumCertificationId: '',
        ecoLabelDlcPremiumCertificationDate: '',
        sustainabilityCharterInd: false,
        shippingQuoteInd: null,
        requiredFraudItemInd: null
      },
      banners: [],
      catNum: null,
      dimensions: [],
      attributes: [],
      addedAttributes: [],
      removedAttributes: [],
      suggestedAttributes: [],
      documents: [],
      addedDocuments: [],
      removedDocuments: [],
      documentUpload: {
        dialog: false,
        documentTypes: [],
        fileType: '0',
        fileInputTxt: '',
        fileInputVal: null,
        showError: false,
        errorMessage: ''
      },
      images: [],
      ideaImages: [],
      addedImages: [],
      removedImages: [],
      cats: {
        sectionId: 0,
        groupId: 0,
        categoryId: 0,
        subCategoryId: 0,
        sectionList: [],
        groupList: [],
        categoryList: [],
        subCategoryList: [],
        isValid: true
      },
      loadCats: false,
      marketingDescription: '',
      keywords: [],
      keywordsSearchOnly: [],
      modal: {
        title: null,
        message: '',
        icon: 'success',
        blockClose: false
      },
      confirmLeaveEdited: false,
      idwLoaded: false,
      idwDimensions: [],
      idwSingleValues: {},
      idwUrls: {},
      idwDialogOpen: false,
      countryList: [],
      crossSellProductEditList: [],
      substituteProducts: [],
      associatedProducts: [],
      likeItems: [],
      enriched: {},
      originalFormData: '',
      validInputs: true,
      isPending: [],
      lastEdited: {
        editId: null
      },
      showTruncateError: false,
      pageTitleDifference: 0,
      salesTiers: [],
      featureChanged: false,
      associatedThirdPartyVideos: [],
      startingDimensions: [],
      startingBanners: [],
      slangTerms: null,
      doNotShowOkButton: false,
      ideaPackage: null
    }
  },
  computed: {
    formData () {
      const mainImage = this.images.find(i => i.ordinalPosition === 1)
      return {
        napmId: this.product.napmId,
        mfrId: this.product.mfrId,
        mfrName: this.product.mfrName,
        languageId: this.language,
        mainImageId: mainImage ? mainImage.mediaId : null, // this.product.mainImageId,
        shortDescription: this.product.shortDescription,
        description: this.product.description,
        marketingDescription: this.product.marketingDescription,
        features: this.product.features.map(f => f.featureDescription),
        keywords: this.keywords.length ? this.keywords : null,
        keywordsSearchOnly: this.keywordsSearchOnly.length ? this.keywordsSearchOnly : null,
        harmonizedCode: this.product.harmonizedCode.toString().replace(/\./g, ''),
        eccn: this.product.eccn,
        catNum: this.product.catNum,
        countryId: this.product.countryId,
        upc: this.product.upc,
        noUpc: this.product.noUpc,
        energyStar: this.product.energyStar,
        boxQty: this.product.boxQty,
        discontinued: this.product.discontinued,
        madeinUSA: this.product.madeInUSA,
        ean: this.product.ean,
        subCategoryId: this.cats.subCategoryId,
        addAttributeList: this.addedAttributes,
        addMediaList: this.addedImages,
        addDimensionsList: this.getChangedDimensions(),
        addDocumentsList: this.addedDocuments,
        deleteAttributeList: this.removedAttributes,
        deleteMediaList: this.removedImages,
        deleteDocumentsList: this.removedDocuments,
        crossSellProductEditList: this.crossSellProductEditList,
        banners: this.banners,
        unspsc: this.product.unspsc,
        sustainabilityTypeId: this.product.sustainabilityTypeId,
        sustainableSegmentId: this.product.sustainableSegmentId,
        ecoLabelEnergyStarCertificationId: this.product.ecoLabelEnergyStarCertificationId,
        ecoLabelEnergyStarCertificationDate: this.product.ecoLabelEnergyStarCertificationDate,
        ecoLabelDlcPremiumInd: this.product.ecoLabelDlcPremiumInd,
        ecoLabelDlcPremiumCertificationId: this.product.ecoLabelDlcPremiumCertificationId,
        ecoLabelDlcPremiumCertificationDate: this.product.ecoLabelDlcPremiumCertificationDate,
        sustainabilityCharterInd: this.product.sustainabilityCharterInd,
        sustainableOfferInd: this.product.sustainableOfferInd,
        productNotes: this.product.productNotes,
        commodityFlag: this.product.commodityFlag,
        shippingQuoteInd: this.product.shippingQuoteInd,
        requiredFraudInd: this.product.requiredFraudInd
      }
    },
    formEdited () {
      if (this.featureChanged) {
        return true
      }
      // want to ignore 0 from falsy for number compare
      const falsy = (item) => {
        if (item === 0) return false
        return !item
      }
      const customizer = (objValue, othValue) => {
        if (falsy(objValue) && falsy(othValue)) return true
      }
      return !isEqualWith(this.formData, this.originalFormData, customizer)
    },
    language () {
      const lang = this.$store.getters.languageId
      return parseInt(lang)
    },
    viewingLanguage () {
      const langId = this.language
      return this.$store.getters.languageOptions.find((option) => {
        return option.id === langId
      })
    },
    productTitle () {
      const detail = this.product
      if (this.viewingLanguage) {
        const lang = '<i class="material-icons" style="margin-bottom:.25rem;">language</i> ' + this.viewingLanguage.name + ': '
        const mfrWidth = this.getTextWidth(detail.mfrName) || 0
        const langWidth = 30 + this.getTextWidth(` ${this.viewingLanguage.name}: `)
        const catNumWidth = this.getTextWidth(detail.catNum) || 0
        this.pageTitleDifference = Math.round(langWidth + mfrWidth + catNumWidth)
        if (detail.mfrName && detail.catNum && detail.shortDescription) {
          return lang + detail.mfrName + ' - ' + detail.catNum + ' - ' + detail.shortDescription
        } else if (detail.mfrName && detail.catNum && !detail.shortDescription) {
          return lang + detail.mfrName + ' - ' + detail.catNum
        } else if (detail.mfrName && !detail.catNum && detail.shortDescription) {
          return lang + detail.mfrName + ' - ' + detail.shortDescription
        } else if (!detail.mfrName && detail.catNum && detail.shortDescription) {
          return lang + detail.catNum + ' - ' + detail.shortDescription
        } else if (!detail.mfrName && detail.catNum && !detail.shortDescription) {
          return lang + detail.catNum
        } else if (!detail.mfrName && !detail.catNum && detail.shortDescription) {
          return lang + detail.shortDescription
        } else if (detail.mfrName && !detail.catNum && !detail.shortDescription) {
          return lang + detail.mfrName
        } else {
          return lang + ''
        }
      }
      return ''
    },
    isEditor () {
      return this.$store.getters.hasPermissionForAnyBanner(PermissionsMixin.data.Roles.editor)
    },
    isAdmin () {
      return this.$store.getters.hasPermissionForAnyBanner(PermissionsMixin.data.Roles.admin)
    }
  },
  watch: {
    language (value, oldVal) {
      this.getProductEditDetails(this.language)
    },
    'product.shortDescription' () {
      this.checkIfTruncated()
    },
    '$route' (newRoute, oldRoute) {
      if (newRoute.params.id !== oldRoute.params.id) {
        this.getProduct()
      }
    }
  },
  mounted () {
    this.getProduct()
  },
  methods: {
    getProduct () {
      this.$store.dispatch('setSpinner', true)
      localStorage.setItem('napm__id', this.$route.params.id)
      localStorage.setItem('napm__title', `IDW - ${this.$route.params.id}`)
      this.$refs.responseModal.close()
      this.getCountries()
      this.getProductEditDetails(this.$store.getters.languageId)
      this.fetchIdwData()
      this.fetchIdeaPackage()
      this.getProductPendingStatus(this.$route.params.id)
      this.getApprovedSlangTermsForProduct(this.$route.params.id)
    },
    showResponseModalOkBtn () {
      if (this.formEdited && !this.doNotShowOkButton) {
        return true
      }
      return false
    },
    isFormInvalid () {
      if (this.formData.sustainabilityTypeId === 1 && this.formData.sustainableSegmentId == null) {
        this.modal.message = 'Sustainable Segment is required.'
        this.modal.blockClose = false
        this.modal.icon = 'warning'
        this.$refs.responseModal.open()
        this.$store.dispatch('setSpinner', false)
        this.doNotShowOkButton = true
        return true
      }

      if (this.formData.ecoLabelEnergyStarCertificationId > 2147483647) {
        this.modal.message = 'Eco Label EnergyStar Certification is invalid.'
        this.modal.blockClose = false
        this.modal.icon = 'warning'
        this.$refs.responseModal.open()
        this.$store.dispatch('setSpinner', false)
        this.doNotShowOkButton = true
        return true
      }
      return false
    },
    getVideoUrls () {
      this.$http.get('/api/media/externalvideos/videos')
        .then(res => {
          this.videoUrlList = res.data
        })
    },
    triggerFeatureChange () {
      this.featureChanged = true
      this.formData.features = this.product.features.map(f => f.featureDescription)
    },
    bannerPropChange (val) {
      if (val === 'rexelca') {
        return
      }

      const bannerObj = this.banners.find(i => i.bannerName.toLowerCase() === val.toLowerCase())
      const startingBannerObj = this.startingBanners.find(i => i.bannerName.toLowerCase() === val.toLowerCase())

      if (bannerObj != null) {
        bannerObj.changed = !isEqualWith(bannerObj, startingBannerObj, (value1, value2, key) => {
          return key === 'changed' ? true : undefined
        })
      }
    },
    triggerKeywordsChange () {
      this.keywords = (this.product.keywords) ? this.product.keywords.split(/[,]+/).filter((item) => item.trim() !== '') : []
      this.keywordsSearchOnly = (this.product.keywordsSearchOnly) ? this.product.keywordsSearchOnly.split(';').filter((item) => item.trim() !== '') : []
    },
    confirmLeaveTo (routeName) {
      this.confirmLeaveEdited = true
      if (routeName === -1) {
        this.$router.go(-1)
      } else {
        this.$router.push({ name: routeName })
      }
    },
    getCountries () {
      const data = {
        params: { languageId: this.language }
      }
      axios.get('/api/countries', data)
        .then(res => {
          this.countryList = res.data
        }).catch(e => console.warn(e))
    },
    getProductPendingStatus (id) {
      axios.get(`/api/approvals/pending/${id}?languageId=${this.language}`)
        .then(res => {
          this.isPending = res.data.length ? res.data : []
        }).catch(e => {
          console.log(e)
        })
    },
    onChangeTriggerHandler () {
      this.getVideoUrlsForProduct()
    },
    getVideoUrlsForProduct () {
      const data = {
        params: {
          napmId: this.product.napmId
        }
      }
      axios.get('/api/media/externalvideos/videosforproduct', data)
        .then(res => {
          this.associatedThirdPartyVideos = res.data
        })
    },
    getChangedDimensions () {
      return this.dimensions.filter(o => this.startingDimensions.some(x => o.dimension === x.dimension && (o.dimensionValue !== x.dimensionValue || o.uomId !== x.uomId)))
    },
    getProductEditDetails (lang) {
      axios.get(`/api/products/${this.$route.params.id}?languageId=${lang}`)
        .then(res => {
          const product = res.data
          const enriched = product.productEnrichment
          this.product = product.singleValues
          const orderedFeatures = lodash.orderBy(product.features, 'featureNumber')
          for (let i = 0; i < 10 - product.features.length; i++) {
            orderedFeatures.push({ featureDescription: null })
          }
          this.product.features = orderedFeatures
          this.documents = product.documents
          this.images = product.media
          this.ideaImages = product.ideaImage
          this.enriched = {
            // Level 1
            level1: enriched.level1IsEnriched,
            // Level 2
            level2: enriched.level2IsEnriched,
            picture: enriched.pictureIsEnriched,
            shortDescription: enriched.shortDescriptionIsEnriched,
            longDescription: enriched.longDescriptionIsEnriched,
            attributes: enriched.attributesIsEnriched,
            categories: enriched.categoryIsEnriched,
            document: enriched.documentIsEnriched,
            // Level 3
            level3: enriched.level3IsEnriched,
            mfrCatNum: enriched.mfrCatNumIsEnriched,
            manufacturer: enriched.manufacturerIsEnriched,
            upc: enriched.upcIsEnriched,
            // Level 4
            level4: enriched.level4IsEnriched,
            associated: enriched.associatedIsEnriched,
            keywords: enriched.keywordsIsEnriched,
            keywordsSearchOnly: enriched.keywordsSearchOnlyIsEnriched,
            minOrderQty: enriched.minOrderQtyIsEnriched,
            weight: enriched.weightIsEnriched,
            // Level 5
            level5: enriched.level5IsEnriched,
            marketingDescription: enriched.marketingDescriptionIsEnriched,
            dimensionalInfo: enriched.dimensionalInfoIsEnriched,
            multiplePictures: enriched.multiplePicturesIsEnriched,
            manufacturerLogo: enriched.manufacturerLogoIsEnriched
          }

          this.getVideoUrlsForProduct()

          this.likeItems = product.likeItems
          this.substituteProducts = product.substituteProducts
          this.associatedProducts = product.associatedProducts

          this.cats.sectionId = product.singleValues.sectionId
          this.cats.groupId = product.singleValues.groupId
          this.cats.categoryId = product.singleValues.categoryId
          this.cats.subCategoryId = product.singleValues.subCategoryId

          this.marketingDescription = product.singleValues.marketingDescription
          this.salesTiers = product.salesTiers
          this.dimensions = product.dimensions
          this.startingDimensions = product.dimensions.map(a => ({ ...a }))
          this.startingBanners = product.banners.map(a => ({ ...a }))
          this.banners = product.banners

          this.keywords = (product.singleValues.keywords) ? product.singleValues.keywords.split(/[,]+/).filter((item) => item.trim() !== '') : []
          this.keywordsSearchOnly = (product.singleValues.keywordsSearchOnly) ? product.singleValues.keywordsSearchOnly.split(';').filter((item) => item.trim() !== '') : []

          this.attributes = product.attributes
          this.suggestedAttributes = product.suggestedAttributes

          if (this.product.ecoLabelDlcPremiumCertificationDate !== null) {
            this.product.ecoLabelDlcPremiumCertificationDate = new Date(this.product.ecoLabelDlcPremiumCertificationDate).toISOString().split('T')[0]
          } else {
            this.product.ecoLabelDlcPremiumCertificationDate = null
          }
          if (this.product.ecoLabelEnergyStarCertificationDate !== null) {
            this.product.ecoLabelEnergyStarCertificationDate = new Date(this.product.ecoLabelEnergyStarCertificationDate).toISOString().split('T')[0]
          } else {
            this.product.ecoLabelEnergyStarCertificationDate = null
          }
        })
        .catch(() => {
          this.$router.push('/products')
        })
        .then(done => {
          this.loadCats = true
          this.$store.dispatch('setSpinner', false)
          this.originalFormData = JSON.parse(JSON.stringify(this.formData))
        })
    },
    fetchIdwData () {
      this.$http.get(`/api/idw/${this.$route.params.id}`)
        .then(res => {
          const data = res.data
          const index = data.dimensions.findIndex(i => i.name === 'Volume')
          this.$delete(data.dimensions, index)
          this.idwDimensions = data.dimensions
          this.idwSingleValues = data.singleValues
          this.idwUrls = data.urls
        }).catch(e => {
          console.warn(e)
        }).then(() => {
          this.idwLoaded = true
        })
    },
    fetchIdeaPackage () {
      axios.get(`/api/idw/${this.$route.params.id}/idea`)
        .then(res => {
          this.ideaPackage = res.data
        })
        .catch(e => {
          console.error(e)
        })
    },
    saveProduct () {
      if (!this.isEditor && !this.isAdmin) {
        return
      }

      this.$store.dispatch('setSpinner', true)

      if (!this.validInputs) {
        this.modal.message = 'Some inputs not valid.'
        this.modal.blockClose = false
        this.modal.icon = 'warning'
        this.$refs.responseModal.open()
        this.$store.dispatch('setSpinner', false)
        return
      }

      if (this.isFormInvalid()) {
        return
      }

      if (!this.cats.isValid) {
        this.$store.dispatch('setSpinner', false)
        this.modal.message = 'Sub Category must be selected'
        this.modal.blockClose = false
        this.modal.icon = 'warning'
        this.$refs.responseModal.open()
      } else if (!this.formEdited) {
        this.$store.dispatch('setSpinner', false)
        this.modal.message = 'You must make edits before submitting product.'
        this.modal.blockClose = false
        this.modal.icon = 'warning'
        this.$refs.responseModal.open()
      } else {
        if (this.formData.madeinUSA === 'null') {
          this.formData.madeinUSA = null
        }
        this.formData.banners.forEach((banner) => {
          if (banner.showOnWeb === 'null') {
            banner.showOnWeb = null
          }
        })
        axios.put('/api/products', this.formData)
          .then(res => {
            this.addedImages = []
            this.addedDocuments = []
            this.addedAttributes = []
            this.removedImages = []
            this.removedDocuments = []
            this.removedAttributes = []
            this.modal.message = 'Successfully Saved Changes!'
            this.modal.blockClose = false
            this.modal.icon = 'Success'
            this.$refs.responseModal.open()
            this.getProductEditDetails(this.language)
          }).catch((err) => {
            console.warn(err)
            this.$store.dispatch('setSpinner', false)
            this.modal.message = 'An Error Occured...'
            this.modal.blockClose = true
            this.modal.icon = 'error'
            this.$refs.responseModal.open()
          }).finally(() => {
            this.$store.dispatch('setSpinner', false)
          })
      }
    },
    resetModal () {
      if (this.$refs.responseModal.visible) {
        this.$refs.responseModal.close()
      }
      this.doNotShowOkButton = false
      this.modal = {
        title: null,
        message: '',
        icon: 'Success',
        blockClose: false
      }
    },

    showIDWsuccess (msg, icon, blockClose) {
      this.idwDialogOpen = false
      this.$store.dispatch('setSpinner', false)
      this.modal.message = msg
      this.modal.blockClose = blockClose
      this.modal.icon = icon
      this.$refs.responseModal.open()
    },
    getTextWidth (txt) {
      if (!this.getTextWidth.c) {
        this.getTextWidth.c = document.createElement('canvas')
        this.getTextWidth.ctx = this.getTextWidth.c.getContext('2d')
      }
      this.getTextWidth.ctx.font = "16px 'Verdana'"
      return this.getTextWidth.ctx.measureText(txt).width
    },
    checkIfTruncated () {
      const maxLabelWidthFromPlattDotCom = 626
      const rawLabel = this.product.mfrName + ' ' + this.product.catNum + ' ' + this.product.shortDescription
      const actualLabelWidth = Math.round(this.getTextWidth(rawLabel))
      this.showTruncateError = actualLabelWidth > maxLabelWidthFromPlattDotCom
    },
    getApprovedSlangTermsForProduct (napmId) {
      axios.get('/api/SlangTerms/GetSlangTerms/approved/' + napmId)
        .then(res => {
          this.slangTerms = res.data[0]
        })
        .catch(res => {
          this.handleHttpError(res, 'Unable to retrieve slang terms at this time.')
        })
    },
    handleHttpError (res, message) {
      this.loading = false
      this.showSpinner = false
      const errors = this.getErrorData(res)
      if (errors && errors.length > 0) {
        console.error(errors)
      } else {
        console.error(res)
      }
    }
  },
  template: '#product-edit-tmpl'
}

</script>
<style lang="scss">
@import "../../../assets/scss/bootstrap-custom-variables";
@import "../../../assets/scss/extra/mixins/flexbox.scss";
.product-edit {
  .btn {
    cursor: pointer;
  }
  .last-saved {
    padding-top: 0.5rem;
    text-align: center;
    color: #999;
    display: block; /* Fallback for non-webkit */
    //display: -webkit-box;
    max-width: 100%;
    // height: (0.8125rem * 1.5 * 2) + 0.5rem; /* Fallback for non-webkit */
    font-size: 0.8125rem;
    line-height: 1.5;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    span {
      display: block;
    }
  }
}
.product-edit section {
  margin-bottom: 0;
  .section-heading {
    width: 100%;
    padding: 1rem 0 0.5rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #ddd;
    font-size: 1.125rem;
    position: relative;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      padding: 0;
      border: none;
      font-weight: 600;
    }
  }

  .custom-control-description {
    font-size: 0.875rem;
  }
  .custom-control-indicator {
    top: 0.125rem;
  }
}

div.product-information-container {
  &::after {
    content: "";
    height: 100%;
    display: block;
    clear: both;
  }
  div#product-information-aside {
    @media (min-width: 60rem) {
      padding-left: 1rem;
      width: 16.625rem;
      float: right;
    }
  }
  div#product-edit-section {
    @media (min-width: 60rem) {
      width: calc(100% - 17.625rem);
      float: left;
    }
  }

  section#catalog-info,
  section#dimensions,
  section#categories,
  div#product-pim {
    div.section-body {
      @media (min-width: 37.5rem) {
        @include flexbox;
        @include flex-wrap(wrap);
        @include justify-content(space-between);
      }
      .form-group {
        width: 100%;
        @media (min-width: 37.5rem) {
          width: calc(50% - 1rem);
        }
        @media (min-width: 1200px) {
          width: calc(25% - 1rem);
        }
        input[readonly],
        input[disabled="disabled"] {
          border: 1px solid transparent;
          background-color: #ececec;
        }
      }
    }
  }
}

.btn-idw {
  > span {
    padding-top: 0.25rem;
    position: relative;
    @include flexbox;
    @include justify-content(space-between);

    &.idw-loaded {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 0.875rem;

      @include justify-content(center);
    }
    .sync {
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -0.5rem;
      -webkit-animation: rotating 2s infinite reverse linear;
      -moz-animation: rotating 2s infinite reverse linear;
      -ms-animation: rotating 2s infinite reverse linear;
      -o-animation: rotating 2s infinite reverse linear;
      animation: rotating 2s infinite reverse linear;
    }
  }
}
</style>
