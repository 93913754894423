<template id="paginationTmpl">
  <nav
    aria-label="Page navigation"
    class="row justify-content-between">
    <div class="col-xs-12 col-md-10">
      <ul
        v-if="pagination.last_page > 0 || noHide"
        class="pagination">
        <li
          v-if="showPrevious()"
          :class="{'page-item': true, 'disabled' : pagination.current_page <= 1}">
          <span
            v-if="pagination.current_page <= 1"
            class="page-link">
            <span aria-hidden="true">
              {{ config.previousText }}
            </span>
          </span>

          <a
            v-if="pagination.current_page > 1 "
            href="#"
            :aria-label="config.ariaPrevious"
            class="page-link"
            @click.prevent="changePage(pagination.current_page - 1)">
            <span aria-hidden="true">
              {{ config.previousText }}
            </span>
          </a>
        </li>
        <li
          v-for="num in array"
          :key="num"
          :class="{ 'page-item': true, 'active': num == pagination.current_page}">
          <a
            href="#"
            class="page-link"
            @click.prevent="changePage(num)">{{ num }}</a>
        </li>
        <li
          v-if="showNext()"
          :class="{ 'page-item': true, 'disabled' : pagination.current_page === pagination.last_page || pagination.last_page === 0 }">
          <span
            v-if="pagination.current_page === pagination.last_page || pagination.last_page === 0"
            class="page-link">
            <span aria-hidden="true">
              {{ config.nextText }}
            </span>
          </span>

          <a
            v-if="pagination.current_page < pagination.last_page"
            href="#"
            :aria-label="config.ariaNext"
            class="page-link"
            @click.prevent="changePage(pagination.current_page + 1)">
            <span aria-hidden="true">
              {{ config.nextText }}
            </span>
          </a>
        </li>
      </ul>
    </div>
    <div
      v-if="pager"
      class="col-xs-12 col-md-2">
      <div
        v-if="pagination.last_page > 0 || noHide"
        class="input-group paging-size-select">
        <span class="input-group-addon">
          <i class="material-icons">
            &#xE8EF;
          </i>
        </span>
        <select
          v-model="pagination.per_page"
          class="form-control">
          <option
            v-for="pageSize in options.pageSizes"
            :key="pageSize"
            :value="pageSize">
            {{ pageSize }}
          </option>
        </select>
      </div>
    </div>
  </nav>
</template>
<script type="text/javascript">
export default {
  name: 'Pagination',
  props: {
    pagination: {
      type: Object,
      required: true
    },
    callback: {
      type: Function,
      required: true
    },
    options: {
      type: Object,
      default: () => {
        return {
          offset: 5,
          previousText: 'Prev',
          nextText: 'Next',
          alwaysShowPrevNext: true,
          pageSizes: [25, 50, 100, 150, 250, 500]
        }
      }
    },
    noHide: {
      type: Boolean,
      default: false,
      required: false
    },
    pager: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  computed: {
    array () {
      if (this.pagination.last_page <= 0) {
        return []
      }

      let from = this.pagination.current_page - this.config.offset
      if (from < 1) {
        from = 1
      }

      let to = from + (this.config.offset * 2)
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page
      }

      const arr = []
      while (from <= to) {
        arr.push(from)
        from++
      }
      return arr
    },
    config () {
      return Object.assign({
        offset: 5,
        ariaPrevious: 'Previous',
        ariaNext: 'Next',
        previousText: '«',
        nextText: '»',
        alwaysShowPrevNext: false
      }, this.options)
    }
  },
  watch: {
    'pagination.per_page' () {
      this.$set(this.pagination, 'current_page', 1)
      this.callback(true)
    }
  },
  methods: {
    showPrevious () {
      return this.config.alwaysShowPrevNext || this.pagination.current_page > 1
    },
    showNext () {
      return this.config.alwaysShowPrevNext || this.pagination.current_page < this.pagination.last_page
    },
    changePage (page) {
      if (this.pagination.current_page === page) {
        return
      }
      this.$set(this.pagination, 'current_page', page)
      this.callback()
    }
  },
  template: '#paginationTmpl'
}
</script>
<style scoped>
ul.pagination {
  margin-bottom: 0;
}
.paging-size-select {
  margin-bottom: 1rem;
  max-width: 8rem;
  float: right;
}
</style>
