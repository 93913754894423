<template>
  <div class="company-filter">
    <multi-select
      v-model="selectedCompany"
      :searchable="false"
      :local-search="false"
      :options="companies"
      label="label"
      select-label=""></multi-select>
  </div>
</template>
<script type="text/javascript">
import Multiselect from 'components/inputs/multiselect.vue'

export default {
  name: 'SelectCompany',
  components: {
    'multi-select': Multiselect
  },
  props: {
    allLabel: {
      type: String,
      default: 'All'
    },
    setUsersDefault: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      companies: [],
      selectedCompany: null
    }
  },
  watch: {
    selectedCompany (val) {
      this.$emit('input', val)
    }
  },
  mounted () {
    this.getCompanies()
    const defaultCompanyId = this.setUsersDefault ? this.$store.getters.user.defaultCompany : 0
    this.selectedCompany = this.getCompany(this.companies, 'id', defaultCompanyId)
  },
  methods: {
    getCompanies () {
      this.companies = this.$store.getters.companyList
        // For now, we'll remove Rexel CA as an option since it will crash dashboard stored proc
        .filter(company => company.name !== 'Rexel CA')
        .map((company) => {
          return {
            label: company.name,
            id: company.companyId
          }
        })
      this.companies.unshift({ label: this.allLabel, id: null })
    },
    getCompany (companies, propName, value) {
      for (let ii = 0; ii < companies.length; ii++) {
        const company = companies[ii]
        if (company[propName] === value) {
          return company
        }
      }
    }
  }
}
</script>
<style lang="scss">

</style>
