<template>
  <div class="sustainability-page">
    <page-title title="Sustainability"></page-title>
    <strong>{{ isEdit ? 'Edit' : 'Add' }} Sustainable Segment</strong>
    <div
      class="d-flex"
      style="margin-top: 10px;">
      <div
        class="form-group">
        <label
          class="form-control-label">Display Position</label>
        <input
          v-model="displayPosition"
          class="form-control"
          type="number"
          style="width: 150px; margin-right:10px;" />
      </div>
      <div
        class="form-group">
        <label
          class="form-control-label">Sustainable Segment</label>
        <input
          v-model="sustainableSegment"
          class="form-control"
          maxlength="40"
          style="width: 700px; margin-right:10px;" />
      </div>
    </div>
    <div style="margin-bottom: 10px;">
      <button
        class="btn btn-link btn-rounded"
        @click="clearForm()">
        {{ isEdit ? 'Cancel Edit' : 'Clear' }}
      </button>
      <button
        class="btn btn-primary btn-rounded"
        :disabled="sustainableSegment == null || displayPosition == null || sustainableSegment == '' || displayPosition == ''"
        @click="saveForm()">
        {{ isEdit ? 'Save Edit' : 'Add' }}
      </button>
    </div>
    <data-table
      :headers="sustainabilityHeaders"
      :items="sustainableSegments">
      <div
        slot="body-cell-1"
        slot-scope="props"
        class="url-text"
        :item="props.item">
        <i
          class="material-icons"
          style="cursor:pointer"
          @click="editSegment(props.item.id, props.item.displayPosition, props.item.name)">
          edit
        </i>
      </div>
      <div
        slot="body-cell-2"
        slot-scope="props"
        class="url-text"
        :item="props.item">
        <strong>{{ props.item.displayPosition }}</strong>
      </div>
      <div
        slot="body-cell-3"
        slot-scope="props"
        class="url-text"
        :item="props.item">
        <strong>{{ props.item.name }}</strong>
      </div>
      <div
        slot="body-cell-4"
        slot-scope="props"
        class="url-text"
        :item="props.item">
        <i
          class="material-icons"
          style="cursor:pointer"
          @click="showDeleteModal(props.item.id, props.item.name)">
          delete
        </i>
      </div>
    </data-table>
    <sweet-modal
      ref="responseModal"
      :icon="modal.icon"
      :blocking="modal.blockClose"
      :title="modal.title"
      @close="resetModal()">
      <div class="text-center">
        {{ modal.message }}
      </div>
      <div slot="button">
        <div>
          <button
            type="button"
            class="btn btn-default"
            @click="resetModal()">
            Close
          </button>
          <button
            v-if="showModalDeleteButton"
            type="button"
            class="btn btn-primary"
            style="margin-left: 5px"
            @click="deleteSegment()">
            Delete
          </button>
        </div>
      </div>
    </sweet-modal>
  </div>
</template>

<script type="text/javascript">
import axios from 'axios'
import TitleComponent from 'components/pageTitle.vue'
import DataTable from 'components/data-table/DataTable.vue'
import SweetModalComponent from 'components/modals/sweet.vue'

function PaginationDefault () {
  this.total = 0
  this.per_page = 25
  this.current_page = 1
  this.last_page = 0
  this.from = 0
  this.to = 15
}

export default {
  name: 'SustainabilityPage',
  components: {
    'page-title': TitleComponent,
    'sweet-modal': SweetModalComponent,
    DataTable
  },
  data () {
    return {
      sustainableSegments: null,
      pagination: new PaginationDefault(),
      search: '',
      sustainabilityHeaders: [
        {
          text: '',
          width: '2%',
          sortable: false
        },
        {
          text: 'Display Position',
          value: 'displayPosition',
          width: '8%'
        },
        {
          text: 'Sustainable Segment Name',
          value: 'name',
          width: '88%'
        },
        {
          text: '',
          width: '2%',
          sortable: false
        }
      ],
      sustainableSegment: null,
      displayPosition: null,
      isEdit: false,
      editedSegmentId: null,
      modal: {
        title: null,
        message: '',
        icon: 'success',
        blockClose: false
      },
      showModalDeleteButton: false
    }
  },
  computed: {
    language () {
      return this.$store.getters.languageId
    }
  },
  mounted () {
    this.$refs.responseModal.close()
    this.getSustainableSegments()
  },
  methods: {
    resetModal () {
      if (this.$refs.responseModal.visible) {
        this.$refs.responseModal.close()
      }

      if (this.showModalDeleteButton) {
        this.showModalDeleteButton = false
        this.clearForm()
      }

      this.modal = {
        title: null,
        message: '',
        icon: 'success',
        blockClose: false
      }
    },
    showDeleteModal (id, name) {
      this.editSegmentId = id
      this.sustainableSegment = name
      this.modal.message = 'Are you sure you want to delete this segment "' + this.sustainableSegment + '"?'
      this.modal.blockClose = true
      this.modal.icon = 'warning'
      this.$refs.responseModal.open()
      this.showModalDeleteButton = true
    },
    getSustainableSegments () {
      return axios.get('/api/sustainability/segment')
        .then(res => {
          this.sustainableSegments = res.data
        })
        .catch(e => {
          console.error(e)
          this.modal.message = 'An Error Occured...'
          this.modal.blockClose = true
          this.modal.icon = 'error'
          this.$refs.responseModal.open()
        })
    },
    editSegment (id, displayPosition, name) {
      this.editSegmentId = id
      this.isEdit = true
      this.displayPosition = displayPosition
      this.sustainableSegment = name
    },
    clearForm () {
      this.editSegmentId = null
      this.isEdit = false
      this.displayPosition = null
      this.sustainableSegment = null
    },
    saveForm () {
      if (this.displayPosition === null || this.sustainableSegment === null) {
        return
      }

      if (this.displayPosition > 2147483647) {
        this.modal.message = 'Display Position is invalid.'
        this.modal.blockClose = false
        this.modal.icon = 'warning'
        this.$refs.responseModal.open()
        return true
      }

      if (typeof this.displayPosition === 'string') {
        this.displayPosition = this.displayPosition.trim()
      }
      this.sustainableSegment = this.sustainableSegment.trim()

      const data = {
        SustainableSegmentName: this.sustainableSegment,
        DisplayPosition: this.displayPosition
      }

      if (this.isEdit) {
        if (this.editSegmentId == null) {
          this.modal.message = 'Please refresh page before updating newly added segments'
          this.modal.blockClose = false
          this.modal.icon = 'warning'
          this.$refs.responseModal.open()
          return
        }

        const segment = this.sustainableSegments.find(x => x.id === this.editSegmentId)

        // eslint-disable-next-line eqeqeq
        if (segment.name == this.sustainableSegment && segment.displayPosition == this.displayPosition) {
          this.modal.message = 'Error saving. There was no change.'
          this.modal.blockClose = false
          this.modal.icon = 'warning'
          this.$refs.responseModal.open()
          return
        }
        // eslint-disable-next-line eqeqeq
        if (this.sustainableSegments.find(x => x.id != this.editSegmentId && (x.name == this.sustainableSegment || x.displayPosition == this.displayPosition))) {
          this.modal.message = 'Sustainable Segment or Display Position already exists'
          this.modal.blockClose = false
          this.modal.icon = 'warning'
          this.$refs.responseModal.open()
          return
        }

        data.SustainableSegmentId = this.editSegmentId
        axios.put('api/sustainability', data)
          .then(res => {
            segment.name = this.sustainableSegment
            segment.displayPosition = this.displayPosition
            this.clearForm()

            this.modal.message = 'Sustainable Segment update successful'
            this.modal.blockClose = false
            this.modal.icon = 'Success'
            this.$refs.responseModal.open()
          })
          .catch((e) => {
            console.log(e)
            this.modal.message = 'An Error Occured...'
            this.modal.blockClose = true
            this.modal.icon = 'error'
            this.$refs.responseModal.open()
          })
      } else {
        // eslint-disable-next-line eqeqeq
        if (this.sustainableSegments.find(x => x.name == this.sustainableSegment || x.displayPosition == this.displayPosition)) {
          this.modal.message = 'Sustainable Segment or Display Position already exists'
          this.modal.blockClose = false
          this.modal.icon = 'warning'
          this.$refs.responseModal.open()
          return
        }

        axios.post('api/sustainability', data)
          .then(res => {
            this.sustainableSegments.push({
              id: this.editSegmentId,
              name: this.sustainableSegment,
              displayPosition: this.displayPosition
            })
            this.modal.message = 'Sustainable Segment save successful'
            this.modal.blockClose = false
            this.modal.icon = 'Success'
            this.$refs.responseModal.open()
            this.clearForm()
          })
          .catch((e) => {
            console.log(e)
            this.modal.message = 'An Error Occured...'
            this.modal.blockClose = true
            this.modal.icon = 'error'
            this.$refs.responseModal.open()
          })
      }
    },
    deleteSegment () {
      if (this.editSegmentId == null) {
        this.modal.message = 'Please refresh page before deleting newly added segments'
        this.modal.blockClose = false
        this.modal.icon = 'warning'
        this.$refs.responseModal.open()
        return
      }
      axios.delete('api/sustainability/' + this.editSegmentId)
        .then(res => {
          this.clearForm()
          this.showModalDeleteButton = false
          const segment = this.sustainableSegments.find(x => x.id === this.editSegmentId)
          this.sustainableSegments.pop(segment)
          this.modal.message = 'Sustainable Segment delete successful'
          this.modal.blockClose = false
          this.modal.icon = 'Success'
          this.$refs.responseModal.open()
        })
        .catch((e) => {
          console.log(e)
          this.modal.message = 'An Error Occured...'
          this.modal.blockClose = true
          this.modal.icon = 'error'
          this.$refs.responseModal.open()
        })
    }
  }
}
</script>
  <style lang="scss" scoped>
  .material-icons {
    position: absolute;
    font-size: 1.0rem;

    top: 60%;
    right: 60%;
    margin: -0.75rem -0.825rem 0 0;
  }
  </style>
