<template>
  <div>
    <template v-if="type === 'Image'">
      <div :class="[size, 'img']">
        <img
          class="img-fluid"
          :src="imgPath" />
      </div>
    </template>
    <template v-else-if="type === 'Video'">
      <video-icon
        :class-attr="file | extension(file, true)"
        :size="size"></video-icon>
    </template>
    <template v-else>
      <document-icon
        :class-attr="file | extension(file, false)"
        :size="size"></document-icon>
    </template>
  </div>
</template>
<script type="text/javascript">
import VideoIcon from 'components/icons/videoIcon.vue'
import DocumentIcon from 'components/icons/documentIcon.vue'

export default {
  name: 'MediaIcon',
  filters: {
    extension (param, value, video) {
      if (!value !== null && value !== undefined) {
        if (value[value.length - 4] === '.') {
          const returnVal = value.toLowerCase().substring(value.length - 3, value.length)
          return returnVal
        } else if (video && !value[value.length - 4] !== '.') {
          return 'mp4'
        } else {
          return 'web'
        }
      }
    }
  },
  components: {
    'video-icon': VideoIcon,
    'document-icon': DocumentIcon
  },
  props: {
    type: String,
    imgPath: String,
    file: String,
    size: {
      type: String,
      default: 'icon-spec'
    }
  },
  methods: {
    iconName () {
      return this.type
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../../assets/scss/extra/mixins/flexbox.scss";

  .icon-spec {
    margin: 0  auto 1.5rem;
    height: 6rem;
    width: 6rem;
  }
  .media-icon-large {
    margin: 1rem auto;
    width: 10rem;
    height: 12rem;
  }
  .media-list-icon {
    width: 4.25rem;
    height: 4.25rem;
    &:not(.img) {
      margin: 1rem auto .75rem;
    }
    &.img {
      width: 4.8125rem;
      height: 4.8125rem;
      overflow: hidden;
      margin: 1rem auto .25rem;
      @include flexbox;
      @include justify-content(center);
      img {
        border-radius: 3px;
        @include align-self(center);
      }
    }
  }
  .media-icon-preview {
    margin: 0 auto 1rem;
    &:not(.img) {
      padding: 1rem 0;
      width: 7rem;
      height: 8.5rem;
    }
    &.img {
      width: 9.375rem;
      height: 9.375rem;
      padding-top: 1rem;
    }
  }
  .media-icon-thumbnail {
    width: 3rem;
    height: 3rem;
    overflow: hidden;
  }
  .media-large-preview {

  }
</style>
