// IE polyfills
import 'babel-polyfill'
import 'es6-promise/auto'
import 'assets/scss/site.scss'
import 'popper.js'
import 'bootstrap/js/dist/tab'
import 'bootstrap/js/dist/util'
import 'bootstrap/js/dist/alert'
import 'bootstrap/js/dist/tooltip'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/collapse'
import Vue from 'vue'
import App from './app'
import VueMask from 'v-mask'
import Toasted from 'vue-toasted'
import Vuelidate from 'vuelidate'
import VueRecyclerview from 'vue-recyclerview'
import VueLocalStorage from 'vue-localstorage'
import VueAnalytics from 'vue-analytics'
import VToolTip from 'v-tooltip'
import axios from 'axios'
import store from '@/store'
import {
  router
} from './router'
import { sync } from 'vuex-router-sync'
import VDragged from 'v-dragged'
import vuetify from './vuetify'

require('./globals/filters')

Vue.use(VToolTip)
Vue.use(VDragged)
Vue.use(VueMask)
Vue.use(Vuelidate)
Vue.use(VueRecyclerview)
Vue.use(VueLocalStorage)
Vue.use(Toasted, {
  router
})

require('assets/scss/palert.scss') // eslint-disable-line import/extensions

const isProd = process.env.NODE_ENV === 'production'

Vue.use(VueAnalytics, {
  id: 'UA-10814830-12',
  router,
  debug: {
    sendHitTask: isProd
  }
})

sync(store, router)

Vue.config.productionTip = false

console.log(`using: ${JSON.stringify(process.env)}`)
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

Vue.prototype.$http = axios

// App startup
async function initData () {
  return await Promise.all([
    axios.get('/account/config'),
    axios.get('/api/users/getAllCompanies'),
    axios.get('/api/banners')
  ])
}

initData()
  .then(async (responseData) => {
    const [
      configRes,
      companiesRes,
      bannersRes
    ] = responseData

    store.dispatch('setAppConfig', configRes.data)
    store.dispatch('setCompanyList', companiesRes.data)
    store.dispatch('setBannersList', bannersRes.data)

    /* eslint-disable no-new */
    new Vue({
      vuetify,
      el: '#app',
      router,
      store,
      components: {
        App
      },
      data: {
        transitionName: 'slide-fade-left'
      },
      watch: {
        $route (to, from) {
          const toDepth = to.path.split('/').length
          const fromDepth = from.path.split('/').length
          this.transitionName =
            toDepth < fromDepth ? 'slide-fade-right' : 'slide-fade-left'
        }
      },
      template: '<App :transition-name="transitionName" />'
    })
  })
  .catch((er) => {
    console.error(er)
  })
