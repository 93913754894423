<template>
  <div>
    <page-title :title="'Attribute - ' + attributeType.type + ' Values'"></page-title>
    <div
      class="spinner"
      :class="{'show-spinner': showSpinner}"></div>

    <div class="jumbotron">
      <div class="attr-type">
        <div class="img-wrap">
          <img
            v-if="attributeType.folderName"
            class="img-fluid"
            :src="'https://rexel-cdn.com/products/dcd460t2.jpg?i=' + attributeType.folderName + '&f=150'" />
          <img
            v-else
            class="img-fluid"
            src="https://sf.rexel-cdn.com/products/no-photo.jpg" />
        </div>
        <div class="attr-info">
          <h6>Type: {{ attributeType.type }}</h6>
          <p>Description: {{ attributeType.description }}</p>
        </div>
        <div class="attr-edit">
          <button
            type="button"
            class="btn btn-primary btn-rounded"
            @click="showTypeModal = true">
            Edit {{ attributeType.type }}
          </button>
        </div>
      </div>
    </div>
    <div class="search-section">
      <div class="attr-search">
        <input
          v-model="search"
          type="text"
          class="form-control" />
        <template>
          <i
            v-show="isEmpty"
            class="material-icons">
            search
          </i>
          <i
            v-show="isDirty"
            class="material-icons times"
            @click="search = ''">
            close
          </i>
        </template>
      </div>
      <div class="add-new-btn">
        <button
          type="button"
          class="btn btn-primary"
          @click="showCreateModal = true">
          Add Value
        </button>
      </div>
      <div class="attr-table-pager">
        <div class="table-viewing-info">
          <label>Viewing {{ currentViewing | numeric }} -
            <span v-if="parseInt(currentViewing) + parseInt(pagination.per_page) - 1 < pagination.total">
              {{ parseInt(currentViewing) + parseInt(pagination.per_page) - 1 | numeric }}
            </span>
            <span v-else>
              {{ pagination.total | numeric }}
            </span> of {{ pagination.total | numeric }}
          </label>
        </div>
        <div class="input-group">
          <span class="input-group-addon">
            Showing:
          </span>
          <select
            v-model="pagination.per_page"
            class="form-control">
            <option
              v-for="o in paginationOptions.pageSizes"
              :key="o"
              :value="o">
              {{ o }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="attr-detail-list">
      <table class="table table-hover">
        <thead>
          <tr>
            <th></th>
            <th>
              <a @click="sortAttributes('attributeValue', sortAsc)">Value Name
                <i
                  v-if="sortCol == 'attributeValue' && sortAsc == true"
                  class="material-icons">
                  expand_more
                </i>
                <i
                  v-else-if="sortCol == 'attributeValue' && sortAsc == false"
                  class="material-icons">
                  expand_less
                </i>
                <i
                  v-else
                  class="material-icons no-sort">
                  code
                </i>
              </a>
            </th>
            <th>
              <a @click="sortAttributes('productCount', sortAsc)">Product Count
                <i
                  v-if="sortCol == 'productCount' && sortAsc == true"
                  class="material-icons">
                  expand_more
                </i>
                <i
                  v-else-if="sortCol == 'productCount' && sortAsc == false"
                  class="material-icons">
                  expand_less
                </i>
                <i
                  v-else
                  class="material-icons no-sort">
                  code
                </i>
              </a>
            </th>
            <th>
              <a @click="sortAttributes('attributeValueId', sortAsc)">Id
                <i
                  v-if="sortCol == 'attributeValueId' && sortAsc == true"
                  class="material-icons">
                  expand_more
                </i>
                <i
                  v-else-if="sortCol == 'attributeValueId' && sortAsc == false"
                  class="material-icons">
                  expand_less
                </i>
                <i
                  v-else
                  class="material-icons no-sort">
                  code
                </i>
              </a>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="attr in attributesDetailsList"
            :key="'attr-value-' + attr.attributeValueId"
            @click="editAttributeOption(attr)">
            <td class="img-col">
              <div class="img-fluid">
                <img
                  v-if="attr.folderName"
                  :src="'https://rexel-cdn.com/products/dcd460t2.jpg?i=' + attr.folderName + '&f=150'" />
                <img
                  v-else
                  src="https://sf.rexel-cdn.com/products/no-photo.jpg" />
              </div>
            </td>
            <td
              class="attr-col"
              v-html="formatResult(attr.attributeValue, search)">
            </td>
            <td class="product-count-col">
              {{ attr.productCount }}
            </td>
            <td class="id-col">
              {{ attr.attributeValueId }}
            </td>
            <td class="arrow-col">
              <span class="attr-arrow">
                <i class="material-icons">
                  call_made
                </i>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="!showSpinner"
      class="attr-pagination">
      <pagination
        :pagination="pagination"
        :callback="searchPagintation"
        :options="paginationOptions"></pagination>
    </div>
    <type-modal
      :show-modal.sync="showTypeModal"
      :details.sync="attributeType"
      @fetch="getAttributeOptions()"></type-modal>
    <value-modal
      :show-modal.sync="showValueModal"
      :attributeTypes="attributeTypes"
      :values="attributeDetails"
      :details.sync="editAttributeValue"
      @fetch="getAttributeOptions()"></value-modal>
    <create-modal
      :show-modal.sync="showCreateModal"
      :type="attributeType"
      :values="attributesFiltered"
      @fetch="getAttributeOptions()"></create-modal>
  </div>
</template>
<script type="text/javascript">
import axios from 'axios'
import TitleComponent from 'components/pageTitle'
import PaginationComponent from 'components/pagination.vue'
import EditAttributeTypeModal from './editType.vue'
import EditAttributeValueModal from './editValue.vue'
import CreateAttributeValueModal from './createValue.vue'

export default {
  name: 'AttributeDetails',
  components: {
    'page-title': TitleComponent,
    pagination: PaginationComponent,
    'type-modal': EditAttributeTypeModal,
    'value-modal': EditAttributeValueModal,
    'create-modal': CreateAttributeValueModal
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.showSpinner = true
      vm.getAttributeOptions(to.params.id)
      vm.getAttributeTypes()
    })
  },
  data () {
    return {
      showSpinner: true,
      showTypeModal: false,
      showValueModal: false,
      showCreateModal: false,
      attributeType: {},
      attributeTypes: [],
      attributeDetails: [],
      sortCol: '',
      sortAsc: false,
      search: '',
      pagination: {
        total: 0,
        per_page: 50, // required
        current_page: 1, // required
        last_page: 0, // required
        from: 0,
        to: 15 // required
      },
      paginationOptions: {
        offset: 5,
        previousText: 'Prev',
        nextText: 'Next',
        alwaysShowPrevNext: true,
        pageSizes: [25, 50, 100, 150, 250, 500]
      },
      currentViewing: 1,
      editAttributeValue: {}
    }
  },
  computed: {
    language () {
      return this.$store.getters.languageId
    },
    isEmpty () {
      return !this.search
    },
    isDirty () {
      return !!this.search
    },
    attributesFiltered () {
      const search = this.search.toLowerCase()
      const list = this.attributeDetails.filter((attr, index) => {
        return attr.attributeValue.toLowerCase().indexOf(search) !== -1
      })
      this.currentViewing = 1
      this.pagination.current_page = 1
      this.pagination.total = list.length
      this.searchPagintation(true)
      return list
    },
    attributesDetailsList () {
      this.currentViewing = (this.pagination.current_page - 1) * this.pagination.per_page + 1

      const list = this.attributesFiltered.filter((ven, index) => {
        return index >= ((this.pagination.current_page - 1) * this.pagination.per_page) && index <= ((this.pagination.current_page - 1) * this.pagination.per_page + this.pagination.per_page - 1)
      })
      const search = this.search.toLowerCase()
      if (search && list.length > 0) {
        // Find the array element which matches the exact search
        const exactElementIndex = list.findIndex(attr => attr.attributeValue === search)
        // If found, then move it to the top
        if (exactElementIndex !== -1) {
          const foundElement = list[exactElementIndex]
          list.splice(exactElementIndex, 1)
          list.splice(0, 0, foundElement)
        }
      }
      return list
    }
  },
  watch: {},
  methods: {
    getAttributeTypes () {
      const data = {
        params: {
          languageId: this.language
        }
      }
      axios.get('/api/attributes/GetAttributeTypes', data).then(res => {
        this.attributeTypes = res.data
      })
    },
    getAttributeOptions (id) {
      const attrId = id || this.$route.params.id
      const data = {
        params: {
          languageId: this.language,
          attributeTypeId: attrId
        }
      }
      axios.get('/api/attributes/getAttributeOptions', data).then(res => {
        const response = res.data
        this.attributeType = response.attributeType
        this.attributeDetails = response.attributeOptions

        this.showSpinner = false
      })
    },
    editAttributeOption (attr) {
      this.showValueModal = true
      this.editAttributeValue = attr
    },
    searchPagintation (size) {
      if (size) {
        this.pagination.last_page = Math.ceil(this.pagination.total / this.pagination.per_page)
      }
    },
    formatResult (suggestion, search) {
      function escapeRegExChars (value) {
        return value.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
      }

      // Do not replace anything if the current value is empty
      if (!search || search === '') {
        return suggestion
      }

      const pattern = '(' + escapeRegExChars(search) + ')'

      return suggestion.toString().toLowerCase()
        .replace(new RegExp(pattern, 'gi'), '<strong>$1</strong>')
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/&lt;(\/?strong)&gt;/g, '<$1>')
    },
    sortAttributes (col, ascending) {
      this.pagination.current_page = 1
      ascending = !ascending
      this.sortCol = col
      this.sortAsc = ascending
      if (ascending) {
        this.attributeDetails.sort((a, b) => {
          if (typeof a[col] === 'string') {
            return a[col].localeCompare(b[col])
          } else {
            return a[col] - b[col]
          }
        })
      } else {
        this.attributeDetails.sort((a, b) => {
          if (typeof b[col] === 'string') {
            return b[col].localeCompare(a[col])
          } else {
            return b[col] - a[col]
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/scss/extra/mixins/flexbox.scss';
@import '../../assets/scss/bootstrap-custom-variables.scss';

.jumbotron {
  padding: 2rem;
}

.attr-type {
  @include flexbox;
  @include justify-content(space-between);
  .img-wrap {
    .img-fluid {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
    }
  }
  .attr-info {
    @include flex(1, 1, 50%);
    @include align-self(center);
    margin-left: 1rem;
    p {
      font-size: 0.875rem;
    }
  }
  .attr-edit {
    @include align-self(flex-end);
  }
}

.search-section {
  margin-bottom: 1.5rem;
  @include flexbox;
  .attr-search {
    position: relative;
    @include flex(0, 1, 40%);
    .form-control {
      &:focus ~ .material-icons.times {
        color: $brand-danger;
        cursor: pointer;
      }
    }
    .material-icons {
      color: darken($gray-lighter, 25%);
      position: absolute;
      font-size: 1.125rem;
      top: 0.125rem;
      right: 0.125rem;
      padding: 0.5rem;
    }
  }
  .add-new-btn {
    padding: 0 1rem;
    .btn {
      padding: 0.5rem 2rem;
    }
  }
  .attr-table-pager {
    @include flex(1, 1, 30%);
    @include flexbox;
    @include justify-content(flex-end);
    .table-viewing-info {
      width: 10rem;
      text-align: right;
      color: #222;
      font-size: 0.875rem;
      @include align-self(center);
      @include flex(1, 1, 10rem);

      > label {
        background: #eee;
        border: 1px solid rgba(0, 0, 0, 0.15);
        padding: 0.5rem 1.5rem;
        margin-bottom: 0;
      }
    }
    .input-group {
      width: 12rem;
      border: 0;

      .input-group-addon {
        font-size: 0.875rem;
        border: 0;
        background: #fff;
      }
      .form-control {
        font-size: 0.875rem;
        background: #eee;
      }
    }
  }
}

.attr-detail-list {
  .table {
    border: 1px solid #eceeef;
    thead {
      tr {
        th {
          text-transform: uppercase;
          .material-icons {
            font-size: 1rem;
            margin-left: 0.5rem;
            &:not(.no-sort) {
              color: $brand-info;
            }
          }
          .no-sort {
            -ms-transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
          }
        }
      }
    }
    tbody {
      tr {
        &:hover {
          .arrow-col .attr-arrow {
            background: darken($brand-primary, 10%);
          }
        }
        td {
          vertical-align: middle;
          cursor: pointer;
        }
      }
    }
    .attr-col {
      font-weight: 500;
    }
    .product-count-col {
      width: 12rem;
    }
    .arrow-col {
      width: 4rem;
      text-align: center;
      .attr-arrow {
        display: block;
        position: relative;
        color: #fff;
        background: $brand-primary;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        &:hover {
          background: darken($brand-primary, 10%);
        }
        .material-icons {
          position: absolute;
          font-size: 1.5rem;

          top: 50%;
          right: 50%;
          margin: -0.75rem -0.825rem 0 0;
        }
      }
    }
    .img-col {
      width: 4.5rem;
      border-right: 1px solid #eceeef;
      .img-fluid {
        @include flexbox;
        @include justify-content(center);
        height: 3.75rem;
        img {
          @include align-self(center);
          max-height: 3.75rem;
          max-width: 3.75rem;
        }
      }
    }
    .id-col,
    .active-col {
      width: 6rem;
    }
  }
}

.material-icons {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
