<template>
  <div>
    <v-data-table
      :headers="youtubeChannelHeaders"
      :items="youtubeChannels"
      :search="search"
      :footer-props="{
        'items-per-page-options': [10, 25, 50, 100]
      }">
      <template #top>
        <v-text-field
          v-model="search"
          label="Search">
        </v-text-field>
      </template>
      <template
        #item.exclusionInd="{item}">
        <v-simple-checkbox
          v-model="item.exclusionInd"
          @click="exclusionOnChange(item.externalMediaYoutubeChannelId, item.exclusionInd)"></v-simple-checkbox>
      </template>
      <template
        #item.showItem="{item}">
        <i
          style="cursor:pointer; font-size: 1.275rem;"
          class="material-icons"
          @click="viewMedia(item.youtubeChannelId)">
          visibility
        </i>
      </template>
    </v-data-table>
  </div>
</template>

<script type="text/javascript">
export default {
  name: 'ExternalYoutube',
  components: {
    // modal: Modal
  },
  data () {
    return {
      youtubeChannels: [],
      youtubeChannelHeaders: [
        {
          text: 'YouTube Channel',
          value: 'youtubeChannelName',
          width: '80%'
        },
        {
          text: 'Exclude Channel',
          value: 'exclusionInd',
          width: '10%',
          sortable: false
        },
        {
          text: 'View Channel',
          width: '10%',
          sortable: false,
          value: 'showItem'
        }
      ],
      search: ''
    }
  },
  mounted () {
    this.getYoutubeChannels()
  },
  methods: {
    viewMedia (channelId) {
      window.open('https://www.youtube.com/channel/' + channelId, '_blank')
    },
    exclusionOnChange (id, value) {
      const data = new FormData()
      data.append('value', value)
      data.append('userId', this.$store.getters.user.userId)
      this.$http.put('/api/media/externalvideos/youtube/' + id, data)
        .then(res => {
        })
    },
    getYoutubeChannels () {
      this.$http.get('/api/media/externalvideos/youtube')
        .then(res => {
          this.youtubeChannels = null
          this.youtubeChannels = res.data
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.update-item-action-cell ::v-deep .btn-update-item-action {
  border-radius: 50%;
    padding: 2px;
    i {
      font-size: 28px;
    }
  }
  .url-text {
    font-size: 16px;
  }
  .action-dropdown-item {
    padding: 4px 10px;
    font-size: 14px;
    font-weight: bold;
    &:hover {
      background-color: #eee;
      cursor: pointer;
    }
  }
  </style>
