<template>
  <div class="card-block">
    <div class="row">
      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label class="form-control-label">
            Platt Item Number
          </label>
          <input
            v-model="product.productId"
            type="text"
            class="form-control"
            disabled />
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label class="form-control-label">
            Product Number
          </label>
          <input
            v-model="product.sku"
            type="text"
            class="form-control"
            disabled />
        </div>
      </div>
    </div>

    <!--Checkboxes-->
    <div id="product-number-price-cartoptions">
      <div
        id="product-cartoptions"
        class="form-group">
        <v-row>
          <v-col :cols="4">
            <v-select
              v-model="product.showOnWeb"
              v-tooltip="'Not set interpreted as yes for Platt and no for others'"
              class="mb-2"
              :items="showOnWebItems"
              :disabled="isReadOnly"
              hide-details
              outlined
              dense
              label="Show On Web">
            </v-select>
          </v-col>
          <v-col :cols="2">
            <label
              v-tooltip="'This will hide pricing globally to all accounts if selected'"
              class="custom-control custom-checkbox ml-3">
              <input
                v-model="product.callForPricing"
                type="checkbox"
                class="custom-control-input"
                :disabled="isReadOnly" />
              <span class="custom-control-indicator"></span>
              <span class="custom-control-description">
                Call For Pricing
              </span>
            </label>
          </v-col>
          <v-col :cols="2">
            <label
              v-tooltip="'This will override CALL FOR PRICING/SHOW IN CART if the customer is logged in. Will not apply to Demo Account.'"
              class="custom-control custom-checkbox">
              <input
                v-model="product.callForPricingGuest"
                type="checkbox"
                class="custom-control-input"
                :disabled="isReadOnly" />
              <span class="custom-control-indicator"></span>
              <span class="custom-control-description">
                Call For Pricing (Guest)
              </span>
            </label>
          </v-col>
          <v-col :cols="2">
            <label
              v-tooltip="'This works with CALL FOR PRICING to allow the price to be seen in cart'"
              class="custom-control custom-checkbox">
              <input
                v-model="product.showInCart"
                type="checkbox"
                class="custom-control-input"
                :disabled="isReadOnly" />
              <span class="custom-control-indicator"></span>
              <span class="custom-control-description">
                Show In Cart
              </span>
            </label>
          </v-col>
        </v-row>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label class="form-control-label">
            Product Family
          </label>
          <input
            v-model="product.family"
            type="text"
            class="form-control"
            :disabled="isReadOnly" />
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label class="form-control-label">
            Product Grouping
          </label>
          <input
            v-model="product.grouping"
            type="text"
            class="form-control"
            :disabled="isReadOnly" />
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label class="form-control-label">
            Product Markets
          </label>
          <multi-select
            v-model="product.markets"
            :options="marketOptions"
            label="name"
            track-by="name"
            :multiple="true"
            :searchable="false"
            :disabled="isReadOnly"></multi-select>
        </div>
      </div>
    </div>
    <!--/*End Row-->

    <div id="product-order-details">
      <div class="section-heading">
        <h6>Order Details</h6>
      </div>

      <div class="row">
        <div class="col-md-6 col-lg-4">
          <div class="form-group">
            <label class="form-control-label">
              Minimum Order Quantity
            </label>
            <input
              v-model="product.minOrdQty"
              type="text"
              class="form-control"
              :disabled="isReadOnly" />
          </div>
        </div>
        <!--27238 <div class="col-md-6 col-lg-4">
          <div class="form-group">
            <label class="form-control-label">Box Quantity</label>
            <input
              type="text"
              class="form-control"
              v-model="product.boxQty"
            />
          </div>
        </div> -->
      </div>
    </div>
    <div id="product-pim">
      <div class="section-heading">
        <h6>PIM Storage Count:</h6>
      </div>
      <div class="section-body">
        <div class="form-group">
          <label class="form-control-label">
            Small Container
          </label>
          <input
            v-model="product.smContainerCnt"
            type="number"
            class="form-control"
            :disabled="isReadOnly" />
        </div>
        <div class="form-group">
          <label class="form-control-label">
            Medium Container
          </label>
          <input
            v-model="product.mdContainerCnt"
            type="number"
            class="form-control"
            :disabled="isReadOnly" />
        </div>
        <div class="form-group">
          <label class="form-control-label">
            Large Container
          </label>
          <input
            v-model="product.lgContainerCnt"
            type="number"
            class="form-control"
            :disabled="isReadOnly" />
        </div>
        <div class="form-group">
          <label class="form-control-label">
            Shelf
          </label>
          <input
            v-model="product.shelfCnt"
            type="number"
            class="form-control"
            :disabled="isReadOnly" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MultiselectComponent from 'components/inputs/multiselect.vue'
import PermissionsMixin from 'mixins/Permissions.mixin'

export default {
  components: {
    'multi-select': MultiselectComponent
  },
  props: {
    product: {
      type: Object
    }
  },
  data () {
    return {
      marketOptions: [],
      showOnWebItems: [
        {
          text: 'Not set',
          value: null
        },
        {
          text: 'Yes',
          value: true
        },
        {
          text: 'No',
          value: false
        }
      ]
    }
  },
  computed: {
    isReadOnly () {
      return !this.$store.getters.hasPermissionForBanner(PermissionsMixin.data.Roles.contributor, 1)
    }
  },
  watch: {
    product: {
      handler (val) {
        this.$emit('bannerPropChange', 'platt')
      },
      deep: true
    }
  },
  mounted () {
    this.getMarketOptions()
  },
  methods: {
    getMarketOptions () {
      return this.$http.get('/api/products/markets')
        .then(res => {
          this.marketOptions = res.data
        }).catch(e => {
          console.log(e)
        })
    }
  }
}
</script>
