import * as humanizeBase from 'humanize-duration'

export const isoDate = value => {
  const date = value ? value.substring(0, 10).split('-') : null
  if (date && date[0] !== '0001') {
    return `${date[1]}/${date[2]}/${date[0]}`
  } else {
    return 'No Date'
  }
}

export const localDate = value => {
  const date = new Date(value)
  return date.toLocaleDateString()
}

export const dateTime = value => {
  const date = new Date(value)
  return date.toLocaleDateString() + ' at ' + date.toLocaleTimeString()
}

export const numeric = value => {
  if (!value) return 0
  const number = value.toString()
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const humanize = value => {
  if (!value) return '--'
  return humanizeBase(value, { largest: 1 }, { units: ['y', 'mo', 'd'] })
}

export const lowercase = value => {
  return value.toLowerCase()
}

export const capitalize = value => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const isComplete = value => {
  const displayValue = value ? 'Complete' : 'Incomplete'
  return displayValue
}
