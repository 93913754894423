<template>
  <v-app
    class="main-layout"
    :class="{'hide-sidebar': !showSidebar, 'show-mobile-sidebar': showMobileSidebar }">
    <div id="napm-layout">
      <header-component @toggleSidebar="toggleSidebar()"></header-component>
      <sidebar-component :user="user"></sidebar-component>
    </div>
    <div id="main">
      <div class="container-fluid">
        <div
          class="spinner"
          :class="{'show-spinner': $store.getters.showSpinner}"></div>
        <div class="sidebar-active-overlay"></div>
        <transition
          :name="transitionName"
          mode="out-in">
          <router-view :class="{'page-loading': $store.getters.showSpinner}"></router-view>
        </transition>
      </div>
    </div>
    <modal
      ref="globalModal"
      :title="$store.getters.modal.title"
      :icon="$store.getters.modal.icon"
      :blocking="$store.getters.modal.blockClose"
      @close="resetModal()">
      <div class="global-modal-message">
        {{ $store.getters.modal.message }}
      </div>
      <div
        slot="button"
        class="global-modal-actions">
        <button
          type="button"
          class="btn btn-gray-alt"
          @click="$refs.globalModal.close()">
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary"
          @click="modalCallback()">
          {{ $store.getters.modal.btn.text }}
        </button>
      </div>
    </modal>
    <external-content></external-content>
  </v-app>
</template>
<script type="text/javascript">

import ModalComponent from 'components/modals/sweet.vue'
import HeaderComponent from './views/layout/header.vue'
import SidebarComponent from './views/layout/sidebar.vue'
import ExternalContent from './views/external/ExternalContent.vue'

export default {
  name: 'App',
  components: {
    modal: ModalComponent,
    'header-component': HeaderComponent,
    'sidebar-component': SidebarComponent,
    'external-content': ExternalContent
  },
  props: ['transitionName'],
  data () {
    return {
      hasCookie: false,
      showSidebar: true,
      mediumWidth: 985, // this should match medium with in _layout.scss
      showMobileSidebar: false,
      windowWidth: 0
    }
  },
  computed: {
    user () {
      if (this.$store.getters.user.name) {
        return this.$store.getters.user
      } else {
        return this.$store.state.identity
      }
    }
  },
  watch: {
    '$store.getters.modal.show' (value) {
      if (value) {
        return this.$refs.globalModal.open()
      }
    },
    windowWidth (val) {
      if (val > this.mediumWidth) {
        this.showMobileSidebar = false
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.windowWidth = window.innerWidth
      window.addEventListener('resize', this.getWindowWidth)
    })
  },
  methods: {
    modalCallback () {
      this.$refs.globalModal.close()
      this.$store.getters.modal.btn.fn()
    },
    toggleSidebar () {
      this.showSidebar = !this.showSidebar
      if (this.windowWidth <= this.mediumWidth) {
        this.showMobileSidebar = !this.showMobileSidebar
      }
    },
    resetModal () {
      setTimeout(() => {
        this.$store.dispatch('resetModal')
      }, 500)
    },
    getWindowWidth (e) {
      this.windowWidth = window.innerWidth
    }
  }
}
</script>

<style lang="scss">
@import './assets/scss/extra/mixins/flexbox.scss';
@import './assets/scss/layout.scss';
@import './assets/scss/sidebar.scss';

body {
  overflow-y: hidden;
}

#main {
  .container-fluid {
    padding: 15px;
    @media (min-width: $mobile-view) {
      padding: 0 2.5rem 2rem;
    }
  }
}

.global-modal-message {
  font-size: 1.125rem;
  text-align: center;
  text-transform: capitalize;
}

.global-modal-actions {
  @include flexbox;
  @include justify-content(space-between);
  .btn {
    min-width: 10rem;
  }
}
</style>
