<template>
  <div
    v-click-outside="hideDropdown"
    class="dropdown-container"
    :class="[ hideArrow ? 'hide-dropdown-arrow' : '']">
    <button
      :id="id"
      class="dropdown-toggle"
      :class="[ btnClass, disabled ? 'disabled' : '' ]"
      @click="show = !show; $emit('btnClick')">
      <slot name="action-btn"></slot>
    </button>
    <div
      v-show="show"
      class="dropdown-menu"
      :aria-labelledby="id"
      :class="[ dropdownRight ? 'dropdown-menu-right' : '', hideMenuArrow ? 'margin-0' : '']">
      <span
        v-if="!hideMenuArrow"
        class="dropdown-menu-arrow dropdown-menu-arrow-before"
        :style="{ right: dropdownArrowLocation }"></span>
      <span
        v-if="!hideMenuArrow"
        class="dropdown-menu-arrow dropdown-menu-arrow-after"
        :style="{ right: dropdownArrowLocation }"></span>
      <slot name="action-content"></slot>
    </div>
  </div>
</template>

<script type="text/javascript">
import ClickOutside from 'vue-click-outside'

export default {
  name: 'ActionList',
  directives: {
    ClickOutside
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    hideMenuArrow: {
      type: Boolean,
      default: false
    },
    hideArrow: {
      type: Boolean,
      default: false
    },
    dropdownRight: Boolean,
    btnClass: {},
    id: String,
    dropdownArrowLocation: String
  },
  data () {
    return {
      show: false
    }
  },
  computed: {},
  watch: {},
  methods: {
    hideDropdown () {
      this.show = false
    }
  }
}
</script>
<style lang="scss" scoped>

.dropdown-container {
  position: relative;
}

.dropdown-menu-right {
  left: inherit;
  right: 0;
}

.dropdown-menu {
  padding-top: .25rem;
  display: inline-block;
  -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.25);
  -moz-box-shadow:  0px 5px 1px rgba(0,0,0,0.25);
  box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.25);
}

.dropdown-toggle:after {
  position: relative;
  top: 1px;
  left: 5px;
  margin-right: 8px;
  border-top: .4em solid;
  border-right: .4em solid transparent;
  border-left: .4em solid transparent;
}

.dropdown-menu-arrow {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  right: 50%;
  content: "";
}

.dropdown-menu-arrow-before {
  border-width: 11px;
  top: -11px;
  margin-right: -11px;
  border-bottom-color: rgba(0,0,0,.25);
  border-top-width: 0;
}

.dropdown-menu-arrow-after {
  border-width: 10px;
  border-top-width: 0;
  top: -10px;
  margin-right: -10px;
  border-bottom-color: #fff;
}

.hide-dropdown-arrow {
  .dropdown-toggle:after {
    display: none;
  }
}

.margin-0 {
  margin: 0 !important;
}

</style>
