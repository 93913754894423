<template>
  <modal
    ref="bulkMedia"
    :title="title ? title : 'Bulk Media Upload'"
    :icon="modal.icon"
    :animate-icon="modal.animate"
    :blocking="true"
    @close="closeModal()">
    <template v-if="selectFile">
      <loading :loading="loading"></loading>
      <div
        class="bulk-upload"
        :class="{'uploading-media': loading}">
        <div class="btn-upload btn btn-primary btn-lg">
          <span>Browse Files</span>
          <input
            type="file"
            class="file-input"
            accept=".zip"
            @change="initFile($event)" />
        </div>
        <label
          v-if="fileValid"
          class="selected-file">You have selected ({{ fileName }})</label>
        <div
          v-if="fileName && !fileValid"
          class="alert alert-danger text-left">
          {{ fileName }} in not a valid file type. The file must be a .zip archive
        </div>
        <hr />
        <template v-if="directions">
          <slot name="directions">
          </slot>
        </template>
        <div
          v-else
          class="directions">
          <h6>Media Upload Directions:</h6>
          <ol>
            <li>Create a new folder and copy all the files you intend to upload into that folder.</li>
            <li>
              Download the BulkMedia_Template.csv file from <a
                href="/assets/templates/BulkMedia_Template.csv"
                target="_blank">here</a>.
            </li>
            <li>Copy the BulkMedia_Template.csv file to the folder containing the media.</li>
            <li>Fill out the template column data with the desired product information and image file references (Include file name and extension for all files. [i.e image.jpg])</li>
            <li>Ensure all data and references are in the correct columns.</li>
            <li>Compress all files within the folder containing the template and media files to create a .zip folder. Zip should not contain any sub directory folders, only files.</li>
            <li>Browse to the folder containing the images and the edited ‘BulkMedia_Template.csv’ file.</li>
            <li>Check confirmation checkbox and process upload</li>
          </ol>
        </div>
        <div class="alert alert-primary text-left mb-0 py-2 px-3">
          <label class="custom-control custom-checkbox mb-0">
            <input
              v-model="confirmDirections"
              type="checkbox"
              class="custom-control-input" />
            <span class="custom-control-indicator"></span>
            <span class="custom-control-description">
              Please confirm that you have followed the steps above
            </span>
          </label>
        </div>
      </div>
    </template>
    <template v-else>
      <p class="text-center lead">
        {{ modal.message }}
      </p>
    </template>
    <div
      slot="button"
      class="d-flex justify-content-between">
      <template v-if="selectFile">
        <button
          class="btn btn-link"
          @click="$emit('update:show', false)">
          Cancel
        </button>
        <button
          class="btn btn-primary"
          :class="{'disabled': !fileValid || loading || !confirmDirections}"
          :disabled="!fileValid || loading || !confirmDirections"
          @click="uploadBulkMedia()">
          Process <i class="material-icons">
            chevron_right
          </i>
        </button>
      </template>
      <template v-else>
        <span></span>
        <button
          class="btn btn-gray-alt"
          @click="$emit('update:show', false)">
          Done
        </button>
      </template>
    </div>
  </modal>
</template>
<script>
import axios from 'axios'
import Loading from '../upload/uploading'
import Modal from 'components/modals/sweet'
export default {
  name: 'Bulk',
  components: {
    modal: Modal,
    loading: Loading
  },
  props: {
    show: Boolean,
    directions: Boolean,
    title: String
  },
  data () {
    return {
      loading: false,
      modal: {
        icon: null,
        message: '',
        animate: false
      },
      file: null,
      fileName: null,
      fileValid: false,
      selectFile: true,
      confirmDirections: false
    }
  },
  computed: {},
  watch: {
    show (value) {
      if (value) {
        this.$refs.bulkMedia.open()
      } else {
        this.modal = {
          icon: null,
          message: '',
          animate: false
        }
        this.file = null
        this.fileName = null
        this.fileValid = false
        this.selectFile = true
        this.confirmDirections = false
        this.$refs.bulkMedia.close()
      }
    }
  },
  methods: {
    closeModal () {
      this.$emit('update:show', false)
      this.modal = {
        title: this.title ? this.title : 'Bulk Media Upload',
        icon: null,
        blocking: true,
        animate: false
      }
    },
    initFile (event) {
      this.file = event.target.files[0]
      this.fileName = event.target.files[0].name
      if (this.file.type.indexOf('zip') > -1) {
        this.fileValid = true
      } else {
        // error
        this.fileValid = false
      }
    },
    uploadBulkMedia () {
      if (!this.confirmDirections) { return }
      const data = new FormData()
      data.append('file', this.file)
      const options = {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
      this.loading = true
      axios.post('/api/bulk/media', data, options)
        .then(res => {
          if (res.status === 200) {
            this.modal.icon = 'success'
            this.modal.message = `(${this.fileName}) has been uploaded and will begin processing.`
          } else {
            this.modal.icon = 'error'
            this.modal.message = 'Upload Failed, ' + res.data
          }
        })
        .catch(e => {
          console.error('uploadBulkMedia() Error: ', e)
          this.modal.icon = 'error'
          this.modal.message = 'Upload Failed, ' + e.response.data
        })
        .finally(() => {
          this.loading = false
          this.selectFile = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/extra/mixins/flexbox";
.bulk-upload {
  text-align: center;
  .btn-upload {
    position: relative;
    overflow: hidden;
    padding: 0.5rem 2.5rem;
    margin-bottom: 1rem;
    .file-input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      z-index: 1;
    }
  }
  .selected-file {
    display: block;
    font-size: 1rem;
  }
  .directions {
    text-align: left;
    margin: 1.5rem 0 1rem;
    a {
      font-weight: 700;
    }
    h6 {
      margin-bottom: 0.75rem;
    }
    ol {
      padding-left: 1rem;
      font-size: 0.8125rem;
      margin-bottom: 0;
      li {
        padding-left: 1rem;
      }
    }
  }
}
</style>
