<template>
  <div class="top-50-display">
    <div class="headers">
      <div class="header product-cell">
        Product Name
      </div>
      <div class="header number-cell">
        Napm #
      </div>
      <div class="header updated-cell">
        Updated On
      </div>
      <div class="header arrow-cell"></div>
    </div>
    <div class="display-body-scroll">
      <div class="display-body">
        <router-link
          v-for="(p, i) in data"
          :key="`product50-${p.id + '-' + i}`"
          class="body-row"
          :to="{ path: `/products/edit/${p.id}` }">
          <div class="body-cell product-cell product-body-cell">
            <div class="img-fluid product-img-container mr-2">
              <img
                v-if="p.mainImage"
                :src="`https://rexel-cdn.com/products/${p.mainImage}/35.jpg`" />
              <img
                v-else
                src="https://images.plattstatic.com/ProductImages/thumbnail/Nonstock.gif" />
            </div>
            <div class="product-desc-container">
              <div class="mb-1">
                <span>Name:
                  <strong>{{ p.catNum }} </strong>
                </span>
                <span v-if="p.manufacturer">
                  By: {{ p.manufacturer }}
                </span>
                <div v-if="p.shortDescription">
                  <span>Description: </span> {{ p.shortDescription }}
                </div>
              </div>
            </div>
          </div>
          <div class="body-cell number-cell number-body">
            {{ p.id }}
          </div>
          <div class="body-cell updated-cell updated-body">
            {{ p.LastUpdated | isoDate }}
          </div>
          <div class="body-cell arrow-cell arrow-body">
            <i class="material-icons">
              arrow_forward
            </i>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'TopProductsTable',
  props: {
    data: Array,
    type: String
  }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/scss/extra/mixins/flexbox.scss';
@import '../../../assets/scss/extra/mixins/scrollbar.scss';
@import '../../../assets/scss/bootstrap-custom-variables';

.top-50-display {
  width: 100%;
  margin-top: 1.25rem;
  border: 1px solid #eceeef;

  .product-cell {
    width: 50%;
  }

  .number-cell {
    width: 20%;
  }

  .updated-cell {
    width: 20%;
  }

  .arrow-cell {
    width: 10%;
  }

  $horizontal-space: 0.75rem;

  .headers {
    background-color: #eee;
    padding-right: 0.5rem; // accounts for scrollbar below
    @include flexbox;
    @include flex-wrap(nowrap);

    .header {
      padding: 0.75rem $horizontal-space;
      color: #999;
      font-weight: bold;
      font-size: 0.75rem;
      text-transform: uppercase;
      border-top: 1px solid #eceeef;
      border-bottom: 2px solid #eceeef;
    }
  }

  .display-body-scroll {
    max-height: 15.75rem;
    overflow-y: auto;
    overflow-x: hidden;
    @include scrollbar(0.5rem, 4.5rem, #eee, 1px);
  }

  .display-body {
    .body-row {
      border-bottom: 1px solid #eceeef;
      text-decoration: none;
      @include flexbox;
      @include flex-wrap(nowrap);
      @include align-items(center);

      &:hover {
        cursor: pointer;
        background-color: darken(#fff, 15%);

        .body-cell {
          color: darken(#999, 65%);
        }
      }
    }

    .body-cell {
      padding: 0.5rem $horizontal-space;
      position: relative;
      color: #999;
      border: none;
      font-size: 0.75rem;
    }

    .product-body-cell {
      @include flexbox;
      @include align-items(center);

      img {
        max-width: 50px;
      }
    }

    .arrow-body {
      i {
        font-size: 1.5rem;
      }
    }
  }
}
</style>
