<template>
  <div>
    <page-title title="Marketing Files"></page-title>
    <section id="catalog-info">
      <div class="col-sm-12 mb-3">
        <div class="row page-header mb-3 pb-2">
          <div class="col-sm-6 pl-0 align-flex-end">
            <h6 class="mb-0">
              Project Info
            </h6>
          </div>
          <div
            v-if="canEdit"
            class="col-sm-6 pr-0">
            <button
              type="button"
              class="btn btn-default float-right"
              @click="editMarketingFile(info.marketingId)">
              Edit
            </button>
          </div>
        </div>
      </div>
      <div class="section-body">
        <div class="row mb-4">
          <div class="col-sm-2">
            <div
              v-if="info.coverImageFolderName"
              class="cover-image-preview"
              :style="`background-image: url(${ '/api/marketing/getfile/' + info.coverImageFolderName})`"></div>
            <div
              v-else
              class="cover-image-preview"
              :style="`background-image: url('https://rexel-cdn.com/products/NoImage.jpg?i=0EA7A6C5-7DD2-4482-9229-D628D3AFAE9D&f=50')`"></div>
          </div>
          <div class="col-sm-2 form-group">
            <label class="form-control-label">
              Project Title
            </label>
            <span>{{ info.projectTitle }}</span>
          </div>
          <div class="col-sm-2 form-group">
            <label class="form-control-label">
              Created By
            </label>
            <span>{{ info.userDisplayName }}</span>
          </div>
          <div class="col-sm-2 form-group">
            <label class="form-control-label">
              Date
            </label>
            <span>{{ info.formattedDateUploaded }}</span>
          </div>
          <div class="col-sm-2 form-group">
            <label class="form-control-label">
              Manufacturers
            </label>
            <ul
              v-if="selectedManufacturers.length"
              class="manufacturer-list">
              <li
                v-for="mfr in selectedManufacturers"
                :key="mfr.id">
                {{ mfr.name }}
              </li>
            </ul>
            <ul
              v-else
              class="manufacturer-list">
              <li>
                <em>No manufacturers selected.</em>
              </li>
            </ul>
          </div>
          <div class="col-sm-2 form-group">
            <label class="form-control-label">
              Company
            </label>
            <span>{{ info.company }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 mb-2">
            <label class="form-control-label">
              Description
            </label>
            <div
              class="marketing-item-description"
              v-html="info.projectDescription"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <documents
              :documents="documents"
              :display-only="true"></documents>
          </div>
        </div>
      </div>
    </section>
    <section class="section-btns">
    </section>
  </div>
</template>
<script type="text/javascript">
import axios from 'axios'
import TitleComponent from 'components/pageTitle.vue'
// import MultiselectComponent from 'components/inputs/multiselect.vue'
// import EditorComponent from 'components/editor.vue'
import DocumentsSection from './components/documents.vue'

export default {
  components: {
    // editor: EditorComponent,
    'page-title': TitleComponent,
    // multiselect: MultiselectComponent,
    documents: DocumentsSection
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.getMarketingFileDetails(to.params.id)
    })
  },
  data () {
    return {
      info: {},
      selectedManufacturers: [],
      documents: [],
      canEdit: false
    }
  },
  computed: {
    companyList () {
      return this.$store.getters.companyList.map(company => {
        return company
      })
    }
  },
  mounted () {
  },
  methods: {
    editMarketingFile (id) {
      this.$router.push({
        name: 'marketingEdit',
        params: {
          id
        }
      })
    },
    getMarketingFileDetails (id) {
      axios.get('/api/marketing/details/' + id).then(res => {
        this.info = res.data.info
        this.documents = res.data.documents
        this.canEdit = res.data.canEdit
        this.selectedManufacturers = res.data.manufacturers.map(mfr => {
          return { id: mfr.id, name: mfr.name }
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/scss/extra/mixins/flexbox.scss';
@import '../../assets/scss/bootstrap-custom-variables.scss';

.page-header {
  border-bottom: 2px solid #eee;
}
button {
  min-width: 150px;
}
[class*='align-flex'],
.flex {
  @include flexbox;
  @include flex-wrap(wrap);
}
.align-flex-end {
  align-items: flex-end;
}
.align-flex-middle {
  align-items: center;
}
.section {
  display: block;
  width: 100%;
  border: 1px solid #c9c9c9;
  background: #eee;
}
.cover-image-preview {
  width: 100px;
  height: 100px;
  background-position: center center;
  background-size: contain;
  background-color: transparent;
  background-repeat: no-repeat;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.form-control-label {
  position: relative;
  display: block;
  text-transform: uppercase;
  color: #000;
  font-weight: bold;
  &.required:before {
    content: 'required';
    text-transform: uppercase;
    color: $brand-danger;
    font-size: 0.75rem;
    right: 0;
    top: 0.125rem;
    position: absolute;
  }
}
.manufacturer-list {
  margin: 0;
  padding: 0;
  li {
    display: inline;
    &:after {
      content: ',';
      padding-right: 4px;
    }
    &:last-child {
      &:after {
        content: '';
        padding-right: 0;
      }
    }
  }
}
.page-header {
  border-bottom: 2px solid #eee;
}
</style>

<style lang="scss">
// Need to import non-scoped because description is rendered with v-html
@import '../../assets/scss/editor.scss';
</style>
