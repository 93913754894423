<template>
  <div
    v-if="salesTier.length > 0"
    class="product-sales-tier">
    <ul class="list-group">
      <li class="list-group-item sales-tier-heading">
        <h6>Sales Tier</h6>
        <a><i class="material-icons">
          brush
        </i></a>
      </li>
      <li class="list-group-item sales-tier-select-company">
        <multiselect
          v-model="selectedTier"
          :searchable="false"
          :local-search="false"
          :options="parsedSalesTier"
          label="label"
          select-label=""></multiselect>
      </li>
      <li class="list-group-item text-primary selected-sales-tier">
        <h6 class="sales-tier-title">
          <i class="material-icons mb-1">
            check_circle
          </i>
          <span>Tier {{ selectedTier.id }}</span>
        </h6>
      </li>
    </ul>
  </div>
</template>
<script>
import Multiselect from 'components/inputs/multiselect.vue'
export default {
  name: 'SalesTierSection',
  components: {
    Multiselect
  },
  props: {
    salesTier: Array
  },
  data () {
    return {
      selectedTier: {},
      parsedSalesTier: []
    }
  },
  watch: {
    salesTier (val) {
      this.setSalesTier()
    }
  },
  mounted () {
    if (this.salesTier.length > 0) {
      this.setSalesTier()
    }
  },
  methods: {
    setSalesTier () {
      if (this.salesTier.length === 0) return
      this.parsedSalesTier = this.salesTier.map(tier => {
        return {
          label: tier.companyName,
          id: tier.salesTier
        }
      })
      if (!Object.prototype.hasOwnProperty.call(this.selectedTier, 'id')) {
        this.selectedTier = this.parsedSalesTier[0]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../../../assets/scss/extra/mixins/flexbox";
  @import "../../../assets/scss/bootstrap-custom-variables";

  .product-sales-tier {
    margin: 1rem 0 1.5rem;
    .list-group {

      .list-group-item {
        &.sales-tier-heading {
          position: relative;
          color: $white;
          background: $brand-primary;
          text-transform: uppercase;
          padding: .75rem;
          h6 {
            margin-bottom: 0;
            text-align: center;
            @include align-self(center);
          }
          a {
            color: $white;
            position: absolute;
            right: .75rem;
            top: 50%;
            height: 1.5rem;
            margin-top: -.75rem;
            @include flexbox;
            @include justify-content(center);
            @include align-items(center);
            i {
              font-size: 1.5rem;
            }
          }
        }
        h6.sales-tier-title{
          margin: 0;
          line-height: 1.25;
        }
      }

      .sales-tier-select-company {
        padding: .75rem 0;
        border: none;
      }

      .selected-sales-tier {
        border-left: none;
        border-right: none;
      }
    }
  }
</style>
