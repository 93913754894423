import Vue from 'vue'
import {
  isoDate,
  localDate,
  dateTime,
  numeric,
  humanize,
  lowercase,
  capitalize,
  isComplete
} from './formatters'

Vue.filter('isoDate', isoDate)
Vue.filter('localDate', localDate)
Vue.filter('dateTime', dateTime)
Vue.filter('numeric', numeric)
Vue.filter('humanize', humanize)
Vue.filter('lowercase', lowercase)
Vue.filter('capitalize', capitalize)
Vue.filter('isComplete', isComplete)
