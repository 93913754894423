import axios from 'axios'

export default {
  data () {
    return {
      documentToUpload: {
        file: '',
        fileName: '',
        manufacturer: null,
        type: null,
        uri: null,
        languageId: 1,
        id: null
      }
    }
  },
  methods: {
    createDocumentForUpload (file, name) {
      this.documentToUpload.file = file
      this.documentToUpload.fileName = name
    },
    saveDocument () {
      const data = new FormData()
      data.append('file', this.documentToUpload.file)
      data.append('uri', this.documentToUpload.uri)
      data.append('documentTypeId', this.documentToUpload.type)
      data.append('fileName', this.documentToUpload.fileName)
      data.append('mfrId', this.documentToUpload.manufacturer)
      data.append('languageId', this.documentToUpload.languageId)
      return axios.post('/api/media/documents', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(res => {
          return res.data
        }).catch(err => {
          console.warn(err)
        })
    },
    replaceDocument () {
      const data = new FormData()
      data.append('file', this.documentToUpload.file)
      data.append('uri', this.documentToUpload.uri)
      data.append('fileName', this.documentToUpload.fileName)
      data.append('documentTypeId', this.documentToUpload.type)
      data.append('languageId', this.documentToUpload.languageId)
      return axios.patch(`/api/media/documents/${this.documentToUpload.id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(res => {
          return res.data
        })
    },
    resetDocumentUploadDefaults () {
      this.documentToUpload = {
        file: '',
        fileName: '',
        manufacturer: '',
        type: null,
        languageId: 1
      }
    }
  }
}
