<template>
  <div>
    <page-title title="Q &amp; A"></page-title>
    <div
      class="spinner"
      :class="{'show-spinner': showSpinner}"></div>
    <section class="productQnA-nav">
      <div class="btn-nav">
        <router-link
          tag="button"
          :to="{name: 'productQnA', params: {status: 'pending'}}"
          class="btn btn-rounded btn-gray-alt">
          Pending
        </router-link>
        <router-link
          tag="button"
          :to="{name: 'productQnA', params: {status: 'answered'}}"
          class="btn btn-rounded btn-gray-alt">
          Answered
        </router-link>
        <router-link
          tag="button"
          :to="{name: 'productQnA', params: {status: 'denied'}}"
          class="btn btn-rounded btn-gray-alt">
          Denied
        </router-link>
      </div>
    </section>
    <div class="search-section">
      <div class="productQnA-search">
        <input
          v-model="search"
          type="text"
          class="form-control" />
        <template>
          <i
            v-show="isEmpty"
            class="material-icons">
            search
          </i>
          <i
            v-show="isDirty"
            class="material-icons times"
            @click="search = ''">
            close
          </i>
        </template>
      </div>
      <div class="add-new-btn">
        <router-link
          tag="button"
          :to="{name: 'manufacturerCreate'}"
          type="button"
          class="btn btn-primary">
          Add New
        </router-link>
      </div>
      <div class="productQnA-table-pager">
        <div class="table-viewing-info">
          <label>Viewing {{ currentViewing | numeric }} -
            <span v-if="parseInt(currentViewing) + parseInt(pagination.per_page) - 1 < pagination.total">
              {{ parseInt(currentViewing) + parseInt(pagination.per_page) - 1 | numeric }}
            </span>
            <span v-else>
              {{ pagination.total | numeric }}
            </span> of {{ pagination.total | numeric }}
          </label>
        </div>
        <div class="input-group">
          <span class="input-group-addon">
            Showing:
          </span>
          <select
            v-model="pagination.per_page"
            class="form-control">
            <option
              v-for="o in paginationOptions.pageSizes"
              :key="`pager-${o}`"
              :value="o">
              {{ o }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div
      v-if="productQnAList.length"
      class="productQnA-list">
      <table class="table table-hover">
        <thead>
          <tr>
            <th></th>
            <th>
              <a @click="sortProductQnAs('catNum', sortAsc)">PRODUCT
                <i
                  v-if="sortCol == 'catNum' && sortAsc == true"
                  class="material-icons">
                  expand_more
                </i>
                <i
                  v-else-if="sortCol == 'catNum' && sortAsc == false"
                  class="material-icons">
                  expand_less
                </i>
                <i
                  v-else
                  class="material-icons no-sort">
                  code
                </i>
              </a>
            </th>
            <th>
              <a @click="sortProductQnAs('question', sortAsc)">QUESTION
                <i
                  v-if="sortCol == 'question' && sortAsc == true"
                  class="material-icons">
                  expand_more
                </i>
                <i
                  v-else-if="sortCol == 'question' && sortAsc == false"
                  class="material-icons">
                  expand_less
                </i>
                <i
                  v-else
                  class="material-icons no-sort">
                  code
                </i>
              </a>
            </th>
            <th>
              <a
                v-if="status === 'pending'"
                @click="sortProductQnAs('formattedCreateDate', sortAsc)">SUBMITTED ON
                <i
                  v-if="sortCol == 'formattedCreateDate' && sortAsc == true"
                  class="material-icons">
                  expand_more
                </i>
                <i
                  v-else-if="sortCol == 'formattedCreateDate' && sortAsc == false"
                  class="material-icons">
                  expand_less
                </i>
                <i
                  v-else
                  class="material-icons no-sort">
                  code
                </i>
              </a>
              <a
                v-if="status === 'answered'"
                @click="sortProductQnAs('answer', sortAsc)">Answer
                <i
                  v-if="sortCol == 'answer' && sortAsc == true"
                  class="material-icons">
                  expand_more
                </i>
                <i
                  v-else-if="sortCol == 'answer' && sortAsc == false"
                  class="material-icons">
                  expand_less
                </i>
                <i
                  v-else
                  class="material-icons no-sort">
                  code
                </i>
              </a>
              <a
                v-if="status === 'denied'"
                @click="sortProductQnAs('denyReason', sortAsc)">Deny Reason
                <i
                  v-if="sortCol == 'denyReason' && sortAsc == true"
                  class="material-icons">
                  expand_more
                </i>
                <i
                  v-else-if="sortCol == 'denyReason' && sortAsc == false"
                  class="material-icons">
                  expand_less
                </i>
                <i
                  v-else
                  class="material-icons no-sort">
                  code
                </i>
              </a>
            </th>
            <th>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="prodQnA in productQnAList"
            :key="'productQnA-' + prodQnA.productQuestionId"
            @click="editProductQnA(prodQnA)">
            <td class="img-col">
              <div class="img-fluid">
                <img
                  v-if="prodQnA.folderName"
                  :src="`https://rexel-cdn.com/Products/${prodQnA.catNum}.jpg?i=${prodQnA.folderName}&f=150`" />
                <img
                  v-else
                  :src="`https://rexel-cdn.com/products/${prodQnA.catNum}.jpg?i=0EA7A6C5-7DD2-4482-9229-D628D3AFAE9D&f=150`" />
              </div>
            </td>
            <td class="id-col">
              Name:
              <span v-html="formatResult(prodQnA.catNum, search)"></span><br /> PIM#:
              <span v-html="formatResult(prodQnA.napmId, search)"></span><br /> UPC:
              <span v-html="formatResult(prodQnA.upc, search)"></span>
            </td>
            <td
              class="productQnA-col"
              v-html="formatResult(prodQnA.question, search)">
            </td>
            <td class="productQnA-col">
              <span
                v-if="status === 'pending'"
                v-html="formatResult(prodQnA.formattedCreateDate, search)">
              </span>
              <span
                v-if="status === 'answered'"
                v-html="formatResult(prodQnA.answer, search)">
              </span>
              <span
                v-if="status === 'denied'"
                v-html="formatResult(prodQnA.denyReason, search)">
              </span>
            </td>
            <td class="arrow-col">
              <span class="productQnA-arrow">
                <i class="material-icons">
                  keyboard_arrow_right
                </i>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-else
      class="alert alert-info">
      No Product Q &amp; A match the search term of
      <strong>"{{ search }}"</strong>. Try changing your search term.
    </div>
    <div
      v-if="!showSpinner"
      class="productQnA-pagination">
      <pagination
        :pagination="pagination"
        :callback="searchPagintation"
        :options="paginationOptions"></pagination>
    </div>
    <template v-if="!showSpinner && productQnAs.length">
      <edit
        :product-qn-a.sync="productQnAs[productQnAIndex]"
        :show.sync="editProductQnAModal"
        :index="productQnAIndex"
        @commit="getAllProductQnAs()"></edit>
    </template>
  </div>
</template>
<script type="text/javascript">
import axios from 'axios'
import EditProductQnAComponent from './edit.vue'
import TitleComponent from 'components/pageTitle.vue'
import PaginationComponent from 'components/pagination.vue'

export default {
  name: 'ProductQnAsList',
  components: {
    edit: EditProductQnAComponent,
    'page-title': TitleComponent,
    pagination: PaginationComponent
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.getAllProductQnAs()
    })
  },
  data () {
    return {
      productQnAs: [],
      sortCol: '',
      sortAsc: false,
      search: '',
      productQnAIndex: 0,
      pagination: {
        total: 0,
        per_page: 50, // required
        current_page: 1, // required
        last_page: 0, // required
        from: 0,
        to: 15 // required
      },
      paginationOptions: {
        offset: 5,
        previousText: 'Prev',
        nextText: 'Next',
        alwaysShowPrevNext: true,
        pageSizes: [25, 50, 100, 150, 250, 500]
      },
      currentViewing: 1,
      showSpinner: true,
      editProductQnAModal: false,
      status: 'pending'
    }
  },
  computed: {
    isEmpty () {
      return !this.search
    },
    isDirty () {
      return !!this.search
    },
    productQnAsFiltered () {
      let list = []
      const search = this.search.toLowerCase()
      if (!search) {
        list = this.productQnAs
      }
      list = this.productQnAs.filter((qnA, index) => {
        return (qnA.catNum && qnA.catNum.toLowerCase().indexOf(search) > -1) ||
          (qnA.napmId && qnA.napmId.toString().indexOf(search) > -1) ||
          (qnA.upc && qnA.upc.toString().indexOf(search) > -1) ||
          (qnA.question && qnA.question.toLowerCase().indexOf(search) > -1) ||
          (qnA.answer && qnA.answer.toLowerCase().indexOf(search) > -1) ||
          (qnA.denyReason && qnA.denyReason.toLowerCase().indexOf(search) > -1) ||
          (qnA.formattedCreateDate && qnA.formattedCreateDate.toLowerCase().indexOf(search) > -1)
      })
      this.currentViewing = 1
      this.pagination.current_page = 1
      this.pagination.total = list.length
      this.searchPagintation(true)
      return list
    },
    productQnAList () {
      this.currentViewing = (this.pagination.current_page - 1) * this.pagination.per_page + 1
      const list = this.productQnAsFiltered.filter((ven, index) => {
        return index >= ((this.pagination.current_page - 1) * this.pagination.per_page) && index <= ((this.pagination.current_page - 1) * this.pagination.per_page + this.pagination.per_page - 1)
      })
      return list
    }
  },
  watch: {
    $route: 'getAllProductQnAs'
  },
  methods: {
    editProductQnA (productQnA) {
      this.productQnAIndex = this.productQnAs.findIndex(prodQnA => {
        return prodQnA.productQuestionId === productQnA.productQuestionId
      })
      this.editProductQnAModal = true
    },
    getAllProductQnAs () {
      if (this.$route.params.status) {
        this.status = this.$route.params.status
      }
      axios.get('/api/productqna/GetProductQuestions/' + this.status).then(res => {
        const response = res.data
        this.pagination.total = response.length
        this.pagination.last_page = Math.ceil(response.length / this.pagination.per_page)
        this.productQnAs = response
        this.$nextTick(() => {
          this.showSpinner = false
        })
      })
    },
    searchPagintation (size) {
      if (size) {
        this.pagination.last_page = Math.ceil(this.pagination.total / this.pagination.per_page)
      }
    },
    escapeRegExChars (value) {
      return value.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
    },
    formatResult (suggestion, search) {
      // Do not replace anything if the current value is empty
      if (!search || search === '' || !suggestion) {
        return suggestion
      }

      const pattern = '(' + this.escapeRegExChars(search) + ')'

      return suggestion.toString().toLowerCase()
        .replace(new RegExp(pattern, 'gi'), '<strong>$1</strong>')
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/&lt;(\/?strong)&gt;/g, '<$1>')
    },
    sortIds (col, ascending) {
      this.pagination.current_page = 1
      ascending = !ascending
      this.sortCol = col
      this.sortAsc = ascending
      if (ascending) {
        this.productQnAs.sort((a, b) => a[col] - b[col])
      } else {
        this.productQnAs.sort((a, b) => b[col] - a[col])
      }
    },
    sortProductQnAs (col, ascending) {
      this.pagination.current_page = 1
      ascending = !ascending
      this.sortCol = col
      this.sortAsc = ascending
      if (ascending) {
        this.productQnAs.sort((a, b) => a[col].localeCompare(b[col]))
      } else {
        this.productQnAs.sort((a, b) => b[col].localeCompare(a[col]))
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/scss/bootstrap.scss';
@import '../../assets/scss/extra/mixins/flexbox.scss';
@import '../../assets/scss/bootstrap-custom-variables.scss';
.search-section {
  margin-bottom: 1.5rem;
  @include flexbox;
  .productQnA-search {
    position: relative;
    @include flex(0, 1, 40%);
    .form-control {
      & ~ .material-icons.times:hover,
      &:focus ~ .material-icons.times {
        cursor: pointer;
        color: $brand-danger;
      }
    }
    .material-icons {
      color: darken($gray-lighter, 25%);
      position: absolute;
      font-size: 1.125rem;
      top: 0.125rem;
      right: 0.125rem;
      padding: 0.5rem;
    }
  }
  .add-new-btn {
    padding: 0 1rem; // hidden until we are ready to add create..
    display: none;
    .btn {
      padding: 0.5rem 2rem;
    }
  }
  .productQnA-table-pager {
    @include flex(1, 1, 30%);
    @include flexbox;
    @include justify-content(flex-end);
    .table-viewing-info {
      width: 10rem;
      text-align: right;
      color: #222;
      font-size: 0.875rem;
      @include align-self(center);
      @include flex(1, 1, 10rem);

      > label {
        background: #eee;
        padding: 0.5rem 1.5rem;
        margin-bottom: 0;
        border: 1px solid rgba(0, 0, 0, 0.15);
      }
    }
    .input-group {
      width: 12rem;
      border: 0;

      .input-group-addon {
        font-size: 0.875rem;
        border: 0;
        background: #fff;
      }
      .form-control {
        font-size: 0.875rem;
        background: #eee;
      }
    }
  }
}

.productQnA-list {
  .table {
    border: 1px solid #eceeef;
    thead {
      tr {
        th {
          text-transform: uppercase;
          .material-icons {
            font-size: 1rem;
          }
          .no-sort {
            -ms-transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
          }
        }
      }
    }
    tbody {
      tr {
        td {
          vertical-align: middle;
          cursor: pointer;
        }
      }
    }
    .productQnA-col {
      width: 23.75rem;
    }
    .arrow-col {
      width: 4rem;
      text-align: center;
      .productQnA-arrow {
        display: block;
        position: relative;
        color: #fff;
        background: $brand-primary;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        &:hover {
          background: darken($brand-primary, 10%);
        }
        .material-icons {
          position: absolute;
          font-size: 1.5rem;

          top: 50%;
          right: 50%;
          margin: -0.75rem -0.825rem 0 0;
        }
      }
    }
    .img-col {
      width: 4.5rem;
      border-right: 1px solid #eceeef;
      .img-fluid {
        @include flexbox;
        @include justify-content(center);
        height: 3.75rem;
        img {
          @include align-self(center);
          max-height: 3.75rem;
          max-width: 3.75rem;
        }
      }
    }
    .id-col,
    .active-col {
      width: 6rem;
    }
  }
}

.productQnA-nav {
  @include flexbox;
  @include justify-content(space-between);

  .btn-nav {
    @include flex(1, 1, 75%);

    .router-link-exact-active {
      @extend .btn-primary;
    }
  }
}
</style>
