import { Line, mixins } from 'vue-chartjs'
const { reactiveData } = mixins
const lineChart = Line.extend({
  mixins: [reactiveData],
  props: ['chartLabels', 'chartDataset'],
  watch: {
    'chartDataset' (val, oldVal) {
      this.fillData()
    }
  },
  data () {
    return {
      dataSet: {}
    }
  },
  methods: {
    fillData: function () {
      this.dataSet = {
        labels: this.chartLabels,
        datasets: [
          {
            label: 'Enriched',
            backgroundColor: '#00416A',
            data: this.chartDataset
          }
        ]
      }
      this.render()
    },
    render: function () {
      this.renderChart(this.dataSet,
        {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [{
              stacked: true,
              ticks: {
                suggestedMax: 10,
                beginAtZero: true,
                callback: function (value) {
                  return Number(value).toFixed(0)
                }
              }
            }]
          },
          legend: {
            display: false
          },
          title: {
            display: true,
            fontColor: '#999',
            fontStyle: 'normal',
            fontSize: 16,
            text: 'TOTAL ENRICHED BY MONTH',
            fontFamily: "'PT Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                const index = tooltipItem.index
                return Number(data.datasets[0].data[index]).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
            }
          }
        })
    }
  }
})
export default lineChart
