<template>
  <div class="products-action">
    <button
      id="dropdownMenuButton"
      type="button"
      class="btn btn-primary dropdown-toggle"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false">
      <span class="pr-3">
        Actions
      </span>
    </button>
    <div
      class="dropdown-menu"
      aria-labelledby="dropdownMenuButton">
      <!--Bulk Export Current Results-->
      <button
        class="dropdown-item"
        type="button"
        @click="bulkExport(true)">
        Bulk Export Current Results
      </button>

      <!--Bulk Export Selected-->
      <button
        class="dropdown-item"
        :disabled="!selectedProductIds.length"
        :class="{'disabled': selectedProductIds.length == 0}"
        type="button"
        @click="bulkExport(false)">
        Bulk Export Selected ({{ selectedProductIds.length }})
      </button>

      <div class="dropdown-divider"></div>

      <!--Bulk Upload-->
      <button
        class="dropdown-item"
        type="button"
        @click="isImportDialogOpen = true">
        Bulk Upload
      </button>
    </div>

    <bulk-import :show-modal.sync="isImportDialogOpen"></bulk-import>
    <bulk-export
      :show-modal.sync="isExportDialogOpen"
      :ids="exportIds"
      @export="exportFile($event)"></bulk-export>
  </div>
</template>

<script>
import BulkExport from 'components/bulk/ProductExport'
import BulkImport from 'components/bulk/Import'
import PermissionsMixin from '@/mixins/Permissions.mixin'
import downloadFileFromApi from '@/utils/downloadFileService'

export default {
  name: 'ActionDropdown',
  components: {
    'bulk-import': BulkImport,
    'bulk-export': BulkExport
  },
  props: {
    ids: {
      type: Array
    },
    selectedProductIds: {
      type: Array
    },
    report: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      isImportDialogOpen: false,
      isExportDialogOpen: false,
      exportIds: []
    }
  },
  computed: {
    isAdmin () {
      return this.$store.getters.hasPermissionForAnyBanner(PermissionsMixin.data.Roles.admin)
    }
  },
  methods: {
    bulkExport (exportAll) {
      this.exportIds = (exportAll) ? this.ids : this.selectedProductIds
      this.isExportDialogOpen = true
    },
    exportFile (options) {
      this.bulkExportModal = false
      const params = {
        ...options,
        ids: options.ids.join()
      }

      const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&')
      const url = `/api/bulk/export?${queryString}`
      downloadFileFromApi(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.products-action {
  .dropdown-item:not(.disabled) {
    cursor: pointer;
  }
}

.dropdown-toggle {
  height: 43px;
}
</style>
