<template>
  <div class="list-group">
    <a class="list-group-item">
      <h5 class="list-group-title">
        <router-link :to="{name: 'categoryChildGroup', params: {sectionId: sectionId}}">
          <i class="material-icons">
            arrow_back
          </i>
        </router-link>
        Category
      </h5>
      <span v-if="averageSubCatCount">Average Number of Sub Categories in a Category:
        <span class="badge badge-light badge-pill">
          {{ averageSubCatCount }}
        </span>
      </span>
    </a>
    <a
      v-if="!list.length"
      class="list-group-item">
      None Found
    </a>
    <template v-for="s in list">
      <router-link
        :key="s.id"
        :to="{name: 'categoryChildSubCategory', params: {sectionId: sectionId, groupId: groupId, categoryId: s.id}}"
        class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
        <div class="category-section">
          <div class="category-image">
            <img
              v-if="s.mainImage"
              :src="`https://rexel-cdn.com/products/${s.name}.jpg?i=${s.mainImage}&f=35`" />
          </div>
          {{ s.name }} (ID {{ s.id }})
          <span class="badge badge-light badge-pill">
            {{ s.categoryCount }}
          </span>
        </div>
        <i class="material-icons">
          chevron_right
        </i>
      </router-link>
    </template>
  </div>
</template>
<script>
import Mixin from './mixin'
import EventBus from './bus'
export default {
  name: 'CategoryChildCategory',
  mixins: [Mixin],
  beforeRouteEnter (to, from, next) {
    next(vm => vm.getCategoryList())
  },
  props: ['sectionId', 'groupId'],
  data () {
    return {
      list: [],
      averageSubCatCount: null
    }
  },
  computed: {},
  mounted () {
    EventBus.$on('updateCats', payLoad => {
      this.getCategoryList()
    })
  },
  methods: {
    getCategoryList () {
      this.getList(this.groupId, false).then(res => {
        this.list = res.data
        const totalCat = this.list.length
        const totalSubCatCount = this.list.reduce((sum, item) => sum + item.categoryCount, 0)
        this.averageSubCatCount = (totalSubCatCount / totalCat).toFixed(1)
        this.$store.dispatch('setCategory', {
          key: 'category',
          value: res.data
        })
      })
    }
  }
}
</script>
<style lang="scss">
.category-section {
  div.category-image {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 100%;
    text-align: center;
    margin-right: 10px;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
  display: flex;
  height: 35px;
  align-items: center;
  line-height: 1.2;
  color: #000;
  @media screen and (max-width: 1024px) {
    font-size: 14px;
  }
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}
</style>
