<template>
  <div class="reports-page">
    <page-title title="Reports"></page-title>
    <div
      v-for="(reportType, index) in reportTypes"
      :key="`reports-${index}`">
      <h5 class="report-title">
        {{ reportType.displayName }}
      </h5>
      <ul class="list-unstyled">
        <li
          v-for="(report, index) in reportType.reports"
          :key="'report-' + report.displayName + '-' + index"
          class="list-item">
          <router-link
            v-if="report.to"
            tag="div"
            :to="{name: report.to}"
            class="card">
            <div class="report-card">
              <div class="info">
                <span class="report-name">
                  {{ formatResult(report.displayName, search) }}
                </span>
                <span class="report-description">
                  {{ formatResult(report.description, search) }}
                </span>
              </div>
              <div class="action">
                <i class="material-icons">
                  &#xE5C8;
                </i>
              </div>
            </div>
          </router-link>
          <div
            v-if="report.click"
            class="card"
            @click="report.click()">
            <div class="report-card">
              <div class="info">
                <span class="report-name">
                  {{ formatResult(report.displayName, search) }}
                </span>
                <span class="report-description">
                  {{ formatResult(report.description, search) }}
                </span>
              </div>
              <div class="action">
                <i class="material-icons">
                  &#xE5C8;
                </i>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script type="text/javascript">
import TitleComponent from 'components/pageTitle.vue'
import downloadFileFromApi from '@/utils/downloadFileService'

export default {
  name: 'ReportsPage',
  components: {
    'page-title': TitleComponent
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
    })
  },
  data () {
    return {
      search: '',
      reportTypes: [
        {
          displayName: 'Categories',
          reports: [
            { displayName: 'SubCategory Product Counts', to: 'reportsSubCategoryProductCounts' }
          ]
        },
        {
          displayName: 'Downloads',
          reports: [
            { displayName: 'Sales Tier 1 & 2 Products Missing Enrichment', click: this.downloadEnrichmentLevelReport },
            { displayName: 'Min Order Qty Comparison', click: this.downloadMinimumOrderQuantityMismatchedReport },
            { displayName: 'Products & Attributes', click: this.downloadProductsAttributesReport },
            { displayName: 'All Categories', click: this.downloadCategoriesReport },
            { displayName: 'Sku Match', click: this.downloadSkuMatchReport },
            { displayName: 'Media & Documents', click: this.downloadMediaDocumentsReport },
            { displayName: 'Sustainable Offer', click: this.downloadSustainableOfferReport },
            { displayName: 'Product Slang Terms', click: this.downloadProductSlangTermsReport },
            { displayName: 'Trademarks', click: this.downloadTrademarkReport },
            { displayName: 'Commodity Products', click: this.downloadCommodityProducts },
            { displayName: 'Manufacturers', click: this.downloadManufacturersReport },
            { displayName: 'ERP Item Match', click: this.downloadErpItemMatchReport },
            { displayName: 'Made in USA', click: this.downloadMadeInUsaReport }
          ]
        }
      ],
      downloading: false
    }
  },
  computed: {
    language () {
      return this.$store.getters.languageId
    }
  },
  watch: {
    downloading (val) {
      this.$store.dispatch('setSpinner', val)
    }
  },
  mounted () {
  },
  methods: {
    formatResult (suggestion, search) {
      // Do not replace anything if value is empty
      if (!search || search === '') {
        return suggestion
      }

      return suggestion.toLowerCase()
        .replace(new RegExp(`(${search.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')})`, 'gi'), '<b>$1</b>')
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/&lt;(\/?b)&gt;/g, '<$1>')
    },
    downloadEnrichmentLevelReport () {
      this.downloading = true
      downloadFileFromApi('/api/reports/enrichment-tier')
        .finally(() => {
          this.downloading = false
          this.$store.dispatch('setSpinner', false)
        })
    },
    downloadSustainableOfferReport () {
      this.downloading = true
      downloadFileFromApi('/api/reports/sustainable-offer')
        .finally(() => {
          this.downloading = false
          this.$store.dispatch('setSpinner', false)
        })
    },
    downloadProductSlangTermsReport () {
      this.downloading = true
      downloadFileFromApi('/api/reports/product-slang-terms')
        .finally(() => {
          this.downloading = false
          this.$store.dispatch('setSpinner', false)
        })
    },
    downloadTrademarkReport () {
      this.downloading = true
      downloadFileFromApi('/api/reports/trademark-report')
        .finally(() => {
          this.downloading = false
          this.$store.dispatch('setSpinner', false)
        })
    },
    downloadCommodityProducts () {
      this.downloading = true
      downloadFileFromApi('/api/reports/commodity-products-report')
        .finally(() => {
          this.downloading = false
          this.$store.dispatch('setSpinner', false)
        })
    },
    downloadMinimumOrderQuantityMismatchedReport () {
      this.downloading = true
      downloadFileFromApi('/api/reports/min-order-qty-mismatch')
        .finally(() => {
          this.downloading = false
          this.$store.dispatch('setSpinner', false)
        })
    },
    downloadProductsAttributesReport () {
      this.downloading = true
      downloadFileFromApi('/api/reports/product-attributes')
        .finally(() => {
          this.downloading = false
          this.$store.dispatch('setSpinner', false)
        })
    },
    downloadCategoriesReport () {
      this.downloading = true
      downloadFileFromApi('/api/reports/categories')
        .finally(() => {
          this.downloading = false
          this.$store.dispatch('setSpinner', false)
        })
    },
    downloadSkuMatchReport () {
      this.downloading = true
      downloadFileFromApi('/api/reports/sku-match')
        .finally(() => {
          this.downloading = false
          this.$store.dispatch('setSpinner', false)
        })
    },
    downloadMediaDocumentsReport () {
      this.downloading = true
      downloadFileFromApi('/api/reports/media-documents')
        .finally(() => {
          this.downloading = false
          this.$store.dispatch('setSpinner', false)
        })
    },
    downloadManufacturersReport () {
      this.downloading = true
      downloadFileFromApi('/api/reports/manufacturers')
        .finally(() => {
          this.downloading = false
          this.$store.dispatch('setSpinner', false)
        })
    },
    downloadErpItemMatchReport () {
      this.downloading = true
      downloadFileFromApi('/api/reports/erp-item-match')
        .finally(() => {
          this.downloading = false
          this.$store.dispatch('setSpinner', false)
        })
    },
    downloadMadeInUsaReport () {
      this.downloading = true
      downloadFileFromApi('/api/reports/made-in-usa')
        .finally(() => {
          this.downloading = false
          this.$store.dispatch('setSpinner', false)
        })
    }

  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/scss/bootstrap-custom-variables.scss';
@import '../../assets/scss/extra/mixins/flexbox.scss';

.report-title {
  font-size: 1.5rem;
  font-weight: 500;
  padding: .5rem .5rem 0;
}

ul.list-unstyled {
  @include flexbox;
  @include flex-wrap(wrap);
  li.list-item {
    padding: .5rem;
    @include flex(0,1,100%);
    @media (min-width: 35.5rem) {
      @include flex(0,1,calc(100% / 2))
    }
    @media (min-width: 75rem) {
      @include flex(0,1,calc(100% / 3))
    }
    @media (min-width: 105rem) {
      @include flex(0,1,calc(100% / 4))
    }

    .report-card {
    @include flexbox;
    @include justify-content(space-between);
    background-color: $gray-lighter;

    >.avatar {
      @include flex(0, 1, 2.5rem);
      @include align-self(center);
      margin-right: .5rem;
    }

    >.info {
      @include flex(1, 1, 2rem);
      @include align-self(center);
      font-size: .875rem;
      text-transform: uppercase;
      padding: .5rem 1rem;
      background-color: $gray-lighter !important;
    }

    >.action {
      @include flexbox;
      @include flex(0, 1, 2rem);
      @include align-self(center);
      @include flex-direction(column);
      @include justify-content(center);
      padding: .25rem .75rem;
      .total {
        font-weight: bold;
        font-size: 1rem;
        @include flex(0, 1, 1.25rem);
        @include align-self(center);
      }
      .total-lbl {
        font-size: .675rem;
        color: $gray-light;
        @include flex(0, 1, 1.25rem);
        @include align-self(center);
      }
    }
    &:hover {
      cursor: pointer;
      background: darken($gray-lighter, 5%);
    }
  }
  }
}
</style>
