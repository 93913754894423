<template>
  <div
    class="quill-editor"
    :class="{'disabled': disabled}">
    <slot name="toolbar"></slot>
    <div ref="editor"></div>
  </div>
</template>
<script type="text/javascript">
import Quill from 'quill'
import 'quill/dist/quill.snow.css'

if (!window.Quill) {
  window.Quill = Quill
}

export default {
  name: 'QuillEditor',
  props: {
    content: String,
    value: String,
    disabled: Boolean,
    options: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      internalContent: '',
      defaultModules: {
        toolbar: [
          ['bold', 'italic', 'underline', { list: 'ordered' }, { list: 'bullet' }]
        ]
      }
    }
  },
  watch: {
    content (newVal, oldVal) {
      if (this.quill) {
        if (!!newVal && newVal !== this.internalContent) {
          this.internalContent = newVal
          const delta = this.quill.clipboard.convert(newVal)
          this.quill.setContents(delta, 'silent')
        } else if (!newVal) {
          this.quill.setText('')
        }
      }
    },
    value (newVal, oldVal) {
      if (this.quill) {
        if (!!newVal && newVal !== this.internalContent) {
          this.internalContent = newVal
          const delta = this.quill.clipboard.convert(newVal)
          this.quill.setContents(delta, 'silent')
        } else if (!newVal) {
          this.quill.setText('')
        }
      }
    },
    disabled (newVal, oldVal) {
      if (this.quill) {
        this.quill.enable(!newVal)
      }
    }
  },
  mounted () {
    this.initialize()
  },
  beforeDestroy () {
    this.quill = null
  },
  methods: {
    initialize () {
      if (this.$el) {
        // options and instance
        this.options.theme = this.options.theme || 'snow'
        this.options.boundary = this.options.boundary || document.body
        this.options.modules = this.options.modules || this.defaultModules
        this.options.modules.toolbar = this.options.modules.toolbar !== undefined
          ? this.options.modules.toolbar
          : this.defaultModules.toolbar
        this.options.placeholder = this.options.placeholder || 'Insert text here ...'
        this.options.readOnly = this.disabled
        this.quill = new Quill(this.$refs.editor, this.options)

        // set editor content
        if (this.value || this.content) {
          const deltaVal = this.value || this.content
          const delta = this.quill.clipboard.convert(deltaVal)
          this.quill.setContents(delta, 'silent')
        }

        // mark model as touched if editor lost focus
        this.quill.on('selection-change', (range) => {
          if (!range) {
            this.$emit('blur', this.quill)
          } else {
            this.$emit('focus', this.quill)
          }
        })

        // update model if text changes
        this.quill.on('text-change', (delta, oldDelta, source) => {
          let html = this.$refs.editor.children[0].innerHTML
          const text = this.quill.getText()
          if (html === '<p><br></p>') html = ''
          this.internalContent = html
          this.$emit('input', this.internalContent)
          this.$emit('change', {
            editor: this.quill,
            html,
            text
          })
        })

        // emit ready
        this.$emit('ready', this.quill)
      }
    }
  }
}
</script>
<style lang="scss">
@import '../assets/scss/bootstrap-custom-variables';
$active-blue: #39628e;

.quill-editor {
  .ql-container {
    .ql-editor {
      min-height: 100px;
      font-size: 1rem;
      color: #464a4c !important;
    }
  }
  &.disabled {
    .ql-toolbar {
      &.ql-snow {
        button {
          pointer-events: none;
          cursor: not-allowed;
          opacity: 0.75;
        }
      }
    }
    .ql-editor,
    .ql-editor * {
      background-color: #eceeef;
      color: #464a4c !important;
    }
  }

  .ql-toolbar {
    &.ql-snow {
      padding: 0.25rem 0;
      border: 0;
      button {
        border: 1px solid #ccc;
        margin-right: 0.25rem;
        height: 1.75rem;
        width: 2rem;
        &.ql-active,
        &:focus,
        &:hover,
        &:active {
          color: #fff !important;
          background: $active-blue;
          border-color: darken($active-blue, 5%);
          .ql-stroke {
            stroke: #fff;
          }
        }
      }
      & + .ql-container.ql-snow {
        border-top: 1px solid #ccc;
      }
    }
  }
}

.has-success {
  .quill-editor {
    .ql-container.ql-snow {
      border-color: $state-success-border !important;
      .ql-editor {
        background-color: $state-success-bg;
        color: $state-success-text;
      }
    }
  }
}
.ql-container.ql-snow {
  transition: border-color 0.3s ease;
}
.is-invalid .ql-container.ql-snow {
  border-color: #c00 !important;
}
.has-danger {
  .quill-editor {
    .ql-container.ql-snow {
      border-color: $state-danger-border !important;
      .ql-editor {
        background-color: $state-danger-bg;
        color: $state-danger-text;
      }
    }
  }
}
</style>
