<template>
  <modal
    ref="createAttributeValueModal"
    title="Create Attribute Value"
    :icon="modal.icon"
    @close="modalReset">
    <template v-if="saveResult">
      <div class="lead text-center">
        {{ modal.message }}
      </div>
    </template>
    <template v-else>
      <upload-image-attr
        :is-saving="isSaving"
        @setUploadData="setUploadData"></upload-image-attr>
      <div class="form-group">
        <label
          class="form-control-label"
          :class="{'text-danger': existingValue && attributeValue.length}">
          Attribute Value Name*
        </label>
        <input
          v-model="attributeValue"
          type="text"
          class="form-control"
          :class="{'is-invalid': existingValue && attributeValue.length}" />
      </div>
      <div class="alert alert-info mb-0">
        <i class="material-icons">
          info_outline
        </i>
        Any new Attribute value must be unique.
      </div>
    </template>
    <div
      slot="button"
      class="d-flex justify-content-between">
      <button
        class="btn btn-link"
        @click="$emit('update:showModal', false)">
        {{ saveResult ? 'Close' : 'Cancel' }}
      </button>
      <button
        v-if="!saveResult"
        class="btn btn-primary"
        :disabled="existingValue || !attributeValue.length"
        @click="createAttributeValue">
        {{ loading ? 'Saving...' : 'Create Value' }}
      </button>
    </div>
  </modal>
</template>
<script>
import axios from 'axios'
import ModalComponent from 'components/modals/sweet.vue'
import UploadImageAttr from './uploadImageAttribute.vue'

export default {
  name: 'CreateValue',
  components: {
    modal: ModalComponent,
    UploadImageAttr
  },
  props: {
    showModal: Boolean,
    values: Array,
    type: Object
  },
  data () {
    return {
      modal: {
        message: '',
        icon: null,
        blockClose: false
      },
      loading: false,
      saveResult: false,
      attributeValue: '',
      isSaving: false,
      formData: {}
    }
  },
  computed: {
    existingValue () {
      const search = this.attributeValue.replace(/ /g, '')
      return this.values.some(a => a.attributeValue.replace(/ /g, '') === search)
    }
  },
  watch: {
    showModal (value) {
      if (value) {
        this.initModal()
      } else if (!value && this.$refs.createAttributeValueModal.is_open) {
        this.$refs.createAttributeValueModal.close()
      }
    }
  },
  methods: {
    modalReset () {
      this.formData = {}
      this.isSaving = true
      this.$emit('update:showModal', false)
    },
    setUploadData (data) {
      this.formData = data
    },
    createAttributeValue () {
      const { file, points, fileName, rotated } = this.formData
      const data = new FormData()
      data.append('file', file || null)
      data.append('x1', points ? points[0] : null)
      data.append('y1', points ? points[1] : null)
      data.append('x2', points ? points[2] : null)
      data.append('y2', points ? points[3] : null)
      data.append('fileName', fileName || null)
      data.append('rotate', rotated || null)
      data.append('attributeTypeId', this.type.typeId)
      data.append('attributeValue', this.attributeValue)
      data.append('languageId', this.$store.getters.languageId)

      this.loading = true
      axios.post('/api/attributes/createAttributeAsync', data, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
        console.log(res)
        if (res.status === 200 || res.status === 204) {
          this.attributeValue = ''
          this.modal.icon = 'success'
          this.modal.message = 'Saved new Attribute value.'
        } else {
          this.modal.icon = 'error'
          this.modal.message = 'Error Saving Attribute value'
        }
        this.saveResult = true
        this.loading = false
        this.$emit('fetch')
        setTimeout(() => {
          this.$refs.createAttributeValueModal._animateIcon()
        }, 50)
      })
    },
    initModal () {
      this.loading = false
      this.saveResult = false
      this.attributeValue = ''
      this.formData = {}
      this.modal = {
        message: '',
        icon: null,
        blockClose: false
      }
      this.$refs.createAttributeValueModal.open()
    }
  }
}
</script>
<style lang="scss">
</style>
