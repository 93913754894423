<template style="background-color:inherit">
  <section id="documents">
    <div class="section-heading">
      <h6>External Media linked to Product:</h6>
    </div>
    <div class="section-body">
      <v-data-table
        :headers="videoListHeaders"
        :items="videoUrlList"
        :search="search"
        density="compact"
        :footer-props="{
          'items-per-page-options': [10, 25, 50]
        }">
        <template #top>
          <v-text-field
            v-model="search"
            class="ml-3 mr-3"
            label="Search">
          </v-text-field>
          <v-row
            v-if="fullUrlList.length !== limitedUrlList.length"
            align-content="space-between">
            <v-col class="ml-3">
              <v-switch
                v-model="isShowAllVideoUrls"
                label="Show Media Already Excluded"
                @click="toggleIsShowAllVideoUrls()"></v-switch>
            </v-col>
            <v-col
              cols="2"
              align-self="center">
              <v-label style="white-space: nowrap;">
                * Already Excluded
              </v-label>
            </v-col>
          </v-row>
        </template>
        <template
          #item.exclusionInd="{item}">
          <v-checkbox
            v-model="item.exclusionInd"
            :disabled="item.externalMediaExclusionInd === true"
            :label="item.externalMediaExclusionInd === true ? '*' : ''"
            @click="exclusionOnChange(item.productExternalMediaId, item.exclusionInd)">
          </v-checkbox>
        </template>
        <template
          #item.showItem="{item}">
          <i
            style="cursor:pointer; font-size: 1.275rem;"
            class="material-icons"
            @click="viewMedia(item.externalMediaUrl)">
            visibility
          </i>
        </template>
      </v-data-table>
    </div>
  </section>
</template>
<script type="text/javascript">

export default {
  props: {
    associatedVideos: Array,
    napmIdentifier: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      videoUrlList: [],
      fullUrlList: [],
      limitedUrlList: [],
      videoListHeaders: [
        {
          text: 'External URLs',
          value: 'externalMediaUrl',
          width: '80%'
        },
        {
          text: 'Exclude Media',
          value: 'exclusionInd',
          width: '10%',
          sortable: false
        },
        {
          text: 'View Media',
          width: '10%',
          sortable: false,
          value: 'showItem'
        }],
      search: '',
      isShowAllVideoUrls: false
    }
  },
  watch: {
    'associatedVideos' (value) {
      this.fullUrlList = JSON.parse(JSON.stringify(value))
      this.limitedUrlList = JSON.parse(JSON.stringify(this.fullUrlList.filter(x => x.externalMediaExclusionInd !== true)))
      this.videoUrlList = this.limitedUrlList
    }
  },
  mounted () {
    this.videoUrlList = this.associatedVideos
  },
  methods: {
    rowStyle (item) {
      return item.externalMediaExclusionInd === true ? 'highlighted' : ''
    },
    toggleIsShowAllVideoUrls () {
      if (this.isShowAllVideoUrls) {
        this.videoUrlList = this.fullUrlList
      } else {
        this.videoUrlList = this.limitedUrlList
      }
    },
    viewMedia (url) {
      window.open(url, '_blank')
    },
    exclusionOnChange (id, value) {
      const data = new FormData()
      data.append('value', value)
      data.append('userId', this.$store.getters.user.userId)
      this.$http.put('/api/media/externalvideos/productmedia/' + id, data)
        .then(res => {
        })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/extra/mixins/flexbox.scss";
@import "../../../assets/scss/bootstrap-custom-variables.scss";

section {
  border: 1px solid #c9c9c9;
  background: #eee;
  margin: 2rem 0;
  .section-heading {
    border-bottom: 0;
    position: relative;
    h6 {
      padding: 0 1.25rem;
    }
  }

  .highlighted {
  background-color:yellow !important;
}
}

</style>
