<template>
  <section id="keywords">
    <div class="section-heading">
      <h6>Keywords:</h6>
    </div>
    <div class="section-body">
      <div class="form-group">
        <label class="form-control-label">
          Keywords - Displayed: (press enter after each keyword or phrase)
        </label>
        <tagging-input :tags.sync="keywords"></tagging-input>
      </div>
    </div>
    <div class="section-body">
      <div class="form-group">
        <label class="form-control-label">
          Keywords - Search Only: (press enter after each keyword or phrase)
        </label>
        <tagging-input :tags.sync="keywordsSearchOnly"></tagging-input>
      </div>
    </div>
  </section>
</template>
<script type="text/javascript">
import TaggingComponent from 'components/tagging.vue'

export default {
  components: {
    'tagging-input': TaggingComponent
  },
  props: {
    keywords: Array,
    keywordsSearchOnly: Array
  }
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/extra/mixins/flexbox";

section {
  border: 1px solid #c9c9c9;
  background: #eee;
  margin: 2rem 0;
  .section-heading {
    position: relative;
    border-bottom: 0;
    h6 {
      padding: 0 1.25rem;
    }
    .idw-btn {
      position: absolute;
      top: 1rem;
      right: 1rem;
      min-width: 8.125rem;
      @include flexbox;
      @include justify-content(space-between);
    }
  }

  .section-body {
    padding: 0 1rem 0.5rem;
  }
}
</style>
