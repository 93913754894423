<template>
  <section id="substitutes">
    <div class="section-heading">
      <h6>Substitutes:</h6>
      <div
        v-if="substitutes.length"
        class="ledgend">
        <span>
          <i class="material-icons">
            swap_horiz
          </i>
        </span>
      </div>
      <button
        v-if="substitutes.length"
        type="button"
        class="btn btn-primary"
        @click="substituteModal = true">
        Add Products
      </button>
    </div>
    <div
      v-if="substitutes.length"
      class="section-body">
      <div class="substitute-deck">
        <div
          v-for="(sub, index) in substitutesDisplay"
          :key="'substitute-item-' + index"
          class="card substitute-item">
          <a
            class="substitute-delete"
            @click="removeSubstitute(sub, index)">
            <i class="material-icons">
              clear
            </i>
          </a>
          <div class="card-img-top">
            <img
              v-if="sub.mainImage"
              :src="`https://rexel-cdn.com/products/${sub.catNum}.jpg?i=${sub.mainImage}&f=77`" />
            <img
              v-else
              :src="`https://rexel-cdn.com/products/${sub.catNum}.jpg?i=0EA7A6C5-7DD2-4482-9229-D628D3AFAE9D&f=77`" />
          </div>
          <div class="substitute-info">
            <div class="substitute-mfr">
              {{ sub.mfrName }}
            </div>
            <div class="substitute-cat">
              <router-link
                :to="{ name: 'productEdit', params: { id: sub.napmId }}">
                {{ sub.catNum }}
              </router-link>
            </div>
          </div>
          <div class="dropup">
            <a
              v-if="sub.crossFilled"
              :id="'cross-filled-btn-' + index"
              class="cross-filled"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false">
              <i class="material-icons">
                swap_horiz
              </i>
            </a>
            <div
              class="dropdown-menu dropdown-menu-left"
              :aria-labelledby="'cross-filled-btn-' + index">
              <h6 class="dropdown-header">
                Remove Cross Filled
              </h6>
              <a
                class="dropdown-item"
                @click="removeCrossFilled(sub, true, $event)">
                This Product
              </a>
              <a
                class="dropdown-item"
                @click="removeCrossFilled(sub, true, $event)">
                Both Products
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="substitutes-action">
        <button
          type="button"
          class="btn btn-rounded btn-primary"
          @click="substituteAllModal = true">
          View All &amp; Edit
        </button>
      </div>
    </div>
    <div
      v-else
      class="section-body no-products">
      <p>No products have been added</p>
      <button
        type="button"
        class="btn btn-primary"
        @click="substituteModal = true">
        Add Products
      </button>
    </div>
    <add-substitute
      :show.sync="substituteModal"
      title="Add Substitute"
      crosstype="Substitutes"
      :crossid="2"
      @save="mergeSubs($event, true)"></add-substitute>
    <view-substitutes
      :show.sync="substituteAllModal"
      :products.sync="substitutes"
      title="View All Substitutes"
      crosstype="Substitutes"
      :crossid="2"
      @save="mergeSubs($event)"></view-substitutes>
  </section>
</template>
<script type="text/javascript">
import AddSubstitute from './crossFilled/addCrossFilled.vue'
import ViewAllSubstitutes from './crossFilled/viewAllCrossFilled.vue'

export default {
  components: {
    'add-substitute': AddSubstitute,
    'view-substitutes': ViewAllSubstitutes
  },
  props: {
    substitutesActions: Array,
    substitutes: Array,
    napmid: Number
  },
  data () {
    return {
      substituteModal: false,
      substituteAllModal: false,
      crossSellTypeId: 2
    }
  },
  computed: {
    substitutesDisplay () {
      return this.substitutes.slice(0, 9)
    }
  },
  methods: {
    mergeSubs (prodList, newProd) {
      prodList.forEach(prod => {
        if (newProd) {
          this.substitutes.unshift(prod)
        }
        const prodAction = {
          parentId: this.napmid,
          napmId: prod.napmId,
          displayOrder: prod.displayOrder,
          crossFilled: prod.crossFilled,
          crossSellTypeId: this.crossSellTypeId,
          requestedAction: prod.removeProd ? 'Delete' : 'Add'
        }
        this.substitutesActions.push(prodAction)
      })
    },
    removeSubstitute (item, index) {
      this.substitutes.splice(index, 1)
      const prod = {
        parentId: this.napmid,
        napmId: item.napmId,
        displayOrder: item.displayOrder,
        crossFilled: false,
        crossSellTypeId: this.crossSellTypeId,
        requestedAction: 'Delete'
      }
      this.substitutesActions.push(prod)
    },
    removeCrossFilled (item, both, event) {
      item.crossFilled = false
      event.target.parentElement.offsetParent.remove('show')

      if (both) {
        const prod = {
          parentId: this.napmid,
          napmId: item.napmId,
          crossFilled: true,
          displayOrder: item.displayOrder,
          crossSellTypeId: this.crossSellTypeId,
          requestedAction: 'Delete'
        }
        this.substitutesActions.push(prod)
      } else {
        const prod = {
          parentId: this.napmid,
          napmId: item.napmId,
          displayOrder: item.displayOrder,
          crossFilled: false,
          crossSellTypeId: this.crossSellTypeId,
          requestedAction: 'Add'
        }
        this.substitutesActions.push(prod)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/scss/extra/mixins/flexbox';
@import '../../../assets/scss/bootstrap-custom-variables';
#substitutes {
  border: 1px solid #c9c9c9;
  background: #eee;
  margin-bottom: 2rem;
  .section-heading {
    border-bottom: 0;
    @include flexbox;
    @include justify-content(space-between);
    > h6 {
      @include flex(1, 1, 80%);
      padding: 0 1.25rem;
    }
    .ledgend {
      @include flex(0, 1, 8rem);
      @include align-self(center);
      padding-top: 0.375rem;
      > span {
        position: relative;
        color: #fff;
        background: $brand-primary;
        width: 1.25rem;
        height: 1.325rem;
        display: inline-block;
        border: 1px solid #c9c9c9;
        &:after {
          content: 'Cross Filled';
          font-size: 0.875rem;
          color: #333;
          width: 6rem;
          position: absolute;
          left: 1.75rem;
          font-weight: 500;
        }
      }
      .material-icons {
        position: absolute;
        top: 50%;
        right: 50%;
        margin: -0.55rem -0.5rem 0 0;
      }
    }
    > .btn {
      margin: 0 1.25rem;
    }
  }

  .section-body {
    padding: 0 1rem 1rem;
    &.no-products {
      text-align: center;
      margin-bottom: 1rem;
      color: #333;
      font-size: 0.875rem;
      .btn {
        font-size: 0.875rem;
        padding: 0.5rem 1.25rem;
      }
    }
  }
  .substitute-deck {
    @include flexbox;
    @include flex-flow(row);
    @include flex-wrap(nowrap);
    overflow: hidden;
    position: relative;
    &:after {
      content: '';
      width: 1rem;
      height: 12rem;
      position: absolute;
      top: 0;
      right: 0;
      background: #eee;
    }
  }
  .substitute-item {
    position: relative;
    width: 8rem;
    min-width: 8rem;
    height: 11rem;
    margin-bottom: 1rem;
    &:not(:last-child) {
      margin-right: 1rem;
    }
    .substitute-delete {
      position: absolute;
      right: 0.25rem;
      top: 0;
      color: $brand-danger;
      font-weight: 500;
      &:hover {
        color: darken($brand-danger, 10%);
      }
    }
    .card-img-top {
      min-height: 6rem;
      padding-top: 0.675rem;
      @include flexbox;
      @include justify-content(center);

      > img {
        @include align-self(center);
      }
    }
    .substitute-info {
      height: 100%;
      padding: 0.5rem 0.75rem 0.25rem;
      .substitute-mfr {
        font-size: 0.875rem;
        font-weight: bold;
        padding-bottom: 0.25rem;
      }
      .substitute-cat {
        font-size: 0.75rem;
      }
    }
    .cross-filled {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 1.75rem;
      height: 1.75rem;
      background: $brand-primary;
      border: 1px solid #c9c9c9;
      cursor: pointer;
      .material-icons {
        position: absolute;
        margin: -0.5rem -0.5rem 0 0;
        top: 50%;
        right: 50%;
        color: #fff;
      }
      &:hover {
        background: darken($brand-primary, 10%);
      }
    }
  }
  .dropdown-menu {
    padding: 0;
    font-size: 0.875rem;
    .dropdown-header {
      background: #eee;
      color: #000;
    }
    .dropdown-item {
      border-top: 1px solid #d9d9d9;
    }
  }
}

.substitutes-action {
  text-align: center;
}
</style>
