<template>
  <div>
    <!--Total-->
    <div
      v-if="products.length > 0"
      class="row">
      <div class="col">
        <label class="custom-control custom-checkbox mt-3">
          <input
            id="select-all-check"
            v-model="selectedAllProducts"
            type="checkbox"
            class="custom-control-input" />
          <span class="custom-control-indicator"></span>
          <span class="custom-control-description">
            Select All ({{ products.length }})
          </span>
        </label>
      </div>
      <div class="col">
        <p class="text-right mb-2 mt-3">
          <span>Showing </span>
          <strong v-if="showingProductCount > 1">
            {{ (showingProductCount - pagination.per_page) + 1 | numeric }} -
          </strong>
          <strong>{{ showingProductCount > pagination.total ? pagination.total : showingProductCount | numeric }}</strong>
          <span v-if="showingProductCount > 1">
            out of
          </span>
          <span v-else>
            -
          </span>
          <strong>{{ pagination.total | numeric }}</strong>
          <span> total products.</span>
        </p>
      </div>
    </div>

    <table
      id="search-results-table"
      class="table table-striped"
      :class="{'page-loading': $store.getters.showSpinner}">
      <tbody>
        <tr
          v-for="(p, i) in products"
          :key="`product-${p.napmId + '-' + i}`">
          <td class="check-cell">
            <label class="custom-control custom-checkbox">
              <input
                :id="`check-${p.napmId}`"
                v-model="selectedProducts"
                type="checkbox"
                class="custom-control-input"
                :value="p.napmId" />
              <span class="custom-control-indicator">
              </span>
              <span class="custom-control-description">
&nbsp;
              </span>
            </label>
          </td>
          <router-link
            tag="td"
            :to="{ name: 'productEdit', params: { id: p.napmId }}"
            class="img-cell hidden-md-down">
            <div class="img-thumbnail">
              <img
                v-if="p.mainImage !== null"
                :src="`https://rexel-cdn.com/products/${p.napmId}.jpg?i=${p.mainImage}&f=77`" />
              <img
                v-else
                :src="`https://rexel-cdn.com/products/${p.napmId}.jpg?i=0EA7A6C5-7DD2-4482-9229-D628D3AFAE9D&f=77`" />
            </div>
          </router-link>
          <router-link
            tag="td"
            :to="{ name: 'productEdit', params: { id: p.napmId }}"
            class="md-cell">
            <p>
              <label>Name: </label>
              <span>
                <strong class="text-primary">
                  {{ p.catNum }}
                </strong>
                <small
                  v-if="p.manufacturer"
                  class="text-muted">
                  <i>by: </i> {{ p.manufacturer.name }}</small>
              </span>
            </p>
            <p>
              <label>PIM #: </label>
              <span>{{ p.napmId }}</span>
            </p>
            <p class="product-description">
              <label>Description: </label>
              <span v-if="p.description">
                {{ p.description }}
              </span>
              <strong v-else>
                -
              </strong>
            </p>
          </router-link>
          <router-link
            tag="td"
            :to="{ name: 'productEdit', params: { id: p.napmId }}"
            class="lg-cell">
            <p
              v-for="(banners, i) in p.companySystems"
              :key="banners.productId + i">
              <template v-if="banners.productId !== 0 && banners.name !== null">
                <label class="ellipsis">
                  {{ banners.name }} #:
                </label>
                <span> {{ banners.erpItemNumber && banners.erpItemNumber.length > 0 ? banners.erpItemNumber : banners.productId }}</span>
              </template>
            </p>
            <p>
              <label>Categories: </label>
              <strong
                v-if="p.subCategory"
                class="text-primary">{{ p.section }}, {{ p.group }}, {{ p.category }}, {{ p.subCategory }}</strong>
              <strong v-else>
                -
              </strong>
            </p>
          </router-link>
          <router-link
            tag="td"
            :to="{ name: 'productEdit', params: { id: p.napmId }}"
            class="edit-prod-btn">
            <div class="prod-btn-wrap">
              <span
                class="product-enriched-circle"
                :class="{'not-enriched': !p.isEnriched}">
                <i class="material-icons">
                  brush
                </i>
              </span>
              <span class="product-circle-arrow">
                <i class="material-icons">
                  keyboard_arrow_right
                </i>
              </span>
            </div>
          </router-link>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'Results',
  props: {
    products: {
      type: Array,
      required: true
    },
    pagination: {
      type: Object
    }
  },
  data () {
    return {
      selectedAllProducts: false,
      selectedProducts: []
    }
  },
  computed: {
    showingProductCount () {
      if (this.pagination.total <= this.pagination.per_page) {
        return 1
      } else {
        return (this.pagination.current_page - 1) * this.pagination.per_page + this.products.length
      }
    }
  },
  watch: {
    selectedAllProducts (val) {
      this.selectedProducts = (val)
        ? this.products.map(product => {
          return product.napmId
        })
        : []
    },
    selectedProducts (val) {
      this.$emit('onSelected', val)
    }
  }
}
</script>

<style lang="scss">
@import "../../../assets/scss/bootstrap-custom-variables";
@import "../../../assets/scss/extra/mixins/flexbox.scss";

#search-results-table {
  border: 1px solid #eceeef;

  &.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f0fafe;
  }

  td {
    cursor: pointer;
    vertical-align: middle;

    > p {
      margin-bottom: 0;

      > label {
        margin-bottom: 0;
      }
    }

    &.check-cell {
      width: 2rem;
      text-align: center;
      padding: 1rem 0 0 1rem;
    }

    &.img-cell {
      width: 5rem;
      text-align: center;

      .img-thumbnail {
        width: 4.25rem;
        height: 4.25rem;
        @include flexbox;
        @include justify-content(center);
        img {
          @include align-self(center);
          max-height: 100%;
          max-width: 100%;
        }
      }
    }

    &.md-cell {
      width: 40%;
    }

    &.lg-cell {
      min-width: 50%;
      max-width: 60%;
      .product-description {
        display: block; /* Fallback for non-webkit */
        display: -webkit-box;
        max-width: 100%;
        height: 0.8125rem * 1.5; /* Fallback for non-webkit */
        font-size: 0.8125rem;
        line-height: 1.5;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &.edit-prod-btn {
      width: 7.75rem;
      .prod-btn-wrap {
        @include flexbox;
        @include justify-content(space-around);
      }
    }
  }
}

.btn-group {
  > .btn:first-child:not(:last-child) {
    @include flex(1, 1, 80%);
  }

  > .btn:last-child:not(:first-child),
  > .dropdown-toggle:not(:first-child) {
    border-left-color: rgba(255, 255, 255, 0.37);
  }
}

.product-enriched-circle {
  border-radius: 50%;
  background: $gray-300;
  border: 1px solid darken($gray-400, 5%);
  color: $brand-primary;
  position: relative;
  height: 2.5rem;
  width: 2.5rem;
  display: inline-block;
  &.not-enriched {
    color: $gray-500;
    border: none;
  }
  .material-icons {
    font-size: 1.5rem;
    position: absolute;
    top: 50%;
    right: 50%;
    margin: -0.75rem -0.75rem 0 0;
  }
}
</style>
