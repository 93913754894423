<template>
  <div>
    <page-title title="Product Reviews"></page-title>
    <div
      class="spinner"
      :class="{'show-spinner': showSpinner}"></div>
    <div>
      <div
        v-if="product"
        class="d-flex align-items-center border-solid text-14">
        <div class="img-fluid product-img-container mr-4">
          <img
            v-if="product.folderName"
            class="border-solid"
            :src="'https://rexel-cdn.com/products/' + product.folderName + '/50.jpg'" />
          <img
            v-else
            class="border-solid"
            src="https://images.plattstatic.com/ProductImages/thumbnail/Nonstock.gif" />
        </div>
        <div class="mr-70">
          <div>Name:</div>
          <a
            :href="`/products/edit/${product.napmID}`"
            target="_blank">
            <span class="text--brand">
              <strong>{{ product.catNum }}</strong>
            </span>
          </a>
        </div>
        <div class="mr-70">
          <div>NAPM #:</div>
          {{ product.napmID }}
        </div>
        <div class="mr-70">
          <div>UPC:</div>
          {{ product.upc || 'N/A' }}
        </div>
        <div class="mr-70">
          <div>Manufacturer:</div>
          {{ product.manufacturer }}
        </div>
      </div>
      <div class="form-row align-items-center mt-3">
        <div class="col-md-4 product-sortby">
          <multi-select
            v-model="statusSelect"
            :searchable="false"
            :options="statusOptions"
            label="name"
            select-label=""
            deselect-label="">
          </multi-select>
        </div>
        <div class="col-md-4">
          <div class="input-group">
            <span class="input-group-addon">
              Sort By:
            </span>
            <multi-select
              v-model="sortSelect"
              :searchable="false"
              :options="sortOptions"
              label="name"
              select-label=""
              deselect-label="">
            </multi-select>
          </div>
        </div>
      </div>
      <div class="mt-3 mb-2">
        <h5 class="review-header mb-0">
          Reviews
        </h5>
        <template v-if="filteredReviews.length > 0">
          <div
            v-for="(review, index) in filteredReviews"
            :key="index"
            class="p-3 border-solid">
            <review
              :item="review"
              :show-product-details="false"></review>
            <hr />
            <div
              slot="button"
              class="modal-actions text-right">
              <button
                :disabled="review.status === 'D'"
                type="button"
                class="btn mr-2"
                @click="save('D', review)">
                Deny
              </button>
              <button
                :disabled="review.status === 'A'"
                type="button"
                class="btn btn-primary"
                @click="save('A', review)">
                Approve
              </button>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="p-3 text-center">
            No Reviews Available
          </div>
        </template>
      </div>
      <div
        v-if="!showSpinner"
        class="vendor-pagination">
        <pagination
          :pagination="pagination"
          :callback="searchPagintation"
          :options="paginationOptions"></pagination>
      </div>
    </div>
    <sweet-modal
      ref="statusModal"
      :icon="modal.icon"
      :title="modal.title">
      <p class="text-center">
        {{ modal.message }}
      </p>
    </sweet-modal>
  </div>
</template>
<script type="text/javascript">
import axios from 'axios'
import PageTitle from 'components/pageTitle.vue'
import MultiSelect from 'components/inputs/multiselect.vue'
import Review from '../components/Review.vue'
import Pagination from 'components/pagination.vue'
import SweetModal from 'components/modals/sweet.vue'

import moment from 'moment'

export default {
  name: 'Reviews',
  components: {
    PageTitle,
    MultiSelect,
    Review,
    Pagination,
    SweetModal
  },
  data () {
    return {
      reviews: [],
      showSpinner: true,
      pagination: {
        total: 0,
        per_page: 10, // required
        current_page: 1, // required
        last_page: 0, // required
        from: 0,
        to: 15 // required
      },
      paginationOptions: {
        offset: 5,
        previousText: 'Prev',
        nextText: 'Next',
        alwaysShowPrevNext: true,
        pageSizes: [10, 50, 100, 150, 250, 500]
      },
      currentViewing: 1,
      product: {},
      statusFilters: ['Show All', 'Pending', 'Approved', 'Denied'],
      statusSelect: { name: 'Show All', value: 'Show All' },
      statusOptions: [
        { name: 'Show All', value: 'Show All' },
        { name: 'Pending', value: 'N' },
        { name: 'Approved', value: 'A' },
        { name: 'Denied', value: 'D' }
      ],
      sortSelect: { name: 'Ascending Date', value: 'asc' },
      sortOptions: [
        { name: 'Ascending Date', value: 'asc' },
        { name: 'Descending Date', value: 'dsc' },
        { name: 'Highest Rating - Lowest', value: 'high' },
        { name: 'Lowest Rating - Highest', value: 'low' }
      ],
      userId: this.$store.getters.user.userId,
      modal: {
        message: '',
        icon: 'success'
      }
    }
  },
  computed: {
    filteredReviews () {
      this.currentViewing = (this.pagination.current_page - 1) * this.pagination.per_page + 1

      const paginatedReviews = this.filterReviews().filter((ven, index) => {
        return index >= ((this.pagination.current_page - 1) * this.pagination.per_page) && index <= ((this.pagination.current_page - 1) * this.pagination.per_page + this.pagination.per_page - 1)
      })
      this.pagination.total = paginatedReviews.length
      this.pagination.last_page = Math.ceil(paginatedReviews.length / this.pagination.per_page)

      return paginatedReviews
    }
  },
  watch: {
  },
  created () {
    this.getAllReviews(this.$route.params.id)
    this.product = this.$route.params.product
  },
  methods: {
    getAllReviews (id) {
      axios.get(`/api/product-reviews/GetProductReviewsList/${id}`).then(res => {
        const response = res.data.map((review, b) => {
          review.createDateFormatted = moment(review.createDate, 'YYYYMMDDHH:mmA').format('MM/DD/YYYY HH:mm A')
          return review
        })

        this.pagination.total = response.length
        this.pagination.last_page = Math.ceil(response.length / this.pagination.per_page)

        this.reviews = response
        this.$nextTick(() => {
          this.showSpinner = false
        })
      }).catch(err => {
        console.log(err)
      })
    },
    sortBy (reviews, sort) {
      if (sort === 'asc' || sort === 'dsc') {
        return this.reviews.sort((a, b) => {
          return sort === 'asc' ? moment.utc(a.createDate).diff(moment.utc(b.createDate)) : moment.utc(b.createDate).diff(moment.utc(a.createDate))
        })
      }
      if (sort === 'high' || sort === 'low') {
        return this.reviews.sort((a, b) => {
          return sort === 'high' ? b.rating - a.rating : a.rating - b.rating
        })
      }
    },
    searchPagintation (size) {
      if (size) {
        this.pagination.last_page = Math.ceil(this.pagination.total / this.pagination.per_page)
      }
    },
    filterReviews () {
      if (this.statusSelect.value === 'Show All') return this.sortBy(this.reviews, this.sortSelect.value)

      return this.sortBy(this.reviews, this.sortSelect.value).filter(r => {
        return r.status === this.statusSelect.value
      })
    },
    save (status, review) {
      const { reviewID } = review
      axios.post(`/api/product-reviews/UpdateProductReviewStatus/${reviewID}?status=${status}&userId=${this.userId}`).then(res => {
        if (res.status === 200) {
          this.modal.icon = 'success'
          this.modal.message = 'Updated Review Successfully.'
          review.status = status
          this.$refs.statusModal.open()
        } else {
          this.modal.icon = 'error'
          this.modal.message = 'Error Updating Review'
        }
        this.$nextTick(() => {
          this.showSpinner = false
        })
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>
<style lang="scss" scoped>

.review-header {
  background-color: #ddd;
  padding: 10px;
}

.border-solid {
  border: solid 1px #ddd;
  padding: 10px;
}

.text-14 {
  font-size: 14px;
}

.mr-70 {
  margin-right: 80px;
}

</style>
