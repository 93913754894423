<template>
  <div>
    <page-title title="Attributes"></page-title>
    <div
      class="spinner"
      :class="{'show-spinner': showSpinner}"></div>
    <div class="attr-header search-section">
      <div class="attr-search">
        <input
          v-model="search"
          type="text"
          class="form-control" />
        <template>
          <i
            v-show="isEmpty"
            class="material-icons">
            search
          </i>
          <i
            v-show="isDirty"
            class="material-icons times"
            @click="search = ''">
            close
          </i>
        </template>
      </div>
      <div class="add-new-btn">
        <button
          type="button"
          class="btn btn-primary">
          Add New
        </button>
      </div>
      <div class="attr-table-pager">
        <div class="table-viewing-info">
          <label>Viewing {{ currentViewing | numeric }} -
            <span v-if="parseInt(currentViewing) + parseInt(pagination.per_page) - 1 < pagination.total">
              {{ parseInt(currentViewing) + parseInt(pagination.per_page) - 1 | numeric }}
            </span>
            <span v-else>
              {{ pagination.total | numeric }}
            </span> of {{ pagination.total | numeric }}
          </label>
        </div>
        <div class="input-group">
          <span class="input-group-addon">
            Showing:
          </span>
          <select
            v-model="pagination.per_page"
            class="form-control">
            <option
              v-for="o in paginationOptions.pageSizes"
              :key="o"
              :value="o">
              {{ o }}
            </option>
          </select>
        </div>
        <div class="col-md-2">
          <button
            type="button"
            class="btn btn-primary"
            @click="exportAttributes">
            Export Attributes
          </button>
        </div>
      </div>
    </div>
    <div class="attr-main">
      <table class="table table-hover">
        <thead>
          <tr>
            <th></th>
            <th>
              <a @click="sortAttributes('attributeType', sortAsc)">Name
                <i
                  v-if="sortCol == 'attributeType' && sortAsc == true"
                  class="material-icons">
                  expand_more
                </i>
                <i
                  v-else-if="sortCol == 'attributeType' && sortAsc == false"
                  class="material-icons">
                  expand_less
                </i>
                <i
                  v-else
                  class="material-icons no-sort">
                  code
                </i>
              </a>
            </th>
            <th>Description</th>
            <th>
              <a @click="sortAttributes('attributeTypeId', sortAsc)">Id
                <i
                  v-if="sortCol == 'attributeTypeId' && sortAsc == true"
                  class="material-icons">
                  expand_more
                </i>
                <i
                  v-else-if="sortCol == 'attributeTypeId' && sortAsc == false"
                  class="material-icons">
                  expand_less
                </i>
                <i
                  v-else
                  class="material-icons no-sort">
                  code
                </i>
              </a>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <template v-if="attributesList">
            <router-link
              v-for="attr in attributesList"
              :key="'attr-type-' + attr.attributeTypeId"
              tag="tr"
              :to="{ name: 'attributeDetails', params: { id: attr.attributeTypeId }}">
              <td class="img-col">
                <div class="img-fluid">
                  <img
                    v-if="attr.folderName"
                    :src="'https://rexel-cdn.com/products/dcd460t2.jpg?i=' + attr.folderName + '&f=150'" />
                  <img
                    v-else
                    src="https://sf.rexel-cdn.com/products/no-photo.jpg" />
                </div>
              </td>
              <td
                class="attr-col"
                v-html="formatResult(attr.attributeType, search)">
              </td>
              <td v-html="formatResult(attr.description, search)"></td>
              <td
                class="id-col"
                v-html="formatResult(attr.attributeTypeId, search)">
              </td>
              <td class="arrow-col">
                <span class="attr-arrow">
                  <i class="material-icons">
                    keyboard_arrow_right
                  </i>
                </span>
              </td>
            </router-link>
          </template>
          <tr v-else>
            <td colspan="5">
              <div class="alert alert-primary">
                No Attributes found
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="!showSpinner && attributesList.length"
      class="attr-pagination">
      <pagination
        :pagination="pagination"
        :callback="searchPagintation"
        :options="paginationOptions"></pagination>
    </div>
  </div>
</template>
<script type="text/javascript">
import axios from 'axios'
import TitleComponent from 'components/pageTitle'
import PaginationComponent from 'components/pagination.vue'
import downloadFileFromApi from '@/utils/downloadFileService'

export default {
  name: 'AttributesList',
  components: {
    'page-title': TitleComponent,
    pagination: PaginationComponent
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.showSpinner = true
      vm.getAttributeTypes()
    })
  },
  data () {
    return {
      showSpinner: true,
      attributeTypes: [],
      sortCol: '',
      sortAsc: false,
      search: '',
      pagination: {
        total: 0,
        per_page: 50, // required
        current_page: 1, // required
        last_page: 0, // required
        from: 0,
        to: 15 // required
      },
      paginationOptions: {
        offset: 5,
        previousText: 'Prev',
        nextText: 'Next',
        alwaysShowPrevNext: true,
        pageSizes: [25, 50, 100, 150, 250, 500]
      },
      currentViewing: 1
    }
  },
  computed: {
    language () {
      return this.$store.getters.languageId
    },
    isEmpty () {
      return !this.search
    },
    isDirty () {
      return !!this.search
    },
    attributesFiltered () {
      const search = this.search.toLowerCase()
      const list = this.attributeTypes.filter((attr, index) => {
        if (attr.description === null) {
          attr.description = ''
        }
        return attr.attributeType.toLowerCase().indexOf(search) !== -1 || attr.description.toLowerCase().indexOf(search) !== -1 || attr.attributeTypeId.toString().indexOf(search) !== -1
      })
      this.currentViewing = 1
      this.pagination.current_page = 1
      this.pagination.total = list.length
      this.searchPagintation(true)
      return list
    },
    attributesList () {
      this.currentViewing = (this.pagination.current_page - 1) * this.pagination.per_page + 1

      const list = this.attributesFiltered.filter((ven, index) => {
        return index >= ((this.pagination.current_page - 1) * this.pagination.per_page) && index <= ((this.pagination.current_page - 1) * this.pagination.per_page + this.pagination.per_page - 1)
      })
      return list
    }
  },
  methods: {
    getAttributeTypes () {
      const data = {
        params: {
          languageId: this.language
        }
      }
      axios.get('/api/attributes/GetAttributeTypes', data).then(res => {
        this.attributeTypes = res.data
        this.showSpinner = false
      })
    },
    searchPagintation (size) {
      if (size) {
        this.pagination.last_page = Math.ceil(this.pagination.total / this.pagination.per_page)
      }
    },
    formatResult (suggestion, search) {
      function escapeRegExChars (value) {
        return value.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
      }

      // Do not replace anything if the current value is empty
      if (!search || search === '') {
        return suggestion
      }

      const pattern = '(' + escapeRegExChars(search) + ')'

      return suggestion.toString().toLowerCase()
        .replace(new RegExp(pattern, 'gi'), '<strong>$1</strong>')
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/&lt;(\/?strong)&gt;/g, '<$1>')
    },
    sortAttributes (col, ascending) {
      this.pagination.current_page = 1
      ascending = !ascending
      this.sortCol = col
      this.sortAsc = ascending
      if (ascending) {
        this.attributeTypes.sort((a, b) => {
          if (typeof a[col] === 'string') {
            return a[col].localeCompare(b[col])
          } else {
            return a[col] - b[col]
          }
        })
      } else {
        this.attributeTypes.sort((a, b) => {
          if (typeof b[col] === 'string') {
            return b[col].localeCompare(a[col])
          } else {
            return b[col] - a[col]
          }
        })
      }
    },
    exportAttributes () {
      downloadFileFromApi('/api/attributes/getattributesreport')
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../assets/scss/extra/mixins/flexbox.scss";
@import "../../assets/scss/bootstrap-custom-variables.scss";

.search-section {
  margin-bottom: 1.5rem;
  @include flexbox;
  .attr-search {

    position: relative;
    @include flex(0, 1, 40%);
    .form-control {

      &:focus~.material-icons.times {
        color: $brand-danger;
        cursor: pointer;
      }
    }
    .material-icons {
      color: darken($gray-lighter, 25%);
      position: absolute;
      font-size: 1.125rem;
      top: .125rem;
      right: .125rem;
      padding: .5rem;
    }
  }
  .add-new-btn {
    padding: 0 1rem;
    display: none;
    .btn {
      padding: .5rem 2rem;
    }
  }
  .attr-table-pager {
    @include flex(1, 1, 30%);
    @include flexbox;
    @include justify-content(flex-end);
    .table-viewing-info {
      width: 10rem;
      text-align: right;
      color: #222;
      font-size: .875rem;
      @include align-self(center);
      @include flex(1, 1, 10rem);

      >label {
        background: #eee;
        border: 1px solid rgba(0, 0, 0, .15);
        padding: .5rem 1.5rem;
        margin-bottom: 0;
      }
    }
    .input-group {
      width: 12rem;
      border: 0;

      .input-group-addon {
        font-size: .875rem;
        border: 0;
        background: #fff;
      }
      .form-control {
        font-size: .875rem;
        background: #eee;
      }
    }
  }
}

.attr-main {
  .table {
    border: 1px solid #eceeef;
    thead {
      tr {
        th {
          text-transform: uppercase;
          .material-icons {
            font-size: 1rem;
            margin-left: .5rem;
            &:not(.no-sort) {
              color: $brand-info;
            }
          }
          .no-sort {
            -ms-transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
          }
        }
      }
    }
    tbody {
      tr {
        &:hover {
          .arrow-col .attr-arrow {
            background: darken($brand-primary, 10%);
          }
        }
        td {
          vertical-align: middle;
          cursor: pointer;
        }
      }
    }
    .attr-col {
      width: 10rem;
      font-weight: 500;
    }
    .id-col {
      width: 4rem;
    }
    .arrow-col {
      width: 4rem;
      text-align: center;
      .attr-arrow {
        display: block;
        position: relative;
        color: #fff;
        background: $brand-primary;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        &:hover {
          background: darken($brand-primary, 10%);
        }
        .material-icons {
          position: absolute;
          font-size: 1.5rem;

          top: 50%;
          right: 50%;
          margin: -.75rem -.825rem 0 0;
        }
      }
    }
    .img-col {
      width: 4.5rem;
      border-right: 1px solid #eceeef;
      .img-fluid {
        @include flexbox;
        @include justify-content(center);
        height: 3.75rem;
        img {
          @include align-self(center);
          max-height: 3.75rem;
          max-width: 3.75rem;
        }
      }
    }
    .id-col,
    .active-col {
      width: 6rem;
    }
  }
}

.material-icons {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
