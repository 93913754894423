<template>
  <div
    class="dashboard"
    :class="{ 'loading': $store.getters.showSpinner }">
    <!-- MAIN TITLE -->
    <div class="d-flex">
      <page-title
        :hide="true"
        title="Dashboard"
        class="dashboard-title"></page-title>
      <div class="last-updated">
        <span class="badge badge-pill badge-secondary">
          Last updated on: {{ lastUpdated | dateTime }}
        </span>
        <a
          class="btn btn-sm btn-link"
          @click="getPageData(true)">Click to Refresh</a>
      </div>
    </div>

    <!-- FILTER BY COMPANY -->
    <div class="d-flex mb-4 align-items-center">
      <h6 class="d-inline-block m-0">
        <strong>Filter By Company: </strong>
      </h6>
      <div class="company-filter ml-2">
        <select-company
          v-model="selectedBanner"
          :set-users-default="true"></select-company>
      </div>
    </div>

    <div v-if="showDashboard">
      <!-- DASHBOARD TILES INFO -->
      <section class="dashboard-tiles">
        <tile
          v-for="(d, i) in productsWithStats"
          :key="'stat-' + i"
          :obj="d"
          :value="d.value"
          :bg="d.bgColor"
          :title="d.label"
          @tile-click="(productWithStat) => productStatClick(productWithStat, false)"></tile>
      </section>

      <!-- PRODUCT ENRICHMENT LEVELS GRAPH -->
      <section class="non-enriched-products">
        <div class="sub-title mt-3 mb-3">
          <h3>
            Product Enrichment Tiers
            <div class="banners-sub-title">
              (Total {{ selectedBannerDisplayName }} products {{ totalProducts | numeric }})
            </div>
          </h3>
        </div>

        <div class="row chart-row">
          <div class="col-md col-sm-12 d-flex chart-wrap section-wrap">
            <doughnut-chart
              v-if="chartDataByTier"
              class="chart-wrap-doughnut"
              :chart-data="formatChartData(chartDataByTier[selectedTier])"
              @click="onChartClick">
              <div slot="header">
                <div class="form-row">
                  <div class="form-group col-md-7">
                    <select
                      id="exampleFormControlSelect1"
                      v-model="selectedTier"
                      class="form-control">
                      <option
                        v-for="tier in tiers"
                        :key="tier.value"
                        :value="tier.value">
                        {{ tier.text }}
                      </option>
                    </select>
                  </div>
                  <h5 class="col-md-5 mt-2">
                    <strong>Total: {{ chartDataByTier[selectedTier].total | numeric }}</strong>
                  </h5>
                </div>
              </div>
            </doughnut-chart>
          </div>
          <!-- ADD NEW COMPANYWIDE TOTAL TABLE HERE  -->
          <div class="col-md col-sm-12 section-wrap">
            <h5 class="mt-2">
              <strong>Total Companywide: {{ companywideTotal | numeric }}</strong>
            </h5>
            <div class="stats-table enrichment-table non-enriched-display mt-4">
              <div class="headers">
                <div class="header fields-cell">
                  Enrichment Level
                </div>
                <div class="header non-enriched-cell">
                  # Products
                </div>
              </div>
              <div class="display-body">
                <div
                  v-for="(stat, i) in enrichedCountByEnrichmentLevel"
                  :key="`enrichment-level-${i}`"
                  class="body-row clickable">
                  <div class="body-cell fields-cell font-weight-bold">
                    Level {{ stat.enrichmentLevel }}
                  </div>
                  <div class="body-cell non-enriched-cell font-weight-bold">
                    {{ stat.productCount | numeric }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="stats-section">
        <!-- TOP 50 NON ENRICHED PRODUCTS -->
        <div class="row">
          <div class="col">
            <div class="sub-title">
              <h3>
                Top 50 Non-enriched Products by Top Viewed
                <div class="banners-sub-title">
                  ({{ selectedBannerDisplayName }})
                </div>
              </h3>
              <a @click="viewAllProducts(topSold)">
                View All
              </a>
            </div>
            <top-products-table :data="viewedTop50Data"></top-products-table>
          </div>

          <!-- PRODUCT FIELDS NON ENRICHED -->
          <div class="col">
            <div class="sub-title">
              <h3>
                Non-Enriched Product Fields
                <div class="banners-sub-title">
                  ({{ selectedBannerDisplayName }})
                </div>
              </h3>
            </div>
            <div class="stats-table non-enriched-display mt-4">
              <div class="headers">
                <div class="header fields-cell">
                  Product Fields
                </div>
                <div class="header non-enriched-cell">
                  Non-Enriched
                </div>
              </div>
              <div class="display-body">
                <div
                  v-for="(stat, i) in nonEnrichedStats"
                  :key="`non-enriched-${i}`"
                  class="body-row clickable"
                  @click="productStatClick(stat, false)">
                  <div class="body-cell fields-cell font-weight-bold">
                    {{ stat.label }}
                  </div>
                  <div class="body-cell non-enriched-cell font-weight-bold">
                    {{ stat.value | numeric }}
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4 text-right total-products">
              <h6 class="font-weight-bold">
                TOTAL PRODUCTS: {{ totalProducts | numeric }}
              </h6>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div v-else>
      <h3>No Information Available</h3>
    </div>
  </div>
</template>
<script type="text/javascript">
import { numeric } from '@/globals/formatters'
import axios from 'axios'
import Tile from './components/tile'
import DoughnutChart from '@/components/DoughnutChart'
import TopProductsTable from './components/TopProductsTable'
import PageTitle from 'components/pageTitle'
import SelectCompany from 'components/inputs/SelectCompany'
import { mapState } from 'vuex'

const colorPalette = ['#E09400', '#13BBB6', '#00416A', '#01B457', '#006330']

export default {
  name: 'DashboardPage',
  components: {
    Tile,
    DoughnutChart,
    PageTitle,
    SelectCompany,
    TopProductsTable
  },
  beforeRouteLeave (to, from, next) {
    next(vm => vm.$store.dispatch('setSpinner', false))
  },
  data () {
    return {
      lastUpdated: Date.now(),
      banners: [],
      selectedBanner: {
        id: null,
        label: ''
      },
      selectedTier: 1,
      productsWithStats: [],
      nonEnrichedStats: [],
      enrichedCountByEnrichmentLevel: [],
      companyWideNonEnrichedStats: [],
      chartDataByTier: null,
      chartData: {
        label: [],
        datasets: []
      },
      tiers: [
        { value: 1, text: 'Tier 1' },
        { value: 2, text: 'Tier 2' },
        { value: 3, text: 'Tier 3' }
      ],
      tierTotals: {},
      totalTier: 0,
      totalProducts: 0,
      topSold: false,
      soldTop50Data: [],
      viewedTop50Data: [],
      showDashboard: true
    }
  },
  computed: {
    ...mapState([
      'accessToken'
    ]),
    language () {
      return this.$store.getters.languageId
    },
    selectedBannerDisplayName () {
      if (!this.selectedBanner.label) return ''
      return this.selectedBanner.id === 0 ? 'All Banners' : this.selectedBanner.label
    },
    companywideTotal () {
      const initialSum = 0
      return this.enrichedCountByEnrichmentLevel.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.productCount
      }, initialSum)
    }
  },
  watch: {
    selectedBanner: {
      immediate: true,
      handler (val) {
        if (val.id !== 0) this.getPageData(false)
      }
    }
  },
  methods: {
    formatChartData (chartData) {
      if (!chartData) return null
      const { data } = chartData
      const labels = data.map(item =>
        `Level ${item.enrichmentLevel}: <strong>${numeric(item.productCount)}</strong>`
      )
      return {
        labels,
        datasets: [
          {
            data: data.map(item => item.productCount),
            backgroundColor: colorPalette
          }
        ]
      }
    },
    fetchDashboardStats (refresh) {
      return axios.get(`/api/dashboard?refresh=${refresh}&bannerId=${this.selectedBanner.id}`)
    },
    fetchTopNonEnriched (type) {
      const data = {
        params: {
          statType: type,
          bannerId: this.selectedBanner.id,
          languageId: this.language
        }
      }
      return axios.get('/api/products/top-non-enriched', data)
    },
    async getPageData (refresh) {
      this.$store.dispatch('setSpinner', true)

      const [dashboardStats, topSold, topViewed] = await Promise.all([
        this.fetchDashboardStats(refresh),
        this.fetchTopNonEnriched('sold'),
        this.fetchTopNonEnriched('viewed')
      ])

      if (!dashboardStats) {
        console.error('problem getting dashboardStats')
        return
      }

      const { stats, enrichedCountBySalesTiers, lastUpdated } = dashboardStats.data

      if (stats != null) {
        this.showDashboard = true
        // set chart data and non-enriched data
        this.lastUpdated = lastUpdated
        this.setProductWithStats(stats)
        this.setNonEnrichedStats(stats)
        this.setChartData(enrichedCountBySalesTiers)
        this.setCompanywideNonEnrichedStats(enrichedCountBySalesTiers)

        // top sold/viewed
        this.soldTop50Data = topSold.data
        this.viewedTop50Data = topViewed.data

        this.selectedTier = 1
      } else {
        this.showDashboard = false
      }

      // done
      this.$store.dispatch('setSpinner', false)
    },
    setCompanywideNonEnrichedStats (stats) {
      const result = stats.reduce((array, { enrichmentLevel, productCount }) => {
        const index = array.findIndex((arrItem) => {
          return arrItem.enrichmentLevel === enrichmentLevel
        })

        if (index > -1) {
          array[index].productCount += productCount
        } else {
          array.push({ enrichmentLevel, productCount })
        }
        return array
      }, [])

      this.enrichedCountByEnrichmentLevel = result
    },
    setProductWithStats (stats) {
      this.productsWithStats = [
        this.getProductStat('Products w/o Attributes', stats.noAttribute, 'hasAttribute', '#006330'),
        this.getProductStat('Products w/o Categories', stats.noCategory, 'hasCategory', '#01B457'),
        this.getProductStat('Products w/o Short Descriptions', stats.noDescription, 'hasShortDescription', '#00416A'),
        this.getProductStat('Products w/o Documents', stats.noDocument, 'hasDocument', '#13BBB6'),
        this.getProductStat('Products w/o Images', stats.noImage, 'hasImage', '#E09400')
      ]
    },
    setNonEnrichedStats (stats) {
      this.totalProducts = stats.totalProducts
      this.nonEnrichedStats = [
        this.getProductStat('Attributes', stats.noAttribute, 'hasAttribute', '#006330'),
        this.getProductStat('Categories', stats.noCategory, 'hasCategory', '#01B457'),
        this.getProductStat('Descriptions', stats.noDescription, 'hasShortDescription', '#00416A'),
        this.getProductStat('Documents', stats.noDocument, 'hasDocument', '#13BBB6'),
        this.getProductStat('Images', stats.noImage, 'hasImage', '#E09400')
      ]
    },
    getProductStat (name, stat, paramName, bgColor) {
      return {
        label: name,
        paramName,
        value: stat,
        bgColor
      }
    },
    setChartData (enrichedTiers) {
      const reducer = (accumulator, currentValue) => {
        const tier = accumulator[currentValue.salesTier]
        return {
          ...accumulator,
          [currentValue.salesTier]: {
            total: tier.total + currentValue.productCount,
            data: [
              ...tier.data,
              currentValue
            ]
          }
        }
      }
      this.chartDataByTier = enrichedTiers.reduce(reducer, {
        1: { total: 0, data: [] },
        2: { total: 0, data: [] },
        3: { total: 0, data: [] }
      })
    },
    productStatClick (stat, enriched) {
      const params = {}
      if (this.selectedBanner.id !== 0) params.banners = this.selectedBanner.label
      params[stat.paramName] = enriched
      this.pushProductSearchParams(params)
    },
    viewAllProducts (sold) {
      const data = sold ? this.soldTop50Data : this.viewedTop50Data
      const napmIds = data.map(prod => prod.id)
      this.pushProductSearchParams({ ids: napmIds })
    },
    pushProductSearchParams (params) {
      const paramsToPreventRedirect = { from: 0, size: 50, languageId: this.$store.getters.languageId || 1, ...params } // these addtional params are to prevent fetchData() from redirecting since it will "redirect" to the same params
      this.$router.push({ name: 'productSearch', query: paramsToPreventRedirect })
    },
    onChartClick ({ click }) {
      const enrichmentLevel = click[0] && (click[0]._index + 1)
      this.$router.push({
        name: 'productSearch',
        query: {
          enrichmentLevel,
          salesTier: this.selectedTier,
          banners: [this.selectedBanner.label]
        }
      })
    }
  }
}
</script>
<style lang="scss">
@import "../../assets/scss/bootstrap-custom-variables";
@import "../../assets/scss/extra/mixins/flexbox";
@import "../../assets/scss/extra/mixins/scrollbar.scss";

.dashboard {
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  .multiselect {
    cursor: pointer;
  }

  .clickable {
    cursor: pointer;
  }

  .multiselect__input {
    cursor: pointer;
    line-height: 1.5rem;
  }

  &.loading {
    opacity: 0;
  }

  .dashboard-title {
    @include flex(0, 0, auto);
  }

  .company-filter {
    @include flex(1, 1, 1);
    @include align-self(center);
    width: 250px;
    min-width: 250px;

    .multiselect {
      display: inline-block;
      width: 90%;
    }

    .material-icons {
      font-size: 1.25rem;
      color: #999;
    }
  }

  .last-updated {
    @include align-self(center);
    margin-left: auto;

    > .btn {
      text-transform: uppercase;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .sub-title {
    @include flexbox;
    @include justify-content(space-between);
    margin: 2rem 0 0;

    h3 {
      @include align-self(center);
      margin-bottom: 0;
      font-size: 1.175rem;

      .material-icons {
        cursor: pointer;
        color: #999;
        font-size: 1.25rem;
        margin-left: 0.125rem;
      }
    }

    a {
      @include align-self(center);
      text-transform: uppercase;
      font-weight: bold;
      color: $gray-700;
      padding-right: 0.5rem;
      &:hover {
        cursor: pointer;
        text-decoration: underline !important;
      }
    }
  }

  .banners-sub-title {
    color: #999;
    font-size: 90%;
    font-weight: normal;
    font-style: oblique;
    display: inline-block;
  }

  .dashboard-tiles {
    @include flexbox;
    @include flex-wrap(wrap);
    @include justify-content(flex-start);
    margin-left: -5px;
    margin-right: -5px;
  }

  .non-enriched-products {
    .chart-row {
      margin-left: -5px;
      margin-right: -5px;
    }

    .section-wrap {
      border: 1px solid black;
      margin: 0 20px 20px 0;
      padding: 16px;
    }
    .section-wrap + .section-wrap {
      margin-right: 0;
    }

    .chart-wrap {
      @include flexbox;
      @include flex-wrap(nowrap);

      width: calc(40% - 10px);

      @media (max-width: 890px) {
        width: calc(50% - 10px);
      }

      @media (max-width: 540px) {
        width: calc(100% - 10px);
      }

      #doughnut-chart {
        max-width: 300px;
        max-height: 300px;
        width: 100% !important;
        height: auto !important;
      }

      > div {
        width: 100%;
      }

      .chart-stats {
        max-width: 20%;
        width: 100%;

        background: #eee;
        margin-top: 0.375rem;
        margin-bottom: 1.6rem;
        text-align: center;
        @include flexbox;
        @include justify-content(space-between);
        @include align-items(center);
        @include flex-direction(column);

        h5 {
          font-size: 1.15rem;
          font-weight: 400;
          margin: 0;
        }
      }
    }

    .enrichment-table {
      .non-enriched-cell {
        white-space: nowrap;
        width: auto;
      }
    }

    .tier-color-item {
      display: inline-block;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin-right: 10px;
    }

    .chart-number {
      font-weight: bold;
    }

    .enrichment-guide-link {
      font-size: 12px;
      color: inherit;
    }
  }

  .stats-nav {
    @include flexbox;
    @include justify-content(space-between);

    .btn.btn-rounded {
      min-width: inherit;
      padding: 10px 20px;
    }

    a {
      @include align-self(center);
      text-transform: uppercase;
      font-weight: bold;
      color: $gray-700;
      line-height: 2;
      padding-right: 0.5rem;
      &:hover {
        cursor: pointer;
        text-decoration: underline !important;
      }
    }
  }

  .stats-table {
    width: 100%;

    .fields-cell {
      width: 75%;
    }

    .non-enriched-cell {
      width: 25%;
    }

    $horizontal-space: 0.5rem;

    .headers {
      background-color: #eee;
      @include flexbox;
      @include flex-wrap(nowrap);
      @include align-items(center);

      .header {
        padding: 0.75rem $horizontal-space;
        color: #999;
        font-weight: bold;
        font-size: 0.75rem;
        text-transform: uppercase;
        border-top: 1px solid #eceeef;
        border-bottom: 2px solid #eceeef;
      }
    }

    .display-body {
      .body-row {
        border-bottom: 1px solid #eceeef;
        @include flexbox;
        @include flex-wrap(nowrap);
        @include align-items(center);

        &:hover {
          background-color: darken(#fff, 15%);

          .body-cell {
            color: darken(#999, 65%);
          }
        }
      }

      .body-cell {
        padding: 0.75rem $horizontal-space;
        position: relative;
        color: #999;
        border: none;
        font-size: 0.75rem;
      }
    }
  }

  .total-products {
    margin-right: 50px;
  }
}
</style>
