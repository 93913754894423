<template>
  <div>
    <div class="jumbotron">
      <div class="img-wrap">
        <img
          class="img-fluid"
          :src="imgSrc" />
      </div>
    </div>
    <div class="form-group">
      <label
        for="file1"
        class="col-form-label form-control-label">
        Upload Image
      </label>
      <div
        class="custom-file"
        style="display: block;">
        <input
          class="custom-file-input form-control"
          type="file"
          :accept="validMediaFileTypes.images"
          @change="imageValidate($event)" />
        <span class="custom-file-control custom-file-btn"></span>
      </div>
    </div>
    <modal
      ref="uploadMfrImage"
      class="upload-mfr-image"
      :title="modal.title"
      :icon="modal.icon"
      :animate-icon="modal.animate"
      :blocking="true"
      :large="true"
      :no-padding="true"
      @close="resetState()">
      <template v-if="imageUpload">
        <uploading :loading="loading"></uploading>
        <div :class="{'uploading-media': loading}">
          <cropper
            ref="imgCropper"
            :enable-exif="true"
            :show-zoomer="false"
            :show-buttons="true"
            :zoom-level="imgZoom"
            :mouse-wheel-zoom="true"
            :enforce-boundary="true"
            :enable-orientation="true"
            :boundary="{height: 450, width: 768}"
            :viewport="{ width: 400, height: 400, type: 'square' }"
            @update="imageCropUpdate($event)"
            @rotate="rotated = $event">
          </cropper>
        </div>
      </template>
      <template v-else>
        <upload-zone
          :document="false"
          :error="modal.error"
          :message="modal.message"
          :message-type="modal.messageType"
          @upload="imageValidate($event)"></upload-zone>
      </template>
      <div
        slot="button"
        class="modal-actions">
        <button
          type="button"
          class="btn btn-link"
          :disabled="loading"
          @click="resetState()">
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          :disabled="loading || !imageUpload"
          @click="imageSave($event)">
          Upload Image
        </button>
      </div>
    </modal>
  </div>
</template>
<script type="text/javascript">
import ModalComponent from 'components/modals/sweet.vue'
import MediaFileTypesMixin from '@/views/media/types/MediaFileTypes.mixin'
import Uploading from '@/views/media/upload/uploading'
import UploadZone from '@/views/media/upload/uploadZone.vue'
import Cropper from 'components/crop'

export default {
  name: 'EditAttributeType',
  components: {
    modal: ModalComponent,
    UploadZone,
    Uploading,
    Cropper
  },
  mixins: [MediaFileTypesMixin],
  props: {
    attrImageFile: {
      type: String,
      default: null
    },
    isSaving: Boolean
  },
  data () {
    return {
      modal: {
        title: 'Upload Image',
        icon: null,
        animate: false,
        message: '',
        messageType: '',
        error: false
      },
      file: null,
      fileName: null,
      uploadModal: false,
      uploadMessage: false,
      editing: false,
      loading: false,
      image: null,
      rotated: 0,
      imgZoom: 1,
      imgPoints: {},
      imageUpload: false,
      imageReset: false,
      imagePreview: null,
      saveResult: false
    }
  },
  computed: {
    imgSrc () {
      if (this.imagePreview) {
        return `${this.imagePreview}`
      }
      if (this.attrImageFile) {
        return `https://rexel-cdn.com/products/dcd460t2.jpg?i=${this.attrImageFile}&f=150`
      }
      return 'https://sf.rexel-cdn.com/products/no-photo.jpg'
    }
  },
  watch: {
    isSaving: {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal) {
          this.imagePreview = null
        }
      }
    },
    uploadModal (value) {
      if (value) {
        this.$refs.uploadMfrImage.open()
        this.editing = false
      } else {
        this.editing = true
        this.$refs.uploadMfrImage.close()
        this.resetState()
      }
    }
  },
  methods: {
    imageValidate (event) {
      if (event.target.files.length > 0) {
        this.file = event.target.files[0]
        this.fileName = event.target.files[0].name
        if (this.file.type.indexOf('image/') > -1) {
          this.uploadModal = true
          this.imageInit(event)
        } else {
          // throw error
        }
      }
      event.target.value = ''
    },
    imageInit (event) {
      const image = new Image()
      const reader = new FileReader()
      reader.onload = (file) => {
        this.image = file.target.result
        image.src = file.target.result
      }
      reader.onloadend = (file) => {
        if (image.width >= 400 && image.height >= 400) {
          this.imageUpload = true
          this.$nextTick(() => {
            this.$refs.imgCropper.bind({
              url: this.image
            })
          })
        } else {
          this.imageUpload = false
          this.modal.error = true
          this.modal.messageType = 'ERROR'
          this.modal.message = 'Minimum size required is 400px by 400px.'
        }
      }
      reader.readAsDataURL(event ? this.file : false)
    },
    imageCropUpdate (event) {
      this.imgZoom = event.zoom
      this.imgPoints = event.points
    },
    imageSave (event) {
      event.preventDefault()
      this.$refs.imgCropper.result({ type: 'base64' }, img => {
        this.imagePreview = img
      })

      const formData = {
        file: this.file,
        points: this.imgPoints,
        fileName: this.fileName,
        rotated: this.rotated ? this.rotated * 90 : null
      }
      this.$emit('setUploadData', formData)
      this.$refs.uploadMfrImage.close()
    },
    resetState () {
      this.modal = {
        title: 'Upload Image',
        icon: null,
        animate: false,
        message: '',
        messageType: '',
        error: false
      }
      this.file = null
      this.imgPoints = {}
      this.fileName = null
      this.imageUpload = false
      this.uploadModal = false
      this.imgZoom = 1
    }
  }
}
</script>
<style lang="scss" scoped>

.jumbotron {
  padding: 2rem !important;
  .img-wrap {
    max-width: 150px;
    .img-fluid {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .3);
    }
  }
}

</style>
