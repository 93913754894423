<template id="multiselect-component">
  <div
    tabindex="0"
    :class="{ 'multiselect--active': isOpen, 'multiselect--disabled': disabled }"
    class="multiselect"
    @focus="activate()"
    @blur="searchable ? false : deactivate()"
    @keydown.self.down.prevent="pointerForward()"
    @keydown.self.up.prevent="pointerBackward()"
    @keydown.enter.stop.prevent.self="addPointerElement()"
    @keydown.tab.stop.prevent.self="addPointerElement()"
    @keyup.esc="deactivate()">
    <div
      class="multiselect__select"
      @mousedown.prevent="toggle()"></div>
    <div
      ref="tags"
      class="multiselect__tags">
      <span
        v-for="(option, index) of visibleValue"
        v-show="showTags"
        :key="`visible-tag-${index}`"
        mousedown="event.preventDefault()"
        :class="[tagClass]"
        class="multiselect__tag">
        <span v-text="getOptionLabel(option)"></span>
        <i
          aria-hidden="true"
          tabindex="1"
          class="multiselect__tag-icon"
          @keydown.enter.prevent="removeElement(option)"
          @click.prevent="removeElement(option)">
        </i>
      </span>
      <template v-if="internalValue && internalValue.length > limit">
        <strong v-text="limitText(internalValue.length - limit)"></strong>
      </template>
      <transition name="multiselect__loading">
        <div
          v-show="loading"
          class="multiselect__spinner"></div>
      </transition>
      <input
        v-if="searchable"
        ref="search"
        name="search"
        type="text"
        autocomplete="off"
        :placeholder="placeholder"
        :value="search"
        :disabled="disabled"
        class="multiselect__input"
        @input="updateSearch($event.target.value)"
        @focus.prevent="activate()"
        @blur.prevent="deactivate()"
        @keyup.esc="deactivate()"
        @keyup.down="pointerForward()"
        @keyup.up="pointerBackward()"
        @keydown.enter.stop.prevent.self="addPointerElement()"
        @keydown.tab.stop.prevent.self="addPointerElement()"
        @keydown.delete="removeLastElement()" />
      <span
        v-if="!searchable && !multiple"
        class="multiselect__single"
        v-text="currentOptionLabel || placeholder">
      </span>
    </div>
    <transition name="multiselect">
      <ul
        v-show="isOpen"
        ref="list"
        :style="{ maxHeight: maxHeight + 'px' }"
        class="multiselect__content"
        :class="{ 'content_fixed' : contentFixed }">
        <slot name="beforeList"></slot>
        <li v-if="multiple && max === internalValue.length">
          <span class="multiselect__option">
            <slot name="maxElements">
              Maximum of {{ max }} options selected. First remove a selected option to select another.
            </slot>
          </span>
        </li>
        <template v-if="!max || internalValue.length < max">
          <li
            v-for="(option, index) of filteredOptions"
            :key="index">
            <span
              tabindex="0"
              :class="optionHighlight(index, option)"
              :data-select="option.isTag ? tagPlaceholder : selectLabelText"
              :data-selected="selectedLabelText"
              :data-deselect="deselectLabelText"
              class="multiselect__option"
              @mousedown.prevent="select(option)"
              @mouseenter="pointerSet(index)">
              <multiselect-option
                :option-function="optionFunction"
                :label="getOptionLabel(option)"
                :option="option">
              </multiselect-option>
            </span>
          </li>
        </template>
        <li v-show="filteredOptions.length === 0 && search">
          <span class="multiselect__option">
            <slot name="noResult">
              No elements found. Consider changing the search query.
            </slot>
          </span>
        </li>
        <slot name="afterList"></slot>
      </ul>
    </transition>
  </div>
</template>
<script type="text/javascript">
import deepClone from '@/utils/deepClone'

export default {
  name: 'VueMultiselect',
  components: {
    multiselectOption: {
      functional: true,
      render (h, context) {
        return context.props.optionFunction(h, context.props.option, context.props.label)
      },
      props: {
        optionFunction: {
          type: Function,
          required: true
        },
        label: {
          required: true
        },
        option: {}
      }
    }
  },
  props: {
    tagClass: {
      type: String,
      required: false
    },
    showTags: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * Enable/disable highlighting of the pointed value.
     * @type {Boolean}
     * @default true
     */
    showPointer: {
      type: Boolean,
      default: true
    },
    /**
     * Decide whether to filter the results based on search query.
     * Useful for async filtering, where we search through more complex data.
     * @type {Boolean}
     */
    localSearch: {
      type: Boolean,
      default: true
    },
    /**
     * Array of available options: Objects, Strings or Integers.
     * If array of objects, visible label will default to option.label.
     * If `labal` prop is passed, label will equal option['label']
     * @type {Array}
     */
    options: {
      type: Array,
      required: true
    },
    /**
     * Equivalent to the `multiple` attribute on a `<select>` input.
     * @default false
     * @type {Boolean}
     */
    multiple: {
      type: Boolean,
      default: false
    },
    /**
     * Presets the selected options value.
     * @type {Object||Array||String||Integer}
     */
    value: {
      type: null,
      default: null
    },
    /**
     * Key to compare objects
     * @default 'id'
     * @type {String}
     */
    trackBy: {
      type: String
    },
    /**
     * Label to look for in option Object
     * @default 'label'
     * @type {String}
     */
    label: {
      type: String
    },
    /**
     * Enable/disable search in options
     * @default true
     * @type {Boolean}
     */
    searchable: {
      type: Boolean,
      default: true
    },
    /**
     * Clear the search input after select()
     * @default true
     * @type {Boolean}
     */
    clearOnSelect: {
      type: Boolean,
      default: true
    },
    /**
     * Hide already selected options
     * @default false
     * @type {Boolean}
     */
    hideSelected: {
      type: Boolean,
      default: false
    },
    /**
     * Equivalent to the `placeholder` attribute on a `<select>` input.
     * @default 'Select option'
     * @type {String}
     */
    placeholder: {
      type: String,
      default: 'Select option'
    },
    /**
     * Sets maxHeight style value of the dropdown
     * @default 300
     * @type {Integer}
     */
    maxHeight: {
      type: Number,
      default: 300
    },
    /**
     * Allow to remove all selected values
     * @default true
     * @type {Boolean}
     */
    allowEmpty: {
      type: Boolean,
      default: true
    },
    /**
     * Reset this.internalValue, this.search after this.internalValue changes.
     * Useful if want to create a stateless dropdown.
     * @default false
     * @type {Boolean}
     */
    resetAfter: {
      type: Boolean,
      default: false
    },
    /**
     * Enable/disable closing after selecting an option
     * @default true
     * @type {Boolean}
     */
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    /**
     * Function to interpolate the custom label
     * @default false
     * @type {Function}
     */
    customLabel: {
      type: Function,
      default (option, label) {
        return label ? option[label] : option
      }
    },
    /**
     * Disable / Enable tagging
     * @default false
     * @type {Boolean}
     */
    taggable: {
      type: Boolean,
      default: false
    },
    /**
     * String to show when highlighting a potential tag
     * @default 'Press enter to create a tag'
     * @type {String}
    */
    tagPlaceholder: {
      type: String,
      default: 'Press enter to create a tag'
    },
    /**
     * Number of allowed selected options. No limit if false.
     * @default False
     * @type {Number}
    */
    max: {
      type: Number
    },
    /**
     * Will be passed with all events as second param.
     * Useful for identifying events origin.
     * @default null
     * @type {String|Integer}
    */
    id: {
      default: null
    },
    /**
     * Limits the options displayed in the dropdown
     * to the first X options.
     * @default 1000
     * @type {Integer}
    */
    optionsLimit: {
      type: Number,
      default: 1500
    },
    /**
     * String to show when pointing to an option
     * @default 'Press enter to select'
     * @type {String}
     */
    selectLabel: {
      type: String,
      default: 'Press enter to select'
    },
    /**
     * String to show next to selected option
     * @default 'Selected'
     * @type {String}
    */
    selectedLabel: {
      type: String,
      default: 'Selected'
    },
    /**
     * String to show when pointing to an alredy selected option
     * @default 'Press enter to remove'
     * @type {String}
    */
    deselectLabel: {
      type: String,
      default: 'Press enter to remove'
    },
    /**
     * Decide whether to show pointer labels
     * @default true
     * @type {Boolean}
    */
    showLabels: {
      type: Boolean,
      default: true
    },
    /**
     * Limit the display of selected options. The rest will be hidden within the limitText string.
     * @default 'label'
     * @type {String}
     */
    limit: {
      type: Number,
      default: 99999
    },
    /**
     * Function that process the message shown when selected
     * elements pass the defined limit.
     * @default 'and * more'
     * @param {Int} count Number of elements more than limit
     * @type {Function}
     */
    limitText: {
      type: Function,
      default: count => `and ${count} more`
    },
    /**
     * Set true to trigger the loading spinner.
     * @default False
     * @type {Boolean}
    */
    loading: {
      type: Boolean,
      default: false
    },
    /**
     * Disables the multiselect if true.
     * @default false
     * @type {Boolean}
    */
    disabled: {
      type: Boolean,
      default: false
    },
    optionFunction: {
      type: Function,
      default (h, option, label) {
        return h('span', {}, label)
      }
    },
    /**
     * Sets list content to position fixed
     * @default false
     * @type {Boolean}
    */
    contentFixed: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      search: '',
      isOpen: false,
      internalValue: this.getInternalValue(this.value, this.multiple),
      pointer: 0,
      visibleElements: this.maxHeight / 40
    }
  },
  computed: {
    pointerPosition () {
      return this.pointer * 40
    },
    visibleValue () {
      return this.multiple ? this.internalValue.slice(0, this.limit) : []
    },
    deselectLabelText () {
      return this.showLabels ? this.deselectLabel : ''
    },
    selectLabelText () {
      return this.showLabels ? this.selectLabel : ''
    },
    selectedLabelText () {
      return this.showLabels ? this.selectedLabel : ''
    },
    filteredOptions () {
      const includes = (str, query) => {
        if (!str) return false
        const text = str.toString().toLowerCase()
        return text.indexOf(query) !== -1
      }
      const search = this.search || ''
      let options = this.hideSelected
        ? this.options.filter(this.isNotSelected)
        : this.options
      if (this.localSearch) {
        options = this.label
          ? options.filter(option => includes(option[this.label], this.search))
          : options.filter(option => includes(option, this.search))
      }
      if (this.taggable && search.length && !this.isExistingOption(search)) {
        options.unshift({ isTag: true, label: search })
      }
      return options.slice(0, this.optionsLimit)
    },
    valueKeys () {
      if (this.trackBy) {
        return this.multiple
          ? this.internalValue.map(element => element[this.trackBy])
          : this.internalValue[this.trackBy]
      } else {
        return this.internalValue
      }
    },
    optionKeys () {
      return this.label
        ? this.options.map(element => element[this.label].toString().toLowerCase())
        : this.options.map(element => element.toString().toLowerCase())
    },
    currentOptionLabel () {
      return this.getOptionLabel(this.internalValue)
    }
  },
  watch: {
    'filteredOptions' () {
      this.pointerAdjust()
    },
    'internalValue' () {
      if (this.resetAfter) {
        this.internalValue = this.getInternalValue(this.value, this.multiple)
        this.search = ''
      }
      this.adjustSearch()
    },
    'search' () {
      /* istanbul ignore else */
      if (this.search === this.currentOptionLabel) return

      this.$emit('search-change', this.search, this.id)
    },
    'value' () {
      this.internalValue = this.getInternalValue(this.value, this.multiple)
    }
  },
  created () {
    if (this.searchable) this.adjustSearch()
  },
  methods: {
    getInternalValue (value, multiple) {
      const isDeepCloneValue = value || value === 0
      if (isDeepCloneValue) {
        return deepClone(value)
      } else {
        return multiple ? [] : null
      }
    },
    optionHighlight (index, option) {
      return {
        'multiselect__option--highlight': index === this.pointer && this.showPointer,
        'multiselect__option--selected': this.isSelected(option)
      }
    },
    addPointerElement () {
      if (this.filteredOptions.length > 0) {
        this.select(this.filteredOptions[this.pointer])
      }
      this.pointerReset()
    },
    pointerForward () {
      if (this.pointer < this.filteredOptions.length - 1) {
        this.pointer++
        if (this.$refs.list.scrollTop <= this.pointerPosition - this.visibleElements * 40) {
          this.$refs.list.scrollTop = this.pointerPosition - (this.visibleElements - 1) * 40
        }
      }
    },
    pointerBackward () {
      if (this.pointer > 0) {
        this.pointer--
        if (this.$refs.list.scrollTop >= this.pointerPosition) {
          this.$refs.list.scrollTop = this.pointerPosition
        }
      }
    },
    pointerReset () {
      if (!this.closeOnSelect) return

      this.pointer = 0
      if (this.$refs.list) {
        this.$refs.list.scrollTop = 0
      }
    },
    pointerAdjust () {
      if (this.pointer >= this.filteredOptions.length - 1) {
        this.pointer = this.filteredOptions.length
          ? this.filteredOptions.length - 1
          : 0
      }
    },
    pointerSet (index) {
      this.pointer = index
    },
    updateSearch (query) {
      this.search = query.trim().toLowerCase()
    },
    /**
     * Finds out if the given query is already present
     * in the available options
     * @param  {String}
     * @returns {Boolean} returns true if element is available
     */
    isExistingOption (query) {
      return !this.options
        ? false
        : this.optionKeys.indexOf(query) > -1
    },
    /**
     * Finds out if the given element is already present
     * in the result value
     * @param  {Object||String||Integer} option passed element to check
     * @returns {Boolean} returns true if element is selected
     */
    isSelected (option) {
      /* istanbul ignore else */
      if (!this.internalValue) return false
      const opt = this.trackBy
        ? option[this.trackBy]
        : option

      if (this.multiple) {
        return this.valueKeys.indexOf(opt) > -1
      } else {
        return this.valueKeys === opt
      }
    },
    /**
     * Finds out if the given element is NOT already present
     * in the result value. Negated isSelected method.
     * @param  {Object||String||Integer} option passed element to check
     * @returns {Boolean} returns true if element is not selected
     */
    isNotSelected (option) {
      return !this.isSelected(option)
    },
    /**
     * Returns empty string when options is null/undefined
     * Returns tag query if option is tag.
     * Returns the customLabel() results and casts it to string.
     *
     * @param  {Object||String||Integer} Passed option
     * @returns {Object||String}
     */
    getOptionLabel (option) {
      if (!option && option !== 0) return ''
      if (option.isTag) return option.label
      return this.customLabel(option, this.label) + ''
    },
    /**
     * Add the given option to the list of selected options
     * or sets the option as the selected option.
     * If option is already selected -> remove it from the results.
     *
     * @param  {Object||String||Integer} option to select/deselect
     */
    select (option) {
      if (this.max && this.multiple && this.internalValue.length === this.max) return
      if (option.isTag) {
        this.$emit('tag', option.label, this.id)
        this.search = ''
      } else {
        if (this.multiple) {
          if (this.isSelected(option)) {
            this.removeElement(option)
            return
          } else {
            this.internalValue.push(option)
          }
        } else {
          const isSelected = this.isSelected(option)

          /* istanbul ignore else */
          if (isSelected && !this.allowEmpty) return

          this.internalValue = isSelected ? null : option
        }
        this.$emit('select', deepClone(option), this.id)
        this.$emit('input', deepClone(this.internalValue), this.id)

        if (this.closeOnSelect) this.deactivate()
      }
    },
    /**
     * Removes the given option from the selected options.
     * Additionally checks this.allowEmpty prop if option can be removed when
     * it is the last selected option.
     *
     * @param  {type} option description
     * @returns {type}        description
     */
    removeElement (option) {
      /* istanbul ignore else */
      if (!this.allowEmpty && this.internalValue.length <= 1) return

      const index = (this.multiple && typeof option === 'object')
        ? this.valueKeys.indexOf(option[this.trackBy])
        : this.valueKeys.indexOf(option)

      this.internalValue.splice(index, 1)
      this.$emit('remove', deepClone(option), this.id)
      this.$emit('input', deepClone(this.internalValue), this.id)
    },
    /**
     * Calls this.removeElement() with the last element
     * from this.internalValue (selected element Array)
     *
     * @fires this#removeElement
     */
    removeLastElement () {
      /* istanbul ignore else */
      if (this.search.length === 0 && Array.isArray(this.internalValue)) {
        this.removeElement(this.internalValue[this.internalValue.length - 1])
      }
    },
    /**
     * Opens the multiselect’s dropdown.
     * Sets this.isOpen to TRUE
     */
    activate () {
      /* istanbul ignore else */
      if (this.isOpen) return

      this.isOpen = true
      /* istanbul ignore else  */
      if (this.searchable) {
        this.search = ''
        this.$refs.search.focus()
      } else {
        this.$el.focus()
      }
      this.$emit('open', this.id)
    },
    /**
     * Closes the multiselect’s dropdown.
     * Sets this.isOpen to FALSE
     */
    deactivate () {
      /* istanbul ignore else */
      if (!this.isOpen) return

      this.isOpen = false
      /* istanbul ignore else  */
      if (this.searchable) {
        this.$refs.search.blur()
        this.adjustSearch()
      } else {
        this.$el.blur()
      }
      this.$emit('close', deepClone(this.internalValue), this.id)
    },
    /**
     * Adjusts the Search property to equal the correct value
     * depending on the selected value.
     */
    adjustSearch () {
      if (!this.searchable || !this.clearOnSelect) return

      this.search = this.multiple
        ? ''
        : this.currentOptionLabel
    },
    /**
     * Call this.activate() or this.deactivate()
     * depending on this.isOpen value.
     *
     * @fires this#activate || this#deactivate
     * @property {Boolean} isOpen indicates if dropdown is open
     */
    toggle () {
      this.isOpen
        ? this.deactivate()
        : this.activate()
    }
  },
  template: '#multiselect-component'
}
</script>
<style lang="scss" scoped>
@import "../../assets/scss/extra/mixins/flexbox.scss";
@import "../../assets/scss/extra/mixins/scrollbar.scss";
@import "../../assets/scss/bootstrap-custom-variables.scss";

fieldset[disabled] .multiselect {
  pointer-events: none;
}

.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 3rem;
  height: 2.1875rem;
  background: #fff;
  display: block;

  &:before,
  &:after {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    margin: -.5rem 0 0 -.5rem;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    border-color: $brand-success transparent transparent;
    border-style: solid;
    border-width: 2px;
    box-shadow: 0 0 0 1px transparent;
  }

  &:before {
    animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
    animation-iteration-count: infinite;
  }

  &:after {
    animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
    animation-iteration-count: infinite;
  }
}

.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}

.multiselect__loading-enter,
.multiselect__loading-leave-active {
  opacity: 0;
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: .875rem;
}

.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  // min-height: 2.5rem;
  min-height: 38px;
  text-align: left;
  color: $body-color;
  @include scrollbar(.5rem, 3.5rem, darken($gray-lightest, 10%));
}
.multiselect.small {
  font-size: .8125rem;
  height: 1.625rem;
  min-height: 0;
  .multiselect__tags {
    min-height: 0;
    padding: .125rem 2.5rem .125rem .5rem;
    .multiselect__single {
      font-size: .8125rem;
      padding: 0 0 0 .25rem;
      margin-top: 0;
    }
  }
  .multiselect__select {
    height: 1.625rem;
  }
  .multiselect__option {
    padding: .375rem .75rem;
    min-height: 1.625rem;
  }
}

.multiselect * {
  box-sizing: border-box;
}

.multiselect:focus {
  outline: none;
}

.multiselect--disabled {
  pointer-events: none;
  opacity: 0.6;
}

.multiselect--active {
  z-index: 50;
}

.multiselect--active .multiselect__current,
.multiselect--active .multiselect__input,
.multiselect--active .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.multiselect--active .multiselect__select {
  transform: rotateZ(180deg);
}

.multiselect__input,
.multiselect__single {
  position: relative;
  display: inline-block;
  min-height: 1.25rem;
  line-height: 1.25rem;
  border: none;
  border-radius: 0;
  background: #fff;
  padding: 1px 0 0 5px;
  width: calc(100%);
  transition: border 0.1s ease;
  box-sizing: border-box;
  margin-top: .5rem;
}

.multiselect__tag~.multiselect__input {
  width: auto;
}

.multiselect__input:hover,
.multiselect__single:hover {
  border-color: $gray-lighter;
}

.multiselect__input:focus,
.multiselect__single:focus {
  border-color: $gray-light;
  outline: none;
}

.multiselect__single {
  padding-left: .375rem; // margin-bottom: .5rem;
}

.input-group {
  .input-group-addon,
  .input-group-btn {
    &~.multiselect {
      .multiselect__tags {
        line-height: 1.75;
        .multiselect__input,
        .multiselect__single {
          font-size: 1rem;
          padding: 3px 0px 0px 5px;
        }
      }
    }
  }
}
.multiselect__tags {
  min-height: 38px;
  display: block;
  padding: 0 2.5rem .4rem .5rem;
  border: 1px solid rgba(0, 0, 0, .15);
  background: #fff;
  line-height: 1.25;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  transition: border-color .3s ease
}
.is-invalid .multiselect__tags {
  border-color: #c00 !important
}
.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: .2rem 1.5rem .2rem .625rem;
  border-radius: 0;
  margin: .35rem .625rem 0 0;
  color: #fff;
  line-height: 1.25;
  background: $brand-primary;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

.multiselect__tag-icon {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -.55rem;
  cursor: pointer;
  margin-left: 1.675rem;
  font-weight: 700;
  font-style: initial;
  width: 1.375rem;
  text-align: center;
  transition: all 0.2s ease;
  border-radius: 0;

  &:after {
    content: 'x';
    color: #fff;
    font-size: .875rem;
  }

  &:focus,
  &:hover {
    &:after {
      color: $brand-danger;
    }
  }
}

.multiselect__current {
  line-height: 1rem;
  min-height: 2.5rem;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  padding: .5rem .75rem 0;
  padding-right: 1.875rem;
  white-space: nowrap;
  margin: 0;
  text-decoration: none;
  border-radius: 0;
  border: 1px solid $gray-lighter;
  cursor: pointer;
}

.multiselect__select {
  line-height: 1rem;
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 2.5rem;
  height: 2.375rem;
  right: 1px;
  top: 1px;
  padding: .25rem .5rem;
  margin: 0;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease;

  &:before {
    position: relative;
    right: 0;
    top: 60%;
    color: $gray-light;
    margin-top: .25rem;
    border-style: solid;
    border-width: .3125rem .3125rem 0 .3125rem;
    border-color: $gray-light transparent transparent transparent;
    content: "";
  }
}

.multiselect__placeholder {
  color: $gray-lighter;
  display: inline-block;
  margin-bottom: .625rem;
  padding-top: 2px;
}

.multiselect--active .multiselect__placeholder {
  display: none;
}

.input-group .multiselect__content:not(:last-child) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.multiselect__content {
  position: absolute;
  list-style: none;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 15rem;
  overflow: auto;
  padding: 0;
  margin: 0;
  border: 1px solid $gray-lighter;
  border-top: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 50;

  &::webkit-scrollbar {
    display: none;
  }
}

.multiselect__content.content_fixed {
  position: fixed;
  width: calc(100% - 4rem);
}

.multiselect__option {
  display: block;
  padding: .75rem;
  min-height: 2.5rem;
  line-height: 1rem;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  white-space: nowrap;

  &:after {
    top: 0;
    right: 0;
    position: absolute;
    line-height: 2.5rem;
    padding-right: .75rem;
    padding-left: 1.25rem;
  }
}

.multiselect__option--highlight {
  background: lighten($brand-primary, 5%);
  outline: none;
  color: #fff;

  &:after {
    content: attr(data-select);
    color: darken($gray-lighter, 10%);
  }
}

.multiselect__option--selected {
  background: $gray-lightest;
  color: $body-color;
  font-weight: bold;

  &:after {
    content: attr(data-selected);
    color: $gray-lighter;
  }
}

.multiselect__option--selected.multiselect__option--highlight {
  background: lighten($brand-danger, 10%);
  color: #fff;

  &:after {
    content: attr(data-select);
    color: darken($gray-lighter, 8%);
    font-weight: normal;
  }
}

.multiselect--disabled {
  background: $gray-lighter;
  pointer-events: none;

  .multiselect__current,
  .multiselect__select {
    // background: $gray-lighter;
    color: $gray-light;
  }
}

.multiselect__option--disabled {
  background: $gray-lighter;
  color: $gray-light;
  cursor: text;
  pointer-events: none;

  &:visited {
    color: darken($gray-light, 5%);
  }

  &:hover,
  &:focus {
    background: lighten($brand-primary, 8%);
  }
}

.multiselect-enter-active,
.multiselect-leave-active {
  transition: all 0.3s ease;
}

.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
  max-height: 0 !important;
}

.multiselect-input-group {
  @include flexbox;
  .input-group-addon {
    @include flex(0, 1, auto);
  }

  .input-group-btn {
    .btn {
      font-size: 1.25rem;
      padding: .415rem .75rem;
    }
  }

  .multiselect {
    @include flexbox;
    @include flex-direction(column);
    @include flex(0, 1, auto);

    &:not(:last-child) {
      .multiselect__tags {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
}

.has-success {
  .multiselect__tags {
    background-color: $state-success-bg;
    color: $state-success-text;
    border-color: $state-success-border;
  }
  .multiselect__input {
    background-color: $state-success-bg;
    color: $state-success-text;
  }
}

.has-danger {
  .multiselect__tags {
    background-color: $state-danger-bg;
    color: $state-danger-text;
    border-color: $state-danger-border;
  }
  .multiselect__input {
    background-color: $state-danger-bg;
    color: $state-danger-text;
  }
}
.tag-selected-user {
    background: #eaeaea;
    span {
      color: #000;
    }
    i {
      border: none;
      outline: none;
      &:after {
        color: #c00;
        font-size: .8rem;
      }
    }
  }
@keyframes spinning {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(2turn);
  }
}
</style>
