<template>
  <modal
    ref="userProfileModal"
    :title="modal.title"
    :icon="modal.icon"
    :blocking="true"
    @close="reset()">
    <h6
      v-if="modal.message"
      class="user-preferences modal-message">
      {{ modal.message }}
    </h6>
    <div
      v-else
      class="user-preferences">
      <div class="form-group">
        <label class="form-control-label">
          Display Name:
        </label>
        <input
          v-model="userName"
          type="text"
          class="form-control"
          :class="{'is-invalid': $v.userName.$error}"
          @input="$v.userName.$touch()" />
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label class="form-control-label">
              Default Language
            </label>
            <select
              v-model="defaultLanguage"
              class="form-control">
              <option
                v-for="l in $store.getters.languageOptions"
                :key="`default-language-${l.id}`"
                :value="l.id">
                {{ l.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label class="form-control-label">
              Default Company
            </label>
            <select
              v-model="defaultCompany"
              class="form-control">
              <option
                v-for="c in companyToShow"
                :key="`company-${c.company.companyId}`"
                :value="c.company.companyId">
                {{ c.company.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div
      slot="button"
      class="preference-actions">
      <button
        type="button"
        class="btn btn-link"
        @click="$refs.userProfileModal.close()">
        Cancel
      </button>
      <button
        v-if="modal.message"
        type="button"
        class="btn btn-primary"
        @click="$refs.userProfileModal.close()">
        Done
      </button>
      <button
        v-else
        type="button"
        class="btn btn-primary"
        :disabled="loading"
        @click="updatePreferences()">
        {{ loading ? 'Saving...' : 'Save Changes' }}
      </button>
    </div>
  </modal>
</template>
<script>
import Modal from 'components/modals/sweet'
import { required, minLength } from 'vuelidate/lib/validators'
export default {
  name: 'Profile',
  components: {
    modal: Modal
  },
  props: {
    show: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      modal: {
        icon: null,
        message: null,
        title: 'Edit Preferences'
      },
      userName: '',
      defaultCompany: null,
      defaultLanguage: null,
      languageOptions: []
    }
  },
  computed: {
    companyToShow () {
      return this.$store.getters.permissions.filter((p) => {
        return p.contributor || p.isEditor || p.isAdmin
      })
    }
  },
  watch: {
    show (value) {
      if (value) {
        this.setUp()
      }
    }
  },
  methods: {
    setUp () {
      const store = this.$store.getters
      this.modal.message = null
      this.userName = store.user.name
      this.languageOptions = store.languageOptions
      this.defaultLanguage = store.user.languageId
      this.defaultCompany = store.user.defaultCompany
      this.$refs.userProfileModal.open()
    },
    updatePreferences () {
      const data = {
        DefaultLanguage: this.defaultLanguage,
        DefaultCompany: this.defaultCompany,
        DisplayName: this.userName
      }
      this.loading = true
      this.$http.put('/api/users/preferences', data)
        .then(res => {
          const response = res.data
          this.loading = false
          this.modal.icon = 'success'
          this.modal.message = `${this.userName}, your preferences have been saved successfully!`
          this.$store.dispatch('setUser', response)
          this.$store.dispatch('setLanguage', this.$store.getters.languageOptions.find(l => l.id === response.languageId))
          setTimeout(() => {
            this.$refs.userProfileModal._animateIcon()
          }, 50)
        }).catch(e => console.warn(e))
    },
    reset () {
      this.modal.icon = null
      this.modal.message = null
      this.$emit('update:show', false)
    }
  },
  validations () {
    return {
      userName: {
        required,
        minLength: minLength(2)
      }
    }
  }
}
</script>
<style lang="scss">
@import "../../../assets/scss/extra/mixins/flexbox.scss";
@import "../../../assets/scss/bootstrap-custom-variables.scss";
.user-preferences {
  .form-group {
    position: relative;
    .invalid-feedback {
      position: absolute;
    }
  }
  &.modal-message {
    color: $gray-700;
    font-weight: 600;
    font-size: 1rem;
    text-align: center;
  }
}
.preference-actions {
  padding-right: 0.5rem;
  @include flexbox;
  @include justify-content(space-between);
  .btn:not(.btn-link) {
    min-width: 7rem;
  }
}
</style>
