<template>
  <section
    id="company-specific"
    class="card">
    <div class="card-header">
      <ul
        class="nav nav-tabs card-header-tabs"
        role="tablist">
        <!--Platt-->
        <li
          v-if="model.platt"
          class="nav-item">
          <a
            class="nav-link active"
            data-toggle="tab"
            href="#platt"
            role="tab"
            :class="{'active': firstActiveBanner == 'platt'}">Platt</a>
        </li>
        <!--Rexel-->
        <li
          v-if="model.rexel"
          class="nav-item">
          <a
            class="nav-link"
            data-toggle="tab"
            href="#rexel"
            role="tab"
            :class="{'active': firstActiveBanner == 'rexel'}">Rexel</a>
        </li>
        <!--Gexpro-->
        <li
          v-if="model.gexpro"
          class="nav-item">
          <a
            class="nav-link"
            data-toggle="tab"
            href="#gexpro"
            role="tab"
            :class="{'active': firstActiveBanner == 'gexpro'}">Gexpro</a>
        </li>
        <!--Mayer-->
        <li
          v-if="model.mayer"
          class="nav-item">
          <a
            class="nav-link"
            data-toggle="tab"
            href="#mayer"
            role="tab"
            :class="{'active': firstActiveBanner == 'mayer'}">Mayer</a>
        </li>
        <!--Rexel Energy-->
        <li
          v-if="model.rexelEnergy"
          class="nav-item">
          <a
            class="nav-link"
            data-toggle="tab"
            href="#rexelEnergy"
            role="tab"
            :class="{'active': firstActiveBanner == 'rexelEnergy'}">Rexel Energy</a>
        </li>
        <!--Capitol Light-->
        <li
          v-if="model.capitolLight"
          class="nav-item">
          <a
            class="nav-link"
            data-toggle="tab"
            href="#capitolLight"
            role="tab"
            :class="{'active': firstActiveBanner == 'capitolLight'}">Capitol Light</a>
        </li>
        <!--Parts Super Center-->
        <li
          v-if="model.partsSuperCenter"
          class="nav-item">
          <a
            class="nav-link"
            data-toggle="tab"
            href="#partsSuperCenter"
            role="tab"
            :class="{'active': firstActiveBanner == 'partsSuperCenter'}">Parts Super Center</a>
        </li>
        <!--Rexel Canada-->
        <li
          v-if="model.rexelCanadaBanners.length > 0"
          class="nav-item">
          <a
            class="nav-link"
            data-toggle="tab"
            href="#rexelCanada"
            role="tab"
            :class="{'active': firstActiveBanner == 'rexelCanadaBanners'}">Rexel CA</a>
        </li>
      </ul>
    </div>
    <div class="section-body">
      <div class="tab-content">
        <!--Platt-->
        <div
          v-if="model.platt"
          id="platt"
          class="tab-pane"
          role="tabpanel"
          :class="{'active': firstActiveBanner == 'platt'}">
          <platt
            :product.sync="model.platt"
            @bannerPropChange="bannerPropChange($event)"></platt>
        </div>

        <!--Rexel-->
        <div
          v-if="model.rexel"
          id="rexel"
          class="tab-pane"
          role="tabpanel"
          :class="{'active': firstActiveBanner == 'rexel'}">
          <rexel
            :product.sync="model.rexel"
            @bannerPropChange="bannerPropChange($event)"></rexel>
        </div>

        <!--Gexpro-->
        <div
          v-if="model.gexpro"
          id="gexpro"
          class="tab-pane"
          role="tabpanel"
          :class="{'active': firstActiveBanner == 'gexpro'}">
          <gexpro
            :product.sync="model.gexpro"
            @bannerPropChange="bannerPropChange($event)"></gexpro>
        </div>
        <!--Mayer-->
        <div
          v-if="model.mayer"
          id="mayer"
          class="tab-pane"
          role="tabpanel"
          :class="{'active': firstActiveBanner == 'mayer'}">
          <mayer
            :product.sync="model.mayer"
            @bannerPropChange="bannerPropChange($event)"></mayer>
        </div>
        <!--Rexel Energy-->
        <div
          v-if="model.rexelEnergy"
          id="rexelEnergy"
          class="tab-pane"
          role="tabpanel"
          :class="{'active': firstActiveBanner == 'rexelEnergy'}">
          <rexelEnergy
            :product.sync="model.rexelEnergy"
            @bannerPropChange="bannerPropChange($event)"></rexelEnergy>
        </div>
        <!--Capitol Light-->
        <div
          v-if="model.capitolLight"
          id="capitolLight"
          class="tab-pane"
          role="tabpanel"
          :class="{'active': firstActiveBanner == 'capitolLight'}">
          <capitolLight
            :product.sync="model.capitolLight"
            @bannerPropChange="bannerPropChange($event)"></capitolLight>
        </div>
        <!--Parts Super Center-->
        <div
          v-if="model.partsSuperCenter"
          id="partsSuperCenter"
          class="tab-pane"
          role="tabpanel"
          :class="{'active': firstActiveBanner == 'partsSuperCenter'}">
          <partsSuperCenter
            :product.sync="model.partsSuperCenter"
            @bannerPropChange="bannerPropChange($event)"></partsSuperCenter>
        </div>
        <!--Rexel Canada-->
        <div
          v-if="model.rexelCanadaBanners.length > 0"
          id="rexelCanada"
          class="tab-pane"
          role="tabpanel"
          :class="{'active': firstActiveBanner = 'rexelCanadaBanners'}">
          <rexel-canada
            :banners.sync="model.rexelCanadaBanners"
            @bannerPropChange="bannerPropChange($event)"></rexel-canada>
        </div>
      </div>
    </div>
  </section>
</template>
<script type="text/javascript">
import Platt from './Platt'
import Rexel from './Rexel'
import Gexpro from './Gexpro'
import RexelCanada from './RexelCanada'
import Mayer from './Mayer'
import RexelEnergy from './RexelEnergy'
import CapitolLight from './CapitolLight'
import PartsSuperCenter from './PartsSuperCenter'

export default {
  components: {
    platt: Platt,
    rexel: Rexel,
    gexpro: Gexpro,
    'rexel-canada': RexelCanada,
    mayer: Mayer,
    rexelEnergy: RexelEnergy,
    capitolLight: CapitolLight,
    partsSuperCenter: PartsSuperCenter
  },
  props: {
    banners: Array
  },
  data () {
    return {
      model: {
        platt: null,
        rexel: null,
        gexpro: null,
        mayer: null,
        rexelEnergy: null,
        capitolLight: null,
        partsSuperCenter: null,
        rexelCanadaBanners: []
      },
      firstActiveBanner: null
    }
  },
  watch: {
    banners (val) {
      this.setPlattData()
      this.setRexelData()
      this.setGexproData()
      this.setRexelCanadaData()
      this.setMayerData()
      this.setRexelEnergyData()
      this.setCapitolLightData()
      this.setPartsSuperCenterData()
      this.setFirstBannerActive()
    }
  },
  methods: {
    setFirstBannerActive () {
      if (this.model.platt != null) {
        this.firstActiveBanner = 'platt'
      } else if (this.model.rexel != null) {
        this.firstActiveBanner = 'rexel'
      } else if (this.model.gexpro != null) {
        this.firstActiveBanner = 'gexpro'
      } else if (this.model.mayer != null) {
        this.firstActiveBanner = 'mayer'
      } else if (this.model.rexelEnergy != null) {
        this.firstActiveBanner = 'rexelEnergy'
      } else if (this.model.capitolLight != null) {
        this.firstActiveBanner = 'capitolLight'
      } else if (this.model.partsSuperCenter != null) {
        this.firstActiveBanner = 'partsSuperCenter'
      } else if (this.model.rexelCanadaBanners.length > 0) {
        this.firstActiveBanner = 'rexelCanadaBanners'
      }
    },
    setPlattData () {
      const index = this.banners.findIndex(i => i.bannerId === 1)
      if (index > -1) {
        this.model.platt = this.banners[index]
      }
    },
    setRexelData () {
      const index = this.banners.findIndex(i => i.bannerId === 2)
      if (index > -1) {
        this.model.rexel = this.banners[index]
      }
    },
    setGexproData () {
      const index = this.banners.findIndex(i => i.bannerId === 3)
      if (index > -1) {
        this.model.gexpro = this.banners[index]
      }
    },
    setRexelCanadaData () {
      const index = this.banners.findIndex(i => i.bannerId === 411)
      if (index > -1) {
        this.model.rexelCanadaBanners = this.banners[index]
      }
    },
    setMayerData () {
      const index = this.banners.findIndex(i => i.bannerId === 12)
      if (index > -1) {
        this.model.mayer = this.banners[index]
      }
    },
    setRexelEnergyData () {
      const index = this.banners.findIndex(i => i.bannerId === 13)
      if (index > -1) {
        this.model.rexelEnergy = this.banners[index]
      }
    },
    setCapitolLightData () {
      const index = this.banners.findIndex(i => i.bannerId === 14)
      if (index > -1) {
        this.model.capitolLight = this.banners[index]
      }
    },
    setPartsSuperCenterData () {
      const index = this.banners.findIndex(i => i.bannerId === 15)
      if (index > -1) {
        this.model.partsSuperCenter = this.banners[index]
      }
    },
    bannerPropChange (val) {
      this.$emit('bannerPropChange', val)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../../assets/scss/extra/mixins/flexbox";
@import "../../../../assets/scss/bootstrap-custom-variables";

.col.wrap {
  flex-wrap: wrap;
}

section.card {
  border: 0;
  margin: 0.5rem 0 1.5rem;
  .card-header {
    padding: 0.75rem 0 0;
    background-color: #fff;
    .card-header-tabs {
      margin: 0;
    }

    ul.nav-tabs {
      li.nav-item {
        min-width: 12.5rem;
        text-align: center;
        margin-right: 0.25rem;
        a {
          color: #000;
          font-weight: bold;
          background-color: #cfcfcf;
          &.active {
            background-color: #fff;
            color: $brand-primary;
          }
        }
      }
    }
  }
  div.tab-content {
    border: 1px solid #ddd;
    border-top: 0;
    .tab-pane {
      padding: 0.75rem 1rem 0;
    }
    div#product-number-price-cartoptions,
    div#product-family-grouping-market,
    div#product-order-details {
      @media (min-width: 37.5rem) {
        @include flexbox;
        @include flex-wrap(wrap);
        @include justify-content(space-between);
      }
      .form-group {
        width: 100%;
        @media (min-width: 37.5rem) {
          width: calc(50% - 1rem);
        }
        @media (min-width: 1200px) {
          width: calc(100% / 3 - 1rem);
        }
        input[readonly],
        input[disabled="disabled"] {
          border: 1px solid transparent;
          background-color: #ececec;
        }
      }
      div#product-cartoptions {
        width: 100%;
      }
    }
  }
}
</style>
