<template>
  <multi-select
    v-model="selected"
    :options="manufacturers"
    label="name"
    track-by="name"
    placeholder="Select a Manufacturer"
    :reset-after="false"
    :multiple="true"
    :hide-selected="false"
    :show-labels="true"
    :show-tags="false"
    :custom-label="customManufacturerLabel"></multi-select>
</template>

<script>
import Multiselect from 'components/inputs/multiselect.vue'

export default {
  name: 'ManufacturersMultiSelect',
  components: {
    'multi-select': Multiselect
  },
  props: {
    manufacturers: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selected: []
    }
  },
  watch: {
    selected (val) {
      this.emitData()
    }
  },
  methods: {
    customManufacturerLabel (option) {
      const total = option.totalProductCount ? option.totalProductCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0
      return `${option.name}  (${total})`
    },
    emitData () {
      this.$emit('onSelected', this.selected)
    },
    resetDropdown () {
      this.selected = []
    }
  }
}
</script>
