<template>
  <div class="product-search">
    <page-title title="Products"></page-title>

    <div class="row">
      <div class="col">
        <div
          v-if="!resultsFound"
          class="alert alert-info alert-dismissible mb-0"
          role="alert">
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close">
            <span aria-hidden="true">
              &times;
            </span>
          </button>
          No results for Found
        </div>
      </div>
    </div>

    <!--Search-->
    <form ref="productForm">
      <div class="row section">
        <div class="col-sm-12">
          <h5 class="section-heading">
            Search Products
          </h5>
        </div>
        <div class="col-md-6 col-lg-3">
          <banner-multiselect
            ref="bannerSelect1"
            :show-label="true"
            :default-banner-id="defaultBannerId"
            @onSelected="setSearchBanners($event)"></banner-multiselect>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="search-group mt-6">
            <input
              v-model="searchTerm"
              type="text"
              class="form-control search-input"
              autocomplete="off"
              placeholder="Search Term"
              @keyup.enter="fetchData"
              @click:search="fetchData" />
            <i class="material-icons search-icon">
              search
            </i>
          </div>
        </div>
      </div>

      <div class="row section">
        <div class="col-sm-12">
          <h5 class="section-heading">
            Product Report
          </h5>
        </div>
        <!--Banners-->
        <div class="col-md-6 col-lg-4">
          <h6>Select Banners</h6>
          <banner-multiselect
            ref="bannerSelect2"
            :show-label="true"
            :default-banner-id="defaultBannerId"
            @onSelected="setBanners($event)"></banner-multiselect>
        </div>
      </div>

      <!--Product Categories-->
      <div class="row section">
        <div class="col-lg-8">
          <div class="row">
            <div class="col-sm-12">
              <h6>Select Product Categories</h6>
            </div>
            <!--Manufacturers-->
            <div class="col-md-6">
              <div class="form-group">
                <manufacturers-multiselect
                  ref="manuSelect"
                  @onSelected="setManufacturers($event)"></manufacturers-multiselect>
              </div>
            </div>
            <!--Enrichment Level-->
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label">
                  Enrichment Level
                </label>
                <multi-select
                  v-model="report.enrichmentLevel"
                  :options="enrichmentLevels"
                  label="name"></multi-select>
              </div>
            </div>
            <!--Last Updated Date-->
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label">
                  Not Updated In
                </label>
                <multi-select
                  v-model="report.lastUpdatedDate"
                  :options="lastUpdatedDates"
                  label="name"></multi-select>
              </div>
            </div>
            <!--Sales Tier-->
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label">
                  Sales Tier
                </label>
                <multi-select
                  v-model="report.salesTier"
                  :options="salesTiers"
                  label="name"></multi-select>
              </div>
            </div>
            <!--Section-->
            <div class="col-md-6">
              <div class="form-group">
                <category-dropdown
                  label="Section"
                  :init="true"
                  :language-id="languageId"
                  @onSelected="setSection($event)"></category-dropdown>
              </div>
            </div>
            <!--Group-->
            <div class="col-md-6">
              <div class="form-group">
                <category-dropdown
                  label="Group"
                  :language-id="languageId"
                  :parent-id.sync="report.section.id"
                  @onSelected="setGroup($event)"></category-dropdown>
              </div>
            </div>
            <!--Category-->
            <div class="col-md-6">
              <div class="form-group">
                <category-dropdown
                  label="Category"
                  :language-id="languageId"
                  :parent-id.sync="report.group.id"
                  @onSelected="setCategory($event)"></category-dropdown>
              </div>
            </div>
            <!--Sub Category-->
            <div class="col-md-6">
              <div class="form-group">
                <category-dropdown
                  label="Sub Category"
                  :language-id="languageId"
                  :parent-id.sync="report.category.id"
                  @onSelected="setSubCategory($event)"></category-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row section">
        <div class="col-md-8">
          <h6 class="product-field-title">
            Select Product Fields
          </h6>
          <div class="row">
            <div class="col-lg-6 col-md-8 product-fields-wrap">
              <div
                v-for="s in radioButtons"
                :key="'product--' + s.name"
                class="form-group product-field">
                <label class="field-label">
                  {{ s.label }}:
                </label>
                <label class="custom-control custom-radio">
                  <input
                    :id="s.name + '-all'"
                    ref="s.name"
                    v-model="s.value"
                    :name="s.name"
                    type="radio"
                    :value="null"
                    class="custom-control-input" />
                  <span class="custom-control-indicator"></span>
                  <span class="custom-control-description">
                    All
                  </span>
                </label>
                <label class="custom-control custom-radio">
                  <input
                    :id="s.name + '-yes'"
                    ref="s.name"
                    v-model="s.value"
                    :name="s.name"
                    type="radio"
                    :value="true"
                    class="custom-control-input" />
                  <span class="custom-control-indicator"></span>
                  <span class="custom-control-description">
                    Yes
                  </span>
                </label>
                <label class="custom-control custom-radio">
                  <input
                    :id="s.name + '-no'"
                    ref="s.name"
                    v-model="s.value"
                    :name="s.name"
                    type="radio"
                    :value="false"
                    class="custom-control-input" />
                  <span class="custom-control-indicator"></span>
                  <span class="custom-control-description">
                    No
                  </span>
                </label>
              </div>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-2">
              <button
                :disabled="!bannerSelected"
                type="button"
                class="btn btn-primary"
                :class="{'disabled': showSpinner}"
                @click="emitData">
                Generate Report
              </button>
            </div>
          </div>
          (at least one banner must be selected)
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import moment from 'moment'
import Multiselect from 'components/inputs/multiselect.vue'
import TitleComponent from 'components/pageTitle.vue'
import CategoryDropdown from 'components/categories/Dropdown'
import BannerMultiselect from 'components/banners/Multiselect'
import ManufacturersMultiselect from 'components/manufacturers/Multiselect'

export default {
  name: 'ReportFilter',
  components: {
    'page-title': TitleComponent,
    'multi-select': Multiselect,
    'category-dropdown': CategoryDropdown,
    'banner-multiselect': BannerMultiselect,
    'manufacturers-multiselect': ManufacturersMultiselect
  },
  props: {
    report: {
      type: Object
    },
    resultsFound: {
      type: Boolean
    }
  },
  data () {
    return {
      bannerSelected: false,
      showSpinner: false,
      bulkUploadModal: false,
      searchTerm: null,
      resetRefs: ['bannerSelect1', 'bannerSelect2', 'manuSelect'],
      searchBanners: [],
      banners: [],
      enrichmentLevels: [
        { name: 'All', value: null },
        { name: 'Level 1', value: 1 },
        { name: 'Level 2', value: 2 },
        { name: 'Level 3', value: 3 },
        { name: 'Level 4', value: 4 },
        { name: 'Level 5', value: 5 }
      ],
      lastUpdatedDates: [
        { name: 'All', value: null },
        { name: '3 Months', value: moment().subtract(3, 'months').toISOString() },
        { name: '6 Months', value: moment().subtract(6, 'months').toISOString() },
        { name: '1 Year', value: moment().subtract(1, 'years').toISOString() },
        { name: '2 Years', value: moment().subtract(2, 'years').toISOString() }
      ],
      salesTiers: [
        { name: 'All', value: null },
        { name: 'Tier 1', value: 1 },
        { name: 'Tier 2', value: 2 },
        { name: 'Tier 3', value: 3 }
      ],
      radioButtons: [
        {
          label: 'Category',
          name: 'hasCategory',
          value: null
        },
        {
          label: 'Document',
          name: 'hasDocument',
          value: null
        },
        {
          label: 'Images',
          name: 'hasImage',
          value: null
        },
        {
          label: 'Video',
          name: 'hasVideo',
          value: null
        },
        {
          label: 'Attributes',
          name: 'hasAttribute',
          value: null
        },
        {
          label: 'Short Description',
          name: 'hasShortDescription',
          value: null
        },
        {
          label: 'Long Description',
          name: 'hasLongDescription',
          value: null
        },
        {
          label: 'UPC',
          name: 'hasUpc',
          value: null
        },
        {
          label: 'Keywords',
          name: 'hasKeyword',
          value: null
        }
      ]
    }
  },
  computed: {
    languageId () {
      return this.$store.getters.languageId
    },
    defaultBannerId () {
      return this.$store.getters.user.defaultCompany
    }
  },
  watch: {
    radioButtons: {
      handler () {
        this.setProductFields()
      },
      deep: true
    },
    resultsFound (val) {
      if (!val) {
        this.resetFields()
      }
    }
  },
  methods: {
    fetchData () {
      this.$router.push({ name: 'productSearch', query: { banners: this.searchBanners.map(i => i.name), searchTerm: this.searchTerm } })
    },
    setManufacturers (data) {
      this.report.manufacturers = data
    },
    setSearchBanners (data) {
      this.searchBanners = data
    },
    setBanners (data) {
      this.report.banners = data
      this.bannerSelected = (this.report.banners !== null && this.report.banners.length !== 0)
    },
    setSection (data) {
      this.report.section = data
    },
    setGroup (data) {
      this.report.group = data
    },
    setCategory (data) {
      this.report.category = data
    },
    setSubCategory (data) {
      this.report.subCategory = data
    },
    setProductFields () {
      this.radioButtons.forEach((obj, index) => {
        this.report[obj.name] = obj.value
      })
    },
    resetFields () {
      this.$refs.productForm.reset() // Reset all normal inputs
      this.resetRefs.forEach((ref) => {
        this.$refs[ref].resetDropdown() // Reset all custom inputs (add refs to resetRefs Array)
      })
      this.radioButtons.forEach((btn) => {
        btn.value = null // Reset radio buttons
      })
    },
    emitData () {
      this.$emit('onGenerateReport', this.report)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/bootstrap-custom-variables";
.product-search {
  .section {
    padding: 12px 0;
  }

  .section-heading {
    font-size: 18px;
    margin-bottom: 12px;
  }

  .search-group {
    position: relative;

    .search-input {
      font-size: 0.875rem;
      color: #000;
      -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
      padding: 0.75rem 2.5rem 0.75rem 1rem;

      &:focus {
        border-color: $brand-primary;

        & ~ i {
          -webkit-transition: all ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
          transition: all ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
          color: $brand-primary;
          opacity: 1;
        }
      }
    }

    .search-icon {
      position: absolute;
      font-size: 1.5rem;
      top: 0.5rem;
      right: 0.5rem;
      opacity: 0.4;
      padding: 0.25rem;
    }
  }

  .search-company {
    > label {
      padding-right: 1rem;
    }
  }

  .product-field-title {
    display: block;
  }

  .product-fields-wrap {
    display: table;
    border-spacing: 0rem 0.5rem;
    border-collapse: separate;

    .product-field {
      display: table-row;

      > label {
        max-width: 2rem;
        display: table-cell;

        &.field-label {
          max-width: 4rem;
        }

        &.custom-control {
          padding-left: 1.75rem;
        }
      }
    }
  }
}
</style>
