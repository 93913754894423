<template>
  <modal
    ref="searchUploadMediaModal"
    :title="document ? 'Add or Edit Documents': 'Add or Edit Media'"
    :icon="modal.icon"
    :animate-icon="modal.animate"
    :blocking="true"
    :extra-large="true"
    :no-padding="true"
    @close="resetState()">
    <div class="container-fluid media-modal-container">
      <div
        v-if="showModal"
        class="media-layout row no-gutters">
        <div class="media-picker col-md-9">
          <div class="media-search p-3 d-flex flex-column flex-sm-row">
            <nav class="nav nav-pills flex-row">
              <a
                class="text-sm-center nav-item nav-link media-type"
                :class="{'active': search.type === 'existing'}"
                @click="selectedMedia = []; search.type = 'existing'">
                Recent {{ document ? 'Documents' : 'Media' }}
              </a>
              <a
                class="text-sm-center nav-item nav-link media-type"
                :class="{'active': search.type === 'new'}"
                @click="uploadToggle()">
                Add
              </a>
              <a
                class="text-sm-center nav-item nav-link media-type"
                :class="{'active': search.type === 'idea'}"
                @click="selectedMedia = []; search.type = 'idea'">
                IDEA
              </a>
            </nav>
            <div
              v-if="search.type === 'existing'"
              class="search-box mt-2 mt-sm-0 w-100">
              <input
                v-model="search.text"
                type="text"
                class="form-control"
                :class="{'disabled': search.type !== 'existing'}"
                :disabled="search.type !== 'existing'"
                placeholder="Search Recent Media" />
              <a
                v-if="search.text.length"
                class="search-icon"
                @click="search.text = ''">
                <i class="material-icons delete">
                  close
                </i>
              </a>
              <a
                v-else
                class="search-icon">
                <i class="material-icons search">
                  search
                </i>
              </a>
            </div>
          </div>
          <template v-if="search.type === 'existing'">
            <div
              v-if="mediaSearch"
              class="media-list">
              <template v-for="m in mediaSearch">
                <div
                  :key="m.mediaId"
                  class="media-card"
                  :class="{'media-added': mediaList.some(ml => ml.mediaId === m.mediaId)}">
                  <div class="media-check">
                    <label class="custom-control custom-checkbox">
                      <input
                        v-model="selectedMedia"
                        type="checkbox"
                        class="custom-control-input"
                        :value="m" />
                      <span class="custom-control-indicator"></span>
                      <span class="custom-control-description"></span>
                    </label>
                  </div>
                  <div
                    class="card"
                    :class="{'card-selected': selectedMedia.some(item => item.mediaId === m.mediaId)}"
                    @click="addSelectedMedia(m)">
                    <div class="card-media">
                      <media-icon
                        :type="m.mediaType"
                        :file="m.fileName"
                        :img-path="`https://rexel-cdn.com/products/${m.name}.jpg?i=${m.folderName}&f=77`"
                        size="media-list-icon"></media-icon>
                    </div>
                    <div class="media-details text-center">
                      <div class="media-type">
                        {{ m.mediaType }}
                      </div>
                      <div
                        v-if="m.name"
                        class="media-name">
                        {{ m.name.substring(0, 7) + '...' + m.name.substring(m.name.length - 3, m.name.length) }}
                      </div>
                      <div
                        v-else
                        class="media-name">
                        {{ m.fileName.substring(0, 7) + '...' + m.fileName.substring(m.fileName.length - 3, m.fileName.length) }}
                      </div>
                      <div
                        v-if="m.mfrId"
                        class="media-mfr">
                        Mfr: {{ getManfacturer(m.mfrId) }}
                      </div>
                      <div
                        v-else
                        class="media-mfr">
&nbsp;
                      </div>
                      <div class="media-date">
                        <i class="material-icons">
                          today
                        </i>
                        {{ m.uploadDate | isoDate }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div
              v-else
              class="p-3">
              <div class="alert alert-primary">
                <div v-if="search.text.length">
                  You have no recently uploaded {{ document ? 'documents' : 'images or videos' }} that match
                  <b>{{ search.text }} </b>.
                </div>
                <div v-else>
                  You have no recently uploaded Media.
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="search.type === 'new'">
            <uploading-spinner :loading="loading"></uploading-spinner>
            <div
              class="upload-section"
              :class="{'uploading-media': loading}">
              <template v-if="uploadType === 'image'">
                <div class="text-center">
                  <upload-image-form
                    :inline="true"
                    :image.sync="imageToUpload"
                    :boundary="{height: 420, width: 810}"
                    :viewport="{ width: 400, height: 400, type: 'square' }"
                    @imageTooSmall="(tooSmall) => showSmallImgMsg = tooSmall">
                  </upload-image-form>
                </div>
              </template>
              <template v-else-if="uploadType === 'video'">
                <div class="video-upload">
                  <div class="media-logo">
                    <media-icon
                      type="Video"
                      :file="videoToUpload.fileName"
                      size="media-icon-large"></media-icon>
                    <label class="file-name">
                      {{ videoToUpload.fileName }}
                    </label>
                  </div>
                  <div class="crop-media-info w-100 p-4">
                    <div class="form-group">
                      <label class="form-control-label">
                        File Name:
                      </label>
                      <input
                        v-model="videoToUpload.fileName"
                        class="form-control" />
                    </div>
                    <label class="form-control-label">
                      Manufacturer:
                    </label>
                    <select
                      v-if="manufacturers.length"
                      v-model="videoToUpload.manufacturer"
                      class="form-control">
                      <option
                        v-for="m in manufacturers"
                        :key="m.id"
                        :value="m.id">
                        {{ m.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </template>
              <template v-else-if="uploadType === 'document'">
                <div class="document-upload">
                  <div class="media-logo">
                    <media-icon
                      type="Document"
                      :file="documentToUpload.fileName"
                      size="media-icon-large"></media-icon>
                    <label class="file-name">
                      {{ documentToUpload.fileName }}
                    </label>
                  </div>
                  <div class="crop-media-info w-100 p-4">
                    <div class="form-group">
                      <label class="form-control-label form-label-required">
                        Language:
                      </label>
                      <select
                        v-model="documentToUpload.languageId"
                        class="form-control">
                        <option
                          v-for="lang in languageOptions"
                          :key="`${lang.name}`"
                          :value="lang.id">
                          {{ lang.name }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label class="form-control-label form-label-required">
                        Document Type:
                      </label>
                      <select
                        v-model="documentToUpload.type"
                        class="form-control"
                        :class="{'is-invalid': validation.documentType && !documentToUpload.type}">
                        <option
                          v-for="d in documentTypes"
                          :key="d.documentTypeId"
                          :value="d.documentTypeId">
                          {{ d.name }}
                        </option>
                      </select>
                    </div>
                    <label class="form-control-label">
                      Manufacturer:
                    </label>
                    <select
                      v-if="manufacturers.length"
                      v-model="documentToUpload.manufacturer"
                      class="form-control">
                      <option
                        v-for="m in manufacturers"
                        :key="m.id"
                        :value="m.id">
                        {{ m.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </template>
              <template v-else>
                <drop-zone
                  :error="validation.media"
                  :message="modal.message"
                  height="25rem"
                  :document="document"
                  @upload="uploadSearchMedia($event)"></drop-zone>
                <div class="row mx-2 mb-1 wrap">
                  <div class="col-12 col-md-10">
                    <div class="form-group">
                      <input
                        v-model="uploadUri"
                        type="text"
                        class="form-control"
                        placeholder="Import file by url.." />
                    </div>
                  </div>
                  <div class="col-4 col-md-2 pl-md-0">
                    <button
                      type="button"
                      class="btn btn-primary"
                      :disabled="!uploadUri"
                      @click="uploadUrlEvent($event)">
                      Import Url
                    </button>
                  </div>
                  <div
                    v-if="showUploadUrlError"
                    class="col-12">
                    <div
                      class="alert alert-danger"
                      role="alert">
                      {{ uploadUrlErrorMsg }}
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </template>
          <template v-if="search.type === 'idea'">
            <div
              v-if="ideaImages && ideaImages.length > 0"
              class="media-list idea">
              <template v-for="m in ideaImages">
                <div
                  :key="m.mediaId"
                  class="idea-card"
                  :class="{'media-added': mediaList.some(ml => ml.mediaId === m.mediaId)}">
                  <div class="media-check">
                    <label class="custom-control custom-checkbox">
                      <input
                        v-model="selectedMedia"
                        type="checkbox"
                        class="custom-control-input"
                        :value="m" />
                      <span class="custom-control-indicator"></span>
                      <span class="custom-control-description"></span>
                    </label>
                  </div>
                  <div
                    class="card"
                    style="height: 60%;"
                    :class="{'card-selected': selectedMedia.some(item => item.mediaId === m.mediaId)}"
                    @click="addSelectedMedia(m)">
                    <div class="card-media">
                      <media-icon
                        type="Image"
                        :file="m.folderNameGuid"
                        :img-path="`https://rexel-cdn.com/products/${m.folderNameGuid}/${m.folderNameGuid}.jpg`"></media-icon>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div
              v-else
              class="p-3"
              style="height: 77%;">
              <div class="alert alert-primary">
                There are no IDEA images for this product.
              </div>
            </div>
          </template>
          <div class="fixed-media-footer">
            <div
              v-if="search.type === 'existing' || search.type === 'idea'"
              class="counter">
              <b>{{ selectedMedia.length }}</b> {{ document ? 'Documents' : 'Items' }} Selected
            </div>
            <div class="actions text-right">
              <button
                type="button"
                class="btn btn-default"
                :disabled="disableBtns"
                :class="{'disabled': disableBtns}"
                @click="cancelMedia()">
                Reset
              </button>
              <button
                type="button"
                class="btn btn-primary"
                :disable="disableBtns"
                :class="{'disabled': disableBtns}"
                @click="addMedia($event)">
                {{ loading ? 'UPLOADING' : 'ADD MEDIA' }}
              </button>
            </div>
          </div>
        </div>
        <div class="media-existing col-md-3">
          <div class="media-list">
            <div
              v-if="selectedPreview && mediaList.length"
              class="media-preview">
              <div v-if="selectedPreview.folderNameGuid">
                <media-icon
                  size="media-icon-preview"
                  type="Image"
                  :file="selectedPreview.folderNameGuid"
                  :img-path="`https://rexel-cdn.com/products/${selectedPreview.folderNameGuid}/${selectedPreview.folderNameGuid}.jpg`"></media-icon>
              </div>
              <div v-else>
                <media-icon
                  class="img-wrap"
                  :type="document ? 'Document' : selectedPreview.mediaType"
                  :file="selectedPreview.fileName"
                  :img-path="getMediaImagePath({ folder: selectedPreview.folderName, size: 150 })"
                  size="media-icon-preview"></media-icon>
              </div>

              <div class="preview-name mb-2 text-center">
                {{ selectedPreview.name }}
              </div>
              <template v-if="selectedPreview.ordinalPosition === 1 && !document">
                <span class="featured-tag">
                  Main
                </span>
              </template>
              <template v-if="selectedPreview.new">
                <span class="new-tag">
                  NEW
                </span>
              </template>
              <div class="featured-actions">
                <button
                  type="button"
                  class="btn btn-sm btn-gray"
                  @click="editMedia(selectedPreview)">
                  Edit
                </button>
                <button
                  type="button"
                  class="btn btn-link btn-link-danger"
                  @click="removeMedia(selectedPreview)">
                  Remove
                </button>
              </div>
            </div>
            <ul
              v-if="mediaList.length"
              class="list-group">
              <template v-for="(media, index) in mediaList">
                <li
                  v-if="document"
                  :key="`document-${index}`"
                  class="list-group-item list-group-item-action"
                  :class="{'active-light': selectedPreview ? media.documentId === selectedPreview.documentId : false}"
                  @click="selectedPreview = media">
                  <div class="media-item-wrap d-flex justify-content-between">
                    <div class="img-wrap">
                      <media-icon
                        type="Document"
                        :file="media.fileName"
                        size="media-icon-thumbnail"></media-icon>
                    </div>
                    <div
                      v-if="media.name"
                      class="media-info">
                      <div class="media-name">
                        {{ media.name.substring(0, 20) + '...' + media.name.substring(media.name.length - 3, media.name.length) }}
                      </div>
                      <div class="media-type">
                        {{ media.documentType }}
                      </div>
                      <div class="media-date">
                        <i class="material-icons">
                          today
                        </i>
                        {{ media.uploadDate | isoDate }}
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  v-else
                  :key="`media-${index}`"
                  class="list-group-item list-group-item-action"
                  :class="{'active-light': selectedPreview ? media.mediaId === selectedPreview.mediaId : false}"
                  @click="selectedPreview = media">
                  <div class="media-item-wrap d-flex justify-content-between">
                    <div class="img-wrap">
                      <div v-if="media.fileName">
                        <media-icon
                          :type="media.mediaType"
                          :file="media.fileName"
                          :img-path="getMediaImagePath(media)"
                          size="media-icon-thumbnail"></media-icon>
                      </div>
                      <div
                        v-else-if="media.folderNameGuid">
                        <media-icon
                          size="media-icon-thumbnail"
                          type="Image"
                          :file="media.folderNameGuid"
                          :img-path="`https://rexel-cdn.com/products/${media.folderNameGuid}/${media.folderNameGuid}.jpg`"></media-icon>
                      </div>
                    </div>
                    <div
                      v-if="media.name"
                      class="media-info">
                      <div class="media-name">
                        {{ media.name.substring(0, 10) + '...' + media.name.substring(media.name.length - 3, media.name.length) }}
                      </div>
                      <div class="media-date">
                        <i class="material-icons">
                          today
                        </i>
                        {{ media.uploadDate | isoDate }}
                      </div>
                    </div>
                    <div v-else-if="media.folderNameGuid">
                      {{ media.folderNameGuid }}
                    </div>
                    <div class="media-main">
                      <button
                        v-if="!document"
                        type="button"
                        class="btn btn-sm"
                        :class="[media.ordinalPosition === 1 ? 'btn-info' : 'btn-gray']"
                        @click="setFeatured(media)">
                        <span v-if="media.ordinalPosition === 1">
                          Main Image
                        </span>
                        <span v-else>
                          Set to Main
                        </span>
                      </button>
                    </div>
                  </div>
                </li>
              </template>
            </ul>
            <div
              v-else
              class="p-3">
              <div class="alert alert-primary text-center">
                No Existing {{ document ? 'Documents' : 'Media' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script type="text/javascript">
import axios from 'axios'
import { mapGetters } from 'vuex'
import UploadMediaMixin from './UploadMedia.mixin'
// import Cropper from 'components/crop'
import IconComponent from '../mediaIcon.vue'
import LoadingComponent from './uploading.vue'
import UploadZoneComponent from './uploadZone.vue'
import ModalComponent from 'components/modals/sweet.vue'
import UploadImageForm from '@/views/media/types/image/UploadImageForm'
import MediaFilePathMixin from '@/views/media/types/MediaFilePath.mixin'
import { EventBus } from '../bus'

export default {
  name: 'UploadWithSearch',
  mixins: [MediaFilePathMixin, UploadMediaMixin],
  props: {
    imageUri: {
      type: String,
      default: null
    },
    showModal: {
      type: Boolean,
      default: false,
      required: true
    },
    mediaList: {
      type: Array,
      default: () => [],
      required: true
    },
    document: {
      type: Boolean,
      default: false,
      required: true
    },
    mfr: {
      type: Number,
      default: null,
      required: false
    },
    ideaImages: {
      type: Array,
      default: () => [],
      required: false
    }
  },
  data () {
    return {
      loading: false,
      uploadMessage: false,
      modal: {
        icon: null,
        animate: false,
        message: ''
      },
      showSmallImgMsg: false,
      selectedPreview: null,
      manufacturers: [],
      documentTypes: [],
      search: {
        text: '',
        dirty: false,
        type: 'new'
      },
      media: [],
      mediaCount: 100,
      selectedMedia: [],
      companies: [],
      axiosCancel: null,
      minImageWidth: 640,
      minImageHeight: 640
    }
  },
  computed: {
    ...mapGetters([
      'languageOptions'
    ]),
    mediaSearch () {
      const term = this.search.text.toLowerCase()
      if (this.media.length) {
        const searchResults = this.media.filter(m => {
          return m.name
            ? m.name.toLowerCase().indexOf(term) > -1
            : false ||
            m.mediaType
              ? m.mediaType.toLowerCase().indexOf(term) > -1
              : false ||
              m.fileName
                ? m.fileName.toString().toLowerCase().indexOf(term) > -1
                : false
        })
        return searchResults
      }
      return false
    },
    allowedImagesMsg () {
      return `File types allowed are (.jpg & .png). Minimum size required is ${this.minImageWidth}px by ${this.minImageHeight}px.`
    },
    disableBtns () {
      return (this.loading) || (this.search.type === 'new' && !this.uploadType) || ((this.search.type === 'existing' || this.search.type === 'idea') && !this.selectedMedia.length) ||
      (this.uploadType === this.mediaTypes.document && !this.documentToUpload.type)
    }
  },
  watch: {
    showModal (value) {
      if (value) {
        this.setupModal()
      } else {
        this.resetState()
      }
    },
    'documentToUpload.languageId' (val) {
      this.getDocumentTypes(val)
    }
  },
  mounted () {
    this.getManfacturers()
  },
  methods: {
    async uploadUrlEvent (event) {
      this.loading = true
      this.uploadUrl(event).finally(() => {
        this.loading = false
      })
    },
    getRecentMedia (all) {
      const data = {
        params: {
          sortBy: 13, // descending
          from: 1,
          size: 100,
          companies: this.companies,
          recentlyUploadedByUser: true,
          mediaTypeId: this.document ? 5 : 1
        }
      }
      if (this.axiosCancel != null) {
        this.axiosCancel.cancel('Search changed, cancel and researching...')
      }
      this.loading = true
      this.axiosCancel = axios.CancelToken.source()
      axios.get('/api/media', data)
        .then(res => {
          this.loading = false
          this.axiosCancel = null
          this.media = res.data.results
          this.mediaCount = res.data.count
        })
    },
    getManfacturers () {
      this.$http.get('/api/manufacturers')
        .then(res => {
          this.manufacturers = res.data
        })
    },
    getManfacturer (mfr) {
      const manfacturer = this.manufacturers.find(man => {
        return man.id === mfr
      })
      return manfacturer.name
    },
    getDocumentTypes (languageId) {
      axios.get(`/api/media/documents/types?languageId=${languageId}`)
        .then(res => {
          this.documentTypes = res.data
        }).catch(e => console.warn(e))
    },
    setFeatured (media) {
      this.mediaList.forEach(m => {
        m.ordinalPosition = null
      })
      media.ordinalPosition = 1
    },
    addSelectedMedia (media) {
      if (this.selectedMedia.includes(media)) {
        this.selectedMedia.splice(this.selectedMedia.findIndex(sm => sm.mediaId === media.mediaId), 1)
      } else {
        this.selectedMedia.push(media)
      }
    },
    uploadSearchMedia (event) {
      this.uploadMedia(event)
    },
    validateBtn (isDoc) {
      if (isDoc) {
        return this.loading || (this.isDirty && !this.documentToUpload.type)
      } else {
        return this.loading
      }
    },
    uploadToggle () {
      this.search.text = ''
      this.search.type = 'new'
    },
    editMedia (media) {
      let mediaType
      if (media.mediaType === 'Image') {
        mediaType = 1
      } else if (media.mediaType === 'Video') {
        mediaType = 4
      } else {
        mediaType = 5
      }
      this.$refs.searchUploadMediaModal.close()
      this.$router.push({ name: 'mediaDetails', params: { type: mediaType, id: media.mediaId } })
    },
    removeMedia (media) {
      const mediaIndex = this.mediaList.findIndex(m => {
        return this.document ? media.documentId === m.documentId : media.mediaId === m.mediaId
      })
      this.$emit('remove', { media, index: mediaIndex })
      if (this.mediaList.length) {
        this.selectedPreview = this.mediaList[0]
      } else {
        this.selectedPreview = null
      }
    },
    addMedia (event) {
      if (this.disableBtns) return
      if (this.search.type === 'existing') {
        const newMedia = this.selectedMedia.map(m => {
          this.$set(m, 'new', true)
          this.$set(m, 'ordinalPosition', false)
          this.$emit('add', m)
          return m
        })
        this.mediaList.push(...newMedia) // merge the two arrays
        this.selectedMedia = []
        this.selectedPreview = this.mediaList[this.mediaList.length - 1]
      } else if (this.search.type === 'new') {
        this.loading = true
        this.saveMedia()
          .then((data) => {
            this.loading = false
            this.updateMedia(data)
            EventBus.$emit('mediaReplace', data)
          }).catch(err => {
            console.log('Add media error')
            console.log(err)
          })
      } else if (this.search.type === 'idea') {
        const newMedia = this.selectedMedia.map(m => {
          this.$set(m, 'new', true)
          this.$set(m, 'ordinalPosition', false)
          this.$emit('add', m)
          return m
        })
        this.mediaList.push(...newMedia) // merge the two arrays
        this.selectedMedia = []
        this.selectedPreview = this.mediaList[this.mediaList.length - 1]
      }
    },
    updateMedia (item) {
      item.new = true
      this.mediaList.push(item)
      this.$emit('add', item)
      this.getRecentMedia()
      this.resetUploadDefaults()
      this.cancelMedia()
      this.selectedPreview = this.mediaList[this.mediaList.length - 1]
    },
    cancelMedia () {
      if (this.search.type === 'existing' || this.search.type === 'idea') {
        this.selectedMedia = []
      }
      this.resetUploadDefaults()
    },
    setupModal () {
      this.getDocumentTypes()
      if (this.document) {
        this.selectedPreview = this.mediaList.length ? this.mediaList[0] : {}
        this.modal.message = 'Upload Document. Common file types for documents are allowed (.pdf, .msword, .xls, .csv, etc.).'
      } else {
        this.modal.message = this.allowedImagesMsg
        this.selectedPreview = this.mediaList.find(media => {
          return media.ordinalPosition === 1
        })
      }
      const companyArray = this.$store.getters.permissions.filter(company => {
        return company.canView || company.contributor || company.isAdmin || company.isEditor
      })
      this.companies = companyArray.map(obj => {
        return obj.company.companyId
      })
      this.getRecentMedia()
      this.$refs.searchUploadMediaModal.open()
    },
    resetState (btn) {
      this.loading = false
      this.showSmallImgMsg = false
      this.resetUploadDefaults()
      this.$emit('update:showModal', false)
      this.cancelMedia()
    }
  },
  components: {
    // cropper: Cropper,
    modal: ModalComponent,
    'media-icon': IconComponent,
    'drop-zone': UploadZoneComponent,
    'uploading-spinner': LoadingComponent,
    UploadImageForm
  }
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/extra/mixins/flexbox.scss";
@import "../../../assets/scss/extra/mixins/scrollbar.scss";
@import "../../../assets/scss/bootstrap-custom-variables.scss";
@import "../../../assets/scss/layout.scss";

.media-modal-container {
  padding: 0px !important;
  background-color: white;
}

.media-layout {
  @include flexbox;
  .media-picker {
    .media-search {
      .nav {
        .nav-link {
          min-width: 8.5rem;
          border-radius: 25px;
          margin-right: 0.675rem;
          &:not(.active) {
            background: #eee;
            &:hover {
              background: darken(#eee, 10%);
            }
          }
          &.active:hover {
            color: $white;
            background: darken($blue, 5%);
          }
        }
      }
      .search-box {
        position: relative;
        @include flex(1, 1, 35%);
        @include align-self(center);
        .disabled {
          cursor: not-allowed;
          & ~ .search-icon {
            cursor: not-allowed;
          }
        }
        .search-icon {
          position: absolute;
          right: 0.5rem;
          top: 50%;
          margin-top: -1rem;
          font-size: 1.125rem;
        }
      }
    }
    .upload-section {
      .video-upload,
      .document-upload {
        height: 100%;
        @include flexbox;
        @include align-items(center);
        @include flex-direction(column);
        @include justify-content(center);
        .media-logo {
          .file-name {
            font-size: 1.125rem;
            color: lighten($gray-600, 10%);
            font-weight: bold;
          }
        }
      }
    }
    .media-list {
      height: 100%;
      &.idea {
        height: 77%;
      }

      padding: 0 0.5rem;
      overflow-y: scroll;
      max-height: calc(675px - 57.5px);
      @include flexbox;
      @include flex-wrap(wrap);
      @include align-content(flex-start);
      @include scrollbar(0.75rem, 1.5rem, #eee);
      .media-card {
        cursor: pointer;
        position: relative;
        height: 12rem;
        margin: 0.5rem;
        max-width: calc(100% / 6 - 1rem);
        @include flex(0, 1, 8.75rem);
        &.media-added {
          opacity: 0.5;
          &:after {
            content: "";
            background: rgba(0, 0, 0, 0.125);
            cursor: not-allowed;
            position: absolute;
            z-index: 10;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
          }
        }
        .media-check {
          position: absolute;
          top: -0.25rem;
          right: -0.5rem;
          z-index: 1;
          &:hover ~ .card:not(.card-selected) {
            border-color: $gray-500;
          }
          .custom-control {
            cursor: pointer;
            margin: 0;
            .custom-control-input {
              top: 0;
              right: 0;
            }
          }
        }
        .card {
          height: 100%;
          overflow: hidden;
          @include align-items(center);
          &:not(.card-selected):hover {
            transition: all 100ms;
            border-color: $gray-500;
          }
          &.card-selected {
            transition: all 100ms;
            border-color: $blue;
          }
          .card-media {
            width: 100%;
            @include flex(0, 1, 4rem);
          }
          .media-details {
            width: 100%;
            padding: 0.25rem;
            font-size: 0.8125rem;
            @include flex(1, 1, 50%);
            .media-type {
              max-width: 100%;
              height: 0.75rem * 1.5; /* Fallback for non-webkit */
              font-size: 0.75rem;
              line-height: 1.5;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              padding: 0 0.5rem;
              font-weight: bold;
              font-style: italic;
              color: #999;
              text-transform: uppercase;
              text-decoration: underline;
            }
            .media-name {
              font-weight: bold;
              color: $gray-800;
            }
            .media-mfr {
              max-width: 100%;
              height: 0.75rem * 1.5; /* Fallback for non-webkit */
              font-size: 0.75rem;
              line-height: 1.5;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              padding: 0 0.5rem;
              font-style: italic;
              color: $gray-600;
            }
            .media-date {
              color: $gray-600;
              padding: 0 0.5rem;
              font-size: 0.75rem;
            }
          }
        }
      }
      .idea-card {
        cursor: pointer;
        position: relative;
        height: 12rem;
        margin: 0.5rem;
        max-width: calc(100% / 6 - 1rem);
        @include flex(0, 1, 8.75rem);
        &.media-added {
          opacity: 0.5;
          &:after {
            content: '';
            background: rgba(0, 0, 0, 0.125);
            cursor: not-allowed !important;
            position: absolute;
            z-index: 10;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            height: 7.2rem;
          }
        }
        .media-check {
          position: absolute;
          top: -0.25rem;
          right: -0.5rem;
          z-index: 1;
          &:hover ~ .card:not(.card-selected) {
            border-color: $gray-500;
          }
          .custom-control {
            cursor: pointer;
            margin: 0;
            .custom-control-input {
              top: 0;
              right: 0;
            }
          }
        }
        .card {
          height: 100%;
          overflow: hidden;
          @include align-items(center);
          &:not(.card-selected):hover {
            transition: all 100ms;
            border-color: $gray-500;
          }
          &.card-selected {
            transition: all 100ms;
            border-color: $blue;
          }
          .card-media {
            width: 100%;
            @include flex(0, 1, 4rem);
          }
          .media-details {
            width: 100%;
            padding: 0.25rem;
            font-size: 0.8125rem;
            @include flex(1, 1, 50%);
            .media-type {
              max-width: 100%;
              height: 0.75rem * 1.5; /* Fallback for non-webkit */
              font-size: 0.75rem;
              line-height: 1.5;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              padding: 0 0.5rem;
              font-weight: bold;
              font-style: italic;
              color: #999;
              text-transform: uppercase;
              text-decoration: underline;
            }
            .media-name {
              font-weight: bold;
              color: $gray-800;
            }
            .media-mfr {
              max-width: 100%;
              height: 0.75rem * 1.5; /* Fallback for non-webkit */
              font-size: 0.75rem;
              line-height: 1.5;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              padding: 0 0.5rem;
              font-style: italic;
              color: $gray-600;
            }
            .media-date {
              color: $gray-600;
              padding: 0 0.5rem;
              font-size: 0.75rem;
            }
          }
        }
      }
    }

    .fixed-media-footer {
      @include flexbox;
      background: $white;
      border-top: 1px solid #eaeaea;
      padding: 1rem;
      .counter {
        @include align-self(center);
      }
      .actions {
        @include flex(1, 1, 80%);
        @media (max-width: $mobile-view) {
          @include flex(1, 1, 93%);
        }
        .btn {
          min-width: 8rem;
          border-radius: 25px;
        }
      }
    }
  }

  .media-existing {
    border-left: 1px solid #eaeaea;
    .media-list {
      .media-preview {
        margin-bottom: 0.125rem;
        .img-wrap {
          min-height: 9.375rem;
        }
        .preview-name {
          color: $gray-600;
        }
        .new-tag,
        .featured-tag {
          position: absolute;
          top: 1rem;
          padding: 0 0.5rem;
          font-size: 0.75rem;
          text-transform: uppercase;
          color: $white;
        }
        .new-tag {
          left: 0.75rem;
          background: $brand-success;
        }
        .featured-tag {
          right: 0.75rem;
          background: $brand-primary;
        }
        .featured-actions {
          @include flexbox;
          @include justify-content(space-between);
          padding: 0 0 0.75rem 0.75rem;
          .btn {
            font-size: 0.75rem;
            padding: 0.25rem 0.75rem;
            line-height: 1;
          }
        }
      }
      .list-group {
        height: 27rem;
        overflow-y: scroll;
        @include scrollbar(0.75rem, 1.5rem, #eee);
        @media (max-width: $mobile-view) {
          height: 6rem;
        }
        .list-group-item {
          padding: 0.75rem;
          border-left: 0;
          border-right: 0;
          &.active-light {
            background-color: #d3eaf3;
            &:after {
              content: "active";
              position: absolute;
              bottom: 0.5rem;
              right: 0.75rem;
              font-weight: bold;
              color: $cyan;
            }
          }
          .media-item-wrap {
            .media-info {
              .media-name {
                font-weight: bold;
                font-size: 0.8125rem;
                color: $gray-800;
              }
              .media-type {
                text-transform: uppercase;
                text-decoration: underline;
                font-weight: bold;
                color: $cyan;
                font-size: 0.75rem;
              }
              .media-date {
                font-size: 0.75rem;
                color: $gray-600;
              }
            }
            .media-main {
              .btn {
                font-size: 0.75rem;
                padding: 0.25rem 0.5rem;
                line-height: 1;
              }
            }
          }
        }
      }
    }
  }
}
</style>
