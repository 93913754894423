<template>
  <div class="doughnut-chart-container">
    <slot name="header"></slot>
    <div class="doughnut-chart-wrap flex-row">
      <div class="doughnut-chart">
        <doughnut-chart-base
          :chart-data="chartData"
          :options="mergedOptions"
          @generateLegend="renderLegend">
        </doughnut-chart-base>
      </div>
      <div
        id="chartLegend"
        class="chart-legend"
        v-html="legendHtml"></div>
    </div>
  </div>
</template>

<script>
import DoughnutChartBase from './DoughnutChartBase'
export default {
  name: 'DoughnutChart',
  components: {
    DoughnutChartBase
  },
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    chartData: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      legendHtml: '',
      defaultOptions: {
        responsive: true,
        legend: {
          display: false
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        tooltips: {
          displayColors: false,
          callbacks: {
            label: (tooltipItem, data) => {
              const dataset = data.datasets[tooltipItem.datasetIndex]
              const total = dataset.data.reduce((previousValue, currentValue, currentIndex, array) => {
                return previousValue + currentValue
              })
              const currentValue = dataset.data[tooltipItem.index]
              const percentage = Math.floor(((currentValue / total) * 100) + 0.5)
              return percentage + '%'
            }
          }
        },
        onClick: (event, click) => {
          this.$emit('click', { event, click })
        }
      }
    }
  },
  computed: {
    mergedOptions () {
      return { ...this.defaultOptions, ...this.options }
    }
  },
  methods: {
    renderLegend (legendHtml) {
      this.legendHtml = legendHtml.replaceAll('&lt;', '<').replaceAll('&gt;', '>') // terrible, never do it again
    }
  }
}
</script>

<style lang="scss">
.doughnut-chart-container {
  display: flex;
  flex-direction: column;
}

.doughnut-chart-wrap {
  display: flex;
  align-items: center;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  flex-grow: 1;
}

.doughnut-chart {
  flex-grow: 1;

  canvas {
    width: 100%;
  }
  cursor: pointer;
}

.chart-legend {
  flex: 0 0 auto;
  margin-left: 15px;

  ul {
    list-style-type: none;
    font-size: 14px;
    padding: 0;
    margin-bottom: 0;
  }

  span {
    display: inline-block;
    // vertical-align: middle;
    margin-right: 5px;
    width: 12px;
    height: 12px;
    border-radius: 100%;
  }
}
</style>
