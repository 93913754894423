<template>
  <nav
    aria-label="breadcrumb"
    role="navigation"
    class="nav-categories">
    <ol class="breadcrumb">
      <li
        class="breadcrumb-item"
        @click="home()">
        <i class="material-icons">
          home
        </i>
      </li>
      <template v-if="tree.section && sectionId">
        <router-link
          :to="{name: 'categoryChildSection'}"
          tag="li"
          class="breadcrumb-item">
          {{ tree.section.name }}
        </router-link>
      </template>
      <template v-if="tree.group && groupId">
        <router-link
          :to="{name: 'categoryChildGroup', params: {sectionId: sectionId}}"
          tag="li"
          class="breadcrumb-item">
          {{ tree.group.name }}
        </router-link>
      </template>
      <template v-if="tree.category && categoryId">
        <router-link
          :to="{name: 'categoryChildCategory', params: {sectionId: sectionId, groupId: groupId}}"
          tag="li"
          class="breadcrumb-item">
          {{ tree.category.name }}
        </router-link>
      </template>
      <template v-if="tree.subCategory && subCategoryId">
        <router-link
          :to="{name: 'categoryChildSubCategory', params: {sectionId: sectionId, groupId: groupId, categoryId: categoryId}}"
          tag="li"
          class="breadcrumb-item">
          {{ tree.subCategory.name }}
        </router-link>
      </template>
    </ol>
  </nav>
</template>
<script>
export default {
  name: 'Breadcrumb',
  props: [
    'tree',
    'sectionId',
    'groupId',
    'categoryId',
    'subCategoryId'
  ],
  methods: {
    getParams (key) {
      if (key === 'section') {
        return {}
      } else if (key === 'group') {
        return {
          sectionId: this.sectionId
        }
      } else if (key === 'category') {
        return {
          sectionId: this.sectionId,
          groupId: this.groupId
        }
      } else if (key === 'subCategory') {
        return {
          sectionId: this.sectionId,
          groupId: this.groupId,
          categoryId: this.categoryId
        }
      }
    },
    home () {
      this.$emit('home', null)
      this.$router.push({ name: 'categoryChildSection' })
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../../../assets/scss/bootstrap-custom-variables";
  .nav-categories {
    .breadcrumb {
      background: #fff;
      margin-bottom: .75rem;
      padding: 0 0 0 1rem;

      .breadcrumb-item {
        color: #333;
        display: block;
        background: $gray-lighter;
        text-decoration: none;
        position: relative;
        height: 2rem;
        line-height: 2rem;
        padding: 0 .75rem 0 .5rem;
        text-align: center;
        margin-right: 1.5rem;
        font-size: .8125rem;
        z-index: 1;
        &:hover {
          cursor: pointer;
          color: $brand-primary;
          background: darken($gray-lighter, 5%);
          &:before {
            border-color: darken($gray-lighter, 5%);
          }
          &:after {
            border-left-color: darken($gray-lighter, 5%);
          }
        }
        &:before,
        &:after {
          content: "";
          position: absolute;
          top: 0;
          border-color: $gray-lighter;
          border-style: solid;
          border-width: 1rem .675rem;
          width: 0;
          height: 0;
          z-index: -1;
        }

        &:before {
          left: -1rem;
          border-left-color: transparent !important;
        }

        &:after {
          left: 100%;
          border-color: transparent;
          border-left-color: $gray-lighter;
        }
      }
    }
  }
</style>
