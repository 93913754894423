<template>
  <div>
    <div
      v-if="item && item.napmID"
      class="content-container">
      <div
        v-if="showProductDetails"
        class="product-container mb-4">
        <div class="img-fluid product-img-container mr-3">
          <img
            v-if="item.folderName"
            :src="'https://rexel-cdn.com/products/' + item.folderName + '/150.jpg'" />
          <img
            v-else
            src="https://images.plattstatic.com/ProductImages/thumbnail/Nonstock.gif" />
        </div>
        <div class="product-desc-container w-100">
          <div class="d-flex justify-content-between align-items-center">
            <div class="m-0">
              Name:
              <span class="prod-detail">
                {{ item.napmID }}
              </span>
            </div>
            <div class="m-0">
              <a
                class="text-primary link"
                @click="viewAll">
                View All Reviews
              </a>
            </div>
          </div>
          <div>
            NAPM#:
            <span class="prod-detail">
              {{ item.catNum }}
            </span>
          </div>
          <div>
            UPC:
            <span class="prod-detail">
              {{ item.upc || 'N/A' }}
            </span>
          </div>
          <!-- <div>Company:
            <span class="prod-detail"></span>
          </div> -->
        </div>
      </div>
      <div class="edit-slang-terms">
        <div v-if="showProductDetails">
          <hr />
          <h5>Reviews</h5>
        </div>
        <div class="d-flex justify-content-between align-items-center mb-2">
          <div>
            <div>{{ item.createUser }}</div>
            <div>{{ item.userLocation }}</div>
          </div>
          <div class="text-right">
            <div v-html="reviewStatus">
            </div>
            <div class="text-muted">
              {{ item.createDateFormatted }}
            </div>
            <div>
              <h4>
                <i
                  v-for="n in 5"
                  :key="n + 'review-details-star-rating'"
                  class="material-icons"
                  :class="{ 'muted-star': n > item.rating }">
                  grade
                </i>
              </h4>
            </div>
          </div>
        </div>
        <div class="mb-1">
          <div><strong>Subject:</strong></div>
          <div>{{ item.subject }}</div>
        </div>
        <div class="mb-1">
          <div><strong>Comment:</strong></div>
          <div>{{ item.comments }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import moment from 'moment'

export default {
  name: 'Review',
  components: {
  },
  props: {
    item: Object,
    showProductDetails: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    reviewStatus () {
      if (this.item.status === 'N') {
        return '<strong>Pending</strong>'
      } else if (this.item.status === 'D') {
        return '<strong class="text-danger">Denied</strong>'
      } else {
        return '<strong class="text-primary">Approved</strong>'
      }
    },
    dateFormatted () {
      return moment(this.item.createDate, 'YYYYMMDDHH:mmA').format('MM/DD/YYYY HH:mm A')
    }
  },
  watch: {
  },
  created () {
  },
  methods: {
    viewAll () {
      this.$emit('viewall')
      this.$router.push({ name: 'productReviews', params: { id: this.item.napmID, product: this.item } })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/scss/bootstrap.scss';
@import '../../../assets/scss/extra/mixins/flexbox.scss';
@import '../../../assets/scss/bootstrap-custom-variables.scss';

.product-container {
  @include flexbox;
  @include align-items(center);

  .product-desc-container {
    font-size: 16px;
    div {
      margin: 5px 0;
    }

    .prod-detail {
      display: inline-block;
      font-weight: bold;
      margin-left: 5px;
    }
  }

  .product-img-container {
    min-width: 60px;
    border: 1px solid #ccc;
    background-color: #fff;
    text-align: center;
    padding: 5px;
  }

  img {
    width: 100%;
    max-height: 150px;
  }
}

.content-container {
  font-size: 14px;
}

.link {
  text-decoration: underline !important;
  font-size:14px;
}

.muted-star {
  color: #cfcfcf;
}

</style>
