<template>
  <modal
    ref="crossSectionModal"
    :title="title"
    @close="resetModal()">
    <div class="cross-section">
      <div class="form-group search-products">
        <label class="form-control-label">
          Look up a product
        </label>
        <autocomplete
          response-data="products"
          @hit="addProduct($event)"></autocomplete>
      </div>
      <div
        v-if="addedProducts.length > 0"
        class="added-products-wrap">
        <h6>Added Items</h6>
        <ul class="added-products-list">
          <li
            v-for="(prod, index) in addedProducts"
            :key="'added-product-' + prod.napmId">
            <div class="prod-img">
              <img
                v-if="prod.mainImage"
                :src="`https://rexel-cdn.com/products/${prod.catNum}.jpg?i=${prod.mainImage}&f=50`" />
              <img
                v-else
                :src="`https://rexel-cdn.com/products/${prod.catNum}.jpg?i=0EA7A6C5-7DD2-4482-9229-D628D3AFAE9D&f=50`" />
            </div>
            <div class="prod-info">
              <div class="row-one">
                {{ prod.catNum }}
              </div>
              <div class="row-two">
                <span>NAPM#: {{ prod.napmId }}</span>
                <span v-if="prod.mfrName">
                  MFR: {{ prod.mfrName }}
                </span>
              </div>
              <div class="row-three">
                {{ prod.description }}
              </div>
            </div>
            <div class="cross-check">
              <label class="form-control-label">
                Cross Filled
              </label>
              <toggle
                :id="'check-toggle-cross-' + index"
                color="primary"
                :is-checked.sync="prod.crossFilled"></toggle>
            </div>
            <div class="remove-prod">
              <a @click="removeProduct(prod, index)">
                <i class="material-icons">
                  clear
                </i>
              </a>
            </div>
          </li>
        </ul>
      </div>
      <div
        v-else
        class="alert alert-info">
        No products added. To {{ title | lowercase }} products, please search for a product to add.
      </div>
    </div>
    <div
      slot="button"
      class="footer-btns">
      <button
        type="button"
        class="btn btn-default"
        @click="$refs.crossSectionModal.close()">
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click="commitProducts()">
        Save
      </button>
    </div>
  </modal>
</template>
<script type="text/javascript">
import ModalComponent from 'components/modals/sweet.vue'
import ToggleComponent from 'components/inputs/toggleSwitch.vue'
import AutocompleteInput from '@/components/products/search/AutocompleteInput'

export default {
  components: {
    modal: ModalComponent,
    toggle: ToggleComponent,
    autocomplete: AutocompleteInput
  },
  props: {
    title: String,
    show: {
      default: false,
      type: Boolean,
      required: true
    },
    crosstype: String,
    crossid: Number
  },
  data () {
    return {
      addedProducts: []
    }
  },
  computed: {
    addedIds () {
      const idList = this.addedProducts.map(prod => {
        return prod.napmId
      })
      return idList
    }
  },
  watch: {
    show (value) {
      if (value) {
        this.$refs.crossSectionModal.open()
      } else {
        this.$refs.crossSectionModal.close()
      }
    }
  },
  methods: {
    resetModal () {
      this.$emit('update:show', false)
      this.addedProducts = []
    },
    commitProducts () {
      this.$emit('save', this.addedProducts)
      this.$refs.crossSectionModal.close()
    },
    prodIsNew (prod) {
      if (this.addedIds.length > 0) {
        return this.addedIds.every(id => {
          return prod.napmId !== id
        })
      } else {
        return true
      }
    },
    addProduct (product) {
      const prod = {
        napmId: product.napmId,
        catNum: product.catNum,
        mfrName: product.manufacturer ? product.manufacturer.name : null,
        mainImage: product.mainImage,
        displayOrder: 0,
        crossFilled: false,
        crossSellTypeId: this.crossid,
        crossSellType: this.crosstype,
        description: product.description
      }
      const newProd = this.addedProducts.length === 0
        ? true
        : this.addedProducts.some(p => {
          return p.napmId !== product.napmId
        })
      if (newProd) {
        this.addedProducts.push(prod)
      }
    },
    removeProduct (product, index) {
      this.addedProducts.splice(index, 1)
    }
  }
}

</script>
<style lang="scss" scoped>
@import '../../../../assets/scss/extra/mixins/flexbox';
@import '../../../../assets/scss/bootstrap-custom-variables';
@import '../../../../../node_modules/bootstrap/scss/bootstrap';

.cross-section {
  min-height: 22rem;
}

.footer-btns {
  padding: 0.25rem 0.5rem;
}

.search-products {
  position: relative;
  ul.product-result-list {
    list-style: none;
    position: absolute;
    top: 3.95rem;
    background: #fff;
    width: 527px;
    max-height: 250px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding-left: 0;
    overflow-y: scroll;
    z-index: 99;
    .product-result {
      &:not(:first-child) {
        border-top: 1px solid rgba(0, 0, 0, 0.15);
      }
      &:hover {
        cursor: pointer;
        background: #eee;
        .result-action .btn {
          @extend .btn-primary;
          @include transition(none);
        }
      }
      @include flexbox;
      .result-img {
        @include flex(0, 1, 3.75rem);
        @include align-self(center);
        text-align: center;
        > img {
          display: inline-block;
        }
      }
      .result-info {
        @include flex(1, 1, 75%);
        @include align-self(center);

        padding: 0.25rem 0.5rem;
        .row-one,
        .row-two {
          font-weight: 500;
          font-size: 0.875rem;
          text-transform: uppercase;
          > span {
            padding-right: 0.75rem;
          }
        }
        .row-two {
        }
        .row-three {
          color: #999;
          width: 21.75rem;
          font-size: 0.75rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .result-action {
        @include flex(0, 1, 3.75rem);
        @include align-self(center);
        text-align: center;
      }
    }
  }
}

.added-products-wrap {
  > h6 {
  }
  ul.added-products-list {
    list-style: none;
    padding-left: 0;
    border: 1px solid rgba(0, 0, 0, 0.15);
    li {
      @include flexbox;
      &:not(:first-child) {
        border-top: 1px solid rgba(0, 0, 0, 0.15);
      }
      .prod-img {
        @include flex(0, 1, 3.75rem);
        @include align-self(center);
        text-align: center;
        img {
          display: inline-block;
        }
      }
      .prod-info {
        @include flex(1, 1, 75%);
        padding: 0.25rem 0.5rem;
        .row-one,
        .row-two {
          font-weight: 500;
          font-size: 0.875rem;
          text-transform: uppercase;
          > span {
            padding-right: 0.75rem;
          }
        }
        .row-three {
          color: #999;
          width: 21.75rem;
          font-size: 0.75rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .cross-check {
        @include flex(0, 1, 4.75rem);
        @include align-self(center);
      }
      .remove-prod {
        @include flex(0, 1, 3rem);
        @include align-self(center);
        text-align: center;
        a {
          display: block;
          font-size: 1.5rem;
          color: $brand-danger;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
