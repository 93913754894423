<template>
  <section id="documents">
    <div class="section-heading">
      <h6>Documents:</h6>
      <idw-button
        title="Documents"
        section="documents"
        :loaded="idwLoaded"
        :has-data="idwUrls != null"
        :btn-name="'View IDW'"
        @openDialog="$refs.idwUrlsDialog.open()"></idw-button>
    </div>
    <div class="section-body">
      <div class="documents-container">
        <div
          v-for="(doc, index) in documents"
          :key="'doc-' + doc.documentId">
          <a
            :href="documentUrl(doc)"
            target="_blank">
            <div class="document">
              <a
                class="remove-doc"
                @click.prevent="removeDocument(doc, index)">
                <i class="material-icons">
                  &#xE5CD;
                </i>
              </a>
              <span
                v-if="doc.new"
                class="doc-new badge badge-success">NEW</span>
              <div class="doc-icon">
                <doc-icon :class-attr="doc.fileName.split('.')[1]"></doc-icon>
              </div>
              <p class="doc-type">
                {{ doc.documentType }} {{ doc.languageId === 2 ? '- ES' : doc.languageId === 3 ? '- FR' : null }}
              </p>
              <div class="doc-name-wrap">
                <span class="doc-name">
                  {{ doc.name }}
                </span>
              </div>
            </div>
          </a>
        </div>
        <div
          v-if="documents.length"
          class="new-document"
          @click="documentUpload.dialog = true">
          <label class="new-document-label">
            Documents
          </label>
          <button
            type="button"
            class="btn btn-primary btn-rounded btn-sm">
            Add/Edit
          </button>
        </div>
        <div
          v-else
          class="no-documents">
          <p>No documents have been added</p>
          <button
            type="button"
            class="btn btn-primary"
            @click="documentUpload.dialog = true">
            Add Documents
          </button>
        </div>
      </div>
    </div>
    <upload-media
      :show-modal.sync="documentUpload.dialog"
      :media-list="documents"
      :document="true"
      :mfr="mfr"
      @add="addDocument($event)"
      @remove="removeDocument($event.media, $event.index)"></upload-media>

    <sweet-modal
      v-if="idwUrls"
      ref="idwUrlsDialog"
      title="Urls">
      <idw-urls
        :urls="idwUrls"
        @onSelected="applyIdwUrls($event)"></idw-urls>
    </sweet-modal>
  </section>
</template>
<script type="text/javascript">
import UploadMedia from '../../media/upload/uploadSearch.vue'
import DocumentIconComponent from 'components/icons/documentIcon.vue'
import SweetModal from 'components/modals/sweet.vue'
import IdwButton from 'components/products/edit/idw/Button'
import IdwUrls from 'components/products/edit/idw/Urls'

export default {
  components: {
    'idw-button': IdwButton,
    'idw-urls': IdwUrls,
    'sweet-modal': SweetModal,
    'upload-media': UploadMedia,
    'doc-icon': DocumentIconComponent
  },
  props: {
    mfr: Number,
    hasIdw: Number,
    loaded: Boolean,
    language: Number,
    documents: Array,
    addedDocuments: Array,
    removedDocuments: Array,
    idwUrls: Object,
    idwLoaded: Boolean
  },
  data () {
    return {
      documentUpload: {
        dialog: false,
        documentTypes: [],
        fileType: '0',
        fileInputTxt: '',
        fileInputVal: null,
        showError: false,
        errorMessage: ''
      },
      showUndoIdwButton: false
    }
  },
  methods: {
    addDocument (doc) {
      this.addedDocuments.push(doc.mediaId)
    },
    removeDocument (doc, docIndex) {
      const docId = doc.mediaId
      let isNewDocument = false

      this.documents.splice(docIndex, 1)
      this.addedDocuments.forEach((id, index) => {
        if (docId === id) {
          this.addedDocuments.splice(index, 1)
          isNewDocument = true
        }
      })

      if (!isNewDocument) {
        this.removedDocuments.push(docId)
      }
    },
    documentUrl (doc) {
      return `https://rexel-cdn.com/products/${doc.fileName}?i=${doc.folderName}`
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/extra/mixins/flexbox.scss";
@import "../../../assets/scss/bootstrap-custom-variables.scss";

label.input-group-btn {
  margin-bottom: 0;
}

section {
  border: 1px solid #c9c9c9;
  background: #eee;
  margin: 2rem 0;
  .section-heading {
    border-bottom: 0;
    position: relative;
    h6 {
      padding: 0 1.25rem;
    }
  }
}

.section-body {
  padding: 0 1rem 0.5rem;
  @media (min-width: 37.5rem) {
  }
}

.documents-container {
  padding: 1rem 0.5rem;
  margin: 0 -1rem;
  @include flexbox;
  @include flex-wrap(wrap);
  @include justify-content(flex-start);
  a {
    text-decoration: none;
    &:hover > .document {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
      border: 1px solid #bbb;
    }
  }
  .document {
    @include flex(0, 1, 8rem);
    width: 8rem;
    height: 12.5rem;
    margin: 0 0.5rem;
    position: relative;
    border: 1px solid #c9c9c9;
    background: #fff;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0);
    transition: box-shadow 0.2s ease, border 0.3s ease;
    .remove-doc {
      position: absolute;
      top: 0.125rem;
      right: 0.25rem;
      color: $brand-danger;
      &:hover {
        color: lighten($brand-danger, 10%);
      }
    }
    .doc-new {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 0.625rem;
      padding: 0.25rem 0.5rem;
    }
    .doc-icon {
      margin-top: 1.5rem;
      margin-bottom: 1rem;
      padding: 0 0.25rem;
      i {
        font-size: 2.1875rem;
      }
    }
    .doc-type {
      display: block; /* Fallback for non-webkit */
      display: -webkit-box;
      max-width: 100%;
      height: 0.75rem * 1.5 * 2; /* Fallback for non-webkit */
      color: $brand-primary;
      font-weight: 600;
      margin: 0;
      padding: 0 0.25rem 0.25rem;
      font-size: 0.75rem;
      line-height: 1.5;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .doc-name-wrap {
      padding: 0 0.325rem 0.25rem;
      > span {
        color: $gray;
        display: block; /* Fallback for non-webkit */
        display: -webkit-box;
        max-width: 100%;
        height: 0.75rem * 1.25 * 2; /* Fallback for non-webkit */
        line-height: 1.25;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 0.75rem;
      }
    }
  }

  .new-document {
    border: 1px solid #c9c9c9;
    background: #d9d9d9;
    width: 8rem;
    height: 12.5rem;
    padding: 0.5rem;
    margin: 0 0.5rem;
    @include flex(0, 1, 8rem);
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(center);
    .new-document-label {
      display: block;
      font-weight: 600;
      color: #303030;
      font-size: 0.875rem;
      text-align: center;
      @include flex(0, 1, 2rem);
      @include align-self(center);
    }
  }

  .no-documents {
    color: #333;
    text-align: center;
    font-size: 0.875rem;
    margin-bottom: 1rem;
    @include flex(1, 1, 100%);
    .btn {
      padding: 0.5rem 1.25rem;
      font-size: 0.875rem;
    }
  }
}
</style>
