export default {
  approved: [
    {
      text: 'Product',
      value: 'catNum',
      width: '25%'
    },
    {
      text: 'Slang Terms',
      value: 'submittedTerm',
      sortable: false,
      width: '50%'
    },
    {
      text: 'Submitted On',
      value: 'submittedToCompanyName',
      width: '18%'
    },
    {
      text: '',
      width: '7%',
      sortable: false,
      align: 'center'
    }
  ],
  pending: [
    {
      text: 'Product',
      value: 'catNum',
      width: '25%'
    },
    {
      text: 'Submitted Slang Term',
      value: 'submittedTerm',
      width: '25%'
    },
    {
      text: 'Slang Terms',
      sortable: false,
      width: '13%'
    },
    {
      text: 'Submitted Date',
      value: 'submittedDate',
      width: '15%'
    },
    {
      text: 'Submitted On',
      value: 'submittedToCompanyName',
      width: '15%'
    },
    {
      text: '',
      width: '7%',
      sortable: false
    }
  ],
  denied: [
    {
      text: 'Product',
      value: 'catNum',
      width: '30%'
    },
    {
      text: 'Submitted Slang Term',
      value: 'submittedTerm',
      width: '30%'
    },
    {
      text: 'Submitted Date',
      value: 'submittedDate',
      width: '15%'
    },
    {
      text: 'Submitted On',
      value: 'submittedToCompanyName',
      width: '15%'
    },
    {
      text: '',
      width: '10%',
      sortable: false,
      align: 'center'
    }
  ]
}
