<template>
  <div class="user-detail">
    <div class="row my-3">
      <div class="col">
        <page-title :title="`User Profile`"></page-title>
      </div>
      <div class="col text-right pt-3">
        <button
          v-if="$store.getters.user.userId === userDetails.userId"
          type="button"
          class="btn btn-rounded"
          :class="[$store.getters.admin ? 'btn-gray-alt' : 'btn-primary']"
          @click="showPreferences = true">
          Edit Preferences
        </button>
        <button
          v-if="$store.getters.admin && (userDetails.emailAddress != $store.getters.user.email)"
          type="button"
          class="btn btn-rounded btn-primary"
          @click="editUser()">
          Edit Permissions
        </button>
      </div>
    </div>
    <section class="card-group mb-3">
      <div class="card user-info">
        <div class="card-header">
          You
        </div>
        <div class="card-block">
          <div class="user-avatar">
            <avatar
              class="desktop"
              :username="userDetails.displayName"
              :size="100"></avatar>
            <avatar
              class="mobile"
              :username="userDetails.displayName"
              :size="75"></avatar>
          </div>
          <div class="user-name">
            <h4 class="card-title">
              {{ userDetails.displayName }}
            </h4>
            <ul class="card-text list-unstyled">
              <li
                v-for="role in userRoles"
                :key="role">
                {{ role }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card user-team">
        <div class="card-header">
          Team
        </div>
        <div class="card-block">
          <h4 class="card-title">
            {{ userDetails.teamName }}
          </h4>
        </div>
      </div>
      <div class="card user-month">
        <div class="card-header">
          This Month
        </div>
        <div class="card-block">
          <h4 class="card-title">
            {{ monthTotal }}
          </h4>
        </div>
      </div>
      <div class="card user-total">
        <div class="card-header">
          Enriched
        </div>
        <div class="card-enriched-totals">
          <strong>
            <p>Level 1 - {{ Math.ceil(levelEnrichments.level1) }}</p>
          </strong>
          <strong>
            <p>Level 2 - {{ Math.ceil(levelEnrichments.level2) }}</p>
          </strong>
          <strong>
            <p>Level 3 - {{ Math.ceil(levelEnrichments.level3) }}</p>
          </strong>
          <strong>
            <p>Level 4 - {{ Math.ceil(levelEnrichments.level4) }}</p>
          </strong>
          <strong>
            <p>Level 5 - {{ Math.ceil(levelEnrichments.level5) }}</p>
          </strong>
          <strong>
            <p>Total - {{ Math.ceil(totalEnriched) }}</p>
          </strong>
        </div>
      </div>
    </section>
    <section class="charts">
      <div class="row wrap">
        <div class="col-md-12 col-lg-5 col-xl-4 my-3">
          <div class="card chart-wrap">
            <div class="card-block">
              <doughnut-chart
                class="chart"
                :chart-dataset="doughnutChartData"
                :chart-ready="doughnutRender"></doughnut-chart>
            </div>
          </div>
        </div>
        <div class=" col-md-12 col-lg-7 col-xl-8 my-3">
          <div class="card chart-wrap">
            <div class="card-block">
              <line-chart
                class="chart"
                :chart-labels="chartMonths"
                :chart-dataset="lineChartData"></line-chart>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--Preferences-->
    <preferences :show.sync="showPreferences"></preferences>
    <!--Edit Modal-->
    <user-edit
      :show-modal.sync="showModal"
      :user.sync="userDetails"
      @userUpdated="getUserDetails($event)"></user-edit>
  </div>
</template>
<script type="text/javascript">
import axios from 'axios'
import UserEdit from './edit.vue'
import Preferences from './preferences.vue'
import UserAvatar from 'components/UserAvatar.vue'
import Title from 'components/pageTitle.vue'
import LineChartComponent from './lineChart'
import DoughnutChartComponent from './doughnutChart'

export default {
  components: {
    avatar: UserAvatar,
    'page-title': Title,
    'user-edit': UserEdit,
    preferences: Preferences,
    'line-chart': LineChartComponent,
    'doughnut-chart': DoughnutChartComponent
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      vm.getUserDetails(to.params.id)
    })
  },
  data () {
    return {
      showModal: false,
      showPreferences: false,
      userDetails: {
        displayName: '',
        userPermissions: []
      },
      chartMonths: [],
      lineChartData: [],
      doughnutRender: false,
      doughnutChartData: {
        labels: [],
        data: []
      },
      levelEnrichments: []
    }
  },
  computed: {
    userRoles () {
      const roles = this.userDetails.userPermissions.map((perm) => {
        if (perm.isAdmin) return `${perm.name} / Admin`
        if (perm.isEditor) return `${perm.name} / Editor`
        if (perm.contributor) return `${perm.name} / Contributor`
        if (perm.marketing) return `${perm.name} / Marketing`
        if (perm.canView) return `${perm.name} / Read Only`
        return `${perm.name} / None`
      })
      return roles
    },
    monthTotal () {
      return Math.ceil(this.lineChartData[12])
    },
    totalEnriched () {
      return this.objectValueReducer(this.levelEnrichments)
    }
  },
  methods: {
    objectValueReducer (obj) {
      return Object.values(obj)
        .reduce((i, n) => {
          if (typeof n === 'number') return i + n
          return i
        }, 0)
    },
    languageFlag (value) {
      return this.$store.getters.languageOptions.find((language) => language.id === value).image
    },
    getUserDetails (id) {
      axios.get('/api/users/getUserProfile', { params: { userId: id } }).then(res => {
        const { profileDetails, companyPermissions, monthlyEnrichments, teamEnrichments } = res.data
        this.userDetails = { ...profileDetails, userPermissions: companyPermissions }
        this.setupLineGraph(monthlyEnrichments)
        this.setupPieGraph(teamEnrichments)
        this.levelEnrichments = teamEnrichments[0]
      })
    },
    setupLineGraph (enrichments) {
      const months = []
      const data = enrichments.map((enriched) => {
        const d = new Date(enriched.month)
        const date = d.toDateString().split(' ')
        months.push(date[1] + ' ' + date[3])
        return enriched.productsEnriched
      })
      this.chartMonths = months
      this.lineChartData = data
    },
    setupPieGraph (data) {
      const cartLabels = []
      this.doughnutChartData.data = data.map((obj) => {
        cartLabels.push(obj.youVTeam)
        return this.objectValueReducer(obj)
      })
      this.doughnutChartData.labels = cartLabels
      this.doughnutRender = true
    },
    editUser () {
      this.merge({ data: this.userDetails.userPermissions }, { data: this.$store.getters.companyList })
      this.showModal = true
    },
    merge (one, two) {
      if (!one.data) return { data: two.data }
      if (!two.data) return { data: one.data }

      const final = { data: one.data }
      // merge
      for (let i = 0; i < two.data.length; i++) {
        const item = two.data[i]
        this.insert(item, final)
      }
      return final
    },
    insert (item, obj) {
      const data = obj.data
      let insertIndex = data.length
      for (let i = 0; i < data.length; i++) {
        if (item.companyId === data[i].companyId) {
          // ignore duplicates
          insertIndex = -1
          break
        } else if (item.companyId < data[i].companyId) {
          insertIndex = i
          break
        }
      }
      if (insertIndex === data.length) {
        data.push(item)
      } else if (insertIndex !== -1) {
        data.splice(insertIndex, 0, item)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/scss/extra/mixins/flexbox.scss';
@import '../../../assets/scss/bootstrap-custom-variables.scss';

.user-detail {
  .charts {
    display: none;
  }
  .card {
    min-height: 7rem;
    .card-header {
      color: #999;
      background: #fff;
      text-align: center;
      text-transform: uppercase;
    }
    .card-title {
      margin-bottom: 0;
    }

    &.chart-wrap {
      min-height: 28rem;
      .chart {
        @include flex(1, 1, 100%);
        width: 100%;
      }
    }
    .card-block {
      @include flexbox;
      padding: 1rem;
    }
  }
  .user-info {
    @include flex(1, 0, 20%);
    @include align-self(center);
    .user-avatar {
      @include flex(1, 0, 6.25rem);
      @include align-self(center);
      > .desktop {
        display: none;
      }
    }
    .user-name {
      @include flex(1, 1, 70%);
      @include align-self(center);
      .card-title {
        font-size: 1.125rem;
        margin-bottom: 0.25rem;
      }
      .card-text {
        font-size: 0.75rem;
        color: #999;
      }
    }
  }
  .user-team,
  .user-month,
  .user-total {
    .card-block {
      @include flexbox;
      @include flex(1, 1, 100%);
      // @include flex-direction(column);
      .card-title {
        text-align: center;
        @include flex(1, 1, 100%);
        @include align-self(center);
        margin-bottom: 1rem;
      }
    }
  }

  .card-enriched-totals {
    padding-top: 8px;
    p {
      font-size: 13px;
      margin: 0 auto;
      text-align: center;
    }
  }

  @media (min-width: 68rem) {
    .charts {
      display: block;
    }
    .user-info {
      .user-avatar {
        > .mobile {
          display: none;
        }
        > .desktop {
          display: block;
        }
      }
    }
  }
  .alert {
    margin: 2rem;
  }

  table.table {
    margin-bottom: 0;
    thead th {
      background: #f7f7f9;
    }
    tbody tr {
      &:hover {
        background: #f0fafe;
        cursor: pointer;
      }
      td {
        vertical-align: middle;
        font-size: 0.875rem;
        &:last-of-type {
          width: 12.5rem;
        }
      }
    }
  }
  .list-action {
    @include flexbox;
    @include justify-content(space-between);
    > span {
      @include flex(0, 1, auto);
      @include align-self(center);
    }
  }
  .list-circle-arrow {
    border-radius: 50%;
    background: $brand-primary;
    color: #fff;
    position: relative;
    height: 2.25rem;
    width: 2.25rem;
    display: inline-block;

    .material-icons {
      font-size: 1.75rem;
      position: absolute;
      top: 50%;
      right: 50%;
      margin: -0.875rem -0.975rem 0 0;
    }
  }
}
</style>
