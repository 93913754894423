import axios from 'axios'
import MediaFileTypesMixin from '@/views/media/types/MediaFileTypes.mixin'

export default {
  mixins: [MediaFileTypesMixin],
  data () {
    return {
      videoToUpload: {
        manufacturer: null,
        fileName: '',
        file: '',
        uri: null,
        id: null
      }
    }
  },
  methods: {
    createVideoForUpload (file, name) {
      this.videoToUpload.file = file
      this.videoToUpload.fileName = name
    },
    saveVideo () {
      const data = new FormData()
      data.append('file', this.videoToUpload.file)
      data.append('mfrId', this.videoToUpload.manufacturer)
      data.append('fileName', this.videoToUpload.fileName)
      data.append('uri', this.videoToUpload.uri)

      return axios.post('/api/media/videos', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        return res.data
      })
    },
    replaceVideo () {
      const data = new FormData()
      data.append('file', this.videoToUpload.file)
      data.append('uri', this.videoToUpload.uri)
      data.append('fileName', this.videoToUpload.fileName)
      data.append('languageId', this.videoToUpload.languageId)
      return axios.patch(`/api/media/videos/${this.videoToUpload.id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(res => {
          return res.data
        })
    },
    resetVideoDefaults () {
      this.videoToUpload = {
        manufacturer: '',
        fileName: '',
        file: ''
      }
    }
  }
}
