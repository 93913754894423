<template>
  <section
    id="erp-status">
    <div
      v-if="deletedOnAS400()"
      class="section-heading">
      <h6>Product Deleted From AS400 on {{ model.platt.deletedOnERPUpdateDate }}</h6>
    </div>
    <div
      v-if="deletedOnEclipse()"
      class="section-heading">
      <h6>Product Deleted From Eclipse</h6>
    </div>
  </section>
</template>
<script type="text/javascript">

export default {
  components: {
  },
  props: {
    banners: Array
  },
  data () {
    return {
      model: {
        platt: null,
        rexel: null,
        gexpro: null,
        rexelCanadaBanners: []
      }
    }
  },
  watch: {
    banners (val) {
      this.setPlattData()
      this.setRexelData()
      this.setGexproData()
      this.setRexelCanadaData()
    }
  },
  methods: {
    deletedOnAS400 () {
      if (!this.model.platt) {
        return false
      } else {
        return this.model.platt.deletedOnERP
      }
    },
    deletedOnEclipse () {
      return false
    },
    setPlattData () {
      const index = this.banners.findIndex(i => i.bannerId === 1)
      if (index > -1) this.model.platt = this.banners[index]
    },
    setRexelData () {
      const index = this.banners.findIndex(i => i.bannerId === 2)
      if (index > -1) this.model.rexel = this.banners[index]
    },
    setGexproData () {
      const index = this.banners.findIndex(i => i.bannerId === 3)
      if (index > -1) this.model.gexpro = this.banners[index]
    },
    setRexelCanadaData () {
      // NAPM assumes that any banner above 3 is Canada
      this.model.rexelCanadaBanners = this.banners.filter(i => {
        return i.bannerId !== 1 && i.bannerId !== 2 && i.bannerId !== 3
      })
    }
  }
}
</script>
<style lang="scss" scoped>

.col.wrap {
  flex-wrap: wrap;
}

section.card {
  border: 0;
  margin: 0.5rem 0 1.5rem;
  .card-header {
    padding: 0.75rem 0 0;
    background-color: #fff;
    .card-header-tabs {
      margin: 0;
    }
}
  div.tab-content {
    border: 1px solid #ddd;
    border-top: 0;
    .tab-pane {
      padding: 0.75rem 1rem 0;
    }
    div#product-number-price-cartoptions,
    div#product-family-grouping-market,
    div#product-order-details {
      @media (min-width: 37.5rem) {
      }
      .form-group {
        width: 100%;
        @media (min-width: 37.5rem) {
          width: calc(50% - 1rem);
        }
        @media (min-width: 1200px) {
          width: calc(100% / 3 - 1rem);
        }
        input[readonly],
        input[disabled="disabled"] {
          border: 1px solid transparent;
          background-color: #ececec;
        }
      }
      div#product-cartoptions {
        width: 100%;
      }
    }
  }
}
</style>
