<template>
  <modal
    ref="createUserModal"
    :icon="modal.icon"
    :blocking="modal.blockClose"
    :title="modal.title"
    @close="resetModal()">
    <div
      v-if="showMessage"
      class="modal-message">
      {{ modal.message }}
    </div>
    <div
      v-else
      class="team-builder-modal">
      <div class="form-group row">
        <label
          for="inputEmail"
          class="form-control-label">
          Email Address
        </label>
        <input
          id="inputEmail"
          v-model="user.emailAddress"
          type="email"
          class="form-control"
          placeholder="Email Address"
          :class="{ 'is-invalid': $v.user.emailAddress.$error }" />
      </div>
      <div class="form-group row">
        <label
          for="inputName"
          class="form-control-label">
          Full Name
        </label>
        <input
          id="inputName"
          v-model="user.displayName"
          type="text"
          class="form-control"
          placeholder="Display Name"
          :class="{ 'is-invalid': $v.user.displayName.$error }" />
      </div>
      <div class="company-permissions mt-4">
        <label class="company-label">
          Company Access
        </label>
        <ul
          v-if="showModal"
          class="list-group">
          <li
            v-for="(perm, index) in user.userPermissions"
            :key="'permission-' + index"
            class="list-group-item">
            <div class="row no-gutters">
              <div class="col align-self-center">
                <toggle
                  :id="'check-toggle-edit' + index"
                  color="primary"
                  :is-checked.sync="perm.canView"
                  class="permissions-option"
                  @checked="canView($event, perm)"></toggle>
                <label class="company-name">
                  {{ perm.name }}
                </label>
              </div>
              <div class="col text-right">
                <select
                  v-model="perm.perm"
                  class="select-permissions form-control"
                  :disabled="!perm.canView"
                  @change="adminType($event, perm)">
                  <option :value="null">
                    Select Role
                  </option>
                  <!-- <option value="isAdmin">Admin</option> -->
                  <option value="isEditor">
                    Editor
                  </option>
                  <option value="contributor">
                    Contributor
                  </option>
                  <option value="marketing">
                    Marketing
                  </option>
                </select>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div
      slot="button"
      class="footer-btns">
      <button
        type="button"
        class="btn btn-link"
        @click="$refs.createUserModal.close()">
        Close
      </button>
      <button
        v-if="!showMessage"
        type="button"
        class="btn btn-primary"
        @click="saveUser()">
        Save
      </button>
      <button
        v-else-if="showMessage && modal.icon == 'error'"
        type="button"
        class="btn btn-primary"
        @click="showMessage = false, modal.icon = null">
        Try Again
      </button>
      <button
        v-else
        type="button"
        class="btn btn-primary"
        @click="$refs.createUserModal.close()">
        Done
      </button>
    </div>
  </modal>
</template>
<script type="text/javascript">
import axios from 'axios'
import Modal from 'components/modals/sweet.vue'
import Toggle from 'components/inputs/toggleSwitch.vue'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'UserCreate',
  components: {
    modal: Modal,
    toggle: Toggle
  },
  props: {
    companyList: {
      type: Array,
      required: true
    },
    showModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showMessage: false,
      user: {
        displayName: '',
        emailAddress: '',
        userPermissions: this.companyList
      },
      modal: {
        title: 'Create User',
        message: '',
        icon: null,
        blockClose: false
      }
    }
  },
  watch: {
    showModal (value) {
      if (value) {
        this.$refs.createUserModal.open()
        this.user.userPermissions = JSON.parse(JSON.stringify(this.companyList))
      } else {
        this.$refs.createUserModal.close()
      }
    }
  },
  methods: {
    resetModal () {
      this.$emit('update:showModal', false)
      this.$v.user.$reset()
      setTimeout(() => {
        this.modal = {
          title: 'Create User',
          message: '',
          icon: null,
          blockClose: false
        }
        this.user.displayName = ''
        this.user.emailAddress = ''
        this.showMessage = false
      }, 500)
    },
    canView (event, permission) {
      if (!event) {
        permission.perm = null
        permission.isAdmin = false
        permission.isEditor = false
        permission.contributor = false
        permission.marketing = false
      }
    },
    adminType (event, permission) {
      const val = event.target.value

      switch (val) {
        case 'isAdmin':
          permission.isAdmin = true
          permission.isEditor = true
          permission.contributor = true
          permission.marketing = true
          break
        case 'isEditor':
          permission.isAdmin = false
          permission.isEditor = true
          permission.contributor = true
          permission.marketing = false
          break
        case 'contributor':
          permission.isAdmin = false
          permission.isEditor = false
          permission.contributor = true
          permission.marketing = false
          break
        case 'marketing':
          permission.isAdmin = false
          permission.isEditor = false
          permission.contributor = false
          permission.marketing = true
          break
        default:
          permission.isAdmin = false
          permission.isEditor = false
          permission.contributor = false
          permission.marketing = false
          break
      }
    },
    saveUser () {
      this.$v.user.$touch()
      const data = {
        bannerId: 1,
        email: this.user.emailAddress,
        displayName: this.user.displayName,
        permissions: this.user.userPermissions
      }
      if (!this.$v.user.$invalid) {
        axios.post('/api/users/insertUser', data).then(res => {
          const response = res.data

          this.$emit('user', response.user)
          this.showMessage = true
          this.modal.message = 'Successfully created ' + this.user.displayName + '!'
          this.modal.icon = 'success'
          setTimeout(() => {
            this.$refs.createUserModal._animateIcon()
          }, 50)
        }).catch(err => {
          this.modal.message = err.response.data
          this.modal.icon = 'error'
          this.showMessage = true
          setTimeout(() => {
            this.$refs.createUserModal._animateIcon()
          }, 50)
        })
      }
    }
  },
  validations () {
    return {
      user: {
        displayName: {
          required
        },
        emailAddress: {
          required,
          email
        }
      }
    }
  }
}

</script>
<style lang="scss" scoped>
@import '../../../assets/scss/extra/mixins/flexbox.scss';
@import '../../../assets/scss/bootstrap-custom-variables.scss';

.has-primary {
  .custom-control {
    color: $brand-primary;
  }
}

.team-builder-modal {
  input,
  select {
    font-size: 0.875rem;
  }
  padding: 0 0.75rem;
}

.company-permissions {
  margin: 0 -1rem;
  .company-label {
    color: lighten($gray, 15%);
    margin: 0;
  }

  .company-name {
    margin: 0;
    padding: 0 1rem;
    line-height: 1.75;
    color: lighten($gray, 15%);
  }

  .material-switch {
    display: inline-block;
  }

  .list-group {
    .list-group-item {
      padding: 0.75rem 0.25rem 0.675rem 1rem;
      border-color: #eceeef;
      border-right: 0;
      border-left: 0;
      &:first-child {
        border-top: 0;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.modal-message {
  margin: 1.5rem 0 0; // font-size: .875rem;
  font-weight: 500;
  text-align: center;
  color: #464a4c;
}

.footer-btns {
  @include flex(1, 1, 100%);
  @include flexbox;
  @include justify-content(space-between);
  padding-right: 0.75rem;
  .btn {
    cursor: pointer;
    min-width: 7rem;
  }
}
</style>
