<template>
  <button
    type="button"
    class="btn btn-sm btn-idw"
    :class="loaded && hasData ? 'btn-primary' : 'btn-gray-alt'"
    :disabled="btnDisabled"
    @click="openDialog()">
    <span v-if="loaded && hasData">{{ btnName }}
      <i class="material-icons">
        call_made
      </i>
    </span>
    <span v-else-if="loaded && !hasData">{{ btnName }}
      <i class="material-icons">
        not_interested
      </i>
    </span>
    <span v-else>IDW
      <img
        src="../../../../assets/images/ic_auto_load.svg"
        class="sync" />
    </span>
  </button>
</template>
<script>
export default {
  name: 'IdwButton',
  props: {
    section: {
      type: String,
      default: '',
      required: false
    },
    title: {
      type: String,
      required: false
    },
    loaded: {
      type: Boolean,
      required: true
    },
    hasData: {
      type: Boolean,
      required: true
    },
    btnName: {
      type: String,
      required: true
    }
  },
  computed: {
    btnDisabled () {
      return (this.loaded && !this.hasData) || (!this.loaded && !this.hasData)
    }
  },
  methods: {
    openDialog () {
      this.$emit('openDialog', true)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../../assets/scss/extra/mixins/flexbox";

.btn-idw {
  position: absolute;
  top: 1rem;
  right: 1rem;
  min-width: 5.125rem;
  > span {
    padding-top: 0;
    font-size: 0.75rem;
    position: relative;
    @include flexbox;
    @include justify-content(space-between);

    i {
      padding: 2px 0;
    }

    .sync {
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -0.5rem;
      -webkit-animation: rotating 2s infinite reverse linear;
      -moz-animation: rotating 2s infinite reverse linear;
      -ms-animation: rotating 2s infinite reverse linear;
      -o-animation: rotating 2s infinite reverse linear;
      animation: rotating 2s infinite reverse linear;
    }
  }
}
</style>
