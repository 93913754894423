<template>
  <div class="media-page">
    <page-title title="Media"></page-title>
    <section
      id="company-specific"
      class="card">
      <div class="card-header">
        <ul
          class="nav nav-tabs card-header-tabs"
          role="tablist">
          <!--Uploaded Media-->
          <li
            class="nav-item">
            <a
              class="nav-link active"
              data-toggle="tab"
              role="tab"
              :class="{'active': uploadedChosen }"
              @click="uploadedVisible=1">Uploaded Media</a>
          </li>
          <!--Third Party-->
          <li
            class="nav-item">
            <a
              class="nav-link"
              data-toggle="tab"
              role="tab"
              :class="{'active': !uploadedChosen }"
              @click="uploadedVisible=2">External Media</a>
          </li>
          <!--External MEdia-->
          <li
            class="nav-item">
            <a
              class="nav-link"
              data-toggle="tab"
              role="tab"
              :class="{'active': !uploadedChosen }"
              @click="uploadedVisible=3">YouTube Channels</a>
          </li>
        </ul>
      </div>
      <div class="tab-content">
        <div
          v-if="uploadedVisible==1"
          id="uploadedMedia"
          class="tab-pane"
          role="tabpanel"
          :class="{'active': uploadedVisible==1}">
          <media-search
            :manufacturers="manufacturers"
            :search.sync="search"
            :pagination.sync="pagination"
            @search-media="searchMedia($event, false)"
            @recent="searchMedia(false, true)"
            @pagination="searchPagination(true, $event)"></media-search>
          <media-list
            :media="media"
            :pagination.sync="pagination"
            :manufacturers="manufacturers"
            :document-types="documentTypes"
            @pagination="searchMedia(false, true)"
            @recent="searchMedia(false, true)"></media-list>
        </div>
        <div
          v-if="uploadedVisible==2"
          id="thirdPartyMedia"
          class="tab-pane"
          role="tabpanel"
          :class="{'active': uploadedVisible==2}">
          <third-party-video></third-party-video>
        </div>
        <div
          v-if="uploadedVisible==3"
          id="thirdPartyMedia"
          class="tab-pane"
          role="tabpanel"
          :class="{'active': uploadedVisible==3}">
          <external-youtube></external-youtube>
        </div>
      </div>
    </section>
  </div>
</template>
<script type="text/javascript">
import axios from 'axios'
import TitleComponent from 'components/pageTitle.vue'
import MediaList from './mediaList/list.vue'
import MediaSearch from './mediaList/search.vue'
import ThirdPartyVideos from './mediaList/thirdpartyvideo/ThirdPartyVideos.vue'
import ExternalYoutube from './mediaList/thirdpartyvideo/ExternalYoutube.vue'

export default {
  name: 'MediaPage',
  components: {
    'media-list': MediaList,
    'media-search': MediaSearch,
    'page-title': TitleComponent,
    'third-party-video': ThirdPartyVideos,
    'external-youtube': ExternalYoutube
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.getDependencies()
      vm.searchMedia(null, true)
    })
  },
  data () {
    return {
      uploadedChosen: true,
      uploadedVisible: 1,
      manufacturers: [],
      documentTypes: [],
      media: [],
      pagination: {
        total: 0,
        per_page: 100,
        current_page: 1,
        last_page: 0,
        from: 0,
        to: 15
      },
      search: {
        search: '',
        companies: [],
        mfrId: null,
        mediaTypeId: null,
        sortBy: null,
        languageId: 1
      }
    }
  },
  computed: {
    language () {
      return this.$store.getters.languageId
    }
  },
  watch: {
    language (value, oldVal) {
      this.search.languageId = value
      this.searchMedia(null, false)
    }
  },
  mounted () {
    const companyArray = this.$store.getters.permissions.filter(company => {
      return company.canView || company.contributor || company.isAdmin || company.isEditor
    })
    this.search.companies = companyArray.map(obj => {
      return obj.company.companyId
    })
    this.search.languageId = this.language
  },
  methods: {
    getDependencies () {
      const hooks = [this.$http.get('/api/manufacturers'), axios.get(`/api/media/documents/types?languageId=${this.language}`)]
      axios.all(hooks).then(axios.spread((manufacturers, documentTypes) => {
        this.manufacturers = manufacturers.data
        this.documentTypes = documentTypes.data
        this.$store.dispatch('setSpinner', false)
      }))
    },
    searchMedia (searchData, paginationReset) {
      const page = this.pagination
      const data = {
        params: searchData || this.search
      }
      data.params.size = page.per_page
      data.params.from = page.current_page === 1 ? 0 : (page.per_page * page.current_page - page.per_page) + 1
      this.$store.dispatch('setSpinner', true)

      axios.get('/api/media', data).then(res => {
        const response = res.data
        this.recent = false
        this.media = response.results
        this.setPagination(response.count, paginationReset)
        this.$store.dispatch('setSpinner', false)
      })
    },
    setPagination (pageTotal, paginationResponse) {
      const page = this.pagination
      page.current_page = paginationResponse ? page.current_page : 1
      page.total = pageTotal
      page.last_page = pageTotal > page.per_page ? Math.ceil(pageTotal / page.per_page) : 0
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../assets/scss/extra/mixins/flexbox";
@import "../../assets/scss/bootstrap-custom-variables";

.col.wrap {
  flex-wrap: wrap;
}
 section.card {
  border: 0;
  margin: 0.5rem 0 1.5rem;
  .card-header {
    padding: 0.75rem 0 0;
    background-color: #fff;
    .card-header-tabs {
      margin: 0;
    }

    ul.nav-tabs {
      li.nav-item {
        min-width: 12.5rem;
        text-align: center;
        margin-right: 0.25rem;
        a {
          color: #000;
          font-weight: bold;
          background-color: #cfcfcf;
          &.active {
            background-color: #fff;
            color: $brand-primary;
          }
        }
      }
    }
  }
  div.tab-content {
    border: 1px solid #ddd;
    border-top: 0;
    .tab-pane {
      padding: 0.75rem 1rem 0;
    }
    div#product-number-price-cartoptions,
    div#product-family-grouping-market,
    div#product-order-details {
      @media (min-width: 37.5rem) {
        @include flexbox;
        @include flex-wrap(wrap);
        @include justify-content(space-between);
      }
      .form-group {
        width: 100%;
        @media (min-width: 37.5rem) {
          width: calc(50% - 1rem);
        }
        @media (min-width: 1200px) {
          width: calc(100% / 3 - 1rem);
        }
        input[readonly],
        input[disabled="disabled"] {
          border: 1px solid transparent;
          background-color: #ececec;
        }
      }
      div#product-cartoptions {
        width: 100%;
      }
    }
  }
}
</style>
