<template>
  <div
    class="prod-wrap"
    :class="{'remove-prod': data.removeProd}">
    <div class="prod-img">
      <img
        v-if="data.folderName"
        :src="`https://rexel-cdn.com/products/${data.catNum}.jpg?i=${data.folderName}&f=50`" />
      <img
        v-else
        :src="`https://rexel-cdn.com/products/${data.catNum}.jpg?i=0EA7A6C5-7DD2-4482-9229-D628D3AFAE9D&f=50`" />
    </div>
    <div class="prod-info">
      <div
        class="row-one"
        v-html="formatResult(data.catNum, filter)">
      </div>
      <div class="row-two">
        <span
          class="napm-id"
          v-html="'NAPM#: ' + formatResult(data.napmId.toString(), filter)">
        </span>
        <span
          v-if="data.mfrName"
          v-html="'MFR: '+ formatResult(data.mfrName, filter)">
        </span>
        <span v-else>
          MFR: -
        </span>
      </div>
    </div>
    <div class="prod-order">
      <input
        v-if="!data.removeProd"
        v-model="data.displayOrder"
        type="number"
        class="form-control"
        @change="prodChanged(data)" />
    </div>
    <div class="prod-cross-check">
      <toggle
        v-if="!data.removeProd"
        :id="'toggle-cross-' + data.napmId"
        color="primary"
        :is-checked.sync="data.crossFilled"
        @checked="prodChanged(data, $event)"></toggle>
      <span
        v-else
        class="removed-lbl">
        Removed
      </span>
    </div>
    <div class="prod-remove">
      <a @click="removeCrossProd(data)">
        <i
          class="material-icons"
          :class="{'rotate': data.removeProd}">
          clear
        </i>
      </a>
    </div>
  </div>
</template>
<script type="text/javascript">
import ToggleComponent from 'components/inputs/toggleSwitch.vue'

export default {
  name: 'SingleProd',
  components: {
    toggle: ToggleComponent
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    filter: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      removeProd: null
    }
  },
  methods: {
    removeCrossProd (product) {
      this.$set(this.data, 'prodChanged', true)
      product.removeProd ? this.data.removeProd = false : this.$set(this.data, 'removeProd', true)
    },
    prodChanged (product) {
      this.$set(this.data, 'prodChanged', true)
    },
    formatResult (suggestion, search) {
      function escapeRegExChars (value) {
        return value.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
      };

      // Do not replace anything if the current value is empty
      if (!search || search === '') {
        return suggestion
      }

      const pattern = '(' + escapeRegExChars(search) + ')'

      return suggestion.toLowerCase()
        .replace(new RegExp(pattern, 'gi'), '<strong>$1</strong>')
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/&lt;(\/?strong)&gt;/g, '<$1>')
    }
  }
}
</script>
<style lang="scss">
@import '../../../../assets/scss/extra/mixins/flexbox';
@import '../../../../assets/scss/bootstrap-custom-variables';

.recyclerview-container,
.prod-search {
  border: 1px solid #c9c9c9;
  .recyclerview {
    transition: none !important;
  }
  .prod-wrap {
    width: 100%;
    height: 4.25rem;
    padding: 0.5rem 0.25rem;
    transition: none !important;
    position: relative;
    border-bottom: 1px solid #c9c9c9;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &.remove-prod {
      background: #fef0f0;
      .rotate {
        transition: all 0.125s ease-in-out;
        transform: rotate(45deg);
      }
      .removed-lbl {
        text-transform: uppercase;
        color: $brand-danger;
        font-weight: bold;
      }
      .prod-img {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        filter: gray;
        opacity: 0.3;
      }
      .prod-info {
        opacity: 0.3;
      }
      .prod-remove {
        color: $brand-primary;
      }
    }
    @include flexbox;
    .prod-img {
      padding: 0 0.5rem;
      @include flex(0, 1, 100px);
      @include align-self(center);
    }
    .prod-info {
      @include flex(1, 1, 75%);
      @include align-self(center);
      text-transform: uppercase;
      padding: 0 0.5rem;
      .row-one,
      .row-two {
        font-size: 0.875rem;
        .napm-id {
          padding-right: 0.5rem;
          min-width: 6.75rem;
          display: inline-block;
        }
      }
    }
    .prod-order {
      padding: 0 0.5rem;
      @include flex(0, 1, 150px);
      @include align-self(center);
    }
    .prod-cross-check {
      padding: 0 0.5rem;
      @include flex(0, 1, 150px);
      @include align-self(center);
    }
    .prod-remove {
      margin-top: -0.5rem;
      font-size: 1.5rem;
      color: $brand-danger;

      @include flex(0, 1, 50px);
      @include align-self(center);
    }
  }
}
</style>
