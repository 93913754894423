<template>
  <v-dialog
    v-model="iframeUrl"
    class="external-site"
    max-width="100%"
    fullscreen>
    <v-card :style="`height 100vh;`">
      <v-btn
        class="close-button mr-1"
        icon
        dark
        @click="close()">
        <v-icon>close</v-icon>
      </v-btn>
      <v-card-text
        class="pa-0 ma-0"
        :style="`height: 100vh;`">
        <iframe
          :style="`width: 100%; height: 100vh; border: none;`"
          :src="$store.state.externalUrl"
          class="ma-0"></iframe>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'ExternalContent',
  computed: {
    iframeUrl: {
      get () {
        return this.$store.state.externalUrl
      },
      set (value) {
        this.value = value
      }
    }
  },
  methods: {
    ...mapActions(['setExternalUrl']),
    close () {
      this.setExternalUrl(null)
    }
  }
}
</script>

<style lang="scss" scoped>

// Button has to float over the iframe toolbar
.close-button {
  position: fixed;
  right: 32px;
  top: 14px;
}
.external-site {
  z-index: 6000;
}
</style>
