<template>
  <div :class="size">
    <svg
      viewBox="0 0 64 72"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      :class="lowerCaseFileName">
      <defs>
        <rect
          id="path-2"
          x="8"
          y="27"
          width="50"
          height="17"
          rx="8.5" />
        <filter
          id="filter-2"
          x="-14.0%"
          y="-29.4%"
          width="128.0%"
          height="182.4%"
          filterUnits="objectBoundingBox">
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation="2"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
            type="matrix"
            in="shadowBlurOuter1" />
        </filter>
      </defs>
      <g
        id="Symbols"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd">
        <g id="ic-/-csv">
          <g>
            <rect
              id="Rectangle-2"
              fill="#EEEEEE"
              x="0"
              y="0"
              width="64"
              height="72"
              rx="5" />
            <rect
              id="Rectangle-3"
              fill="#D8D8D8"
              x="8"
              y="12"
              width="49"
              height="4"
              rx="2" />
            <rect
              id="Rectangle-3-Copy"
              fill="#D8D8D8"
              x="8"
              y="22"
              width="49"
              height="4"
              rx="2" />
            <rect
              id="Rectangle-3-Copy-4"
              fill="#D8D8D8"
              x="8"
              y="32.672389"
              width="30"
              height="4.13447781"
              rx="2.0672389" />
            <rect
              id="Rectangle-3-Copy-5"
              fill="#D8D8D8"
              x="8"
              y="43.0085836"
              width="22.5"
              height="4.13447781"
              rx="2.0672389" />
            <g id="Rectangle-4">
              <use
                fill="black"
                fill-opacity="1"
                filter="url(#filter-2)"
                xlink:href="#path-2" />
              <use
                class="type-bg"
                fill="#00A14D"
                fill-rule="evenodd"
                xlink:href="#path-2" />
            </g>
            <text
              font-size="12"
              font-weight="bold"
              fill="#FFFFFF">
              <tspan
                x="22.354"
                y="40">
                {{ lowerCaseFileName }}
              </tspan>
            </text>
            <rect
              id="Rectangle-3-Copy-6"
              fill="#D8D8D8"
              x="8"
              y="53"
              width="30"
              height="4"
              rx="2" />
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  name: 'DocumentIcon',
  props: {
    classAttr: {
      type: String,
      default: '',
      required: true
    },
    size: {
      type: String,
      default: 'icon-size',
      required: false
    }
  },
  computed: {
    lowerCaseFileName () {
      return this.classAttr.toLowerCase()
    }
  }
}
</script>
<style lang="scss" scoped>
  .icon-size {
    margin: 0  auto 1rem;
    height: 4.6875rem;
    width: 4.6875rem;
  }
  .xls, .xlsx, .csv {
    .type-bg { fill: #00A14D }
  }
  .pdf .type-bg { fill: #a00 }
  .doc, .docx, .txt, .rtf {
    .type-bg {
      fill: #0084D8
    }
  }
  .ppt, .pptx {
    .type-bg {
      fill: #f24115
    }
  }
  .psd .type-bg { fill: #00416A }
  .web .type-bg { fill: rgb(255, 0, 128)}
  .rfa .type-bg { fill: rgb(76, 0, 255) }
  .exe .type-bg { fill: rgb(18, 134, 150) }
</style>
