<template>
  <section
    id="documents"
    :class="{ 'mt-0': displayOnly }">
    <div class="section-heading pt-3 pl-3">
      <label class="form-control-label">
        Documents:
      </label>
    </div>
    <div class="section-body">
      <div class="documents-container">
        <div
          v-for="(doc, index) in documents"
          :key="'doc-' + doc.documentFolderName">
          <a
            :href="documentUrl(doc)"
            target="_blank">
            <div class="document">
              <a
                v-if="!displayOnly"
                class="remove-doc"
                @click.prevent="removeDocument(doc, index)">
                <i class="material-icons">
                  &#xE5CD;
                </i>
              </a>
              <div class="doc-icon">
                <doc-icon :class-attr="doc.documentDisplayName.split('.')[1]"></doc-icon>
              </div>
              <p class="doc-date">
                {{ uploadDate(doc.uploadDate) }}
              </p>
              <div class="doc-name-wrap">
                <span class="doc-name">
                  {{ doc.documentDisplayName }}
                </span>
              </div>
              <p class="doc-download">
                Download
              </p>
            </div>
          </a>
        </div>
        <div
          v-if="documents && documents.length && !displayOnly"
          class="new-document"
          @click="documentUpload.dialog = true">
          <label class="new-document-label">
            Documents
          </label>
          <label
            v-if="uploadReady"
            for="file"
            class="btn btn-primary btn-fake btn-rounded">
            <input
              id="file"
              name="file"
              type="file"
              :accept="validMediaFileTypes.document.concat(validMediaFileTypes.images)"
              @change="saveDocument($event)" />
            Add New
          </label>
        </div>
        <template v-else>
          <div
            v-if="!documents.length"
            class="no-documents">
            <p>No documents have been added</p>
            <label
              v-if="uploadReady && !displayOnly"
              for="file"
              class="btn btn-primary btn-fake">
              <input
                id="file"
                name="file"
                type="file"
                :accept="validMediaFileTypes.document"
                @change="saveDocument($event)" />
              Add Documents
            </label>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>
<script type="text/javascript">
// import UploadMedia from '../../media/upload/uploadSearch.vue'
import DocumentIconComponent from 'components/icons/documentIcon.vue'
import axios from 'axios'
import MediaFileTypesMixin from '@/views/media/types/MediaFileTypes.mixin'

export default {
  components: {
    // 'upload-media': UploadMedia,
    'doc-icon': DocumentIconComponent
  },
  mixins: [MediaFileTypesMixin],
  props: {
    mfr: Number,
    hasIdw: Number,
    loaded: Boolean,
    language: Number,
    documents: Array,
    addedDocuments: Array,
    removedDocuments: Array,
    displayOnly: Boolean
  },
  data () {
    return {
      file: '',
      fileEvent: '',
      documentDisplayName: '',
      documentUpload: {
        dialog: false,
        documentTypes: [],
        fileType: '0',
        fileInputTxt: '',
        fileInputVal: null,
        showError: false,
        errorMessage: ''
      },
      uploadReady: true,
      cleanDocuments: []
    }
  },
  methods: {
    uploadDate (dateStr) {
      const d = dateStr || Date.now()
      const dt = new Date(d)
      const m = dt.getMonth() + 1
      const month = (m < 10) ? '0' + m : m
      const day = (dt.getDate() < 10) ? '0' + dt.getDate() : dt.getDate()
      const year = dt.getFullYear()
      const date = [month, day, year].join('/')
      return date
    },
    saveDocument (event) {
      this.fileEvent = event
      this.file = event.target.files[0]
      this.documentDisplayName = event.target.files[0].name
      const data = new FormData()
      data.append('file', this.file)
      this.loading = true
      const uploadDate = this.uploadDate()
      axios.post('/api/marketing/uploadFile', data, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
        this.loading = false
        this.documents.push({ documentFolderName: res.data, documentDisplayName: this.documentDisplayName, uploadDate })
        this.uploadReady = false
        this.$nextTick(() => {
          this.uploadReady = true
        })
      }).catch(err => {
        console.log(err)
      })
    },
    removeDocument (doc, docIndex) {
      const docId = doc.mediaId
      this.documents.splice(docIndex, 1)
      if (this.addedDocuments) {
        this.addedDocuments.forEach((id, index) => {
          if (docId === id) {
            this.addedDocuments.splice(index, 1)
          }
        })
      }
    },
    documentUrl (doc) {
      return `/api/marketing/getfile/${doc.documentFolderName}`
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/scss/extra/mixins/flexbox.scss';
@import '../../../assets/scss/bootstrap-custom-variables.scss';

.form-control {
  line-height: 1.5;
}
.form-control-label {
  position: relative;
  display: block;
  &.required:after {
    content: "*";
    color: $brand-danger;
    top: .125rem;
    padding-left: .25rem;
  }
}
label.input-group-btn {
  margin-bottom: 0;
}
label.btn.btn-primary.btn-fake {
    background: #00416a;
    overflow: hidden !important;
    position: relative !important;
    &.btn-rounded {
      min-width: unset;
    }
    &:hover {
      background: #002a44
    }
  #file {
    cursor: inherit;
    display: block;
    filter: alpha(opacity=0);
    min-height: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    right: 0;
  }
}
section {
  border: 1px solid #c9c9c9;
  background: #eee;
  margin: 1rem 0 2rem;
  transition: border-color .3s ease;
  .section-heading {
    border-bottom: 0;
    position: relative;
  }
}
section.is-invalid {
  border-color: #c00
}
.section-body {
  padding: 0 1rem 0.5rem;
  @media (min-width: 37.5rem) {
  }
}

.documents-container {
  padding: 1rem 0.5rem;
  margin: 0 -1rem;
  @include flexbox;
  @include flex-wrap(wrap);
  @include justify-content(flex-start);
  a {
    text-decoration: none;
    &:hover > .document {
      box-shadow: 0 2px 4px rgba(0,0,0,.15);
      border: 1px solid #bbb
    }
  }
  .document {
    @include flex(0, 1, 8rem);
    width: 8rem;
    height: 12.5rem;
    margin: 0 0.5rem;
    position: relative;
    border: 1px solid #c9c9c9;
    background: #fff;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0,0,0,0);
    transition: box-shadow .2s ease, border .3s ease;
    .remove-doc {
      position: absolute;
      top: 0.125rem;
      right: 0.25rem;
      color: $brand-danger;
      &:hover {
        color: lighten($brand-danger, 10%);
      }
    }
    .doc-new {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 0.625rem;
      padding: 0.25rem 0.5rem;
    }
    .doc-icon {
      margin-top: 1.5rem;
      margin-bottom: 1rem;
      padding: 0 0.25rem;
      i {
        font-size: 2.1875rem;
      }
    }
    .doc-date {
      display: block;
      max-width: 100%;
      color: $brand-primary;
      font-weight: 600;
      line-height: 2;
      margin: 0;
      padding: 0 0.25rem;
      font-size: 0.75rem;
    }
    .doc-download {
      display: block;
      max-width: 100%;
      color: $brand-primary;
      font-weight: 600;
      margin: 0;
      padding: 0 0.25rem 0.25rem;
      font-size: 0.75rem;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      &:hover {
        text-decoration: underline;
      }
    }
    .doc-name-wrap {
      padding: 0 0.325rem 0.25rem;
      > span {
        color: $gray;
        display: block;
        line-height: 2;
        font-size: 0.75rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 90%;
        overflow: hidden;
        margin: 0 auto
      }
    }
  }

  .new-document {
    border: 1px solid #c9c9c9;
    background: #d9d9d9;
    width: 8rem;
    height: 12.5rem;
    padding: 0.5rem;
    margin: 0 0.5rem;
    @include flex(0, 1, 8rem);
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(center);
    .new-document-label {
      display: block;
      font-weight: 600;
      color: #303030;
      font-size: 0.875rem;
      text-align: center;
      @include flex(0, 1, 2rem);
      @include align-self(center);
    }
  }

  .no-documents {
    color: #333;
    text-align: center;
    font-size: 0.875rem;
    margin-bottom: 1rem;
    @include flex(1,1,100%);
    .btn {
      padding: 0.5rem 1.25rem;
      font-size: 0.875rem;
    }
  }
}
</style>
