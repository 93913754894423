<template>
  <div class="autocomplete">
    <input
      v-model="query.searchTerm"
      type="text"
      class="form-control autocomplete-input"
      autocomplete="off"
      :placeholder="placeholder"
      @keydown.down="down"
      @keydown.up="up"
      @keydown.enter="hit"
      @keydown.esc="reset"
      @blur="reset"
      @input="update" />
    <img
      v-if="loading"
      src="~/assets/images/ic_auto_load.svg"
      class="loader spin" />
    <template v-else>
      <i
        v-show="isEmpty"
        class="material-icons">search</i>
      <i
        v-show="isDirty"
        class="material-icons times"
        @click="reset">close</i>
    </template>
    <ul v-show="items.length > 0">
      <li
        v-for="(item, $item) in items"
        :key="`auto-${item.napmId}`"
        :class="activeClass($item)"
        class="result-item"
        @mousedown="hit"
        @mousemove="setActive($item)">
        <div class="result-img">
          <img
            v-if="item.mainImage"
            :src="`https://rexel-cdn.com/products/${item.catNum}.jpg?i=${item.mainImage}&f=50`" />
          <img
            v-else
            :src="`https://rexel-cdn.com/products/${item.catNum}.jpg?i=0EA7A6C5-7DD2-4482-9229-D628D3AFAE9D&f=50`" />
        </div>
        <div class="result-info">
          <div class="row-one">
            {{ item.catNum }}
          </div>
          <div class="row-two">
            <span>NAPM#: {{ item.napmId }}</span>
            <span v-if="item.manufacturer">
              MFR#: {{ item.manufacturer.id }}
            </span>
          </div>
          <div class="row-three">
            {{ item.description }}
          </div>
        </div>
        <div class="result-action">
          <button
            type="button"
            class="btn btn-rounded btn-sm btn-gray-light">
            Add
          </button>
        </div>
      </li>
    </ul>
  </div>
</template>
<script type="text/javascript">
import axios from 'axios'

export default {
  name: 'Autocomplete',
  props: {
    src: {
      type: String,
      required: false,
      default: '/api/products'
    },
    limit: {
      type: Number,
      required: false,
      default: 25
    },
    minChars: {
      type: Number,
      required: false,
      default: 3
    },
    responseData: {
      type: String,
      default: null,
      required: false
    },
    placeholder: {
      type: String,
      default: '',
      required: false
    }
  },
  data () {
    return {
      items: [],
      current: -1,
      loading: false,
      selectFirst: false,
      query: {
        searchTerm: '',
        size: 25,
        languageId: 1
      }
    }
  },
  computed: {
    isEmpty () {
      return !this.query.searchTerm
    },

    isDirty () {
      return !!this.query.searchTerm
    }
  },
  methods: {
    update () {
      this.cancel()

      if (!this.query.searchTerm) {
        return this.reset()
      }

      if (this.minChars && this.query.searchTerm.length < this.minChars) {
        return
      }

      this.loading = true

      this.fetch().then((response) => {
        if (response && this.query.searchTerm) {
          let data = response.data

          data = this.responseData ? data[this.responseData] : data
          this.items = this.limit ? data.slice(0, this.limit) : data
          this.current = -1
          this.loading = false

          if (this.selectFirst) {
            this.down()
          }
        }
      })
    },

    fetch () {
      const cancel = new Promise((resolve) => {
        this.cancel = resolve
      })
      const request = axios.get(this.src, { params: this.query })

      return Promise.race([cancel, request])
    },

    cancel () {
      // used to 'cancel' previous searches
    },

    reset () {
      this.items = []
      this.query.searchTerm = null
      this.loading = false
    },

    setActive (index) {
      this.current = index
    },

    activeClass (index) {
      return {
        active: this.current === index
      }
    },

    hit () {
      if (this.current !== -1) {
        this.onHit(this.items[this.current])
      }
    },

    up () {
      if (this.current > 0) {
        this.current--
      } else if (this.current === -1) {
        this.current = this.items.length - 1
      } else {
        this.current = -1
      }
    },

    down () {
      if (this.current < this.items.length - 1) {
        this.current++
      } else {
        this.current = -1
      }
    },

    onHit (item) {
      this.$emit('hit', item)
      this.reset()
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/extra/mixins/flexbox";
@import "../../../assets/scss/bootstrap-custom-variables";
@import "../../../../node_modules/bootstrap/scss/bootstrap";
@import "../../../assets/scss/extra/mixins/scrollbar.scss";

.autocomplete {
  position: relative;
  z-index: 2;
}

.autocomplete-input {
  width: 100%;
  font-size: 0.875rem;
  color: #000;
  line-height: 1.42857143;
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  padding: 0.75rem 1.5rem;
}

.autocomplete-input:focus {
  border-color: $brand-primary;
}

.autocomplete-input:focus ~ i {
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  color: $brand-primary;
  opacity: 1;
}

i {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  opacity: 0.4;
}

.loader {
  position: absolute;
  top: 0.95rem;
  right: 0.75rem;
  display: inline;
}

ul {
  position: absolute;
  padding: 0;
  min-width: 100%;
  max-width: 100%;
  background-color: #fff;
  list-style: none;
  border-radius: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  max-height: 19.2rem;
  overflow-y: scroll;
  @include scrollbar(0.5rem, 4.5rem, #eee, 1px);
}

.active {
  cursor: pointer;
  background: #eee;
  .btn {
    @extend .btn-primary;
    @include transition(none);
  }
}

li.result-item {
  padding: 0.125rem 0.75rem;
  &:not(:first-child) {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
  }

  @include flexbox;
  .result-img {
    @include flex(0, 1, 3.75rem);
    @include align-self(center);
    text-align: center;
    > img {
      display: inline-block;
    }
  }
  .result-info {
    @include flex(1, 1, 75%);
    @include align-self(center);

    padding: 0.25rem 0.5rem;
    .row-one,
    .row-two {
      font-weight: 500;
      font-size: 0.875rem;
      text-transform: uppercase;
      > span {
        padding-right: 0.75rem;
      }
    }
    .row-three {
      color: #999;
      width: 21.75rem;
      font-size: 0.75rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .result-action {
    @include flex(0, 1, 3.75rem);
    @include align-self(center);
    text-align: center;
  }
}

.material-icons {
  font-size: 1.5rem;
}

.material-icons.times {
  cursor: pointer;
}

.loader.spin {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
