<template>
  <div class="material-switch">
    <input
      :id="id"
      v-model="checkbox"
      type="checkbox"
      :disabled="isDisabled"
      @change="checkboxEvent($event)" />
    <label
      :for="id"
      :class="'label-' + color"></label>
  </div>
</template>

<script type="text/javascript">

export default {
  name: 'MaterialSwitch',
  props: ['color', 'isChecked', 'id', 'isDisabled'],
  data () {
    return {
      checkbox: this.isChecked
    }
  },
  methods: {
    checkboxEvent (event) {
      this.$emit('update:isChecked', this.checkbox)
      // this.$emit('checked', this.checkbox)
    }
  }
}
</script>
<style lang="scss">
@import '../../assets/scss/bootstrap-custom-variables.scss';

.material-switch {
  input[type='checkbox'] {
    display: none;
  }
  input[type='checkbox']:checked+label::before {
    opacity: .5;
    background: inherit;
  }
  input[type='checkbox']:checked+label::after {
    left: 1rem;
    background: inherit;
  }
  label {
    width: 2rem;
    height: 0;
    position: relative;

    cursor: pointer;
    &:before {
      content: '';
      width: 2rem;
      height: .5rem;
      margin-top: -.25rem;
      position: absolute;
      transition: none .4s ease-in-out;
      opacity: .3;
      border-radius: .5rem;
      background: rgb(0, 0, 0);
      box-shadow: inset 0 0 10px rgba(0, 0, 0, .5);
    }
    &:after {
      content: '';
      width: 1.25rem;
      height: 1.25rem;
      margin-top: -.375rem;
      position: absolute;
      top: -.25rem;
      left: -.25rem;
      transition: all .3s ease-in-out;
      border-radius: 1rem;
      background: rgb(255, 255, 255);
      box-shadow: 0 2px 4px rgba(0, 0, 0, .3);
    }
  }
}

.label-primary {
  background-color: $brand-primary;
}

.label-success {
  background-color: $brand-success;
}

.label-info {
  background-color: $brand-info;
}

.label-warning {
  background-color: $brand-warning;
}

.label-danger {
  background-color: $brand-danger;
}

.label-default {
  background-color: $brand-inverse;
}
</style>
