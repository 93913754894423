<template>
  <section id="slangTerms">
    <div class="section-heading">
      <h6>Slang Terms:</h6>
    </div>
    <div v-if="slangTerms && slangTerms.slangTerms">
      <div class="section-body">
        <div class="form-group">
          <tagging-input
            :tags.sync="terms"
            :read-only="true">
          </tagging-input>
        </div>
      </div>
      <div class="slangTerms-action">
        <button
          type="button"
          class="btn btn-rounded btn-primary"
          @click="openModal">
          View All &amp; Edit
        </button>
      </div>
    </div>
    <div
      v-else
      class="section-body no-products">
      <p>No products have been added</p>
    </div>
    <approved-term-modal
      ref="approvedModal"
      :item="slangTerms"
      :hide-actions="false"
      :mode="'ProductEdit'"
      @save="saveApprovedSlangTerm">
    </approved-term-modal>
  </section>
</template>
<script type="text/javascript">
import axios from 'axios'
import TaggingComponent from 'components/tagging.vue'
import ApprovedTermModal from '@/views/slangterms/ApprovedTermModal.vue'

export default {
  components: {
    'tagging-input': TaggingComponent,
    ApprovedTermModal
  },
  props: {
    slangTerms: {
      type: Object,
      default: null,
      required: false
    }
  },
  computed: {
    terms () {
      if (!this.slangTerms || !this.slangTerms.slangTerms) {
        return []
      }
      return this.slangTerms.slangTerms.map(x => x.submittedTerm)
    }
  },
  methods: {
    openModal () {
      this.$refs.approvedModal.open()
    },
    saveApprovedSlangTerm (data) {
      const updatedData = {
        productSlangTermId: data.productSlangTermId.toString(),
        action: data.action
      }
      if (data.submittedTerm) {
        updatedData.submittedTerm = data.submittedTerm
      }
      axios.post('/api/SlangTerms/UpdateSlangTerm', updatedData)
        .then(res => {
          const updatedSlangTerms = { ...this.slangTerms }
          const indexOfSlangTermBeingUpdated = updatedSlangTerms.slangTerms.findIndex(x => x.productSlangTermId === data.productSlangTermId)
          if (indexOfSlangTermBeingUpdated !== -1) {
            updatedSlangTerms.slangTerms[indexOfSlangTermBeingUpdated].submittedTerm = updatedData.submittedTerm
            this.$emit('update', updatedSlangTerms)
          } else {
            console.error('updateSlangTerm() could not find indexOfSlangTermBeingUpdated productSlangTermId=', data.productSlangTermId)
          }
        })
        .catch(res => {
          console.error('updateSlangTerm() error res=', res)
          this.handleHttpError(res, 'Unable to retrieve update slang term at this time.')
        })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/extra/mixins/flexbox";

#slangTerms {
  border: 1px solid #c9c9c9;
  background: #eee;
  margin: 2rem 0;
  .section-heading {
    position: relative;
    border-bottom: 0;
    h6 {
      padding: 0 1.25rem;
    }
    .idw-btn {
      position: absolute;
      top: 1rem;
      right: 1rem;
      min-width: 8.125rem;
      @include flexbox;
      @include justify-content(space-between);
    }
  }
  .section-body {
    padding: 0 1rem 1rem;
    &.no-products {
      text-align: center;
      margin-bottom: 1rem;
      color: #333;
      font-size: 0.875rem;
      .btn {
        font-size: 0.875rem;
        padding: 0.5rem 1.25rem;
      }
    }
  }
  .slangTerms-action {
    padding: 0 1rem 1rem;
    text-align: center;
  }
}
</style>
