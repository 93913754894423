import { render, staticRenderFns } from "./editType.vue?vue&type=template&id=de2ef20e&scoped=true"
import script from "./editType.vue?vue&type=script&lang=js"
export * from "./editType.vue?vue&type=script&lang=js"
import style0 from "./editType.vue?vue&type=style&index=0&id=de2ef20e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de2ef20e",
  null
  
)

export default component.exports