<template>
  <div class="mt-2">
    <a
      class="toggle-products"
      @click="$emit('update:show', !show)">
      <div>
        <span>Associated Products</span>
        <span
          v-if="productCount"
          class="badge badge-light badge-pill ml-2">
          {{ productCount | numeric }}
        </span>
      </div>
      <i
        v-if="show"
        class="material-icons">
        keyboard_arrow_up
      </i>
      <i
        v-else
        class="material-icons">
        keyboard_arrow_down
      </i>
    </a>
    <div
      v-if="show"
      class="detail-related-products">
      <div class="search-cat-products">
        <input
          v-model="searchQuery"
          type="text"
          class="form-control"
          placeholder="Search..."
          @keyup="search" />
      </div>
      <div
        v-if="productList.length"
        class="products-table">
        <div class="table-head">
          <div class="tr">
            <div class="th product-img"></div>
            <div class="th product-desc">
              Product Name
            </div>
            <div class="th product-num">
              NAPM #
            </div>
            <div class="th product-arrow"></div>
          </div>
        </div>
        <div class="table-body">
          <router-link
            v-for="p in productList"
            :key="`product-${p.napmId}`"
            class="tr"
            :to="{path: `/products/edit/${p.napmId}`}">
            <div class="product-img td">
              <div class="img-fluid">
                <img
                  v-if="p.mainImage"
                  :src="`https://rexel-cdn.com/products/${p.catNum}.jpg?i=${p.mainImage}&f=50`" />
                <img
                  v-else
                  :src="`https://rexel-cdn.com/products/${p.catNum}.jpg?i=0EA7A6C5-7DD2-4482-9229-D628D3AFAE9D&f=50`" />
              </div>
            </div>
            <div class="product-desc td">
              <p>
                <span>Name: {{ p.catNum }}</span>
                <small v-if="p.manufacturer">
                  By: {{ p.manufacturer.name }}
                </small>
              </p>
              <p v-if="p.shortDescription">
                <span>Description: </span> {{ p.shortDescription }}
              </p>
            </div>
            <div class="product-num td">
              {{ p.napmId }}
            </div>
            <div class="product-arrow td">
              <i class="material-icons">
                keyboard_arrow_right
              </i>
            </div>
          </router-link>
        </div>
      </div>
      <div
        v-else
        class="no-products">
        No Associated Products Found
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DetailsProducts',
  props: {
    show: Boolean,
    loading: Boolean,
    productList: Array,
    productCount: Number
  },
  data () {
    return {
      searchQuery: null
    }
  },
  methods: {
    search () {
      this.$emit('searchAssociated', this.searchQuery)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/scss/extra/mixins/flexbox';
@import '../../../assets/scss/bootstrap-custom-variables';
@import '../../../assets/scss/extra/mixins/scrollbar.scss';

a.toggle-products {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.75rem 1.25rem;
  color: $gray-900;
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
  &:hover {
    cursor: pointer;
    background-color: $gray-100;
  }
  .material-icons {
    font-size: 1.5rem;
  }
}

.detail-related-products {
  @include scrollbar(0.5rem, 4.5rem, $gray-200, 1px);
  .table,
  .products-table {
    width: 100%;
    margin: 0;
    border-style: solid;
    border-width: 0 1px 1px;
    border-color: rgba(0, 0, 0, 0.125);
    thead,
    .table-head {
      background-color: $gray-100;
      th,
      .th {
        padding: 0.75rem;
        color: #999;
        font-weight: 500;
        font-size: 0.875rem;
        text-transform: uppercase;
      }
    }
    tbody,
    .table-body {
      max-height: 15.75rem;
      overflow-y: auto;
      tr,
      .tr {
        border-top: 1px solid rgba(0, 0, 0, 0.125);
      }
      td,
      .td {
        padding: 0.5rem 0.75rem;
        position: relative;
        color: #999;
        border: none;
        font-size: 0.75rem;

        p,
        label {
          margin: 0;
          font-size: 0.75rem;
        }
      }
      .material-icons {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.5rem;
      }
    }
    .tr {
      width: 100%;
      @include flexbox;
      .product-img {
        width: 4.625rem;
        @include flex(0, 1, 4.625rem);
        &.td {
          height: 4.125rem;
          @include flexbox;
          @include flex-direction(column);
          @include justify-content(center);
        }
      }
      .product-desc {
        @include flex(1, 1, 70%);
        @include align-self(center);
      }
      .product-num {
        @include flex(0, 1, 4.5rem);
        @include align-self(center);
        &.th {
          @include flex(0, 1, 5rem);
        }
      }
      .product-arrow {
        @include flex(0, 1, 4.5rem);
      }
    }
    a.tr {
      &:hover {
        text-decoration: none;
        background-color: $gray-100;
        .td {
          color: darken(#999, 15%);
        }
      }
    }
  }
  .no-products {
    color: #999;
    font-size: 0.875rem;
    padding: 0.75rem 1.25rem;
    text-transform: uppercase;
    background-color: $gray-100;
    border-style: solid;
    border-width: 0 1px 1px;
    border-color: rgba(0, 0, 0, 0.125);
  }
}
.search-cat-products {
  background: #eee;
  @include flex(1, 1, 100%);
  padding: 0.75em;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-top: none;
}
</style>
