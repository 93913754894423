<template>
  <div class="category-details">
    <div v-if="details && detailView || details && create">
      <span class="category-label">
        {{ create ? createView : detailView }}
      </span>
      <div class="details-heading">
        <div class="title">
          <details-media
            :details.sync="details"
            :invalid="$v.details.mediaId.$error"></details-media>
          <div class="title--text">
            <h4>{{ details.language[language].name }}</h4>
          </div>
        </div>
        <div
          v-if="!create"
          class="dropdown">
          <a
            id="details-action"
            class="dropdown-toggle"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">
            <i class="material-icons">
              more_vert
            </i>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="details-action">
            <h6 class="dropdown-header">
              Actions
            </h6>
            <div class="dropdown-divider"></div>
            <a
              v-if="detailView !== 'Sub Category'"
              class="dropdown-item"
              @click="createCategory()">Add Child Category</a>
            <a
              class="dropdown-item"
              :disabled="!productsLoaded || products.length"
              :class="{'disabled': !productsLoaded || products.length}"
              @click="deleteCategory()">Remove</a>
            <a
              class="dropdown-item"
              @click="showBannerSpecificModal = true">Photo for Banners</a>
          </div>
        </div>
      </div>
      <nav class="lang-nav">
        <template v-for="lang in $store.getters.languageOptions">
          <button
            :key="`language-id-${lang.id}`"
            class="btn btn-rounded"
            :class="[lang.id === language ? 'btn-primary' : 'btn-gray-light']"
            @click="$store.dispatch('setLanguage', lang)">
            {{ lang.name }}
          </button>
        </template>
      </nav>
      <div
        class="form-group"
        :class="{'is-invalid': missingNames}">
        <label
          class="form-control-label"
          :class="{'required': language === 1}">Legacy Category Name</label>
        <input
          v-model="details.language[language].name"
          class="form-control"
          :class="{ 'is-invalid': missingNames }"
          :placeholder="missingNames ? 'English Name is required' : ''" />
      </div>
      <div class="form-group">
        <label class="form-control-label">
          Legacy Category Description
        </label>
        <textarea
          v-model="details.language[language].description"
          rows="5"
          class="form-control"></textarea>
      </div>
      <div
        class="form-group">
        <label
          class="form-control-label"
          :class="{'required': language === 1}">New Category Short Name</label>
        <span
          v-tooltip="'This name is used in category breadcrumbs shown on product pages, category menus, and category tiles when browsing by category.  This name does not need reference to parent categories because they can be seen in previous selections and breadcrumbs.'"
          class="info">
          <i class="material-icons">
            info_outline
          </i>
        </span>
        <input
          v-model="details.language[language].categoryShortName"
          maxlength="65"
          class="form-control"
          :class="{ 'is-invalid': missingNames }"
          :placeholder="missingNames ? 'Category short name is required' : ''" />
      </div>
      <div
        class="form-group">
        <label
          class="form-control-label"
          :class="{'required': language === 1}">New Category Long Name</label>
        <span
          v-tooltip="'This name is used on category page titles and on category tiles on page when performing text searches.  This name gives additional context for categories when all category levels above it cannot be seen.'"
          class="info">
          <i class="material-icons">
            info_outline
          </i>
        </span>
        <input
          v-model="details.language[language].categoryLongName"
          maxlength="100"
          class="form-control"
          :class="{ 'is-invalid': missingNames }"
          :placeholder="missingNames ? 'Category long is required' : ''" />
      </div>
      <div class="form-group">
        <label class="form-control-label">
          Markets
        </label>
        <multi-select
          v-model="details.markets"
          :options="marketList"
          :searchable="false"
          :multiple="true"></multi-select>
      </div>
      <div class="form-group">
        <label class="form-control-label">
          OBSO Lifecycle
        </label>
        <select
          v-model="details.language[language].obsolescenceLifecycleId">
          <option
            v-for="l in lifecycles"
            :key="l.lifecycleId"
            :value="l.lifecycleId">
            {{ l.lifecycleText }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label class="form-control-label">
          Keywords
        </label>
        <tagging :tags.sync="details.language[language].keywords"></tagging>
      </div>
      <div class="form-group">
        <label class="form-control-label">
          Keywords (Search Only)
        </label>
        <tagging :tags.sync="details.language[language].keywordsSearchOnly"></tagging>
      </div>
      <div class="form-group">
        <label class="form-control-label">
          Canada RPN
        </label>
        <input
          v-model="details.canadaRpn"
          class="form-control" />
      </div>
      <div class="form-group">
        <label class="form-control-label">
          ETIM Class
        </label>
        <input
          v-model="details.etimClass"
          class="form-control"
          :maxlength="10" />
      </div>
      <div class="form-group">
        <label class="form-control-label">
          ETIM Class Description
        </label>
        <input
          v-model="details.etimClassDescription"
          class="form-control"
          :maxlength="100" />
      </div>
      <div class="form-group">
        <label class="form-control-label">
          ETIM Qualification
        </label>
        <select
          v-model="details.etimQualificationTypeId"
          class="form-control">
          <option :value="null">
            -- Select --
          </option>
          <option
            v-for="s in sustainableTypes"
            :key="s.id"
            :value="s.id">
            {{ s.name }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label class="form-control-label">
          ETIM Version
        </label>
        <input
          v-model="details.etimVersion"
          class="form-control"
          :maxlength="10" />
      </div>
      <div class="form-group">
        <label class="form-control-label">
          Sustainable Segment
        </label>
        <select
          v-model="details.sustainableSegmentId"
          class="form-control">
          <option :value="null">
            -- Select --
          </option>
          <option
            v-for="s in sustainableSegments"
            :key="s.id"
            :value="s.id">
            {{ s.name }}
          </option>
        </select>
      </div>
      <div class="d-flex justify-content-end my-4">
        <button
          :disabled="allCategoryLinks.length === 0"
          class="btn btn-secondary btn-rounded mr-2"
          @click="openModal">
          Category Link ({{ currentAliasLength }})
        </button>
        <button
          v-if="create"
          class="btn btn-primary btn-rounded"
          :disabled="loading"
          @click="saveNewCategory()">
          {{ loading ? 'Processing...' : 'Save Details' }}
        </button>
        <button
          v-else
          class="btn btn-primary btn-rounded"
          :disabled="loading"
          @click="updateCategory()">
          {{ loading ? 'Processing...' : 'Save Details' }}
        </button>
      </div>
      <associated-products
        v-if="!create"
        :product-list="products"
        :product-count="productCount"
        :show.sync="showProducts"
        :loading="!productsLoaded"
        @searchAssociated="getProducts($event)"></associated-products>

      <!-- Response Dialog -->
      <modal
        ref="saveDetailsRes"
        :title="`${details.language[language].name}`"
        :icon="modal.icon">
        <h6 class="lead mb-0 text-center">
          {{ modal.message }}
        </h6>
        <div
          slot="button"
          class="d-flex justify-content-end">
          <button
            v-if="deleteBtn"
            class="btn btn-gray-alt"
            @click="deleteBtnDone()">
            Done
          </button>
          <button
            v-else
            class="btn btn-gray-alt"
            @click="$refs.saveDetailsRes.close()">
            Done
          </button>
        </div>
      </modal>
    </div>
    <div
      v-else
      class="details-none">
      <h4>Please select a category<small>OR</small></h4>
      <button
        class="btn btn-rounded btn-primary"
        @click="createCategory()">
        Add Category
      </button>
    </div>
    <banner-specific-photo-modal
      v-if="details"
      :override-details.sync="overrideDetails"
      :details.sync="details"
      :show-modal.sync="showBannerSpecificModal"
      @close="showBannerSpecificModal = false"
      @save="saveFromBannerSpecificModal($event)"
      @error="errorFromBannerSpecificModal($event)"></banner-specific-photo-modal>
    <v-dialog
      v-model="dialog"
      max-width="1400px">
      <v-card>
        <v-card-title class="headline">
          Category Links
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="quickCategoryId"
                hide-spin-buttons
                type="number"
                min="0"
                outlined
                dense
                label="Quick Add Category ID"
                style="width: 295px"
                @keyup.enter="linkByCategoryId(quickCategoryId)">
              </v-text-field>
              <v-btn
                :disabled="quickCategoryId === null"
                class="mb-3"
                color="primary"
                @click="linkByCategoryId(quickCategoryId)">
                Link Category ID
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            class="mt-4"
            flex-nowrap>
            <v-col
              v-if="sectionList.length > 0"
              :cols="3">
              <v-select
                v-model="aliasTree.sectionId"
                :items="sectionList"
                item-text="name"
                item-value="id"
                dense
                label="Section"
                outlined
                @change="sectionSelected()">
              </v-select>
            </v-col>
            <v-col :cols="3">
              <v-select
                v-if="aliasTree.groupList.length != 0"
                v-model="aliasTree.groupId"
                :items="aliasTree.groupList"
                item-text="name"
                item-value="id"
                dense
                label="Group"
                outlined
                @change="groupSelected()">
              </v-select>
            </v-col>
            <v-col :cols="3">
              <v-select
                v-if="aliasTree.categoryList.length != 0"
                v-model="aliasTree.categoryId"
                :items="aliasTree.categoryList"
                item-text="name"
                item-value="id"
                dense
                label="Category"
                outlined
                @change="categorySelected()">
              </v-select>
            </v-col>
            <v-col :cols="3">
              <v-select
                v-if="aliasTree.subCategoryList.length != 0"
                v-model="aliasTree.subCategoryId"
                :items="aliasTree.subCategoryList"
                item-text="name"
                item-value="id"
                dense
                label="Sub Category"
                outlined>
              </v-select>
            </v-col>
          </v-row>
          <v-btn
            :disabled="aliasTree.sectionId === null"
            color="primary"
            @click="addCategoryLink">
            Add Link
          </v-btn>
          <v-data-table
            :headers="headers"
            :items="categoryLinks"
            :hide-default-footer="true">
            <template #items="props">
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.sectionName }}</td>
              <td>{{ props.item.groupName }}</td>
              <td>{{ props.item.categoryName }}</td>
              <td>{{ props.item.subCategoryName }}</td>
            </template>
            <template #item.actions="{item}">
              <v-btn
                color="primary"
                @click="deleteCategoryLink(item)">
                Delete
              </v-btn>
            </template>
          </v-data-table>
          <span class="text-danger">
            {{ validationError }}
          </span>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="saveCategoryLinks">
            Save
          </v-btn>
          <v-btn
            color="secondary"
            @click="dialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import qs from 'qs'
import EventBus from './bus'
import Tagging from 'components/tagging'
import Modal from 'components/modals/sweet'
import MultiSelect from 'components/inputs/multiselect.vue'
import DetailsMedia from './detailsMedia'
import AssociatedProducts from 'components/products/search/AssociatedProducts'
import BannerSpecificPhotoModal from './bannerSpecificPhotoModal'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CategoryDetails',
  components: {
    modal: Modal,
    tagging: Tagging,
    'multi-select': MultiSelect,
    'details-media': DetailsMedia,
    'associated-products': AssociatedProducts,
    'banner-specific-photo-modal': BannerSpecificPhotoModal
  },
  props: {
    id: {
      type: Number,
      default: null
    },
    markets: {
      type: Array,
      default: () => []
    },
    lifecycles: {
      type: Array,
      default: () => []
    },
    companies: {
      type: Array,
      default: () => []
    },
    sectionList: {
      type: Array,
      default: () => []
    },
    language: {
      type: Number,
      default: 1
    },
    sectionId: {
      type: Number,
      default: null
    },
    groupId: {
      type: Number,
      default: null
    },
    categoryId: {
      type: Number,
      default: null
    },
    subCategoryId: {
      type: Number,
      default: null
    },
    createView: {
      type: String,
      default: ''
    },
    detailView: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      sustainableSegments: null,
      sustainableTypes: null,
      modal: {
        icon: 'success',
        message: ''
      },
      products: [],
      productCount: null,
      deleteBtn: false,
      details: null,
      create: false,
      loading: false,
      cancelToken: null,
      showProducts: false,
      productsLoaded: false,
      showBannerSpecificModal: false,
      overrideDetails: null,
      dialog: false,
      categoryLinks: [],
      allCategoryLinks: [],
      aliasTree: {
        sectionId: null,
        groupId: null,
        categoryId: null,
        subCategoryId: null,
        groupList: [],
        categoryList: [],
        subCategoryList: []
      },
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Section', value: 'sectionName' },
        { text: 'Group', value: 'groupName' },
        { text: 'Category', value: 'categoryName' },
        { text: 'SubCategory', value: 'subCategoryName' },
        { text: '', value: 'actions', sortable: false }
      ],
      validationError: null,
      currentAliasLength: 0,
      quickCategoryId: null
    }
  },
  computed: {
    marketList () {
      return this.markets.map(m => m.name)
    },
    missingNames () {
      return (this.$v.details.language[1].name.$error || this.$v.details.language[1].categoryLongName.$error || this.$v.details.language[1].categoryShortName.$error) && this.language === 1
    }
  },
  watch: {
    id (value, oldValue) {
      if (value && value !== oldValue) {
        this.getDetails()
        this.getCurrentCategories()
      }
    }
  },
  mounted () {
    if (this.id) {
      this.getDetails()
    }
    this.getAllCategories()
    this.getSustainableSegments()
    this.getSustainableTypes()
    this.getCurrentCategories()
  },
  methods: {
    resetLinkForm () {
      this.validationError = null
      this.aliasTree.sectionId = null
      this.aliasTree.sectionName = null
      this.aliasTree.groupId = null
      this.aliasTree.groupName = null
      this.aliasTree.categoryId = null
      this.aliasTree.categoryName = null
      this.aliasTree.subCategoryId = null
      this.aliasTree.subCategoryName = null
      this.aliasTree.groupList = []
      this.aliasTree.categoryList = []
      this.aliasTree.subCategoryList = []
    },
    isLinkInvalid (id) {
      if (this.categoryLinks.find(x => x.id === id) != null) {
        this.validationError = 'This link already exists.'
        return true
      } else if (id === this.id) {
        this.validationError = 'You cannot add a link to itself.'
        return true
      } else if (this.allCategoryLinks.find(x => x.id === id) === undefined) {
        this.validationError = 'This categoryID does not exist.'
        return true
      }
      this.validationError = null
      return false
    },
    linkByCategoryId (id) {
      id = parseInt(id)

      if (this.isLinkInvalid(id)) {
        return
      }
      const firstCategory = this.allCategoryLinks.find(x => x.id === id)
      this.quickCategoryId = null
      let secondCategory = null
      let thirdCategory = null
      let fourthCategory = null

      if (firstCategory.parentCategoryId != null) {
        secondCategory = this.allCategoryLinks.find(x => x.id === firstCategory.parentCategoryId)

        if (secondCategory.parentCategoryId != null) {
          thirdCategory = this.allCategoryLinks.find(x => x.id === secondCategory.parentCategoryId)

          if (thirdCategory.parentCategoryId != null) {
            fourthCategory = this.allCategoryLinks.find(x => x.id === thirdCategory.parentCategoryId)
          }
        }
      }

      const categoryLink = {
        sectionId: null,
        sectionName: null,
        groupId: null,
        groupName: null,
        categoryId: null,
        categoryName: null,
        subCategoryId: null,
        subCategoryName: null,
        id: null
      }

      if (secondCategory == null) {
        categoryLink.id = categoryLink.sectionId = firstCategory.id
        categoryLink.sectionName = firstCategory.categoryLegacyName
      } else if (thirdCategory == null) {
        categoryLink.id = categoryLink.groupId = firstCategory.id
        categoryLink.groupName = firstCategory.categoryLegacyName

        categoryLink.sectionId = secondCategory.id
        categoryLink.sectionName = secondCategory.categoryLegacyName
      } else if (fourthCategory == null) {
        categoryLink.id = categoryLink.categoryId = firstCategory.id
        categoryLink.categoryName = firstCategory.categoryLegacyName

        categoryLink.groupId = secondCategory.id
        categoryLink.groupName = secondCategory.categoryLegacyName

        categoryLink.sectionId = thirdCategory.id
        categoryLink.sectionName = thirdCategory.categoryLegacyName
      } else {
        categoryLink.id = categoryLink.subCategoryId = firstCategory.id
        categoryLink.subCategoryName = firstCategory.categoryLegacyName

        categoryLink.categoryId = secondCategory.id
        categoryLink.categoryName = secondCategory.categoryLegacyName

        categoryLink.groupId = thirdCategory.id
        categoryLink.groupName = thirdCategory.categoryLegacyName

        categoryLink.sectionId = fourthCategory.id
        categoryLink.sectionName = fourthCategory.categoryLegacyName
      }

      this.categoryLinks.push(categoryLink)
    },
    getCurrentCategories () {
      if (this.id == null || this.id === 0) return
      this.categoryLinks = []

      axios.get('/api/categories/links/' + this.id)
        .then(res => {
          this.currentAliasLength = res.data.length
          for (let i = 0; i < res.data.length; i++) {
            const linkFromCategoryId = res.data[i].linkFromCategoryId
            this.linkByCategoryId(linkFromCategoryId)
          }
        })
        .catch(e => {
          console.error(e)
        })
    },
    saveCategoryLinks () {
      if (this.currentAliasLength === 0 && this.categoryLinks.length === 0) {
        this.validationError = 'There were no changes made.'
        return
      }

      const model = {
        id: this.id,
        links: ''
      }

      for (let i = 0; i < this.categoryLinks.length; i++) {
        model.links += this.categoryLinks[i].id + ','
      }

      model.links = model.links.slice(0, -1)

      axios.post('/api/categories/links', model)
        .then(res => {
          if (res.data) {
            this.currentAliasLength = this.categoryLinks.length
          } else {
            this.validationError = 'An error occured. Please contact support.'
          }
        })
        .catch(e => {
          console.error(e)
          this.validationError = 'An error occured. Please contact support.'
        })
        .finally(() => {
          this.dialog = false
        })
    },
    deleteCategoryLink (item) {
      this.categoryLinks = this.categoryLinks.filter(x => {
        return x.id !== item.id
      })
    },
    getAllCategories () {
      axios.get('/api/categories/all')
        .then(res => {
          this.allCategoryLinks = res.data
        })
        .catch(e => {
          console.error(e)
        })
    },
    addCategoryLink () {
      const categoryLink = {
        sectionId: this.aliasTree.sectionId,
        sectionName: this.aliasTree.sectionId === null ? null : this.allCategoryLinks.find(x => x.id === this.aliasTree.sectionId).categoryLegacyName ?? null,
        groupId: this.aliasTree.groupId,
        groupName: this.aliasTree.groupId === null ? null : this.allCategoryLinks.find(x => x.id === this.aliasTree.groupId).categoryLegacyName ?? null,
        categoryId: this.aliasTree.categoryId,
        categoryName: this.aliasTree.categoryId === null ? null : this.allCategoryLinks.find(x => x.id === this.aliasTree.categoryId).categoryLegacyName ?? null,
        subCategoryId: this.aliasTree.subCategoryId,
        subCategoryName: this.aliasTree.subCategoryId === null ? null : this.allCategoryLinks.find(x => x.id === this.aliasTree.subCategoryId).categoryLegacyName ?? null,
        id: null
      }

      if (categoryLink.subCategoryId != null) {
        categoryLink.id = categoryLink.subCategoryId
      } else if (categoryLink.categoryId != null) {
        categoryLink.id = categoryLink.categoryId
      } else if (categoryLink.groupId != null) {
        categoryLink.id = categoryLink.groupId
      } else if (categoryLink.sectionId != null) {
        categoryLink.id = categoryLink.sectionId
      }

      if (this.isLinkInvalid(categoryLink.id)) {
        return
      }

      this.categoryLinks.push(categoryLink)
      this.resetLinkForm()
    },
    sectionSelected () {
      if (this.aliasTree.sectionId) {
        this.aliasTree.groupId = null
        this.aliasTree.categoryId = null
        this.aliasTree.subCategoryId = null
        this.aliasTree.groupList = []
        this.aliasTree.categoryList = []
        this.aliasTree.subCategoryList = []
        this.getGroups()
      }
    },
    groupSelected () {
      if (this.aliasTree.groupId) {
        this.aliasTree.categoryId = null
        this.aliasTree.subCategoryId = null
        this.aliasTree.categoryList = []
        this.aliasTree.subCategoryList = []
        this.getCategories()
      }
    },
    categorySelected () {
      if (this.aliasTree.categoryId) {
        this.aliasTree.subCategoryId = null
        this.aliasTree.subCategoryList = []
        this.getSubCategories()
      }
    },
    fetchData (id) {
      const query = (id)
        ? `/api/categories/${id}/children?languageId=${this.language}`
        : `/api/categories/children?languageId=${this.language}`
      return axios.get(query)
        .then(res => {
          return res.data
        })
        .catch(e => {
          console.error(e)
        })
    },
    getGroups () {
      this.fetchData(this.aliasTree.sectionId)
        .then(res => {
          this.aliasTree.groupList = res
        })
    },
    getCategories () {
      this.fetchData(this.aliasTree.groupId)
        .then(res => {
          this.aliasTree.categoryList = res
        })
    },
    getSubCategories () {
      this.fetchData(this.aliasTree.categoryId)
        .then(res => {
          this.aliasTree.subCategoryList = res
        })
    },
    openModal () {
      this.resetLinkForm()
      this.getCurrentCategories()
      this.dialog = true
    },
    getSustainableTypes () {
      return axios.get('/api/sustainability/types')
        .then(res => {
          this.sustainableTypes = res.data
        })
        .catch(e => {
          console.error(e)
        })
    },
    getSustainableSegments () {
      return axios.get('/api/sustainability/segment')
        .then(res => {
          this.sustainableSegments = res.data
        })
        .catch(e => {
          console.error(e)
        })
    },
    getDetails () {
      this.productsLoaded = false
      this.showProducts = false
      this.productCount = null
      this.products = []
      this.create = false
      this.deleteBtn = false
      this.$http.get(`/api/categories/${this.id}`)
        .then(res => {
          const response = res.data.details
          this.getProducts()
          response.forEach(langObj => {
            langObj.markets = langObj.markets ? langObj.markets.split(';') : []
            langObj.keywords = langObj.keywords ? langObj.keywords.split(',') : []
            langObj.keywordsSearchOnly = langObj.keywordsSearchOnly ? langObj.keywordsSearchOnly.split(',') : []
          })
          this.details = {
            markets: response[0].markets,
            mediaId: response[0].mediaId,
            mainImage: response[0].mainImage,
            language: {
              1: response.find(langObj => langObj.languageId === 1), // English
              2: response.find(langObj => langObj.languageId === 2), // Spanish
              3: response.find(langObj => langObj.languageId === 3) // French
            },
            webCategoryId: response[0].webCategoryId,
            canadaRpn: response[0].canadaRpn,
            sustainableSegmentId: response[0].sustainableSegmentId,
            etimClass: response[0].etimClass,
            etimClassDescription: response[0].etimClassDescription,
            etimQualificationTypeId: response[0].etimQualificationTypeId,
            etimVersion: response[0].etimVersion
          }
          this.overrideDetails = res.data.overrides
        }).catch(e => console.warn(e))
    },
    getProducts (searchQuery) {
      const params = {
        size: 100,
        sectionId: this.sectionId,
        groupId: this.groupId,
        categoryId: this.categoryId,
        subCategoryId: this.subCategoryId,
        languageId: this.language,
        searchTerm: searchQuery
      }
      axios.get('/api/products', {
        params,
        paramsSerializer (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        }
      }).then(res => {
        const result = res.data
        this.productsLoaded = true
        this.products = result.products
        this.productCount = result.resultCount
      })
    },
    errorFromBannerSpecificModal (event) {
      this.modal.icon = 'error'
      this.modal.message = 'Error while saving banner specific photo. Refresh and try again.'
      return this.$refs.saveDetailsRes.open()
    },
    saveFromBannerSpecificModal (event) {
      this.details.mediaId = event
      this.updateCategory()
      this.getDetails()
    },
    updateCategory () {
      this.$v.details.$touch()
      if (this.$v.details.$error) {
        this.modal.icon = 'error'
        this.modal.message = 'An English Category Name is required prior to saving.'
        return this.language !== 1 ? this.$refs.saveDetailsRes.open() : false
      } else {
        const data = {
          id: this.id,
          languageId: this.language,
          mediaId: this.details.mediaId,
          markets: this.details.markets.join(),
          name: this.details.language[this.language].name,
          description: this.details.language[this.language].description,
          keywords: this.details.language[this.language].keywords.join(),
          canadaRpn: this.details.canadaRpn,
          categoryShortName: this.details.language[this.language].categoryShortName,
          categoryLongName: this.details.language[this.language].categoryLongName,
          obsolescenceLifecycleId: this.details.language[this.language].obsolescenceLifecycleId,
          sustainableSegmentId: this.details.sustainableSegmentId,
          etimClass: this.details.etimClass,
          etimClassDescription: this.details.etimClassDescription,
          etimQualificationTypeId: this.details.etimQualificationTypeId,
          etimVersion: this.details.etimVersion
        }
        this.loading = true
        this.$http.put('/api/categories', data)
          .then(res => {
            this.modal.icon = 'success'
            this.modal.message = 'Saved Changes!'
          }).catch(e => {
            this.modal.icon = 'error'
            this.modal.message = 'Error, please try again.'
          }).then(() => {
            this.loading = false
            this.$v.details.$reset()
            this.$refs.saveDetailsRes.open()
          })
      }
    },
    deleteCategory () {
      if (!this.productsLoaded || this.products.length) {
        return false
      }
      this.loading = true
      this.$http.delete(`/api/categories/${this.id}`)
        .then(res => {
          this.modal.icon = 'success'
          this.modal.message = 'Successfully deleted category'
          EventBus.$emit('refreshSection')
          EventBus.$emit('catsRemoved')
        }).catch(e => {
          this.modal.icon = 'error'
          this.modal.message = 'Error occurred when deleting category, please try again.'
        }).then(() => {
          this.loading = false
          this.deleteBtn = true
          this.$refs.saveDetailsRes.open()
        })
    },
    deleteBtnDone () {
      this.$refs.saveDetailsRes.close()
      this.details = null
      const rp = this.$route.params
      if (Object.keys(rp).length !== 0 && rp.constructor === Object) {
        this.$router.back()
      }
    },
    createCategory () {
      this.create = true
      this.products = []
      this.productCount = null
      this.details = {
        markets: [],
        mediaId: null,
        folderName: null,
        parentId: this.id,
        canadaRpn: null,
        language: {
          1: {
            name: '',
            keywords: [],
            keywordsSearchOnly: [],
            languageId: 1,
            description: '',
            categoryShortName: '',
            obsolescenceLifecycleId: null
          },
          2: {
            name: '',
            keywords: [],
            keywordsSearchOnly: [],
            languageId: 1,
            description: '',
            categoryShortName: '',
            categoryLongName: '',
            obsolescenceLifecycleId: null
          },
          3: {
            name: '',
            keywords: [],
            keywordsSearchOnly: [],
            languageId: 1,
            description: '',
            categoryShortName: '',
            categoryLongName: '',
            obsolescenceLifecycleId: null
          }
        }
      }
      this.$v.details.$reset()
    },
    saveNewCategory () {
      this.$v.details.$touch()
      if (this.$v.details.$error) {
        this.modal.icon = 'error'
        this.modal.message = 'An Image, English Category Name, English Category Short and English Category Long Name are required prior to saving.'
        return this.$refs.saveDetailsRes.open()
      } else {
        const data = {
          mediaId: this.details.mediaId,
          markets: this.details.markets.join(),
          parentCategoryId: !this.details.parentId ? null : this.details.parentId,
          englishName: this.details.language[1].name,
          spanishName: this.details.language[2].name,
          frenchName: this.details.language[3].name,
          englishShortName: this.details.language[1].categoryShortName,
          spanishShortName: this.details.language[2].categoryShortName,
          frenchShortName: this.details.language[3].categoryShortName,
          englishLongName: this.details.language[1].categoryLongName,
          spanishLongName: this.details.language[2].categoryLongName,
          frenchLongName: this.details.language[3].categoryLongName,
          englishDescription: this.details.language[1].description,
          spanishDescription: this.details.language[2].description,
          frenchDescription: this.details.language[3].description,
          englishKeywords: this.details.language[1].keywords.join(),
          englishKeywordsSearchOnly: this.details.language[1].keywordsSearchOnly.join(),
          spanishKeywords: this.details.language[2].keywords.join(),
          spanishKeywordsSearchOnly: this.details.language[2].keywordsSearchOnly.join(),
          frenchKeywords: this.details.language[3].keywords.join(),
          frenchKeywordsSearchOnly: this.details.language[3].keywordsSearchOnly.join(),
          canadaRpn: this.details.canadaRpn,
          obsolescenceLifecycleId: this.details.language[this.language].obsolescenceLifecycleId,
          sustainableSegmentId: this.details.sustainableSegmentId,
          etimClass: this.details.etimClass,
          etimClassDescription: this.details.etimClassDescription,
          etimQualificationTypeId: this.details.etimQualificationTypeId,
          etimVersion: this.details.etimVersion
        }
        this.loading = true

        this.$http.post('/api/categories', data).then(res => {
          this.loading = false
          const r = this.$route
          const rp = r.params
          EventBus.$emit('updateCats')
          if (rp.categoryId) {
            this.$emit('update:detailView', 'Sub Category')
            this.$router.push({
              name: r.name,
              params: {
                sectionId: rp.sectionId,
                groupId: rp.groupId,
                categoryId: rp.categoryId,
                subCategoryId: res.data
              }
            })
          } else if (rp.groupId) {
            this.$emit('update:detailView', 'Category')
            this.$router.push({
              name: r.name,
              params: {
                sectionId: rp.sectionId,
                groupId: rp.groupId,
                categoryId: res.data
              }
            })
          } else if (rp.sectionId) {
            this.$emit('update:detailView', 'Group')
            this.$router.push({
              name: r.name,
              params: {
                sectionId: rp.sectionId,
                groupId: res.data
              }
            })
          } else if (Object.keys(rp).length === 0 && rp.constructor === Object) {
            this.$emit('update:detailView', 'Section')
            EventBus.$emit('refreshSection')
            this.$router.replace({
              name: r.name,
              params: {
                sectionId: res.data
              }
            })
          } else {
            EventBus.$emit('refreshSection')
            this.$router.push({
              name: 'categoryChildGroup',
              params: {
                sectionId: res.data
              }
            })
          }
        })
      }
    }
  },
  validations () {
    return this.details
      ? {
          details: {
            mediaId: {
              required
            },
            language: {
              1: {
                name: {
                  required
                },
                categoryShortName: {
                  required
                },
                categoryLongName: {
                  required
                }
              }
            }
          }
        }
      : {}
  }
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/extra/mixins/flexbox";
@import "../../../assets/scss/bootstrap-custom-variables";

.category-details {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 1rem;
  height: 100%;
  min-height: calc(100% - 1.5rem);
  position: relative;
  .category-label {
    position: absolute;
    top: 0.5rem;
    right: 1.5rem;
    font-style: italic;
    letter-spacing: 0.5px;
    color: $gray-500;
  }
  .details-none {
    text-align: center;
    height: 50rem;
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    h4 {
      color: $gray-700;
      margin-bottom: 0;
      small {
        display: block;
        padding: 1rem 0;
      }
    }
    .btn {
      padding: 0.5rem 1.5rem;
    }
  }
  .details-heading {
    margin-bottom: 0.5rem;
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);
    .title {
      @include flex(1, 1, 75%);
      @include flexbox;
      @include align-items(center);
      .title--text {
        margin: 0 1rem;
        h4 {
          margin: 0;
        }
        small {
          display: block;
          font-weight: 500;
        }
      }
    }
    .dropdown {
      margin-bottom: 1.75rem;
      &.show {
        .dropdown-toggle {
          color: $gray-light;
        }
      }
      .dropdown-toggle {
        cursor: pointer;
        font-size: 1.5rem;
        &:after {
          display: none;
        }
      }
      .dropdown-menu {
        padding-top: 0.5rem;
        margin: 10px -10px 0 0;
        &:before,
        &:after {
          position: absolute;
          display: block;
          width: 0;
          height: 0;
          border-color: transparent;
          border-style: solid;
        }
        &:before {
          content: "";
          border-width: 11px;
          top: -11px;
          margin-right: -11px;
          border-bottom-color: rgba(0, 0, 0, 0.25);
          right: 20px;
          border-top-width: 0;
        }
        &:after {
          content: "";
          border-width: 10px;
          right: 20px;
          border-top-width: 0;
          top: -10px;
          margin-right: -10px;
          border-bottom-color: #fff;
        }
        .dropdown-item {
          &.disabled:hover {
            cursor: not-allowed;
            color: $gray-200;
          }
        }
      }
    }
  }

  .lang-nav {
    padding: 1rem 0;
    @include flexbox;
    @include align-items(center);
    @include flex-wrap(wrap);
    .btn {
      margin: 0 0.5rem 0.5rem;
    }
  }

  // misc styles
  .btn {
    cursor: pointer;
  }
  .form-control-label.required:after {
    content: "*";
  }
  .form-group.is-invalid {
    .form-control-label,
    .form-control::placeholder {
      color: $brand-danger;
    }
  }
}
</style>
