<template>
  <div>
    <modal
      ref="editAttributeTypeModal"
      :icon="typeModal.icon"
      :blocking="typeModal.blockClose"
      :title="typeModal.title"
      @close="resetModal()">
      <div v-if="saveResult">
        <h6 class="text-center">
          {{ typeModal.message }}
        </h6>
      </div>
      <template v-else>
        <upload-image-attr
          :is-saving="isSaving"
          :attr-image-file="details.folderName"
          @setUploadData="setUploadData"></upload-image-attr>
        <div class="form-group">
          <label class="form-control-label required">
            Type
          </label>
          <input
            v-model="details.type"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.details.type.$error }" />
        </div>
        <div class="form-group">
          <label class="form-control-label required">
            Description
          </label>
          <textarea
            v-model="details.description"
            class="form-control"
            :class="{ 'is-invalid': $v.details.description.$error }"></textarea>
        </div>
      </template>
      <div
        slot="button"
        class="action-btns">
        <button
          type="button"
          class="btn btn-link"
          @click="cancelEdit()">
          Cancel
        </button>
        <button
          v-if="saveResult"
          type="button"
          class="btn btn-primary"
          @click="$refs.editAttributeTypeModal.close()">
          Done
        </button>
        <button
          v-else
          type="button"
          class="btn btn-primary"
          :disabled="loading || $v.details.$invalid"
          @click="saveEdit()">
          {{ !loading ? 'Save' : 'Saving' }}
        </button>
      </div>
    </modal>
  </div>
</template>
<script type="text/javascript">
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
import ModalComponent from 'components/modals/sweet.vue'
import MediaFileTypesMixin from '@/views/media/types/MediaFileTypes.mixin'
// import Uploading from '@/views/media/upload/uploading'
// import UploadZone from '@/views/media/upload/uploadZone.vue'
// import Cropper from 'components/crop'
import UploadImageAttr from './uploadImageAttribute.vue'

export default {
  name: 'EditAttributeType',
  components: {
    modal: ModalComponent,
    // UploadZone,
    // Uploading,
    // Cropper,
    UploadImageAttr
  },
  mixins: [MediaFileTypesMixin],
  props: {
    showModal: Boolean,
    details: Object
  },
  data () {
    return {
      typeModal: {
        title: 'Edit Attribute Type',
        message: '',
        icon: null,
        blockClose: false
      },
      formData: {},
      isSaving: false,
      uploadModal: false,
      editing: false,
      loading: false,
      saveResult: false,
      original: {}
    }
  },
  computed: {
    language () {
      return this.$store.getters.languageId
    }
  },
  watch: {
    showModal (value) {
      if (value) {
        this.modalOpen()
      } else {
        this.$refs.editAttributeTypeModal.close()
      }
    },
    uploadModal (value) {
      if (value) {
        this.$refs.uploadMfrImage.open()
        this.editing = false
      } else {
        this.editing = true
        this.$refs.uploadMfrImage.close()
      }
    }
  },
  methods: {
    setUploadData (data) {
      this.formData = data
    },
    resetModal () {
      this.$emit('update:showModal', false)
      this.saveResult = false
      this.typeModal = {
        title: 'Edit Attribute Type',
        message: '',
        icon: null,
        blockClose: false
      }
      this.formData = {}
      this.isSaving = true
    },
    modalOpen () {
      this.original = JSON.stringify(this.details)
      this.$refs.editAttributeTypeModal.open()
      this.saveResult = false
      this.isSaving = false
    },
    saveEdit () {
      this.$v.details.$touch()

      const { file, points, fileName, rotated } = this.formData
      const { typeId, type, description } = this.details

      const data = new FormData()
      data.append('file', file || null)
      data.append('x1', points ? points[0] : null)
      data.append('y1', points ? points[1] : null)
      data.append('x2', points ? points[2] : null)
      data.append('y2', points ? points[3] : null)
      data.append('fileName', fileName || null)
      data.append('rotate', rotated || null)
      data.append('attributeTypeId', typeId)
      data.append('nameEng', type)
      data.append('descriptionEng', description)
      data.append('nameEsp', type)
      data.append('descriptionEsp', description)
      data.append('nameFrn', type)
      data.append('descriptionFrn', description)
      this.loading = true
      if (!this.$v.details.$invalid) {
        axios.post('/api/attributes/editAttributeTypeTranslationAsync', data, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
          this.saveResult = true
          if (res.status === 200 || res.status === 204) {
            this.$emit('fetch', true)

            this.loading = false
            this.typeModal.icon = 'success'
            this.typeModal.message = `Successfully updated the attribute type of ${this.details.type}`
          } else {
            this.loading = false
            this.typeModal.icon = 'error'
            this.typeModal.message = `Error updating the attribute type of ${this.details.type}`
          }
          setTimeout(() => {
            this.$refs.editAttributeTypeModal._animateIcon()
          }, 50)
        })
      }
    },
    cancelEdit () {
      this.$emit('update:details', JSON.parse(this.original))
      this.resetModal()
    }
  },
  validations () {
    return {
      details: {
        type: { required },
        description: { required }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/scss/extra/mixins/flexbox.scss';
@import '../../assets/scss/bootstrap-custom-variables.scss';

.action-btns {
  @include flexbox;
  @include justify-content(space-between);
  .btn {
    min-width: 8.5rem;
  }
}
</style>
