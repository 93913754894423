<template>
  <div class="user-list">
    <div class="user-body">
      <h5 class="user-title">
        Users
      </h5>
      <div class="user-nav">
        <div class="user-filter">
          <label>Sort by:</label>
          <ul class="list-unstyled">
            <li>
              <a
                class="filter"
                :class="{'active': userSort == null}"
                @click="userSort = null">
                All
              </a>
            </li>
            <li>
              <a
                class="filter"
                :class="{'active': userSort == true}"
                @click="userSort = true">
                Active
              </a>
            </li>
            <li>
              <a
                class="filter"
                :class="{'active': userSort == false}"
                @click="userSort = false">
                Inactive
              </a>
            </li>
          </ul>
        </div>
        <div class="user-create">
          <button
            type="button"
            class="btn btn-primary btn-rounded"
            @click="showCreateModal = true">
            Create New User
          </button>
        </div>
      </div>
      <div
        v-if="filteredUsers.length == 0"
        class="alert alert-info">
        <span>Sorry, no users match
          <span v-if="search != ''">
            <strong>"{{ search }}"</strong> in </span>
          <strong v-if="companyFilter.name != 'All'">
            {{ companyFilter.name }} Company
          </strong>filter</span>
      </div>
      <ul
        v-else
        class="list-unstyled">
        <li
          v-for="(user, index) in filteredUsers"
          :key="'user-' + user.userId"
          class="list-item"
          @click.ctrl="updateUserDetails(user, index)">
          <router-link
            tag="div"
            :to="{name: 'userDetail', params: {id: user.userId}}"
            class="card"
            :class="{'user-inactive': !user.activeInd}">
            <div class="avatar">
              <avatar
                :username="user.displayName"
                :size="50"
                :rounded="false"></avatar>
            </div>
            <div class="user-card">
              <div
                class="info"
                style="background: #eceeef !important;">
                <span class="user-name">
                  {{ formatResult(user.displayName, search) }}
                </span>
                <span class="user-email">
                  {{ formatResult(user.emailAddress, search) }}
                </span>
              </div>
              <div class="action">
                <i class="material-icons">
                  &#xE5C8;
                </i>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
    <!--Edit Modal-->
    <edit
      :show-modal.sync="showEditModal"
      :user="userDetail"></edit>
    <!--Create Modal-->
    <create
      :show-modal.sync="showCreateModal"
      :company-list="companies"
      @user="emitUpdateUser"></create>
  </div>
</template>
<script type="text/javascript">
import UserAvatar from 'components/UserAvatar.vue'
import EditUser from './edit.vue'
import CreateUser from './create.vue'

export default {
  name: 'UserList',
  components: {
    avatar: UserAvatar,
    edit: EditUser,
    create: CreateUser
  },
  props: {
    search: {
      type: String,
      default: ''
    },
    userList: {
      type: Array,
      default: () => [],
      required: true
    },
    teamFilter: {
      type: Object,
      default: () => {}
    },
    companyFilter: {
      type: Object,
      default: () => {}
    },
    companies: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      userSort: true,
      userDetail: {
        displayName: '',
        emailAddress: ''
      },
      showEditModal: false,
      showCreateModal: false
    }
  },
  computed: {
    filteredUsers () {
      const search = this.search
      const sort = this.userSort
      const users = this.userList
      const teamId = this.teamFilter.teamId
      const companyId = this.companyFilter.companyId
      const sortedUsers = sort !== null ? users.filter(u => u.activeInd === sort) : users
      const sortedByTeam = teamId ? sortedUsers.filter(u => u.team === teamId) : sortedUsers
      const sortedByCompany = companyId
        ? sortedByTeam
          .filter(u => u.defaultCompany === companyId || u.userPermissions.find(y => y.companyId === companyId && (y.contributor || y.isAdmin || y.isEditor || y.marketing)))
        : sortedByTeam
      const searchRegEx = new RegExp(`(?=.*${search.split(/,|\s/).join(')(?=.*')})`, 'gi')
      if (search.length) {
        const searchFilter = sortedByCompany.filter(user => {
          return user.displayName.match(searchRegEx) || user.emailAddress.match(searchRegEx)
        })
        return searchFilter
      }
      return sortedByCompany
    }
  },
  methods: {
    emitUpdateUser (event) {
      this.$emit('user', event)
    },
    updateUserDetails (user) {
      this.merge({ data: user.userPermissions }, { data: this.companies })
      this.showEditModal = true
      this.userDetail = user
    },
    merge (one, two) {
      if (!one.data) {
        return { data: two.data }
      }
      if (!two.data) {
        return { data: one.data }
      }
      const final = {
        data: one.data
      }
      // merge
      for (let i = 0; i < two.data.length; i++) {
        const item = two.data[i]
        this.insert(item, final)
      }
      return final
    },
    insert (item, obj) {
      const data = obj.data
      let insertIndex = data.length
      for (let i = 0; i < data.length; i++) {
        if (item.companyId === data[i].companyId) {
          // ignore duplicates
          insertIndex = -1
          break
        } else if (item.companyId < data[i].companyId) {
          insertIndex = i
          break
        }
      }
      if (insertIndex === data.length) {
        data.push(item)
      } else if (insertIndex !== -1) {
        data.splice(insertIndex, 0, item)
      }
    },
    formatResult (suggestion, search) {
      // Do not replace anything if value is empty
      if (!search || search === '') {
        return suggestion
      }

      return suggestion.toLowerCase()
        .replace(new RegExp(`(${search.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')})`, 'gi'), '<b>$1</b>')
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/&lt;(\/?b)&gt;/g, '<$1>')
    }
  }
}

</script>
<style lang="scss">
@import '../../../assets/scss/bootstrap-custom-variables.scss';
@import '../../../assets/scss/extra/mixins/flexbox.scss';

#team-builder {
  .user-list {
    .btn {
      cursor: pointer;
    }
    .user-body {
      padding: 1rem 0;
    }
    .user-title {
      font-size: 1.5rem;
      font-weight: 500;
      padding: 0 .5rem 1rem;
      margin-bottom: 1.5rem;
      border-bottom: 1px solid $gray-500;
    }
    .user-nav {
      padding: 0 .5rem;
      // margin-bottom: .5rem;
      @include flexbox;
      @include justify-content(space-between);
      .user-filter {
        @include flexbox;
        font-size: 1rem;
        color: #999;
        padding-top: .5rem;
        > label {
          font-weight: 500;
        }
        > ul {
          margin-left: 1rem;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          li {
            display: inline-block;
            width: 5rem;

            .filter {
              display: block;
              text-align: center;
              padding: 0 .5rem .3125rem;
              &.active {
                font-weight: 500;
                border-bottom: 3px solid $brand-primary;
              }
              &:hover:not(.active) {
                border-bottom: 3px solid $gray-lighter;
              }
            }
          }
        }
      }
    }

    ul.list-unstyled {
      @include flexbox;
      @include flex-wrap(wrap);
      li.list-item {
        padding: .5rem;
        @include flex(0,1,100%);
        @media (min-width: 35.5rem) {
          @include flex(0,1,calc(100% / 2))
        }
        @media (min-width: 75rem) {
          @include flex(0,1,calc(100% / 3))
        }
        @media (min-width: 105rem) {
          @include flex(0,1,calc(100% / 4))
        }

        .card {
          border: 0;
          width: 100%;
          @include flexbox;
          @include flex-direction(row);
          &.user-inactive {
            .user-card {
              border-color: $state-danger-border;
              background: $state-danger-bg;
              color: $state-danger-text;
              &:hover {
                border-color: darken($state-danger-border, 5%);
                background: darken($state-danger-bg, 5%);
                >.action {
                  border-left: 1px solid darken($state-danger-border, 5%);
                }
              }
              >.info {
                .user-email {
                  color: lighten($state-danger-text, 5%);
                }
              }
              >.action {
                border-left: 1px solid $state-danger-border;
              }
            }
          }
          >.avatar {
            @include flex(0, 1, 2.5rem);
            @include align-self(center);
          }
          .user-card {
            background: $gray-lighter;
            border-width: 1px 1px 1px 0;
            border-style: solid;
            border-color: rgba(0, 0, 0, .125);
            @include flexbox;
            @include flex(1, 1, 80%);
            @include justify-content(space-between);
            &:hover {
              background: darken($gray-lighter, 5%);

              cursor: pointer;
              i.material-icons {
                font-weight: bold;
              }
            }

            >.info {
              padding: .25rem .5rem;
              margin-left: .5rem;
              @include flexbox;
              @include flex-direction(column);
              @include justify-content(center);
              .user-name, .user-email {
                b { color: $cyan; }
              }
              .user-name {
                text-transform: uppercase;
                font-size: .875rem;
                display: block;
                line-height: .75;
                padding-top: .25rem;
                font-weight: 500;
              }
              .user-email {
                text-transform: lowercase;
                font-style: italic;
                font-size: .8125rem;
                color: lighten(#444, 15%);
              }
            }
            >.action {
              border-left: 1px solid #c9c9c9;
              padding: .25rem .75rem;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              >i {
                font-size: 1.25rem;
                line-height: 2;
              }
            }
          }
        }
      }
    }
    .modal-header {
      padding: 1rem 0 !important;
      >div {
        width: 100%;

        @include flexbox;
        @include justify-content(space-between);
        h3 {
          padding: 0;

          @include flex(1, 1, 80%);
        }
        a {
          @include flex(0, 1, auto);
          .material-icons {
            cursor: pointer;
            font-size: 1.5rem;
            &:hover {
              font-weight: bold;
            }
          }
        }
      }
    }
    .modal-footer {
      .btn {
        cursor: pointer;
      }
    }
  }
}
</style>
