<template>
  <div>
    <page-title title="Slang Terms"></page-title>
    <div
      class="spinner"
      :class="{'show-spinner': showSpinner }"></div>
    <div class="slangTerms-nav">
      <div class="btn-nav">
        <router-link
          class="btn btn-rounded btn-gray-alt"
          tag="button"
          :to="{name: 'slangTerms', params: {status: 'approved'}}"
          :active-class="'btn-primary'">
          Approved
        </router-link>
        <router-link
          class="btn btn-rounded btn-gray-alt btn-pending"
          tag="button"
          :to="{name: 'slangTerms', params: {status: 'pending'}}"
          :active-class="'btn-primary'">
          Pending
          <span
            v-if="totalPending"
            class="number-of-pending badge badge-pill">
            {{ totalPending }}
          </span>
        </router-link>
        <router-link
          class="btn btn-rounded btn-gray-alt"
          tag="button"
          :to="{name: 'slangTerms', params: {status: 'denied'}}"
          :active-class="'btn-primary'">
          Denied
        </router-link>
      </div>
      <hr class="mt-0" />
    </div>
    <div v-if="!showSpinner">
      <div class="d-flex flex-row justify-content-between">
        <search-filter
          v-model="search"
          class="search-slang-terms"
          placeholder="Search..."></search-filter>
        <action-list
          v-if="status === 'pending'"
          :id="'select-all-action-list'"
          :btn-class="'btn btn-gray-alt'"
          dropdown-right
          :dropdown-arrow-location="'13%'">
          <template slot="action-btn">
            <span class="action-btn-select-all">
              Action
            </span>
          </template>
          <template slot="action-content">
            <div
              class="action-dropdown-item"
              @click="performBulkActionOnSelected(actions.approve)">
              Approve selected
            </div>
            <div
              class="action-dropdown-item"
              @click="performBulkActionOnSelected(actions.approveSearch)">
              Approve selected for search
            </div>
            <div
              class="action-dropdown-item"
              @click="performBulkActionOnSelected(actions.deny)">
              Deny selected
            </div>
          </template>
        </action-list>
      </div>
      <div class="mt-4 mb-4">
        <!-- APPROVED SLANG TERMS TABLE -->
        <data-table
          v-if="status === 'approved'"
          :headers="approvedHeaders"
          :pagination.sync="pagination"
          :pagination-options.sync="paginationOptions"
          :search="search"
          :max-height="500"
          :items="slangTerms[status]"
          :loading="loading"
          :body-cell-class="'d-flex align-items-center'"
          :custom-filter="filterSlangTerms">
          <product-body-cell
            slot="body-cell-1"
            slot-scope="props"
            :item="props.item"></product-body-cell>
          <div
            slot="body-cell-2"
            slot-scope="props"
            class="basic-cell more-approved-cell">
            <div class="pr-2">
              <span
                v-for="(term, ind) in props.item.slangTerms.slice(0, displayApprovedSlangTerms)"
                :key="`more-terms-${props.item.napmId}-${ind}`"
                class="approved-slang-term">
                {{ term.submittedTerm + (ind !== props.item.slangTerms.length - 1 && ind !== displayApprovedSlangTerms - 1 ? ', ' : '') }}
              </span>
            </div>
            <div
              v-if="props.item.slangTerms.length > displayApprovedSlangTerms"
              class="pr-2">
              <action-list
                :id="'more-action-list-'+props.index"
                :btn-class="'btn btn-gray-alt btn-more'"
                :hide-arrow="true"
                :dropdown-arrow-location="'13%'"
                dropdown-right>
                <template slot="action-btn">
                  <span class="action-btn-more mr-1">
                    more
                  </span>
                  <i class="material-icons action-more-icon">
                    arrow_drop_down
                  </i>
                </template>
                <template slot="action-content">
                  <div
                    v-for="(term, ind) in props.item.slangTerms.slice(displayApprovedSlangTerms)"
                    :key="`more-terms-${props.item.napmId}-${ind}`"
                    class="more-dropdown-item normal-font">
                    {{ term.submittedTerm }}
                  </div>
                </template>
              </action-list>
            </div>
          </div>
          <div
            slot="body-cell-3"
            slot-scope="props"
            class="basic-cell">
            {{ getSubmittedOnNames(props.item.slangTerms) }}
          </div>
          <div
            slot="body-cell-4"
            slot-scope="props">
            <span
              class="action-arrow"
              @click="editApprovedItem(props.item)">
              <i class="material-icons">
                keyboard_arrow_right
              </i>
            </span>
          </div>
        </data-table>

        <!-- PENDING SLANG TERMS TABLE -->
        <data-table
          v-if="status === 'pending'"
          v-model="selectedPending"
          :headers="pendingHeaders"
          :pagination.sync="pagination"
          :pagination-options.sync="paginationOptions"
          :search="search"
          :max-height="500"
          :items="slangTerms[status]"
          :select-all="true"
          :loading="loading"
          :body-cell-class="'d-flex align-items-center'"
          :custom-filter="filterSlangTerms">
          <product-body-cell
            slot="body-cell-1"
            slot-scope="props"
            :item="props.item"></product-body-cell>
          <div
            slot="body-cell-2"
            slot-scope="props"
            class="basic-cell submitted-term-cell">
            <div
              v-show="editPending.productSlangTermId !== props.item.productSlangTermId"
              class="input-group">
              <input
                v-model="props.item.submittedTerm"
                type="text"
                class="form-control"
                readonly />
              <div class="input-group-append">
                <span
                  class="input-group-text"
                  @click="editPendingItem(props.item, `editPending${props.item.productSlangTermId}`)">
                  <i class="material-icons">
                    edit
                  </i>
                </span>
              </div>
            </div>
            <div
              v-if="editPending.productSlangTermId === props.item.productSlangTermId"
              class="input-group">
              <input
                :ref="`editPending${props.item.productSlangTermId}`"
                v-model="editPending.submittedTerm"
                type="text"
                class="form-control" />
              <div class="input-group-append">
                <span
                  class="input-group-text input-group-action input-group-close"
                  @click="editPending = {}">
                  <i class="material-icons">
                    close
                  </i>
                </span>
                <span
                  class="input-group-text input-group-action input-group-save"
                  @click="updatePendingItem(props.item)">
                  <i class="material-icons">
                    check
                  </i>
                </span>
              </div>
              <div
                v-if="editPendingError"
                class="error-updating-pending">
                {{ editPendingError }}
              </div>
            </div>
          </div>
          <div
            slot="body-cell-3"
            slot-scope="props"
            class="basic-cell more-cell">
            <action-list
              :id="'more-action-list-'+props.index"
              :btn-class="'btn btn-gray-alt btn-more'"
              :hide-arrow="true"
              :dropdown-arrow-location="'13%'"
              dropdown-right
              @btnClick="getSlangTermsForProduct(props.item.napmId)">
              <template slot="action-btn">
                <span class="action-btn-more mr-1">
                  more
                </span>
                <i class="material-icons action-more-icon">
                  arrow_drop_down
                </i>
              </template>
              <template slot="action-content">
                <div
                  v-if="!moreTermsProduct[props.item.napmId]"
                  class="more-dropdown-item">
                  Loading... {{ moreTermsProduct[props.item.napmId] }}
                </div>
                <div
                  v-else-if="moreTermsProduct[props.item.napmId].length === 0"
                  class="more-dropdown-item">
                  No other slang terms for product
                </div>
                <div
                  v-for="(term, ind) in moreTermsProduct[props.item.napmId]"
                  v-else
                  :key="`more-terms-${props.item.napmId}-${ind}`"
                  class="more-dropdown-item">
                  {{ term }}
                </div>
              </template>
            </action-list>
          </div>
          <div
            slot="body-cell-4"
            slot-scope="props"
            class="basic-cell date-cell">
            {{ props.item.submittedDate | localDate }}
          </div>
          <div
            slot="body-cell-5"
            slot-scope="props"
            class="basic-cell">
            {{ props.item.submittedToCompanyName }}
          </div>
          <div
            slot="body-cell-6"
            slot-scope="props"
            class="update-item-action-cell">
            <action-list
              :id="'pending-action-list-'+props.index"
              :btn-class="'btn btn-update-item-action btn-primary'"
              :hide-arrow="true"
              dropdown-right
              :dropdown-arrow-location="'10%'">
              <template slot="action-btn">
                <i class="material-icons">
                  &#xE5D4;
                </i>
              </template>
              <template slot="action-content">
                <div
                  class="action-dropdown-item"
                  @click="performActionOnItem(props.item, actions.approve)">
                  Approve
                </div>
                <div
                  class="action-dropdown-item"
                  @click="performActionOnItem(props.item, actions.approveSearch)">
                  Approve for search
                </div>
                <div
                  class="action-dropdown-item"
                  @click="performActionOnItem(props.item, actions.deny)">
                  Deny
                </div>
              </template>
            </action-list>
          </div>
        </data-table>

        <!-- DENIED SLANG TERMS TABLE -->
        <data-table
          v-if="status === 'denied'"
          v-model="selectedPending"
          :headers="deniedHeaders"
          :pagination.sync="pagination"
          :pagination-options.sync="paginationOptions"
          :search="search"
          :max-height="500"
          :items="slangTerms[status]"
          :loading="loading"
          :body-cell-class="'d-flex align-items-center'"
          :custom-filter="filterSlangTerms">
          <product-body-cell
            slot="body-cell-1"
            slot-scope="props"
            :item="props.item"></product-body-cell>
          <div
            slot="body-cell-2"
            slot-scope="props"
            class="basic-cell submitted-term-cell">
            <div class="text--danger">
              {{ props.item.submittedTerm }}
            </div>
          </div>
          <div
            slot="body-cell-3"
            slot-scope="props"
            class="basic-cell date-cell">
            {{ props.item.submittedDate | localDate }}
          </div>
          <div
            slot="body-cell-4"
            slot-scope="props"
            class="basic-cell">
            {{ props.item.submittedToCompanyName }}
          </div>
          <div
            slot="body-cell-5"
            slot-scope="props"
            class="update-item-action-cell">
            <action-list
              :id="'denied-action-list-'+props.index"
              :btn-class="'btn btn-update-item-action btn-primary'"
              :hide-arrow="true"
              dropdown-right
              :dropdown-arrow-location="'10%'">
              <template slot="action-btn">
                <i class="material-icons">
                  &#xE5D4;
                </i>
              </template>
              <template slot="action-content">
                <div
                  class="action-dropdown-item"
                  @click="performActionOnItem(props.item, actions.approve)">
                  Approve
                </div>
                <div
                  class="action-dropdown-item"
                  @click="performActionOnItem(props.item, actions.approveSearch)">
                  Approve for search
                </div>
              </template>
            </action-list>
          </div>
        </data-table>
      </div>
    </div>
    <approved-term-modal
      ref="approvedModal"
      :item="editApproved"
      :mode="'Approval'"
      @save="saveApprovedSlangTerm"
      @close="resetApprovedSlangTerm"></approved-term-modal>
  </div>
</template>
<script type="text/javascript">
import TitleComponent from 'components/pageTitle.vue'
import SearchFilter from 'components/inputs/SearchFilter.vue'
import DataTable from 'components/data-table/DataTable.vue'
import SlangTermTableHeaders from './SlangTermTableHeaders'
import ProductBodyCell from './ProductBodyCell.vue'
import ActionList from 'components/ActionList.vue'
import ApprovedTermModal from './ApprovedTermModal.vue'
import SlangTermMixin from './SlangTermMixin'

function PaginationDefault () {
  this.total = 0
  this.per_page = 25
  this.current_page = 1
  this.last_page = 0
  this.from = 0
  this.to = 15
}

export default {
  name: 'SlangTermsList',
  components: {
    SearchFilter,
    ActionList,
    'page-title': TitleComponent,
    DataTable,
    ProductBodyCell,
    ApprovedTermModal
  },
  mixins: [SlangTermMixin],
  data () {
    return {
      showSpinner: true,
      loading: false,
      status: '',
      slangTerms: {},
      search: '',
      pagination: new PaginationDefault(),
      paginationOptions: {
        offset: 5,
        previousText: 'Prev',
        nextText: 'Next',
        alwaysShowPrevNext: true,
        pageSizes: [25, 50, 100, 150, 250, 500]
      },
      pendingHeaders: SlangTermTableHeaders.pending,
      selectedPending: [],
      editPending: {},
      editPendingError: '',
      moreTermsProduct: [],
      approvedHeaders: SlangTermTableHeaders.approved,
      editApproved: {},
      displayApprovedSlangTerms: 5,
      deniedHeaders: SlangTermTableHeaders.denied
    }
  },
  computed: {
    totalPending () {
      return this.slangTerms.pending ? this.slangTerms.pending.length : null
    }
  },
  watch: {
    '$route.params.status' (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.pagination = new PaginationDefault()
        this.search = ''
        this.getSlangTermsByStatus(newVal)
      }
    },
    'editPending.submittedTerm' () {
      this.editPendingError = ''
    }
  },
  created () {
    const status = this.$route.params.status ? this.$route.params.status : 'pending'
    this.getSlangTermsByStatus(status)
  },
  methods: {
    filterSlangTerms (items, filter, search, cells, filterOnKeys) {
      if (search && typeof search === 'string') {
        let propsMapperFn
        if (this.status === 'approved') {
          propsMapperFn = i => [i.napmId.toString(), i.catNum, i.upc ? i.upc : '']
            .concat(i.slangTerms.reduce(
              (a, s) => a.concat(s.submittedTerm, s.submittedToCompanyName), []
            ))
        } else if (this.status === 'pending' || this.status === 'denied') {
          propsMapperFn = i => [i.catNum, i.submittedTerm, i.napmId.toString(), i.upc ? i.upc : '']
        }
        if (typeof propsMapperFn === 'function') {
          return items.filter(i => propsMapperFn(i)
            .map(s => s.toLowerCase().trim())
            .some(p => p.includes(search.toLowerCase().trim()))
          )
        }
      }
      return items
    },
    getSlangTermsByStatus (status, forceUpdate) { // retrieves slang terms for each stats 'approved', 'pending', 'denied'
      this.status = status
      if (!this.slangTerms[status] || forceUpdate) {
        this.loading = true
        this.$http.get('/api/SlangTerms/GetSlangTerms/' + status).then(res => {
          const data = res.data
          this.$set(this.slangTerms, status, data) // need to do it this way otherwise data will not update, vue does not look that deep
          this.loading = false
          this.showSpinner = false
        })
          .catch(res => {
            this.handleHttpError(res, 'Unable to retrieve slang terms at this time.')
          })
      }
    },
    editPendingItem (item, ref) { // edit a single item on the pending tab
      this.editPending = JSON.parse(JSON.stringify(item))
      this.$nextTick(() => {
        this.$refs[ref].focus()
      })
    },
    updatePendingItem (item) { // update a single item once done editing
      const data = {
        productSlangTermId: item.productSlangTermId.toString(),
        action: 'change',
        submittedTerm: this.editPending.submittedTerm
      }
      this.updateSlangTerm(data)
        .then(res => {
          item.submittedTerm = this.editPending.submittedTerm
          this.editPending = {}
        })
        .catch(res => {
          const errors = this.getErrorData(res)
          const duplicateSlangTerm = this.hasErrorMessage(errors, 'Slang term already exists for this product')
          if (duplicateSlangTerm) {
            this.$nextTick(() => {
              this.editPendingError = 'This slang term already exists.'
            })
          } else {
            this.handleHttpError(res, 'Unable to retrieve slang terms at this time.')
          }
        })
    },
    performActionOnItem (item, action) { // update a single item with "Approve", "Approve For Search", "Deny"
      const productSlangId = item.productSlangTermId.toString()
      this.bulkUpdateSlangTerms(productSlangId, action)
    },
    performBulkActionOnSelected (action) { // The select all for pending items will call this with the overall action dropdown
      if (this.selectedPending.length === 0) return
      const productSlangIds = this.selectedPending.map((item) => item.productSlangTermId).join(',')
      this.bulkUpdateSlangTerms(productSlangIds, action)
    },
    bulkUpdateSlangTerms (items, action) { // updates actions for all splangterms ids items
      const data = {
        productSlangTermIds: items,
        action
      }
      this.loading = true
      this.$http.post('/api/SlangTerms/BulkApproveSlangTerms', data)
        .then(res => {
          this.slangTerms = {} // reset slang terms
          this.selectedPending = []
          this.loading = false
          this.getSlangTermsByStatus(this.status)
        })
        .catch(res => {
          this.handleHttpError(res, 'Unable update slang terms at this time.')
        })
    },
    getSlangTermsForProduct (napmId) { // get more slang terms for a pending item that have already been approved
      if (this.moreTermsProduct[napmId]) { return }
      this.$http.get(`/api/SlangTerms/GetSlangTermsForProduct/${napmId}`)
        .then(res => {
          const data = res.data
          const additionalSlangTerms = data.map(x => x.submittedTerm)
          this.$set(this.moreTermsProduct, napmId, additionalSlangTerms)
        })
        .catch(res => {
          this.handleHttpError(res, 'Unable to retrieve slang terms for product at this time.')
        })
    },
    editApprovedItem (item) { // open the approved item modal that will show you the list of approved slang terms for that product
      this.editApproved = JSON.parse(JSON.stringify(item))
      this.$refs.approvedModal.open()
    },
    saveApprovedSlangTerm (data) { // a slang terms has been saved from the approve modal
      const updatedData = {
        productSlangTermId: data.productSlangTermId.toString(),
        action: data.action
      }
      if (data.submittedTerm) {
        updatedData.submittedTerm = data.submittedTerm
      }
      this.updateSlangTerm(updatedData)
        .then(res => {
          this.updateSelectedApproveItem(data)
        })
        .catch(res => {
          this.handleHttpError(res, 'Unable to retrieve update slang term at this time.')
        })
      if (data.action === 'deny') {
        // If we have denied something we should refetch the set.
        this.getSlangTermsByStatus('approved', true)
      }
    },
    resetApprovedSlangTerm () { // modal for approved item has been closed so reset it
      this.editApproved = {}
    },
    updateSelectedApproveItem (data) { // update the slang term item in all the approved products instead of just getting them all again
      const item = this.getProductByNapmId(data.napmId, this.slangTerms.approved)
      const slangTerm = this.getSlangTermByProductId(data.productSlangTermId, item.slangTerms)
      if (slangTerm) {
        if (data.submittedTerm) {
          slangTerm.submittedTerm = data.submittedTerm
        }
        if (data.action === this.actions.approve) {
          slangTerm.added = true
          slangTerm.approved = true
        } else if (data.action === this.actions.approveSearch) {
          slangTerm.added = false
          slangTerm.approved = true
        }
        this.editApproved = JSON.parse(JSON.stringify(item))
      }
    },
    updateSlangTerm (data) {
      return this.$http.post('/api/SlangTerms/UpdateSlangTerm', data)
    },
    getProductByNapmId (napmId, items) {
      for (let ii = 0; ii < items.length; ii++) {
        const item = items[ii]
        if (napmId === item.napmId) {
          return item
        }
      }
    },
    getSlangTermByProductId (productId, slangTerms) {
      if (!slangTerms) return null
      for (let jj = 0; jj < slangTerms.length; jj++) {
        const slangTerm = slangTerms[jj]
        if (productId === slangTerm.productSlangTermId) {
          return slangTerm
        }
      }
    },
    getErrorData (res) {
      const errorObject = JSON.parse(JSON.stringify(res))
      return errorObject ? errorObject.response.data : null
    },
    hasErrorMessage (array, message) {
      if (array && array.length > 0) {
        for (let ii = 0; ii < array.length; ii++) {
          if (array[ii].indexOf(message) !== -1) {
            return true
          }
        }
      }
      return false
    },
    handleHttpError (res, message) {
      this.loading = false
      this.showSpinner = false
      const errors = this.getErrorData(res)
      if (errors && errors.length > 0) {
        console.error(errors)
      } else {
        console.error(res)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/bootstrap.scss';
@import '../../assets/scss/extra/mixins/flexbox.scss';
@import '../../assets/scss/bootstrap-custom-variables.scss';

/**** GLOBAL *****/
.text--brand {
  color: $brand-primary;
}

.text--danger {
  color: $brand-danger;
}

.bold-font {
  font-weight: bold !important;
}

.normal-font {
  font-weight: normal !important;
}

/**** ROUTER LINKS TOP OF PAGE *****/
// BUTTON ROUTER LINK FOR PENDING
.btn-pending {
  position: relative;

  .number-of-pending {
    position: absolute;
    right: 1px;
    font-size: 12px;
    border-radius: 100%;
    background-color: $brand-primary;
    color: #fff;
    line-height: 15px;
    top: -9px;
  }
}

.router-link-exact-active {
  .number-of-pending {
    display: none;
  }
}

/**** TABLE ENHANCEMENTS *****/
.search-slang-terms {
  max-width: 40%;
}

.action-btn-select-all {
  display: inline-block;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 20px;
  border-right: 1px solid #000;
}

.action-dropdown-item {
  padding: 4px 10px;
  font-size: 14px;
  font-weight: bold;

  &:hover {
    background-color: #eee;
    cursor: pointer;
  }
}

.more-dropdown-item {
  padding: 4px 10px;
  font-weight: bold;
  line-height: 1.2rem;
}

/**** TABLE BODY CELLS ****/
.basic-cell {
  font-size: 14px;
  font-weight: bold;
}

.submitted-term-cell {
  .input-group {
    @include flexbox;
    @include align-items(stretch);
    @include flex-wrap(wrap);
    width: 100%;
  }

  .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #000;
    background-clip: padding-box;
    font-weight: bold;
    border: 1px solid #ccc;
  }

  .editing-form-control {
    background-color: #fff;
  }

  .input-group > .form-control:not(:last-child) {
    @include flex(1, 1, auto);
    width: 1%;
  }

  .input-group-append {
    margin-left: -1px;
    @include flexbox;
    @include flex-wrap(nowrap);
  }

  .input-group-text {
    @include flexbox;
    @include align-items(center);
    @include align-self(stretch);
    padding: 0.375rem 0.5rem;
    height: 100%;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #ccc;
    cursor: pointer;
  }

  .input-group-action {
    background-color: #eceeef;

    i {
      font-weight: bold;
    }
  }

  .input-group-close {
    border-right: none;
    color: $brand-danger;
  }

  .input-group-save {
    color: $brand-success;
  }
}

.error-updating-pending {
  width: 100%;
  font-size: 12px;
  color: $brand-danger;
}

.more-approved-cell {
  font-weight: normal;
  width: 100%;
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
}

.more-cell::v-deep .btn-more {
  color: #000;
  font-weight: bold;
  border: 1px solid #ccc;
}

.update-item-action-cell::v-deep .btn-update-item-action {
  border-radius: 50%;
  padding: 2px;

  i {
    font-size: 28px;
  }
}

.action-arrow {
  display: block;
  position: relative;
  color: #fff;
  background: $brand-primary;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  &:hover {
    background: darken($brand-primary, 10%);
  }
  .material-icons {
    position: absolute;
    font-size: 1.5rem;

    top: 50%;
    right: 50%;
    margin: -0.75rem -0.825rem 0 0;
  }
}
</style>
