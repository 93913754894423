<template>
  <div class="idw-dialog">
    <template v-if="urls">
      <div class="row">
        <template v-for="property in Object.entries(urls)">
          <div
            :key="property[0]"
            class="col-sm-6">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="form-control-label">
                    {{ property[0].split(/(?=[A-Z])/).join(" ") }}
                  </label>
                  <label
                    v-tooltip="{
                      content: 'Copy',
                      placement: 'bottom-center',
                      classes: ['info']
                    }">
                    <div
                      class="input-group"
                      @click="copyToClipboard(property[1])">
                      <input
                        class="form-control border-right-0 cursor"
                        :value="property[1]"
                        disabled />
                      <span class="input-group-addon border-left-0 cursor">
                        <i class="material-icons">
                          file_copy
                        </i>
                      </span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>

    <div
      v-else
      class="alert alert-primary">
      No description data found.
    </div>
  </div>
</template>
<script type="text/javascript">

export default {
  name: 'IdwUrls',
  props: {
    urls: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      selectAll: false,
      selectedValues: []
    }
  },
  watch: {
    selectAll (val) {
      if (val) this.selectedValues = this.urls
      else if (!val && this.selectedValues.length === this.urls.length) this.selectedValues = []
    },
    selectedValues (val) {
      this.selectAll = val.length === this.urls.length
    }
  },
  methods: {
    onSubmit () {
      this.$emit('onSelected', this.selectedValues)
    },
    copyToClipboard (value) {
      const el = document.createElement('textarea')
      el.value = value
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      const coppied = document.execCommand('copy')
      document.body.removeChild(el)

      if (coppied) {
        this.$toasted.show('Url Copied to Clipboard', {
          theme: 'toasted-primary',
          type: 'success',
          position: 'bottom-center',
          duration: 3000
        })
      } else {
        this.$toasted.show('Error Copying URL to Clipboard', {
          theme: 'toasted-primary',
          type: 'error',
          position: 'bottom-center',
          duration: 3000
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../../assets/scss/extra/mixins/flexbox";
@import "../../../../assets/scss/bootstrap-custom-variables";
.idw-dialog {
  border: none;

  .form-control-label {
    text-transform: capitalize;
  }

  .cursor:hover {
    cursor: pointer;
  }
}
</style>
