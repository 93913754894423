import { Doughnut, mixins } from 'vue-chartjs'

export default {
  extends: Doughnut,
  name: 'DoughnutChartJs',
  mixins: [mixins.reactiveProp],
  props: {
    chartData: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {}
  },
  watch: {
    chartData (nVal) {
      if (nVal) {
        this.$emit('generateLegend', this._chart.generateLegend())
      }
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
