<template>
  <div class="list-group">
    <a class="list-group-item">
      <h5 class="list-group-title">
        Section
      </h5>
      <span v-if="averageSubCatCount">Average Number of Groups in a Section:
        <span class="badge badge-light badge-pill">
          {{ averageSubCatCount }}
        </span>
      </span>
    </a>
    <template v-for="s in list">
      <router-link
        :key="s.id"
        :to="{name: 'categoryChildGroup', params: {sectionId: s.id}}"
        class="list-group-item list-group-item-action">
        <div class="category-section">
          <div class="category-image">
            <img
              v-if="s.mainImage"
              :src="`https://rexel-cdn.com/products/${s.name}.jpg?i=${s.mainImage}&f=35`" />
          </div>
          {{ s.name }} (ID {{ s.id }})
          <span class="badge badge-light badge-pill">
            {{ s.categoryCount }}
          </span>
        </div>
        <i class="material-icons">
          chevron_right
        </i>
      </router-link>
    </template>
  </div>
</template>
<script>
export default {
  name: 'CategoryChildSection',
  computed: {
    list () {
      return this.$store.getters.category.section
    },
    averageSubCatCount () {
      const totalCat = this.list.length
      const totalSubCatCount = this.list.reduce((sum, item) => sum + item.categoryCount, 0)
      return (totalSubCatCount / totalCat).toFixed(1)
    }
  }
}
</script>

<style lang="scss">
.category-section {
  div.category-image {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 100%;
    text-align: center;
    margin-right: 10px;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
  display: flex;
  height: 35px;
  align-items: center;
  line-height: 1.2;
  @media screen and (max-width: 1024px) {
    font-size: 14px;
  }
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}
</style>
