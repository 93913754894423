<template>
  <modal
    ref="editAttributeValueModal"
    :icon="modal.icon"
    :blocking="modal.blockClose"
    :title="modal.title"
    @close="resetModal()">
    <div v-if="saveResult || deleteConfirmation">
      <h6
        v-if="modal.message"
        class="text-center">
        {{ modal.message }}
      </h6>
      <h6
        v-else-if="showMerge && !deleteConfirmation"
        class="text-center">
        Replacing values cannot be undone and the current value will be deleted, are you sure you would like to replace?
        <strong>{{ original.attributeValue }}</strong> with value
        <strong>{{ mergeWithValueValues.join(', ') }}</strong>? This will impact
        <strong>{{ attachedProductCount }}</strong> {{ attachedProductCount === 1 ? 'product' : 'products' }}
      </h6>
      <h6
        v-else-if="showMoveAttribute && !deleteConfirmation"
        class="text-center">
        Please confirm that attribute value
        <strong>{{ original.attributeValue }}</strong> is to be moved to attribute type
        <strong>{{ moveToAttribute.attributeType }}</strong>? This will impact
        <strong>{{ attachedProductCount }}</strong> {{ attachedProductCount === 1 ? 'product' : 'products' }}
      </h6>
      <h6
        v-else
        class="text-center">
        Are you sure you would like to delete this value?<br />This value is attached to
        <strong>{{ attachedProductCount }} {{ attachedProductCount === 1 ? 'product' : 'products' }}</strong>.
      </h6>
    </div>
    <div v-else>
      <upload-image-attr
        :is-saving="isSaving"
        :attr-image-file="details.folderName"
        @setUploadData="setUploadData"></upload-image-attr>
      <div class="form-group">
        <label class="form-control-label required">
          Value Name
        </label>
        <input
          v-model="original.attributeValue"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': $v.original.attributeValue.$error }" />
      </div>
      <div class="form-group">
        <label class="custom-control custom-checkbox">
          <input
            v-model="showMoveAttribute"
            type="checkbox"
            class="custom-control-input" /> Move this value to another attribute
          <span class="custom-control-indicator"></span>
          <span class="custom-control-description"></span>
        </label>
        <multi-select
          v-if="showMoveAttribute"
          id="attr-type-list"
          v-model="moveToAttribute"
          :options="attributeTypes"
          track-by="attributeType"
          label="attributeType"
          placeholder="Search attribute..."
          tag-placeholder="Invalid attribute."
          :multiple="false"
          :max-height="200"
          :content-fixed="true"
          @tag="multiSelectItemSelected($event)">
        </multi-select>
      </div>
      <div
        v-if="!showMoveAttribute"
        class="form-group">
        <label class="custom-control custom-checkbox">
          <input
            v-model="showMerge"
            type="checkbox"
            class="custom-control-input" /> Replace with other value(s)
          <span class="custom-control-indicator"></span>
          <span class="custom-control-description"></span>
        </label>
        <multi-select
          v-if="showMerge"
          id="attr-list"
          v-model="mergeWithValues"
          :options="values"
          track-by="attributeValue"
          label="attributeValue"
          placeholder="Search attribute values..."
          tag-placeholder="Invalid attribute value."
          :multiple="true"
          :max-height="200"
          :content-fixed="true"
          @tag="multiSelectItemSelected($event)">
        </multi-select>
      </div>
      <div class="pb-4">
        <associated-products
          :product-list="associatedProducts"
          :product-count="associatedProductCount"
          :show.sync="showProducts"
          :loading="!productsLoaded"
          @searchAssociated="getAssociatedProducts($event)"></associated-products>
      </div>
    </div>
    <div
      slot="button"
      class="action-btns">
      <button
        v-if="!saveResult || (saveResult && (mergeConfirmation || moveConfirmation))"
        type="button"
        class="btn btn-link"
        @click="cancelEdit()">
        Cancel
      </button>
      <button
        v-if="saveResult && !mergeConfirmation && !moveConfirmation"
        type="button"
        class="btn btn-primary"
        @click="$refs.editAttributeValueModal.close()">
        Done
      </button>
      <button
        v-else-if="mergeConfirmation"
        type="button"
        class="btn btn-outline-danger mr-2"
        @click="mergeValues()">
        Complete Merge
      </button>
      <button
        v-else-if="moveConfirmation"
        type="button"
        class="btn btn-outline-danger mr-2"
        @click="moveValue()">
        Complete Move
      </button>
      <button
        v-else-if="deleteConfirmation"
        type="button"
        class="btn btn-outline-danger mr-2"
        @click="deleteValue()">
        Delete
      </button>
      <div v-else>
        <button
          type="button"
          class="btn btn-outline-danger mr-2"
          @click="confirmDelete()">
          Delete
        </button>
        <button
          type="button"
          class="btn btn-primary"
          :disabled="loading"
          @click="saveEdit()">
          {{ !loading ? 'Save' : 'Saving' }}
        </button>
      </div>
    </div>
  </modal>
</template>
<script type="text/javascript">
import axios from 'axios'
import qs from 'qs'
import { required } from 'vuelidate/lib/validators'
import ModalComponent from 'components/modals/sweet.vue'
// import AttributeAutocomplete from './components/attributeAutocomplete.vue'
import MultiselectComponent from 'components/inputs/multiselect.vue'
import AssociatedProducts from 'components/products/search/AssociatedProducts'
import UploadImageAttr from './uploadImageAttribute.vue'

export default {
  name: 'EditAttributeValue',
  components: {
    modal: ModalComponent,
    // 'attribute-autocomplete': AttributeAutocomplete,
    'multi-select': MultiselectComponent,
    'associated-products': AssociatedProducts,
    UploadImageAttr
  },
  props: {
    showModal: Boolean,
    details: Object,
    type: Object,
    attributeTypes: Array,
    values: Array
  },
  data () {
    return {
      showMoveAttribute: false,
      showMerge: false,
      modal: {
        title: 'Edit Attribute Value',
        message: '',
        icon: null,
        blockClose: false
      },
      deleteConfirmation: false,
      mergeConfirmation: false,
      moveConfirmation: false,
      saveResult: false,
      original: {},
      attachedProductCount: 0,
      moveToAttribute: null,
      mergeWithValues: [],
      cancelToken: null,
      productsLoaded: false,
      showProducts: false,
      associatedProductCount: 0,
      associatedProducts: [],
      formData: {},
      isSaving: false,
      loading: false
    }
  },
  computed: {
    language () {
      return this.$store.getters.languageId
    },
    mergeWithValueIds () {
      if (this.mergeWithValues.length) {
        return this.mergeWithValues.map(value => value.attributeValueId)
      }
      return []
    },
    mergeWithValueValues () {
      if (this.mergeWithValues.length) {
        return this.mergeWithValues.map(value => value.attributeValue)
      }
      return []
    }
  },
  watch: {
    showModal (value) {
      if (value) {
        this.modalOpen()
      } else {
        this.$emit('fetch', true)
        this.$refs.editAttributeValueModal.close()
      }
    },
    details () {
      this.getAssociatedProducts()
    }
  },
  methods: {
    setUploadData (data) {
      this.formData = data
    },
    resetModal () {
      this.saveResult = false
      this.deleteConfirmation = false
      this.mergeConfirmation = false
      this.moveConfirmation = false
      this.showMoveAttribute = false
      this.showMerge = false
      this.attachedProductCount = 0
      this.mergeWithValues = []
      this.$emit('update:showModal', false)
      setTimeout(() => {
        this.modal = {
          title: 'Edit Attribute Value',
          message: '',
          icon: null,
          blockClose: true
        }
      }, 1000)
      this.formData = {}
      this.isSaving = true
    },
    modalOpen () {
      this.saveResult = false
      this.deleteConfirmation = false
      this.isSaving = false
      const original = JSON.stringify(this.details)
      this.original = JSON.parse(original)
      this.attachedProductCount = this.details.productCount
      this.$refs.editAttributeValueModal.open()
    },
    saveEdit () {
      this.$v.original.$touch()
      if (this.showMerge && this.mergeWithValueIds && this.mergeWithValueIds.length > 0) {
        this.saveResult = true
        this.mergeConfirmation = true
        this.showModal = true
      } else if (this.showMoveAttribute && this.moveToAttribute) {
        this.saveResult = true
        this.moveConfirmation = true
        this.showModal = true
      } else {
        const { file, points, fileName, rotated } = this.formData
        const data = new FormData()
        data.append('file', file || null)
        data.append('x1', points ? points[0] : null)
        data.append('y1', points ? points[1] : null)
        data.append('x2', points ? points[2] : null)
        data.append('y2', points ? points[3] : null)
        data.append('fileName', fileName || null)
        data.append('rotate', rotated || null)
        data.append('attributeValueId', this.original.attributeValueId)
        data.append('valueEng', this.original.attributeValue)
        data.append('valueEsp', this.original.attributeValue)
        data.append('valueFrn', this.original.attributeValue)
        this.loading = true
        if (!this.$v.original.$invalid) {
          axios.post('/api/attributes/editAttributeValueTranslationAsync', data, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
            this.saveResult = true
            if (res.status === 200 || res.status === 204) {
              this.loading = false
              this.modal.icon = 'success'
              this.modal.message = `Successfully updated the attribute value of ${this.original.attributeValue}`
            } else {
              this.loading = false
              this.modal.icon = 'error'
              this.modal.message = `Error updating the attribute value of ${this.original.attributeValue}`
            }
            setTimeout(() => {
              this.$refs.editAttributeValueModal._animateIcon()
            }, 50)
          }).catch(err => {
            console.warn(err)
            this.saveResult = true
            this.modal.icon = 'error'
            this.modal.message = `Error updating the attribute value of ${this.original.attributeValue}`
            setTimeout(() => {
              this.$refs.editAttributeValueModal._animateIcon()
            }, 50)
          })
        }
      }
    },
    confirmDelete () {
      this.deleteConfirmation = true
      this.modal.title = 'Confirm Delete Value'
    },
    deleteValue () {
      const data = {
        params: {
          attributeValueId: this.original.attributeValueId
        }
      }
      this.deleteConfirmation = true
      axios.delete('/api/attributes/delete', data).then(res => {
        this.saveResult = true
        this.deleteConfirmation = false
        this.showMerge = false
        if (res.status === 200) {
          this.modal.icon = 'success'
          this.modal.message = `Successfully deleted the attribute value id ${this.original.attributeValueId}`
        } else {
          this.modal.icon = 'error'
          this.modal.message = `Error deleting the attribute value id ${this.original.attributeValueId}`
        }
        setTimeout(() => {
          this.$refs.editAttributeValueModal._animateIcon()
        }, 50)
      })
    },
    mergeValues () {
      const data = {
        attributeValueId: this.original.attributeValueId,
        mergeToAttributeValueIds: this.mergeWithValueIds
      }
      this.mergeConfirmation = false
      axios.post('/api/attributes/MergeAttributeValue', data).then((res) => {
        this.saveResult = true
        this.showMerge = false
        if (res.status === 200) {
          this.modal.icon = 'success'
          this.modal.message = `Successfully merged the attribute value id ${this.original.attributeValueId}`
        } else {
          this.modal.icon = 'error'
          this.modal.message = `Error merging the attribute value id ${this.original.attributeValueId}`
        }
        setTimeout(() => {
          this.$refs.editAttributeValueModal._animateIcon()
        }, 50)
      }).catch((err) => {
        console.log(err)
        this.modal.icon = 'error'
        this.modal.message = `Error merging the attribute value id ${this.original.attributeValueId}`
        this.$refs.editAttributeValueModal._animateIcon()
      })
    },
    moveValue () {
      const data = {
        attributeValueId: this.original.attributeValueId,
        newAttributeTypeId: this.moveToAttribute.attributeTypeId
      }
      this.moveConfirmation = false
      axios.post('/api/attributes/MoveAttributeValueToNewAttributeType', data).then((res) => {
        this.saveResult = true
        this.showMoveAttribute = false
        if (res.status === 200) {
          this.modal.icon = 'success'
          this.modal.message = `Successfully moving the attribute value id ${this.original.attributeValueId}`
        } else {
          this.modal.icon = 'error'
          this.modal.message = `Error moving the attribute value id ${this.original.attributeValueId}`
        }
        setTimeout(() => {
          this.$refs.editAttributeValueModal._animateIcon()
        }, 50)
      }).catch((err) => {
        console.log(err)
        this.modal.icon = 'error'
        this.modal.message = `Error moving the attribute value id ${this.original.attributeValueId}`
        this.$refs.editAttributeValueModal._animateIcon()
      })
    },
    cancelEdit () {
      this.resetModal()
      this.$emit('update:details', {})
    },
    getAssociatedProducts (searchQuery) {
      const params = {
        size: 100,
        attributeValueId: this.details.attributeValueId,
        languageId: this.language,
        searchTerm: searchQuery
      }
      axios.get('/api/products', {
        params,
        paramsSerializer (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        }
      }).then(res => {
        const result = res.data
        this.cancelToken = null
        this.productsLoaded = true
        this.associatedProductCount = result.resultCount
        this.associatedProducts = result.products
      })
    }
  },
  validations () {
    return {
      original: {
        attributeValue: { required }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/scss/extra/mixins/flexbox.scss';
@import '../../assets/scss/bootstrap-custom-variables.scss';

h6 {
  line-height: 1.612;
}
.action-btns {
  @include flexbox;
  @include justify-content(space-between);
  .btn-primary {
    min-width: 5.5rem;
  }
}
</style>
