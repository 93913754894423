<template>
  <div>
    <page-title title="Marketing Files"></page-title>
    <section class="section-logo"></section>
    <section class="section-info">
      <div class="col-sm-12 mb-3">
        <div class="row page-header mb-3 pb-2">
          <div class="col-sm-6 pl-0 align-flex-end">
            <h6 class="mb-0">
              New Marketing File
            </h6>
          </div>
          <div class="col-sm-6 pr-0">
            <button
              type="button"
              class="btn btn-primary float-right ml-2"
              @click="submitMarketingFile()">
              Save
            </button>
            <button
              type="button"
              class="btn btn-default float-right"
              @click="$router.back()">
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label class="form-control-label cover-image-label">
            Cover Image
          </label>
        </div>
      </div>
      <div class="row">
        <div class="cover-image form-group col-sm-12 align-self-center">
          <div
            v-if="coverImage === null"
            class="no-photo float-left">
            <span>No Photo</span>
          </div>
          <div
            v-else
            class="removable-image">
            <i
              class="material-icons"
              @click="clearCoverImage()">
              close
            </i>
            <div
              class="cover-image-preview"
              :style="`background-image: url(${ '/api/marketing/getfile/' + coverImage })`"></div>
          </div>
          <template v-if="uploadReady">
            <label
              v-if="uploadReady"
              for="file"
              class="btn btn-primary btn-fake">
              <input
                id="file"
                name="file"
                type="file"
                :accept="validMediaFileTypes.images"
                @change="saveCoverImage($event)" />
              Upload Cover Image
            </label>
            <div
              v-if="uploadReady"
              class="info-caption">
              Images must either be .jpg or .png format
            </div>
          </template>
        </div>
        <div class="form-group col-sm-8">
          <label class="form-control-label required">
            Project Title
          </label>
          <input
            v-model="marketing.projectTitle"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.marketing.projectTitle.$error }" />
        </div>
        <div class="form-group col-sm-4">
          <label class="form-control-label required">
            Company
          </label>
          <multiselect
            v-model="marketing.company"
            :class="{ 'is-invalid': $v.marketing.company.$error }"
            :options="companyList"
            :searchable="false"
            :multiple="false"
            label="name"
            track-by="companyId"></multiselect>
        </div>
        <div class="form-group col-sm-12">
          <label class="form-control-label required">
            Description
          </label>
          <editor
            id="marketing-description"
            v-model="marketing.projectDescription"
            :class="{ 'is-invalid': $v.marketing.projectDescription.$error }"
            :disabled="false"></editor>
        </div>
      </div>

      <document-upload
        :language="language"
        :documents.sync="documents"
        :removed-documents.sync="removedDocuments"
        :added-documents.sync="addedDocuments"
        @upload="uploadMedia($event)"></document-upload>

      <div class="row pl-3 pr-3">
        <div class="section form-group col-sm-12 pt-3 pb-3">
          <label class="form-control-label">
            Manufacturers
          </label>
          <multiselect
            v-model="marketing.manufacturers"
            :options="manufacturerList"
            :searchable="true"
            :multiple="true"
            label="name"
            track-by="id"></multiselect>
        </div>
      </div>
    </section>
    <section class="section-btns mt-3 pt-4 mb-5">
      <div class="col-sm-12 pr-0">
        <button
          type="button"
          class="btn btn-primary float-right ml-2"
          @click="submitMarketingFile()">
          Save
        </button>
        <button
          type="button"
          class="btn btn-default float-right"
          @click="$router.back()">
          Cancel
        </button>
      </div>
    </section>
  </div>
</template>
<script type="text/javascript">
import axios from 'axios'
import TitleComponent from 'components/pageTitle.vue'
import MultiselectComponent from 'components/inputs/multiselect.vue'
import EditorComponent from 'components/editor.vue'
import DocumentsSection from './components/documents.vue'
import MediaFileTypesMixin from '@/views/media/types/MediaFileTypes.mixin'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    editor: EditorComponent,
    'page-title': TitleComponent,
    multiselect: MultiselectComponent,
    'document-upload': DocumentsSection
  },
  mixins: [MediaFileTypesMixin],
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.getManufacturers()
    })
  },
  data () {
    return {
      file: '',
      fileEvent: '',
      fileName: '',
      coverImage: null,
      marketing: {
        projectTitle: null,
        company: null,
        projectDescription: null,
        manufacturers: []
      },
      manufacturerList: [],
      documents: [],
      addedDocuments: [],
      removedDocuments: [],
      documentUpload: {
        dialog: false,
        documentTypes: [],
        fileType: '0',
        fileInputTxt: '',
        fileInputVal: null,
        showError: false,
        errorMessage: ''
      },
      uploadReady: true
    }
  },
  computed: {
    companyList () {
      return this.$store.getters.companyList.map(company => {
        return company
      })
    },
    language () {
      const lang = this.$store.getters.languageId
      return parseInt(lang)
    }
  },
  mounted () {
  },
  methods: {
    clearCoverImage () {
      this.uploadReady = true
      this.coverImage = null
    },
    getManufacturers () {
      this.$http.get('/api/manufacturers')
        .then(res => {
          this.manufacturerList = res.data
        }).catch(e => console.warn(e))
    },
    saveCoverImage (event) {
      this.fileEvent = event
      this.file = event.target.files[0]
      this.fileName = event.target.files[0].name
      const data = new FormData()
      data.append('file', this.file)
      this.loading = true
      axios.post('/api/marketing/uploadFile', data, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
        this.loading = false
        this.coverImage = res.data
        this.uploadReady = false
      })
    },
    submitMarketingFile () {
      this.$v.marketing.$touch()
      if (!this.$v.marketing.$invalid) {
        const data = {
          companyId: this.marketing.company.companyId,
          manufacturerIds: this.marketing.manufacturers.map(mfr => {
            return mfr.id
          }),
          addedDocuments: this.documents.map(doc => {
            return {
              documentFolderName: doc.documentFolderName,
              documentDisplayName: doc.documentDisplayName
            }
          }),
          projectTitle: this.marketing.projectTitle,
          projectDescription: this.marketing.projectDescription
        }
        if (this.coverImage !== null) {
          data.coverImageFolderName = this.coverImage
        }
        axios.post('/api/marketing/create', data).then(res => {
          this.$router.push({ name: 'marketing' })
        })
      }
    }
  },
  validations () {
    return {
      marketing: {
        projectTitle: { required },
        company: { required },
        projectDescription: { required }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/scss/extra/mixins/flexbox.scss';
@import '../../assets/scss/bootstrap-custom-variables.scss';
.cover-image { height: 100px; }
.page-header {
  border-bottom: 2px solid #eee;
}
.section-btns {
  border-top: 2px solid #eee
}
button {
  min-width: 150px;
}
[class*='align-flex'], .flex {
  @include flexbox;
  @include flex-wrap(wrap);
}
.align-flex-end {
  align-items: flex-end;
}
.align-flex-middle {
  align-items: center;
}
.section {
  display: block;
  width: 100%;
  border: 1px solid #c9c9c9;
  background: #eee
}
.form-control {
  line-height: 1.5;
}
.form-control-label {
  position: relative;
  display: block;
  &.required:after {
    content: "*";
    color: $brand-danger;
    top: .125rem;
    padding-left: .25rem;
  }
}
.ql-editor {
  color: #464a4c !important;
}
.no-photo {
  position: relative;
  height: 100px;
  width: 100px;
  border: 1px solid rgba(0,0,0,.15);
  pointer-events: none;
  margin-right: 1.5rem;
  span {
    line-height: 100px;
    text-align: center;
    display: block;
    color: rgba(0,0,0,.4);
    pointer-events: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
.info-caption {
  color: rgba(0,0,0,.4);
  padding-top: .5rem;
}
.cover-image-preview {
  width: 100px;
  height: 100px;
  background-position: center center;
  background-size: contain;
  background-color: transparent;
  background-repeat: no-repeat;
  border: 1px solid rgba(0,0,0,.15)
}
.cover-image {
  label.btn.btn-primary.btn-fake {
    background: #00416a;
    overflow: hidden !important;
    position: relative !important;
    &.btn-rounded {
      min-width: unset;
    }
    &:hover {
      background: #002a44
    }
    #file {
      cursor: inherit;
      display: block;
      filter: alpha(opacity=0);
      min-height: 100%;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
      left: 0;
      right: 0;
    }
  }
}
.removable-image {
  position: relative;
  width: 100px;
  i {
    position: absolute;
    right: -4px;
    top: -4px;
    background: #cc0000;
    color: #fff;
    padding: 3px;
    border-radius: 100%;
    font-size: 10px;
    font-weight: bold;
    box-shadow: 1px 1px 1px rgba(0,0,0,0.4);
    cursor: pointer;
  }
}
</style>
