<template>
  <div
    :class="{['read-only form-control-static disabled']: readOnly }"
    class="vue-input-tag-wrapper form-control"
    @click="focusNewTag()">
    <span
      v-for="(tag, index) in tags"
      :key="index"
      class="input-tag">
      <span>{{ tag }}</span>
      <a
        v-if="!readOnly"
        class="remove"
        @click.prevent.stop="remove(index)"></a>
    </span>
    <input
      v-if="!readOnly"
      v-model="newTag"
      :placeholder="getPlaceholder()"
      type="text"
      class="new-tag"
      @keydown.delete.stop="removeLastTag()"
      @keydown.enter.prevent.stop="addNew(newTag)" />
  </div>
</template>

<script type="text/javascript">
const validators = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  url: /^(https?|ftp|rmtp|mms):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)(:(\d+))?\/?/i,
  text: /^[a-zA-Z]+$/,
  digits: /^[\d() .:\-+#]+$/
}

export default {
  name: 'Tagging',
  props: {
    tags: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    onChange: {
      type: Function,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    validate: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      newTag: ''
    }
  },
  methods: {
    focusNewTag () {
      if (this.readOnly) { return }
      this.$el.querySelector('.new-tag').focus()
    },
    addNew (tag) {
      if (tag && !this.tags.includes(tag) && this.validateIfNeeded(tag)) {
        this.tags.push(tag)
        this.tagChange()
      }
      this.newTag = ''
    },
    validateIfNeeded (tagValue) {
      if (this.validate === '' || this.validate === undefined) {
        return true
      } else if (Object.keys(validators).indexOf(this.validate) > -1) {
        return validators[this.validate].test(tagValue)
      }
      return true
    },
    remove (index) {
      this.tags.splice(index, 1)
      this.tagChange()
    },
    removeLastTag () {
      if (this.newTag) { return }
      this.tags.pop()
      this.tagChange()
    },
    getPlaceholder () {
      if (!this.tags.length) {
        return this.placeholder
      }
      return ''
    },
    tagChange () {
      if (this.onChange) {
        // avoid passing the observer
        this.onChange(JSON.parse(JSON.stringify(this.tags)))
      }
    }
  }
}
</script>
<style lang="scss">
@import "../assets/scss/bootstrap-custom-variables";

.vue-input-tag-wrapper {
  overflow: hidden;
  cursor: text;
  text-align: left;
  min-height: 2.875rem;
  .input-tag {
    background-color: $brand-primary;
    border-radius: 0;
    border: 1px solid darken($brand-primary, 10%);
    color: #fff;
    display: inline-block;
    font-size: .875rem;
    font-weight: 400;
    padding: .25rem .625rem;
    margin: 0 .5rem .25rem 0;
    .remove {
      cursor: pointer;
      font-weight: bold;
      color: #fff;
      margin-left: 1.25rem;

      &:hover {
        text-decoration: none;
        color: $brand-danger;
      }

      &::before {
        content: " x";
      }
    }
  }

  .new-tag {
    background: transparent;
    border: 0;
    color: #777;
    font-size: .875rem;
    font-weight: 400;
    margin: 1px 0;
    outline: none;
    padding: .25rem;
    padding-left: 0;
    width: 100%;
  }

  &.read-only {
    cursor: default;
  }
}

.tag {
  background: $gray-lighter;
  position: relative;
  display: inline-block;
  border: 1px solid darken($gray-lighter, 5%);
  padding: 0 1rem 0 2rem;
  line-height: 2rem;
  margin-right: .5rem;
  font-size: .8125rem;
  height: 2rem;
  text-transform: uppercase;
  .tag-label {}

  .tag-remove {
    position: absolute;
    top: 50%;
    left: .5rem;
    margin-top: -1rem;
    cursor: pointer;
    font-size: 1rem;
    &:hover {
      color: darken($brand-danger, 15%);
    }
  }
}

</style>
