<template>
  <div v-if="image.src">
    <cropper
      v-show="image.src && !loading"
      ref="imgCropperRef"
      :inline="inline"
      :enable-exif="true"
      :show-zoomer="false"
      :zoom-level="image.zoom"
      :mouse-wheel-zoom="true"
      :enforce-boundary="false"
      :enable-orientation="true"
      :boundary="boundary"
      :viewport="viewport"
      :show-buttons="true"
      @update="imageCropUpdate($event)"
      @rotate="rotateImage"></cropper>
    <div
      v-if="loading"
      class="loading-container">
      <h6 class="mt-4">
        Uploading image <strong>{{ image.fileName }}</strong>,<br /> please wait until process has finished.
      </h6>
      <uploading-spinner :loading="loading"></uploading-spinner>
    </div>
    <div
      v-else
      class="crop-media-info w-100 p-4 text-left">
      <div class="form-group">
        <label class="form-control-label">
          File Name:
        </label>
        <input
          v-model="image.fileName"
          class="form-control" />
      </div>
      <div
        v-if="!existing"
        class="mb-4">
        <label class="form-control-label">
          Manufacturer:
        </label>
        <select
          v-model="image.manufacturer"
          class="form-control">
          <option
            v-for="vendor in vendors"
            :key="vendor.id"
            :value="vendor.id">
            {{ vendor.name }}
          </option>
        </select>
      </div>
      <div
        v-show="!hideImageSizeRequired && showSmallImgMsg"
        class="alert alert-danger small-img-msg"
        role="alert">
        {{ smallImgMsg }}
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import ManufacturersMixin from 'mixins/Manufacturers.mixin'
import Cropper from 'components/crop'
import UploadingSpinner from '@/views/media/upload/uploading'

export default {
  name: 'UploadImageForm',
  components: { Cropper, UploadingSpinner },
  mixins: [ManufacturersMixin],
  props: {
    image: { // should be type of ImageUploadMixin imageToUpload
      type: Object
    },
    loading: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    boundary: {
      type: Object,
      default: () => {
        return { height: 450, width: 750 }
      }
    },
    viewport: {
      type: Object,
      default: () => {
        return { width: 400, height: 400, type: 'square' }
      }
    },
    existing: {
      type: Boolean,
      default: false
    },
    hideImageSizeRequired: {
      type: Boolean,
      default: false
    },
    minImageWidth: {
      type: Number,
      default: 640
    },
    minImageHeight: {
      type: Number,
      default: 640
    }
  },
  data () {
    return {
      vendors: []
    }
  },
  computed: {
    showSmallImgMsg () {
      let imageTooSmall = false
      if (this.image.width && this.image.height) {
        imageTooSmall = (this.image.width < this.minImageWidth || this.image.height < this.minImageHeight)
      }
      this.$emit('imageTooSmall', imageTooSmall)
      return imageTooSmall
    },
    smallImgMsg () {
      return `This image does not meet our minimum size requirements (${this.minImageWidth}px x ${this.minImageHeight}px).
      Your image quality may be degraded after uploading, please upload a higher quality image.`
    }
  },
  watch: {
    'image.src' (val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.imgCropperRef.bind({
            url: val
          })
        })
      }
    }
  },
  mounted () {
    this.getAllManufacturers()
      .then(response => {
        this.vendors = response.data
      })
  },
  methods: {
    imageCropUpdate (event) {
      this.image.zoom = event.zoom
      this.$set(this.image, 'points', event.points)
    },
    rotateImage (event) {
      this.image.rotated = event
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-container {
  min-height: 250px;
  text-align: center;
}
</style>
