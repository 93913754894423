<template>
  <modal
    ref="viewAllCrossSectionModal"
    :title="title"
    :large="true"
    @close="resetModal()">
    <div class="edit-cross-filled">
      <div class="search-header">
        <div class="form-group">
          <label class="form-control-label">
            Look up a product
          </label>
          <div class="search-group">
            <input
              v-model="prodFilter"
              type="text"
              class="form-control"
              @keyup.esc="prodFilter = ''" />
            <a
              v-if="!inSearch"
              class="search-btn">
              <i class="material-icons">
                search
              </i>
            </a>
            <a
              v-else
              class="clear-search"
              @click="prodFilter = ''">
              <i class="material-icons">
                clear
              </i>
            </a>
          </div>
        </div>
      </div>
      <div class="cross-filled-table">
        <div class="cross-filled-header">
          <label class="info">
            Products
          </label>
          <label class="order">
            Order
          </label>
          <label class="cross-filled">
            Cross Filled
          </label>
        </div>
        <template v-if="renderList && !inSearch && products.length > 100">
          <RecyclerView
            :prerender="30"
            style="height: calc(31.25rem - 4.375rem)"
            :fetch="fetch"
            :item="SingleItem"></RecyclerView>
        </template>
        <template v-else-if="(renderList && inSearch) || (renderList && products.length < 100)">
          <div
            v-if="!prodResults.length"
            class="alert alert-info">
            No results matching
            <strong>{{ prodFilter }}</strong>. Please change your search or
            <a
              class="clear-search"
              @click="prodFilter = ''">
              <u>clear search</u>
            </a> and try again.
          </div>
          <div
            v-else
            class="prod-search">
            <div
              v-for="prod in prodResults"
              :key="'cross-filled-prod-' + prod.napmId">
              <single-prod
                :data="prod"
                :filter="prodFilter"></single-prod>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div
      slot="button"
      class="footer-btns">
      <button
        type="button"
        class="btn btn-link"
        @click="$refs.viewAllCrossSectionModal.close()">
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click="commitProducts()">
        Submit
      </button>
    </div>
  </modal>
</template>
<script type="text/javascript">
import ModalComponent from 'components/modals/sweet.vue'
// import ToggleComponent from 'components/inputs/toggleSwitch.vue'
import SingleItem from './singleProd.vue'

export default {
  name: 'ViewAll',
  components: {
    modal: ModalComponent,
    // toggle: ToggleComponent,
    'single-prod': SingleItem
  },
  props: {
    title: String,
    products: Array,
    show: {
      default: false,
      type: Boolean,
      required: true
    },
    crosstype: String,
    crossid: Number
  },
  data () {
    return {
      id: 0,
      inSearch: false,
      prodFilter: '',
      renderList: false,
      SingleItem
    }
  },
  computed: {
    prodResults () {
      const search = this.prodFilter.toLowerCase()
      const filterProds = this.products.filter(prod => {
        return prod.catNum.toLowerCase().indexOf(search) !== -1 || prod.mfrName.toLowerCase().indexOf(search) !== -1 || prod.napmId.toString().indexOf(search) !== -1
      })
      return filterProds
    }
  },
  watch: {
    show (value) {
      if (value) {
        this.renderList = value
        this.$refs.viewAllCrossSectionModal.open()
      } else {
        this.renderList = value
        this.$refs.viewAllCrossSectionModal.close()
      }
    },
    prodFilter (value) {
      this.inSearch = value.length >= 2
    }
  },
  methods: {
    resetModal () {
      this.$emit('update:show', false)
      // this.prodResults = []
      this.prodFilter = ''
      this.id = 0
    },
    fetch (limit, skip) {
      limit = Math.max(30, limit)
      return this.query(limit, skip)
        .then(list => {
          return {
            list,
            count: this.products.length
          }
        })
    },
    query (limit, skip) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const items = []
          for (let i = 0; i < limit; i++) {
            items[i] = this.getItem(skip + i)
          }
          resolve(Promise.all(items))
        }, 200)
      })
    },
    getItem (index) {
      return new Promise(resolve => {
        const item = this.products[index]
        resolve(item)
      })
    },
    commitProducts () {
      const changedProds = this.products.filter((prod, index) => {
        if (prod.removeProd) {
          this.spliceProduct(prod, index)
        }
        return prod.prodChanged
      })

      this.$emit('save', changedProds)
      this.resetModal()
    },
    spliceProduct (prod, index) {
      if (prod.napmId === this.products[index].napmId) {
        this.products.splice(index, 1)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../../assets/scss/extra/mixins/flexbox";
@import "../../../../assets/scss/bootstrap-custom-variables";
// .edit-cross-filled {}

.search-header {
  @include flexbox;
  .form-group {
    @include flex(0, 1, 50%)
  }

  .search-group {
    position: relative;

    .form-control {
      padding-right: 1rem;
    }
    .search-btn,
    .clear-search {
      position: absolute;
      top: 50%;
      right: .25rem;
      opacity: .4;
      z-index: 10;
      padding: .5rem;
      font-size: 1.125rem;
      margin-top: -1.5rem;
    }
    .clear-search {
      opacity: 1;
      color: $brand-danger;
      &:hover {
        cursor: pointer;
        font-weight: bold;
      }
    }
  }
}

.cross-filled-table {
  height: 31.25rem;

  .cross-filled-header {
    width: 100%;
    background: #eee;
    padding: 1rem 1rem .5rem;
    border: 1px solid #c9c9c9;
    @include flexbox;
    >label {
      font-weight: bold;
      text-transform: uppercase;
      &.info {
        @include flex(1, 1, 38rem);
      }
      &.order {
        @include flex(0, 1, 9.25rem);
      }
      &.cross-filled {
        @include flex(0, 1, 12rem);
      }
    }
  }
}

.prod-search {
  height: calc(31.25rem - 4.375rem);
  border: 1px solid #c9c9c9;
  overflow-y: auto;
}

.footer-btns {
  @include flexbox;
  @include justify-content(space-between);
}
</style>
