<template>
  <section id="attributes">
    <div class="section-heading">
      <h6>Attributes:</h6>
    </div>
    <div class="section-body">
      <div
        id="add-attribute"
        class="form-group">
        <multi-select
          id="attr-list"
          :options="attributeTypes"
          track-by="attributeTypeId"
          label="attributeType"
          placeholder="Select a Attribute"
          :reset-after="true"
          @select="addAttributeType">
        </multi-select>
      </div>
      <div
        v-if="suggestedAttributes && suggestedAttributes.length > 0"
        id="add-suggested-attribute"
        class="form-group">
        <multi-select
          id="attr-suggested-list"
          :options="suggestedAttributes"
          track-by="attributeTypeId"
          label="attributeType"
          placeholder="Select a Suggested Attribute"
          :reset-after="true"
          @select="addSuggestedAttributeType">
        </multi-select>
      </div>
      <template v-if="attributes.length > 0">
        <div
          v-for="(attr, index) in attributes"
          :id="'attr-group-' + attr.attributeTypeId"
          :key="'attribute-type-' + attr.attributeTypeId"
          class="attr attr-group">
          <label class="form-control-label">
            {{ attr.attributeType }}
          </label>
          <multi-select
            :id="'attr-type-' + attr.attributeTypeId"
            :ref="'attr-type-' + attr.attributeTypeId"
            v-model="attr.attributes"
            :options="attr.options"
            label="attributeValue"
            track-by="attributeValue"
            :multiple="true"
            :type-id="attr.attributeTypeId"
            :type-index="index"
            @select="addAttributeValue"
            @remove="removeAttributeValue">
          </multi-select>
          <button
            type="button"
            class="btn btn-outline-danger attr-btn"
            @click="removeAttributeType(attr.attributeTypeId)">
            <i class="material-icons">
              &#xE92B;
            </i>
            <span>Remove</span>
          </button>
        </div>
      </template>
    </div>
  </section>
</template>
<script type="text/javascript">
import axios from 'axios'
import MultiselectComponent from 'components/inputs/multiselect.vue'

export default {
  components: {
    'multi-select': MultiselectComponent
  },
  props: {
    hasIdw: Number,
    loaded: Boolean,
    language: Number,
    attributes: Array,
    addedAttributes: Array,
    suggestedAttributes: Array,
    removedAttributes: Array
  },
  data () {
    return {
      attributeTypes: []
    }
  },
  mounted () {
    this.getAttributeTypes()
  },
  methods: {
    getAttributeTypes () {
      const data = {
        params: { languageId: this.language }
      }
      axios.get('/api/attributes/getAttributeTypes', data).then(res => {
        this.attributeTypes = res.data
      })
    },
    addAttributeType (selectedOption, id) {
      this.getAttributeOptions(selectedOption.attributeTypeId, this.attributeTypes)
    },
    addSuggestedAttributeType (selectedOption, id) {
      this.getAttributeOptions(selectedOption.attributeTypeId, this.suggestedAttributes)
    },
    getAttributeOptions (typeId, types) {
      if (!document.querySelector('#attr-group-' + typeId)) {
        const data = {
          params: {
            attributeTypeId: typeId,
            languageId: this.language
          }
        }
        axios.get('/api/attributes/GetAttributeOptions', data).then(res => {
          const result = res.data
          const attrType = types.find(function (type) {
            return type.attributeTypeId === typeId
          })

          attrType.options = result.attributeOptions
          attrType.attributes = []
          this.attributes.unshift(attrType)
        })
      }
    },
    removeAttributeType (id) {
      let attrId, addedAttrs, removedAttrs

      this.attributes.forEach((obj, attrIndex) => {
        if (obj.attributeTypeId === id) {
          obj.attributes.forEach((attrObj) => {
            attrId = attrObj.attributeValueId

            addedAttrs = this.addedAttributes.some((attr) => {
              return attr === attrId
            })

            removedAttrs = this.removedAttributes.some((attr) => {
              return attr === attrId
            })

            if (addedAttrs) {
              this.addedAttributes.forEach((addedAttr, arrayIndex) => {
                if (addedAttr === attrId) {
                  this.addedAttributes.splice(arrayIndex, 1)
                }
              })
            } else if (!removedAttrs) {
              this.removedAttributes.push(attrId)
            }
          })

          this.attributes.splice(attrIndex, 1)
        }
      })
    },
    createAttributeValue (attribute, id) {
      const attrTypeId = this.$refs[id][0].$attrs.typeId
      const data = {
        attributeTypeId: attrTypeId,
        attributeValue: attribute,
        languageId: this.language
      }
      axios.post('/api/attributes/createAttributeAsync', data)
        .then(res => {
          const newAttrValue = {
            attributeValue: attribute,
            attributeValueId: res.data
          }
          this.attributes[this.$refs[id][0].$attrs.typeIndex].options.push(newAttrValue)
          this.attributes[this.$refs[id][0].$attrs.typeIndex].attributes.push(newAttrValue)
          this.addAttributeValue(newAttrValue)
        })
    },
    addAttributeValue (selectedOption, id) {
      const attrId = selectedOption.attributeValueId

      const isAdded = this.addedAttributes.some((attr) => {
        return attr === attrId
      })
      const isRemoved = this.removedAttributes.some((attr) => {
        return attr === attrId
      })

      if (isRemoved) {
        this.removedAttributes.forEach((item, index) => {
          if (item === attrId) {
            this.removedAttributes.splice(index, 1)
          }
        })
      } else if (!isAdded) {
        this.addedAttributes.push(attrId)
      }
    },
    removeAttributeValue (removedOption, id) {
      const attrId = removedOption.attributeValueId

      const isAdded = this.addedAttributes.some((attr) => {
        return attr === attrId
      })
      const isRemoved = this.removedAttributes.some((attr) => {
        return attr === attrId
      })

      if (isAdded) {
        this.addedAttributes.forEach((item, index) => {
          if (item === attrId) {
            this.addedAttributes.splice(index, 1)
          }
        })
      } else if (!isRemoved) {
        this.removedAttributes.push(attrId)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/scss/extra/mixins/flexbox';
@import '../../../assets/scss/bootstrap-custom-variables';
#attributes {
  border: 1px solid #c9c9c9;
  background: #eee;

  .section-heading {
    border-bottom: 0;
    > h6 {
      position: relative;
      padding: 0 1.25rem;
    }
  }

  .section-body {
    padding: 0 1rem 0.5rem;
  }
}

.attr-group {
  margin-bottom: 1rem;
  @include flexbox;
  @include flex-wrap(wrap);
  .form-control-label {
    @include flex(1, 1, 100%);
    color: #222;
    text-transform: uppercase;
  }
  .multiselect {
    @include flex(1, 1, 80%);
  }
  .attr-btn {
    @include flex(1, 0, 1.5rem);
    margin-left: 0.5rem;
  }
}
</style>
