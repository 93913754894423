<template>
  <div
    class="search-filter"
    :class="[ containerClass, searchFocus ? 'search-filter-focus' : '' ]">
    <input
      v-model="search"
      type="text"
      :placeholder="placeholder"
      @focus="searchFocus = true"
      @blur="searchFocus = false" />
    <i
      v-show="isEmpty"
      class="material-icons">
      search
    </i>
    <i
      v-show="isDirty"
      class="material-icons times clickable"
      @click="search = ''">
      close
    </i>
  </div>
</template>
<script type="text/javascript">

export default {
  name: 'SearchFilter',
  props: {
    placeholder: String,
    containerClass: String
  },
  data () {
    return {
      search: '',
      searchFocus: false
    }
  },
  computed: {
    isEmpty () {
      return !this.search
    },
    isDirty () {
      return !!this.search
    }
  },
  watch: {
    search (val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/extra/mixins/flexbox.scss";
.search-filter {
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
  @include flex-wrap(nowrap);
  width: 100%;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #464a4c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 0;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  input {
    border: 0;
    width: 100%;
    outline: none !important;
  }

  .material-icons {
    font-size: 24px;
    text-align: center;
    border: 0;
    @include flexbox;
    @include align-self(stretch);
    @include align-items(center);
    @include justify-content(flex-end);
    padding-left: .75rem;
  }
}

.search-filter-focus {
  color: #464a4c;
  background-color: #fff;
  border-color: #008fea;
}

.clickable {
  cursor: pointer;
}
</style>
