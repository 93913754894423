<template>
  <section id="catalog-info">
    <div class="section-heading">
      <h6>Catalog Information</h6>
    </div>
    <div class="section-body">
      <div class="form-group">
        <label class="form-control-label">
          NAPM Number
        </label>
        <input
          v-model="product.napmId"
          type="text"
          class="form-control"
          disabled />
      </div>
      <div class="form-group">
        <label class="form-control-label">
          Catalog Number
        </label>
        <input
          v-model="product.catNum"
          type="text"
          class="form-control"
          :disabled="!canEditCatNum" />
      </div>
      <div class="form-group">
        <label class="form-control-label">
          Manufacturer
        </label>
        <input
          v-model="product.mfrName"
          type="text"
          class="form-control"
          disabled />
      </div>
      <div class="form-group">
        <label class="form-control-label">
          EAN
        </label>
        <input
          v-model="product.ean"
          type="number"
          class="form-control hide-arrows" />
        <span
          v-if="!$v.product.ean.minLength || !$v.product.ean.maxLength"
          class="text-danger">
          Must be 13 digits long
        </span>
      </div>
    </div>
    <div class="section-body">
      <div class="form-group">
        <label class="form-control-label">
          UPC
        </label>
        <input
          v-model="product.upc"
          type="text"
          class="form-control"
          :disabled="product.noUpc" />
      </div>
      <div class="form-group">
        <label class="custom-control custom-checkbox">
          <input
            v-model="product.noUpc"
            type="checkbox"
            class="custom-control-input" />
          <span class="custom-control-indicator"></span>
          <span class="custom-control-description">
            No UPC
          </span>
        </label>
        <label class="custom-control custom-checkbox">
          <input
            v-model="product.commodityFlag"
            type="checkbox"
            class="custom-control-input" />
          <span class="custom-control-indicator"></span>
          <span class="custom-control-description">
            Is Commodity Product
          </span>
        </label>
        <label class="custom-control custom-checkbox">
          <input
            v-model="product.shippingQuoteInd"
            type="checkbox"
            class="custom-control-input" />
          <span class="custom-control-indicator"></span>
          <span class="custom-control-description">
            Shipping Quote
          </span>
        </label>
        <label class="custom-control custom-checkbox">
          <input
            v-model="product.requiredFraudInd"
            type="checkbox"
            class="custom-control-input" />
          <span class="custom-control-indicator"></span>
          <span class="custom-control-description">
            Required Fraud Item
          </span>
        </label>
      </div>
      <div class="form-group">
        <label class="form-control-label">
          Discontinued
        </label>
        <select
          v-model="product.discontinued"
          disabled
          class="form-control">
          <option
            value="null"
            selected>
            None
          </option>
          <option value="true">
            Yes
          </option>
          <option value="false">
            No
          </option>
        </select>
      </div>
      <div class="form-group">
        <label class="form-control-label">
          UNSPSC
        </label>
        <input
          v-model="product.unspsc"
          type="number"
          class="form-control hide-arrows" />
      </div>
    </div>
  </section>
</template>
<script type="text/javascript">

import { minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  props: {
    product: Object
  },
  data: () => ({
    tempUpc: null
  }),
  computed: {
    canEditCatNum () {
      return this.$store.getters.user.companyPermissions.some(perm => { return perm.isAdmin })
    }
  },
  watch: {
    'product.ean' (value) {
      const valid = !!(!value || value.length === 13)
      this.$emit('validate-ean', valid)
    },
    'product.noUpc' (value) {
      // clear UPC field when there is no UPC, but
      // re-fill it with previous value if re-enabled
      if (value) {
        this.tempUpc = this.product.upc
        this.product.upc = null
      } else {
        this.product.upc = this.tempUpc
        this.tempUpc = null
      }
    }
  },
  validations: {
    product: {
      ean: {
        minLength: minLength(13),
        maxLength: maxLength(13)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/scss/extra/mixins/flexbox';
.section-body {
  @media (min-width: 37.5rem) {
    @include flexbox;
    @include flex-wrap(wrap);
    @include justify-content(space-between);
  }
  .form-group {
    width: 100%;
    @media (min-width: 37.5rem) {
      width: calc(50% - 1rem);
    }
    @media (min-width: 1200px) {
      width: calc(25% - 1rem);
    }
    input[readonly],
    input[disabled='disabled'] {
      border: 1px solid transparent;
      background-color: #ececec;
    }
  }
}
</style>
