export default [
  {
    text: 'Product',
    value: 'napmID',
    width: '25%',
    maxWidth: '25%'
  },
  {
    text: 'Subject',
    value: 'subject',
    width: '10%',
    maxWidth: '10%'
  },
  {
    text: 'Comment',
    value: 'comments',
    width: '30%',
    maxWidth: '30%'

  },
  {
    text: 'Rating',
    value: 'rating',
    width: '10%',
    maxWidth: '10%'
  },
  {
    text: 'Submitted By',
    value: 'createUser',
    width: '10%',
    maxWidth: '10%'
  },
  {
    text: 'Submitted Date',
    value: 'createDate',
    width: '10%',
    maxWidth: '10%'
  },
  // {
  //   text: 'Submitted On',
  //   value: 'submittedToCompanyNasde',
  //   width: '10%',
  //   maxWidth: '10%',
  //   sortable: true
  // },
  {
    text: '',
    width: '5%',
    maxWidth: '5%',
    sortable: false
  }
]
