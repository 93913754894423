import { render, staticRenderFns } from "./documentIcon.vue?vue&type=template&id=68b2089e&scoped=true"
import script from "./documentIcon.vue?vue&type=script&lang=js"
export * from "./documentIcon.vue?vue&type=script&lang=js"
import style0 from "./documentIcon.vue?vue&type=style&index=0&id=68b2089e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68b2089e",
  null
  
)

export default component.exports