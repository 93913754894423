<template>
  <!--Report-->
  <report-filter
    v-if="showReportFilter"
    :report.sync="report"
    :results-found="resultsFound"
    @onGenerateReport="setSearchRequestFromReport($event)"></report-filter>

  <div
    v-else
    class="product-search-results">
    <div class="top-title">
      <div class="inline-container">
        <div
          class="btn-action-back"
          @click="reset">
          <i class="material-icons go-back">
            &#xE5C4;
          </i>
        </div>
        <h1 class="page-name">
          Results
        </h1>
      </div>
    </div>

    <!--Toolbar-->
    <div class="row">
      <div class="col-auto">
        <action-dropdown
          :ids="ids"
          :selected-product-ids="selectedProducts"
          :report="searchRequest"></action-dropdown>
      </div>
      <div class="col">
        <div class="form-group">
          <search-within @onSubmit="setSearchWithin($event)"></search-within>
          <small
            v-if="lastSearchTerm"
            id="search-help"
            class="form-text text-muted">Original Search:
            <strong>"{{ lastSearchTerm }}"</strong>
          </small>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <manufacturer-dropdown
            :manufacturers="manufacturers"
            @onSelected="setManufacturers($event)"></manufacturer-dropdown>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <sortby-dropdown @onSelected="setSortBy($event)"></sortby-dropdown>
        </div>
      </div>
    </div>

    <!--Products-->
    <div class="row">
      <div class="col">
        <results
          :products="products"
          :pagination="pagination"
          @onSelected="selectedProducts = $event"></results>
      </div>
    </div>

    <!--Pagination-->
    <div
      v-if="pagination.last_page > 1"
      class="search-pagination">
      <pagination
        :pagination="pagination"
        :callback="searchPagination"></pagination>
    </div>
  </div>
</template>

<script>
import qs from 'qs'
// import TitleComponent from 'components/pageTitle.vue'
import ReportFilter from 'components/products/search/ReportFilter'
import ActionDropdown from 'components/products/search/ActionDropdown'
import SearchWithinInput from 'components/products/search/SearchWithinInput'
import ManufacturersMultiSelect from 'components/products/search/ManufacturersMultiSelect'
import SortByDropdown from 'components/products/search/SortByDropdown'
// import QueryChips from 'components/products/search/QueryChips'
import Results from 'components/products/search/Results'
import Pagination from 'components/pagination'

export default {
  name: 'ProductSearchPage',
  components: {
    // 'page-title': TitleComponent,
    'report-filter': ReportFilter,
    'action-dropdown': ActionDropdown,
    'search-within': SearchWithinInput,
    'manufacturer-dropdown': ManufacturersMultiSelect,
    'sortby-dropdown': SortByDropdown,
    // 'query-chips': QueryChips,
    results: Results,
    pagination: Pagination
  },
  data () {
    return {
      showSpinner: false,
      showReportFilter: true,
      report: {
        banners: [],
        manufacturers: [],
        enrichmentLevel: null,
        lastUpdatedDate: null,
        salesTier: null,
        section: {},
        group: {},
        category: {},
        subCategory: {},
        hasCategory: null,
        hasDocument: null,
        hasImage: null,
        hasVideo: null,
        hasAssociated: null,
        hasAttribute: null,
        hasShortDescription: null,
        hasLongDescription: null,
        hasUpc: null,
        hasKeyword: null,
        isStocked: null,
        activeDemand: null,
        sortBy: null
      },
      searchRequest: {
        searchWithin: null,
        sortBy: null,
        ids: null,
        id: null
      },
      products: [],
      selectedManufacturers: [],
      manufacturers: [],
      ids: [],
      selectedProducts: [],
      lastSearchTerm: null,
      pagination: {
        total: 0,
        per_page: 50,
        current_page: 1,
        last_page: 0,
        from: 0,
        to: 15
      },
      searching: false,
      resultsFound: true
    }
  },
  computed: {
    languageId () {
      return this.$store.getters.languageId
    }
  },
  watch: {
    searching (val) {
      this.$store.dispatch('setSpinner', val)
    },
    '$route.query': {
      handler (val, oldVal) {
        if (!Object.keys(val).length) {
          this.reset()
        } else {
          this.checkRouteParameters()
        }
      },
      deep: true
    }
  },
  created () {
    this.checkRouteParameters()
  },
  methods: {
    fetchData () {
      this.searching = true
      const params = this.removeNullProperties({
        ...this.searchRequest,
        manufacturers: (this.selectedManufacturers.length > 0) ? this.selectedManufacturers.map(i => i.name) : this.searchRequest.manufacturers,
        from: this.pagination.from,
        size: this.pagination.per_page,
        languageId: this.languageId
      })

      this.$router.push({ query: params })
      this.$http.get('/api/products', {
        params,
        paramsSerializer (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        }
      }).then(res => {
        const data = res.data
        this.products = data.products

        // Set Banner Order
        const bannerOrderMap = new Map([['Platt', 1], ['Rexel', 2], ['Gexpro', 3]])
        this.products.forEach((product) => {
          product.companySystems.forEach((companySystem) => {
            companySystem.order = bannerOrderMap.get(companySystem.name) ?? 99
          })
          product.companySystems.sort((a, b) => {
            return a.order - b.order
          })
        })

        this.pagination.total = data.resultCount
        this.pagination.last_page = Math.ceil(data.resultCount / this.pagination.per_page)
        this.ids = data.products.map(i => i.napmId)
        this.manufacturers = (this.manufacturers.length > 0) ? this.manufacturers : data.manufacturers
      }).catch(e => {
        console.log(e)
      }).then(() => {
        this.searching = false
        this.$store.dispatch('setSpinner', this.searching)
        if (this.products.length === 0) {
          this.resultsFound = false
          this.$router.replace({ query: {} })
        } else {
          this.resultsFound = true
          this.showReportFilter = false
        }
      })
    },
    checkRouteParameters () {
      if (Object.entries(this.$route.query).length !== 0 && !this.searching) {
        this.setSearchRequestFromQuery(this.$route.query)
      }
    },
    setSearchRequestFromReport (data) {
      this.searchRequest = {
        banners: data.banners.length > 0
          ? data.banners.map(i => {
            return i.name
          })
          : null,
        manufacturers: data.manufacturers.length > 0
          ? data.manufacturers.map(i => {
            return i.name
          })
          : null,
        enrichmentLevel: data.enrichmentLevel ? data.enrichmentLevel.value : null,
        lastUpdatedDate: data.lastUpdatedDate ? data.lastUpdatedDate.value : null,
        salesTier: data.salesTier ? data.salesTier.value : null,
        section: data.section.name ? data.section.name : null,
        group: data.group.name ? data.group.name : null,
        category: data.category.name ? data.category.name : null,
        subCategory: data.subCategory.name ? data.subCategory.name : null,
        hasCategory: data.hasCategory,
        hasDocument: data.hasDocument,
        hasImage: data.hasImage,
        hasVideo: data.hasVideo,
        hasAssociated: data.hasAssociated,
        hasAttribute: data.hasAttribute,
        hasShortDescription: data.hasShortDescription,
        hasLongDescription: data.hasLongDescription,
        hasUpc: data.hasUpc,
        hasKeyword: data.hasKeyword,
        isStocked: data.isStocked,
        activeDemand: data.activeDemand,
        searchTerm: data.searchTerm,
        searchWithin: data.searchWithin,
        sortBy: data.sortBy
      }
      this.lastSearchTerm = data.searchTerm
      this.fetchData()
    },
    setSearchRequestFromQuery (data) {
      const from = data.from ? parseInt(data.from) : 0
      const to = data.size ? parseInt(data.size) : 50
      const current = (from === 0) ? 1 : Math.round(from / to) + 1
      this.pagination = {
        from,
        to,
        current_page: current,
        per_page: to,
        total: 0
      }
      this.lastSearchTerm = data.searchTerm
      this.searchRequest = {
        banners: Array.isArray(data.banners) ? data.banners : (data.banners) ? [data.banners] : data.banners,
        manufacturers: Array.isArray(data.manufacturers) ? data.manufacturers : (data.manufacturers) ? [data.manufacturers] : data.manufacturers,
        lastUpdatedDate: data.lastUpdatedDate,
        enrichmentLevel: data.enrichmentLevel,
        salesTier: data.salesTier,
        section: data.section,
        group: data.group,
        category: data.category,
        subCategory: data.subCategory,
        hasCategory: data.hasCategory,
        hasDocument: data.hasDocument,
        hasImage: data.hasImage,
        hasVideo: data.hasVideo,
        hasAssociated: data.hasAssociated,
        hasAttribute: data.hasAttribute,
        hasShortDescription: data.hasShortDescription,
        hasLongDescription: data.hasLongDescription,
        hasUpc: data.hasUpc,
        hasKeyword: data.hasKeyword,
        isStocked: data.isStocked,
        activeDemand: data.activeDemand,
        searchTerm: data.searchTerm,
        searchWithin: data.searchWithin,
        sortBy: data.sortBy,
        ids: data.ids,
        id: data.id
      }
      this.fetchData()
    },
    setManufacturers (manufacturers) {
      this.selectedManufacturers = manufacturers
      this.fetchData()
    },
    setSearchWithin (term) {
      this.searchRequest.searchWithin = term
      this.manufacturers = []
      this.fetchData()
    },
    setSortBy (sortBy) {
      this.searchRequest.sortBy = sortBy.value
      this.fetchData()
    },
    searchPagination () {
      this.pagination.from = (this.pagination.current_page === 1) ? 0 : (this.pagination.current_page - 1) * this.pagination.per_page + 1
      this.fetchData()
    },
    removeNullProperties (obj) {
      Object.entries(obj).forEach(([key, val]) => {
        ((val && typeof val === 'object') && this.removeNullProperties(val)) ||
          ((val === null || val === '' || val === undefined) && delete obj[key])
      })
      return obj
    },
    removeProperty (name) {
      this.manufacturers = []

      if (this.$route.query.banners) {
        let bannerIndex = (this.searchRequest.banners) ? this.searchRequest.banners.findIndex(i => i === name) : -1
        if (bannerIndex > -1) {
          this.$delete(this.searchRequest.banners, bannerIndex)
        }

        bannerIndex = (this.report.banners) ? this.report.banners.findIndex(i => i.name === name) : -1
        if (bannerIndex > -1) {
          this.$delete(this.report.banners, bannerIndex)
        }

        this.$router.push({ query: { banners: this.searchRequest.banners } })
      }

      if (this.$route.query[name] !== undefined) {
        this.searchRequest[name] = null
        this.fetchData()
      }
    },
    reset () {
      this.$router.push({ query: {} })
      this.showReportFilter = true
      this.manufacturers = []
      this.selectedManufacturers = []
      this.pagination = {
        total: 0,
        per_page: 50,
        current_page: 1,
        last_page: 0,
        from: 0,
        to: 15
      }
      this.report = {
        banners: [],
        manufacturers: [],
        enrichmentLevel: null,
        lastUpdatedDate: null,
        salesTier: null,
        section: {},
        group: {},
        category: {},
        subCategory: {},
        hasCategory: null,
        hasDocument: null,
        hasImage: null,
        hasVideo: null,
        hasAssociated: null,
        hasAttribute: null,
        hasShortDescription: null,
        hasLongDescription: null,
        hasUpc: null,
        hasKeyword: null,
        isStocked: null,
        activeDemand: null,
        sortBy: null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/bootstrap-custom-variables";
@import "../../assets/scss/extra/mixins/flexbox.scss";
.top-title {
  font-weight: 400;
  padding: 1.25rem 0 1rem;
  .inline-container {
    @include flexbox;

    .page-name {
      color: #333;
      margin-bottom: 0;
      display: block; /* Fallback for non-webkit */
      display: -webkit-box;
      height: 1.5rem * 1.5; /* Fallback for non-webkit */
      font-size: 1.5rem;
      line-height: 1.5;
      font-weight: 500;
      text-transform: capitalize;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .btn-action-back {
      display: block;
      color: #333;
      margin: 0.25rem 0.25rem 0 0;
      line-height: 1.5;
      @include flex(0, 1, 1.75rem);
      @include align-self(center);
      cursor: pointer;
      .go-back {
        vertical-align: text-bottom;
        font-size: 1.5rem;
      }
      &:hover {
        color: #000;
        font-weight: 500;
      }
    }
  }
}

.product-search-results {
  .dropdown-toggle {
    height: 43px;
  }
  .search-box {
    height: 43px;
    & ~ .input-group-btn {
      .btn {
        height: 43px;
      }
    }
  }

  .btn-group {
    > .btn:first-child:not(:last-child) {
      @include flex(1, 1, 80%);
    }

    > .btn:last-child:not(:first-child),
    > .dropdown-toggle:not(:first-child) {
      border-left-color: rgba(255, 255, 255, 0.37);
    }
  }
}
</style>
