<template>
  <div>
    <label class="form-control-label">
      {{ label }}
    </label>
    <multi-select
      v-model="selected"
      :options="options"
      label="name"
      track-by="id"
      :multiple="false"
      :disabled="options.length === 0"></multi-select>
  </div>
</template>

<script>
import Multiselect from 'components/inputs/multiselect.vue'

export default {
  name: 'CategoryDropdown',
  components: {
    'multi-select': Multiselect
  },
  props: {
    parentId: {
      type: Number,
      required: false
    },
    languageId: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    init: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      options: [],
      selected: null
    }
  },
  watch: {
    parentId (val, oldVal) {
      if (val !== oldVal && val !== null) {
        this.resetDropdown()
        this.fetchData()
      } else if (val === null) {
        this.resetDropdown()
      }
    },
    selected (val) {
      this.emitSelected()
    }
  },
  mounted () {
    if (this.init) this.fetchData()
  },
  methods: {
    fetchData () {
      const request = (this.parentId) ? this.getChildren() : this.getSection()
      request.then(res => {
        this.options = res.data
      }).catch(e => {
        console.log(e)
      })
    },
    getSection () {
      return this.$http.get(`/api/categories/children?languageId=${this.languageId}`)
    },
    getChildren () {
      return this.$http.get(`/api/categories/${this.parentId}/children?languageId=${this.languageId}`)
    },
    emitSelected () {
      this.$emit('onSelected', this.selected)
    },
    resetDropdown () {
      this.selected = null
      this.options = []
    }
  }
}
</script>
