<template>
  <iframe
    style="width: 100%; height: 100vh; border: none;"
    :src="externalUrl">
  </iframe>
</template>

<script>
export default {
  name: 'ExternalContent',
  computed: {
    externalUrl () {
      if (this.$route.params.externalUrl) {
        return this.$route.params.externalUrl
      } else {
        return this.getExternalUrl()
      }
    }
  },
  methods: {
    getExternalUrl () {
      return `${this.$store.state.appConfig.marketingContentUrl}/${this.$route.query.contentName}?external=true`
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
