<template>
  <span></span>
</template>
<script>
import { manager, initAuth } from '@/auth'

export default {
  name: 'AuthCallbackSilent',
  async mounted () {
    if (manager != null) {
      console.log('AuthCallbackSilent, manager not null')
      manager.signinSilentCallback()
    } else {
      console.log('AuthCallbackSilent, NULL')
      await initAuth()
    }
  }
}
</script>
<style></style>
