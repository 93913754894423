<template>
  <div class="card-block">
    <div class="row">
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            ERP
          </label>
          <select
            v-model="selectedBanner"
            class="form-control"
            :disabled="isReadOnly">
            <option :value="0">
              - Select -
            </option>
            <template v-for="banner in banners">
              <option
                :key="banner.bannerId"
                :value="banner.bannerId">
                {{ `${banner.bannerName} (${banner.erpCode})` }}
              </option>
            </template>
          </select>
        </div>
      </div>
    </div>

    <template v-if="selectedBanner">
      <div class="row">
        <div class="col-md-6 col-lg-3">
          <div class="form-group">
            <label class="form-control-label">
              Code
            </label>
            <input
              v-model="product.code"
              type="text"
              class="form-control"
              disabled />
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="form-group">
            <label class="form-control-label">
              Price Line
            </label>
            <input
              v-model="product.sku"
              type="text"
              class="form-control"
              :disabled="isReadOnly" />
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="form-group">
            <label class="form-control-label">
              Manufacturer AID
            </label>
            <input
              v-model="product.mfrCode"
              type="text"
              class="form-control"
              :disabled="isReadOnly" />
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="form-group">
            <label class="form-control-label">
              Life Cycle
            </label>
            <select
              v-model="product.activeStatusCode"
              class="form-control"
              :disabled="isReadOnly">
              <option value="">
                --- Choose Lifecycle ---
              </option>
              <option value="1">
                Active
              </option>
              <option value="0">
                Inactive
              </option>
              <option value="2">
                Obsolete
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="form-group">
            <label class="form-control-label">
              UOM
            </label>
            <input
              v-model="product.uom"
              type="text"
              class="form-control"
              :disabled="isReadOnly" />
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="form-group">
            <label class="form-control-label">
              Minimum Order Quantity
            </label>
            <input
              v-model="product.minOrdQty"
              type="text"
              class="form-control"
              :disabled="isReadOnly" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col wrap d-flex align-items-center">
          <label class="custom-control custom-checkbox">
            <input
              v-model="product.showOnWeb"
              type="checkbox"
              class="custom-control-input"
              :disabled="isReadOnly" />
            <span class="custom-control-indicator"></span>
            <span class="custom-control-description">
              Show On Web
            </span>
          </label>
          <label class="custom-control custom-checkbox">
            <input
              v-model="product.visibleOnlyToCustomers"
              type="checkbox"
              class="custom-control-input"
              :disabled="isReadOnly" />
            <span class="custom-control-indicator"></span>
            <span class="custom-control-description">
              Only Visible To Customers
            </span>
          </label>
          <label class="custom-control custom-checkbox">
            <input
              v-model="product.stockItem"
              type="checkbox"
              class="custom-control-input"
              :disabled="isReadOnly" />
            <span class="custom-control-indicator"></span>
            <span class="custom-control-description">
              Normally In Stock
            </span>
          </label>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import PermissionsMixin from 'mixins/Permissions.mixin'

export default {
  props: {
    banners: Array
  },
  data () {
    return {
      product: {},
      selectedBanner: null
    }
  },
  computed: {
    isReadOnly () {
      return !this.$store.getters.hasPermissionForAnyCanadaBanner(PermissionsMixin.data.Roles.contributor)
    }
  },
  watch: {
    selectedBanner (val, oldVal) {
      if (val === 0) return
      const index = this.banners.findIndex(i => i.bannerId === val)
      const banner = this.banners[index]
      this.product = banner
    }
  }
}
</script>
