<template>
  <section class="media-section">
    <div class="media-list">
      <new-media
        :manufacturers="manufacturers"
        :document-types="documentTypes"
        @update="$emit('recent')"></new-media>
      <template v-if="media.length && $route.name === 'media'">
        <router-link
          v-for="(m) in media"
          :key="m.mediaId"
          :to="{name: 'mediaDetails', params: {id: m.mediaId, type: m.mediaTypeId}}"
          tag="div"
          class="card">
          <div class="card-media">
            <media-icon
              :type="m.mediaType"
              :file="m.fileName"
              :img-path="getMediaImagePath({ folder: m.folderName, size: 77 })"
              size="media-list-icon"></media-icon>
          </div>
          <div class="media-details">
            <div class="media-type">
              {{ m.mediaType }}
            </div>
            <div
              v-if="m.name"
              class="media-name">
              {{ m.name.substring(0, 15) }}
              <span v-if="m.name.length > 14">
                ...
              </span>
            </div>
            <div
              v-else-if="m.fileName"
              class="media-name">
              {{ m.fileName.substring(0, 15) }}
              <span v-if="m.fileName.length > 14">
                ...
              </span>
            </div>
            <div
              v-else
              class="media-name">
&nbsp;
            </div>
            <div
              v-if="m.mfrId"
              class="media-mfr">
              Mfr: {{ getManfacturer(m.mfrId) }}
            </div>
            <div
              v-else
              class="media-mfr">
&nbsp;
            </div>
            <div class="media-date">
              <i class="material-icons">
                today
              </i>
              {{ m.uploadDate | isoDate }}
            </div>
          </div>
        </router-link>
      </template>
    </div>
    <div class="pl-1 pt-3 pr-4 mr-5">
      <pagination
        :pagination="pagination"
        :callback="searchPagintation"
        :options="paginationOptions"
        :pager="false"></pagination>
    </div>
  </section>
</template>
<script type="text/javascript">
import MediaIcon from '../mediaIcon.vue'
import NewMediaSection from '../upload/uploadSingle.vue'
import PaginationComponent from 'components/pagination.vue'
import MediaFilePathMixin from '@/views/media/types/MediaFilePath.mixin'

export default {
  name: 'MediaList',
  components: {
    'media-icon': MediaIcon,
    'new-media': NewMediaSection,
    pagination: PaginationComponent
  },
  mixins: [MediaFilePathMixin],
  props: {
    media: Array,
    pagination: Object,
    manufacturers: Array,
    documentTypes: Array
  },
  data () {
    return {
      loading: false,
      paginationOptions: {
        offset: 5,
        previousText: 'Prev',
        nextText: 'Next',
        alwaysShowPrevNext: true,
        pageSizes: [20, 40, 60, 100]
      }
    }
  },
  methods: {
    mediaItemSelected () { },
    searchPagintation (pageSize) {
      this.$emit('pagination', pageSize || false)
    },
    getManfacturer (mfr) {
      const manfacturer = this.manufacturers.find(man => {
        return man.id === mfr
      })
      return manfacturer ? manfacturer.name : ''
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/extra/mixins/flexbox.scss";
@import "../../../assets/scss/bootstrap-custom-variables.scss";

.media-list {
  @include flexbox;
  @include flex-wrap(wrap);
  .card {
    cursor: pointer;
    overflow: hidden;
    min-height: 12rem;
    min-width: 8.75rem;
    margin: 0.5rem;
    @include flex(0, 1, 8.75rem);
    @include align-items(center);
    @include justify-content(space-around);

    @media (min-width: 35.5rem) {
      max-width: calc(100% / 3 - 1rem);
    }
    @media (min-width: 48rem) {
      max-width: calc(100% / 4 - 1rem);
    }
    @media (min-width: 68rem) {
      max-width: calc(100% / 6 - 1rem);
    }
    @media (min-width: 75rem) {
      max-width: calc(100% / 8 - 1rem);
    }
    &:hover {
      border-color: $blue;
      transition: all 600ms;
    }
  }
  .card {
    height: 100%;
    overflow: hidden;
    @include align-items(center);
    &:not(.card-selected):hover {
      transition: all 100ms;
      border-color: $gray-500;
    }
    &.card-selected {
      transition: all 100ms;
      border-color: $blue;
    }
    .card-media {
      width: 100%;
      @include flex(0, 1, 4rem);
    }
    .media-details {
      width: 100%;
      padding: 0.25rem;
      font-size: 0.8125rem;
      text-align: center;
      @include flex(1, 1, 50%);
      .media-type {
        display: block; /* Fallback for non-webkit */
        max-width: 100%;
        height: 0.75rem * 1.5; /* Fallback for non-webkit */
        font-size: 0.75rem;
        line-height: 1.5;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 0.5rem;
        font-weight: bold;
        font-style: italic;
        color: #999;
        text-transform: uppercase;
        text-decoration: underline;
      }
      .media-name {
        font-weight: bold;
        color: $gray-800;
      }
      .media-mfr {
        display: block; /* Fallback for non-webkit */
        max-width: 100%;
        font-size: 0.75rem;
        line-height: 1.5;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        padding: 0 0.5rem;
        font-style: italic;
        color: $gray-600;
      }
      .media-date {
        color: $gray-600;
        padding: 0 0.5rem;
        font-size: 0.75rem;
      }
    }
  }
  .media-details {
  }
}
</style>
