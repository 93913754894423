<template>
  <div>
    <label class="form-control-label">
      Manufacturers
    </label>
    <multi-select
      v-model="selected"
      :options="options"
      label="name"
      track-by="id"
      :multiple="true"></multi-select>
  </div>
</template>

<script>
import Multiselect from 'components/inputs/multiselect.vue'

export default {
  name: 'ManufacturerDropdown',
  components: {
    'multi-select': Multiselect
  },
  props: {
    label: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      options: [],
      selected: null
    }
  },
  watch: {
    selected (val) {
      this.emitSelected()
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.$http.get('/api/manufacturers')
        .then(res => {
          this.options = res.data
        }).catch(e => {
          console.log(e)
        })
    },
    emitSelected () {
      this.$emit('onSelected', this.selected)
    },
    resetDropdown () {
      this.selected = null
    }
  }
}
</script>
