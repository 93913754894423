<template>
  <div>
    <div class="product-images">
      <div
        v-for="(img) in mainImage"
        :key="'img-' + img.mediaId"
        class="product-img">
        <a
          class="img-remove"
          @click.prevent="removeImage(img)">
          <i class="material-icons">
            &#xE5CD;
          </i>
        </a>
        <div class="img-wrap">
          <span
            v-if="img.new"
            class="img-new badge badge-success">NEW</span>
          <div class="img-fluid img-large">
            <a
              v-if="img.mediaType == 'Video'"
              class="media-play"
              @click="getVideoLink(img.mediaId)">
              <img src="../../../assets/images/icon-play.svg" />
            </a>
            <img
              v-else-if="img.folderNameGuid != null"
              style="height: 77px"
              :src="`https://rexel-cdn.com/products/${img.folderNameGuid}/${img.folderNameGuid}.jpg`" />
            <img
              v-else-if="img.fileName || img.fileName != ''"
              :src="`https://rexel-cdn.com/products/${img.fileName}.jpg?i=${img.folderName}&f=150`" />

            <img
              v-else
              :src="`https://rexel-cdn.com/products/${img.fileName}.jpg?i=0EA7A6C5-7DD2-4482-9229-D628D3AFAE9D&f=150`" />
          </div>
        </div>
      </div>
      <div
        v-for="(img) in notMainImage"
        :key="'img-' + img.mediaId"
        class="product-img">
        <a
          class="img-remove"
          @click.prevent="removeImage(img)">
          <i class="material-icons">
            &#xE5CD;
          </i>
        </a>
        <div class="img-wrap img-small">
          <span
            v-if="img.new"
            class="img-new badge badge-success">NEW</span>
          <div class="img-fluid">
            <a
              v-if="img.mediaType == 'Video'"
              class="media-play"
              @click="getVideoLink(img.mediaId)">
              <img src="../../../assets/images/icon-play.svg" />
            </a>
            <img
              v-else-if="img.folderNameGuid != null"
              style="height: 77px"
              :src="`https://rexel-cdn.com/products/${img.folderNameGuid}/${img.folderNameGuid}.jpg`" />
            <img
              v-else-if="img.fileName || img.fileName != ''"
              :src="`https://rexel-cdn.com/products/${img.fileName}.jpg?i=${img.folderName}&f=77`" />

            <img
              v-else
              :src="`https://rexel-cdn.com/products/${img.fileName}.jpg?i=0EA7A6C5-7DD2-4482-9229-D628D3AFAE9D&f=77`" />
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <button
        type="button"
        class="btn btn-block btn-primary"
        @click="modalUpload = true">
        Add / Edit Media
      </button>
    </div>
    <upload-search
      :show-modal.sync="modalUpload"
      :media-list.sync="images"
      :idea-images.sync="ideaImages"
      :document="false"
      :mfr="mfr"
      @add="addMedia($event)"
      @remove="removeImage($event.media)"></upload-search>

    <play-video
      :open.sync="showVideo"
      :src.sync="videoSrc"></play-video>
  </div>
</template>
<script type="text/javascript">
import PlayVideo from '../../media/mediaDetails/playVideo.vue'
import UploadMedia from '../../media/upload/uploadSearch.vue'
import { EventBus } from './bus'
export default {
  name: 'ProductEditImages',
  components: {
    'play-video': PlayVideo,
    'upload-search': UploadMedia
  },
  props: {
    mfr: Number,
    images: Array,
    ideaImages: Array,
    language: Number,
    addedImages: Array,
    removedImages: Array
  },
  data () {
    return {
      videoSrc: {},
      showVideo: false,
      modalUpload: false,
      modalThirdPartyUpload: false
    }
  },
  computed: {
    mainImage () {
      return this.images.filter((i) => {
        return i.ordinalPosition === 1
      })
    },
    notMainImage () {
      return this.images.filter((i) => {
        return i.ordinalPosition !== 1
      })
    }
  },
  mounted () {
    EventBus.$on('useImage', data => {
      const useImage = data
      useImage.new = true
      if (!this.images.length) {
        useImage.ordinalPosition = 1
      }
      this.images.push(useImage)
      this.addedImages.push(useImage.mediaId)
      this.$emit('useSuccess')
    })
  },
  methods: {
    removeImage (img) {
      const imgId = img.mediaId
      let isNewImg = false

      const index = this.images.findIndex(x => x.mediaId === img.mediaId)
      if (index > -1) {
        this.images.splice(index, 1)
      }

      this.addedImages.forEach((id, index) => {
        if (imgId === id) {
          this.addedImages.splice(index, 1)
          isNewImg = true
        }
      })

      if (!isNewImg) {
        this.removedImages.push(imgId)
      }

      // if this is the main image, then fall back to use the next top image to be main instead.
      if (img.ordinalPosition === 1 && this.images.length > 0) {
        this.images[0].ordinalPosition = 1
      }
    },
    addMedia (img) {
      const hasMain = this.images.some(i => i.ordinalPosition === 1)
      if (!hasMain) {
        img.ordinalPosition = 1
      }
      this.addedImages.push(img.mediaId)
    },
    getVideoLink (mediaId) {
      this.$http.get(`/api/media/videos/${mediaId}/urls`).then(res => {
        this.showVideo = true
        this.videoSrc = res.data
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/bootstrap-custom-variables";
@import "../../../assets/scss/extra/mixins/flexbox.scss";

.product-images {
  @include flexbox;
  @include flex-wrap(wrap);
  @include justify-content(space-between);
}

.product-img {
  width: calc(50% - 1rem);
  margin-bottom: 1rem;
  position: relative;
  border: 1px solid #ddd;
  padding: 1rem;
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  @media (min-width: 48rem) {
    width: calc(100% / 4 - 1rem);
  }
  @media (min-width: 60rem) {
    width: calc(50% - 5px);
  }
  .img-remove {
    position: absolute;
    top: 0;
    right: 5px;
    color: darken($brand-danger, 5%);
  }
  .img-wrap {
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    &.img-small {
      min-width: 77px;
      min-height: 77px;
    }
    .img-new {
      position: absolute;
      top: 0;
      left: 0;
    }
    .media-play {
      padding: 1rem;
      cursor: pointer;
    }
  }

  &:first-of-type {
    @media (min-width: 60rem) {
      width: 100%;
      min-width: 150px;
      min-height: 150px;
    }
  }
}

.upload-btn {
  cursor: pointer;
  position: relative;
  text-transform: uppercase;

  input.file-upload {
    opacity: 0;
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
  }
}
</style>
