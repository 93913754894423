<template>
  <div
    v-show="is_open"
    :class="overlay_classes"
    @click="_onOverlayClick">
    <div
      :class="modal_classes"
      :style="{ 'max-height': large ? 'auto' : `${overlayHeight}rem` }">
      <div class="sweet-box-actions">
        <!-- Custom Actions -->
        <slot name="box-action"></slot>
        <!-- Close Button -->
        <div
          v-if="!hideCloseButton"
          class="sweet-action-close"
          @click="close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
              fill="#292c34" />
          </svg>
        </div>
      </div>
      <!-- Title: Housing the title and tabs, if no title is present -->
      <div
        v-if="has_title || has_tabs"
        class="sweet-title">
        <!-- Tabs but no title -->
        <template v-if="has_tabs && !has_title">
          <ul class="sweet-modal-tabs">
            <li
              v-for="tab in tabs"
              :key="tab-title"
              :class="_getClassesForTab(tab)">
              <a
                href="#"
                @click.prevent="_changeTab(tab)">
                <div class="sweet-modal-valign">
                  <span
                    v-if="tab.icon"
                    class="sweet-modal-tab-icon"
                    v-html="tab.icon"></span>
                  <span class="sweet-modal-tab-title">
                    {{ tab.title }}
                  </span>
                </div>
              </a>
            </li>
          </ul>
        </template>
        <!-- Title -->
        <template v-if="has_title">
          <h2 v-if="title">
            {{ title }}
          </h2>
          <slot name="title"></slot>
        </template>
      </div>
      <!-- Tabs: If title AND tabs are present -->
      <ul
        v-if="has_title && has_tabs"
        class="sweet-modal-tabs">
        <li
          v-for="(tab, index) in tabs"
          :key="`${tab-title}-${index}`"
          :class="_getClassesForTab(tab)">
          <a
            href="#"
            @click.prevent="_changeTab(tab)">
            <div class="sweet-modal-valign">
              <span
                v-if="tab.icon"
                class="sweet-modal-tab-icon"
                v-html="tab.icon"></span>
              <span class="sweet-modal-tab-title">
                {{ tab.title }}
              </span>
            </div>
          </a>
        </li>
      </ul>
      <!-- Content: Wrapper -->
      <div
        ref="content"
        class="sweet-content"
        :style="{ 'max-height': large ? 'auto' : `${overlayHeight - 8}rem` }">
        <!-- Icon: Error -->
        <div
          v-if="icon == 'error'"
          ref="icon_error"
          class="sweet-modal-icon sweet-modal-error">
          <span class="sweet-modal-x-mark">
            <span class="sweet-modal-line sweet-modal-left"></span>
            <span class="sweet-modal-line sweet-modal-right"></span>
          </span>
        </div>
        <!-- Icon: Warning -->
        <div
          v-if="icon == 'warning'"
          ref="icon_warning"
          class="sweet-modal-icon sweet-modal-warning">
          <span class="sweet-modal-body"></span>
          <span class="sweet-modal-dot"></span>
        </div>
        <!-- Icon: Info -->
        <div
          v-if="icon == 'info'"
          ref="icon_info"
          class="sweet-modal-icon sweet-modal-info"></div>
        <!-- Icon: Success -->
        <div
          v-show="icon == 'success'"
          ref="icon_success"
          class="sweet-modal-icon sweet-modal-success">
          <span class="sweet-modal-line sweet-modal-tip"></span>
          <span class="sweet-modal-line sweet-modal-long"></span>
          <div class="sweet-modal-placeholder"></div>
          <div class="sweet-modal-fix"></div>
        </div>
        <!-- Actual Content -->
        <div
          v-if="$slots.default"
          class="sweet-content-content">
          <slot></slot>
        </div>
      </div>
      <!-- Buttons -->
      <div
        v-if="$slots.button"
        class="sweet-buttons">
        <slot name="button"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SweetModal',
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },

    overlayTheme: {
      type: String,
      required: false,
      default: 'light'
    },

    modalTheme: {
      type: String,
      required: false,
      default: 'light'
    },

    blocking: {
      type: Boolean,
      required: false,
      default: false
    },

    icon: {
      type: String,
      required: false,
      default: ''
    },

    animateIcon: {
      type: Boolean,
      required: false,
      default: false
    },

    hideCloseButton: {
      type: Boolean,
      required: false,
      default: false
    },

    small: {
      type: Boolean,
      required: false,
      default: false
    },

    large: {
      type: Boolean,
      required: false,
      default: false
    },

    extraLarge: {
      type: Boolean,
      required: false,
      default: false
    },

    extraExtraLarge: {
      type: Boolean,
      required: false,
      default: false
    },

    noPadding: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      visible: false,
      is_open: false,
      is_bouncing: false,
      tabs: []
    }
  },
  computed: {
    has_title () {
      return this.title || this.$slots.title
    },

    has_tabs () {
      return this.tabs.length > 0
    },

    has_content () {
      return this.$slots.default
    },

    current_tab () {
      return this.tabs.filter(t => t.active === true)[0]
    },
    overlayHeight () {
      const height = window.innerHeight
      return height / 16
    },
    overlay_classes () {
      return [
        'sweet-modal-overlay',
        'theme-' + this.overlayTheme,
        'sweet-modal-clickable', {
          'is-visible': this.visible,
          blocking: this.blocking
        }
      ]
    },
    modal_classes () {
      return [
        'sweet-modal',
        'theme-' + this.modalTheme, {
          'has-title': this.has_title,
          'has-tabs': this.has_tabs,
          'has-content': this.has_content,
          'has-icon': this.icon,
          'is-visible': this.visible,
          'sweet-alert': (this.icon && !this.has_tabs) || (!this.icon && !this.title && !this.$slots.title),
          bounce: this.is_bouncing,
          'sweet-modal-sm': this.small,
          'sweet-modal-lg': this.large,
          'sweet-modal-xl': this.extraLarge,
          'sweet-modal-xxl': this.extraExtraLarge,
          'no-content-padding': this.noPadding
        }
      ]
    }
  },
  watch: {
    animateIcon (value) {
      if (value && this.icon === 'success') {
        setTimeout(() => {
          this._applyClasses(this.$refs.icon_success, {
            '': ['animate'],
            '.sweet-modal-tip': ['animateSuccessTip'],
            '.sweet-modal-long': ['animateSuccessLong']
          })
        }, 80)
      }
    }
  },
  mounted () {
    this.tabs = this.$children.filter(c => c.cmpName && c.cmpName === 'tab')

    if (this.has_tabs) {
      this.currentTab = this._changeTab(this.tabs[0])
    }

    document.addEventListener('keyup', this._onDocumentKeyup)
  },
  beforeDestroy () {
    document.removeEventListener('keyup', this._onDocumentKeyup)
  },
  methods: {
    /**
     * Open the dialog
     * Emits an event 'open'
     */
    open () {
      this.is_open = true
      this._animateIcon()

      setTimeout(() => {
        this.visible = true
      }, 30)

      this.$emit('open')
      document.body.classList.add('sweet-modal-active')
    },
    /**
     * Close the dialog
     * Emits an event 'close'
     */
    close () {
      this.visible = false

      setTimeout(() => {
        this.is_open = false
      }, 300)

      this.$emit('close')
      document.body.classList.remove('sweet-modal-active')
    },
    /**
     * Bounce the modal.
     */
    bounce () {
      this.is_bouncing = true

      setTimeout(() => {
        this.is_bouncing = false
      }, 330)
    },
    /**********************
        INTERNAL METHODS
     **********************/
    _onOverlayClick (event) {
      if (!event.target.classList || event.target.classList.contains('sweet-modal-clickable')) {
        if (this.blocking) {
          this.bounce()
        } else {
          this.close()
        }
      }
    },

    _onDocumentKeyup (event) {
      if (event.keyCode === 27) {
        if (this.blocking) {
          this.bounce()
        } else {
          this.close()
        }
      }
    },

    _changeTab (tab) {
      this.currentTab = this.tabs.map((t) => {
        t.active = t === tab
        return t
      })
    },

    _getClassesForTab (tab) {
      return [
        'sweet-modal-tab',
        {
          active: tab.active,
          disabled: tab.disabled
        }
      ]
    },

    _animateIcon () {
      if (!this.icon) return

      switch (this.icon) {
        case 'success':
          setTimeout(() => {
            this._applyClasses(this.$refs.icon_success, {
              '': ['animate'],
              '.sweet-modal-tip': ['animateSuccessTip'],
              '.sweet-modal-long': ['animateSuccessLong']
            })
          }, 80)

          break

        case 'warning':
          this._applyClasses(this.$refs.icon_warning, {
            '': ['pulseWarning'],
            '.sweet-modal-body': ['pulseWarningIns'],
            '.sweet-modal-dot': ['pulseWarningIns']
          })

          break

        case 'error':
          setTimeout(() => {
            this._applyClasses(this.$refs.icon_error, {
              '': ['animateErrorIcon'],
              '.sweet-modal-x-mark': ['animateXMark']
            })
          }, 80)

          break
      }
    },

    /**
     * Apply classes from the classMap to $ref or children of $ref, a native
     * DOMElement.
     *
     * ClassMap:
     * {
     *     'selector': [ 'class1', 'class2', ... ]
     * }
     *
     * Empty Selector selects $ref.
     *
     * @param DOMNode $ref     Element to apply classes to or children of that element
     * @param Object  classMap Class Map which elements get which classes (see doc)
     */
    _applyClasses ($ref, classMap) {
      for (const cl in classMap) {
        const classes = classMap[cl]
        let $el

        if (cl === '') {
          $el = $ref
        } else {
          $el = $ref.querySelector(cl)
        }

        $el.classList.remove(...classes)
        $el.classList.add(...classes)
      }
    }
  }
}
</script>
<style lang="scss">
@import '../../assets/scss/extra/mixins/flexbox.scss';
@import '../../assets/scss/extra/components/modals/icons.scss';
@import '../../assets/scss/extra/components/modals/mixins.scss';
@import '../../assets/scss/extra/components/modals/colors.scss';
@import '../../assets/scss/extra/components/modals/animations.scss';

$color:color(dark-modal);
.sweet-modal-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9002;
  top: 0;
  left: 0; // display:block !important;
  // Animation
  opacity: 0;
  transition: opacity .3s;
  transform: translate3D(0, 0, 0);
  overflow-y: auto; // Theming
  background: rgba(#333, .25);
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;

  font-size: .875rem;

  -webkit-font-smoothing: antialiased;
  -webkit-perspective: 500px;
  &.theme-dark {
    // background: radial-gradient(ellipse at center, rgba($color, 0.9) 0%, rgba($color, 0.96) 100%);
    background: rgba($color, .94);

    $color: color(dark-overlay);
  }
  &.is-visible {
    opacity: 1;
  }
  @include media(mobile) {
    & {
      width: 100%;
      height: 100%;
    }
  }
}

.sweet-modal-active {
  overflow-y: hidden;
  margin-right: 1rem; // for scrollbar
}

.sweet-modal {
  width: 80%;
  max-width: 40rem;
  position: relative;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  transition: {
    property: transform, opacity;
    duration: .3s;
    delay: .05s;
    timing-function: cubic-bezier(.52, .02, .19, 1.02);
  }
  transform: scale(.9);
  opacity:0;
  border-top:3px solid color(blue);
  background:#fff;
  box-shadow:0 16px 32px 0 rgba(0, 0, 0, .3);
  @include border-box;
  // Responsiveness
  @include media(desktop) {
    // Bouncing animation
    @keyframes bounce {
      0% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.02);
      }

      100% {
        transform: scale(1);
      }
    }
  }
  @include media(mobile) {
    & {
      width: 100%;
      height: 100%;
      // max-width: 100%;
      // top: 0;
      // left: 0;
      // transform: scale(.9);
      // &.is-visible {
        // transform: none;
      // }
    }
    .sweet-buttons {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;

      @include border-box;
    }
  }

  .sweet-box-actions {
    position: absolute;
    top: .75rem;
    right: .75rem;
    .sweet-action-close {
      width: 2.625rem;
      height: 2.625rem;
      display: inline-block;
      color: color(dark);
      border-radius: 50%;

      cursor: pointer;
      text-align: center;
      line-height: 2.625rem;
      svg {
        width: 24px;
        height: 24px;
        margin-top: -2px;

        vertical-align: middle;

        @include svg-path(true) {
          fill: currentColor;
        }
      }
      &:hover {
        color: #fff;
        background: color(blue);
      }
    }
  }
  .sweet-title {
    height: 4rem;
    padding: {
      right: 4rem;
      left: 2rem;
    }
    border-bottom:1px solid color(border);

    line-height:4rem;

    @include ellipsis;
    >h2 {
      font-size: 1.375rem;
      font-weight: 500;
      line-height: 4rem;

      @include mp0;
      @include ellipsis;
    }
  }
  ul.sweet-modal-tabs {
    width: calc(100% + 2rem);
    height: 100%;
    margin-left: -2rem;
    overflow-x: auto;
    @include flexbox;
    @include align-items(center);
    @include ulreset;
    li.sweet-modal-tab {
      height: 100%;
      display: block;
      a {
        height: 100%;
        padding: 0 1.25rem;
        color: color(dark);
        text-align: center;
        text-decoration: none;
        @include ellipsis;
        @include flexbox;
        @include align-items(center);
        span.sweet-modal-tab-title {
          display: block;
        }
        span.sweet-modal-tab-icon {
          display: block;
          line-height: 1.0;
          svg,
          img {
            width: 16px;
            height: 16px;

            @include svg-path(true) {
              fill: currentColor;
            }
          }
        }
        span.sweet-modal-tab-icon+span.sweet-modal-tab-title {
          margin-top: .5rem;

          line-height: 1.0;
        }
      }
      &:first-child a {
        padding-left: 2rem;
      }
      &.active a {
        color: color(blue);

        font-weight: 600;
      }
      &.disabled a {
        color: color(light-grey);
        @include unclickable;
      }
    }
  }
  &.has-tabs:not(.has-title) {
    .sweet-title {
      height: 5.25rem;
      line-height: 5.25rem;
    }
  }
  &.has-tabs.has-title {
    ul.sweet-modal-tabs {
      width: 100%;
      height: 3rem;
      margin: 0;
      border-bottom: 1px solid color(border);
      li.sweet-modal-tab a {
        margin-top: -.25rem;
        span.sweet-modal-tab-icon {
          display: inline-block;
          svg,
          img {
            margin: {
              top: -2px;
              right: 8px;
            }
            vertical-align:middle;
          }
        }
        span.sweet-modal-tab-title {
          display: inline-block;
        }
      }
    }
  }
  &.no-content-padding {
    .sweet-content {
      padding: 0 !important;
      .sweet-modal-icon {
        margin: 2rem auto;
      }
    }
  }
  .sweet-content {
    padding: 1.5rem 2rem;
    max-width: 100%;

    line-height:1.5;
    overflow-y: auto;
    @include flexbox;

    .sweet-content-content {
      flex-grow: 1;
      width: 100%;
    }
    .sweet-modal-tab:not(.active) {
      display: none;
    }
    .sweet-modal-icon {
      margin-bottom: 2.25rem;
    }
  }
  .sweet-buttons {
    padding: {
      top: .75rem;
      right: 1.25rem;
      bottom: .75rem;
      left: 1.25rem;
    }

    text-align:right;
  }
  .sweet-content+.sweet-buttons {
    border-top: 1px solid color(border);
  }
  // Special Styles
  &.sweet-alert {
    .sweet-content {
      padding: {
        top: 4rem;
        bottom: 4rem;
      }
      display:block;

      font-size:1rem;
    }
  }
  &.has-tabs.has-icon .sweet-content {
    padding: 2rem 0;
    .sweet-content-content {
      padding-left: 2rem;
    }
    .sweet-modal-icon {
      margin-bottom: 0;
    }
  }
  &:not(.has-content) {
    .sweet-modal-icon {
      margin-bottom: 0;
    }
  }
  // Theming
  &.theme-dark {
    color: #fff;
    background: $color;
    .sweet-box-actions {
      .sweet-action-close {
        color: #fff;
      }
    }
    .sweet-title {
      border-bottom-color: darken($color, 8%);
      box-shadow: 0 1px 0 lighten($color, 8%);
    }
    ul.sweet-modal-tabs li {
      a {
        color: #fff;
      }
      &.active a {
        color: color(blue);
      }
      &.disabled a {
        color: lighten($color, 20%);
      }
    }
    &.has-tabs.has-title {
      ul.sweet-modal-tabs {
        border-bottom-color: darken($color, 8%);
        box-shadow: 0 1px 0 lighten($color, 8%);
      }
    }
    .sweet-content+.sweet-buttons {
      border-top-color: lighten($color, 8%);
      box-shadow: 0 -1px 0 darken($color, 8%);
    }
  }
  .sweet-buttons,
  .sweet-content {
    transition-property: transform, opacity;
    transition-duration: .3s;
    transition-delay: .09s;
    transition-timing-function: cubic-bezier(.52, .02, .19, 1.02);
    opacity: 0;
  }
  .sweet-content {
    transform: translateY(-8px);
  }
  .sweet-buttons {
    transform: translateY(16px);
  }

  &.is-visible {
    opacity: 1;
    .sweet-buttons,
    .sweet-content {
      transform: none;
      opacity: 1;
    }
    @include media(desktop) {
      & {
        transform: none;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-content: center;
        position: relative;
      }
    }
  }
  &.bounce {
    animation-name: bounce;
    animation-duration: .3s;
    animation-iteration-count: 2;
    animation-direction: alternate;
  }

  &.sweet-modal-sm {
    max-width: 25rem;
  }
  &.sweet-modal-lg {
    max-width: 48rem;
  }
  &.sweet-modal-xl {
    width: 72rem;
    max-width: 100%;
    max-height: 100%;
  }
  &.sweet-modal-xxl {
    width: 112rem;
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
