<template>
  <div class="categories-page">
    <page-title
      title="Categories"
      :route="from"></page-title>
    <breadcrumb
      :tree="categoryTree"
      :section-id="sectionId"
      :group-id="groupId"
      :category-id="categoryId"
      :sub-category-id="subCategoryId"
      @home="detailView = $event">
    </breadcrumb>
    <div class="row mt-2">
      <section class="col-12 col-sm-6 col-md-6">
        <transition
          name="fade"
          mode="out-in">
          <router-view></router-view>
        </transition>
      </section>
      <section class="col-12 col-sm-6 col-md-6">
        <category-details
          :id="Number(detailId)"
          :section-list="sectionList"
          :markets="markets"
          :lifecycles="lifecycles"
          :language="language"
          :companies="companies"
          :group-id="Number(groupId)"
          :section-id="Number(sectionId)"
          :category-id="Number(categoryId)"
          :sub-category-id="Number(subCategoryId)"
          :create-view.sync="createView"
          :detail-view.sync="detailView">
        </category-details>
      </section>
    </div>
  </div>
</template>
<script>
import qs from 'qs'
import axios from 'axios'
import CategoryDetails from './components/details'
import Breadcrumb from './components/breadcrumb'
import TitleComponent from 'components/pageTitle'
import EventBus from './components/bus'

export default {
  name: 'Categories',
  components: {
    breadcrumb: Breadcrumb,
    'category-details': CategoryDetails,
    'page-title': TitleComponent
  },
  beforeRouteEnter (to, from, next) {
    to.meta.spinner()
    next(vm => {
      vm.from = from
      axios.all([
        axios.get('/api/products/markets'),
        axios.get('/api/products/lifecycles'),
        axios.get(`/api/media/documents/types?languageId=${to.meta.lang}`),
        axios.get(`/api/categories/children?languageId=${to.meta.lang}`)
      ]).then(axios.spread((markets, lifecycles, documentTypes, sectionList) => {
        vm.markets = markets.data
        vm.lifecycles = lifecycles.data
        vm.sectionList = sectionList.data
        vm.documentTypes = documentTypes.data
        vm.companies = vm.$store.getters.user.companyPermissions.map(perm => {
          return perm.company.companyId
        })
        vm.$store.dispatch('setCategory', {
          key: 'section',
          value: sectionList.data
        })
        vm.$store.dispatch('setSpinner', false)
        vm.categoryState()
      }))
    })
  },
  props: [
    'sectionId',
    'groupId',
    'categoryId',
    'subCategoryId'
  ],
  data () {
    return {
      from: null,
      markets: [],
      companies: [],
      createView: '',
      detailView: '',
      sectionList: [],
      categoryTree: {},
      documentTypes: [],
      cancelToken: null,
      lifecycles: []
    }
  },
  computed: {
    language () {
      return this.$store.getters.languageId
    },
    detailId () {
      if (this.subCategoryId) {
        this.createView = null
        this.detailView = 'Sub Category'
        return this.subCategoryId
      } else if (this.categoryId) {
        this.createView = 'Sub Category'
        this.detailView = 'Category'
        return this.categoryId
      } else if (this.groupId) {
        this.createView = 'Category'
        this.detailView = 'Group'
        return this.groupId
      } else if (this.sectionId) {
        this.createView = 'Group'
        this.detailView = 'Section'
        return this.sectionId
      } else {
        this.createView = 'Section'
        return 0
      }
    },
    treeState () {
      const tree = []
      tree.push(this.sectionId ? this.sectionId : 0)
      tree.push(this.groupId ? this.groupId : 0)
      tree.push(this.categoryId ? this.categoryId : 0)
      return tree
    }
  },
  watch: {
    treeState: {
      deep: true,
      handler (value, oldValue) {
        if (value !== oldValue) {
          this.categoryState()
        }
      }
    },
    subCategoryId (value, oldValue) {
      if (value && value !== oldValue) {
        this.categoryState()
      }
    }
  },
  mounted () {
    EventBus.$on('refreshSection', payLoad => {
      this.$http.get(`/api/categories/children?languageId=${this.language}`)
        .then(res => {
          this.sectionList = res.data
          this.$store.dispatch('setCategory', {
            key: 'section',
            value: res.data
          })
        }).catch(e => console.warn(e))
    })
    EventBus.$on('catsRemoved', payLoad => {
      this.$emit('refreshSection')
      this.$router.back()
    })
  },
  methods: {
    categoryState () {
      if (this.cancelToken != null) {
        this.cancelToken.cancel('Bailed on request: re-starting..')
      }
      const params = {
        languageId: this.language,
        ids: this.treeState
      }
      this.cancelToken = axios.CancelToken.source()
      this.$http.get('/api/categories/children', {
        params,
        paramsSerializer (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        }
      }, { cancelToken: this.cancelToken.token })
        .then(res => {
          const result = res.data
          const length = Object.keys(result).length

          const treeObj = {
            section: this.sectionId ? this.sectionList.find(s => s.id === Number(this.sectionId)) : null,
            group: length >= 1 ? result.find(g => g.id === Number(this.groupId)) : null,
            category: length >= 2 ? result.find(c => c.id === Number(this.categoryId)) : null,
            subCategory: length > 2 ? result.find(s => s.id === Number(this.subCategoryId)) : null
          }
          this.categoryTree = treeObj
          this.cancelToken = null
        })
    }
  }
}
</script>
<style lang="scss">
@import "../../assets/scss/extra/mixins/flexbox";
@import "../../assets/scss/bootstrap-custom-variables";
.categories-page {
  .list-group {
    min-height: 700px;
    .list-group-title {
      font-weight: 600;
      margin-bottom: 0;
      @include flexbox;
      @include align-items(center);
      .material-icons {
        color: #000;
        margin-right: 0.5rem;
        &:hover {
          color: $brand-primary;
        }
      }
    }
    .list-group-item {
      border-width: 1px 0 1px;
      @include flexbox;
      @include align-items(center);
      @include justify-content(space-between);
      .badge {
        border-radius: 3px;
        margin: 0 0.5rem;
      }
      .material-icons {
        font-size: 1.25rem;
        font-weight: bold;
      }
    }
  }
}
</style>
