<template>
  <div class="team-goals">
    <h5 class="goals-title">
      Goals <em>(for enriching products)</em>
    </h5>
    <div class="dashboard-stats-table">
      <table class="table">
        <thead>
          <tr>
            <th></th>
            <th>LEVEL 1</th>
            <th>LEVEL 2</th>
            <th>LEVEL 3</th>
            <th>LEVEL 4</th>
            <th>LEVEL 5</th>
          </tr>
        </thead>
        <tbody>
          <template>
            <tr v-if="currentProgress">
              <td>TOTAL ENRICHED</td>
              <td :class="getColorClass(goals[0].level1Goal, currentProgress.level1)">
                {{ currentProgress.level1 | numericGoal }}
              </td>
              <td :class="getColorClass(goals[0].level2Goal, currentProgress.level2)">
                {{ currentProgress.level2 | numericGoal }}
              </td>
              <td :class="getColorClass(goals[0].level3Goal, currentProgress.level3)">
                {{ currentProgress.level3 | numericGoal }}
              </td>
              <td :class="getColorClass(goals[0].level4Goal, currentProgress.level4)">
                {{ currentProgress.level4 | numericGoal }}
              </td>
              <td :class="getColorClass(goals[0].level5Goal, currentProgress.level5)">
                {{ currentProgress.level5 | numericGoal }}
              </td>
            </tr>
            <template v-for="(c, i) in goals">
              <tr
                v-if="i !== editYearId"
                :key="'company-total-enriched-' + i">
                <td>
                  {{ c.year }}
                  <div
                    v-if="$store.state.isAdmin"
                    class="btn-group">
                    <button
                      class="btn btn-rounded btn-sm"
                      :class="{'btn-success' : i === goals.length - 1}"
                      @click="editYearGoals(i)">
                      <span v-if="i !== goals.length - 1">
                        Edit
                      </span>
                      <span v-else>
                        Add
                      </span>
                    </button>
                  </div>
                </td>
                <td :class="{'text-danger': !c.level1Goal}">
                  {{ c.level1Goal | numericGoal }}
                </td>
                <td :class="{'text-danger': !c.level2Goal}">
                  {{ c.level2Goal | numericGoal }}
                </td>
                <td :class="{'text-danger': !c.level3Goal}">
                  {{ c.level3Goal | numericGoal }}
                </td>
                <td :class="{'text-danger': !c.level4Goal}">
                  {{ c.level4Goal | numericGoal }}
                </td>
                <td :class="{'text-danger': !c.level5Goal}">
                  {{ c.level5Goal | numericGoal }}
                </td>
              </tr>
              <tr
                v-else
                :key="'company-total-enriched-edit' + i">
                <td>
                  {{ c.year }}
                  <div class="btn-group">
                    <button
                      class="btn btn-rounded btn-sm btn-primary"
                      @click="saveYearGoals(i)">
                      <span>Save</span>
                    </button>
                    <button
                      class="btn btn-rounded btn-sm btn-outline-danger"
                      @click="undoYearGoalsEdit()">
                      <span>Undo</span>
                    </button>
                  </div>
                </td>
                <td>
                  <input
                    v-model="goals[i].level1Goal"
                    type="text"
                    class="form-control" />
                </td>
                <td>
                  <input
                    v-model="goals[i].level2Goal"
                    type="text"
                    class="form-control" />
                </td>
                <td>
                  <input
                    v-model="goals[i].level3Goal"
                    type="text"
                    class="form-control" />
                </td>
                <td>
                  <input
                    v-model="goals[i].level4Goal"
                    type="text"
                    class="form-control" />
                </td>
                <td>
                  <input
                    v-model="goals[i].level5Goal"
                    type="text"
                    class="form-control" />
                </td>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeamBuilderGoalsTable',
  filters: {
    numericGoal (value) {
      if (!value) return '--'
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  props: {
    yearlyGoals: {
      type: Array,
      default: () => []
    },
    currentProgress: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      editYearId: -1,
      originalGoalJSON: null
    }
  },
  computed: {
    goals () {
      return [...this.yearlyGoals, {
        year: this.yearlyGoals[this.yearlyGoals.length - 1].year + 2,
        level1Goal: 0,
        level2Goal: 0,
        level3Goal: 0,
        level4Goal: 0,
        level5Goal: 0
      }]
    }
  },
  watch: {
    yearlyGoals () {
      console.log('hello')
    }
  },
  methods: {
    editYearGoals (index) {
      this.editYearId = index
      this.originalGoalJSON = JSON.stringify(this.goals[index])
    },
    undoYearGoalsEdit () {
      this.goals[this.editYearId] = JSON.parse(this.originalGoalJSON)
      this.editYearId = -1
    },
    saveYearGoals (index) {
      this.$http.put('/api/teams/enrichment-goals', this.goals[index])
        .then(res => {
          this.editYearId = -1
          this.$toasted.success('Goal updated successfully.', { theme: 'primary', position: 'bottom-center', duration: 5000, icon: 'done' })
        })
        .catch((err) => {
          console.log(err)
          this.$toasted.error(`Update failed: ${err.message}`, { theme: 'primary', position: 'bottom-center', duration: 5000, icon: 'close' })
          this.goals[this.editYearId] = JSON.parse(this.originalGoalJSON)
        })
    },
    getColorClass (goal, actual) {
      if (goal === 0) return ''
      if (goal > actual) return 'text-danger'
      return 'text-success'
    }
  }
}
</script>

<style scoped lang="scss">
.goals-title {
  font-size: 1.5rem;
  font-weight: 500;
  em {
    font-size: smaller;
    color: #aaa;
  }
}
table {
  font-size: 1rem;
  border-bottom: 1px solid #efefef;
  width: 100%;
  thead {
    tr {
      th {
        border:none;
        font-size: 1rem;
        text-align: center;
        color: #666;
        background-color: white;
      }
    }
  }
  tbody {
    tr {
      font-weight: 500;
      &:first-child {
        font-weight: 600;
      }
      td {
        text-align: center;
        &:first-child {
          background-color: #efefef;
          border:none;
          font-weight: 600;
          min-width: 13rem;
          width: 13rem;
        }
        &:last-child {
          border-right: 1px solid #efefef;
        }
        .btn-group {
          margin: 0 .1rem;
          min-width: 6.5rem;
        }
        .btn.btn-rounded.btn-sm {
          min-width: 3.1rem;
          margin: 0 auto;
        }
        input {
          max-width: 8rem;
          height: 31px;
          margin:auto;
          text-align: center;
        }
      }
    }
  }
}
</style>
