<template>
  <div class="product-cell">
    <div class="img-fluid product-img-container mr-3">
      <img
        v-if="item.folderName"
        :src="'https://rexel-cdn.com/products/' + item.folderName + '/50.jpg'" />
      <img
        v-else
        src="https://images.plattstatic.com/ProductImages/thumbnail/Nonstock.gif" />
    </div>
    <div class="product-desc-container">
      <div>
        Name:
        <a
          :href="`/products/edit/${item.napmID}`"
          target="_blank">
          <span class="text--brand">
            <strong>{{ item.catNum }}</strong>
          </span>
        </a>
      </div>
      <div>
        NAPM#: {{ item.napmID }}
      </div>
      <div>
        UPC: {{ item.upc || 'N/A' }}
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'ProductBodyCell',
  components: {},
  props: {
    item: Object
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/extra/mixins/flexbox.scss';
@import '../../assets/scss/bootstrap-custom-variables.scss';

/**** GLOBAL *****/
.text--brand {
  color: $brand-primary;
}

/**** TABLE BODY CELLS ****/
.product-cell {
  @include flexbox;
  @include align-items(center);
  width: 100%;

  .product-img-container {
    min-width: 60px;
    border: 1px solid #ccc;
    background-color: #fff;
    text-align: center;
    padding: 5px;
  }

  img {
    max-width: 50px;
    width: 100%;
    max-height: 60px;
  }
}
</style>
