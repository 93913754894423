<template>
  <div class="teams-list">
    <h5 class="teams-title">
      Teams
    </h5>
    <div class="teams-body">
      <div
        v-if="filterTeams.length == 0"
        class="alert alert-info">
        Sorry, no teams found <span v-if="companyFilter">
          under (company) <b>{{ companyFilter.name }} </b>
        </span>.
      </div>
      <ul
        v-else
        class="list-unstyled">
        <li
          v-for="(t, index) in filterTeams"
          :key="`team-${t.teamName}-${index}`"
          class="list-item"
          :class="{'team-active': t === teamFilter}"
          @click="$emit('select', t)">
          <div class="card">
            <div class="team-card">
              <div class="avatar">
                <avatar
                  :username="t.teamName"
                  :size="55"
                  :rounded="false"
                  color="#fff"
                  background-color="#00416a"></avatar>
              </div>
              <div
                class="info"
                style="background: inherit !important;">
                {{ t.teamName }}
              </div>
              <div class="user-count">
                <span class="total">
                  {{ t.memberCount }}
                </span>
                <span class="total-lbl">
                  Members
                </span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script type="text/javascript">
import UserAvatar from 'components/UserAvatar.vue'

export default {
  components: {
    avatar: UserAvatar
  },
  props: {
    search: {
      type: String,
      required: false,
      default: ''
    },
    teamList: {
      type: Array,
      default: () => []
    },
    teamFilter: {
      type: Object,
      default: () => {}
    },
    companyFilter: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    filterTeams () {
      const company = this.companyFilter.companyId
      const filtered = this.teamList.filter(team => {
        if (company) {
          return team.companyList.some((companyId) => { return company === companyId })
        }
        return true
      })
      return filtered
    }
  }
}
</script>
<style lang="scss">
@import "../../../assets/scss/bootstrap-custom-variables.scss";
@import "../../../assets/scss/extra/mixins/flexbox.scss";

#team-builder .teams-list {
  .teams-title {
    font-size: 1.5rem;
    font-weight: 500;
    padding: .5rem .5rem 0;
  }
  .teams-body {
    padding: .25rem 0 1rem;
    >ul.list-unstyled {
      margin-bottom: 0;
      @include flexbox;
      @include flex-wrap(wrap);
      li.list-item {
        padding: .5rem;
        @include flex(0, 1, 50%);
        @media (min-width: 75rem) {
          @include flex(0, 1, calc(100% / 4));
        }
        @media (min-width: 105rem) {
          @include flex(0, 1, calc(100% / 5));
        }
        &.team-active {
          .card {
            border-color: $blue-light;
            .team-card {
              background: $blue-lighter;
              color: $brand-primary;
              >.user-count .total-lbl {
                color: $brand-primary;
              }
            }
            &:hover {
              .team-card {
                background: darken($blue-lighter, 2.5%);
              }
            }
          }
        }
      }
    }

    .team-btn {
      display: inline-block;
    }
  }

  .team-card {
    @include flexbox;
    @include justify-content(space-between);
    background: $gray-lighter;

    >.avatar {
      @include flex(0, 1, 2.5rem);
      @include align-self(center);
      margin-right: .5rem;
    }

    >.info {
      @include flex(1, 1, 2rem);
      @include align-self(center);
      font-size: .875rem;
      text-transform: uppercase;
      padding: .25rem .5rem;
      background: transparent;
      strong {
        color: $cyan;
      }
    }

    >.user-count {
      @include flexbox;
      @include flex(0, 1, 2rem);
      @include align-self(center);
      @include flex-direction(column);
      @include justify-content(center);
      padding: .25rem .75rem;
      .total {
        font-weight: bold;
        font-size: 1rem;
        @include flex(0, 1, 1.25rem);
        @include align-self(center);
      }
      .total-lbl {
        font-size: .675rem;
        color: $gray-light;
        @include flex(0, 1, 1.25rem);
        @include align-self(center);
      }
    }
    &:hover {
      cursor: pointer;
      background: darken($gray-lighter, 5%);
    }
  }
}
</style>
