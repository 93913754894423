<template>
  <div class="copy-from-dialog">
    <button
      type="button"
      class="btn btn-block btn-gray"
      @click="showModal = true">
      <span>Copy From Product</span>
    </button>
    <modal
      ref="copyFromModal"
      :icon="modal.icon"
      :blocking="modal.blockClose"
      :title="modal.title"
      :animate-icon="modal.animate"
      @close="showModal = false">
      <div v-if="!productCopied">
        <div
          class="product-search"
          :class="{'has-danger': searchFeedback}">
          <div class="input-group">
            <div class="input-group-btn">
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-light dropdown-toggle btn-search-type"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
                  {{ selectedType.name }}
                </button>
                <div class="dropdown-menu dropdown-menu-left">
                  <h6 class="dropdown-header">
                    Search By:
                  </h6>
                  <template v-for="s in searchTypes">
                    <button
                      :key="`type-${s.name}`"
                      class="dropdown-item"
                      :class="{ 'active': s.name == selectedType.name }"
                      type="button"
                      @click="searchType(s)">
                      {{ s.name }}
                    </button>
                  </template>
                </div>
              </div>
            </div>
            <input
              v-model="searchTerm"
              v-mask="'############'"
              class="form-control"
              :placeholder="`Search By ${selectedType.name}`"
              @keyup.enter="searchProduct(true)"
              @keyup.stop="searchProduct()" />
            <img
              v-if="loading"
              src="../../../assets/images/ic_auto_load.svg"
              class="search-icon loader spin" />
            <template v-else>
              <i
                v-if="isDirty"
                class="material-icons search-icon times"
                @click="searchTerm = ''">close</i>
              <i
                v-else
                class="material-icons search-icon search">search</i>
            </template>
          </div>
          <div class="form-control-feedback">
            <small
              v-if="searchFeedback"
              v-html="searchFeedback">
            </small>
          </div>
        </div>
        <template v-if="selectedProduct">
          <div class="card selected-product">
            <div class="prod-img">
              <template v-if="selectedProduct.mainImage">
                <img
                  class="img-fluid"
                  :src="`https://rexel-cdn.com/products/${selectedProduct.catNum}.jpg?i=${selectedProduct.mainImage}&f=77`" />
              </template>
              <img
                v-else
                class="img-fluid"
                src="https://images.plattstatic.com/ProductImages/thumbnail/Nonstock.gif" />
            </div>
            <div class="prod-info">
              <h6>
                {{ selectedProduct.catNum }}
                <span
                  v-if="selectedProduct.manufacturer"
                  class="mfr">By: {{ selectedProduct.manufacturer.name }}</span>
              </h6>
              <p v-if="selectedProduct.description || selectedProduct.shortDescription">
                {{ selectedProduct.description || selectedProduct.shortDescription }}
              </p>
              <p
                v-if="selectedProduct.subCategory"
                class="cats">
                {{ selectedProduct.section }}, {{ selectedProduct.group }}, {{ selectedProduct.category }}, {{ selectedProduct.subCategory }}
              </p>
            </div>
            <div class="prod-remove">
              <a @click="selectedProduct = null">
                <i class="material-icons">
                  close
                </i>
              </a>
            </div>
          </div>
        </template>
        <h6>Fields:</h6>
        <div>
          <ul class="list-unstyled field-list copy-options">
            <li>
              <label class="custom-control custom-checkbox">
                <input
                  v-model="selectAllFields"
                  type="checkbox"
                  class="custom-control-input"
                  :value="true" />
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">
                  Select All
                </span>
              </label>
            </li>
            <li
              v-for="field in fields"
              :key="`copy-field-${field.value}`">
              <label class="custom-control custom-checkbox">
                <input
                  v-model="selectedFields"
                  type="checkbox"
                  class="custom-control-input"
                  :value="field.value" />
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">
                  {{ field.name }}
                </span>
              </label>
            </li>
          </ul>
        </div>
      </div>
      <h5
        v-else
        class="modal-message">
        {{ modal.message }}
      </h5>
      <div
        slot="button"
        class="copy-product-actions">
        <template v-if="!productCopied">
          <button
            type="button"
            class="btn btn-link"
            @click="showModal = false">
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            :class="{'disabled': !selectedProduct}"
            :disabled="!selectedProduct || selectedFields.length === 0"
            @click="copyProduct()">
            Copy Product
          </button>
        </template>
        <template v-else>
          <div></div>
          <button
            type="button"
            class="btn btn-primary"
            @click="showModal = false">
            Done
          </button>
        </template>
      </div>
    </modal>
  </div>
</template>
<script type="text/javascript">
import qs from 'qs'
import axios from 'axios'
import lodash from 'lodash'
import ModalComponent from 'components/modals/sweet.vue'
export default {
  name: 'CopyFromDialog',
  components: {
    modal: ModalComponent
  },
  props: {
    product: Object,
    company: Array,
    dimensions: Array,
    cats: Object,
    lang: Number,
    images: Array,
    addedImages: Array,
    removedImages: Array,
    attributes: Array,
    addedAttributes: Array,
    removedAttributes: Array,
    documents: Array,
    addedDocuments: Array,
    removedDocuments: Array,
    associated: Array, // Already associated
    crossSellProductEditList: Array
  },
  data () {
    return {
      loading: false,
      modal: {
        icon: null,
        message: '',
        title: 'Copy From Product',
        blockClose: true,
        animate: false
      },
      showModal: false,
      axiosCancel: null,
      fields: [
        { name: 'Categories', value: 'categories' },
        { name: 'Short Description', value: 'shortDescription' },
        { name: 'Long Description', value: 'description' },
        { name: 'Image', value: 'images' },
        { name: 'Document', value: 'documents' },
        { name: 'Attribute', value: 'attributes' },
        { name: 'Min Order Qty', value: 'minOrderQty' },
        { name: 'Dimensions / Weight', value: 'dimensions' },
        { name: 'Marketing Description', value: 'marketingDescription' },
        { name: 'Features & Benefits', value: 'features' },
        { name: 'Keywords', value: 'keywords' },
        { name: 'Associated', value: 'associated' }
      ],
      searchTerm: '',
      searchTypes: [],
      selectedType: { name: 'NAPM ID', value: null },
      searchFeedback: null,
      selectedProduct: null,
      selectedFields: [],
      productCopied: false,
      selectAllFields: false
    }
  },
  computed: {
    isDirty () {
      return this.searchTerm.length
    }
  },
  watch: {
    showModal (value) {
      if (value) {
        this.setupSearchTypes()
        this.$refs.copyFromModal.open()
      } else {
        this.resetModal()
        this.$refs.copyFromModal.close()
      }
    },
    searchTerm (value, oldValue) {
      if (value.length < 6 && value !== oldValue) {
        this.searchFeedback = null
      }
    },
    selectAllFields (value) {
      if (value) {
        this.selectedFields = this.fields.map(field => {
          return field.value
        })
      } else {
        this.selectedFields = []
      }
    }
  },
  methods: {
    setupSearchTypes () {
      const banners = this.$store.getters.banners
      const searchTypes = banners.map(c => {
        return { name: `${c.name} ID`, value: c.id }
      })
      searchTypes.unshift(this.selectedType)
      this.searchTypes = searchTypes
    },
    searchType (type) {
      this.selectedType = type
      this.searchProduct(true)
    },
    searchProduct (force) {
      // force should only be true when a user hits enter or changes id type, allowing users to search for product ids with less than 3 digits --DZD
      if (this.searchTerm.length < 4 && !force) {
        return
      }

      if (this.searchTerm.length < 1) {
        return
      }

      this.searchFeedback = null
      const company = this.selectedType.value
      const params = {
        id: this.searchTerm,
        bannerIds: company ? [company] : []
      }

      if (this.axiosCancel != null) {
        this.axiosCancel.cancel('Search changed, cancel and researching...')
      }

      this.loading = true
      this.axiosCancel = axios.CancelToken.source()
      this.$http.get('/api/products', {
        params,
        paramsSerializer (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        }
      }, { cancelToken: this.axiosCancel.token })
        .then(res => {
          this.loading = false
          this.axiosCancel = null

          if (res.data.resultCount) {
            this.selectedProduct = res.data.products[0]
          } else {
            this.searchFeedback = `No products found when searching for ${this.selectedType.name} <b>#${params.id}</b>.`
          }
        })
    },
    copyProduct () {
      axios.get(`/api/products/${this.selectedProduct.napmId}?languageId=${this.lang}`)
        .then(res => {
          const prod = res.data
          this.selectedFields.forEach(field => {
            if (field === 'categories') {
              this.copyCats(prod.singleValues)
            } else if (field === 'images') {
              this.copyImgs(prod.media)
            } else if (field === 'documents') {
              this.copyDocs(prod.documents)
            } else if (field === 'attributes') {
              this.copyAttrs(prod, prod.attributes)
            } else if (field === 'associated') {
              this.copyAssociated(prod, prod.associatedProducts)
            } else if (field === 'minOrderQty') {
              const plattIndex = prod.banners.findIndex(i => i.bannerId === 1)
              if (plattIndex > -1) {
                const pi = this.company.findIndex(i => i.bannerId === 1)
                if (pi > -1) this.company[pi].minOrdQty = prod.banners[plattIndex].minOrdQty
              }

              const rexelIndex = prod.banners.findIndex(i => i.bannerId === 2)
              if (rexelIndex > -1) {
                const ri = this.company.findIndex(i => i.bannerId === 2)
                if (ri > -1) this.company[ri].minOrdQty = prod.banners[rexelIndex].minOrdQty
              }

              const gexproIndex = prod.banners.findIndex(i => i.bannerId === 3)
              if (gexproIndex > -1) {
                const gi = this.company.findIndex(i => i.bannerId === 3)
                if (gi > -1) this.company[gi].minOrdQty = prod.banners[gexproIndex].minOrdQty
              }
            } else if (field === 'weight') {
              this.dimensions[0] = prod.dimensions[0]
            } else if (field === 'dimensions') {
              this.$emit('update:dimensions', prod.dimensions)
            } else if (field === 'features') {
              this.copyFeatures(prod.features)
            } else if (field === 'keywords') {
              this.product.keywords = prod.singleValues.keywords
              this.product.keywordsSearchOnly = prod.singleValues.keywordsSearchOnly
              this.$emit('keywordsChange')
            } else {
              this.product[field] = prod.singleValues[field]
            }
          }, this.showMessage())
        })
    },
    copyAttrs (prod, newAttrs) {
      const addedAttributes = []
      newAttrs.forEach(type => {
        type.attributes.forEach(attribute => {
          addedAttributes.push(attribute.attributeValueId)
        })
      })
      const removedAttributes = []
      this.attributes.forEach(attribute => {
        attribute.attributes.forEach(attr => {
          removedAttributes.push(attr.attributeValueId)
        })
      })
      this.$emit('update:addedAttributes', addedAttributes)
      this.$emit('update:removedAttributes', removedAttributes)
      this.$emit('update:attributes', newAttrs)
    },
    copyAssociated (prod, newAssociated) {
      const clonedAssociated = [...this.associated]
      const clonedCrossSellProductEditList = [...this.crossSellProductEditList]
      let nextDisplayOrder = Math.max(...this.associated.map(x => x.displayOrder))
      if (!Number.isFinite(nextDisplayOrder)) {
        nextDisplayOrder = 0
      }
      nextDisplayOrder++
      newAssociated.forEach(associatedItem => {
        if (clonedCrossSellProductEditList.findIndex(x => x.napmId === associatedItem.napmId && x.requestedAction === 'Add') === -1) {
          clonedCrossSellProductEditList.push(
            {
              crossFilled: associatedItem.crossFilled,
              crossSellTypeId: 1,
              displayOrder: nextDisplayOrder,
              napmId: associatedItem.napmId,
              parentId: this.product.napmId,
              plattProductId: associatedItem.plattProductId,
              requestedAction: 'Add'
            })
          clonedAssociated.push(
            {
              catNum: associatedItem.catNum,
              crossFilled: associatedItem.crossFilled,
              crossSellTypeId: 1,
              displayOrder: nextDisplayOrder,
              fileName: associatedItem.fileName,
              mainImage: associatedItem.mainImage,
              mfrName: associatedItem.mfrName,
              napmId: associatedItem.napmId,
              plattProductId: associatedItem.plattProductId
            }
          )
          nextDisplayOrder++
        }
      })
      this.$emit('update:crossSellProductEditList', clonedCrossSellProductEditList)
      this.$emit('update:associated', clonedAssociated)
    },
    copyDocs (docs) {
      const removedDocuments = this.documents.map(doc => {
        return doc.documentId
      })
      const addedDocuments = docs.map(doc => {
        return doc.documentId
      })
      this.$emit('update:addedDocuments', addedDocuments)
      this.$emit('update:removedDocuments', removedDocuments)
      this.$emit('update:documents', docs)
    },
    copyImgs (imgs) {
      const removedImages = this.images.map(img => {
        return img.mediaId
      })
      const addedImages = imgs.map(img => {
        return img.mediaId
      })
      this.$emit('update:addedImages', addedImages)
      this.$emit('update:removedImages', removedImages)
      this.$emit('update:images', imgs)
    },
    copyCats (prod) {
      this.cats.sectionId = prod.sectionId
      this.getCategories(prod.sectionId).then(res => {
        this.cats.groupList = res.data
        this.cats.groupId = prod.groupId
      })
      this.getCategories(prod.groupId).then(res => {
        this.cats.categoryList = res.data
        this.cats.categoryId = prod.categoryId
      })
      this.getCategories(prod.categoryId).then(res => {
        this.cats.subCategoryList = res.data
        this.cats.subCategoryId = prod.subCategoryId
      })
    },
    copyFeatures (feat) {
      const orderedFeatures = lodash.orderBy(feat, 'featureNumber')
      for (let i = 0; i < 10 - feat.length; i++) {
        orderedFeatures.push({ featureDescription: null })
      }
      this.product.features = orderedFeatures
      this.$emit('featurechange')
    },
    getCategories (id) {
      return axios.get(`/api/categories/${id}/children?languageId=${this.lang}`)
    },
    showMessage () {
      this.productCopied = true
      this.modal = {
        icon: 'success',
        message: 'Copied your selected fields',
        animate: true
      }
    },
    resetModal () {
      setTimeout(() => {
        this.searchTerm = ''
        this.axiosCancel = null
        this.productCopied = false
        this.selectedProduct = null
        this.searchFeedback = false
        this.selectedFields = []
        this.selectAllFields = false
        this.selectedType = { name: 'NAPM ID', value: null }
        this.modal = {
          icon: null,
          message: '',
          title: 'Copy From Product',
          blockClose: true,
          animate: false
        }
      }, 2000)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/extra/mixins/flexbox.scss";
@import "../../../assets/scss/bootstrap-custom-variables.scss";

.copy-options {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.copy-from-dialog {
  margin-bottom: 1rem;

  > .btn {
    span {
      padding-top: 0.25rem;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 0.875rem;
    }
  }

  .btn-search-type {
    min-width: 8.85rem;
  }

  .modal-message {
    margin: 1.5rem 0 0;
    font-weight: 500;
    text-align: center;
    color: #464a4c;
  }

  .has-danger {
    .btn-secondary {
      border-color: $brand-danger;
      color: $brand-danger;
    }
  }
  .product-search {
    margin-bottom: 0.5rem;
    .form-control {
      padding-right: 1.25rem;
    }

    .search-icon {
      position: absolute;
      right: 0.75rem;
      top: 50%;

      z-index: 99;
      &.material-icons {
        font-size: 1.25rem;
        margin-top: -0.625rem;
        &.search {
          color: #464a4c;
        }
        &.times {
          cursor: pointer;
          color: $brand-danger;
        }
      }
      &.loader {
        margin-top: -0.5rem;
      }
    }
    .form-control-feedback {
      height: 1rem;
      z-index: 100;
      display: block;
      padding-left: 9rem;
      color: $brand-danger;
      -webkit-transform: translate3d(0, 0, 0);
    }
  }

  .selected-product {
    @include flexbox;
    @include flex-direction(row);
    margin-bottom: 1.5rem;
    padding: 0.25rem;
    .prod-img {
      padding: 0.25rem;
      @include flex(0, 1, 4rem);
      @include align-self(center);
    }
    .prod-info {
      @include flex(1, 1, 80%);
      @include align-self(center);
      h6 {
        margin-bottom: 0;
        font-size: 0.875rem;
        .mfr {
          font-style: italic;
          font-size: 0.75rem;
          font-weight: 400;
          color: $gray;
        }
      }
      > p {
        margin-bottom: 0;
        display: block; /* Fallback for non-webkit */
        display: -webkit-box;
        max-width: 100%;
        height: 0.8125rem * 1.5; /* Fallback for non-webkit */
        font-size: 0.8125rem;
        line-height: 1.5;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        &.cats {
          color: $brand-primary;
          font-weight: 600;
        }
      }
    }
    .prod-remove {
      @include flex(0, 1, 2);
      @include align-self(center);
      text-align: center;
      a {
        display: inline-block;
        color: $brand-danger;
        font-size: 1.25rem;
        padding: 0.125rem 0.5rem 0.5rem;
        cursor: pointer;
        &:hover {
          color: darken($brand-danger, 15%);
        }
      }
    }
  }
  .field-list {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0;
    li {
      > label {
        font-weight: normal;
        .custom-control-description {
          font-size: 0.875rem;
        }
        .custom-control-indicator {
          top: 0.125rem;
        }
      }
    }
  }
  // footer
  .copy-product-actions {
    @include flexbox;
    @include justify-content(space-between);
    .btn {
      min-width: 8.25rem;
    }
  }
}

.loader.spin {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
