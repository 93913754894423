import MediaFileTypesMixin from '@/views/media/types/MediaFileTypes.mixin'
import ImageUploadMixin from '@/views/media/types/image/ImageUpload.mixin'
import DocumentUploadMixin from '@/views/media/types/document/DocumentUpload.mixin'
import VideoUploadMixin from '@/views/media/types/video/VideoUpload.mixin'
import UriUploadMixin from '@/views/media/types/uri/UriUpload.mixin'

export default {
  mixins: [MediaFileTypesMixin, DocumentUploadMixin, VideoUploadMixin, ImageUploadMixin, UriUploadMixin],
  data () {
    return {
      uploadType: '',
      validation: {
        media: false,
        documentType: false
      }
    }
  },
  methods: {
    async uploadUrl () {
      await this.createUriForUpload()
        .then(res => { // res is the uri response
          if (!this.uriToUpload.error) {
            this.uploadType = this.uriToUpload.type

            if (this.uriToUpload.type === this.mediaTypes.image) {
              this.createImageForUpload(res, this.uriToUpload.fileName)
            } else {
              this.documentToUpload.uri = this.uploadUri
              this.videoToUpload.uri = this.uploadUri
            }
          }
        })
    },
    uploadMedia (event) {
      this.setUploadTypeByFileType(event.target.files[0].type)
      if (this.uploadType === this.mediaTypes.image) {
        this.createImageForUpload(event.target.files[0], event.target.files[0].name)
      } else if (this.uploadType === this.mediaTypes.document) {
        this.createDocumentForUpload(event.target.files[0], event.target.files[0].name)
      } else {
        this.createVideoForUpload(event.target.files[0], event.target.files[0].name)
      }
    },
    isValidMedia () {
      let valid = true
      if (this.uploadType === this.mediaTypes.document && !this.documentToUpload.type) {
        this.validation.documentType = true
        valid = false
      } else {
        this.validation.documentType = false
      }
      return valid
    },
    invalidMediaDocument () {
      return (this.uploadType === this.mediaTypes.document && this.validation.documentType && !this.documentToUpload.type)
    },
    saveMedia () {
      if (this.uploadType === this.mediaTypes.image) {
        return this.saveImage()
      } else if (this.uploadType === this.mediaTypes.document) {
        return this.saveDocument()
      } else if (this.uploadType === this.mediaTypes.video) {
        return this.saveVideo()
      } else {
        console.error('Upload type not supported')
      }
    },
    replaceMedia () {
      if (this.uploadType === this.mediaTypes.image) {
        return this.replaceImage()
      } else if (this.uploadType === this.mediaTypes.document) {
        return this.replaceDocument()
      } else if (this.uploadType === this.mediaTypes.video) {
        return this.replaceVideo()
      } else {
        console.error('Upload type not supported')
      }
    },
    setUploadTypeByFileName (fileName) {
      this.uploadType = this.getMediaTypeByFileName(fileName)
    },
    setUploadTypeByFileType (fileType) {
      this.uploadType = this.getMediaTypeByFileType(fileType)
    },
    resetUploadDefaults () {
      this.uploadType = ''
      this.resetDocumentUploadDefaults()
      this.resetVideoDefaults()
      this.resetImageUploadDefaults()
      this.resetUriDefaults()
      this.validation = {
        media: false,
        documentType: false
      }
    }
  }
}
