<template>
  <modal
    v-if="showModal"
    class="banner-spec-photo-modal"
    :heading="title"
    :scrollable="false"
    @close="resetModal()">
    <div
      slot="body"
      class="bulk-upload-modal">
      <template v-if="!loading">
        <section
          id="company-specific"
          class="card">
          <div class="card-header">
            <ul
              class="nav nav-tabs card-header-tabs"
              role="tablist">
              <li
                v-for="(detail, i) in companyDetails"
                :key="'tab-links-' + detail.companyId + detail.name"
                class="nav-item">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  :href="`#${sanitizeString(detail.name)}`"
                  role="tab"
                  :class="{'active': i === 0}">{{ detail.name }}</a>
              </li>
            </ul>
          </div>
          <div class="section-body">
            <div
              v-if="companyDetails.length"
              class="tab-content">
              <template v-for="(detail, i) in companyDetails">
                <div
                  :id="sanitizeString(detail.name)"
                  :key="'tab-panes-' + detail.companyId + detail.name"
                  class="tab-pane"
                  :class="{'active': i === 0}"
                  role="tabpanel">
                  <div class="details-heading">
                    <div class="title">
                      <details-media :details="detail"></details-media>
                      <div class="title--text">
                        <h4>{{ detail.language[1].name }} {{ detail.name }}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </section>
      </template>
    </div>
    <template
      slot="footer">
      <button
        class="btn btn-grey btn-rounded"
        @click="resetModal()">
        Cancel
      </button>
      <button
        class="btn btn-primary btn-rounded"
        @click="save()">
        Save Changes
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from 'components/modals/basic'
import DetailsMedia from './detailsMedia'

export default {
  components: {
    modal: Modal,
    'details-media': DetailsMedia
  },
  props: {
    showModal: Boolean,
    details: Object,
    overrideDetails: Array
  },
  data () {
    return {
      title: 'Banner Specific Section Photos',
      loading: false,
      icon: null,
      company: {},
      companyDetails: [],
      originalComapanyDetails: null
    }
  },
  watch: {
    showModal (value) {
      if (value) {
        this.buildCompanySpecificDetails(this.details)
      } else {
        this.resetModal()
      }
    },
    'companyDetails.length' (value) {
      if (value) {
        this.originalComapanyDetails = JSON.stringify(this.companyDetails)
      }
    }
  },
  methods: {
    resetModal () {
      this.$emit('update:showModal', false)
      this.companyDetails = []
      this.originalComapanyDetails = null
    },
    buildCompanySpecificDetails (details) {
      const companyList = this.$store.getters.companyList
      this.companyDetails = [
        { ...details, companyId: 0, name: 'NAPM' },
        ...companyList.map(company => {
          const override = this.overrideDetails.filter(item => item.bannerId === company.companyId)
          const result = {
            ...this.details,
            ...company
          }
          if (override.length > 0 && override[0].bannerId === company.companyId) {
            result.mainImage = override[0].mainImage
            result.mediaId = override[0].mediaId
          }
          return result
        })
      ]
    },
    sanitizeString (str) {
      str = str.replace(/ /g, '')
      return str.trim()
    },
    save () {
      const original = JSON.parse(this.originalComapanyDetails)
      const langId = this.$store.getters.languageId
      const mediaIdForNapmPhoto = this.companyDetails.filter(x => x.companyId === 0)[0].mediaId
      const catMediaCompanies = original.map(x => {
        if (x.mediaId !== this.companyDetails.filter(cd => cd.companyId === x.companyId)[0].mediaId) {
          return {
            bannerId: x.companyId,
            categoryId: x.language[langId].id,
            mediaId: this.companyDetails.filter(cd => cd.companyId === x.companyId)[0].mediaId
          }
        }
        return null
      }).filter(x => x && x.companyId !== 0)
      if (catMediaCompanies.length) {
        const params = {
          languageId: langId,
          banners: catMediaCompanies
        }
        this.$http.put('/api/categories/banner-media', params)
          .then(res => {
            this.$emit('save', mediaIdForNapmPhoto)
            this.resetModal()
          }).catch(err => {
            console.log(err)
            this.$emit('error')
          })
      } else {
        this.$emit('save', mediaIdForNapmPhoto)
        this.resetModal()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/extra/mixins/flexbox";
@import "../../../assets/scss/bootstrap-custom-variables";
.details-heading {
  margin-bottom: 0.5rem;
  padding: 1rem;
  @include flexbox;
  @include justify-content(space-between);
  @include align-items(center);
  .title {
    @include flex(1, 1, 75%);
    @include flexbox;
    @include align-items(center);
    .title--text {
      margin: 0 1rem;
      h4 {
        margin: 0;
      }
      small {
        display: block;
        font-weight: 500;
      }
    }
  }
  .dropdown {
    margin-bottom: 1.75rem;
    &.show {
      .dropdown-toggle {
        color: $gray-light;
      }
    }
    .dropdown-toggle {
      cursor: pointer;
      font-size: 1.5rem;
      &:after {
        display: none;
      }
    }
    .dropdown-menu {
      padding-top: 0.5rem;
      margin: 10px -10px 0 0;
      &:before,
      &:after {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
      }
      &:before {
        content: "";
        border-width: 11px;
        top: -11px;
        margin-right: -11px;
        border-bottom-color: rgba(0, 0, 0, 0.25);
        right: 20px;
        border-top-width: 0;
      }
      &:after {
        content: "";
        border-width: 10px;
        right: 20px;
        border-top-width: 0;
        top: -10px;
        margin-right: -10px;
        border-bottom-color: #fff;
      }
      .dropdown-item {
        &.disabled:hover {
          cursor: not-allowed;
          color: $gray-200;
        }
      }
    }
  }
}
</style>
