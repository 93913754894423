import axios from 'axios'

// Using axios will allow Bearer token to be added to API request
export default async function downloadFileFromApi (url, fileNameOverride = null) {
  await axios({
    url,
    method: 'GET',
    responseType: 'blob'
  })
    .then((res) => {
      const bytes = res.data
      const file = new Blob([bytes], { type: res.headers['content-type'] })
      const fileURL = window.URL.createObjectURL(file)
      const downloadLink = document.createElement('a')
      downloadLink.href = fileURL
      let filenameFromHeader
      if (res.headers['content-disposition']) {
        const contentDisposition = res.headers['content-disposition']
        filenameFromHeader = contentDisposition.substring(contentDisposition.indexOf('filename=') + 9, contentDisposition.lastIndexOf(';'))
      }
      if (fileNameOverride) {
        downloadLink.download = fileNameOverride
      } else {
        downloadLink.download = filenameFromHeader
      }
      document.body.appendChild(downloadLink)
      downloadLink.click()
      downloadLink.remove()
    })
    .catch((err) => {
      console.error(err)
    })
}
