export default {
  methods: {
    getAllManufacturers () {
      if (this.$store.state.manufacturers.length > 0) {
        return new Promise((resolve) => {
          const response = { data: this.$store.state.manufacturers }
          resolve(response)
        })
      } else {
        return this.$http.get('/api/manufacturers')
          .then((res) => {
            this.$store.commit('setManufacturers', res.data)
            return res
          })
      }
    }
  }
}
