import { UserManager } from 'oidc-client'
import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
// import palert from './utils/pAlerts'

import {
  REDIRECT_URI,
  SILENT_REDIRECT_URI,
  OIDC_DEFAULT_SETTINGS,
  OIDC_DEFAULT_SESSION_SETTINGS
} from './auth-settings'

/** Global singleton */
let _manager = null
let _settings = null
let _callbackSettings = null

class Auth {
  constructor (settings = {}) {
    _settings = Object.assign(
      {},
      OIDC_DEFAULT_SETTINGS,
      OIDC_DEFAULT_SESSION_SETTINGS,
      settings
    )
    _callbackSettings = Object.assign({}, OIDC_DEFAULT_SETTINGS, settings)
  }

  get manager () {
    return _manager
  }

  get settings () {
    return _settings
  }

  get authStateJSON () {
    const { pathname, search, hash } = window.location
    return JSON.stringify({ path: pathname + search + hash })
  }

  get isSilentRenew () {
    return RegExp(SILENT_REDIRECT_URI).test(window.location.href)
  }

  get isAuthCallback () {
    return RegExp(REDIRECT_URI).test(window.location.href)
  }

  /**
   * Initializes the system and takes a callback to run after authentication is successful
   * @param {Func} callback
   */
  async init (callback = () => {}) {
    if (_manager) {
      const user = await _manager.getUser()
      callback(user)
    }
    if (this.isSilentRenew) {
      // silent renew, we're loading in an iframe
      const tempManager = new UserManager(_callbackSettings)
      tempManager.signinSilentCallback()
    } else if (this.isAuthCallback) {
      // auth callback, need to process tokens
      const tempManager = new UserManager(_callbackSettings)
      let state = { path: '/' }
      try {
        const res = await tempManager.signinRedirectCallback()
        // replace path history
        state = JSON.parse(res.state)
        state.path = state.path || '/'
        window.location.assign(state.path)
      } catch (err) {
        console.error('isAuthCallback Error processing signin redirect', err)
        window.sessionStorage.clear()
        window.localStorage.clear()
        tempManager.signinRedirect({ state: JSON.stringify(state) })
      }
    } else {
      // not handling a sign-in situation
      _manager = new UserManager(_settings)
      try {
        const user = await _manager.getUser()
        if (!user || user.expired) {
          _manager.signinRedirect({ state: this.authStateJSON })
        } else {
          callback(user)
        }
      } catch (err) {
        console.error('Auth Failure\n', err)
        _manager.signinRedirect({ state: this.authStateJSON })
      }
    }
  }
}

export const assignAnalyticsPermissionsLanguage = (user) => {
  store.dispatch('setUser', user)

  Vue.$ga.set('dimension1', user.email)
  Vue.$ga.set('dimension2', user.name)
  // Set user's default company for Google Analytics
  let defaultCompany = 'unknown'
  if (!isNaN(user.defaultCompany) && store.getters.companyList[store.getters.user.defaultCompany] != null) {
    defaultCompany = store.getters.companyList[user.defaultCompany].name
  }
  Vue.$ga.set('dimension3', defaultCompany)

  store.dispatch('setPermissions', user.companyPermissions)
  if (user && user.languageId) {
    const language = store.getters.languageOptions.find(lang => {
      return lang.id === user.languageId
    })
    store.dispatch('setLanguage', language)
  }
}

const initVueUser = async (data) => {
  try {
    store.dispatch('setAccessToken', data.access_token)
    axios.defaults.headers.common.Authorization = `Bearer ${data.access_token}`

    // If there are companyPermissions then user is already logged in as a Napm user
    if (store.getters.user.companyPermissions) {
      return store.getters.user
    }

    store.dispatch('setIdentity', data.profile)
    let napmUser = null
    await axios.get('/account/isLoggedIn')
      .then((res) => {
        assignAnalyticsPermissionsLanguage(res.data)
        napmUser = res.data
      })
      .catch((err) => {
        console.error(err.response.data)
      })
    return napmUser
  } catch (err) {
    console.error(err)
    return null
  }
}

// Exposed for sign out from components (header, AuthCallback)
export let manager
let auth

export const initAuth = () => {
  return new Promise((resolve, reject) => {
    try {
      if (!auth) {
        auth = new Auth({
          authority: store.getters.appConfig.identityAuthorityUrl
        })
      }

      auth.init(async (u) => {
        // let user = null
        await initVueUser(u)
          .then((napmUser) => {
            // user = napmUser
          })
        auth.manager.events.addUserLoaded(async (u) => {
          await initVueUser(u)
        })
        auth.manager.events.addSilentRenewError(function (error) {
          console.error('error while renewing the access token', error)
        })
        auth.manager.events.addUserSignedOut(data => {
          auth.manager.stopSilentRenew()
          auth.manager.removeUser()
          auth.manager.clearStaleState()
          auth.manager.signinRedirect({ state: JSON.stringify({ path: window.location.pathname + window.location.hash }) })
        })
        manager = auth.manager

        // See what InitVueUser return - if null then it's not a Napm user
        // if (!user) {
        //   palert({
        //     title: 'Error',
        //     type: 'error',
        //     text: 'Unable to access NAPM due to authentication issue. Verify that you are not impersonating another user.'
        //   })
        //   return reject(new Error('No User found'))
        // }
        return resolve(manager)
      })
    } catch (err) {
      console.error(err)
    }
  })
}
