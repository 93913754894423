export default {
  data () {
    return {
      actions: {
        approve: 'approve',
        approveSearch: 'approveForSearch',
        deny: 'deny',
        change: 'change'
      },
      actionsForProductEdit: [
        { itemValue: 'approve', itemText: 'Visible' },
        { itemValue: 'approveForSearch', itemText: 'For Search Only' },
        { itemValue: 'deny', itemText: 'Delete' }
      ]
    }
  },
  methods: {
    getSubmittedOnNames (slangTerms) {
      const companies = []
      for (let ii = 0; ii < slangTerms.length; ii++) {
        const name = slangTerms[ii].submittedToCompanyName
        if (companies.indexOf(name) === -1) {
          companies.push(name)
        }
      }
      return companies.length > 0 ? companies.join(', ') : companies[0]
    },
    getSlangTermStatus (slangTerm) {
      if (slangTerm.approved && slangTerm.added) {
        return this.actions.approve
      } else if (slangTerm.approved) {
        return this.actions.approveSearch
      } else {
        return this.actions.deny
      }
    }
  }
}
