<template>
  <modal
    ref="videoModal"
    :video="true"
    :no-padding="true"
    :hide-close-button="true"
    @close="closeVideo()">
    <div
      id="video"
      class="embed-responsive embed-responsive-16by9">
      <iframe
        class="embed-responsive-item"
        :src="src.id ? `https://player.vimeo.com/video/${src.id}?autoplay=1&width=640` : ''"
        allowscriptaccess="always"></iframe>
    </div>
  </modal>
</template>
<script>
import ModalComponent from 'components/modals/sweet'

export default {
  name: 'PlayVideo',
  components: {
    modal: ModalComponent
  },
  props: {
    src: Object,
    open: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    open (value) {
      if (value) {
        this.$refs.videoModal.open()
      }
    }
  },
  methods: {
    closeVideo () {
      this.$emit('update:src', {})
      this.$emit('update:open', false)
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
