<template>
  <modal
    ref="cropModal"
    :title="modal.title"
    :icon="modal.icon"
    :animate-icon="modal.animate"
    :blocking="modal.message ? false : true"
    :large="true"
    :no-padding="true"
    @close="reset()">
    <loading :loading="loading"></loading>
    <div
      class="re-crop-container"
      :class="{'uploading-media': loading}">
      <div
        v-if="modal.message"
        class="modal-message">
        {{ modal.message }}
      </div>
      <template v-else>
        <cropper
          ref="img"
          :show-zoomer="false"
          :zoom-level="imgZoom"
          :boundary="{height: 450, width: 768}"
          :viewport="{ width: 400, height: 400, type: 'square' }"
          :show-buttons="true"
          :enforce-boundary="false"
          class="no-slider"
          @update="imageUpdate($event)"
          @rotate="rotated = $event"></cropper>
      </template>
    </div>
    <div
      slot="button"
      class="re-crop-actions">
      <button
        type="button"
        class="btn btn-link"
        @click="reset()">
        Close
      </button>
      <button
        v-if="modal.message"
        type="button"
        class="btn btn-primary"
        @click="reset()">
        Done
      </button>
      <button
        v-else
        type="button"
        class="btn btn-primary"
        :disabled="loading"
        @click="cropImage()">
        {{ loading ? 'Cropping..' : 'Crop Image' }}
      </button>
    </div>
  </modal>
</template>
<script>
import axios from 'axios'
import Cropper from 'components/crop'
import Loading from '../upload/uploading.vue'
import Modal from 'components/modals/sweet.vue'
export default {
  name: 'Recrop',
  components: {
    loading: Loading,
    cropper: Cropper,
    modal: Modal
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    path: {
      type: String,
      default: ''
    },
    folder: String,
    mediaId: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rotated: 0,
      imgZoom: 0,
      imgPoints: {},
      modal: {
        icon: null,
        title: 'Crop Image',
        animate: false,
        message: null
      },
      loading: false
    }
  },
  computed: {},
  watch: {
    open (value, oldValue) {
      if (value) {
        this.setupImage()
        this.$refs.cropModal.open()
      } else {
        this.$refs.cropModal.close()
      }
    }
  },
  methods: {
    setupImage () {
      this.$refs.img.bind({ url: this.path })
    },
    imageUpdate (event) {
      this.imgZoom = event.zoom
      this.imgPoints = event.points
    },
    cropImage () {
      const imgPoints = this.imgPoints.map(i => parseInt(i))
      const data = {
        folderName: this.folder,
        x1: imgPoints[0],
        y1: imgPoints[1],
        x2: imgPoints[2],
        y2: imgPoints[3],
        rotate: this.rotated ? this.rotated * 90 : 0,
        uri: this.path,
        fileName: this.name
      }
      this.loading = true
      axios.patch(`/api/media/images/${this.mediaId}/crop`, data).then(res => {
        this.loading = false
        this.modal.message = 'Success!'
        this.modal.icon = 'success'
        this.modal.animate = true
        this.$emit('replace', res.data)
      }).catch(err => {
        console.warn(err)
        this.loading = false
        this.modal.message = 'Error Cropping Image!'
        this.modal.icon = 'error'
        setTimeout(() => {
          this.$refs.cropModal._animateIcon()
        }, 50)
      })
    },
    reset () {
      this.$emit('update:open', false)
      setTimeout(() => {
        this.modal = {
          icon: null,
          title: 'Crop Image',
          animate: false,
          message: null
        }
      }, 1000)
    }
  }
}
</script>
<style lang="scss">
@import '../../../assets/scss/extra/mixins/flexbox.scss';
@import '../../../assets/scss/bootstrap-custom-variables.scss';
.re-crop-container {
  .modal-message {
    margin-bottom: 1.5rem;
    font-size: 1.15rem;
    font-weight: bold;
    text-align: center;
  }

  .no-slider {
    .croppie-container .cr-slider-wrap {
      display: none;
    }
  }
}
.re-crop-actions {
  @include flexbox;
  @include justify-content(space-between);
  .btn-primary {
    min-width: 7rem;
  }
}
</style>
