<template>
  <div>
    <modal
      v-show="editing"
      ref="editProductQnAModal"
      title="Detail"
      @close="resetModal()">
      <div>
        <section class="image-header">
          <div class="image-box">
            <img :src="imagePreview" />
          </div>
          <div class="title">
            <h4>
              Name: {{ productQnADetails.catNum }}<br /> PIM#: {{ productQnADetails.napmId }}<br /> UPC: {{ productQnADetails.upc }}<br />
            </h4>
          </div>
        </section>
        <hr />
        <section class="productQnA-info">
          <div class="form-group">
            <label class="form-control-label">
              Question
            </label>
            <textarea
              v-model="productQnADetails.question"
              type="text"
              class="form-control"
              rows="4"
              :disabled="modalState !== 'edit'"></textarea>
            <button
              v-if="modalState === 'detail' || modalState === 'edit'"
              type="button"
              class="btn"
              @click="editQuestion()">
              Edit
            </button>
          </div>
          <div
            v-if="!productQnADetails.denyReason && (modalState === 'answer' || productQnADetails.answer)"
            class="form-group">
            <label class="form-control-label">
              Answer
            </label>
            <textarea
              v-model="productQnADetails.answer"
              type="text"
              class="form-control"
              rows="4"
              :disabled="modalState !== 'answer'"></textarea>
            <button
              v-if="modalState === 'detail' || modalState === 'answer'"
              type="button"
              class="btn"
              @click="answerQuestion()">
              Edit
            </button>
          </div>
          <div
            v-if="modalState === 'deny' || productQnADetails.denyReason"
            class="form-group">
            <label class="form-control-label">
              Deny Reason
            </label>
            <textarea
              v-model="productQnADetails.denyReason"
              type="text"
              class="form-control"
              rows="4"
              :disabled="modalState !== 'deny'"></textarea>
            <button
              v-if="modalState === 'detail' || modalState === 'answer'"
              type="button"
              class="btn"
              @click="answerQuestion()">
              Edit
            </button>
          </div>
        </section>
      </div>
      <div
        slot="button"
        class="action-btns">
        <button
          type="button"
          class="btn btn-link"
          @click="resetModal()">
          Cancel
        </button>
        <button
          v-if="modalState === 'edit'"
          type="button"
          class="btn btn-primary mr-2"
          @click="editQuestion()">
          Save Changes
        </button>
        <button
          v-if="modalState === 'detail' || modalState === 'deny'"
          type="button"
          class="btn btn-danger mr-2"
          @click="denyQuestion()">
          Deny
        </button>
        <button
          v-if="modalState === 'detail' || modalState === 'answer'"
          type="button"
          class="btn btn-primary"
          @click="answerQuestion()">
          Answer
        </button>
      </div>
    </modal>
  </div>
</template>
<script type="text/javascript">
import axios from 'axios'
import ModalComponent from 'components/modals/sweet.vue'
// import ToggleComponent from 'components/inputs/toggleSwitch.vue'

export default {
  components: {
    modal: ModalComponent
    // toggle: ToggleComponent
  },
  props: {
    productQnA: Object,
    show: Boolean,
    index: Number
  },
  data () {
    return {
      productQnADetails: {},
      imagePreview: '',
      editing: false,
      modalState: 'detail'
    }
  },
  computed: {},
  watch: {
    show (value) {
      if (value) {
        this.editing = true
        this.$refs.editProductQnAModal.open()
        this.getProductQnADetails()
      } else {
        this.$refs.editProductQnAModal.close()
      }
    }
  },
  methods: {
    setImagePreview (folder) {
      const folderName = (folder) || this.productQnADetails.folderName
      let url = ''
      if (folderName === null) {
        url = `https://rexel-cdn.com/products/${this.productQnADetails.mfrName}.jpg?i=0EA7A6C5-7DD2-4482-9229-D628D3AFAE9D&f=150`
      } else {
        url = `https://rexel-cdn.com/Products/${this.productQnADetails.mfrName}.jpg?i=${folderName}&f=150`
      }
      this.imagePreview = url
    },
    resetModal () {
      this.$emit('update:show', false)
      this.productQnADetails = {}
      this.imagePreview = ''
      this.modalState = 'detail'
    },
    getProductQnADetails () {
      axios.get('/api/productQnA/GetProductQuestionDetail/' + this.productQnA.productQuestionId).then(res => {
        const response = res.data
        this.productQnADetails = response
        this.setImagePreview(this.folderName)
      })
    },
    editQuestion () {
      if (this.modalState !== 'edit') {
        this.modalState = 'edit'
      } else {
        const data = {
          productQuestionId: this.productQnADetails.productQuestionId,
          question: this.productQnADetails.question
        }
        axios.post('/api/productQnA/editProductQuestion', data).then(res => {
          this.$emit('commit', { productQnA: data, index: this.index })
          this.resetModal()
        })
      }
    },
    answerQuestion () {
      if (this.modalState !== 'answer') {
        this.modalState = 'answer'
        this.productQnADetails.denyReason = null
      } else {
        const data = {
          productQuestionId: this.productQnADetails.productQuestionId,
          answer: this.productQnADetails.answer
        }
        axios.post('/api/productQnA/answerProductQuestion', data).then(res => {
          this.$emit('commit', { productQnA: data, index: this.index })
          this.resetModal()
        })
      }
    },
    denyQuestion () {
      if (this.modalState !== 'deny') {
        this.modalState = 'deny'
        this.productQnADetails.answer = null
      } else {
        const data = {
          productQuestionId: this.productQnADetails.productQuestionId,
          denyReason: this.productQnADetails.denyReason
        }
        axios.post('/api/productQnA/denyProductQuestion', data).then(res => {
          this.$emit('commit', { productQnA: data, index: this.index })
          this.resetModal()
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/scss/extra/mixins/flexbox.scss';
@import '../../assets/scss/bootstrap-custom-variables.scss';
.image-header {
  @include flexbox;
  .image-box {
    width: 8rem;
    height: 8rem;
    padding: 0.5rem;
    margin-bottom: 0.25rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(center);
    > img {
      width: 100%;
      max-width: 7rem;
      max-height: 7rem;
    }
  }
  .title {
    @include flex(1, 1, 75%);
    @include flexbox;
    @include align-items(center);
    h4 {
      margin: 0 2rem;
    }
  }
  .change-image {
    padding: 1rem 2rem;
    @include align-self(center);
  }
}
.image-upload {
  position: relative;
  font-size: 0.8125rem;
  letter-spacing: 0.75px;
  color: $gray-600;
  padding-top: 0.25rem;
  text-decoration: underline;
  width: 8rem;
  text-align: center;
  margin-bottom: 1.25rem;
  &:hover {
    cursor: pointer;
    color: $brand-primary;
  }
  input {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
.productQnA-info {
  .custom-checkbox {
    margin-bottom: 0;
  }
  .form-control-label {
    position: relative;
    display: block;
    &.required:before {
      content: 'required';
      text-transform: uppercase;
      color: $brand-danger;
      font-size: 0.75rem;
      right: 0;
      top: 0.125rem;
      position: absolute;
    }
  }
}
.productQnA-company-access {
  .list-group {
    .list-group-item {
      border-right: 0;
      border-left: 0;
      padding: 0.5rem 1.25rem;
      @include flexbox;
      @include align-items(center);
      .toggle-lbl {
        padding-left: 1rem;
        color: lighten($gray, 15%);
        font-size: 1rem;
        &.active {
          color: $brand-primary;
        }
      }
    }
  }
}
.footer-btns {
  padding: 0 0.75rem;
  @include flexbox;
  @include justify-content(space-between);
  @include flex-direction(row-reverse);
  .btn-primary {
    min-width: 8.75rem;
  }
}
</style>
