<template id="modal-tmpl">
  <transition name="modal">
    <div class="modal-mask">
      <div
        class="modal-wrapper"
        :class="{'is-dialog': dialog}">
        <div
          v-dragged="onDrag"
          class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <h4>{{ heading }}</h4>
              <a
                class="close-modal"
                @click="$emit('close', false)">
                <i class="material-icons">
                  &#xE5CD;
                </i>
              </a>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              default body
            </slot>
          </div>

          <div
            v-if="!dialog"
            class="modal-footer">
            <slot name="footer">
              <button
                type="button"
                class="btn btn-gray-alt"
                @click="$emit('close', false)">
                Cancel
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script type="text/javascript">
export default {
  name: 'Modal',
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    heading: {
      default: '',
      type: String,
      required: false
    }
  },
  data () {
    return {
      dragged: false
    }
  },
  computed: {
    isSmallDevice () {
      return !window.matchMedia('(min-width: 800px)').matches
    }
  },
  methods: {
    onDrag ({ el, deltaX, deltaY, offsetX, offsetY, clientX, clientY, first, last }) {
      if (this.dialog && !this.isSmallDevice) {
        if (first) {
          this.dragged = true
          return
        }
        if (last) {
          this.dragged = false
          return
        }
        const l = +window.getComputedStyle(el).left.slice(0, -2) || 0
        const t = +window.getComputedStyle(el).top.slice(0, -2) || 0
        el.style.left = l + deltaX + 'px'
        el.style.top = t + deltaY + 'px'
      }
    }
  },
  template: '#modal-tmpl'
}
</script>
<style lang="scss" scoped>
@import '../../assets/scss/bootstrap-custom-variables';
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  width: 80%;
  margin: 0 auto;
  padding: .25rem 1.5rem .5rem;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  user-select: none;
  position: relative;
  @media (min-width: 800px) {
    width: 700px;
  }
}
.modal-header {
  padding: 1rem 0;
  color: $gray;
  > h4,
  > h3 {
    margin: 0;
  }
  .close-modal {
    cursor: pointer;
    padding: 0 0.25rem;

    .material-icons {
      font-size: 1.5rem;
    }
    &:hover {
      .material-icons {
        font-weight: bold;
      }
    }
  }
}
.modal-body {
  margin: 1.25rem 0;
  max-height: calc(100vh - 215px);
  overflow-y: auto;
}
.modal-footer {
  padding: 1rem 0;
}

.modal-wrapper.is-dialog {
  .modal-container {
    padding: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  .modal-header {
    padding: 0;
    padding-left: 1rem;
    background: #00416a;
    h4 {
      color: #fff;
      font-weight: 500;
      font-size: 1rem;
    }
    .close-modal {
      padding: 0;
      color: #fff;

      i.material-icons {
        font-size: 1.25rem;
        padding: 0.75rem !important;
        transition: background 0.15s ease;
      }
      i.material-icons:hover {
        background: #c00;
      }
    }
  }
  .modal-body {
    margin-top: 0;
    padding: 0;
  }
}

/* transition styles */
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
