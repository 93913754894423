<template>
  <div id="site-header-container">
    <nav class="navbar navbar-light navbar-expand-sm">
      <div
        class="navbar-container">
        <!-- NAVBAR CONTENT -->
        <div class="navbar-content">
          <ul
            v-if="hideGlobalSearch"
            class="navbar-nav">
            <li>
              <button
                class="btn-sidebar-toggler"
                @click.stop="toggleSidebar()">
                <div class="btn__content">
                  <i class="material-icons">
                    menu
                  </i>
                </div>
              </button>
            </li>
            <li>
              <a
                href="/"
                class="navbar-brand">
                <img
                  class="img-fluid"
                  src="../../assets/images/_logo-content-central-text.svg"
                  alt="NAPM" />
              </a>
            </li>
            <li>
              <v-container ml-6>
                <v-row
                  justify="center">
                  <v-col>
                    <div style="border:none;">
                      <v-row
                        justify="center"
                        align="center">
                        <v-col
                          cols="1">
                          <i class="material-icons">
                            search
                          </i>
                        </v-col>
                        <v-col>
                          <input
                            id="global-search"
                            v-model="searchTerm"
                            type="text"
                            class="form-control"
                            placeholder="Search for Products"
                            @keyup.enter="fetchProducts()" />
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </li>
          </ul>
          <ul v-else></ul>
          <ul class="navbar-nav navbar-user-container">
            <li
              v-if="language"
              class="nav-item dropdown language-dropdown-container">
              <a
                id="language-options"
                class="nav-link user-additional-actions dropdown-toggle"
                href="#"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                :class="{'disabled': $route.meta.task || $route.query.taskId}">
                <i class="material-icons">
                  language
                </i>
                <span class="selected-language">
                  {{ language.name }}
                </span>
              </a>
              <div
                class="dropdown-menu"
                aria-labelledby="language-options">
                <h6 class="dropdown-header">
                  Choose Language
                </h6>
                <div class="dropdown-divider"></div>
                <a
                  v-for="lang in $store.getters.languageOptions"
                  :key="`language-option-${lang.id}`"
                  class="dropdown-item"
                  :class="{'active': lang.id === language.id}"
                  @click.prevent="$store.dispatch('setLanguage', lang)">
                  <i class="material-icons">
                    language
                  </i>
                  <span>{{ lang.name }}</span>
                </a>
              </div>
            </li>
            <li class="nav-item dropdown user-action-container">
              <a
                id="user-actions"
                class="nav-link user-additional-actions dropdown-toggle"
                href="#"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                <i class="material-icons">
                  &#xE5D4;
                </i>
              </a>
              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="user-actions">
                <router-link
                  :to="{ name: 'userDetail', params: { id: $store.getters.user.userId || ' ' } }"
                  class="dropdown-item">
                  <i class="material-icons">
                    &#xE566;
                  </i> View Profile
                </router-link>
                <a
                  class="dropdown-item"
                  @click="sendToJiraForm()">
                  <i class="material-icons">
                    &#xE85A;
                  </i> Support
                </a>
                <a
                  class="dropdown-item"
                  @click="logoutUser()">
                  <i class="material-icons">
                    &#xE8AC;
                  </i> Sign Out
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
<script type="text/javascript">
import { manager } from '@/auth'

export default {
  name: 'HeaderComponent',
  data () {
    return {
      sidebarActive: false,
      globalSearch: false,
      searchTerm: ''
    }
  },
  computed: {
    isDirty () {
      return !this.searchTerm
    },
    language () {
      const lang = this.$store.getters.languageId
      return this.$store.getters.languageOptions.find(option => lang === option.id)
    },
    hideGlobalSearch () {
      // return !(this.$route.name === 'productSearch' && !!Object.entries(this.$route.query).length)
      return true
    }
  },
  methods: {
    toggleSidebar () {
      this.$emit('toggleSidebar')
    },
    fetchProducts () {
      const params = {
        searchTerm: this.searchTerm
      }
      this.$router.push({ name: 'productSearch', query: params })
      this.searchTerm = null
    },
    logoutUser () {
      manager.signoutRedirect()
    },
    sendToJiraForm () {
      const path = window.location.pathname
      let url = 'https://jira.rexelusa.com/servicedesk/customer/portal/60/create/513'
      const absoluteURL = new URL(path, window.location.origin).href
      url = url + '?customfield_28400=' + absoluteURL
      url = url + '&customfield_27100=30703'
      window.open(url, '_blank')
    }
  }
}
</script>
<style lang="scss">
@import "../../assets/scss/bootstrap-custom-variables";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../assets/scss/extra/mixins/flexbox";
@import "../../assets/scss/layout.scss";

#site-header-container {
  height: $header-height;

  .navbar {
    padding: 0;
    transform: translateY(0px);
    z-index: 5000;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: $header-height;
  }

  .navbar-container {
    @include flexbox;
    @include align-items(center);
    background-color: #eee;
    width: 100%;
    position: relative;
    border-bottom: 1px solid #c9c9c9;
  }

  .navbar-toggle-sidebar-container {
    background-color: $brand-primary;
    @include flexbox;
    @include align-items(center);
    height: $header-height;

    .navbar-brand {
      max-width: 130px;
    }
  }

  .material-icons {
    font-size: 24px;
  }

  .navbar-content {
    width: 100%;
    @include flexbox;
    @include flex-wrap(nowrap);
    @include justify-content(space-between);
  }

  ul.navbar-content {
    padding: 0px !important;
    margin: 0px !important;
  }

  .navbar-nav {
    height: $header-height;
    @include flex-direction(row);
    @include align-items(center);
    padding-left: 0px;
  }

  .nav-item,
  .nav-item a {
    @include flexbox;
    @include align-self(stretch);
    @include align-items(center);
  }

  .dropdown-menu {
    padding-top: 0.25rem;
    &:before,
    &:after {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      right: 50%;
      content: "";
    }
    &:before {
      border-width: 11px;
      top: -11px;
      margin-right: -11px;
      border-bottom-color: rgba(0, 0, 0, 0.25);
      border-top-width: 0;
    }
    &:after {
      border-width: 10px;
      border-top-width: 0;
      top: -10px;
      margin-right: -10px;
      border-bottom-color: #fff;
    }
  }

  .dropdown-toggle:after {
    position: relative;
    top: 1px;
    left: 5px;
    margin-right: 8px;
    border-top: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent;
  }

  .nav-link {
    padding: 0.5rem 1rem;
  }

  .global-search-dropdown {
    background-color: #c9c9c9;
    width: 30%;
    .nav-link {
      color: rgba(0, 0, 0, 0.75);
      width: 100%;
    }

    .dropdown-menu {
      left: 5px;
      &:before,
      &:after {
        right: 64%;
      }
    }
  }

  .global-search-input {
    .form-control {
      width: 100%;
      @include flexbox;
      @include align-self(stretch);
      @include align-items(center);
      background-color: #fff;
      border: none;
    }

    .search-icon-container {
      @include flexbox;
      @include align-self(stretch);
      @include align-items(center);
      background-color: #fff;
      padding-right: 10px;
    }
  }

  .navbar-user-container {
    .nav-item {
      border-left: 1px solid #c9c9c9;
      font-size: 1rem;
      @include flexbox;
      @include justify-content(center);
    }
  }

  .language-dropdown-container {
    .nav-link {
      min-width: 8.6125rem;
    }

    .selected-language {
      text-transform: uppercase;
      padding: 0 0.5rem;
    }

    .dropdown-menu {
      &:before,
      &:after {
        right: 16%;
      }
    }

    .dropdown-header {
      padding-bottom: 0;
    }

    .dropdown-item {
      @include flexbox;
      text-transform: uppercase;

      .material-icons {
        @include align-self(center);
        margin-right: 0.5rem;
      }

      &.active {
        color: $white;
      }
    }
  }

  .task-dropdown-container {
    min-width: 4rem;
    position: relative;

    .dropdown-toggle:after {
      display: none;
    }

    .dropdown-menu {
      &:before,
      &:after {
        right: 17%;
      }
    }

    #incomplete-tasks {
      width: 1rem;
      height: 1rem;
      position: absolute;
      right: 0.4rem;
      top: 0.6rem;
      color: #fff;
      background-color: $brand-primary;
      border: 1px solid darken($brand-primary, 5%);
      border-radius: 100%;
      font-size: 0.625rem;
      @include flexbox;
      @include justify-content(center);
      @include align-items(center);
    }
  }

  .user-action-container {
    min-width: 4rem;
    position: relative;

    .dropdown-toggle:after {
      display: none;
    }

    .dropdown-menu {
      right: 5px;

      i {
        font-size: 18px;
        vertical-align: middle;
        padding-right: 5px;
      }

      &:before,
      &:after {
        right: 16.5%;
      }
    }
  }
}

@media (max-width: $mobile-view) {
  .hide-on-mobile {
    display: none !important;
  }

  #site-header-container {
    .navbar-content,
    .nav-link {
      background-color: $brand-primary;
      color: white;
    }
  }
}

.v-dialog__container {
    display: flex !important;
}
</style>
