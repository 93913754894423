<template>
  <section id="sustainabilities">
    <div class="section-heading">
      <h6>Sustainability</h6>
    </div>
    <div class="section-body">
      <div class="container ml-0 pl-0">
        <div class="row">
          <div class="col">
            <label class="form-control-label">
              Sustainability Type
            </label>
            <select
              v-model="product.sustainabilityTypeId"
              class="form-control">
              <option :value="null">
                -- Select --
              </option>
              <option
                v-for="s in sustainableTypes"
                :key="s.id"
                :value="s.id">
                {{ s.name }}
              </option>
            </select>
          </div>
          <div class="col">
            <label class="form-control-label">
              Sustainable Segment
            </label>
            <select
              v-model="product.sustainableSegmentId"
              class="form-control">
              <option :value="null">
                -- Select --
              </option>
              <option
                v-for="s in sustainableSegments"
                :key="s.id"
                :value="s.id">
                {{ s.name }}
              </option>
            </select>
          </div>
          <div class="col">
            <v-checkbox
              v-model="product.sustainabilityCharterInd"
              class="my-0"
              color="black"
              hide-details
              disabled
              label="Charter">
            </v-checkbox>
            <v-checkbox
              v-model="product.sustainableOfferInd"
              color="black"
              class="my-0"
              hide-details
              disabled
              label="Sustainable Offer">
            </v-checkbox>
          </div>
        </div>
        <v-row
          class="my-2 py-2 pr-2"
          style="background: #eee;"
          no-gutters>
          <v-col
            class="ml-2">
            <v-checkbox
              v-model="product.energyStar"
              color="indigo darken-4"
              hide-details
              dense
              label="Energy Star">
            </v-checkbox>
          </v-col>
          <v-col
            v-if="product.energyStar">
            <label class="form-control-label">
              Ecolabel ID#
            </label>
            <input
              v-model="product.ecoLabelEnergyStarCertificationId"
              type="number"
              class="form-control" />
          </v-col>
          <v-col
            v-if="product.energyStar"
            class="ml-1">
            <label class="form-control-label">
              Date Certified
            </label>
            <input
              v-model="product.ecoLabelEnergyStarCertificationDate"
              class="form-control"
              type="date" />
          </v-col>
        </v-row>
        <v-row
          class="py-2 pr-2"
          style="background: #eee;"
          no-gutters>
          <v-col
            class="ml-2">
            <v-checkbox
              v-model="product.ecoLabelDlcPremiumInd"
              color="indigo darken-4"
              hide-details
              dense
              label="DLC Premium">
            </v-checkbox>
          </v-col>
          <v-col
            v-if="product.ecoLabelDlcPremiumInd">
            <label class="form-control-label">
              Ecolabel ID#
            </label>
            <input
              v-model="product.ecoLabelDlcPremiumCertificationId"
              maxlength="15"
              class="form-control" />
          </v-col>
          <v-col
            v-if="product.ecoLabelDlcPremiumInd"
            class="ml-1">
            <label class="form-control-label">
              Date Certified
            </label>
            <input
              v-model="product.ecoLabelDlcPremiumCertificationDate"
              type="date"
              class="form-control" />
          </v-col>
        </v-row>
      </div>
    </div>
  </section>
</template>
<script type="text/javascript">
import axios from 'axios'
export default {
  props: {
    loaded: Boolean,
    product: Object,
    countries: Array
  },
  data () {
    return {
      sustainableSegments: null,
      sustainableTypes: null
    }
  },
  mounted () {
    this.getSustainableSegments()
    this.getSustainableTypes()
  },
  methods: {
    getSustainableSegments () {
      return axios.get('/api/sustainability/segment')
        .then(res => {
          this.sustainableSegments = res.data
        })
        .catch(e => {
          console.error(e)
        })
    },
    getSustainableTypes () {
      return axios.get('/api/sustainability/types')
        .then(res => {
          this.sustainableTypes = res.data
        })
        .catch(e => {
          console.error(e)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/scss/extra/mixins/flexbox.scss';
.section-heading {
  margin-top: 0.5rem;
}

.form-group {
    width: 100%;
    @media (min-width: 37.5rem) {
      width: calc(50% - 1rem);
    }
    @media (min-width: 1200px) {
      width: calc(25% - 1rem);
    }
    input[readonly],
    input[disabled='disabled'] {
      border: 1px solid transparent;
      background-color: #ececec;
    }
  }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
