<template>
  <div>
    <div class="overflow_table">
      <div
        class="data-table"
        :style="{'min-width': minWidth + 'px' }">
        <div
          v-if="headers.length > 0"
          ref="tableHeaders"
          class="headers-container">
          <div
            v-if="selectAll"
            class="header select-all-enabled">
            <div
              class="checkbox-container"
              :class="[allItemsSelected ? 'checkbox-checked' : '']"
              @click="selectAllValues()">
              <i
                v-show="allItemsSelected"
                class="material-icons">
                check
              </i>
            </div>
          </div>
          <div class="content">
            <div
              v-for="(header, index) in headers"
              :key="`header-${index}`"
              class="header"
              :style="{
                'min-width': header.width,
                'text-align': header.align ? header.align : 'left'
              }"
              :class="[header.sortable !== false ? 'sortable-header' : '']"
              @click="sortContent(header, index)">
              <span>{{ header.text }}</span>
              <span
                v-if="header.sortable !== false"
                class="sort-container d-flex">
                <i
                  v-if="sortCol.value === header.value && sortCol.asc === true"
                  class="material-icons">
                  expand_more
                </i>
                <i
                  v-else-if="sortCol.value === header.value && sortCol.asc === false"
                  class="material-icons">
                  expand_less
                </i>
                <i
                  v-else
                  class="material-icons no-sort">
                  code
                </i>
              </span>
            </div>
          </div>
        </div>
        <div
          ref="tableBody"
          class="display-body-scroll"
          :style="{ 'max-height': bodyMaxHeight }">
          <div
            v-if="!loading && hasFilteredItems"
            class="display-body">
            <div
              v-for="(row, index) in filteredItems"
              :key="`body-row-${index}`"
              class="body-row"
              :class="[clickable ? 'clickable' : '', evalBodyRowClass(row, index)]"
              @click="$emit('rowClicked', row)">
              <div
                v-if="selectAll"
                class="body-cell">
                <div
                  class="checkbox-container"
                  :class="[rowIsSelected(row) ? 'checkbox-checked' : '']"
                  @click.stop.prevent="selectRow(row)">
                  <i
                    v-show="rowIsSelected(row)"
                    class="material-icons">
                    check
                  </i>
                </div>
              </div>
              <div class="content">
                <div
                  v-for="(cell, ind) in cells"
                  :key="`body-row-${index}-${ind}`"
                  class="body-cell"
                  :class="[bodyCellClass ? bodyCellClass : '']"
                  :style="{
                    'min-width': cell.width,
                    'text-align': cell.align ? cell.align : 'left',
                    'max-width': cell.maxWidth ? cell.maxWidth : ''
                  }">
                  <slot
                    :name="`body-cell-${ind + 1}`"
                    :item="row"
                    :index="index"></slot>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else-if="loading"
            class="data-table-loading">
            <div class="table-progress-bar">
              <div class="sub-progress-bar base-bar"></div>
              <div class="sub-progress-bar transition-bar"></div>
            </div>
            <div class="data-table-searching">
              Searching...
            </div>
          </div>
          <div
            v-else-if="hasItems && search && search.length > 0"
            class="alert alert-info mb-0">
            No Data found for the search term of <strong>"{{ search }}"</strong>. Try changing your search term.
          </div>
          <div
            v-else
            class="alert alert-info mb-0">
            {{ noDataText }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!hideDetails && hasItems && !viewingAllItems"
      class="table-pagination mt-2">
      <data-table-pagination
        :pagination.sync="pagination"
        :options="paginationOptions"></data-table-pagination>
    </div>
  </div>
</template>

<script type="text/javascript">
import DataTablePagination from './DataTablePagination.vue'

// copied over from vuetify
function getObjectValueByPath (obj, path) {
  // credit: http://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-with-string-key#comment55278413_6491621
  if (!path || path.constructor !== String) return
  path = path.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
  path = path.replace(/^\./, '') // strip a leading dot
  const a = path.split('.')
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i]
    if (obj instanceof Object && k in obj) {
      obj = obj[k]
    } else {
      return
    }
  }
  return obj
}

export default {
  name: 'DataTable',
  components: {
    DataTablePagination
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    },
    headers: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    },
    minWidth: {
      type: Number,
      default: 1000
    },
    maxHeight: {
      default: '100%'
    },
    clickable: {
      type: Boolean,
      default: false
    },
    bodyCellClass: {},
    evalBodyRowClass: {
      type: Function,
      default () {
        return () => ''
      }
    },
    items: {
      type: Array,
      default: () => []
    },
    search: {
      type: String,
      default: ''
    },
    noDataText: {
      type: String,
      default: 'No data available'
    },
    selectAll: Boolean,
    rowsPerPageText: {
      type: String,
      default: 'Rows per page:'
    },
    pagination: {
      default: () => {
        return {
          total: 0,
          per_page: 25,
          current_page: 1,
          last_page: 0,
          from: 0,
          to: 15
        }
      }
    },
    paginationOptions: {
      default: () => {
        return {
          offset: 5,
          previousText: 'Prev',
          nextText: 'Next',
          alwaysShowPrevNext: true,
          pageSizes: [25, 50, 100, 150, 250, 500]
        }
      }
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    /** copied from vuetify node_module logic data-iterable.js with some slight modifications **/
    filter: {
      type: Function,
      default: (val, search) => {
        return val != null &&
          typeof val !== 'boolean' &&
          val.toString().toLowerCase().indexOf(search) !== -1
      }
    },
    filterOnKeys: {
      type: Array
    },
    customFilter: {
      type: Function,
      default: (items, filter, search, cells, filterOnKeys) => {
        search = search.toString().toLowerCase()
        if (search.trim() === '') return items
        const props = filterOnKeys || cells.map(h => h.value)
        return items.filter((item) => {
          return props.some(prop => filter(getObjectValueByPath(item, prop), search))
        })
      }
    },
    customSort: {
      type: Function,
      default: (items, index, isDescending) => {
        if (index === null) return items
        return items.sort((a, b) => {
          let sortA = getObjectValueByPath(a, index)
          let sortB = getObjectValueByPath(b, index)

          if (sortA === null && sortB === null) {
            return 0
          }

          [sortA, sortB] = [sortA, sortB]
            .map(s => (
              (s || '').toString().toLocaleLowerCase().trim()
            ))

          return isDescending ? sortB.localeCompare(sortA) : sortA.localeCompare(sortB)
        })
      }
    }
    /** end of copied from vuetify node_module logic data-iterable.js **/
  },
  data () {
    return {
      sortCol: { value: '', asc: null },
      baseItems: [],
      selected: []
    }
  },
  computed: {
    filteredItems () {
      let items
      if (this.search) {
        items = this.customFilter(this.baseItems, this.filter, this.search, this.cells, this.filterOnKeys)
      } else {
        items = this.baseItems.slice()
      }
      // logic copied over from product qna list logic
      if (this.pagination) {
        this.pagination.total = this.search ? items.length : this.baseItems.length
        this.pagination.currentViewing = (this.pagination.current_page - 1) * this.pagination.per_page + 1
        items = items.filter((ven, index) => {
          return index >= ((this.pagination.current_page - 1) * this.pagination.per_page) &&
                            index <= ((this.pagination.current_page - 1) * this.pagination.per_page + this.pagination.per_page - 1)
        })
        this.pagination.last_page = Math.ceil(this.pagination.total / this.pagination.per_page)
      }
      this.checkForVerticalScrollBar()
      return items
    },
    cells () {
      return this.headers.length > 0 ? this.headers : this.columns
    },
    hasFilteredItems () {
      return this.filteredItems.length > 0
    },
    hasItems () {
      return this.baseItems && this.baseItems.length > 0
    },
    bodyMaxHeight () {
      return Number(this.maxHeight) ? `${this.maxHeight}px` : this.maxHeight
    },
    viewingAllItems () {
      return parseInt(this.pagination.currentViewing) + parseInt(this.pagination.per_page) - 1 >= this.pagination.total
    },
    allItemsSelected () {
      return (this.selected.length === this.baseItems.length && this.selected.length > 0)
    }
  },
  watch: {
    items (val, old) {
      this.baseItems = val.slice()
    },
    value (val) {
      this.selected = val
    },
    selected (val) {
      this.$emit('input', val)
    },
    loading (val) {
      if (!val) {
        this.checkForVerticalScrollBar()
      }
    },
    search (val, oldVal) {
      if (val !== oldVal && this.pagination) {
        this.pagination.currentViewing = 1
        this.pagination.current_page = 1
      }
    },
    'pagination.per_page' () {
      this.pagination.currentViewing = 1
      this.pagination.current_page = 1
    }
  },
  updated () {
    if (!this.loading) {
      this.checkForVerticalScrollBar()
    }
  },
  mounted () {
    if (this.items != null) {
      this.baseItems = this.items.slice()
    }
  },
  methods: {
    sortContent (header, index) {
      if (header.sortable === false || !header.value || !this.hasItems) { return }
      if (this.sortCol.value !== header.value) {
        this.sortCol.value = header.value
        this.sortCol.asc = null
      }
      if (this.sortCol.asc === true) {
        this.sortCol.asc = false
      } else if (this.sortCol.asc === null) {
        this.sortCol.asc = true
      } else {
        this.sortCol.asc = null
      }
      if (this.sortCol.asc == null) {
        this.baseItems = this.items.slice()
      } else {
        if (header.customSort) {
          this.baseItems = header.customSort(this.baseItems, this.sortCol.value, this.sortCol.asc)
        } else {
          this.baseItems = this.customSort(this.baseItems, this.sortCol.value, this.sortCol.asc)
        }
      }
    },
    checkForVerticalScrollBar () {
      this.$nextTick(() => {
        if (!this.$refs.tableBody || !this.$refs.tableHeaders) return
        const offsetScrollbar = this.$refs.tableBody.offsetWidth - this.$refs.tableBody.clientWidth
        if (offsetScrollbar > 0) {
          this.$refs.tableHeaders.style['padding-right'] = offsetScrollbar + 2 + 'px'
        } else {
          this.$refs.tableHeaders.style['padding-right'] = 0
        }
      })
    },
    selectAllValues () {
      if (this.allItemsSelected) {
        this.selected = []
      } else {
        this.selected = this.baseItems.slice()
      }
    },
    selectRow (row) {
      const index = this.selected.indexOf(row)
      if (index !== -1) {
        this.selected.splice(index, 1)
      } else {
        this.selected.push(row)
      }
    },
    rowIsSelected (row) {
      return this.selected.indexOf(row) !== -1
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/scss/extra/mixins/flexbox.scss';
@import '../../assets/scss/bootstrap-custom-variables';

.overflow_table {
  overflow-y: hidden;
  overflow-x: auto;
}

.data-table {
  width: 100%;
  margin: 0;
  border: 1px solid #eceeef;

  $horizontal-space: 0.75rem;

  .content {
    @include flexbox;
    @include flex-wrap(nowrap);
    width: 100%;
  }

  .headers-container {
    background-color: #eee;
    @include flexbox;
    @include flex-wrap(nowrap);
    width: 100%;

    .header {
      padding: 0.75rem $horizontal-space;
      @include flexbox;
      @include align-items(center);
      @include flex-wrap(nowrap);
      overflow: hidden;
      box-sizing: border-box;
      flex-grow: 1;
      color: #333;
      font-weight: bold;
      font-size: 14px;
      -webkit-user-select: none; /* Safari 3.1+ */
      -moz-user-select: none; /* Firefox 2+ */
      -ms-user-select: none; /* IE 10+ */
      user-select: none; /* Standard syntax */
    }

    .no-sort {
      -ms-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
      visibility: hidden;
    }

    .sortable-header:hover {
      cursor: pointer;
      opacity: .8;

      .no-sort {
        visibility: visible;
      }
    }

    .sort-container {
      padding-left: 2px;
      font-size: 18px;
    }
  }

  .checkbox-container {
    @include flexbox;
    @include flex-wrap(wrap);
    @include align-items(center);
    @include justify-content(center);
    width: 100%;
    min-width: 16px;
    min-height: 16px;
    position: relative;
    border: 1px solid #999;
    border-radius: 2px;
    outline: none;
    background-color: #fff;
    cursor: pointer;
    -webkit-transition: -webkit-box-shadow .3s cubic-bezier(.25,.8,.5,1);
    transition: -webkit-box-shadow .3s cubic-bezier(.25,.8,.5,1);
    transition: box-shadow .3s cubic-bezier(.25,.8,.5,1);
    transition: box-shadow .3s cubic-bezier(.25,.8,.5,1),-webkit-box-shadow .3s cubic-bezier(.25,.8,.5,1);

    &.checkbox-checked {
      background-color: $brand-primary;
      border: 0;
    }

    .material-icons {
      color: #fff;
      font-size: 14px !important;
    }
  }

  .display-body-scroll {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .display-body {
    .body-row {
      border-bottom: 1px solid #eceeef;
      text-decoration: none;
      @include flexbox;
      @include flex-wrap(nowrap);
      @include align-items(center);

      .body-cell {
        color: #333;
      }
    }

    .clickable:hover {
      background-color: darken(#fff, 5%);
      cursor: pointer;
      .body-cell {
        color: darken(#999, 65%);
      }
    }

    .body-cell {
      padding: 0.5rem $horizontal-space;
      position: relative;
      color: #999;
      border: none;
      font-size: 0.75rem;
    }

    .product-body-cell {
      @include flexbox;
      @include align-items(center);

      img {
        max-width: 50px;
      }
    }

    .arrow-body {
      i {
        font-size: 1.5rem;
      }
    }
  }
}

// .table-pager {
//   @include flex(1, 1, 30%);
//   @include flexbox;
//   @include justify-content(flex-end);
//   .table-viewing-info {
//     width: 10rem;
//     text-align: right;
//     color: #222;
//     font-size: .875rem;
//     @include align-self(center);
//     @include flex(1, 1, 10rem);

//     >label {
//       background: #eee;
//       padding: .5rem 1.5rem;
//       margin-bottom: 0;
//       border: 1px solid rgba(0, 0, 0, .15);
//     }
//   }
//   .input-group {
//     width: 12rem;
//     border: 0;

//     .input-group-addon {
//       font-size: .875rem;
//       border: 0;
//       background: #fff;
//     }
//     .form-control {
//       font-size: .875rem;
//       background: #eee;
//     }
//   }
// }

.data-table-searching {
  font-weight: bold;
  text-align: center;
  padding: 2rem;
  position: relative;
}

.table-progress-bar {
  height: 7px;
  width: 100%;
  position: relative;
  top: -2px;
  margin: 0 0 -2px;
  background-color: transparent;

  .sub-progress-bar {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 7px;
  }

  .base-bar {
    background-color: $brand-primary;
    opacity: .3;
  }

  .transition-bar {
    transform: translateX(-30vw);
    width: 20%;
    animation: move 1.5s linear 0s infinite;
    background-color: $brand-primary;
  }

  @-webkit-keyframes move {
    from {
      transform: translateX(-30vw);
    }
    to {
      transform: translateX(100vw);
    }
  }

  @-moz-keyframes move {
    from {
      transform: translateX(-30vw);
    }
    to {
      transform: translateX(100vw);
    }
  }

  @-o-keyframes move {
    from {
      transform: translateX(-30vw);
    }
    to {
      transform: translateX(100vw);
    }
  }

  @keyframes move {
    from {
      transform: translateX(-30vw);
    }
    to {
      transform: translateX(100vw);
    }
  }
}
</style>
