<template>
  <div class="idw-dialog">
    <template v-if="singleValues.description || singleValues.shortDescription">
      <div class="row mb-3">
        <div class="col checkboxes">
          <label class="custom-control custom-checkbox mt-2 pr-2">
            <input
              v-model="selectAll"
              type="checkbox"
              class="custom-control-input" />
            <span class="custom-control-indicator"></span>
          </label>
          <span>Select All (In this section)</span>
        </div>
      </div>

      <div class="row">
        <div class="col-auto checkboxes">
          <label class="custom-control custom-checkbox">
            <input
              v-model="selectedValues"
              type="checkbox"
              class="custom-control-input"
              :value="{ name: 'description', value: singleValues.description }" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="col">
          <div class="form-group">
            <label class="form-control-label">
              Description
            </label>
            <textarea
              class="form-control"
              :value="singleValues.description"
              disabled
              rows="4"></textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-auto checkboxes">
          <label class="custom-control custom-checkbox">
            <input
              v-model="selectedValues"
              type="checkbox"
              class="custom-control-input"
              :value="{ name: 'shortDescription', value: singleValues.shortDescription }" />
            <span class="custom-control-indicator"></span>
          </label>
        </div>
        <div class="col">
          <div class="form-group">
            <label class="form-control-label">
              Short Description
            </label>
            <input
              class="form-control"
              :value="singleValues.shortDescription"
              disabled />
          </div>
        </div>
      </div>
      <div class="row text-center">
        <div class="col">
          <button
            type="button"
            class="btn btn-primary"
            @click="onSubmit">
            Apply
          </button>
        </div>
      </div>
    </template>

    <div
      v-else
      class="alert alert-primary">
      No description data found.
    </div>
  </div>
</template>
<script type="text/javascript">

export default {
  name: 'IdwDescriptions',
  props: {
    singleValues: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      selectAll: false,
      selectedValues: []
    }
  },
  watch: {
    selectAll (val) {
      if (val) {
        this.selectedValues = [
          { name: 'description', value: this.singleValues.description },
          { name: 'shortDescription', value: this.singleValues.shortDescription }
        ]
      } else if (!val && this.selectedValues.length === 2) this.selectedValues = []
    },
    selectedValues (val) {
      this.selectAll = val.length === 2
    }
  },
  methods: {
    onSubmit () {
      this.$emit('onSelected', this.selectedValues)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../../assets/scss/extra/mixins/flexbox";
@import "../../../../assets/scss/bootstrap-custom-variables";
.idw-dialog {
  border: none;

  .checkboxes {
    display: flex;
    align-items: center;
    z-index: 100;

    :hover {
      cursor: pointer;
    }

    label {
      padding-left: 0rem;
    }
  }
}
</style>
