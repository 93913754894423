<template>
  <div>
    <modal
      ref="modal"
      :title="'Product'"
      @close="close()">
      <div
        v-if="item && item.napmId"
        class="content-container">
        <div class="product-container">
          <div class="img-fluid product-img-container mr-3">
            <img
              v-if="item.folderName"
              :src="'https://rexel-cdn.com/products/' + item.folderName + '/150.jpg'" />
            <img
              v-else
              src="https://images.plattstatic.com/ProductImages/thumbnail/Nonstock.gif" />
          </div>
          <div class="product-desc-container">
            <div>
              NAPM#:
              <span class="prod-detail">
                {{ item.napmId }}
              </span>
            </div>
            <div>
              Mfr Cat #:
              <span class="prod-detail">
                {{ item.catNum }}
              </span>
            </div>
            <div>
              UPC:
              <span class="prod-detail">
                {{ item.upc || 'N/A' }}
              </span>
            </div>
          </div>
        </div>
        <hr />
        <div class="edit-slang-terms">
          <h5>Slang Terms</h5>
          <div class="input-group-container">
            <div
              v-for="(slangTerm, index) in item.slangTerms"
              :key="`approve-edit-term-${index}`"
              class="term-input-group">
              <div v-show="selectedItem.productSlangTermId !== slangTerm.productSlangTermId">
                <div class="slang-term">
                  <div class="slang-term-content">
                    <div class="submitted-term">
                      {{ slangTerm.submittedTerm }}
                    </div>
                    <div class="action">
                      {{ getSlangTermStatusText(slangTerm) }}
                    </div>
                  </div>
                  <div class="input-group-append">
                    <span
                      class="input-group-text"
                      @click="editItem(slangTerm, `approvedChange${slangTerm.productSlangTermId}`)">
                      <i class="material-icons">
                        edit
                      </i>
                    </span>
                  </div>
                </div>
              </div>
              <div
                v-if="selectedItem.productSlangTermId === slangTerm.productSlangTermId"
                class="edit-approved-input">
                <input
                  :ref="`approvedChange${slangTerm.productSlangTermId}`"
                  v-model="selectedItem.submittedTerm"
                  type="text"
                  class="form-control mb-2" />
                <div v-if="mode === 'Approval'">
                  <select
                    v-if="!hideActions"
                    v-model="action"
                    class="form-control mb-2">
                    <option :value="actions.approve">
                      Approve
                    </option>
                    <option :value="actions.approveSearch">
                      Approve for Search
                    </option>
                    <option :value="actions.deny">
                      Deny
                    </option>
                  </select>
                </div>
                <div v-else>
                  <v-select
                    v-if="!hideActions"
                    v-model="action"
                    :items="actionsForProductEdit"
                    item-text="itemText"
                    item-value="itemValue"
                    label="State">
                  </v-select>
                </div>
                <div class="input-buttons">
                  <div class="d-inline-block">
                    <div
                      v-if="invalid"
                      class="text--danger">
                      {{ invalid }}
                    </div>
                  </div>
                  <v-row
                    align="center"
                    no-gutters>
                    <v-spacer>
                    </v-spacer>
                    <v-col
                      cols="auto">
                      <v-btn
                        class="p-0"
                        text
                        x-small
                        left
                        @click="cancel()">
                        Cancel
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="auto">
                      <v-btn
                        text
                        x-small
                        left
                        @click="save(slangTerm)">
                        Save
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        slot="button"
        class="modal-actions">
        <button
          type="button"
          class="btn btn-primary"
          :disabled="selectedItem.productSlangTermId"
          @click="done()">
          Done
        </button>
      </div>
    </modal>
  </div>
</template>
<script type="text/javascript">
import Modal from 'components/modals/sweet.vue'
import SlangTermMixin from './SlangTermMixin'

export default {
  name: 'ApprovedTermModal',
  components: {
    modal: Modal
  },
  mixins: [SlangTermMixin],
  props: {
    item: {
      type: Object,
      default: null
    },
    hideActions: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'ProductEdit' // or 'Approval'
    }
  },
  data () {
    return {
      selectedItem: {},
      action: '',
      invalid: ''
    }
  },
  watch: {
    'selectedItem.submittedTerm' (val) {
      if (val) { this.invalid = '' }
    }
  },
  methods: {
    open () {
      this.$refs.modal.open()
    },
    save (unchangedSlangTerm) {
      const termChanged = this.isTermChanged(unchangedSlangTerm)
      const actionChanged = this.isActionChanged(unchangedSlangTerm)
      if (termChanged || actionChanged) { // only save if change occurs
        this.invalid = this.validateTerm(this.selectedItem)
        if (!this.invalid) {
          const data = {
            productSlangTermId: this.selectedItem.productSlangTermId,
            action: actionChanged ? this.action : this.actions.change,
            napmId: this.item.napmId
          }
          if (termChanged) {
            data.submittedTerm = this.selectedItem.submittedTerm // only pass submitted term if it is different
          }
          this.$emit('save', data)
          this.done()
          // if (data.action === 'deny') {
          //   // We set this to deny...so we should remove the slang term.
          //   // this.item.slangTerms = this.item.slangTerms.filter(element => element !== this.selectedItem)
          //   this.done()
          // } else {
          //   this.cancel()
          // }
        }
      } else {
        this.cancel()
      }
    },
    validateTerm (newTerm) {
      if (!newTerm.submittedTerm) { return 'Required' }
      const slangTermsFound = this.item.slangTerms.filter(term => {
        return (term.productSlangTermId !== newTerm.productSlangTermId &&
          term.submittedTerm.trim() === newTerm.submittedTerm.trim())
      }).length > 0
      if (slangTermsFound) {
        return 'Term already exists'
      }
    },
    editItem (slangTerm, ref) {
      this.selectedItem = JSON.parse(JSON.stringify(slangTerm))
      this.action = this.getSlangTermStatus(slangTerm)
      this.$nextTick(() => {
        this.$refs[ref][0].focus()
      })
    },
    cancel () {
      this.selectedItem = {}
      this.action = ''
    },
    close () {
      this.cancel()
      this.$emit('close')
    },
    done () {
      this.$refs.modal.close()
    },
    isTermChanged (oldItem) {
      return (this.selectedItem.submittedTerm.trim() && (this.selectedItem.submittedTerm.trim() !== oldItem.submittedTerm.trim()))
    },
    isActionChanged (oldItem) {
      const oldAction = this.getSlangTermStatus(oldItem)
      return (this.action !== oldAction)
    },
    getSlangTermStatusText (slangTerm) {
      const action = this.getSlangTermStatus(slangTerm)
      return (action === this.actions.approveSearch) ? '(For search only)' : ''
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../assets/scss/extra/mixins/flexbox.scss";
@import "../../assets/scss/bootstrap-custom-variables.scss";

/**** GLOBAL *****/
.text--brand {
  color: $brand-primary !important;
}

.text--danger {
  color: $brand-danger !important;
}

.product-container {
  @include flexbox;
  @include align-items(center);

  .product-desc-container {
    font-size: 16px;
    div {
      margin: 5px 0;
    }

    .prod-detail {
      display: inline-block;
      font-weight: bold;
      margin-left: 5px;
    }
  }

  .product-img-container {
    min-width: 60px;
    border: 1px solid #ccc;
    background-color: #fff;
    text-align: center;
    padding: 5px;
  }

  img {
    width: 100%;
    max-height: 150px;
  }
}

.edit-slang-terms {
  h5 {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 15px;
  }

  $horizontal-spacing: 10px;

  .input-group-container {
    @include flexbox;
    @include flex-wrap(wrap);
    margin-left: -$horizontal-spacing;
    margin-right: -$horizontal-spacing;
  }

  .term-input-group {
    width: 50%;
    padding: 0 $horizontal-spacing;
    margin: 5px 0;

    @media screen and (max-width: 768px) {
      width: 100%;;
    }
  }

  .edit-approved-input {
    background-color: #eceeef;
    padding: 10px;

    .input-buttons {
      @include flexbox;
      @include justify-content(space-between);
      span {
        color: #000;
        cursor: pointer;
      }

      span:hover {
        opacity: .7;
      }
    }
  }

  .slang-term-content,
  .form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    line-height: 1.5;
    color: #000;
    background-clip: padding-box;
    font-weight: bold;
    border: 1px solid #ccc;
  }

  .slang-term-content {
    .submitted-term {
      display: inline-block;
    }

    .action {
      font-size: 12px;
      font-style: italic;
      font-weight: normal;
      display: inline-block;
      margin-left: 5px;
    }
  }

  .slang-term {
    @include flexbox;
    @include align-items(stretch);
    @include flex-wrap(nowrap);
    width: 100%;
    background-color: #eceeef;
  }

  .editing-form-control {
    background-color: #fff;
  }

  .slang-term > .form-control:not(:last-child) {
    @include flex(1,1,auto);
    width: 1%;
  }

  .input-group-append {
    margin-left: -1px;
    @include flexbox;
    @include flex-wrap(nowrap);
  }

  .input-group-text {
    @include flexbox;
    @include align-items(center);
    @include align-self(stretch);
    padding: .375rem .5rem;
    height: 100%;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #ccc;
    cursor: pointer;
  }

  .input-group-action {
    background-color: #eceeef;

    i {
      font-weight: bold;
    }
  }
}

.modal-actions {
  @include flexbox;
  @include justify-content(flex-end);
  .btn {
    min-width: 7rem;
  }
}
</style>
