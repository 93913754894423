<template>
  <div
    v-if="loading"
    class="uploading-spinner"></div>
</template>
<script type="text/javascript">
export default {
  name: 'LoadingSpinner',
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: true
    }
  }
}
</script>
<style lang="scss">
  .uploading-media {
    opacity: .5;
  }
  .uploading-spinner {
    -moz-animation: spin .75s infinite linear;
    -o-animation: spin .75s infinite linear;
    -webkit-animation: spin .75s infinite linear;
    animation: spin .75s infinite linear;
    background-image: url("../../../assets/images/loader.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -2rem 0 0 -2rem;
    width: 4rem;
    height: 4rem;
    z-index: 1000;
  }
</style>
