<template>
  <div class="input-group">
    <input
      v-model="term"
      type="text"
      class="form-control search-box"
      placeholder="Search Within Results"
      @keyup.enter="emitData()" />
    <span class="input-group-btn">
      <button
        type="button"
        class="btn btn-outline-secondary"
        @click="emitData()">
        <i class="material-icons">
          &#xE8B6;
        </i>
      </button>
    </span>
  </div>
</template>

<script>
export default {
  name: 'SearchWithinInput',
  data () {
    return {
      term: null
    }
  },
  methods: {
    emitData () {
      this.$emit('onSubmit', this.term)
    }
  }
}
</script>

<style lang="scss" scoped>
.search-box {
  height: 43px;
  & ~ .input-group-btn {
    .btn {
      height: 43px;
    }
  }
}
</style>
