<template>
  <div>
    <div class="list-group">
      <a
        class="list-group-item history-title collapsed"
        data-toggle="collapse"
        href="#collapseHistory"
        aria-expanded="false"
        aria-controls="collapseHistory">
        <h6>Product Log</h6>
        <span class="history-title-icns">
          <i class="material-icons hist-collapsed">
            add
          </i>
          <i class="material-icons hist-show">
            remove
          </i>
        </span>
      </a>
      <div
        id="collapseHistory"
        class="history-list collapse"
        aria-expanded="false">
        <div
          v-for="(hist, index) in showHistoryList"
          :key="index"
          class="list-group-item">
          <div class="text-uppercase">
            Section: {{ hist.editType }}
          </div>
          <div class="text-lowercase">
            {{ hist.editDate | isoDate }} -
            <a :href="`mailto:${hist.emailAddress}`">
              {{ hist.emailAddress }}
            </a>
          </div>
        </div>
        <div
          v-if="historyList.length == 0"
          class="list-group-item">
          <span class="text-uppercase">
            No History
          </span>
        </div>
        <a
          v-if="listLength < historyList.length"
          class="list-group-item list-group-item-action"
          @click="showFullList()">Show All</a>
        <a
          v-if="listLength > 5 && listLength == historyList.length"
          class="list-group-item list-group-item-action"
          @click="showShortList()">Show First Five</a>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import axios from 'axios'

export default {
  props: {
    id: Number
  },
  data () {
    return {
      historyList: [],
      listLength: 5
    }
  },
  computed: {
    showHistoryList () {
      return this.historyList.slice(0, this.listLength)
    }
  },
  watch: {
    id () {
      this.getEditHistory()
    }
  },
  methods: {
    getEditHistory () {
      axios.get(`/api/products/${this.id}/edit-history`)
        .then(res => {
          const response = res.data
          this.showShortList()
          if (response.editHistory.length) {
            this.historyList = response.editHistory
            this.$emit('history', response.editHistory[0])
          } else {
            this.$emit('history', {
              editDate: '',
              editId: null,
              editType: '',
              emailAddress: '',
              formattedDate: ''
            })
          }
        }).catch(e => {
          console.log(e)
        })
    },
    showFullList () {
      this.listLength = this.historyList.length
    },
    showShortList () {
      this.listLength = 5
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/extra/mixins/flexbox";
@import "../../../assets/scss/bootstrap-custom-variables";

.history-title {
  @include flexbox;
  @include justify-content(space-between);

  text-transform: uppercase;
  h6 {
    margin-bottom: 0;
    font-size: 0.875rem;
    @include align-self(center);
  }
  .material-icons {
    font-size: 1.5rem;
  }

  .hist-show,
  .hist-collapsed {
    display: none;
  }

  &[aria-expanded="false"] {
    .hist-collapsed {
      display: inline-block;
    }
  }

  &[aria-expanded="true"] {
    .hist-show {
      display: inline-block;
    }
  }
}

.list-group {
  .list-group-item:not(.list-group-item-action) {
    color: $gray-900;
    background: $gray-200;
    padding: 0.5rem 0.75rem;
  }
  .history-list {
    .list-group-item {
      font-size: 0.675rem;
    }
    .list-group-item-action {
      color: $gray-900;
      background: $gray-200;
      text-transform: uppercase;
      @include flexbox;
      @include justify-content(center);
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        background: darken($gray-200, 5%);
      }
    }
  }
}
</style>
