<template>
  <div>
    <modal
      ref="modal"
      :title="'Product'"
      :icon="modal.icon"
      @close="resetModal">
      <template v-if="saveResult">
        <div class="lead text-center">
          {{ modal.message }}
        </div>
      </template>
      <template v-else>
        <review
          :item="item"
          @viewall="close()"></review>
        <div
          slot="button"
          class="modal-actions">
          <button
            :disabled="item.status === 'D'"
            type="button"
            class="btn mr-2"
            @click="save('D')">
            Deny
          </button>
          <button
            :disabled="item.status === 'A'"
            type="button"
            class="btn btn-primary"
            @click="save('A')">
            Approve
          </button>
        </div>
      </template>
    </modal>
  </div>
</template>
<script type="text/javascript">
import Modal from 'components/modals/sweet.vue'
import Review from './components/Review.vue'
import axios from 'axios'

export default {
  name: 'EditReviewModal',
  components: {
    modal: Modal,
    Review
  },
  props: {
    item: Object
  },
  data () {
    return {
      userId: this.$store.getters.user.userId,
      modal: {
        message: '',
        icon: null
      },
      saveResult: false
    }
  },
  watch: {
  },
  methods: {
    open () {
      this.$refs.modal.open()
    },
    save (status) {
      axios.post(`/api/product-reviews/UpdateProductReviewStatus/${this.item.reviewID}?status=${status}&userId=${this.userId}`).then(res => {
        if (res.status === 200) {
          this.modal.icon = 'success'
          this.modal.message = 'Updated Review Successfully.'
          this.item.status = status
          this.$emit('save', this.item)
        } else {
          this.modal.icon = 'error'
          this.modal.message = 'Error Updating Review'
        }
        this.saveResult = true
        this.$nextTick(() => {
          this.showSpinner = false
        })
      }).catch(err => {
        console.log(err)
      })
    },
    close () {
      this.resetModal()
      this.$refs.modal.close()
    },
    resetModal () {
      this.modal = {
        message: '',
        icon: null
      }
      this.saveResult = false
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../assets/scss/extra/mixins/flexbox.scss";

.modal-actions {
  @include flexbox;
  @include justify-content(flex-end);
  .btn {
    min-width: 7rem;
  }
}
</style>
