<template>
  <section id="export">
    <div class="section-heading">
      <h6>Export Information:</h6>
    </div>
    <template v-if="countries.length && product.napmId">
      <div class="section-body">
        <div class="form-group">
          <label class="form-control-label">
            Harmonized Code
          </label>
          <input
            v-model="product.harmonizedCode"
            v-mask="'####.##.####'"
            type="text"
            class="form-control" />
        </div>
        <div class="form-group">
          <label class="form-control-label">
            ECCN
          </label>
          <input
            v-model="product.eccn"
            v-mask="'NNNNN'"
            type="text"
            class="form-control" />
        </div>
        <div class="form-group">
          <label class="form-control-label">
            Country of Origin
          </label>
          <select
            v-model="product.countryId"
            class="form-control">
            <option :value="0">
              -- Select --
            </option>
            <option
              v-for="c in countries"
              :key="`country-${c.id}`"
              :value="c.id">
              {{ c.name }}
            </option>
          </select>
        </div>
      </div>
    </template>
  </section>
</template>
<script type="text/javascript">
export default {
  props: {
    loaded: Boolean,
    product: Object,
    countries: Array
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/extra/mixins/flexbox.scss";
section {
  border: 1px solid #c9c9c9;
  background: #eee;
  margin: 2rem 0;
  .section-heading {
    border-bottom: 0;
    h6 {
      padding: 0 1.25rem;
    }
  }
}

.section-body {
  padding: 0 1rem .5rem;
  @media (min-width: 37.5rem) {
    @include flexbox;
    @include flex-wrap(wrap);
    @include justify-content(space-between);
  }
  .form-group {
    width: 100%;
    @media (min-width: 1200px) {
      width: calc(33% - .5rem);
    }
    input[readonly],
    input[disabled='disabled'] {
      border: 1px solid transparent;
      background-color: #ececec;
    }
  }
}
</style>
