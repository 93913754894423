export default {
  data: {
    Roles: {
      admin: 1,
      editor: 2,
      contributor: 3,
      readonly: 4,
      marketing: 5
    }
  }
}
