<template>
  <div class="card-block">
    <div class="row">
      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label class="form-control-label">
            Rexel Energy Item Number
          </label>
          <input
            v-model="product.productId"
            type="text"
            class="form-control"
            disabled />
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="form-group">
          <label class="form-control-label">
            Product Number
          </label>
          <input
            v-model="product.sku"
            type="text"
            class="form-control"
            disabled />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      marketOptions: []
    }
  },
  watch: {
    product: {
      handler (val) {
        this.$emit('bannerPropChange', 'rexelEnergy')
      },
      deep: true
    }
  }
}
</script>
