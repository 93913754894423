import { Doughnut, mixins } from 'vue-chartjs'
import 'chart.piecelabel.js'
const { reactiveData } = mixins

const doughnutChart = Doughnut.extend({
  mixins: [reactiveData],
  props: ['chartDataset', 'chartReady'],
  watch: {
    chartReady (val, oldVal) {
      this.fillData()
    }
  },
  data () {
    return {
      dataSet: {}
    }
  },
  methods: {
    fillData () {
      this.dataSet = {
        labels: this.chartDataset.labels,
        datasets: [
          {
            backgroundColor: ['#00416A', '#0084d8'],
            data: this.chartDataset.data
          }
        ]
      }
      this.render()
    },
    render () {
      this.renderChart(this.dataSet,
        {
          responsive: true,
          maintainAspectRatio: false,
          scaleFontSize: '14',
          title: {
            display: true,
            fontColor: '#999',
            fontStyle: 'normal',
            fontSize: 16,
            text: 'YOU VS. TEAM',
            fontFamily: "'PT Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
          },
          legend: {
            display: true,
            position: 'bottom',
            fullWidth: false,
            labels: {
              usePointStyle: true,
              fontSize: 12,
              boxWidth: 10,
              fontStyle: 'normal',
              padding: 20,
              fontFamily: "'PT Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
            }
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                const index = tooltipItem.index
                return `${data.labels[index]}:  ${Number(data.datasets[0].data[index]).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
              }
            }
          },
          pieceLabel: {
            mode: 'percentage'
          }
        })
    }
  }
})
export default doughnutChart
