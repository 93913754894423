import axios from 'axios'
export default {
  data () {
    return {
      imageToUpload: {
        file: null,
        fileName: '',
        manufacturer: null,
        rotated: 0,
        zoom: 1,
        points: {},
        src: null,
        width: 0,
        height: 0,
        mediaId: null,
        languageId: 1
      }
    }
  },
  methods: {
    createImageForUpload (dataToRead, fileName) {
      return new Promise((resolve, reject) => {
        const image = new Image()
        const reader = new FileReader()
        reader.readAsDataURL(dataToRead)
        reader.onload = file => {
          this.imageToUpload.src = file.target.result
          image.src = file.target.result
        }
        reader.onloadend = file => {
          this.imageToUpload.width = image.width
          this.imageToUpload.fileName = fileName
          this.imageToUpload.file = dataToRead
          this.imageToUpload.height = image.height
          resolve(this.imageToUpload)
        }
      })
    },
    appendImageData (data) {
      data.append('x1', this.imageToUpload.points[0])
      data.append('y1', this.imageToUpload.points[1])
      data.append('x2', this.imageToUpload.points[2])
      data.append('y2', this.imageToUpload.points[3])
      data.append(
        'rotate',
        this.imageToUpload.rotated ? this.imageToUpload.rotated * 90 : null
      )
    },
    saveImage () {
      const data = new FormData()
      data.append('file', this.imageToUpload.file)
      this.appendImageData(data)
      data.append('fileName', this.imageToUpload.fileName)
      data.append('mfrId', this.imageToUpload.manufacturer)
      data.append('mediaId', this.imageToUpload.mediaId)
      data.append('languageId', this.imageToUpload.languageId)
      const axiosOptions = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      return axios.post('/api/media/images', data, axiosOptions)
        .then(res => {
          return res.data
        })
    },
    replaceImage () {
      const data = new FormData()
      data.append('file', this.imageToUpload.file)
      data.append('uri', this.imageToUpload.uri)
      data.append('fileName', this.imageToUpload.fileName)
      data.append('languageId', this.imageToUpload.languageId)
      this.appendImageData(data)
      return axios.patch(`/api/media/images/${this.imageToUpload.mediaId}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(res => {
          return res.data
        })
    },
    resetImageUploadDefaults () {
      this.imageToUpload = {
        file: null,
        fileName: '',
        manufacturer: null,
        rotated: 0,
        zoom: 1,
        points: {},
        src: null,
        width: 0,
        height: 0
      }
    }
  }
}
