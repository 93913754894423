<template>
  <div class="card-block">
    <div class="row">
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            Gexpro Item Number
          </label>
          <input
            v-model="product.productId"
            type="text"
            class="form-control"
            disabled />
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            Gexpro Price Line
          </label>
          <input
            v-model="product.mfrCode"
            type="text"
            class="form-control"
            :disabled="isReadOnly" />
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            Gexpro SKU
          </label>
          <input
            v-model="product.sku"
            type="text"
            class="form-control"
            :disabled="isReadOnly" />
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            Life Cycle
          </label>
          <select
            v-model="product.activeStatusCode"
            class="form-control"
            :disabled="isReadOnly">
            <option value="">
              --- Choose Lifecycle ---
            </option>
            <option value="1">
              Active
            </option>
            <option value="0">
              Inactive
            </option>
            <option value="2">
              Obsolete
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            Unit Sales
          </label>
          <input
            v-model="product.unitSales"
            type="text"
            class="form-control"
            :disabled="isReadOnly" />
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            UOM
          </label>
          <input
            v-model="product.uom"
            type="text"
            class="form-control"
            :disabled="isReadOnly" />
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            Minimum Order Quantity
          </label>
          <input
            v-model="product.minOrdQty"
            type="text"
            class="form-control"
            :disabled="isReadOnly" />
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            Buy Line
          </label>
          <input
            v-model="product.buyLine"
            type="text"
            class="form-control"
            disabled />
        </div>
      </div>
    </div>
    <v-row>
      <v-col :cols="3">
        <v-select
          v-model="product.showOnWeb"
          v-tooltip="'Not set interpreted as yes for Platt and no for others'"
          class="mb-2"
          :items="showOnWebItems"
          :disabled="isReadOnly"
          hide-details
          outlined
          dense
          label="Show On Web">
        </v-select>
      </v-col>
      <v-col :cols="1">
        <label class="custom-control custom-checkbox ml-3">
          <input
            v-model="product.visibleOnlyToCustomers"
            type="checkbox"
            class="custom-control-input"
            :disabled="isReadOnly" />
          <span class="custom-control-indicator"></span>
          <span class="custom-control-description">
            Only Visible To Customers
          </span>
        </label>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import MultiselectComponent from 'components/inputs/multiselect.vue'
import PermissionsMixin from 'mixins/Permissions.mixin'

export default {
  components: {
    // 'multi-select': MultiselectComponent
  },
  props: {
    product: {
      type: Object
    }
  },
  data () {
    return {
      vendorGroupOptions: ['RW_COMP'],
      showOnWebItems: [
        {
          text: 'Not set',
          value: null
        },
        {
          text: 'Yes',
          value: true
        },
        {
          text: 'No',
          value: false
        }
      ]
    }
  },
  computed: {
    isReadOnly () {
      return !this.$store.getters.hasPermissionForBanner(PermissionsMixin.data.Roles.contributor, 3)
    }
  },
  watch: {
    product: {
      handler () {
        this.$emit('bannerPropChange', 'gexpro')
      },
      deep: true
    }
  }
}
</script>
