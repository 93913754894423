<template>
  <div>
    <v-data-table
      :headers="videoListHeaders"
      :items="videoUrlList"
      :search="search"
      :footer-props="{
        'items-per-page-options': [10, 25, 50, 100]
      }">
      <template #top>
        <v-text-field
          v-model="search"
          label="Search">
        </v-text-field>
      </template>
      <template
        #item.exclusionInd="{item}">
        <v-simple-checkbox
          v-model="item.exclusionInd"
          @click="exclusionOnChange(item.externalMediaId, item.exclusionInd)"></v-simple-checkbox>
      </template>
      <template
        #item.showItem="{item}">
        <i
          style="cursor:pointer; font-size: 1.275rem;"
          class="material-icons"
          @click="viewMedia(item.externalMediaUrl)">
          visibility
        </i>
      </template>
    </v-data-table>
  </div>
</template>

<script type="text/javascript">

export default {
  name: 'ThirdPartyVideos',
  data () {
    return {
      changeProducts: false,
      createVideo: false,
      editVideo: false,
      selectedVideoUrls: [],
      videoUrlList: [],
      videoUrl: '',
      initialUrl: '',
      currentMediaId: 0,
      videoListHeaders: [
        {
          text: 'External URLs',
          value: 'externalMediaUrl',
          width: '80%'
        },
        {
          text: 'Exclude Media',
          value: 'exclusionInd',
          width: '10%',
          sortable: false
        },
        {
          text: 'View Media',
          width: '10%',
          sortable: false,
          value: 'showItem'
        }
      ],
      search: ''
    }
  },
  mounted () {
    this.getVideoUrls()
  },
  methods: {
    viewMedia (url) {
      window.open(url, '_blank')
    },
    exclusionOnChange (id, value) {
      const data = new FormData()
      data.append('value', value)
      data.append('userId', this.$store.getters.user.userId)
      this.$http.put('/api/media/externalvideos/videos/' + id, data)
        .then(res => {
        })
    },
    changeProductAssociations (key) {
      this.currentMediaId = key
      this.changeProducts = true
    },
    createVideoOpen () {
      this.createVideo = true
    },
    editUrl (key, url) {
      this.initialUrl = url
      this.currentMediaId = key
      this.editVideo = true
    },
    getVideoUrls () {
      this.$http.get('/api/media/externalvideos/videos')
        .then(res => {
          this.videoUrlList = null
          this.videoUrlList = res.data
        })
    },
    resetModal () {
      this.modal = {
        title: 'Add 3rd Party Video'
      }
      this.$emit('close-modal', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.update-item-action-cell ::v-deep .btn-update-item-action {
  border-radius: 50%;
  padding: 2px;
  i {
    font-size: 28px;
  }
}

.url-text {
  font-size: 16px;
}

.action-dropdown-item {
  padding: 4px 10px;
  font-size: 14px;
  font-weight: bold;
  &:hover {
    background-color: #eee;
    cursor: pointer;
  }
}

</style>
