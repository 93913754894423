<template>
  <div class="top-title">
    <div class="inline-container">
      <div
        v-if="!hide"
        class="btn-action-back"
        @click="goBack()">
        <i class="material-icons go-back">
          &#xE5C4;
        </i>
      </div>
      <h1
        v-if="hasHtml"
        class="page-name"
        v-html="title"></h1>
      <h1
        v-else
        class="page-name">
        {{ title }}
      </h1>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  props: {
    title: {
      default: '',
      type: String,
      required: false
    },
    hide: {
      default: false,
      type: Boolean,
      required: false
    },
    hasHtml: {
      default: false,
      type: Boolean,
      required: false
    },
    route: {
      default: null,
      type: Object,
      required: false
    }
  },
  methods: {
    goBack () {
      if (this.route) {
        this.$router.push(this.route)
      } else {
        this.$router.back()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/scss/extra/mixins/flexbox.scss";
.top-title {
  font-weight: 400;
  padding: 1.25rem 0 1rem;
  .inline-container {
    @include flexbox;

    .page-name {

      color: #333;
      margin-bottom: 0;
      display: block; /* Fallback for non-webkit */
      display: -webkit-box;
      height: 1.5rem * 1.5; /* Fallback for non-webkit */
      font-size: 1.5rem;
      line-height: 1.5;
      font-weight: 500;
      text-transform: capitalize;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .btn-action-back {
      display: block;
      color: #333;
      margin: .25rem .25rem 0 0;
      line-height: 1.5;
      @include flex(0,1,1.75rem);
      @include align-self(center);
      cursor: pointer;
      .go-back {
        vertical-align: text-bottom;
        font-size: 1.5rem;
      }
      &:hover {
        color: #000;
        font-weight: 500;
      }
    }
  }
}
</style>
