<template>
  <div
    class="image-cropper"
    :class="{'inline-cropper': inline}">
    <div
      id="croppieContainer"
      ref="croppieContainer"
      :class="[customClass, showZoomer ? '' : 'no-zoomer']"></div>
    <div
      v-if="showButtons"
      class="crop-controls">
      <button
        type="button"
        class="btn btn-light"
        @click="rotate(90)">
        <i class="material-icons">
          rotate_right
        </i>
      </button>
      <button
        type="button"
        class="btn btn-light"
        @click="zoomIn()">
        <i class="material-icons">
          add
        </i>
      </button>
      <button
        type="button"
        class="btn btn-light"
        @click="zoomOut()">
        <i class="material-icons">
          remove
        </i>
      </button>
    </div>
  </div>
</template>
<script>
import Croppie from 'croppie'
// import * as EXIF from 'exif-js'
import 'croppie/croppie.css'
export default {
  props: {
    boundary: {
      type: Object,
      default () {
        return {
          width: 400,
          height: 400
        }
      }
    },
    imageUrl: {},
    customClass: String,
    enableOrientation: {
      type: Boolean,
      default: true
    },
    enableExif: {
      type: Boolean,
      default: true
    },
    enableZoom: {
      type: Boolean,
      default: true
    },
    enforceBoundary: {
      type: Boolean,
      default: true
    },
    mouseWheelZoom: {
      type: Boolean,
      default: true
    },
    showZoomer: {
      type: Boolean,
      default: false
    },
    showButtons: {
      type: Boolean,
      default: false
    },
    zoomLevel: {
      type: Number,
      required: false,
      default: 1
    },
    inline: {
      type: Boolean,
      default: false
    },
    viewport: {
      type: Object,
      default () {
        return {
          width: 200,
          height: 200,
          type: 'square'
        }
      }
    }
  },
  data () {
    return {
      croppie: null,
      rotated: 0
    }
  },
  watch: {
    imageUrl (val, old) {
      if (val !== old) {
        this.$nextTick(() => {
          this.bind({ url: val })
        })
      } else {
        if (old) { this.refresh() }
      }
    }
  },
  mounted () {
    this.initCroppie()
  },
  methods: {
    initCroppie () {
      const el = this.$refs.croppieContainer

      el.addEventListener('update', (ev) => {
        this.$emit('update', ev.detail)
      })
      this.croppie = new Croppie(el, {
        boundary: this.boundary,
        enableExif: this.enableExif,
        enableOrientation: this.enableOrientation,
        enableZoom: this.enableZoom,
        enforceBoundary: this.enforceBoundary,
        mouseWheelZoom: this.mouseWheelZoom,
        viewport: this.viewport,
        showZoomer: this.showZoomer
      })
    },
    bind (options) {
      this.croppie.bind(options)
    },
    destroy () {
      this.croppie.destroy()
    },
    get (cb) {
      cb(this.croppie.get())
    },
    rotate (angle) {
      this.rotated += 1
      this.$emit('rotate', this.rotated)
      this.croppie.rotate(angle)
    },
    zoomIn () {
      this.setZoom(this.zoomLevel + 0.05)
    },
    zoomOut () {
      this.setZoom(this.zoomLevel - 0.05)
    },
    setZoom (value) {
      this.croppie.setZoom(value)
    },
    result (options, cb) {
      if (!options) options = { type: 'base64' }
      this.croppie.result(options).then(output => {
        if (!cb) {
          this.$emit('result', output)
        } else {
          cb(output)
        }
        this.refresh()
      })
    },
    refresh () {
      this.croppie.destroy()
      this.initCroppie()
    }
  }
}
</script>
<style lang="scss">
@import "../../assets/scss/extra/mixins/flexbox.scss";
.image-cropper {
  position: relative;
  .crop-controls {
    @include flexbox;
    @include flex-direction(column);
    position: absolute;
    bottom: 1.25rem;
    right: 1rem;
    z-index: 100;
    .btn {
      margin-bottom: 0.75rem;
      padding: 0.325rem 0.55rem;
      border-radius: 2px;
      .material-icons {
        font-size: 1.5rem;
      }
    }
  }
}
.inline-cropper {
  display: inline-block;
}
.no-zoomer.croppie-container {
  .cr-slider-wrap {
    display: none;
  }
}
</style>
