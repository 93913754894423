import PermissionsMixin from 'mixins/Permissions.mixin'

export default {
  languageId (state) {
    return parseInt(state.language.id)
  },
  languageOptions (state) {
    return state.languageOptions
  },
  // Obsolete. Use banners
  companyList (state) {
    return state.companyList
  },
  banners (state) {
    return state.banners
  },
  user (state) {
    return state.user
  },
  permissions (state) {
    return state.permissions
  },
  hasPermissionForBanner: (state) => (role, banner) => {
    switch (role) {
      case PermissionsMixin.data.Roles.admin:
      {
        return state.permissions.some(p => p.isAdmin === true && p.company.companyId === banner)
      }
      case PermissionsMixin.data.Roles.editor:
      {
        return state.permissions.some(p => (p.isAdmin === true && p.company.companyId === banner) || (p.isEditor === true && p.company.companyId === banner))
      }
      case PermissionsMixin.data.Roles.contributor:
      {
        return state.permissions.some(p => (p.isAdmin === true && p.company.companyId === banner) || (p.isEditor === true && p.company.companyId === banner) ||
          (p.contributor === true && p.company.companyId === banner))
      }
      default:
      {
        console.log('Unknown permission')
        console.log(role)
        return false
      }
    }
  },
  hasPermissionForAnyCanadaBanner: (state) => (role) => {
    // See if there is any permission that has this role.
    // for any of the canada banners (Assumes banner > 3 --> Canada banner)
    switch (role) {
      case PermissionsMixin.data.Roles.admin:
      {
        return state.permissions.some(p => p.isAdmin === true && p.company.companyId > 3)
      }
      case PermissionsMixin.data.Roles.editor:
      {
        return state.permissions.some(p => (p.isAdmin === true && p.company.companyId > 3) || (p.isEditor === true && p.company.companyId > 3))
      }
      case PermissionsMixin.data.Roles.contributor:
      {
        return state.permissions.some(p => (p.isAdmin === true && p.company.companyId > 3) || (p.isEditor === true && p.company.companyId > 3) ||
           (p.contributor === true && p.company.companyId > 3))
      }
      default:
      {
        console.log('Unknown permission')
        console.log(role)
        return false
      }
    }
  },

  hasPermissionForAnyBanner: (state) => (role) => {
    // See if there is any permission that has this role.
    // For any banner above 3 (those are all Canada banners)
    switch (role) {
      case PermissionsMixin.data.Roles.admin:
      {
        return state.permissions.some(p => p.isAdmin === true)
      }
      case PermissionsMixin.data.Roles.editor:
      {
        return state.permissions.some(p => p.isAdmin === true || p.isEditor === true)
      }
      case PermissionsMixin.data.Roles.contributor:
      {
        return state.permissions.some(p => p.isAdmin === true || p.isEditor === true || p.contributor === true)
      }
      default:
      {
        console.log('Unknown permission')
        console.log(role)
        return false
      }
    }
  },
  // hasPermissionForMarketing: (state) => (role) => {
  //   if (!state.identity) {
  //     return false
  //   }
  //   return state.identity.role.some(role => JSON.parse(role).name === 'marketing')
  // },
  // isNapmUser (state) {
  //   return state.permissions && state.permissions.length > 0
  // },
  admin (state, getters) {
    return getters.hasPermissionForAnyBanner(PermissionsMixin.data.Roles.admin)
  },
  teamId (state) {
    return state.user.team
  },
  getProductBulkList (state) {
    return state.productBulkList
  },
  showSpinner (state) {
    return state.showSpinner
  },
  manufacturers (state) {
    return state.manufacturers
  },
  vendors (state) {
    return state.vendors
  },
  modal (state) {
    return state.modal
  },
  category (state) {
    return state.categories
  },
  documentTypeIds (state) {
    return state.documentTypeIds
  },
  appConfig (state) {
    return state.appConfig
  }
}
